import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import {
    Brain, SendHorizontal, User, Bot, Loader, Trash, Info, Settings,
    Lock, Shield, Sparkles, Zap, AlertTriangle, Code, Terminal, Database,
    Eye, EyeOff, Download, Upload, Coffee, Key, RefreshCw, FileText, Image,
    ImagePlus, X, Clipboard
} from 'lucide-react';
import { ThemeContext } from '@/FullDevApp.jsx';
import AIContextManager from "@/ai-scope/contexts/AIContextManager.js";
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark, oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import FrontAutocrat, { MESSAGE_TYPES, COMPONENT_TYPES, EVENT_TYPES } from '@/FrontAutocrat.jsx';
import { useThemeColors } from "@/templates-scope/colors/accepted/Accepted_Colors_GeneralWebpages_Templates.jsx";

// Import specialists
import Specialist_Knowledge_Contexts_Chat_AI_Admin
    from "@/admin-scope/ai/chat/specialists/Specialist_Knowledge_Contexts_Chat_AI_Admin.js";
import Specialist_Conversation_Contexts_Chat_AI_Admin
    from "@/admin-scope/ai/chat/specialists/Specialist_Conversation_Contexts_Chat_AI_Admin.js";
import Specialist_Remainder_Chat_AI_Admin
    from "@/admin-scope/ai/chat/specialists/Specialist_Remainder_Chat_AI_Admin.jsx";

/**
 * Admin version of the AI Chat Component for Taytrom
 * This component is intended for administrative use only and requires special access codes.
 * It provides enhanced capabilities for system administration, development, and debugging.
 */
const Compiler_Chat_AI_Admin = ({
                                    persistKey = 'admin-claude-conversation',
                                    maxMessages = 500, // Enhanced message limit for admins
                                    moduleContext = null,
                                    className = "",
                                    initialMode = "standard"
                                }) => {
    // Theme Context and Colors
    const { isDark, toggleTheme } = useContext(ThemeContext);
    const themeColors = useThemeColors();
    const codeStyle = isDark ? atomDark : oneLight;

    // Initialize specialists
    const ADMIN_ACCESS_CODES = Specialist_Remainder_Chat_AI_Admin.ADMIN_ACCESS_CODES;
    const ADMIN_THINKING_BUDGET_OPTIONS = Specialist_Remainder_Chat_AI_Admin.ADMIN_THINKING_BUDGET_OPTIONS;
    const ADMIN_OPERATIONAL_MODES = Specialist_Remainder_Chat_AI_Admin.ADMIN_OPERATIONAL_MODES;
    const ADMIN_SYSTEM_PROMPTS = Specialist_Remainder_Chat_AI_Admin.ADMIN_SYSTEM_PROMPTS;

    // Initialize states from specialists
    const remainderState = Specialist_Remainder_Chat_AI_Admin.initializeRemainderState();
    const conversationState = Specialist_Conversation_Contexts_Chat_AI_Admin.initializeConversationState();
    const knowledgeState = Specialist_Knowledge_Contexts_Chat_AI_Admin.initializeKnowledgeState();

    // Authentication and Access Control
    const [isAuthenticated, setIsAuthenticated] = useState(remainderState.isAuthenticated);
    const [accessCode, setAccessCode] = useState(remainderState.accessCode);
    const [accessAttempts, setAccessAttempts] = useState(remainderState.accessAttempts);
    const [isLocked, setIsLocked] = useState(remainderState.isLocked);
    const [lockdownTimer, setLockdownTimer] = useState(remainderState.lockdownTimer);
    const [showAccessForm, setShowAccessForm] = useState(remainderState.showAccessForm);

    // Core Chat States
    const [messages, setMessages] = useState(() => {
        if (typeof window !== 'undefined' && isAuthenticated) {
            try {
                const saved = localStorage.getItem(persistKey);
                return saved ? JSON.parse(saved) : [];
            } catch (e) {
                console.warn('[Admin] Failed to load saved messages:', e);
                return [];
            }
        }
        return [];
    });

    const [input, setInput] = useState(conversationState.input);
    const [isLoading, setIsLoading] = useState(conversationState.isLoading);
    const [error, setError] = useState(conversationState.error);
    const [conversationId, setConversationId] = useState(conversationState.conversationId);
    const [currentThoughts, setCurrentThoughts] = useState(conversationState.currentThoughts);
    const [isPending, setIsPending] = useState(conversationState.isPending);
    const [attachedImages, setAttachedImages] = useState(conversationState.attachedImages);
    const [isImageUploading, setIsImageUploading] = useState(conversationState.isImageUploading);
    const [conversationHistory, setConversationHistory] = useState(() => {
        if (typeof window !== 'undefined' && isAuthenticated) {
            try {
                const savedHistory = localStorage.getItem(`${persistKey}_history`);
                return savedHistory ? JSON.parse(savedHistory) : [];
            } catch (e) {
                console.warn('[Admin] Failed to load saved conversation history:', e);
                return [];
            }
        }
        return [];
    });

    // Admin-specific States
    const [operationalMode, setOperationalMode] = useState(initialMode);
    const [systemPrompt, setSystemPrompt] = useState(ADMIN_SYSTEM_PROMPTS.standard);
    const [showSystemPrompt, setShowSystemPrompt] = useState(remainderState.showSystemPrompt);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(remainderState.showAdvancedOptions);
    const [temperature, setTemperature] = useState(remainderState.temperature);
    const [maxTokens, setMaxTokens] = useState(remainderState.maxTokens);
    const [extendedThinkingEnabled, setExtendedThinkingEnabled] = useState(remainderState.extendedThinkingEnabled);
    const [thinkingBudget, setThinkingBudget] = useState(remainderState.thinkingBudget);
    const [debugMode, setDebugMode] = useState(remainderState.debugMode);
    const [sessionLogs, setSessionLogs] = useState([]);
    const [showSessionLogs, setShowSessionLogs] = useState(remainderState.showSessionLogs);

    // Knowledge-related States
    const [adminKnowledge, setAdminKnowledge] = useState(knowledgeState.adminKnowledge);
    const [isAdminKnowledgeLoaded, setIsAdminKnowledgeLoaded] = useState(knowledgeState.isAdminKnowledgeLoaded);
    const [viewingAdminFile, setViewingAdminFile] = useState(knowledgeState.viewingAdminFile);
    const [lastFilePath, setLastFilePath] = useState(knowledgeState.lastFilePath);
    const [availableKnowledgeDomains, setAvailableKnowledgeDomains] = useState(knowledgeState.availableKnowledgeDomains);
    const [detectedContextType, setDetectedContextType] = useState(remainderState.detectedContextType);

    // Context-related States
    const [contextualKnowledge, setContextualKnowledge] = useState(null);
    const [isContextLoaded, setIsContextLoaded] = useState(false);

    // AI System Integration States
    const [aiManagerInitialized, setAiManagerInitialized] = useState(false);

    // References
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const orchestratorRef = useRef(null);
    const messageContainerRef = useRef(null);
    const aiManagerRef = useRef(null);
    const accessCodeInputRef = useRef(null);
    const sessionLogsRef = useRef([]);
    const fileInputRef = useRef(null);

    // Create addToSessionLogs as a wrapper for Specialist_Remainder_Chat_AI_Admin.addToSessionLogs
    const addToSessionLogs = useCallback((message, type = 'info') => {
        Specialist_Remainder_Chat_AI_Admin.addToSessionLogs(
            message,
            type,
            sessionLogsRef,
            setSessionLogs,
            debugMode
        );
    }, [debugMode]);

    // Initialize orchestrator on component mount
    useEffect(() => {
        if (!orchestratorRef.current) {
            orchestratorRef.current = new FrontAutocrat({
                mode: 'development',
                debug: true
            });

            orchestratorRef.current.initialize().then(() => {
                console.log('[Admin] Orchestrator initialized');
                addToSessionLogs('Orchestrator initialized successfully');

                // Initialize AI Manager after orchestrator is ready
                if (!aiManagerRef.current) {
                    aiManagerRef.current = new AIContextManager(orchestratorRef.current);

                    // Explicitly initialize AI Manager with file system
                    aiManagerRef.current.initialize().then(() => {
                        console.log('[Admin] AI Manager initialized successfully');
                        setAiManagerInitialized(true);
                        addToSessionLogs('AI Manager initialized successfully');

                        // Detect context type based on current location
                        const newContextType = Specialist_Remainder_Chat_AI_Admin.detectContextType(
                            aiManagerRef,
                            addToSessionLogs,
                            detectedContextType,
                            loadAdminKnowledge
                        );
                        setDetectedContextType(newContextType);

                        // Load known knowledge domains
                        loadAvailableKnowledgeDomains();
                    }).catch(err => {
                        console.error('[Admin] Failed to initialize AI Manager:', err);
                        setError(`Failed to initialize AI Manager: ${err.message}`);
                        addToSessionLogs(`ERROR: Failed to initialize AI Manager: ${err.message}`, 'error');
                    });
                }
            }).catch(err => {
                console.error('[Admin] Failed to initialize orchestrator:', err);
                setError(`Failed to initialize AI system: ${err.message}`);
                addToSessionLogs(`ERROR: Failed to initialize orchestrator: ${err.message}`, 'error');
            });
        }
    }, []);

    // Initialize conversationId if not present
    useEffect(() => {
        if (!conversationId) {
            const newId = `admin_conv_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
            setConversationId(newId);
            addToSessionLogs(`New conversation initialized: ${newId}`);
        }
    }, [conversationId, addToSessionLogs]);

    // Add welcome message after authentication
    useEffect(() => {
        if (isAuthenticated && messages.length === 0) {
            const welcomeMessage = Specialist_Conversation_Contexts_Chat_AI_Admin.createWelcomeMessage(
                ADMIN_OPERATIONAL_MODES[operationalMode].name,
                detectedContextType
            );
            setMessages([welcomeMessage]);
            addToSessionLogs('Admin welcome message displayed');
        }
    }, [isAuthenticated, messages.length, operationalMode, detectedContextType, ADMIN_OPERATIONAL_MODES, addToSessionLogs]);

    // Load available knowledge domains
    const loadAvailableKnowledgeDomains = useCallback(async () => {
        const domains = await Specialist_Knowledge_Contexts_Chat_AI_Admin.loadAvailableKnowledgeDomains(
            aiManagerRef,
            addToSessionLogs
        );
        setAvailableKnowledgeDomains(domains);
    }, [addToSessionLogs]);

    // Force refresh knowledge domains
    const forceRefreshKnowledgeDomains = useCallback(() => {
        const domains = Specialist_Knowledge_Contexts_Chat_AI_Admin.forceRefreshKnowledgeDomains(
            aiManagerRef,
            addToSessionLogs
        );
        setAvailableKnowledgeDomains(domains);
    }, [addToSessionLogs]);

    // Call this function after authentication is complete
    useEffect(() => {
        if (isAuthenticated && aiManagerInitialized) {
            // Allow time for everything to initialize
            setTimeout(() => {
                loadAvailableKnowledgeDomains();

                // Force a second load after a delay to ensure domains are loaded
                setTimeout(forceRefreshKnowledgeDomains, 1000);
            }, 500);
        }
    }, [isAuthenticated, aiManagerInitialized, loadAvailableKnowledgeDomains, forceRefreshKnowledgeDomains]);

    // Clear attached images
    const clearAttachedImages = useCallback(() => {
        setAttachedImages([]);
    }, []);

    // File handling operations
    const handleFileSelect = useCallback((event) => {
        Specialist_Conversation_Contexts_Chat_AI_Admin.handleFileSelect(
            event,
            setIsImageUploading,
            setAttachedImages,
            addToSessionLogs
        );
    }, [addToSessionLogs]);

    const handlePaste = useCallback((event) => {
        Specialist_Conversation_Contexts_Chat_AI_Admin.handlePaste(
            event,
            setIsImageUploading,
            setAttachedImages,
            addToSessionLogs
        );
    }, [addToSessionLogs]);

    const handleDrop = useCallback((event) => {
        Specialist_Conversation_Contexts_Chat_AI_Admin.handleDrop(
            event,
            setIsImageUploading,
            setAttachedImages,
            addToSessionLogs
        );
    }, [addToSessionLogs]);

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Remove an attached image
    const removeAttachedImage = (imageId) => {
        setAttachedImages(prevImages => prevImages.filter(image => image.id !== imageId));
    };

    // View admin file
    const viewAdminFile = useCallback((fileContent, fileName) => {
        setViewingAdminFile({
            name: fileName || 'project-structure.txt',
            content: fileContent
        });
    }, []);

    // Check file system status
    const checkFileSystemStatus = useCallback(() => {
        return Specialist_Knowledge_Contexts_Chat_AI_Admin.checkFileSystemStatus(
            aiManagerRef,
            addToSessionLogs
        );
    }, [addToSessionLogs]);

    // Reinitialize file system
    const reinitializeFileSystem = useCallback(async () => {
        return await Specialist_Knowledge_Contexts_Chat_AI_Admin.reinitializeFileSystem(
            aiManagerRef,
            addToSessionLogs
        );
    }, [addToSessionLogs]);

    // Load admin knowledge
    const loadAdminKnowledge = useCallback(async () => {
        const result = await Specialist_Knowledge_Contexts_Chat_AI_Admin.loadAdminKnowledge(
            aiManagerRef,
            isAuthenticated,
            addToSessionLogs,
            checkFileSystemStatus,
            reinitializeFileSystem
        );

        if (result.success) {
            setAdminKnowledge(result.adminData);
            setIsAdminKnowledgeLoaded(true);
            setContextualKnowledge(result.adminData);
            return true;
        }
        return false;
    }, [isAuthenticated, addToSessionLogs, checkFileSystemStatus, reinitializeFileSystem]);

    // Test file access
    const testFileAccess = useCallback(async () => {
        await Specialist_Knowledge_Contexts_Chat_AI_Admin.testFileAccess(
            aiManagerRef,
            addToSessionLogs,
            reinitializeFileSystem,
            setLastFilePath,
            viewAdminFile
        );
    }, [addToSessionLogs, reinitializeFileSystem, viewAdminFile]);

    // Prepare AI request with domain knowledge
    const prepareAIRequestWithDomains = useCallback(async (userInput, currentHistory = null) => {
        return await Specialist_Knowledge_Contexts_Chat_AI_Admin.prepareAIRequestWithDomains(
            userInput,
            aiManagerRef,
            addToSessionLogs,
            currentHistory || conversationHistory,
            conversationId,
            moduleContext,
            systemPrompt,
            temperature,
            maxTokens,
            isAdminKnowledgeLoaded,
            adminKnowledge,
            extendedThinkingEnabled,
            thinkingBudget,
            isDark,
            debugMode
        );
    }, [
        conversationId, moduleContext, conversationHistory, systemPrompt,
        temperature, maxTokens, isAdminKnowledgeLoaded, adminKnowledge,
        extendedThinkingEnabled, thinkingBudget, isDark, debugMode, addToSessionLogs
    ]);

    // Handle message submission
    const handleSubmit = useCallback((e) => {
        Specialist_Conversation_Contexts_Chat_AI_Admin.handleSubmit(
            e,
            input,
            attachedImages,
            isLoading,
            isAuthenticated,
            setMessages,
            setIsLoading,
            setInput,
            orchestratorRef,
            conversationId,
            prepareAIRequestWithDomains,
            clearAttachedImages,
            addToSessionLogs,
            conversationHistory,
            setConversationHistory,
            setError
        );
    }, [
        input, attachedImages, isLoading, isAuthenticated, conversationId,
        prepareAIRequestWithDomains, clearAttachedImages, addToSessionLogs,
        conversationHistory
    ]);

    // Handle AI response
    const handleAIResponse = useCallback((notification) => {
        Specialist_Conversation_Contexts_Chat_AI_Admin.handleAIResponse(
            notification,
            extendedThinkingEnabled,
            thinkingBudget,
            currentThoughts,
            operationalMode,
            contextualKnowledge,
            isContextLoaded,
            temperature,
            systemPrompt,
            detectedContextType,
            conversationHistory,
            addToSessionLogs,
            setMessages,
            setConversationHistory,
            setCurrentThoughts,
            setIsLoading,
            setIsPending,
            aiManagerRef
        );
    }, [
        extendedThinkingEnabled, thinkingBudget, currentThoughts, operationalMode,
        contextualKnowledge, isContextLoaded, temperature, systemPrompt,
        detectedContextType, conversationHistory, addToSessionLogs
    ]);

    // Subscribe to AI events
    useEffect(() => {
        if (orchestratorRef.current && conversationId && isAuthenticated) {
            const subscription = orchestratorRef.current.subscribe('ai', handleAIResponse);

            return () => {
                if (orchestratorRef.current) {
                    orchestratorRef.current.unsubscribe('ai', subscription.subscriptionId);
                }
            };
        }
    }, [conversationId, handleAIResponse, isAuthenticated]);

    // Handle clear conversation
    const handleClearConversation = useCallback(() => {
        Specialist_Conversation_Contexts_Chat_AI_Admin.handleClearConversation(
            persistKey,
            addToSessionLogs,
            setMessages,
            setConversationHistory,
            setCurrentThoughts,
            setError,
            setIsPending,
            setInput,
            clearAttachedImages
        );
    }, [persistKey, addToSessionLogs, clearAttachedImages]);

    // Handle sign out
    const handleSignOut = useCallback(() => {
        Specialist_Remainder_Chat_AI_Admin.handleSignOut(
            addToSessionLogs,
            setIsAuthenticated,
            setShowAccessForm
        );
    }, [addToSessionLogs]);

    // Export conversation logs
    const exportConversation = useCallback(() => {
        Specialist_Conversation_Contexts_Chat_AI_Admin.exportConversation(
            conversationId,
            operationalMode,
            detectedContextType,
            systemPrompt,
            messages,
            addToSessionLogs,
            sessionLogsRef
        );
    }, [conversationId, operationalMode, detectedContextType, systemPrompt, messages, addToSessionLogs]);

    // Update the useEffect that saves messages to also save conversation history:
    useEffect(() => {
        if (messages.length > 0 && typeof window !== 'undefined') {
            localStorage.setItem(persistKey, JSON.stringify(messages.slice(-maxMessages)));
            // Also save conversation history
            localStorage.setItem(`${persistKey}_history`, JSON.stringify(conversationHistory));
        }
    }, [messages, conversationHistory, persistKey, maxMessages]);

    // Handle lockdown timer countdown
    useEffect(() => {
        let interval;
        if (isLocked && lockdownTimer > 0) {
            interval = setInterval(() => {
                setLockdownTimer(prev => {
                    if (prev <= 1) {
                        clearInterval(interval);
                        setIsLocked(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isLocked, lockdownTimer]);

    // Check for existing auth on mount
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const isAuth = sessionStorage.getItem('taytrom_admin_authenticated');
            const expiry = sessionStorage.getItem('taytrom_admin_auth_expiry');

            if (isAuth === 'true' && expiry && parseInt(expiry) > Date.now()) {
                setIsAuthenticated(true);
                setShowAccessForm(false);
                addToSessionLogs('Session authentication restored');
            }
        }
    }, [addToSessionLogs]);

    // Handle access code validation
    const validateAccessCode = useCallback(() => {
        Specialist_Remainder_Chat_AI_Admin.validateAccessCode(
            accessCode,
            ADMIN_ACCESS_CODES,
            accessAttempts,
            setIsAuthenticated,
            setShowAccessForm,
            addToSessionLogs,
            setAccessAttempts,
            setAccessCode,
            setIsLocked,
            setLockdownTimer
        );
    }, [accessCode, accessAttempts, ADMIN_ACCESS_CODES, addToSessionLogs]);

    // Render access form
    if (showAccessForm && !isAuthenticated) {
        return (
            <div style={{
                maxWidth: '500px',
                margin: '0 auto',
                padding: '40px 24px',
                backgroundColor: isDark ? '#1a1a1a' : '#ffffff',
                borderRadius: '12px',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                color: isDark ? '#fff' : '#333',
                border: `1px solid ${isDark ? '#333' : '#eaeaea'}`
            }}>
                <div style={{
                    marginBottom: '24px',
                    textAlign: 'center'
                }}>
                    <div style={{
                        display: 'inline-flex',
                        padding: '12px',
                        backgroundColor: isDark ? 'rgba(255, 100, 100, 0.1)' : 'rgba(255, 100, 100, 0.05)',
                        borderRadius: '50%',
                        marginBottom: '16px'
                    }}>
                        <Shield size={40} color="#ff6464" />
                    </div>
                    <h2 style={{
                        margin: '0 0 8px 0',
                        fontSize: '24px',
                        fontWeight: '700'
                    }}>Taytrom Administrative Access</h2>
                    <p style={{
                        margin: '0 0 24px 0',
                        color: isDark ? '#aaa' : '#666',
                        fontSize: '14px'
                    }}>
                        This area is restricted to authorized administrators only.
                        Please enter your administrative access code to continue.
                    </p>
                </div>

                {isLocked ? (
                    <div style={{
                        padding: '16px',
                        backgroundColor: isDark ? '#3a1c1c' : '#fff5f5',
                        border: '1px solid #ff8080',
                        borderRadius: '8px',
                        marginBottom: '24px',
                        textAlign: 'center'
                    }}>
                        <AlertTriangle size={24} color="#ff6464" style={{ marginBottom: '8px' }} />
                        <p style={{
                            margin: '0 0 8px 0',
                            color: '#ff6464',
                            fontWeight: '500'
                        }}>
                            Access Temporarily Locked
                        </p>
                        <p style={{
                            margin: '0',
                            fontSize: '14px',
                            color: isDark ? '#ffaaaa' : '#cc4444'
                        }}>
                            Too many failed attempts. Please try again in {lockdownTimer} seconds.
                        </p>
                    </div>
                ) : (
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        validateAccessCode();
                    }}>
                        <div style={{ marginBottom: '24px' }}>
                            <label htmlFor="accessCode" style={{
                                display: 'block',
                                marginBottom: '8px',
                                fontSize: '14px',
                                fontWeight: '500',
                                color: isDark ? '#ccc' : '#555'
                            }}>
                                Administrative Access Code
                            </label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    ref={accessCodeInputRef}
                                    type="password"
                                    id="accessCode"
                                    value={accessCode}
                                    onChange={(e) => setAccessCode(e.target.value)}
                                    disabled={isLocked}
                                    style={{
                                        width: '100%',
                                        padding: '12px 16px',
                                        borderRadius: '8px',
                                        border: `1px solid ${accessAttempts > 0 ? '#ff6464' : isDark ? '#444' : '#ddd'}`,
                                        backgroundColor: isDark ? '#2d2d2d' : '#fff',
                                        color: isDark ? '#fff' : '#333',
                                        fontSize: '16px',
                                        outline: 'none',
                                        boxSizing: 'border-box'
                                    }}
                                    placeholder="Enter access code"
                                />
                                <Key size={20} style={{
                                    position: 'absolute',
                                    right: '16px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    color: isDark ? '#666' : '#aaa'
                                }} />
                            </div>
                            {accessAttempts > 0 && (
                                <p style={{
                                    margin: '8px 0 0 0',
                                    fontSize: '12px',
                                    color: '#ff6464'
                                }}>
                                    Invalid access code. Attempts: {accessAttempts}/3
                                </p>
                            )}
                        </div>

                        <button type="submit" style={{
                            width: '100%',
                            padding: '14px',
                            backgroundColor: '#ff6464',
                            color: 'white',
                            border: 'none',
                            borderRadius: '8px',
                            fontSize: '16px',
                            fontWeight: '600',
                            cursor: isLocked ? 'not-allowed' : 'pointer',
                            opacity: isLocked ? 0.5 : 1,
                            transition: 'background-color 0.2s',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px'
                        }} disabled={isLocked}>
                            <Lock size={20} />
                            Authenticate
                        </button>
                    </form>
                )}

                <p style={{
                    margin: '24px 0 0 0',
                    fontSize: '12px',
                    color: isDark ? '#666' : '#999',
                    textAlign: 'center'
                }}>
                    This is a secure system. Unauthorized access attempts will be logged and reported.
                </p>
            </div>
        );
    }

    // Main admin console interface
    return (
        <div className={`admin-ai-component ${className}`} style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
            backgroundColor: isDark ? '#1a1a1a' : '#ffffff',
            border: `1px solid ${isDark ? '#333' : '#eaeaea'}`
        }}>
            {/* Admin Header */}
            <div style={{
                padding: '16px',
                borderBottom: `1px solid ${isDark ? '#333' : '#eaeaea'}`,
                backgroundColor: isDark ? '#252525' : '#f9f9f9',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '8px',
                        backgroundColor: '#ff6464',
                        color: 'white'
                    }}>
                        <Shield size={24} />
                    </div>

                    <div>
                        <h2 style={{
                            margin: '0 0 4px 0',
                            fontSize: '18px',
                            fontWeight: '700',
                            color: isDark ? '#fff' : '#333',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            Taytrom Admin Console
                            <span style={{
                                fontSize: '12px',
                                padding: '2px 8px',
                                backgroundColor: isDark ? 'rgba(255, 100, 100, 0.2)' : 'rgba(255, 100, 100, 0.1)',
                                color: '#ff6464',
                                borderRadius: '12px',
                                fontWeight: '600'
                            }}>
                {detectedContextType === 'admin' ? 'Admin Mode' : 'User Mode'}
              </span>
                        </h2>
                        <p style={{
                            margin: '0',
                            fontSize: '12px',
                            color: isDark ? '#aaa' : '#666'
                        }}>
                            Enhanced AI capabilities for system administration
                        </p>
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {/* Debug Mode Toggle */}
                    <button
                        onClick={() => {
                            setDebugMode(!debugMode);
                            addToSessionLogs(`Debug mode ${!debugMode ? 'enabled' : 'disabled'}`);
                        }}
                        aria-label={`${debugMode ? 'Disable' : 'Enable'} debug mode`}
                        title={`${debugMode ? 'Disable' : 'Enable'} debug mode`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '6px',
                            borderRadius: '6px',
                            border: 'none',
                            backgroundColor: debugMode ? '#ff6464' : isDark ? '#333' : '#eaeaea',
                            color: debugMode ? 'white' : isDark ? '#ccc' : '#666',
                            cursor: 'pointer'
                        }}
                    >
                        <Terminal size={18} />
                    </button>

                    {/* Session Logs Toggle */}
                    <button
                        onClick={() => setShowSessionLogs(!showSessionLogs)}
                        aria-label={`${showSessionLogs ? 'Hide' : 'Show'} session logs`}
                        title={`${showSessionLogs ? 'Hide' : 'Show'} session logs`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '6px',
                            borderRadius: '6px',
                            border: 'none',
                            backgroundColor: showSessionLogs ? '#ff6464' : isDark ? '#333' : '#eaeaea',
                            color: showSessionLogs ? 'white' : isDark ? '#ccc' : '#666',
                            cursor: 'pointer'
                        }}
                    >
                        <Database size={18} />
                    </button>

                    {/* Admin Knowledge Refresh */}
                    <button
                        onClick={loadAdminKnowledge}
                        aria-label="Refresh admin knowledge"
                        title="Refresh admin knowledge"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '6px',
                            borderRadius: '6px',
                            border: 'none',
                            backgroundColor: isAdminKnowledgeLoaded ? '#4CAF50' : isDark ? '#333' : '#eaeaea',
                            color: isAdminKnowledgeLoaded ? 'white' : isDark ? '#ccc' : '#666',
                            cursor: 'pointer'
                        }}
                    >
                        <Database size={18} />
                    </button>

                    {/* Export Conversation */}
                    <button
                        onClick={exportConversation}
                        aria-label="Export conversation"
                        title="Export conversation"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '6px',
                            borderRadius: '6px',
                            border: 'none',
                            backgroundColor: isDark ? '#333' : '#eaeaea',
                            color: isDark ? '#ccc' : '#666',
                            cursor: 'pointer'
                        }}
                    >
                        <Download size={18} />
                    </button>

                    {/* Clear Conversation */}
                    <button
                        onClick={handleClearConversation}
                        aria-label="Clear conversation"
                        title="Clear conversation"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '6px',
                            borderRadius: '6px',
                            border: 'none',
                            backgroundColor: isDark ? '#333' : '#eaeaea',
                            color: isDark ? '#ccc' : '#666',
                            cursor: 'pointer'
                        }}
                    >
                        <Trash size={18} />
                    </button>

                    {/* Sign Out */}
                    <button
                        onClick={handleSignOut}
                        aria-label="Sign out"
                        title="Sign out"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '8px 12px',
                            borderRadius: '6px',
                            border: 'none',
                            backgroundColor: '#ff6464',
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '500',
                            gap: '6px'
                        }}
                    >
                        <Lock size={16} />
                        Sign Out
                    </button>
                </div>
            </div>

            {/* Admin Configuration Panel */}
            <div style={{
                padding: '12px 16px',
                backgroundColor: isDark ? '#252525' : '#f5f5f5',
                borderBottom: `1px solid ${isDark ? '#333' : '#ddd'}`
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: '12px'
                }}>
                    {/* Operational Mode */}
                    <div style={{ flex: '1', minWidth: '200px' }}>
                        <label style={{
                            display: 'block',
                            fontSize: '12px',
                            fontWeight: '500',
                            marginBottom: '4px',
                            color: isDark ? '#aaa' : '#666'
                        }}>
                            Operational Mode
                        </label>
                        <select
                            value={operationalMode}
                            onChange={(e) => setOperationalMode(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '8px 12px',
                                backgroundColor: isDark ? '#333' : '#fff',
                                color: isDark ? '#fff' : '#333',
                                border: `1px solid ${isDark ? '#444' : '#ddd'}`,
                                borderRadius: '6px',
                                fontSize: '14px'
                            }}
                        >
                            {Object.entries(ADMIN_OPERATIONAL_MODES).map(([key, value]) => (
                                <option key={key} value={key}>
                                    {value.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Thinking Budget */}
                    <div style={{ flex: '1', minWidth: '200px' }}>
                        <label style={{
                            display: 'block',
                            fontSize: '12px',
                            fontWeight: '500',
                            marginBottom: '4px',
                            color: isDark ? '#aaa' : '#666'
                        }}>
                            Thinking Budget
                        </label>
                        <select
                            value={thinkingBudget}
                            onChange={(e) => setThinkingBudget(Number(e.target.value))}
                            style={{
                                width: '100%',
                                padding: '8px 12px',
                                backgroundColor: isDark ? '#333' : '#fff',
                                color: isDark ? '#fff' : '#333',
                                border: `1px solid ${isDark ? '#444' : '#ddd'}`,
                                borderRadius: '6px',
                                fontSize: '14px'
                            }}
                        >
                            {ADMIN_THINKING_BUDGET_OPTIONS.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Advanced Controls Toggle */}
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <button
                            onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                                padding: '8px 12px',
                                backgroundColor: showAdvancedOptions ? '#ff6464' : 'transparent',
                                color: showAdvancedOptions ? 'white' : isDark ? '#ccc' : '#666',
                                border: `1px solid ${showAdvancedOptions ? '#ff6464' : isDark ? '#444' : '#ddd'}`,
                                borderRadius: '6px',
                                fontSize: '13px',
                                fontWeight: '500',
                                cursor: 'pointer'
                            }}
                        >
                            <Sparkles size={16} />
                            {showAdvancedOptions ? 'Hide Advanced' : 'Show Advanced'}
                        </button>
                    </div>
                </div>

                {/* Advanced Options Panel - Include only when needed */}
                {showAdvancedOptions && (
                    <div style={{
                        marginTop: '12px',
                        padding: '12px',
                        backgroundColor: isDark ? '#2a2a2a' : '#f0f0f0',
                        borderRadius: '6px',
                        border: `1px solid ${isDark ? '#3a3a3a' : '#ddd'}`
                    }}>
                        {/* Advanced options content */}
                        {/* This would include temperature controls, system prompt editor, etc. */}
                    </div>
                )}
            </div>

            {/* Session Logs Panel */}
            {showSessionLogs && (
                <div style={{
                    padding: '12px 16px',
                    backgroundColor: isDark ? '#1a1a1a' : '#f9f9f9',
                    maxHeight: '200px',
                    overflow: 'auto',
                    borderBottom: `1px solid ${isDark ? '#333' : '#ddd'}`,
                    fontSize: '12px',
                    fontFamily: 'monospace'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '8px'
                    }}>
                        <h3 style={{
                            margin: '0',
                            fontSize: '13px',
                            fontWeight: '600',
                            color: isDark ? '#ccc' : '#666'
                        }}>
                            Session Logs
                        </h3>
                        <div>
                            <button
                                onClick={() => {
                                    setSessionLogs([]);
                                    sessionLogsRef.current = [];
                                }}
                                style={{
                                    padding: '2px 6px',
                                    backgroundColor: 'transparent',
                                    border: `1px solid ${isDark ? '#444' : '#ddd'}`,
                                    borderRadius: '4px',
                                    fontSize: '11px',
                                    color: isDark ? '#ccc' : '#666',
                                    cursor: 'pointer'
                                }}
                            >
                                Clear Logs
                            </button>
                        </div>
                    </div>

                    {sessionLogs.length === 0 ? (
                        <div style={{
                            padding: '8px',
                            color: isDark ? '#666' : '#999',
                            fontStyle: 'italic',
                            textAlign: 'center'
                        }}>
                            No session logs recorded yet
                        </div>
                    ) : (
                        <div>
                            {sessionLogs.map((log, index) => {
                                // Determine log entry color based on type
                                const logColor = log.type === 'error' ? '#ff6464' :
                                    log.type === 'warning' ? '#ffb347' :
                                        isDark ? '#8bb6ac' : '#36887a';

                                return (
                                    <div key={index} style={{
                                        marginBottom: '4px',
                                        color: logColor,
                                        display: 'flex',
                                        fontSize: '11px'
                                    }}>
                    <span style={{
                        flexShrink: 0,
                        color: isDark ? '#666' : '#aaa',
                        marginRight: '8px'
                    }}>
                      {new Date(log.timestamp).toLocaleTimeString()}
                    </span>
                                        <span>{log.message}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}

            {/* Messages Container */}
            <div
                ref={messageContainerRef}
                style={{
                    flex: 1,
                    overflow: 'auto',
                    padding: '16px',
                    backgroundColor: isDark ? '#1e1e1e' : '#f5f5f5'
                }}
            >
                {messages.map((message) => {
                    const isUserMessage = message.role === 'user';

                    return (
                        <div
                            key={message.id}
                            style={{
                                display: 'flex',
                                justifyContent: isUserMessage ? 'flex-end' : 'flex-start',
                                marginBottom: '12px'
                            }}
                        >
                            <div style={{
                                maxWidth: '80%',
                                backgroundColor: isUserMessage
                                    ? '#ff6464'
                                    : isDark ? '#2d2d2d' : '#fff',
                                color: isUserMessage
                                    ? '#fff'
                                    : isDark ? '#fff' : '#333',
                                padding: '12px 16px',
                                borderRadius: isUserMessage
                                    ? '16px 4px 16px 16px'
                                    : '4px 16px 16px 16px',
                                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
                            }}>
                                {Specialist_Remainder_Chat_AI_Admin.renderMessageContent(message, isDark)}

                                {/* Display thinking content if available */}
                                {!isUserMessage && message.thinkingContent && (
                                    <div style={{
                                        marginTop: '12px',
                                        padding: '8px 12px',
                                        backgroundColor: isDark ? 'rgba(255, 100, 100, 0.1)' : 'rgba(255, 100, 100, 0.05)',
                                        borderLeft: '3px solid #ff6464',
                                        borderRadius: '4px',
                                        color: isDark ? '#ccc' : '#555',
                                        whiteSpace: 'pre-wrap',
                                        fontFamily: 'monospace',
                                        fontSize: '12px'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            marginBottom: '6px',
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                            color: isDark ? '#aaa' : '#666'
                                        }}>
                                            <Brain size={14} color="#ff6464" />
                                            <span>Admin Thinking Process ({message.thinkingBudget} tokens)</span>
                                        </div>
                                        {message.thinkingContent}
                                    </div>
                                )}

                                {/* Context badges */}
                                {!isUserMessage && (
                                    <div style={{
                                        marginTop: '8px',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: '6px',
                                        flexWrap: 'wrap'
                                    }}>
                                        {/* Mode badge */}
                                        <div style={{
                                            fontSize: '11px',
                                            padding: '2px 6px',
                                            borderRadius: '4px',
                                            backgroundColor: isDark ? 'rgba(255, 100, 100, 0.15)' : 'rgba(255, 100, 100, 0.1)',
                                            color: '#ff6464',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '4px'
                                        }}>
                                            <Zap size={10} />
                                            <span>{ADMIN_OPERATIONAL_MODES[message.operationalMode]?.name || message.operationalMode}</span>
                                        </div>

                                        {/* Context type badge */}
                                        {message.detectedContextType && (
                                            <div style={{
                                                fontSize: '11px',
                                                padding: '2px 6px',
                                                borderRadius: '4px',
                                                backgroundColor: message.detectedContextType === 'admin'
                                                    ? isDark ? 'rgba(255, 100, 100, 0.15)' : 'rgba(255, 100, 100, 0.1)'
                                                    : isDark ? 'rgba(100, 100, 255, 0.15)' : 'rgba(100, 100, 255, 0.1)',
                                                color: message.detectedContextType === 'admin' ? '#ff6464' : '#6464ff',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px'
                                            }}>
                                                <Shield size={10} />
                                                <span>{message.detectedContextType === 'admin' ? 'Admin Mode' : 'User Mode'}</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}

                {/* Loading indicator */}
                {isLoading && !isPending && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginBottom: '12px'
                    }}>
                        <div style={{
                            backgroundColor: isDark ? '#2d2d2d' : '#fff',
                            color: isDark ? '#ccc' : '#666',
                            padding: '12px 16px',
                            borderRadius: '4px 16px 16px 16px',
                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            <Loader size={16} style={{ animation: 'spin 1s linear infinite' }} color="#ff6464" />
                            <span>Generating admin response...</span>
                        </div>
                    </div>
                )}

                {/* Thinking in progress */}
                {isPending && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginBottom: '12px'
                    }}>
                        <div style={{
                            backgroundColor: isDark ? '#2d2d2d' : '#fff',
                            color: isDark ? '#ccc' : '#666',
                            padding: '12px 16px',
                            borderRadius: '4px 16px 16px 16px',
                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                            maxWidth: '80%'
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                                marginBottom: '6px',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                color: isDark ? '#aaa' : '#666'
                            }}>
                                <Brain size={16} style={{ color: '#ff6464' }} />
                                <span>Admin Thinking... (Enhanced)</span>
                            </div>
                            <div style={{
                                padding: '8px 12px',
                                backgroundColor: isDark ? 'rgba(255, 100, 100, 0.1)' : 'rgba(255, 100, 100, 0.05)',
                                borderLeft: '3px solid #ff6464',
                                borderRadius: '4px',
                                color: isDark ? '#ccc' : '#555',
                                whiteSpace: 'pre-wrap',
                                fontFamily: 'monospace',
                                fontSize: '12px',
                                maxHeight: '300px',
                                overflow: 'auto'
                            }}>
                                {currentThoughts || 'Processing your administrative request...'}
                            </div>
                        </div>
                    </div>
                )}

                {/* Error message */}
                {error && (
                    <div style={{
                        backgroundColor: isDark ? '#3a2222' : '#ffebee',
                        color: isDark ? '#ff8a80' : '#c62828',
                        padding: '12px 16px',
                        borderRadius: '8px',
                        margin: '12px 0',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <AlertTriangle size={18} />
                        <span>{error}</span>
                        <button
                            onClick={() => setError(null)}
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                color: isDark ? '#ff8a80' : '#c62828',
                                cursor: 'pointer',
                                fontSize: '12px',
                                textDecoration: 'underline',
                                marginLeft: 'auto'
                            }}
                        >
                            Dismiss
                        </button>
                    </div>
                )}

                <div ref={messagesEndRef} />
            </div>

            {/* Input area with image upload */}
            <form
                onSubmit={handleSubmit}
                style={{
                    padding: '16px',
                    borderTop: `1px solid ${isDark ? '#333' : '#eaeaea'}`,
                    backgroundColor: isDark ? '#252525' : '#f9f9f9',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                }}
                onPaste={handlePaste}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >
                <div style={{ position: 'relative' }}>
          <textarea
              ref={inputRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit(e);
                  }
              }}
              placeholder="Enter administrative query or command..."
              disabled={isLoading}
              style={{
                  width: '100%',
                  padding: '12px 40px 12px 12px',
                  borderRadius: '8px',
                  border: `1px solid ${isDark ? '#444' : '#ddd'}`,
                  backgroundColor: isDark ? '#333' : '#fff',
                  color: isDark ? '#fff' : '#333',
                  resize: 'none',
                  minHeight: '44px',
                  maxHeight: '120px',
                  outline: 'none',
                  fontFamily: 'inherit'
              }}
          />

                    <button
                        type="submit"
                        disabled={isLoading || (!input.trim() && attachedImages.length === 0)}
                        style={{
                            position: 'absolute',
                            right: '8px',
                            bottom: '8px',
                            backgroundColor: '#ff6464',
                            color: 'white',
                            border: 'none',
                            borderRadius: '50%',
                            width: '28px',
                            height: '28px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: (!input.trim() && attachedImages.length === 0 || isLoading) ? 'not-allowed' : 'pointer',
                            opacity: (!input.trim() && attachedImages.length === 0 || isLoading) ? 0.5 : 1
                        }}
                    >
                        <SendHorizontal size={16} />
                    </button>
                </div>
                {/* Image attachment controls */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <div style={{
                        display: 'flex',
                        gap: '8px'
                    }}>
                        <button
                            onClick={() => fileInputRef.current?.click()}
                            title="Attach image"
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                color: isDark ? '#aaa' : '#666',
                                cursor: 'pointer',
                                padding: '4px',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ImagePlus size={18} />
                        </button>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleFileSelect}
                            style={{ display: 'none' }}
                        />
                        <button
                            onClick={() => navigator.clipboard.read().then(items => {
                                for (const item of items) {
                                    if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
                                        item.getType(item.types.find(type => type.startsWith('image/'))).then(blob => {
                                            const file = new File([blob], "clipboard-image.png", { type: blob.type });
                                            const event = { target: { files: [file] } };
                                            handleFileSelect(event);
                                        });
                                    }
                                }
                            }).catch(err => {
                                addToSessionLogs(`Error pasting from clipboard: ${err.message}`, 'error');
                            })}
                            title="Paste from clipboard"
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                color: isDark ? '#aaa' : '#666',
                                cursor: 'pointer',
                                padding: '4px',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Clipboard size={18} />
                        </button>
                    </div>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '12px',
                        color: isDark ? '#666' : '#999'
                    }}>
                        {isImageUploading ? (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <Loader size={12} style={{ animation: 'spin 1s linear infinite' }} />
                                <span>Uploading image...</span>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <Coffee size={12} />
                                <span>Administrative access mode active</span>
                            </div>
                        )}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <span>Press Enter to send</span>
                    </div>
                </div>

                {/* Render attached images preview */}
                {attachedImages.length > 0 && (
                    <div style={{
                        marginTop: '8px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '8px'
                    }}>
                        {attachedImages.map(image => (
                            <div
                                key={image.id}
                                style={{
                                    position: 'relative',
                                    width: '80px',
                                    height: '80px',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    backgroundColor: isDark ? '#333' : '#f0f0f0'
                                }}
                            >
                                <img
                                    src={image.data}
                                    alt={image.name}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                                <button
                                    onClick={() => removeAttachedImage(image.id)}
                                    style={{
                                        position: 'absolute',
                                        top: '2px',
                                        right: '2px',
                                        backgroundColor: isDark ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.6)',
                                        color: isDark ? '#fff' : '#333',
                                        border: 'none',
                                        borderRadius: '50%',
                                        width: '20px',
                                        height: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        padding: 0
                                    }}
                                >
                                    <X size={14} />
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </form>

            {/* Admin File Viewer Modal */}
            {viewingAdminFile && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    zIndex: 1000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px'
                }}>
                    <div style={{
                        backgroundColor: isDark ? '#1a1a1a' : '#ffffff',
                        borderRadius: '12px',
                        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
                        width: '90%',
                        maxWidth: '1200px',
                        maxHeight: '90vh',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div style={{
                            padding: '16px',
                            borderBottom: `1px solid ${isDark ? '#333' : '#eaeaea'}`,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}>
                                <FileText size={20} color={isDark ? '#ccc' : '#666'} />
                                <h3 style={{
                                    margin: 0,
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    color: isDark ? '#fff' : '#333'
                                }}>
                                    {viewingAdminFile.name}
                                </h3>
                            </div>
                            <button
                                onClick={() => setViewingAdminFile(null)}
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: isDark ? '#aaa' : '#666',
                                    fontSize: '22px'
                                }}
                            >
                                ×
                            </button>
                        </div>
                        <div style={{
                            flex: 1,
                            overflow: 'auto',
                            padding: '16px',
                            backgroundColor: isDark ? '#2d2d2d' : '#f9f9f9'
                        }}>
        <pre style={{
            margin: 0,
            fontFamily: 'monospace',
            fontSize: '14px',
            lineHeight: 1.5,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
            color: isDark ? '#ddd' : '#333'
        }}>
          {viewingAdminFile.content}
        </pre>
                        </div>
                    </div>
                </div>
            )}

            <style jsx>{`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`}</style>
        </div>
    );
};

export default Compiler_Chat_AI_Admin;

