import React, { useState, useRef, useEffect } from 'react';
import {
    Paperclip, X, File, Image, FileText, AlertCircle, Upload,
    Check, Trash2, Maximize2, Minimize2, Eye, DownloadCloud,
    Clock, Loader
} from 'lucide-react';
import styles from './Attachments_Specialists_Writing.module.css';

/**
 * @component Attachments_Specialists_Writing
 *
 * @description
 * A robust component for handling file attachments in the writing environment.
 * Follows Taytrom's Enhanced CSS System for a luxurious, immersive UX.
 * Supports multiple file types, drag-and-drop, previews, and integration with AI.
 */
const Attachments_Specialists_Writing = ({
                                             orchestrator = null,
                                             onAttach = () => {},
                                             onRemove = () => {},
                                             onProcessAttachment = () => {},
                                             maxSize = 10 * 1024 * 1024, // 10MB default
                                             maxFiles = 10,
                                             allowedTypes = ['image/*', 'application/pdf', 'text/plain', 'application/msword',
                                                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document-panel',
                                                 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                 'text/csv'],
                                             attachments = [],
                                             setAttachments = null,
                                             isProcessing = false,
                                             isDark = false,
                                             isCompact = false,
                                             sectionId = null,
                                             className = ""
                                         }) => {
    // State for managing component's internal state
    const [isDragging, setIsDragging] = useState(false);
    const [errors, setErrors] = useState({});
    const [expandedPreview, setExpandedPreview] = useState(null);
    const [uploadProgress, setUploadProgress] = useState({});
    const [processingAttachments, setProcessingAttachments] = useState([]);
    const [showAttachments, setShowAttachments] = useState(true);

    // Refs
    const fileInputRef = useRef(null);
    const dropZoneRef = useRef(null);

    // Track mutable state for attachments if not externally controlled
    const [internalAttachments, setInternalAttachments] = useState([]);

    // Use either controlled or internal attachments
    const currentAttachments = setAttachments ? attachments : internalAttachments;
    const updateAttachments = setAttachments || setInternalAttachments;

    // Handle drag events for the dropzone
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Only set dragging to false if the drag leaves the dropzone
        if (dropZoneRef.current && !dropZoneRef.current.contains(e.relatedTarget)) {
            setIsDragging(false);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    // Check if file is allowed based on type
    const isFileTypeAllowed = (file) => {
        if (!allowedTypes || allowedTypes.length === 0) return true;

        // Handle wildcards like 'image/*'
        return allowedTypes.some(type => {
            if (type.endsWith('/*')) {
                const mainType = type.split('/')[0];
                return file.type.startsWith(`${mainType}/`);
            }
            return file.type === type;
        });
    };

    // Validate file before processing
    const validateFile = (file) => {
        const errorMessages = {};

        // Check file size
        if (file.size > maxSize) {
            errorMessages.size = `File exceeds maximum size (${Math.round(maxSize / 1024 / 1024)}MB)`;
        }

        // Check file type
        if (!isFileTypeAllowed(file)) {
            errorMessages.type = 'File type not allowed';
        }

        return Object.keys(errorMessages).length ? errorMessages : null;
    };

    // Generate a preview for the file if possible
    const generatePreview = (file) => {
        return new Promise((resolve) => {
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve({
                        url: e.target.result,
                        type: 'image'
                    });
                };
                reader.readAsDataURL(file);
            } else {
                // For non-image files, resolve with file type icon info
                const fileTypeInfo = getFileTypeInfo(file);
                resolve({
                    url: null,
                    type: 'file',
                    icon: fileTypeInfo.icon,
                    color: fileTypeInfo.color
                });
            }
        });
    };

    // Get file type information for icon display
    const getFileTypeInfo = (file) => {
        const extension = file.name.split('.').pop().toLowerCase();

        // Default file type information
        let fileTypeInfo = {
            icon: <File size={24} />,
            color: '#6B7280' // gray-500
        };

        // Document types
        if (['doc', 'docx', 'txt', 'rtf', 'odt'].includes(extension)) {
            fileTypeInfo = {
                icon: <FileText size={24} />,
                color: '#3B82F6' // blue-500
            };
        }
        // Spreadsheet types
        else if (['xls', 'xlsx', 'csv'].includes(extension)) {
            fileTypeInfo = {
                icon: <FileText size={24} />,
                color: '#22C55E' // green-500
            };
        }
        // PDF
        else if (extension === 'pdf') {
            fileTypeInfo = {
                icon: <FileText size={24} />,
                color: '#EF4444' // red-500
            };
        }
        // Image types
        else if (['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(extension)) {
            fileTypeInfo = {
                icon: <Image size={24} />,
                color: '#8B5CF6' // purple-500
            };
        }

        return fileTypeInfo;
    };

    // Process files for upload
    const processFiles = async (fileList) => {
        const files = Array.from(fileList);

        // Check if adding these files would exceed the max count
        if (currentAttachments.length + files.length > maxFiles) {
            setErrors({
                ...errors,
                maxFiles: `Cannot add more than ${maxFiles} files`
            });

            // Set a timeout to clear the error after a few seconds
            setTimeout(() => {
                setErrors((prev) => {
                    const newErrors = {...prev};
                    delete newErrors.maxFiles;
                    return newErrors;
                });
            }, 3000);

            return;
        }

        // Process each file
        for (const file of files) {
            // Validate file
            const validationErrors = validateFile(file);
            if (validationErrors) {
                setErrors((prev) => ({
                    ...prev,
                    [file.name]: validationErrors
                }));

                // Clear error after a few seconds
                setTimeout(() => {
                    setErrors((prev) => {
                        const newErrors = {...prev};
                        delete newErrors[file.name];
                        return newErrors;
                    });
                }, 3000);

                continue;
            }

            // Generate a unique ID for the attachment
            const attachmentId = `attachment_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;

            // Set initial progress
            setUploadProgress((prev) => ({
                ...prev,
                [attachmentId]: 0
            }));

            try {
                // Generate preview
                const preview = await generatePreview(file);

                // Create attachment object
                const newAttachment = {
                    id: attachmentId,
                    file,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    preview,
                    uploadDate: new Date(),
                    status: 'uploading'
                };

                // Add to attachments
                updateAttachments((prev) => [...prev, newAttachment]);

                // Simulate upload progress
                simulateUploadProgress(attachmentId);

                // Call onAttach callback
                onAttach(newAttachment);

            } catch (error) {
                console.error("Error processing file:", error);
                setErrors((prev) => ({
                    ...prev,
                    [file.name]: { processing: 'Error processing file' }
                }));

                // Clear error after a few seconds
                setTimeout(() => {
                    setErrors((prev) => {
                        const newErrors = {...prev};
                        delete newErrors[file.name];
                        return newErrors;
                    });
                }, 3000);
            }
        }
    };

    // Simulate upload progress - in a real implementation, this would be replaced with actual upload logic
    const simulateUploadProgress = (attachmentId) => {
        let progress = 0;
        const interval = setInterval(() => {
            progress += Math.random() * 15;
            if (progress >= 100) {
                progress = 100;
                clearInterval(interval);

                // Update attachment status after "upload" completes
                setUploadProgress((prev) => ({
                    ...prev,
                    [attachmentId]: progress
                }));

                updateAttachments((prev) =>
                    prev.map(attachment =>
                        attachment.id === attachmentId
                            ? { ...attachment, status: 'uploaded' }
                            : attachment
                    )
                );

                // Clear progress after a moment
                setTimeout(() => {
                    setUploadProgress((prev) => {
                        const newProgress = {...prev};
                        delete newProgress[attachmentId];
                        return newProgress;
                    });
                }, 1000);

            } else {
                setUploadProgress((prev) => ({
                    ...prev,
                    [attachmentId]: progress
                }));
            }
        }, 200);
    };

    // Handle file drop
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            processFiles(e.dataTransfer.files);
        }
    };

    // Handle file input change
    const handleFileInputChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            processFiles(e.target.files);

            // Reset the input value so the same file can be selected again
            e.target.value = '';
        }
    };

    // Trigger file browser dialog
    const handleBrowseClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Remove an attachment
    const handleRemoveAttachment = (attachmentId) => {
        const attachmentToRemove = currentAttachments.find(a => a.id === attachmentId);

        updateAttachments((prev) => prev.filter(a => a.id !== attachmentId));

        if (expandedPreview === attachmentId) {
            setExpandedPreview(null);
        }

        // Call onRemove callback
        if (attachmentToRemove) {
            onRemove(attachmentToRemove);
        }
    };

    // Toggle attachment preview
    const toggleExpandPreview = (attachmentId) => {
        setExpandedPreview(current => current === attachmentId ? null : attachmentId);
    };

    // Process an attachment with AI
    const handleProcessAttachment = async (attachmentId) => {
        const attachment = currentAttachments.find(a => a.id === attachmentId);

        if (!attachment) return;

        // Mark as processing
        setProcessingAttachments((prev) => [...prev, attachmentId]);

        try {
            // Update attachment status
            updateAttachments((prev) =>
                prev.map(a =>
                    a.id === attachmentId
                        ? { ...a, status: 'processing' }
                        : a
                )
            );

            // Call the onProcessAttachment callback
            await onProcessAttachment(attachment, sectionId);

            // Update status after processing
            updateAttachments((prev) =>
                prev.map(a =>
                    a.id === attachmentId
                        ? { ...a, status: 'processed' }
                        : a
                )
            );

        } catch (error) {
            console.error("Error processing attachment with AI:", error);

            // Update status to error
            updateAttachments((prev) =>
                prev.map(a =>
                    a.id === attachmentId
                        ? { ...a, status: 'error', error: error.message }
                        : a
                )
            );

            setErrors((prev) => ({
                ...prev,
                [attachment.name]: { processing: 'Error processing with AI' }
            }));

            // Clear error after a few seconds
            setTimeout(() => {
                setErrors((prev) => {
                    const newErrors = {...prev};
                    delete newErrors[attachment.name];
                    return newErrors;
                });
            }, 3000);
        } finally {
            // Remove from processing list
            setProcessingAttachments((prev) => prev.filter(id => id !== attachmentId));
        }
    };

    // Handle processing all attachments
    const processAllAttachments = async () => {
        // Get all attachments that are uploaded but not processed
        const attachmentsToProcess = currentAttachments.filter(
            a => a.status === 'uploaded' || a.status === 'error'
        );

        for (const attachment of attachmentsToProcess) {
            await handleProcessAttachment(attachment.id);
        }
    };

    // Keyboard accessibility for the attachments list
    const handleKeyDown = (e, action, attachmentId) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();

            switch (action) {
                case 'remove':
                    handleRemoveAttachment(attachmentId);
                    break;
                case 'expand':
                    toggleExpandPreview(attachmentId);
                    break;
                case 'process':
                    handleProcessAttachment(attachmentId);
                    break;
                default:
                    break;
            }
        }
    };

    // Format file size for display
    const formatFileSize = (bytes) => {
        if (bytes < 1024) return bytes + ' B';
        else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
        else return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
    };

    // Get status display info
    const getStatusDisplay = (status) => {
        switch (status) {
            case 'uploading':
                return { icon: <Upload size={16} />, text: 'Uploading', color: '#6B7280' };
            case 'uploaded':
                return { icon: <Check size={16} />, text: 'Uploaded', color: '#22C55E' };
            case 'processing':
                return { icon: <Loader size={16} className={styles['taytrom-attachments-spin']} />, text: 'Processing', color: '#3B82F6' };
            case 'processed':
                return { icon: <Check size={16} />, text: 'Processed', color: '#22C55E' };
            case 'error':
                return { icon: <AlertCircle size={16} />, text: 'Error', color: '#EF4444' };
            default:
                return { icon: <Clock size={16} />, text: 'Pending', color: '#6B7280' };
        }
    };

    // Render expanded preview modal
    const renderExpandedPreview = () => {
        if (!expandedPreview) return null;

        const attachment = currentAttachments.find(a => a.id === expandedPreview);
        if (!attachment) return null;

        return (
            <div
                className={`${styles['taytrom-modal-overlay']} ${styles['taytrom-modal-overlay--visible']}`}
                onClick={() => setExpandedPreview(null)}
            >
                <div
                    className={`${styles['taytrom-modal']} ${styles['taytrom-modal--visible']}`}
                    onClick={e => e.stopPropagation()}
                >
                    <div className={styles['taytrom-modal-header']}>
                        <h3 className={styles['taytrom-modal-title']}>{attachment.name}</h3>
                        <button
                            onClick={() => setExpandedPreview(null)}
                            className={styles['taytrom-modal-close']}
                            aria-label="Close preview"
                        >
                            <X size={20} />
                        </button>
                    </div>

                    <div className={styles['taytrom-modal-content']}>
                        {attachment.preview.type === 'image' ? (
                            <img
                                src={attachment.preview.url}
                                alt={attachment.name}
                                className={styles['taytrom-modal-image']}
                            />
                        ) : (
                            <div className={styles['taytrom-modal-file']}>
                                <div
                                    className={styles['taytrom-modal-icon-container']}
                                >
                                    {React.cloneElement(attachment.preview.icon, {
                                        size: 40,
                                        color: attachment.preview.color
                                    })}
                                </div>
                                <p className={styles['taytrom-modal-filename']}>
                                    {attachment.name}
                                </p>
                                <p className={styles['taytrom-modal-filesize']}>
                                    {formatFileSize(attachment.size)}
                                </p>
                            </div>
                        )}
                    </div>

                    <div className={styles['taytrom-modal-footer']}>
                        <button
                            onClick={() => setExpandedPreview(null)}
                            className={styles['taytrom-button-modal']}
                        >
                            Close
                        </button>
                        {attachment.status === 'uploaded' && (
                            <button
                                onClick={() => {
                                    handleProcessAttachment(attachment.id);
                                    setExpandedPreview(null);
                                }}
                                className={`${styles['taytrom-button-modal']} ${styles['taytrom-button-modal-primary']}`}
                                disabled={isProcessing}
                            >
                                Process with AI
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    // Render the attachment list
    const renderAttachmentsList = () => {
        if (currentAttachments.length === 0) return null;

        if (!showAttachments && !isCompact) {
            return (
                <div className={styles['taytrom-attachments-show-button-container']}>
                    <button
                        onClick={() => setShowAttachments(true)}
                        className={styles['taytrom-attachments-show-button']}
                    >
                        <Paperclip size={14} className={styles['taytrom-attachments-show-icon']} />
                        Show {currentAttachments.length} attachment{currentAttachments.length !== 1 ? 's' : ''}
                    </button>
                </div>
            );
        }

        if (isCompact) {
            // Compact view for limited space
            return (
                <div className={styles['taytrom-attachments-compact-list']}>
                    {currentAttachments.map(attachment => (
                        <div
                            key={attachment.id}
                            className={styles['taytrom-attachments-compact-item']}
                        >
                            {attachment.preview.type === 'image'
                                ? <Image size={14} className={styles['taytrom-attachments-compact-icon']} color="#8B5CF6" />
                                : <FileText size={14} className={styles['taytrom-attachments-compact-icon']} color="#3B82F6" />
                            }
                            <span className={styles['taytrom-attachments-compact-name']} title={attachment.name}>{attachment.name}</span>
                            <button
                                onClick={() => handleRemoveAttachment(attachment.id)}
                                className={styles['taytrom-attachments-compact-remove']}
                                aria-label={`Remove ${attachment.name}`}
                            >
                                <X size={14} />
                            </button>
                        </div>
                    ))}
                </div>
            );
        }

        // Full attachment list
        return (
            <div className={styles['taytrom-attachments-list-container']}>
                <div className={styles['taytrom-attachments-list-header']}>
                    <span className={styles['taytrom-attachments-list-title']}>
                        <Paperclip size={16} style={{ marginRight: '0.375rem' }} />
                        Attachments ({currentAttachments.length})
                    </span>
                    <div className={styles['taytrom-attachments-list-actions']}>
                        <button
                            onClick={processAllAttachments}
                            disabled={isProcessing || currentAttachments.every(a => a.status === 'processed')}
                            className={`${styles['taytrom-button-process-all']} ${
                                isProcessing || currentAttachments.every(a => a.status === 'processed')
                                    ? styles['taytrom-button-process-all--disabled']
                                    : ''
                            }`}
                            aria-label="Process all attachments"
                        >
                            Process All
                        </button>
                        <button
                            onClick={() => setShowAttachments(false)}
                            className={styles['taytrom-button-toggle']}
                            aria-label="Hide attachments"
                        >
                            <Minimize2 size={16} />
                        </button>
                    </div>
                </div>

                <ul className={styles['taytrom-attachments-list']}>
                    {currentAttachments.map(attachment => {
                        const progress = uploadProgress[attachment.id] || 0;
                        const isUploading = attachment.status === 'uploading';
                        const isCurrentlyProcessing = processingAttachments.includes(attachment.id);
                        const statusDisplay = getStatusDisplay(attachment.status);

                        return (
                            <li
                                key={attachment.id}
                                className={styles['taytrom-attachments-item']}
                            >
                                <div className={styles['taytrom-attachments-item-content']}>
                                    {/* Preview thumbnail */}
                                    <div
                                        className={styles['taytrom-attachments-thumbnail']}
                                        style={{ backgroundColor: attachment.preview.type === 'image' ? 'transparent' : `${attachment.preview.color}10` }}
                                        onClick={() => toggleExpandPreview(attachment.id)}
                                        tabIndex={0}
                                        role="button"
                                        aria-label={`Preview ${attachment.name}`}
                                        onKeyDown={(e) => handleKeyDown(e, 'expand', attachment.id)}
                                    >
                                        {attachment.preview.type === 'image' ? (
                                            <img
                                                src={attachment.preview.url}
                                                alt={attachment.name}
                                                className={styles['taytrom-attachments-thumbnail-image']}
                                            />
                                        ) : (
                                            React.cloneElement(attachment.preview.icon, {
                                                color: attachment.preview.color,
                                                className: styles['taytrom-attachments-thumbnail-icon']
                                            })
                                        )}
                                    </div>

                                    {/* File details */}
                                    <div className={styles['taytrom-attachments-details']}>
                                        <div className={styles['taytrom-attachments-item-header']}>
                                            <p className={styles['taytrom-attachments-filename']}>
                                                {attachment.name}
                                            </p>
                                            <div className={styles['taytrom-attachments-meta']}>
                                                <span
                                                    className={`${styles['taytrom-attachments-status']} ${styles[`taytrom-attachments-status--${attachment.status}`]}`}
                                                >
                                                    {React.cloneElement(statusDisplay.icon, {
                                                        className: styles['taytrom-attachments-status-icon']
                                                    })}
                                                    <span>{statusDisplay.text}</span>
                                                </span>
                                                <span className={styles['taytrom-attachments-filesize']}>
                                                    {formatFileSize(attachment.size)}
                                                </span>
                                            </div>
                                        </div>

                                        {/* Progress bar for uploading state */}
                                        {isUploading && (
                                            <div className={styles['taytrom-attachments-progress-container']}>
                                                <div
                                                    className={styles['taytrom-attachments-progress-bar']}
                                                    style={{ width: `${progress}%` }}
                                                ></div>
                                            </div>
                                        )}

                                        {/* Action buttons */}
                                        <div className={styles['taytrom-attachments-actions']}>
                                            <button
                                                onClick={() => toggleExpandPreview(attachment.id)}
                                                className={`${styles['taytrom-button-attachment-action']} ${styles['taytrom-button-preview']}`}
                                                aria-label="Preview file"
                                                onKeyDown={(e) => handleKeyDown(e, 'expand', attachment.id)}
                                                tabIndex={0}
                                            >
                                                <Eye size={14} className={styles['taytrom-button-attachment-action-icon']} />
                                                Preview
                                            </button>

                                            {(attachment.status === 'uploaded' || attachment.status === 'error') && (
                                                <button
                                                    onClick={() => handleProcessAttachment(attachment.id)}
                                                    disabled={isProcessing}
                                                    className={`${styles['taytrom-button-attachment-action']} ${styles['taytrom-button-process']} ${
                                                        isProcessing ? styles['taytrom-button-process--disabled'] : ''
                                                    }`}
                                                    aria-label="Process with AI"
                                                    onKeyDown={(e) => handleKeyDown(e, 'process', attachment.id)}
                                                    tabIndex={0}
                                                >
                                                    {isCurrentlyProcessing ? (
                                                        <Loader size={14} className={`${styles['taytrom-button-attachment-action-icon']} ${styles['taytrom-attachments-spin']}`} />
                                                    ) : (
                                                        <Upload size={14} className={styles['taytrom-button-attachment-action-icon']} />
                                                    )}
                                                    Process with AI
                                                </button>
                                            )}

                                            <button
                                                onClick={() => handleRemoveAttachment(attachment.id)}
                                                className={`${styles['taytrom-button-attachment-action']} ${styles['taytrom-button-remove']}`}
                                                aria-label="Remove file"
                                                onKeyDown={(e) => handleKeyDown(e, 'remove', attachment.id)}
                                                tabIndex={0}
                                            >
                                                <Trash2 size={14} className={styles['taytrom-button-attachment-action-icon']} />
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    // Render error messages
    const renderErrors = () => {
        const errorMessages = Object.entries(errors);
        if (errorMessages.length === 0) return null;

        return (
            <div className={styles['taytrom-attachments-errors']}>
                {errorMessages.map(([key, error]) => (
                    <div
                        key={key}
                        className={styles['taytrom-attachments-error']}
                    >
                        <AlertCircle size={16} className={styles['taytrom-attachments-error-icon']} />
                        <div className={styles['taytrom-attachments-error-content']}>
                            <p className={styles['taytrom-attachments-error-title']}>{key}</p>
                            <ul className={styles['taytrom-attachments-error-list']}>
                                {Object.entries(error).map(([errorType, message]) => (
                                    <li key={errorType} className={styles['taytrom-attachments-error-list-item']}>{message}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    // Main render function
    return (
        <div
            className={`${styles['taytrom-attachments']} ${className}`}
            data-theme={isDark ? "dark" : "light"}
        >
            {/* Hidden file input */}
            <input
                ref={fileInputRef}
                type="file"
                multiple
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
                accept={allowedTypes.join(',')}
                aria-label="Upload files"
            />

            {/* Drop zone */}
            <div
                ref={dropZoneRef}
                className={`${styles['taytrom-attachments-dropzone']} ${
                    isDragging ? styles['taytrom-attachments-dropzone--dragging'] : ''
                } ${isCompact ? styles['taytrom-attachments-dropzone--compact'] : ''}`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                tabIndex={0}
                role="button"
                aria-label="Drop files here or click to browse"
                onClick={isCompact ? handleBrowseClick : undefined}
            >
                <div className={styles['taytrom-attachments-dropzone-content']}>
                    <Paperclip
                        size={isCompact ? 24 : 32}
                        className={styles['taytrom-attachments-dropzone-icon']}
                    />

                    {!isCompact && (
                        <>
                            <p className={styles['taytrom-attachments-dropzone-text-primary']}>
                                {isDragging ? 'Drop files here' : 'Drag and drop files here'}
                            </p>
                            <p className={styles['taytrom-attachments-dropzone-text-secondary']}>
                                or <button
                                onClick={handleBrowseClick}
                                className={styles['taytrom-attachments-browse-button']}
                                type="button"
                            >
                                browse files
                            </button>
                            </p>
                            <p className={styles['taytrom-attachments-dropzone-text-secondary']}>
                                Max size: {Math.round(maxSize / 1024 / 1024)}MB •
                                Supported formats: images, documents, spreadsheets, PDFs
                            </p>
                        </>
                    )}

                    {isCompact && (
                        <button
                            onClick={handleBrowseClick}
                            className={styles['taytrom-attachments-browse-button']}
                            type="button"
                        >
                            Add files
                        </button>
                    )}
                </div>

                {/* Processing overlay */}
                {isProcessing && (
                    <div className={`${styles['taytrom-attachments-loading-overlay']} ${styles['taytrom-attachments-loading-overlay--visible']}`}>
                        <Loader size={32} className={`${styles['taytrom-attachments-loading-spinner']} ${styles['taytrom-attachments-spin']}`} />
                    </div>
                )}
            </div>

            {/* Error messages */}
            {renderErrors()}

            {/* Attachment list */}
            {renderAttachmentsList()}

            {/* Expanded preview modal */}
            {renderExpandedPreview()}
        </div>
    );
};

export default Attachments_Specialists_Writing;