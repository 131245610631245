// Theme-Aware Container Component
// This can go in your /SCOPE/templates-scope/TANK/generalWebpages/ATOM/containers/primary/ folder

import React, { useContext } from "react";
import { ThemeContext } from "@/FullDevApp.jsx";
import styles from '@/TACS2.module.css';

// Original Primary_Containers_GeneralWebpages_Templates import
import OriginalContainer from "@/templates-scope/containers/primary/Primary_Containers_GeneralWebpages_Templates.jsx";

// Create a themed wrapper around the original container
const ThemedContainer = React.forwardRef((props, ref) => {
    const { isDark } = useContext(ThemeContext);

    // Extract variant to apply appropriate themed background
    const { variant, style = {}, className = "", ...otherProps } = props;

    // Apply theme-specific background styles based on variant
    const themedBackgroundStyles = {
        // For section variant - typically content steps
        section: isDark
            ? { background: '#121218', width: '100%', maxWidth: '100%' }
            : { background: 'rgba(255, 255, 255, 0.3)', width: '100%', maxWidth: '100%' },

        // For full variant - typically full page/screen containers
        full: isDark
            ? { background: 'rgba(15, 15, 20, 0.95)', width: '100%', maxWidth: '100%' }
            : { background: 'rgba(250, 250, 255, 0.8)', width: '100%', maxWidth: '100%' },

        // Add more variants as needed
        // ...
    };

    const fullWidthClass = `${styles['w-full']} ${styles['max-w-full']}`;

    // Merge base styles with variant-specific themed background styles
    const mergedStyles = {
        ...themedBackgroundStyles[variant] || {},
        ...style,
    };

    // Add width classes to className
    const enhancedClassName = `${fullWidthClass} ${className}`;



    return <OriginalContainer
        ref={ref}
        variant={variant}
        style={mergedStyles}
        className={enhancedClassName}
        {...otherProps}
    />;
});

ThemedContainer.displayName = 'ThemedContainer';

export default ThemedContainer;