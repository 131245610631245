import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

/**
 * Title_GeneralWebpages_Template.jsx - Reusable 3D title component
 *
 * @param {Object} props
 * @param {string} props.text - Text to display (defaults to "TAYTROM")
 * @param {string} props.to - Link destination (defaults to "/about")
 * @param {boolean} props.isDark - Whether dark mode is active
 * @param {Function} props.getTacsColor - Function to get colors from theme
 * @param {Function} props.withAlpha - Function to apply alpha to colors
 * @param {string} props.className - Additional classes to apply
 * @param {Object} props.style - Additional styles to apply
 * @param {boolean} props.showOutline - Whether to show the teal outline
 * @param {boolean} props.letterByLetter - Whether to render each letter separately for animation
 * @param {string} props.fontSize - Font size (CSS value)
 * @param {string} props.marginLeft - Left margin (CSS value)
 * @param {string} props.marginTop - Top margin (CSS value)
 * @param {string} props.paddingTop - Top padding (CSS value)
 * @param {boolean} props.reflectionEffect - Whether to show the reflection effect
 * @param {Function} props.onMount - Callback when component is mounted
 * @param {string} props.ariaLabel - Aria label for accessibility
 * @returns {JSX.Element}
 */
const Title_GeneralWebpages_Template = ({
                                            text = "TAYTROM",
                                            to = "/about",
                                            isDark = false,
                                            getTacsColor,
                                            withAlpha,
                                            className = "",
                                            style = {},
                                            showOutline = false,
                                            letterByLetter = true,
                                            fontSize = "clamp(3rem, 15vw, 8rem)",
                                            marginLeft = "50px",
                                            marginTop = "100px",
                                            paddingTop = "150px",
                                            reflectionEffect = true,
                                            onMount,
                                            ariaLabel = "Navigate to page"
                                        }) => {
    const titleRef = useRef(null);

    // If color helper functions aren't provided, create simple versions
    const getColor = getTacsColor || ((name, shade) => {
        const colors = {
            teal: ['#20B2AA', '#1EA098', '#1C8E87', '#1A7C76', '#176A64', '#155853', '#124642', '#103431', '#0D2220', '#0A110F'],
            whiteKhaki: ['#F5F5DC'],
            blackGray: ['#1C1C1C', '#222222', '#2A2A2A', '#333333', '#3D3D3D', '#474747', '#525252', '#5C5C5C', '#666666', '#707070']
        };
        return colors[name][shade] || colors[name][0];
    });

    const addAlpha = withAlpha || ((color, alpha) => {
        if (color.startsWith('#')) {
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        }
        return color;
    });

    // Apply any animation or initialization logic on mount
    useEffect(() => {
        if (onMount && typeof onMount === 'function') {
            onMount(titleRef.current);
        }
    }, [onMount]);
// Generate text shadow based on dark/light mode
    const getTextShadow = () => {
        if (isDark) {
            return `0 1px 0 ${getColor('blackGray', 3)},
    0 2px 0 ${getColor('blackGray', 4)},
    0 3px 0 ${getColor('blackGray', 5)},
    0 4px 0 ${getColor('blackGray', 6)},
    0 5px 0 ${getColor('blackGray', 7)},
    0 6px 1px ${addAlpha(getColor('blackGray', 10), 0.1)},
    0 0 5px ${addAlpha(getColor('blackGray', 10), 0.1)},
    0 1px 3px ${addAlpha(getColor('blackGray', 10), 0.3)},
    0 3px 5px ${addAlpha(getColor('blackGray', 10), 0.2)},
    0 5px 10px ${addAlpha(getColor('blackGray', 10), 0.25)},
    0 10px 10px ${addAlpha(getColor('blackGray', 10), 0.2)},
    0 20px 20px ${addAlpha(getColor('blackGray', 10), 0.15)}`;
        } else {
            // Reduced shadow for light mode
            return `0 1px 0 ${getColor('blackGray', 6)},
    0 2px 0 ${getColor('blackGray', 7)},
    0 3px 0 ${getColor('blackGray', 8)},
    ${showOutline ? `1px 1px 0 ${addAlpha(getColor('teal', 5), 0.2)},` : ''}
    -1px -1px 0 ${addAlpha(getColor('whiteKhaki', 1), 0.5)},
    0 4px 2px ${addAlpha(getColor('blackGray', 10), 0.25)},
    0 7px 5px ${addAlpha(getColor('blackGray', 10), 0.15)},
    0 10px 10px ${addAlpha(getColor('blackGray', 10), 0.1)}`;
        }
    };
    // Determine stroke style based on showOutline prop
    const getStrokeStyle = () => {
        if (!showOutline) return { WebkitTextStroke: 'none', textStroke: 'none' };

        return {
            WebkitTextStroke: isDark
                ? `1px ${addAlpha(getColor('teal', 5), 0.7)}`
                : `1px ${addAlpha(getColor('teal', 5), 0.8)}`,
            textStroke: isDark
                ? `1px ${addAlpha(getColor('teal', 5), 0.7)}`
                : `1px ${addAlpha(getColor('teal', 5), 0.8)}`
        };
    };

    // Create letters if letterByLetter is true
    const renderText = () => {
        if (!letterByLetter) return text;

        return text.split('').map((letter, index) => (
            <span key={index} className="letter">{letter}</span>
        ));
    };

    return (
        <div
            style={{
                position: 'relative',
                zIndex: 9,
                ...style
            }}
        >
            <div
                id="taytrom-title"
                ref={titleRef}
                style={{
                    color: isDark ? getColor('whiteKhaki', 1) : '#1c1c1c',

                    letterSpacing: '0.06em',
                    position: 'relative',
                    zIndex: 5,

                    ...getStrokeStyle()
                }}
            >
                <Link
                    to={to}
                    className="title-3d-link"
                    style={{
                        textDecoration: 'none',
                        cursor: 'pointer',
                        display: 'block',
                        position: 'relative',
                        zIndex: 10,
                        color: 'inherit',
                        marginLeft,
                        marginTop,
                        textShadow: getTextShadow()
                    }}
                    aria-label={ariaLabel}
                >
                    {renderText()}
                </Link>
            </div>

            {reflectionEffect && (
                <style jsx>{`
          .title-3d::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 40%;
            background: linear-gradient(
              to bottom,
              var(--reflection-start-color, rgba(255, 255, 255, 0.2)),
              var(--reflection-end-color, rgba(255, 255, 255, 0))
            );
            z-index: -1;
            pointer-events: none;
          }
          
          /* Optional: Add letter animation support */
          .letter {
            display: inline-block;
            transition: transform 0.3s ease, text-shadow 0.3s ease;
          }
          
          .title-3d-link:hover .letter {
            display: inline-block;
          }
          
          .title-3d-link:hover .letter:nth-child(odd) {
            transform: translateY(-2px);
          }
          
          .title-3d-link:hover .letter:nth-child(even) {
            transform: translateY(2px);
          }
        `}</style>
            )}
        </div>
    );
};

/**
 * MultipleWords3D - Specialized 3D title component for multi-word titles with adjusted word spacing
 *
 * @param {Object} props
 * @param {string} props.text - Text to display (defaults to "MULTI WORD TITLE")
 * @param {string} props.to - Link destination or anchor tag (defaults to "#")
 * @param {boolean} props.isDark - Whether dark mode is active
 * @param {Function} props.getTacsColor - Function to get colors from theme
 * @param {Function} props.withAlpha - Function to apply alpha to colors
 * @param {string} props.className - Additional classes to apply
 * @param {Object} props.style - Additional styles to apply
 * @param {string} props.fontSize - Font size (CSS value)
 * @param {string} props.wordSpacing - Word spacing value (defaults to "0.5em")
 * @param {string} props.id - ID for the title element
 * @param {string} props.ariaLabel - Aria label for accessibility
 * @returns {JSX.Element}
 */
const MultipleWords3D = ({
                             text = "MULTI WORD TITLE",
                             to = "#",
                             isDark = false,
                             getTacsColor,
                             withAlpha,
                             className = "",
                             style = {},
                             fontSize = "2.5rem",
                             wordSpacing = "0.5em",
                             id = "multiple-words-title",
                             ariaLabel = "Section title"
                         }) => {
    const titleRef = useRef(null);

    // If color helper functions aren't provided, create simple versions
    const getColor = getTacsColor || ((name, shade) => {
        const colors = {
            teal: ['#20B2AA', '#1EA098', '#1C8E87', '#1A7C76', '#176A64', '#155853', '#124642', '#103431', '#0D2220', '#0A110F'],
            whiteKhaki: ['#F5F5DC'],
            blackGray: ['#1C1C1C', '#222222', '#2A2A2A', '#333333', '#3D3D3D', '#474747', '#525252', '#5C5C5C', '#666666', '#707070']
        };
        return colors[name][shade] || colors[name][0];
    });

    const addAlpha = withAlpha || ((color, alpha) => {
        if (color.startsWith('#')) {
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        }
        return color;
    });

    // Generate appropriate text shadow based on dark/light mode
    const getTextShadow = () => {
        if (isDark) {
            return `0 1px 0 ${getColor('blackGray', 3)},
 0 2px 0 ${getColor('blackGray', 4)},
 0 3px 0 ${getColor('blackGray', 5)},
 0 4px 0 ${getColor('blackGray', 6)},
 0 5px 0 ${getColor('blackGray', 7)},
 0 6px 1px ${addAlpha(getColor('blackGray', 10), 0.1)},
 0 0 5px ${addAlpha(getColor('blackGray', 10), 0.1)},
 0 1px 3px ${addAlpha(getColor('blackGray', 10), 0.3)},
 0 3px 5px ${addAlpha(getColor('blackGray', 10), 0.2)},
 0 5px 10px ${addAlpha(getColor('blackGray', 10), 0.25)},
 0 10px 10px ${addAlpha(getColor('blackGray', 10), 0.2)},
 0 20px 20px ${addAlpha(getColor('blackGray', 10), 0.15)}`;
        } else {
            return `0 1px 0 ${getColor('blackGray', 6)},
 0 2px 0 ${getColor('blackGray', 7)},
 0 3px 0 ${getColor('blackGray', 8)},
 0 4px 0 ${getColor('blackGray', 9)},
 0 5px 0 ${getColor('blackGray', 9)},
 -1px -1px 0 ${addAlpha(getColor('whiteKhaki', 1), 0.7)},
 0 10px 5px ${addAlpha(getColor('blackGray', 10), 0.4)},
 0 12px 10px ${addAlpha(getColor('blackGray', 10), 0.3)},
 0 15px 15px ${addAlpha(getColor('blackGray', 10), 0.2)},
 0 20px 20px ${addAlpha(getColor('blackGray', 10), 0.15)}`;
        }
    };

    return (
        <div
            className={`multiple-words-title-container ${className}`}
            style={{
                position: 'relative',
                zIndex: 9,
                marginBottom: '0.5rem',
                textAlign: 'center',
                width: '100%',
                ...style
            }}
        >
            <h2
                ref={titleRef}
                id={id}
                className="multiple-words-title title-3d"
                style={{
                    fontSize: fontSize,
                    letterSpacing: '0.06em',
                    wordSpacing: wordSpacing,
                    color: isDark ? getColor('whiteKhaki', 1) : '#1c1c1c',
                    textShadow: getTextShadow(),
                    position: 'relative',
                    zIndex: 5
                }}
            >
                {/* Using an <a> tag instead of <Link> for hash links or when used outside of react-router */}
                <a
                    href={to}
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        position: 'relative',
                        zIndex: 10
                    }}
                    aria-label={ariaLabel}
                >
                    {text}
                </a>
            </h2>

            {/* Reflection effect - always included for this component */}
            <style jsx>{`
                .multiple-words-title::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 40%;
                    background: linear-gradient(
                        to bottom,
                        var(--reflection-start-color, rgba(255, 255, 255, 0.2)),
                        var(--reflection-end-color, rgba(255, 255, 255, 0))
                    );
                    z-index: -1;
                    pointer-events: none;
                }
            `}</style>
        </div>
    );
};

export { MultipleWords3D };
export default Title_GeneralWebpages_Template;


/**

 // Basic usage example in HomePage.jsx
 import React from 'react';
 import TaytromTitle from '../components/TaytromTitle';
 import { getTacsColor, withAlpha } from '../utils/colors';

 const HomePage = ({ isDarkMode }) => {
 return (
 <div className="home-container">
<div className="hero-section">
    <TaytromTitle
        isDark={isDarkMode}
        getTacsColor={getTacsColor}
        withAlpha={withAlpha}
        showOutline={false} // Remove the teal outline
        ariaLabel="Go to About page"
    />

    <p className="hero-tagline">Your innovative solutions partner</p>
</div>
</div>
);
};

// Example with custom text on another page
import React from 'react';
import TaytromTitle from '../components/TaytromTitle';

const ServicesPage = ({ isDarkMode }) => {
    return (
        <div className="services-page">
            <TaytromTitle
                text="SERVICES"
                to="/services"
                isDark={isDarkMode}
                fontSize="clamp(2rem, 10vw, 6rem)"
                marginLeft="20px"
                marginTop="50px"
                paddingTop="100px"
                ariaLabel="Services overview"
            />

        </div>
    );
};

// Example with multiple titles on the same page
import React from 'react';
import TaytromTitle from '../components/TaytromTitle';

const PortfolioPage = ({ isDarkMode }) => {
    return (
        <div className="portfolio-page">
            <section className="portfolio-hero">
                <TaytromTitle
                    text="PORTFOLIO"
                    to="/portfolio"
                    isDark={isDarkMode}
                />
            </section>

            <section className="case-studies">
                <TaytromTitle
                    text="CASE STUDIES"
                    to="/case-studies"
                    isDark={isDarkMode}
                    fontSize="clamp(1.5rem, 8vw, 4rem)"
                    marginLeft="0"
                    marginTop="40px"
                    paddingTop="80px"
                    reflectionEffect={false}
                />

            </section>

            <section className="testimonials">
                <TaytromTitle
                    text="SUCCESS STORIES"
                    to="/testimonials"
                    isDark={isDarkMode}
                    fontSize="clamp(1.5rem, 8vw, 4rem)"
                    marginLeft="0"
                    marginTop="40px"
                    paddingTop="80px"
                />

            </section>
        </div>
    );
};




 */