import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { ThemeContext } from "@/FullDevApp.jsx";
import styles from '../../TACS2.module.css';
import PropTypes from 'prop-types';
import { useThemeColors} from "@/templates-scope/colors/accepted/Accepted_Colors_GeneralWebpages_Templates.jsx";

import {
    Home,
    Layers,
    MessageSquare,
    Mail,
    Settings,
    Users,
    BarChart2,
    FileText,
    Image,
    Globe,
    Code,
    Book,
    Zap
} from "lucide-react";

const Vertical_Navigate = ({
                               sections = [],
                               activeSectionIndex = 0,
                               onSectionChange = () => {},
                               position = 'right',
                               colorScheme = 'primary',
                               showLabels = false,
                               customStyles = {}
                           }) => {
    // Theme context for dark/light mode
    const { isDark } = useContext(ThemeContext);

    // Get theme-aware colors using the provided hook
    const colors = useThemeColors();

    // State for hover indicators and visible tooltips
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [visibleTooltip, setVisibleTooltip] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);

    // State for current active section
    const [activeIndex, setActiveIndex] = useState(activeSectionIndex);

    // Refs for scroll handling
    const isScrolling = useRef(false);
    const scrollTimeout = useRef(null);
    const updateTimeout = useRef(null);
    const manualScrolling = useRef(false);
    const navRef = useRef(null);

    // Function to map icon names to Lucide icons
    const getIconComponent = (iconName) => {
        const iconMap = {
            'home': Home,
            'layers': Layers,
            'message-square': MessageSquare,
            'mail': Mail,
            'settings': Settings,
            'users': Users,
            'bar-chart': BarChart2,
            'file-text': FileText,
            'image': Image,
            'globe': Globe,
            'code': Code,
            'book': Book,
            'zap': Zap
        };

        const IconComponent = iconMap[iconName] || Home;
        return IconComponent;
    };

    // Get premium color based on colorScheme
    const getPremiumColor = () => {
        switch (colorScheme) {
            case 'primary':
                return colors.getTacsPremiumColor('teal', 8);
            case 'secondary':
                return colors.getTacsPremiumColor('blue', 7);
            case 'info':
                return colors.getTacsPremiumColor('blue', 5);
            case 'success':
                return colors.getTacsPremiumColor('green', 6);
            case 'warning':
                return colors.getTacsPremiumColor('yellow', 6);
            case 'error':
                return colors.getTacsPremiumColor('red', 6);
            default:
                return colors.getTacsPremiumColor('teal', 8);
        }
    };

    // Get base color based on colorScheme
    const getBaseColor = () => {
        switch (colorScheme) {
            case 'primary':
                return colors.getTacsColor('teal', 8);
            case 'secondary':
                return colors.getTacsColor('blue', 7);
            case 'info':
                return colors.getTacsColor('blue', 5);
            case 'success':
                return colors.getTacsColor('green', 6);
            case 'warning':
                return colors.getTacsColor('yellow', 6);
            case 'error':
                return colors.getTacsColor('red', 6);
            default:
                return colors.getTacsColor('teal', 8);
        }
    };

    // Helper function for rgba conversion
    const withAlpha = (color, alpha) => {
        return colors.withAlpha(color, alpha);
    };

    // Direct scroll position tracking for more reliability
    const updateSectionVisibility = useCallback(() => {
        if (manualScrolling.current) return;

        // Get viewport height and scroll position
        const viewportHeight = window.innerHeight;
        const scrollTop = window.scrollY;

        // Calculate visibility for each section
        const visibilityScores = sections.map((section, index) => {
            if (!section.ref?.current) return { index, score: 0 };

            const rect = section.ref.current.getBoundingClientRect();

            // Calculate how much of the section is in the viewport
            const visibleHeight = Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0);
            const sectionHeight = rect.height;

            // Calculate visibility ratio
            let score = Math.max(0, visibleHeight / sectionHeight);

            // Boost score for steps at the top of the viewport
            if (rect.top < 100 && rect.top > -100) {
                score *= 1.5;
            }

            // Boost score for current active section to prevent flicker
            if (index === activeIndex) {
                score *= 1.1;
            }

            return { index, score };
        });

        // Sort by visibility score
        visibilityScores.sort((a, b) => b.score - a.score);

        // Select the most visible section if it has a meaningful visibility
        if (visibilityScores.length > 0 && visibilityScores[0].score > 0.15) {
            const newActiveIndex = visibilityScores[0].index;

            if (newActiveIndex !== activeIndex) {
                setActiveIndex(newActiveIndex);
                onSectionChange(newActiveIndex);

                // Add animation flag for active change
                setIsAnimating(true);
                setTimeout(() => setIsAnimating(false), 500);
            }
        }
    }, [sections, activeIndex, onSectionChange]);

    // Handle section click
    const handleSectionClick = useCallback((index) => {
        if (!sections[index]?.ref?.current) return;

        // Mark that we're doing manual scrolling to disable auto-detection temporarily
        manualScrolling.current = true;

        // Set the active section immediately for responsive UI
        setActiveIndex(index);
        onSectionChange(index);

        // Add animation flag for active change
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), 500);

        // Calculate offset for header if you have one (adjust as needed)
        const headerOffset = 60;

        // Get the target position
        const targetElement = sections[index].ref.current;
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        // Scroll to the target section
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

        // Clear any previous timeout
        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
        }

        // Re-enable auto-detection after scrolling animation is likely to be complete
        scrollTimeout.current = setTimeout(() => {
            manualScrolling.current = false;
        }, 1000);
    }, [sections, onSectionChange]);

    // Setup scroll event handling
    useEffect(() => {
        const handleScroll = () => {
            // Mark that we're scrolling
            isScrolling.current = true;

            // Clear any previous timeout for scroll end detection
            if (scrollTimeout.current) {
                clearTimeout(scrollTimeout.current);
            }

            // Only update during scrolling if we're not in a manual navigation
            if (!manualScrolling.current) {
                // Use throttling to avoid too frequent updates
                if (updateTimeout.current) {
                    clearTimeout(updateTimeout.current);
                }

                updateTimeout.current = setTimeout(() => {
                    updateSectionVisibility();
                }, 100); // Throttle to run at most every 100ms
            }

            // Set timeout to detect when scrolling stops
            scrollTimeout.current = setTimeout(() => {
                isScrolling.current = false;

                // Final update when scrolling ends
                if (!manualScrolling.current) {
                    updateSectionVisibility();
                }
            }, 150);
        };

        // Register scroll event listener
        window.addEventListener('scroll', handleScroll, { passive: true });

        // Initial update
        updateSectionVisibility();

        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);

            if (scrollTimeout.current) {
                clearTimeout(scrollTimeout.current);
            }

            if (updateTimeout.current) {
                clearTimeout(updateTimeout.current);
            }
        };
    }, [updateSectionVisibility]);

    // Keep the internal state in sync with props
    useEffect(() => {
        if (activeSectionIndex !== activeIndex && !manualScrolling.current) {
            setActiveIndex(activeSectionIndex);
        }
    }, [activeSectionIndex, activeIndex]);

    // Effect for keyboard navigation
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowUp' && activeIndex > 0) {
                handleSectionClick(activeIndex - 1);
            } else if (e.key === 'ArrowDown' && activeIndex < sections.length - 1) {
                handleSectionClick(activeIndex + 1);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [activeIndex, handleSectionClick, sections.length]);

    // Add subtle float animation
    useEffect(() => {
        if (!navRef.current) return;

        const floatAnimation = () => {
            if (!navRef.current) return;
            navRef.current.style.transform = `translateY(-50%) translateY(${Math.sin(Date.now() / 2000) * 3}px)`;
            requestAnimationFrame(floatAnimation);
        };

        const animationFrame = requestAnimationFrame(floatAnimation);
        return () => cancelAnimationFrame(animationFrame);
    }, []);

    // Calculate position styles
    const positionStyles = {
        left: {
            left: '1rem',
            right: 'auto',
        },
        right: {
            right: '1rem',
            left: 'auto',
        }
    };

    const baseColor = getBaseColor();

    // Premium gradient for background
    const getPremiumGradient = () => {
        return `linear-gradient(135deg, ${isDark ? '#1c1c1c' : '#1c1c1c'}, ${isDark ? '#2a2a2a' : '#2a2a2a'})`;
    };

    // Get glassmorphism effects based on theme
    const glassEffect = colors.getGlassEffect('medium', true);


    const luxeShadow = colors.getShadow('luxe');

    // Enhanced 3D button style based on "Get Started" button
    // Also update the getButtonStyle function to enhance the 3D effect of each button
    const getButtonStyle = (isActive, isHovered) => {
        // Base styling for all button states
        const baseStyle = {
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            position: 'relative',
            zIndex: 5,
            transform: 'perspective(800px) rotateX(0deg) translateZ(0)',
            transformStyle: 'preserve-3d',
            transition: 'all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)',
            boxShadow: isDark ?
                `0 6px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 10), 0.8)},
            0 8px 12px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.3)},
            inset 0 1px 2px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.4)},
            inset 0 -1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.3)}` :
                `0 6px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 9), 0.7)},
            0 8px 15px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.2)},
            inset 0 1px 3px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.5)},
            inset 0 -1px 2px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.3)}`,
            textShadow: `0 1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.5)}`
        };

        // Active state styling
        if (isActive) {
            return {
                ...baseStyle,
                background: getPremiumGradient(),
                transform: isAnimating
                    ? 'perspective(800px) rotateX(-10deg) translateZ(10px) translateY(-2px) scale(1.2)'
                    : 'perspective(800px) rotateX(-10deg) translateZ(10px) translateY(-2px) scale(1.05)',
                boxShadow: isDark ?
                    `0 4px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 10), 0.8)},
                    0 10px 16px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.4)},
                    inset 0 1px 1px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.3)},
                    inset 0 -1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.2)}` :
                    `0 4px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 9), 0.7)},
                    0 10px 20px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.25)},
                    inset 0 1px 2px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.4)},
                    inset 0 -1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.15)}`,
                color: colors.getTacsColor('whiteKhaki', 1)
            };
        }

        // Hover state styling
        if (isHovered) {
            return {
                ...baseStyle,
                background: colors.getTacsColor('whiteKhaki', 1),
                transform: 'perspective(800px) rotateX(-10deg) translateZ(10px) translateY(-2px)',
                boxShadow: isDark ?
                    `0 4px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 10), 0.8)},
                    0 10px 16px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.4)},
                    inset 0 1px 1px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.3)},
                    inset 0 -1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.2)}` :
                    `0 4px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 9), 0.7)},
                    0 10px 20px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.25)},
                    inset 0 1px 2px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.4)},
                    inset 0 -1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.15)}`,
                color: colors.getTacsColor('blackGray', 9)
            };
        }

        // Default state styling
        return {
            ...baseStyle,
            background: isDark ?
                withAlpha(colors.getTacsColor('blackGray', 9), 0.95) :
                withAlpha('#1c1c1c', 0.95),
            color: isDark ? colors.getTacsColor('whiteKhaki', 1) : colors.getTacsColor('whiteKhaki', 1)
        };
    };

    // Enhanced tooltip style
    const getTooltipStyle = (isVisible) => ({
        background: isDark
            ? 'linear-gradient(135deg, rgba(40, 40, 50, 0.95), rgba(30, 30, 40, 0.85))'
            : 'linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(245, 245, 250, 0.85))',
        color: colors.getThemeColor('text', 'primary'),
        boxShadow: luxeShadow,
        borderRadius: '0.75rem',
        padding: '0.5rem 0.8rem',
        fontSize: '0.85rem',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        position: 'absolute',
        top: '50%',
        transform: isVisible
            ? 'translateY(-50%) scale(1) translateX(0)'
            : 'translateY(-50%) scale(0.8) translateX(10px)',
        opacity: isVisible ? 1 : 0,
        [position === 'right' ? 'right' : 'left']: 'calc(100% + 16px)',
        pointerEvents: 'none',
        transition: 'all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
        zIndex: 1010,
        border: `1px solid ${withAlpha(colors.getThemeColor('border', 'subtle'), 0.4)}`,
        ...colors.getGlassEffect('subtle')
    });

    return (
        <nav
            ref={navRef}
            style={{
                position: 'fixed',
                top: '50%',
                ...positionStyles[position],
                transform: 'translateY(-50%)',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.75rem',
                padding: '1rem 0.75rem',
                borderRadius: '2rem',
                ...glassEffect,
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                // Enhanced 3D border with contrasting edges
                border: 'none', // Remove the previous border
                // Add a subtle inner border with bright highlight
                boxShadow: `
            ${luxeShadow}, 
            0 0 15px ${withAlpha(baseColor, 0.2)},
            inset 0 1px 2px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.7)},
            inset -1px 0 2px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.5)},
            inset 0 -1px 2px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.5)},
            inset 1px 0 2px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.3)}
        `,
                transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
                background: isDark ?
                    `linear-gradient(135deg, #252525, #1c1c1c)` :
                    `linear-gradient(135deg, #252525, #1c1c1c)`,
                // Add a subtle outline to enhance the 3D effect
                outline: `1px solid ${withAlpha(colors.getTacsColor('blackGray', 10), 0.5)}`,
                outlineOffset: '1px',
                ...customStyles
            }}
            role="navigation"
            aria-label="Page Navigation"
            className={`${styles.fixed} ${styles['z-1000']} ${styles.transition} ${styles['hidden-mobile']}`}
        >
            {/* Add a shine overlay to create the 3D pop effect */}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '30%',
                background: `linear-gradient(to bottom, 
            ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.25)}, 
            ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0)}
        )`,
                borderTopLeftRadius: '2rem',
                borderTopRightRadius: '2rem',
                pointerEvents: 'none',
                zIndex: 1
            }} />

            {/* Add a bottom shadow for depth */}
            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '30%',
                background: `linear-gradient(to top, 
            ${withAlpha(colors.getTacsColor('blackGray', 10), 0.3)}, 
            ${withAlpha(colors.getTacsColor('blackGray', 10), 0)}
        )`,
                borderBottomLeftRadius: '2rem',
                borderBottomRightRadius: '2rem',
                pointerEvents: 'none',
                zIndex: 1
            }} />
            {/* Ambient glow effect */}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: '2rem',
                background: `radial-gradient(circle at center, ${withAlpha(baseColor, 0.15)}, transparent 70%)`,
                filter: 'blur(10px)',
                opacity: 0.8,
                zIndex: -1
            }}/>

            {/* Navigation Links */}
            <div
                className={`${styles['flex-column']} ${styles['items-center']} ${styles['gap-3']}`}>
                {sections.map((section, index) => {
                    const isActive = activeIndex === index;
                    const isHovered = hoveredIndex === index;
                    const tooltipText = section.label;
                    const showTooltip = visibleTooltip === index && !showLabels;

                    // Get the appropriate icon component
                    const IconComponent = section.icon ?
                        (typeof section.icon === 'string' ? getIconComponent(section.icon) : section.icon) :
                        getIconComponent('home');

                    return (
                        <div
                            key={section.id}
                            className={`${styles.relative} ${styles.group} ${styles['cursor-pointer']} ${styles.transition}`}
                            style={{
                                width: showLabels ? 'auto' : '42px',
                                height: '42px',
                                marginBottom: '14px',
                                position: 'relative'
                            }}
                            onClick={() => handleSectionClick(index)}
                            onMouseEnter={() => {
                                setHoveredIndex(index);
                                setVisibleTooltip(index);
                            }}
                            onMouseLeave={() => {
                                setHoveredIndex(null);
                                setVisibleTooltip(null);
                            }}
                            onMouseDown={(e) => {
                                // Apply pressed effect
                                const button = e.currentTarget.querySelector('div');
                                if (button) {
                                    button.style.transform = 'perspective(800px) rotateX(0deg) translateZ(0) translateY(3px)';
                                    button.style.boxShadow = isDark ?
                                        `0 2px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 10), 0.8)},
                                        0 4px 6px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.3)},
                                        inset 0 1px 1px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.2)},
                                        inset 0 -1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.2)}` :
                                        `0 2px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 9), 0.7)},
                                        0 4px 8px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.2)},
                                        inset 0 1px 2px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.3)},
                                        inset 0 -1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.15)}`;
                                }
                            }}
                            onMouseUp={(e) => {
                                // Return to hover state
                                const button = e.currentTarget.querySelector('div');
                                if (button && isHovered) {
                                    button.style.transform = 'perspective(800px) rotateX(-10deg) translateZ(10px) translateY(-2px)';
                                    button.style.boxShadow = isDark ?
                                        `0 4px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 10), 0.8)},
                                        0 10px 16px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.4)},
                                        inset 0 1px 1px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.3)},
                                        inset 0 -1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.2)}` :
                                        `0 4px 0 0 ${withAlpha(colors.getTacsColor('blackGray', 9), 0.7)},
                                        0 10px 20px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.25)},
                                        inset 0 1px 2px ${withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.4)},
                                        inset 0 -1px 1px ${withAlpha(colors.getTacsColor('blackGray', 10), 0.15)}`;
                                }
                            }}
                            aria-current={isActive ? 'page' : undefined}
                            tabIndex={0}
                            role="button"
                            aria-label={`Navigate to ${section.label} section`}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handleSectionClick(index);
                                }
                            }}
                        >
                            {/* 3D Button with gradient and glassmorphism */}
                            <div style={getButtonStyle(isActive, isHovered)}>
                                <IconComponent
                                    size={isActive ? 18 : 16}
                                    strokeWidth={isActive ? 2.5 : 2}
                                    style={{
                                        filter: isActive ? 'drop-shadow(0 0 3px rgba(255,255,255,0.5))' : 'none',
                                        transition: 'all 0.3s ease',
                                        transform: 'translateZ(5px)',
                                    }}
                                />

                                {/* Label if enabled */}
                                {showLabels && (
                                    <span
                                        style={{
                                            marginLeft: '10px',
                                            fontSize: '0.8rem',
                                            fontWeight: isActive ? 600 : 500,
                                            color: isActive
                                                ? colors.getTacsColor('whiteKhaki', 1)
                                                : isDark
                                                    ? 'rgba(255, 255, 255, 0.9)'
                                                    : 'rgba(30, 30, 40, 0.9)',
                                            textShadow: isActive ? `0 0 5px ${withAlpha(baseColor, 0.3)}` : 'none',
                                            transform: 'translateZ(5px)',
                                        }}
                                    >
                                        {section.label}
                                    </span>
                                )}

                                {/* Add a subtle highlight effect on the top */}
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: '50%',
                                    borderTopLeftRadius: '9999px',
                                    borderTopRightRadius: '9999px',
                                    pointerEvents: 'none',
                                    opacity: 0.3,
                                }}/>
                            </div>

                            {/* Pulse glow effect for active item */}
                            {isActive && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                        background: `radial-gradient(circle, ${withAlpha(baseColor, 0.3)}, transparent 70%)`,
                                        animation: 'pulse 2s infinite',
                                        zIndex: 1
                                    }}
                                />
                            )}

                            {/* Active indicator dot with enhanced styling */}
                            {isActive && (
                                <span
                                    className={`${styles.absolute} ${styles['rounded-full']} ${styles['animate-pulse']}`}
                                    style={{
                                        background: `linear-gradient(135deg, ${baseColor}, ${colors.getHarmoniousColor(baseColor, 'analogous')[0]})`,
                                        boxShadow: `0 0 8px ${withAlpha(baseColor, 0.7)}`,
                                        bottom: '-6px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        width: '8px',
                                        height: '8px',
                                        zIndex: 10
                                    }}
                                    aria-hidden="true"
                                />
                            )}

                            {/* Enhanced Tooltip */}
                            {!showLabels && (
                                <div style={getTooltipStyle(showTooltip)}
                                     aria-hidden={!showTooltip}>
                                    {tooltipText}

                                    {/* Enhanced Tooltip arrow */}
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: '10px',
                                            height: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            [position === 'right' ? 'right' : 'left']: '-5px',
                                            background: isDark
                                                ? 'rgba(40, 40, 50, 0.95)'
                                                : 'rgba(255, 255, 255, 0.95)',
                                            borderLeft: position === 'right' ? `1px solid ${withAlpha(colors.getThemeColor('border', 'subtle'), 0.4)}` : 'none',
                                            borderBottom: position === 'right' ? `1px solid ${withAlpha(colors.getThemeColor('border', 'subtle'), 0.4)}` : 'none',
                                            borderRight: position === 'left' ? `1px solid ${withAlpha(colors.getThemeColor('border', 'subtle'), 0.4)}` : 'none',
                                            borderTop: position === 'left' ? `1px solid ${withAlpha(colors.getThemeColor('border', 'subtle'), 0.4)}` : 'none',
                                            zIndex: 0
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {/* Enhanced progress indicator */}
            {sections.length > 1 && (
                <div
                    style={{
                        position: 'relative',
                        width: '3px',
                        height: '50px',
                        background: isDark
                            ? 'linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05))'
                            : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05))',
                        marginTop: '8px',
                        marginBottom: '8px',
                        borderRadius: '3px',
                        overflow: 'hidden',
                        boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: `${(activeIndex / (sections.length - 1)) * 100}%`,
                            background: colors.getTacsColor('whiteKhaki', 1),
                            borderRadius: '3px',
                            transition: 'height 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
                            boxShadow: `0 0 8px ${withAlpha(baseColor, 0.5)}`
                        }}
                    />
                </div>
            )}

            {/* CSS keyframes for animations */}
            <style jsx>{`
                @keyframes pulse {
                    0% {
                        opacity: 0.7;
                        transform: scale(0.95);
                    }
                    50% {
                        opacity: 0.3;
                        transform: scale(1.2);
                    }
                    100% {
                        opacity: 0.7;
                        transform: scale(0.95);
                    }
                }

                @keyframes float {
                    0% {
                        transform: translateY(0px);
                    }
                    50% {
                        transform: translateY(-5px);
                    }
                    100% {
                        transform: translateY(0px);
                    }
                }

                @keyframes shimmer {
                    0% {
                        background-position: -200% 0;
                    }
                    100% {
                        background-position: 200% 0;
                    }
                }

                @keyframes button3dIntro {
                    0% {
                        transform: perspective(800px) rotateX(20deg) translateZ(-10px);
                        opacity: 0;
                    }
                    100% {
                        transform: perspective(800px) rotateX(0deg) translateZ(0);
                        opacity: 1;
                    }
                }
                @keyframes shine {
                    0% {
                        background-position: -100% 0;
                    }
                    100% {
                        background-position: 300% 0;
                    }
                }
            `}</style>
        </nav>
    );
};

// PropTypes validation
Vertical_Navigate.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            icon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
            ref: PropTypes.object.isRequired
        })
    ),
    activeSectionIndex: PropTypes.number,
    onSectionChange: PropTypes.func,
    position: PropTypes.oneOf(['left', 'right']),
    colorScheme: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error']),
    showLabels: PropTypes.bool,
    customStyles: PropTypes.object
};

export default Vertical_Navigate;