/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                       /SCOPE/templates-report/TANK/generalWebpages/ATOM/colors/accepted/Accepted_Colors_GeneralWebpages_Templates.jsx
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

import React, { useContext } from 'react';
import { ThemeContext } from '@/FullDevApp.jsx';

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                          CONSTANTS & UTILITY FUNCTIONS
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

const logWx4H = (who, what, why, how) => {
    if (typeof window !== 'undefined' && window.DEBUG_LOGGING) {
    }
};


// TACS (The Accepted Color System) Standardized Colors - 100 Recognized Colors
// Original TACS_COLORS with descriptive names - Maintained for backward compatibility
// DEPRECATED: Use TACS_COLOR_CENTURIAL instead for new development
const TACS_COLORS = {
    // 1. White/Khaki
    whiteKhaki: {
        pureWhite: '#FFFFFF',
        aliceBlue: '#F0F8FF',
        antiqueWhite: '#FAEBD7',
        beige: '#F5F5DC',
        bisque: '#FFE4C4',
        moccasin: '#FFE4B5',
        wheat: '#F5DEB3',
        khaki: '#F0E68C',
        whiteSmoke: '#F5F5F5',
        gainsboro: '#DCDCDC'
    },

    // 2. Yellow
    yellow: {
        pureYellow: '#FFFF00',
        lightYellow: '#FFFFE0',
        lemonChiffon: '#FFFACD',
        lightGoldenrodYellow: '#FFEC8B',
        gold: '#FFD700',
        paleGoldenrod: '#EEE8AA',
        goldenrod: '#DAA520',
        peachYellow: '#FFDFBA',
        lightGoldenrod: '#FAFAD2',
        cornsilk: '#FFF8DC'
    },

    // 3. Green
    green: {
        mintGreen: '#CCFFCC',
        lightGreen: '#90EE90',
        paleGreen: '#98FB98',
        lawnGreen: '#7CFC00',
        limeGreen: '#32CD32',
        forestGreen: '#228B22',
        springGreen: '#00FF7F',
        mediumSeaGreen: '#3CB371',
        seaGreen: '#2E8B57',
        lightSeaGreen: '#20B2AA'  // Taytrom brand accent color
    },

    // 4. Teal
    teal: {
        lightCyan: '#E6FFFF',
        paleTurquoise: '#AFEEEE',
        aquamarine: '#7FFFD4',
        turquoise: '#40E0D0',
        mediumTurquoise: '#48D1CC',
        cyan: '#00FFFF',
        darkTurquoise: '#00CED1',
        cadetBlue: '#5F9EA0',
        teal: '#008080',
        darkTeal: '#004040'
    },

    // 5. Blue
    blue: {
        powderBlue: '#B0E0E6',
        lightBlue: '#ADD8E6',
        skyBlue: '#87CEEB',
        lightSkyBlue: '#87CEFA',
        cornflowerBlue: '#6495ED',
        steelBlue: '#4682B4',
        royalBlue: '#4169E1',
        pureBlue: '#0000FF',
        mediumBlue: '#0000CD',
        navyBlue: '#000080'
    },

    // 6. Purple
    purple: {
        lavenderMist: '#E6E6FA',
        thistle: '#D8BFD8',
        plum: '#DDA0DD',
        violet: '#EE82EE',
        orchid: '#DA70D6',
        mediumOrchid: '#BA55D3',
        mediumPurple: '#9370DB',
        blueViolet: '#8A2BE2',
        darkViolet: '#9400D3',
        purple: '#800080'
    },

    // 7. Pink
    pink: {
        lavenderBlush: '#FFF0F5',
        mistyRose: '#FFE4E1',
        babyPink: '#FFDADC',
        pink: '#FFC0CB',
        lightPink: '#FFB6C1',
        bubbleGumPink: '#FFA0AF',
        hotPink: '#FF69B4',
        deepPink: '#FF1493',
        mediumVioletRed: '#C71585',
        darkPink: '#BC3F60'
    },

    // 8. Orange
    orange: {
        papayaWhip: '#FFEFD5',
        peach: '#FFDAB9',
        lightPeach: '#FFD2AA',
        lightCoralOrange: '#FFBE96',
        macaroniAndCheese: '#FFB347',
        pureOrange: '#FFA500',
        darkOrange: '#FF8C00',
        coral: '#FF7F50',
        tangerine: '#ED9121',
        burntOrange: '#CC5500'
    },

    // 9. Red
    red: {
        lightSalmon: '#FFA07A',
        lightCoral: '#FF7F7F',
        tomato: '#FF6347',
        redOrange: '#FF4500',
        pureRed: '#FF0000',
        salmon: '#FA8072',
        crimson: '#DC143C',
        firebrick: '#B22222',
        darkRed: '#8B0000',
        maroon: '#800000'
    },

    // 10. Black/Gray
    blackGray: {
        lightGray: '#D3D3D3',
        silver: '#C0C0C0',
        darkGray: '#A9A9A9',
        gray: '#808080',
        dimGray: '#696969',
        smokeGray: '#5A5A5A',
        charcoal: '#404040',
        onyx: '#2D2D2D',
        jetBlack: '#202020',
        darkMode: '#1C1C1C'  // Taytrom dark mode primary color
    }
};

// TACS Color Centurial - The numerical reference system for all 100 colors
// PREFERRED: Use this for all new development
const TACS_COLOR_CENTURIAL = {
    // 1. White/Khaki
    whiteKhaki: {
        1: '#FFFFFF',  // pureWhite
        2: '#F5F5F5',  // whiteSmoke
        3: '#FFFFF0',  // ivory (faded yellowish white)
        4: '#FFF8DC',  // cornsilk (faded yellowish white)
        5: '#FAF0E6',  // linen (faded yellowish white)
        6: '#FAEBD7',  // antiqueWhite
        7: '#F5F5DC',  // beige
        8: '#FFE4B5',  // moccasin
        9: '#F5DEB3',  // wheat
        10: '#F0E68C',  // khaki
    },

    // 2. Yellow
    yellow: {
        1: '#FFFFE0',  // lightYellow (lightest)
        2: '#FAFAD2',  // lightGoldenrod
        3: '#FFFACD',  // lemonChiffon
        4: '#EEE8AA',  // paleGoldenrod
        5: '#FFEC8B',  // lightGoldenrodYellow
        6: '#FFDFBA',  // peachYellow
        7: '#FFD700',  // gold
        8: '#FFFF00',  // pureYellow
        9: '#FFC125',  // goldenYellow (intermediate)
        10: '#DAA520',  // goldenrod (darkest)
    },

    // 3. Green
    green: {
        1: '#CCFFCC',  // mintGreen (lightest)
        2: '#98FB98',  // paleGreen
        3: '#90EE90',  // lightGreen
        4: '#7CFC00',  // lawnGreen
        5: '#00FF7F',  // springGreen
        6: '#32CD32',  // limeGreen
        7: '#3CB371',  // mediumSeaGreen
        8: '#20B2AA',  // lightSeaGreen (Taytrom brand accent)
        9: '#2E8B57',  // seaGreen
        10: '#228B22',  // forestGreen (darkest)
    },

    // 4. Teal
    teal: {
        1: '#E6FFFF',  // lightCyan (lightest)
        2: '#AFEEEE',  // paleTurquoise
        3: '#7FFFD4',  // aquamarine
        4: '#00FFFF',  // cyan
        5: '#40E0D0',  // turquoise
        6: '#48D1CC',  // mediumTurquoise
        7: '#00CED1',  // darkTurquoise
        8: '#5F9EA0',  // cadetBlue
        9: '#008080',  // teal
        10: '#004040'  // darkTeal (darkest)
    },

    // 5. Blue
    blue: {
        1: '#F0F8FF',  // aliceBlue (lightest)
        2: '#B0E0E6',  // powderBlue
        3: '#ADD8E6',  // lightBlue
        4: '#87CEFA',  // lightSkyBlue
        5: '#87CEEB',  // skyBlue
        6: '#6495ED',  // cornflowerBlue
        7: '#4682B4',  // steelBlue
        8: '#4169E1',  // royalBlue
        9: '#0000FF',  // pureBlue
        10: '#000080'  // navyBlue (darkest)
    },

    // 6. Purple
    purple: {
        1: '#E6E6FA',  // lavenderMist (lightest)
        2: '#D8BFD8',  // thistle
        3: '#DDA0DD',  // plum
        4: '#EE82EE',  // violet
        5: '#DA70D6',  // orchid
        6: '#BA55D3',  // mediumOrchid
        7: '#9370DB',  // mediumPurple
        8: '#8A2BE2',  // blueViolet
        9: '#9400D3',  // darkViolet
        10: '#800080'  // purple (darkest)
    },

    // 7. Pink
    pink: {
        1: '#FFF0F5',  // lavenderBlush (lightest)
        2: '#FFE4E1',  // mistyRose
        3: '#FFDADC',  // babyPink
        4: '#FFC0CB',  // pink
        5: '#FFB6C1',  // lightPink
        6: '#FFA0AF',  // bubbleGumPink
        7: '#FF69B4',  // hotPink
        8: '#FF1493',  // deepPink
        9: '#C71585',  // mediumVioletRed
        10: '#BC3F60'  // darkPink (darkest)
    },

    // 8. Orange
    orange: {
        1: '#FFEFD5',  // papayaWhip (lightest)
        2: '#FFDAB9',  // peach
        3: '#FFD2AA',  // lightPeach
        4: '#FFBE96',  // lightCoralOrange
        5: '#FFB347',  // macaroniAndCheese
        6: '#FFA500',  // pureOrange
        7: '#FF8C00',  // darkOrange
        8: '#FF7F50',  // coral
        9: '#ED9121',  // tangerine
        10: '#CC5500'  // burntOrange (darkest)
    },

    // 9. Red
    red: {
        1: '#FFEBEE',  // lightestRed (lightest)
        2: '#FFCDD2',  // veryLightRed
        3: '#FFA07A',  // lightSalmon
        4: '#FF7F7F',  // lightCoral
        5: '#FA8072',  // salmon
        6: '#FF6347',  // tomato
        7: '#FF4500',  // redOrange
        8: '#FF0000',  // pureRed
        9: '#DC143C',  // crimson
        10: '#800000'  // maroon (darkest)
    },

    // 10. Black/Gray
    blackGray: {
        1: '#F9F9F9',  // nearWhite (lightest)
        2: '#EEEEEE',  // brighterLightGray
        3: '#DCDCDC',  // gainsboro
        4: '#D3D3D3',  // lightGray
        5: '#C0C0C0',  // silver
        6: '#A9A9A9',  // darkGray
        7: '#808080',  // gray
        8: '#696969',  // dimGray
        9: '#404040',  // charcoal
        10: '#1C1C1C'  // darkMode (Taytrom dark mode primary, darkest)
    }
};





// COLOR_PALETTES - Core palette system aligned with TACS Color Centurial
// This updated definition maintains compatibility with existing code
// while aligning with the TACS system
export const COLOR_PALETTES = {
    // Primary palette - maps to teal/blue hues
    primary: {
        50: '#F0F8FF',   // aliceBlue (blue.1)
        100: '#E6FFFF',  // lightCyan (teal.1)
        200: '#B0E0E6',  // powderBlue (blue.2)
        300: '#ADD8E6',  // lightBlue (blue.3)
        400: '#87CEEB',  // skyBlue (blue.5)
        500: '#4682B4',  // steelBlue (blue.7)
        600: '#20B2AA',  // lightSeaGreen - Taytrom brand accent (green.8)
        700: '#008080',  // teal (teal.9)
        800: '#000080',  // navyBlue (blue.10)
        900: '#1C1C1C',  // darkMode - Taytrom dark mode primary (blackGray.10)
        950: '#004040'   // darkTeal (teal.10)
    },

    // Secondary palette - maps to neutral/gray hues
    secondary: {
        50: '#F9F9F9',   // nearWhite (blackGray.1)
        100: '#F5F5F5',  // whiteSmoke (blackGray.2)
        200: '#EEEEEE',  // brighterLightGray (blackGray.2)
        300: '#E0E0E0',  // lightGray variant
        400: '#D3D3D3',  // lightGray (blackGray.4)
        500: '#C0C0C0',  // silver (blackGray.5)
        600: '#A9A9A9',  // darkGray (blackGray.6)
        700: '#808080',  // gray (blackGray.7)
        800: '#696969',  // dimGray (blackGray.8)
        900: '#404040',  // charcoal (blackGray.9)
        950: '#202020'   // jetBlack
    },

    // Neutral palette - true grayscale for text and backgrounds
    neutral: {
        50: '#FFFFFF',   // pureWhite (whiteKhaki.1)
        100: '#F5F5F5',  // whiteSmoke (whiteKhaki.2)
        200: '#EEEEEE',  // brighterLightGray (blackGray.2)
        300: '#E0E0E0',  // lightGray variant
        400: '#CCCCCC',  // lightGray variant
        500: '#A0A0A0',  // mediumGray
        600: '#808080',  // gray (blackGray.7)
        700: '#606060',  // darkGray variant
        800: '#404040',  // charcoal (blackGray.9)
        900: '#202020',  // jetBlack
        950: '#1C1C1C'   // darkMode (blackGray.10)
    },

    // Accent palette - based on Taytrom's brand accent color
    accent: {
        50: '#E6FFFA',   // lightest teal
        100: '#B2F5EA',  // very light teal
        200: '#81E6D9',  // light teal
        300: '#4FD1C5',  // medium-light teal
        400: '#38B2AC',  // medium teal
        500: '#20B2AA',  // lightSeaGreen - Taytrom brand accent (green.8)
        600: '#1D9F97',  // slightly darker teal
        700: '#198C84',  // medium-dark teal
        800: '#157A72',  // dark teal
        900: '#11695F',  // very dark teal
        950: '#0D534D'   // darkest teal
    },

    // Success palette - maps to green hues
    success: {
        50: '#CCFFCC',   // mintGreen (green.1)
        100: '#98FB98',  // paleGreen (green.2)
        200: '#90EE90',  // lightGreen (green.3)
        300: '#7CFC00',  // lawnGreen (green.4)
        400: '#00FF7F',  // springGreen (green.5)
        500: '#32CD32',  // limeGreen (green.6)
        600: '#3CB371',  // mediumSeaGreen (green.7)
        700: '#2E8B57',  // seaGreen (green.9)
        800: '#228B22',  // forestGreen (green.10)
        900: '#006400',  // darkGreen
        950: '#004D00'   // very dark green
    },

    // Warning palette - maps to yellow/amber hues
    warning: {
        50: '#FFFFE0',   // lightYellow (yellow.1)
        100: '#FFFACD',  // lemonChiffon (yellow.3)
        200: '#FFEC8B',  // lightGoldenrodYellow (yellow.5)
        300: '#FFD700',  // gold (yellow.7)
        400: '#FFC125',  // goldenYellow (yellow.9)
        500: '#FFA500',  // pureOrange (orange.6)
        600: '#FF8C00',  // darkOrange (orange.7)
        700: '#ED9121',  // tangerine (orange.9)
        800: '#DAA520',  // goldenrod (yellow.10)
        900: '#CC5500',  // burntOrange (orange.10)
        950: '#8B4000'   // darker orange
    },

    // Error palette - maps to red hues
    error: {
        50: '#FFEBEE',   // lightestRed (red.1)
        100: '#FFCDD2',  // veryLightRed (red.2)
        200: '#FFA07A',  // lightSalmon (red.3)
        300: '#FF7F7F',  // lightCoral (red.4)
        400: '#FA8072',  // salmon (red.5)
        500: '#FF6347',  // tomato (red.6)
        600: '#FF4500',  // redOrange (red.7)
        700: '#FF0000',  // pureRed (red.8)
        800: '#DC143C',  // crimson (red.9)
        900: '#800000',  // maroon (red.10)
        950: '#5C0000'   // darker maroon
    },

    // Info palette - maps to blue hues
    info: {
        50: '#F0F8FF',   // aliceBlue (blue.1)
        100: '#B0E0E6',  // powderBlue (blue.2)
        200: '#ADD8E6',  // lightBlue (blue.3)
        300: '#87CEFA',  // lightSkyBlue (blue.4)
        400: '#87CEEB',  // skyBlue (blue.5)
        500: '#6495ED',  // cornflowerBlue (blue.6)
        600: '#4682B4',  // steelBlue (blue.7)
        700: '#4169E1',  // royalBlue (blue.8)
        800: '#0000FF',  // pureBlue (blue.9)
        900: '#000080',  // navyBlue (blue.10)
        950: '#00005A'   // deeper navy
    },

    // Purple palette - for special/premium features
    purple: {
        50: '#E6E6FA',   // lavenderMist (purple.1)
        100: '#D8BFD8',  // thistle (purple.2)
        200: '#DDA0DD',  // plum (purple.3)
        300: '#EE82EE',  // violet (purple.4)
        400: '#DA70D6',  // orchid (purple.5)
        500: '#BA55D3',  // mediumOrchid (purple.6)
        600: '#9370DB',  // mediumPurple (purple.7)
        700: '#8A2BE2',  // blueViolet (purple.8)
        800: '#9400D3',  // darkViolet (purple.9)
        900: '#800080',  // purple (purple.10)
        950: '#4B0082'   // indigo
    },

    // Pink palette - for feminine/gentle UI elements
    pink: {
        50: '#FFF0F5',   // lavenderBlush (pink.1)
        100: '#FFE4E1',  // mistyRose (pink.2)
        200: '#FFDADC',  // babyPink (pink.3)
        300: '#FFC0CB',  // pink (pink.4)
        400: '#FFB6C1',  // lightPink (pink.5)
        500: '#FFA0AF',  // bubbleGumPink (pink.6)
        600: '#FF69B4',  // hotPink (pink.7)
        700: '#FF1493',  // deepPink (pink.8)
        800: '#C71585',  // mediumVioletRed (pink.9)
        900: '#BC3F60',  // darkPink (pink.10)
        950: '#8B2252'   // deeper pink
    }
};










// TACS Ultra Premium Colors - Exclusive multi-layered luxury gradients with signature finishes
const TACS_PREMIUM_COLORS = {
    // 1. White/Khaki - Refined Neutrals Collection
    whiteKhaki: {
        1: 'linear-gradient(135deg, #FFFFFF, #FAFAFA, #F8F8F8, rgba(255,255,255,0.9))',  // Diamond White Brilliance
        2: 'linear-gradient(142deg, #F5F5F5, #F2F2F2 30%, #F0F0F0 60%, rgba(245,245,245,0.92))',  // Platinum Frost Cascade
        3: 'linear-gradient(138deg, #FFFFF0, #FFFEF0 25%, #FAFAE6 70%, rgba(255,255,240,0.95))',  // Opulent Ivory Satin
        4: 'linear-gradient(150deg, #FFF8DC, #FFF6D8 30%, #FFF3D7, rgba(255,248,220,0.94))',  // Champagne Silk Glow
        5: 'linear-gradient(145deg, #FAF0E6, #F8EEE4 40%, #F5EBE1, rgba(250,240,230,0.93))',  // Cashmere Linen Caress
        6: 'linear-gradient(132deg, #FAEBD7, #F8E9D5 35%, #F5E6D2, rgba(250,235,215,0.92))',  // Antique Pearl Luminescence
        7: 'linear-gradient(148deg, #F5F5DC, #F3F3DA 45%, #F0F0D7, rgba(245,245,220,0.91))',  // Heritage Beige Elegance
        8: 'linear-gradient(140deg, #FFE4B5, #FFE2B3 30%, #FFDFB0 60%, rgba(255,228,181,0.93))',  // Gilded Moccasin Velvet
        9: 'linear-gradient(136deg, #F5DEB3, #F3DCB1 40%, #F0D9AE, rgba(245,222,179,0.94))',  // Harvest Wheat Prestige
        10: 'linear-gradient(152deg, #F0E68C, #EEE48A 35%, #EBE187 65%, rgba(240,230,140,0.92))'  // Sovereign Khaki Splendor
    },

    // 2. Yellow - Solar Radiance Collection
    yellow: {
        1: 'linear-gradient(138deg, #FFFFE0, #FFFFD0 40%, #FFFFDB, rgba(255,255,224,0.92))',  // Celestial Light Aura
        2: 'linear-gradient(144deg, #FAFAD2, #F8F8D0 30%, #F5F5CD 60%, rgba(250,250,210,0.93))',  // Golden Dawn Shimmer
        3: 'linear-gradient(135deg, #FFFACD, #FFF8CB 25%, #FFF5C8 70%, rgba(255,250,205,0.94))',  // Artisan Lemon Chiffon
        4: 'linear-gradient(150deg, #EEE8AA, #ECE6A8 35%, #E9E3A5, rgba(238,232,170,0.92))',  // Royal Gold Parchment
        5: 'linear-gradient(140deg, #FFEC8B, #FFEA89 30%, #FFE786 65%, rgba(255,236,139,0.93))',  // Majestic Yellow Diamond
        6: 'linear-gradient(148deg, #FFDFBA, #FFDDB8 40%, #FFDAB5, rgba(255,223,186,0.92))',  // Amber Peach Luminosity
        7: 'linear-gradient(155deg, #FFD700, #FFD500 25%, #F8D000 75%, rgba(255,215,0,0.95))',  // 24K Gold Essence
        8: 'linear-gradient(132deg, #FFFF00, #FDFD00 30%, #E6E600 65%, rgba(255,255,0,0.94))',  // Imperial Canary Brilliance
        9: 'linear-gradient(142deg, #FFC125, #FFBF23 35%, #F7B720 65%, rgba(255,193,37,0.93))',  // Amber Solstice Radiance
        10: 'linear-gradient(136deg, #DAA520, #D8A31E 40%, #D5A01B, rgba(218,165,32,0.92))'  // Regal Goldenrod Treasury
    },

    // 3. Green - Emerald Vitality Collection
    green: {
        1: 'linear-gradient(142deg, #CCFFCC, #CAFACA 25%, #C7FFC7 70%, rgba(204,255,204,0.93))',  // Pristine Mint Crystal
        2: 'linear-gradient(138deg, #98FB98, #96F996 30%, #93F693 65%, rgba(152,251,152,0.94))',  // Luminous Jade Whisper
        3: 'linear-gradient(145deg, #90EE90, #8EEC8E 40%, #8BE98B, rgba(144,238,144,0.92))',  // Verdant Silk Essence
        4: 'linear-gradient(152deg, #7CFC00, #7AFA00 35%, #77F700 60%, rgba(124,252,0,0.93))',  // Electric Lawn Vitality
        5: 'linear-gradient(140deg, #00FF7F, #00FD7D 25%, #00FA7A 75%, rgba(0,255,127,0.95))',  // Spring Emerald Oasis
        6: 'linear-gradient(135deg, #32CD32, #30CB30 40%, #2DC82D, rgba(50,205,50,0.94))',  // Prosperity Lime Legacy
        7: 'linear-gradient(148deg, #3CB371, #3AB16F 30%, #37AE6C 60%, rgba(60,179,113,0.92))',  // Oceanic Emerald Harmony
        8: 'linear-gradient(136deg, #20B2AA, #1EB0A8 40%, #1CADA5, rgba(32,178,170,0.93))',  // Taytrom Signature Teal
        9: 'linear-gradient(155deg, #2E8B57, #2C8955 35%, #298652, rgba(46,139,87,0.92))',  // Opulent Sea Verde
        10: 'linear-gradient(144deg, #228B22, #208920 25%, #1D861D 75%, rgba(34,139,34,0.94))'  // Noble Forest Majesty
    },

    // 4. Teal - Aquatic Luxury Collection
    teal: {
        1: 'linear-gradient(136deg, #E6FFFF, #E4FDFD 30%, #E1FAFA 65%, rgba(230,255,255,0.93))',  // Arctic Crystal Mirage
        2: 'linear-gradient(150deg, #AFEEEE, #ADECEC 40%, #AAE9E9, rgba(175,238,238,0.94))',  // Ethereal Turquoise Bliss
        3: 'linear-gradient(142deg, #7FFFD4, #7DFFD2 25%, #7AFACF 70%, rgba(127,255,212,0.92))',  // Aquamarine Jewel Cascade
        4: 'linear-gradient(138deg, #00FFFF, #00FDFD 35%, #00FAFA 60%, rgba(0,255,255,0.95))',  // Azure Cyan Paradise
        5: 'linear-gradient(145deg, #40E0D0, #3EDECD 30%, #3BDBCB 65%, rgba(64,224,208,0.93))',  // Bespoke Turquoise Haven
        6: 'linear-gradient(155deg, #48D1CC, #46CFC9 40%, #43CCC7, rgba(72,209,204,0.92))',  // Premium Turquoise Radiance
        7: 'linear-gradient(132deg, #00CED1, #00CCCF 25%, #00C9CC 75%, rgba(0,206,209,0.94))',  // Deep Horizon Lustre
        8: 'linear-gradient(144deg, #5F9EA0, #5D9C9E 35%, #5A999B 60%, rgba(95,158,160,0.93))',  // Tranquil Cadet Serenity
        9: 'linear-gradient(140deg, #008080, #007E7E 30%, #007B7B 65%, rgba(0,128,128,0.94))',  // Mystic Teal Enchantment
        10: 'linear-gradient(148deg, #004040, #003E3E 40%, #003B3B, rgba(0,64,64,0.95))'  // Midnight Teal Infinity
    },

    // 5. Blue - Sapphire Serenity Collection
    blue: {
        1: 'linear-gradient(140deg, #F0F8FF, #EEF6FD 30%, #EBF3FA 65%, rgba(240,248,255,0.93))',  // Celestial Alice Silk
        2: 'linear-gradient(152deg, #B0E0E6, #ADDEE4 40%, #ABDBE1, rgba(176,224,230,0.94))',  // Powder Sapphire Cascade
        3: 'linear-gradient(138deg, #ADD8E6, #ABD6E4 25%, #A8D3E1 70%, rgba(173,216,230,0.92))',  // Azure Tranquility Whisper
        4: 'linear-gradient(145deg, #87CEFA, #85CCF8 35%, #82C9F5 60%, rgba(135,206,250,0.93))',  // Ethereal Sky Aurora
        5: 'linear-gradient(136deg, #87CEEB, #85CCE9 30%, #82C9E6 65%, rgba(135,206,235,0.92))',  // Pristine Azure Symphony
        6: 'linear-gradient(150deg, #6495ED, #6293EB 40%, #5F90E8, rgba(100,149,237,0.95))',  // Royal Cornflower Heritage
        7: 'linear-gradient(142deg, #4682B4, #447FB2 25%, #417DAF 75%, rgba(70,130,180,0.94))',  // Steel Azure Fortitude
        8: 'linear-gradient(155deg, #4169E1, #3F67DF 35%, #3C64DC 60%, rgba(65,105,225,0.93))',  // Sovereign Blue Splendor
        9: 'linear-gradient(132deg, #0000FF, #0000FD 30%, #0000FA 65%, rgba(0,0,255,0.94))',  // Cobalt Intense Luster
        10: 'linear-gradient(144deg, #000080, #00007E 40%, #00007B, rgba(0,0,128,0.95))'  // Noble Navy Command
    },

    // 6. Purple - Imperial Violet Collection
    purple: {
        1: 'linear-gradient(148deg, #E6E6FA, #E4E4F8 30%, #E1E1F5 65%, rgba(230,230,250,0.93))',  // Lavender Mist Serenade
        2: 'linear-gradient(135deg, #D8BFD8, #D6BDD6 40%, #D3BAD3, rgba(216,191,216,0.94))',  // Thistle Velvet Estate
        3: 'linear-gradient(142deg, #DDA0DD, #DB9EDB 25%, #D89BD8 70%, rgba(221,160,221,0.92))',  // Orchid Plum Opulence
        4: 'linear-gradient(136deg, #EE82EE, #EC80EC 35%, #E97DE9 60%, rgba(238,130,238,0.93))',  // Violet Magic Essence
        5: 'linear-gradient(150deg, #DA70D6, #D86ED4 30%, #D56BD1 65%, rgba(218,112,214,0.92))',  // Orchid Mystique Prism
        6: 'linear-gradient(140deg, #BA55D3, #B853D1 40%, #B550CE, rgba(186,85,211,0.95))',  // Amethyst Radiance Legacy
        7: 'linear-gradient(155deg, #9370DB, #916ED9 25%, #8E6BD6 75%, rgba(147,112,219,0.94))',  // Regal Purple Mystery
        8: 'linear-gradient(138deg, #8A2BE2, #8829E0 35%, #8526DD 60%, rgba(138,43,226,0.93))',  // Imperial Violet Grandeur
        9: 'linear-gradient(144deg, #9400D3, #9200D1 30%, #8F00CE 65%, rgba(148,0,211,0.94))',  // Majestic Violet Splendor
        10: 'linear-gradient(132deg, #800080, #7E007E 40%, #7B007B, rgba(128,0,128,0.95))'  // Royal Purple Sovereignty
    },

    // 7. Pink - Luxe Rosé Collection
    pink: {
        1: 'linear-gradient(136deg, #FFF0F5, #FDEEF3 30%, #FAEBF0 65%, rgba(255,240,245,0.93))',  // Blush Petal Whisper
        2: 'linear-gradient(150deg, #FFE4E1, #FDE2DF 40%, #FADFDC, rgba(255,228,225,0.94))',  // Bespoke Rose Mist
        3: 'linear-gradient(142deg, #FFDADC, #FDD8DA 25%, #FAD5D7 70%, rgba(255,218,220,0.92))',  // Cherished Pink Innocence
        4: 'linear-gradient(138deg, #FFC0CB, #FDBEC9 35%, #FABBC6 60%, rgba(255,192,203,0.93))',  // Couture Pink Satin
        5: 'linear-gradient(145deg, #FFB6C1, #FDB4BF 30%, #FAB1BC 65%, rgba(255,182,193,0.92))',  // Rosé Petal Cascade
        6: 'linear-gradient(155deg, #FFA0AF, #FD9EAD 40%, #FA9BAA, rgba(255,160,175,0.95))',  // Blushing Peony Luxe
        7: 'linear-gradient(132deg, #FF69B4, #FD67B2 25%, #FA64AF 75%, rgba(255,105,180,0.94))',  // Hot Pink Prestige
        8: 'linear-gradient(144deg, #FF1493, #FD1291 35%, #FA0F8E 60%, rgba(255,20,147,0.93))',  // Fuchsia Elite Allure
        9: 'linear-gradient(140deg, #C71585, #C51383 30%, #C21080 65%, rgba(199,21,133,0.94))',  // Mulberry Violet Passion
        10: 'linear-gradient(148deg, #BC3F60, #BA3C5E 40%, #B73A5B, rgba(188,63,96,0.95))'  // Bordeaux Rose Nobility
    },

    // 8. Orange - Amber Flame Collection
    orange: {
        1: 'linear-gradient(142deg, #FFEFD5, #FEEDD3 30%, #FAEAD0 65%, rgba(255,239,213,0.93))',  // Papaya Whip Nectar
        2: 'linear-gradient(138deg, #FFDAB9, #FDD8B7 40%, #FAD5B4, rgba(255,218,185,0.94))',  // Heirloom Peach Silk
        3: 'linear-gradient(145deg, #FFD2AA, #FDD0A8 25%, #FACDA5 70%, rgba(255,210,170,0.92))',  // Amber Dawn Radiance
        4: 'linear-gradient(152deg, #FFBE96, #FDBC94 35%, #FAB991 60%, rgba(255,190,150,0.93))',  // Coral Sunset Aura
        5: 'linear-gradient(140deg, #FFB347, #FDB145 30%, #FAAE42 65%, rgba(255,179,71,0.92))',  // Artisan Gold Amber
        6: 'linear-gradient(135deg, #FFA500, #FDA300 40%, #FA9F00, rgba(255,165,0,0.95))',  // Pure Tangerine Luminance
        7: 'linear-gradient(148deg, #FF8C00, #FD8A00 25%, #FA8700 75%, rgba(255,140,0,0.94))',  // Amber Flame Brilliance
        8: 'linear-gradient(136deg, #FF7F50, #FD7D4E 35%, #FA7A4B 60%, rgba(255,127,80,0.93))',  // Coral Sunset Paradise
        9: 'linear-gradient(155deg, #ED9121, #EB8F1F 30%, #E88C1C 65%, rgba(237,145,33,0.94))',  // Exotic Tangerine Elixir
        10: 'linear-gradient(144deg, #CC5500, #CA5300 40%, #C75000, rgba(204,85,0,0.95))'  // Burnt Amber Legacy
    },

    // 9. Red - Ruby Intensity Collection
    red: {
        1: 'linear-gradient(136deg, #FFEBEE, #FDE9EC 30%, #FAE6E9 65%, rgba(255,235,238,0.93))',  // Ethereal Blush Whisper
        2: 'linear-gradient(150deg, #FFCDD2, #FDCBD0 40%, #FAC8CD, rgba(255,205,210,0.94))',  // Rose Quartz Essence
        3: 'linear-gradient(142deg, #FFA07A, #FD9E78 25%, #FA9B75 70%, rgba(255,160,122,0.92))',  // Coral Silk Luminescence
        4: 'linear-gradient(138deg, #FF7F7F, #FD7D7D 35%, #FA7A7A 60%, rgba(255,127,127,0.93))',  // Blushing Coral Passion
        5: 'linear-gradient(145deg, #FA8072, #F87E70 30%, #F57B6D 65%, rgba(250,128,114,0.92))',  // Salmon Velvet Aura
        6: 'linear-gradient(155deg, #FF6347, #FD6145 40%, #FA5E42, rgba(255,99,71,0.95))',  // Tomato Vermillion Splendor
        7: 'linear-gradient(132deg, #FF4500, #FD4300 25%, #FA4000 75%, rgba(255,69,0,0.94))',  // Imperial Crimson Fire
        8: 'linear-gradient(144deg, #FF0000, #FD0000 35%, #FA0000 60%, rgba(255,0,0,0.93))',  // Pure Ruby Majesty
        9: 'linear-gradient(140deg, #DC143C, #DA123A 30%, #D70F37 65%, rgba(220,20,60,0.94))',  // Crimson Royal Intensity
        10: 'linear-gradient(148deg, #800000, #7E0000 40%, #7B0000, rgba(128,0,0,0.95))'  // Sovereign Maroon Dynasty
    },

    // 10. Black/Gray - Monochrome Elite Collection
    blackGray: {
        1: 'linear-gradient(142deg, #F9F9F9, #F7F7F7 30%, #F4F4F4 65%, rgba(249,249,249,0.93))',  // Platinum White Perfection
        2: 'linear-gradient(138deg, #EEEEEE, #ECECEC 40%, #E9E9E9, rgba(238,238,238,0.94))',  // Silver Frost Brilliance
        3: 'linear-gradient(145deg, #DCDCDC, #DADADA 25%, #D7D7D7 70%, rgba(220,220,220,0.92))',  // Gainsboro Silver Heritage
        4: 'linear-gradient(152deg, #D3D3D3, #D1D1D1 35%, #CECECE 60%, rgba(211,211,211,0.93))',  // Light Platinum Symphony
        5: 'linear-gradient(140deg, #C0C0C0, #BEBEBE 30%, #BBBBBB 65%, rgba(192,192,192,0.92))',  // Sterling Silver Radiance
        6: 'linear-gradient(135deg, #A9A9A9, #A7A7A7 40%, #A4A4A4, rgba(169,169,169,0.95))',  // Refined Graphite Elegance
        7: 'linear-gradient(148deg, #808080, #7E7E7E 25%, #7B7B7B 75%, rgba(128,128,128,0.94))',  // Balanced Slate Authority
        8: 'linear-gradient(136deg, #696969, #676767 35%, #646464 60%, rgba(105,105,105,0.93))',  // Dark Graphite Mystique
        9: 'linear-gradient(155deg, #404040, #3E3E3E 30%, #3B3B3B 65%, rgba(64,64,64,0.94))',  // Obsidian Charcoal Prestige
        10: 'linear-gradient(144deg, #1C1C1C, #1A1A1A 40%, #171717, rgba(28,28,28,0.95))'  // Noir Elite Excellence
    }
};

// Add premium metallic finish effects
const PREMIUM_EFFECTS = {
    satin: 'linear-gradient(to right, rgba(255,255,255,0.1), rgba(255,255,255,0.15), rgba(255,255,255,0.05))',
    pearl: 'radial-gradient(circle at 30% 30%, rgba(255,255,255,0.15), rgba(255,255,255,0.05) 60%)',
    metallic: 'linear-gradient(to bottom right, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0.1) 60%, rgba(255,255,255,0.2) 100%)',
    gloss: 'linear-gradient(160deg, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0.03) 50%, rgba(0,0,0,0.03) 100%)',
    matte: 'linear-gradient(to right, rgba(0,0,0,0.02), rgba(0,0,0,0.01), rgba(0,0,0,0.03))'
};


// Theme-specific color sets
const THEME_COLORS = {
    // Light theme contextual colors
    light: {
        text: {
            primary: COLOR_PALETTES.neutral[900],
            secondary: COLOR_PALETTES.neutral[700],
            tertiary: COLOR_PALETTES.neutral[500],
            inverse: COLOR_PALETTES.neutral[50]
        },
        background: {
            surface: COLOR_PALETTES.primary[50], // White
            elevated: COLOR_PALETTES.primary[50], // White
            sunken: COLOR_PALETTES.secondary[200],
            interactive: COLOR_PALETTES.secondary[300]
        },
        border: {
            subtle: COLOR_PALETTES.secondary[200],
            strong: COLOR_PALETTES.secondary[300]
        },
        shadow: {
            ambient: 'rgba(0, 0, 0, 0.05)',
            elevated: 'rgba(0, 0, 0, 0.1)',
            strong: 'rgba(0, 0, 0, 0.2)'
        },
        overlay: 'rgba(0, 0, 0, 0.4)',
        accent: COLOR_PALETTES.accent[500] // #20B2AA
    },

    // Dark theme contextual colors
    dark: {
        text: {
            primary: COLOR_PALETTES.primary[50], // White
            secondary: COLOR_PALETTES.secondary[300],
            tertiary: COLOR_PALETTES.secondary[500],
            inverse: COLOR_PALETTES.primary[900]
        },
        background: {
            surface: COLOR_PALETTES.primary[900], // #1C1C1C
            elevated: '#2A2A2A',
            sunken: '#151515',
            interactive: '#333333'
        },
        border: {
            subtle: COLOR_PALETTES.neutral[800],
            strong: COLOR_PALETTES.neutral[700]
        },
        shadow: {
            ambient: 'rgba(0, 0, 0, 0.3)',
            elevated: 'rgba(0, 0, 0, 0.5)',
            strong: 'rgba(0, 0, 0, 0.7)'
        },
        overlay: 'rgba(0, 0, 0, 0.7)',
        accent: COLOR_PALETTES.accent[500] // #20B2AA
    }
};

// Gradient presets for various uses - Enhanced for luxury and sleekness
const GRADIENT_PRESETS = {
    primary: {
        light: 'linear-gradient(135deg, #FFFFFF, #F0F0F0)',
        dark: 'linear-gradient(135deg, #2A2A2A, #1C1C1C)'
    },
    secondary: {
        light: 'linear-gradient(135deg, #F5F5F5, #E0E0E0)',
        dark: 'linear-gradient(135deg, #333333, #262626)'
    },
    accent: {
        light: 'linear-gradient(135deg, #26C6C3, #20B2AA)',
        dark: 'linear-gradient(135deg, #20B2AA, #1A8A84)'
    },
    success: {
        light: 'linear-gradient(135deg, #84fab0, #8fd3f4)',
        dark: 'linear-gradient(135deg, #52c234, #061700)'
    },
    error: {
        light: 'linear-gradient(135deg, #ff6b6b, #ff8e8e)',
        dark: 'linear-gradient(135deg, #cb356b, #bd3f32)'
    },
    warning: {
        light: 'linear-gradient(135deg, #ffd166, #ffb347)',
        dark: 'linear-gradient(135deg, #f9d423, #e65c00)'
    },
    info: {
        light: 'linear-gradient(135deg, #6a11cb, #2575fc)',
        dark: 'linear-gradient(135deg, #3a7bd5, #3a6073)'
    },
    sunset: {
        light: 'linear-gradient(135deg, #FF8C42, #F25767)',
        dark: 'linear-gradient(135deg, #FF416C, #FF4B2B)'
    },
    ocean: {
        light: 'linear-gradient(135deg, #4A90E2, #7442C8)',
        dark: 'linear-gradient(135deg, #396afc, #2948ff)'
    },
    forest: {
        light: 'linear-gradient(135deg, #3BDA83, #0F998B)',
        dark: 'linear-gradient(135deg, #24FE41, #FDFC47)'
    },
    night: {
        light: 'linear-gradient(135deg, #232526, #414345)',
        dark: 'linear-gradient(135deg, #0F2027, #203A43, #2C5364)'
    },
    dawn: {
        light: 'linear-gradient(135deg, #F093FB, #F5576C)',
        dark: 'linear-gradient(135deg, #5f2c82, #49a09d)'
    },
    cosmic: {
        light: 'linear-gradient(135deg, #3A1C71, #D76D77, #FFAF7B)',
        dark: 'linear-gradient(135deg, #360033, #0b8793)'
    },
    neural: {
        light: 'linear-gradient(135deg, #4facfe, #00f2fe)',
        dark: 'linear-gradient(135deg, #0F2027, #203A43)'
    },
    // Luxury gradients for Taytrom
    luxury: {
        light: 'linear-gradient(135deg, #FFFFFF, #F0F0F0, #E6E6E6)',
        dark: 'linear-gradient(135deg, #2A2A2A, #1C1C1C, #151515)'
    },
    metallic: {
        light: 'linear-gradient(135deg, #D7D7D7, #EFEFEF, #D7D7D7)',
        dark: 'linear-gradient(135deg, #333333, #444444, #333333)'
    },
    plasma: {
        light: 'linear-gradient(135deg, #20B2AA, #26C6BE, #20B2AA)',
        dark: 'linear-gradient(135deg, #1A8A84, #20B2AA, #1A8A84)'
    }
};

// Glassmorphic effect presets
const GLASS_EFFECTS = {
    light: {
        subtle: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(5px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.3)'
        },
        medium: {
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.4)'
        },
        strong: {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(16px)',
            boxShadow: '0 12px 48px rgba(0, 0, 0, 0.15)',
            border: '1px solid rgba(255, 255, 255, 0.5)'
        },
        accentSubtle: {
            backgroundColor: 'rgba(32, 178, 170, 0.1)',
            backdropFilter: 'blur(5px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(32, 178, 170, 0.2)'
        },
        accentMedium: {
            backgroundColor: 'rgba(32, 178, 170, 0.2)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(32, 178, 170, 0.3)'
        },
        accentStrong: {
            backgroundColor: 'rgba(32, 178, 170, 0.3)',
            backdropFilter: 'blur(16px)',
            boxShadow: '0 12px 48px rgba(0, 0, 0, 0.15)',
            border: '1px solid rgba(32, 178, 170, 0.4)'
        }
    },
    dark: {
        subtle: {
            backgroundColor: 'rgba(28, 28, 28, 0.5)',
            backdropFilter: 'blur(5px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
            border: '1px solid rgba(50, 50, 50, 0.3)'
        },
        medium: {
            backgroundColor: 'rgba(28, 28, 28, 0.6)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
            border: '1px solid rgba(50, 50, 50, 0.4)'
        },
        strong: {
            backgroundColor: 'rgba(28, 28, 28, 0.7)',
            backdropFilter: 'blur(16px)',
            boxShadow: '0 12px 48px rgba(0, 0, 0, 0.25)',
            border: '1px solid rgba(50, 50, 50, 0.5)'
        },
        accentSubtle: {
            backgroundColor: 'rgba(32, 178, 170, 0.1)',
            backdropFilter: 'blur(5px)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
            border: '1px solid rgba(32, 178, 170, 0.2)'
        },
        accentMedium: {
            backgroundColor: 'rgba(32, 178, 170, 0.15)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
            border: '1px solid rgba(32, 178, 170, 0.25)'
        },
        accentStrong: {
            backgroundColor: 'rgba(32, 178, 170, 0.2)',
            backdropFilter: 'blur(16px)',
            boxShadow: '0 12px 48px rgba(0, 0, 0, 0.25)',
            border: '1px solid rgba(32, 178, 170, 0.3)'
        }
    }
};

// 3D Shadow presets for depth and luxury
const SHADOW_PRESETS = {
    light: {
        subtle: '0 1px 2px rgba(0, 0, 0, 0.05)',
        card: '0 4px 8px rgba(0, 0, 0, 0.08)',
        elevated: '0 8px 16px rgba(0, 0, 0, 0.1)',
        floating: '0 16px 32px rgba(0, 0, 0, 0.12)',
        dropdown: '0 2px 8px rgba(0, 0, 0, 0.15)',
        modal: '0 24px 48px rgba(0, 0, 0, 0.2)',
        luxe: '0 30px 60px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.1)',
        inner: 'inset 0 2px 4px rgba(0, 0, 0, 0.05)',
        glow: '0 0 20px rgba(32, 178, 170, 0.3)',
        layered: '0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12)'
    },
    dark: {
        subtle: '0 1px 2px rgba(0, 0, 0, 0.2)',
        card: '0 4px 8px rgba(0, 0, 0, 0.25)',
        elevated: '0 8px 16px rgba(0, 0, 0, 0.3)',
        floating: '0 16px 32px rgba(0, 0, 0, 0.35)',
        dropdown: '0 2px 8px rgba(0, 0, 0, 0.4)',
        modal: '0 24px 48px rgba(0, 0, 0, 0.5)',
        luxe: '0 30px 60px rgba(0, 0, 0, 0.6), 0 10px 20px rgba(0, 0, 0, 0.4)',
        inner: 'inset 0 2px 4px rgba(0, 0, 0, 0.2)',
        glow: '0 0 20px rgba(32, 178, 170, 0.25)',
        layered: '0 1px 1px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.3), 0 4px 4px rgba(0, 0, 0, 0.3), 0 8px 8px rgba(0, 0, 0, 0.3)'
    }
};

// Microinteraction presets to create profound user experiences
const MICRO_INTERACTIONS = {
    transitions: {
        fast: 'all 0.1s ease-in-out',
        default: 'all 0.2s ease-in-out',
        smooth: 'all 0.3s ease-in-out',
        slow: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
        spring: 'all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
        bounce: 'all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
        elegant: 'all 0.7s cubic-bezier(0.19, 1, 0.22, 1)'
    },
    hover: {
        lift: {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)'
        },
        grow: {
            transform: 'scale(1.02)',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)'
        },
        glow: {
            boxShadow: '0 0 20px rgba(32, 178, 170, 0.4)'
        },
        highlight: {
            boxShadow: 'inset 0 0 0 2px #20B2AA'
        },
        pulse: {
            animation: 'pulse 1.5s infinite'
        }
    },
    active: {
        press: {
            transform: 'scale(0.98)'
        },
        sink: {
            transform: 'translateY(2px)'
        }
    },
    animations: {
        fadeIn: 'fade-in 0.3s ease-in',
        slideIn: 'slide-in 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        pulse: 'pulse 1.5s infinite',
        float: 'float 3s ease-in-out infinite',
        shimmer: 'shimmer 2s linear infinite',
        spin: 'spin 1s linear infinite'
    }
};

// Color accessibility helpers
const COLOR_ACCESSIBILITY = {
    // WCAG contrast ratio thresholds
    contrastThresholds: {
        aa: {
            normal: 4.5,
            large: 3.0
        },
        aaa: {
            normal: 7.0,
            large: 4.5
        }
    },

    // Color blindness simulations
    colorBlindness: {
        protanopia: 'filter: url("#protanopia")',
        deuteranopia: 'filter: url("#deuteranopia")',
        tritanopia: 'filter: url("#tritanopia")',
        achromatopsia: 'filter: grayscale(100%)'
    }
};

// Color utility functions
const calculateLuminance = (hexColor) => {
    logWx4H(
        'calculateLuminance',
        'Calculate color luminance',
        'To determine contrast ratios for accessibility',
        'Uses the relative luminance formula from WCAG 2.0'
    );

    // Convert hex to RGB
    let r, g, b;
    if (hexColor.startsWith('#')) {
        const hex = hexColor.slice(1);
        r = parseInt(hex.length === 3 ? hex[0] + hex[0] : hex.slice(0, 2), 16) / 255;
        g = parseInt(hex.length === 3 ? hex[1] + hex[1] : hex.slice(2, 4), 16) / 255;
        b = parseInt(hex.length === 3 ? hex[2] + hex[2] : hex.slice(4, 6), 16) / 255;
    } else if (hexColor.startsWith('rgb')) {
        const rgbMatch = hexColor.match(/(\d+)\s*,\s*(\d+)\s*,\s*(\d+)/);
        if (rgbMatch) {
            r = parseInt(rgbMatch[1], 10) / 255;
            g = parseInt(rgbMatch[2], 10) / 255;
            b = parseInt(rgbMatch[3], 10) / 255;
        } else {
            return 0;
        }
    } else {
        return 0;
    }

    // Handle sRGB
    r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    // Luminance formula
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const calculateContrastRatio = (color1, color2) => {
    logWx4H(
        'calculateContrastRatio',
        'Calculate contrast ratio between colors',
        'To ensure accessible color combinations',
        'Divides the lighter color\'s luminance by the darker one'
    );

    const luminance1 = calculateLuminance(color1);
    const luminance2 = calculateLuminance(color2);

    const lighter = Math.max(luminance1, luminance2);
    const darker = Math.min(luminance1, luminance2);

    return (lighter + 0.05) / (darker + 0.05);
};

const isAccessible = (foreground, background, level = 'aa', size = 'normal') => {
    logWx4H(
        'isAccessible',
        'Check if color combination is accessible',
        'To validate color choices against WCAG standards',
        'Compares contrast ratio to required thresholds'
    );

    const contrastRatio = calculateContrastRatio(foreground, background);
    return contrastRatio >= COLOR_ACCESSIBILITY.contrastThresholds[level][size];
};

const getAccessibleTextColor = (backgroundColor) => {
    logWx4H(
        'getAccessibleTextColor',
        'Get accessible text color for background',
        'To ensure text is readable against any background',
        'Chooses between white and black based on background luminance'
    );

    const luminance = calculateLuminance(backgroundColor);
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const hexToRgba = (hex, alpha = 1) => {
    logWx4H(
        'hexToRgba',
        'Convert hex color to rgba',
        'To add transparency to colors',
        'Parses hex value and returns rgba format'
    );

    if (!hex || typeof hex !== 'string') {
        return `rgba(0, 0, 0, ${alpha})`;
    }

    let r, g, b;
    if (hex.startsWith('#')) {
        const cleanHex = hex.slice(1);
        r = parseInt(cleanHex.length === 3 ? cleanHex[0] + cleanHex[0] : cleanHex.slice(0, 2), 16);
        g = parseInt(cleanHex.length === 3 ? cleanHex[1] + cleanHex[1] : cleanHex.slice(2, 4), 16);
        b = parseInt(cleanHex.length === 3 ? cleanHex[2] + cleanHex[2] : cleanHex.slice(4, 6), 16);
    } else {
        return hex; // Not a hex color
    }

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                                   COLOR SYSTEM COMPONENT
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

/**
 * Accepted_Colors_GeneralWebpages_Templates - A comprehensive color management system for web applications
 */
const Accepted_Colors_GeneralWebpages_Templates = {
    /**
     * Get the color value from the color palette
     * @param {string} palette - The color palette name (primary, secondary, etc.)
     * @param {number|string} shade - The shade value (50, 100, 200, ..., 900, 950)
     * @returns {string} The color value
     */
    getColor: (palette, shade = 500) => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getColor',
            'Get color from palette',
            'To access standardized colors with semantic meaning',
            'Returns the color value based on palette and shade'
        );

        try {
            if (!COLOR_PALETTES[palette]) {
                palette = 'primary';
            }

            if (!COLOR_PALETTES[palette][shade]) {
                shade = 500;
            }

            return COLOR_PALETTES[palette][shade];
        } catch (error) {
            return COLOR_PALETTES.primary[500]; // Default fallback
        }
    },

    /**
     * Get a theme-specific color based on the current theme context
     * @param {boolean} isDark - Whether dark theme is active
     * @param {string} category - The color category (text, background, border, shadow)
     * @param {string} variant - The variant within the category (primary, secondary, etc.)
     * @returns {string} The theme-appropriate color
     */
    getThemeColor: (isDark, category, variant) => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getThemeColor',
            'Get theme-specific color',
            'To ensure proper colors for current theme',
            'Returns the appropriate color based on theme, category, and variant'
        );

        try {
            const themeSet = isDark ? THEME_COLORS.dark : THEME_COLORS.light;

            if (!themeSet[category]) {
                return isDark ? '#FFFFFF' : '#000000'; // Fallback
            }

            if (!themeSet[category][variant]) {
                return Object.values(themeSet[category])[0]; // Return first value as fallback
            }

            return themeSet[category][variant];
        } catch (error) {
            return isDark ? '#FFFFFF' : '#000000'; // Ultimate fallback
        }
    },

    /**
     * Get a gradient preset based on the current theme
     * @param {boolean} isDark - Whether dark theme is active
     * @param {string} name - The gradient preset name
     * @returns {string} The gradient CSS value
     */
    getGradient: (isDark, name = 'primary') => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getGradient',
            'Get theme-specific gradient',
            'To provide beautiful gradient backgrounds',
            'Returns the appropriate gradient based on theme and preset name'
        );

        try {
            if (!GRADIENT_PRESETS[name]) {
                name = 'primary';
            }

            return GRADIENT_PRESETS[name][isDark ? 'dark' : 'light'];
        } catch (error) {
            return GRADIENT_PRESETS.primary[isDark ? 'dark' : 'light']; // Fallback
        }
    },

    /**
     * Get a glassmorphic effect preset based on the current theme
     * @param {boolean} isDark - Whether dark theme is active
     * @param {string} intensity - The intensity of the effect (subtle, medium, strong)
     * @param {boolean} accent - Whether to use accent color in the glass effect
     * @returns {Object} The glassmorphic effect style object
     */
    getGlassEffect: (isDark, intensity = 'medium', accent = false) => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getGlassEffect',
            'Get glassmorphic effect',
            'To create modern, translucent UI elements',
            'Returns appropriate glass effect style based on theme and intensity'
        );

        try {
            const themeSet = isDark ? GLASS_EFFECTS.dark : GLASS_EFFECTS.light;
            const effectKey = accent ? `accent${intensity.charAt(0).toUpperCase() + intensity.slice(1)}` : intensity;

            if (!themeSet[effectKey]) {
                return themeSet[accent ? 'accentMedium' : 'medium'];
            }

            return themeSet[effectKey];
        } catch (error) {
            return isDark ? GLASS_EFFECTS.dark.medium : GLASS_EFFECTS.light.medium; // Fallback
        }
    },

    /**
     * Get a shadow preset based on the current theme
     * @param {boolean} isDark - Whether dark theme is active
     * @param {string} type - The shadow type (subtle, card, elevated, etc.)
     * @returns {string} The shadow CSS value
     */
    getShadow: (isDark, type = 'card') => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getShadow',
            'Get theme-specific shadow',
            'To add depth and dimension to UI elements',
            'Returns appropriate shadow based on theme and type'
        );

        try {
            const themeSet = isDark ? SHADOW_PRESETS.dark : SHADOW_PRESETS.light;

            if (!themeSet[type]) {
                return themeSet.card;
            }

            return themeSet[type];
        } catch (error) {
            return isDark ? SHADOW_PRESETS.dark.card : SHADOW_PRESETS.light.card; // Fallback
        }
    },

    /**
     * Get a microinteraction preset
     * @param {string} category - The interaction category (transitions, hover, active, animations)
     * @param {string} type - The interaction type within the category
     * @returns {string|Object} The interaction CSS value or style object
     */
    getMicroInteraction: (category, type) => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getMicroInteraction',
            'Get microinteraction preset',
            'To create engaging and responsive UI interactions',
            'Returns interaction style based on category and type'
        );

        try {
            if (!MICRO_INTERACTIONS[category]) {
                return MICRO_INTERACTIONS.transitions.default;
            }

            if (!MICRO_INTERACTIONS[category][type]) {
                return Object.values(MICRO_INTERACTIONS[category])[0];
            }

            return MICRO_INTERACTIONS[category][type];
        } catch (error) {
            return MICRO_INTERACTIONS.transitions.default; // Fallback
        }
    },

    /**
     * Create a custom gradient with the specified colors
     * @param {string} angle - The gradient angle (e.g., '45deg', 'to right')
     * @param {string[]} colors - Array of colors to use in the gradient
     * @returns {string} The custom gradient CSS value
     */
    createGradient: (angle = '135deg', colors = []) => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.createGradient',
            'Create custom gradient',
            'To generate unique gradients from specified colors',
            'Constructs linear-gradient with given angle and colors'
        );

        try {
            if (!colors || colors.length < 2) {
                return GRADIENT_PRESETS.primary.light;
            }

            return `linear-gradient(${angle}, ${colors.join(', ')})`;
        } catch (error) {
            return GRADIENT_PRESETS.primary.light; // Fallback
        }
    },

    /**
     * Get an accessible text color for a given background
     * @param {string} backgroundColor - The background color to check against
     * @param {string} preferredColor - Preferred text color if accessible
     * @param {string} level - Accessibility level ('aa' or 'aaa')
     * @returns {string} An accessible text color
     */
    getAccessibleTextColor: (backgroundColor, preferredColor = null, level = 'aa') => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getAccessibleTextColor',
            'Get accessible text color',
            'To ensure readable text on any background',
            'Finds color with sufficient contrast for accessibility'
        );

        // Default options are black and white
        const defaultOptions = ['#000000', '#FFFFFF'];

        try {
            // If preferred color is provided and meets contrast requirements, use it
            if (preferredColor && isAccessible(preferredColor, backgroundColor, level)) {
                return preferredColor;
            }

            // Otherwise, choose between black and white based on background luminance
            return getAccessibleTextColor(backgroundColor);
        } catch (error) {
            return '#000000'; // Default to black as fallback
        }
    },

    /**
     * Apply an alpha/transparency value to a color
     * @param {string} color - The base color (hex, rgb, etc.)
     * @param {number} alpha - The alpha value (0-1)
     * @returns {string} Color with applied transparency
     */
    withAlpha: (color, alpha = 1) => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.withAlpha',
            'Apply transparency to color',
            'To create semi-transparent variations of colors',
            'Converts color to rgba format with specified alpha'
        );

        try {
            if (!color) {
                return `rgba(0, 0, 0, ${alpha})`;
            }

            return hexToRgba(color, alpha);
        } catch (error) {
            return color; // Return original color on error
        }
    },

    /**
     * Check if a color combination is accessible
     * @param {string} foregroundColor - Text or foreground color
     * @param {string} backgroundColor - Background color
     * @param {string} level - WCAG level ('aa' or 'aaa')
     * @param {string} size - Text size category ('normal' or 'large')
     * @returns {boolean} Whether the combination is accessible
     */
    isAccessible: (foregroundColor, backgroundColor, level = 'aa', size = 'normal') => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.isAccessible',
            'Check color accessibility',
            'To validate color combinations against WCAG standards',
            'Calculates contrast ratio and compares to required thresholds'
        );

        try {
            return isAccessible(foregroundColor, backgroundColor, level, size);
        } catch (error) {
            return false; // Conservative fallback
        }
    },

    /**
     * Generate a color scale between two colors
     * @param {string} startColor - Starting color (hex)
     * @param {string} endColor - Ending color (hex)
     * @param {number} steps - Number of colors in the scale
     * @returns {string[]} Array of colors in the scale
     */
    generateColorScale: (startColor, endColor, steps = 5) => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.generateColorScale',
            'Generate color scale',
            'To create smooth color transitions between two colors',
            'Interpolates between colors for the specified number of steps'
        );

        try {
            const scale = [];

            // Parse start color
            let startR, startG, startB;
            if (startColor.startsWith('#')) {
                const hex = startColor.slice(1);
                startR = parseInt(hex.slice(0, 2), 16);
                startG = parseInt(hex.slice(2, 4), 16);
                startB = parseInt(hex.slice(4, 6), 16);
            } else {
                startR = startG = startB = 0;
            }

            // Parse end color
            let endR, endG, endB;
            if (endColor.startsWith('#')) {
                const hex = endColor.slice(1);
                endR = parseInt(hex.slice(0, 2), 16);
                endG = parseInt(hex.slice(2, 4), 16);
                endB = parseInt(hex.slice(4, 6), 16);
            } else {
                endR = endG = endB = 255;
            }

            // Calculate step size for each color component
            const stepR = (endR - startR) / (steps - 1);
            const stepG = (endG - startG) / (steps - 1);
            const stepB = (endB - startB) / (steps - 1);

            // Generate the color scale
            for (let i = 0; i < steps; i++) {
                const r = Math.round(startR + (stepR * i));
                const g = Math.round(startG + (stepG * i));
                const b = Math.round(startB + (stepB * i));
                scale.push(`#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`);
            }

            return scale;
        } catch (error) {
            return [startColor, endColor]; // Simple fallback
        }
    },

    /**
     * Get a color that harmonizes with the input color
     * @param {string} baseColor - The base color to harmonize with
     * @param {string} harmonyType - Type of harmony ('complementary', 'analogous', 'triadic')
     * @returns {string|string[]} The harmonizing color(s)
     */
    getHarmoniousColor: (baseColor, harmonyType = 'complementary') => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getHarmoniousColor',
            'Get harmonious color',
            'To create aesthetically pleasing color combinations',
            'Applies color theory principles to generate harmonious colors'
        );

        try {
            // Convert to HSL for easier harmony calculations
            let r, g, b;
            if (baseColor.startsWith('#')) {
                const hex = baseColor.slice(1);
                r = parseInt(hex.slice(0, 2), 16) / 255;
                g = parseInt(hex.slice(2, 4), 16) / 255;
                b = parseInt(hex.slice(4, 6), 16) / 255;
            } else {
                return baseColor; // Can't process non-hex colors
            }

            // Convert RGB to HSL
            const max = Math.max(r, g, b);
            const min = Math.min(r, g, b);
            let h, s, l = (max + min) / 2;

            if (max === min) {
                h = s = 0; // achromatic
            } else {
                const d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch (max) {
                    case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                    case g: h = (b - r) / d + 2; break;
                    case b: h = (r - g) / d + 4; break;
                }
                h /= 6;
            }

            h = Math.round(h * 360);
            s = Math.round(s * 100);
            l = Math.round(l * 100);

            // Create harmony based on type
            switch (harmonyType) {
                case 'complementary':
                    // Opposite on the color wheel
                    return `hsl(${(h + 180) % 360}, ${s}%, ${l}%)`;

                case 'analogous':
                    // Adjacent on the color wheel
                    return [
                        `hsl(${(h - 30 + 360) % 360}, ${s}%, ${l}%)`,
                        `hsl(${(h + 30) % 360}, ${s}%, ${l}%)`
                    ];

                case 'triadic':
                    // Three evenly spaced colors
                    return [
                        `hsl(${(h + 120) % 360}, ${s}%, ${l}%)`,
                        `hsl(${(h + 240) % 360}, ${s}%, ${l}%)`
                    ];

                default:
                    return baseColor;
            }
        } catch (error) {
            return baseColor; // Return original on error
        }
    },

    // Get color from TACS Color Centurial
    /**
     * Get a color from the TACS Color Centurial system
     * @param {string} family - The color family (whiteKhaki, yellow, green, etc.)
     * @param {number} number - The number within the family (1-10)
     * @returns {string} The color value
     */
    getTacsColor: (family, number = 1) => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getTacsColor',
            'Get color from TACS Centurial',
            'To access numbered standardized colors',
            'Returns the color value based on family and number'
        );

        try {
            if (!TACS_COLOR_CENTURIAL[family]) {
                family = 'whiteKhaki';
            }

            if (!TACS_COLOR_CENTURIAL[family][number]) {
                number = 1;
            }

            return TACS_COLOR_CENTURIAL[family][number];
        } catch (error) {
            return TACS_COLOR_CENTURIAL.whiteKhaki[1]; // Default fallback
        }
    },

    /**
     * Get a premium gradient from the TACS Premium Colors
     * @param {string} family - The color family (whiteKhaki, yellow, green, etc.)
     * @param {number} number - The number within the family (1-10)
     * @returns {string} The gradient value
     */
    getTacsPremiumColor: (family, number = 1) => {
        logWx4H(
            'Accepted_Colors_GeneralWebpages_Templates.getTacsPremiumColor',
            'Get premium gradient from TACS Premium Colors',
            'To access luxury gradient variations of colors',
            'Returns the gradient value based on family and number'
        );

        try {
            if (!TACS_PREMIUM_COLORS[family]) {
                family = 'whiteKhaki';
            }

            if (!TACS_PREMIUM_COLORS[family][number]) {
                number = 1;
            }

            return TACS_PREMIUM_COLORS[family][number];
        } catch (error) {
            return TACS_PREMIUM_COLORS.whiteKhaki[1]; // Default fallback
        }
    },

    // Export collections for direct access
    palettes: COLOR_PALETTES, // DEPRECATED
    themeColors: THEME_COLORS,
    gradients: GRADIENT_PRESETS,
    glassMorphism: GLASS_EFFECTS,
    shadows: SHADOW_PRESETS,
    microInteractions: MICRO_INTERACTIONS,
    accessibility: COLOR_ACCESSIBILITY,
    tacsColors: TACS_COLOR_CENTURIAL,
    tacsPremiumColors: TACS_PREMIUM_COLORS
};

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                              THEME-AWARE COLOR HOOK COMPONENT
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

/**
 * useThemeColors - React hook for accessing theme-aware colors
 * @returns {Object} Object containing color utility functions
 */
const useThemeColors = () => {
    logWx4H(
        'useThemeColors',
        'Initialize theme-aware color hook',
        'To provide easy access to theme-appropriate colors',
        'Creates a unified API for color access based on current theme'
    );

    // Get current theme from context
    const themeContext = useContext(ThemeContext) || { isDark: false };
    const { isDark } = themeContext;

    // Return color utilities that are theme-aware
    return {
        /**
         * Get color from palette with theme awareness (DEPRECATED)
         * @param {string} palette - Color palette name
         * @param {number|string} shade - Color shade
         * @returns {string} The color value
         */
        getColor: (palette, shade = 500) => {
            return Accepted_Colors_GeneralWebpages_Templates.getColor(palette, shade);
        },

        /**
         * Get color from TACS Color Centurial
         * @param {string} family - Color family name
         * @param {number} number - Color number (1-10)
         * @returns {string} The color value
         */
        getTacsColor: (family, number = 1) => {
            return Accepted_Colors_GeneralWebpages_Templates.getTacsColor(family, number);
        },

        /**
         * Get premium gradient from TACS Premium Colors
         * @param {string} family - Color family name
         * @param {number} number - Color number (1-10)
         * @returns {string} The gradient value
         */
        getTacsPremiumColor: (family, number = 1) => {
            return Accepted_Colors_GeneralWebpages_Templates.getTacsPremiumColor(family, number);
        },

        /**
         * Get theme-specific color
         * @param {string} category - Color category
         * @param {string} variant - Variant within category
         * @returns {string} Theme-appropriate color
         */
        getThemeColor: (category, variant) => {
            return Accepted_Colors_GeneralWebpages_Templates.getThemeColor(isDark, category, variant);
        },

        /**
         * Get gradient based on current theme
         * @param {string} name - Gradient preset name
         * @returns {string} Theme-appropriate gradient
         */
        getGradient: (name = 'primary') => {
            return Accepted_Colors_GeneralWebpages_Templates.getGradient(isDark, name);
        },

        /**
         * Get glassmorphic effect based on current theme
         * @param {string} intensity - Effect intensity
         * @param {boolean} accent - Whether to use accent color
         * @returns {Object} Theme-appropriate glass effect styles
         */
        getGlassEffect: (intensity = 'medium', accent = false) => {
            return Accepted_Colors_GeneralWebpages_Templates.getGlassEffect(isDark, intensity, accent);
        },

        /**
         * Get shadow based on current theme
         * @param {string} type - Shadow type
         * @returns {string} Theme-appropriate shadow
         */
        getShadow: (type = 'card') => {
            return Accepted_Colors_GeneralWebpages_Templates.getShadow(isDark, type);
        },

        /**
         * Get microinteraction preset
         * @param {string} category - Interaction category
         * @param {string} type - Interaction type
         * @returns {string|Object} Interaction style
         */
        getMicroInteraction: (category, type) => {
            return Accepted_Colors_GeneralWebpages_Templates.getMicroInteraction(category, type);
        },

        /**
         * Create a custom gradient
         * @param {string} angle - Gradient angle
         * @param {string[]} colors - Colors for gradient
         * @returns {string} Gradient CSS value
         */
        createGradient: (angle = '135deg', colors = []) => {
            return Accepted_Colors_GeneralWebpages_Templates.createGradient(angle, colors);
        },

        /**
         * Apply alpha/transparency to a color
         * @param {string} color - Base color
         * @param {number} alpha - Alpha value (0-1)
         * @returns {string} Color with transparency
         */
        withAlpha: (color, alpha = 1) => {
            return Accepted_Colors_GeneralWebpages_Templates.withAlpha(color, alpha);
        },

        /**
         * Get accessible text color for background
         * @param {string} backgroundColor - Background color
         * @param {string} preferredColor - Preferred text color
         * @param {string} level - Accessibility level
         * @returns {string} Accessible text color
         */
        getAccessibleTextColor: (backgroundColor, preferredColor = null, level = 'aa') => {
            return Accepted_Colors_GeneralWebpages_Templates.getAccessibleTextColor(
                backgroundColor, preferredColor, level
            );
        },

        /**
         * Check color combination accessibility
         * @param {string} foregroundColor - Text color
         * @param {string} backgroundColor - Background color
         * @param {string} level - WCAG level
         * @param {string} size - Text size category
         * @returns {boolean} Is combination accessible
         */
        isAccessible: (foregroundColor, backgroundColor, level = 'aa', size = 'normal') => {
            return Accepted_Colors_GeneralWebpages_Templates.isAccessible(
                foregroundColor, backgroundColor, level, size
            );
        },

        /**
         * Generate harmonious colors
         * @param {string} baseColor - Base color
         * @param {string} harmonyType - Type of harmony
         * @returns {string|string[]} Harmonious color(s)
         */
        getHarmoniousColor: (baseColor, harmonyType = 'complementary') => {
            return Accepted_Colors_GeneralWebpages_Templates.getHarmoniousColor(baseColor, harmonyType);
        },

        /**
         * Generate color scale
         * @param {string} startColor - Starting color
         * @param {string} endColor - Ending color
         * @param {number} steps - Number of steps
         * @returns {string[]} Color scale
         */
        generateColorScale: (startColor, endColor, steps = 5) => {
            return Accepted_Colors_GeneralWebpages_Templates.generateColorScale(startColor, endColor, steps);
        },

        /**
         * Get direct references to color collections
         */
        palettes: COLOR_PALETTES, // DEPRECATED
        themeColors: isDark ? THEME_COLORS.dark : THEME_COLORS.light,
        gradients: GRADIENT_PRESETS,
        glassMorphism: isDark ? GLASS_EFFECTS.dark : GLASS_EFFECTS.light,
        shadows: isDark ? SHADOW_PRESETS.dark : SHADOW_PRESETS.light,
        microInteractions: MICRO_INTERACTIONS,
        accessibility: COLOR_ACCESSIBILITY,
        tacsColors: TACS_COLOR_CENTURIAL,
        tacsPremiumColors: TACS_PREMIUM_COLORS,

        /**
         * Current theme state
         */
        isDark
    };
};

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                                         DEFAULT EXPORTS
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

// Add display name for dev tools
Accepted_Colors_GeneralWebpages_Templates.displayName = 'Accepted_Colors_GeneralWebpages_Templates';

// Export color constants for easier consumption
Accepted_Colors_GeneralWebpages_Templates.COLOR_PALETTES = COLOR_PALETTES; // DEPRECATED
Accepted_Colors_GeneralWebpages_Templates.THEME_COLORS = THEME_COLORS;
Accepted_Colors_GeneralWebpages_Templates.GRADIENT_PRESETS = GRADIENT_PRESETS;
Accepted_Colors_GeneralWebpages_Templates.GLASS_EFFECTS = GLASS_EFFECTS;
Accepted_Colors_GeneralWebpages_Templates.SHADOW_PRESETS = SHADOW_PRESETS;
Accepted_Colors_GeneralWebpages_Templates.MICRO_INTERACTIONS = MICRO_INTERACTIONS;
Accepted_Colors_GeneralWebpages_Templates.COLOR_ACCESSIBILITY = COLOR_ACCESSIBILITY;
Accepted_Colors_GeneralWebpages_Templates.TACS_COLOR_CENTURIAL = TACS_COLOR_CENTURIAL;
Accepted_Colors_GeneralWebpages_Templates.TACS_PREMIUM_COLORS = TACS_PREMIUM_COLORS;

// Export the hook
Accepted_Colors_GeneralWebpages_Templates.useThemeColors = useThemeColors;

export {useThemeColors};

export default Accepted_Colors_GeneralWebpages_Templates;


/*
# Taytrom CSS System - Implementation Guide

## Color System Migration Notice

**IMPORTANT**: Taytrom is transitioning from the legacy COLOR_PALETTES to the new TACS Color Centurial system. All new development should use TACS_COLOR_CENTURIAL and TACS_PREMIUM_COLORS instead of the deprecated COLOR_PALETTES. The legacy system is maintained for backward compatibility only.

## Core Design Philosophy

Taytrom's design goal is to create the sleekest, most modern, and luxurious application experience in internet history. Every UI element should:
- Use glassmorphic styling throughout the interface
- Incorporate 3D shadows and rich gradients
- Feature microinteractions for an immersive user experience
- Use the brand accent color (#20B2AA) sparingly
- Follow the established semantic color meanings

## Basic Setup

### Step 1: Import the Component

First, import the color system into your React component:

```jsx
import Accepted_Colors_GeneralWebpages_Templates, { useThemeColors } from '/path/to/Accepted_Colors_GeneralWebpages_Templates';
```

### Step 2: Access TACS Color Centurial

The TACS Color Centurial system is the primary color system for all new development:

```jsx
// Using the direct API
const mintGreen = Accepted_Colors_GeneralWebpages_Templates.getTacsColor('green', 1);
const goldColor = Accepted_Colors_GeneralWebpages_Templates.getTacsColor('yellow', 5);
const brandAccent = Accepted_Colors_GeneralWebpages_Templates.getTacsColor('green', 10); // #20B2AA

// Using the hook
const { getTacsColor } = useThemeColors();
const mintGreen = getTacsColor('green', 1);
```

### Step 3: Access TACS Premium Colors

For luxury gradients and premium designs:

```jsx
// Using the direct API
const goldLuxury = Accepted_Colors_GeneralWebpages_Templates.getTacsPremiumColor('yellow', 5);
const brandAccentLuxury = Accepted_Colors_GeneralWebpages_Templates.getTacsPremiumColor('green', 10);

// Using the hook
const { getTacsPremiumColor } = useThemeColors();
const goldLuxury = getTacsPremiumColor('yellow', 5);
```

### Step 2: Use the Theme-Aware Hook

For theme-aware components, use the provided React hook:

```jsx
import React from 'react';
import { useThemeColors } from '/path/to/Accepted_Colors_GeneralWebpages_Templates';

const LuxuriousComponent = () => {
    const colors = useThemeColors();

    // Create a glassmorphic card with proper shadows
    return (
        <div style={{
            ...colors.getGlassEffect('medium'),
            boxShadow: colors.getShadow('elevated'),
            color: colors.getThemeColor('text', 'primary'),
            transition: colors.getMicroInteraction('transitions', 'elegant'),
            padding: '2rem',
            borderRadius: '12px'
        }}>
            <h2>Luxury Experience</h2>
            <p>This component embodies Taytrom's sleek, modern design philosophy.</p>
        </div>
    );
};
```

## Creating Glassmorphic UI Elements

Glassmorphic effects are a core part of Taytrom's design language:

```jsx
// Using the hook
const { getGlassEffect, getShadow, getThemeColor } = useThemeColors();

// Card with basic glass effect
const glassCardStyle = {
    ...getGlassEffect('medium'),
    padding: '2rem',
    borderRadius: '12px',
    boxShadow: getShadow('elevated'),
    color: getThemeColor('text', 'primary')
};

// Card with accent-colored glass effect
const accentGlassCardStyle = {
    ...getGlassEffect('medium', true), // true enables accent color
    padding: '2rem',
    borderRadius: '12px',
    boxShadow: getShadow('elevated'),
    color: getThemeColor('text', 'primary')
};

// Modal with stronger glass effect
const glassModalStyle = {
    ...getGlassEffect('strong'),
    padding: '3rem',
    borderRadius: '16px',
    boxShadow: getShadow('modal'),
    color: getThemeColor('text', 'primary')
};
```

## Adding Microinteractions

To create immersive experiences, use the microinteraction presets:

```jsx
// Using the hook
const { getMicroInteraction, getShadow } = useThemeColors();

// Button with hover and active microinteractions
const InteractiveButton = ({ children, ...props }) => {
    const baseStyle = {
        padding: '10px 20px',
        borderRadius: '8px',
        border: 'none',
        cursor: 'pointer',
        transition: getMicroInteraction('transitions', 'spring'),
        boxShadow: getShadow('card')
    };

    return (
        <button
            style={baseStyle}
            onMouseEnter={(e) => {
                Object.assign(e.target.style, getMicroInteraction('hover', 'lift'));
            }}
            onMouseLeave={(e) => {
                e.target.style.transform = '';
                e.target.style.boxShadow = getShadow('card');
            }}
            onMouseDown={(e) => {
                Object.assign(e.target.style, getMicroInteraction('active', 'press'));
            }}
            onMouseUp={(e) => {
                Object.assign(e.target.style, getMicroInteraction('hover', 'lift'));
            }}
            {...props}
        >
            {children}
        </button>
    );
};
```

## Implementing Rich Gradients

```jsx
// Using the hook
const { getGradient, createGradient } = useThemeColors();

// Predefined gradient
const primaryGradient = getGradient('luxury');

// Custom gradient
const customGradient = createGradient('135deg', ['#20B2AA', '#26C6BE', '#20B2AA']);
```

## Using TACS Color Centurial

### Color References

The TACS Color Centurial provides a comprehensive set of 100 colors organized into 10 families, with 10 colors in each family:

1. **whiteKhaki** - Various white and light khaki shades
2. **yellow** - Yellow shades from light to dark
3. **green** - Green shades from light to dark
4. **teal** - Teal and cyan shades
5. **blue** - Blue shades from light to dark
6. **purple** - Purple and violet shades
7. **pink** - Pink shades from light to dark
8. **orange** - Orange shades from light to dark
9. **red** - Red shades from light to dark
10. **blackGray** - Gray and black shades from light to dark

Each color is accessed by its family name and number (1-10), with 1 being the lightest and 10 being the darkest in most cases.

### TACS Premium Colors

The TACS Premium Colors provide luxury gradients for each of the 100 colors in the TACS Color Centurial. These gradients create an elegant, premium feel ideal for Taytrom's luxury brand identity.

## Practical Examples

### Luxury Card Component with TACS Colors

```jsx
import React from 'react';
import { useThemeColors } from '/path/to/Accepted_Colors_GeneralWebpages_Templates';

const LuxuryCard = ({ title, content, level = 'medium' }) => {
    const colors = useThemeColors();

    // Choose glass effect and shadow based on level
    const getStyles = () => {
        switch (level) {
            case 'high':
                return {
                    glass: colors.getGlassEffect('strong'),
                    shadow: colors.getShadow('luxe'),
                    transition: colors.getMicroInteraction('transitions', 'elegant')
                };
            case 'low':
                return {
                    glass: colors.getGlassEffect('subtle'),
                    shadow: colors.getShadow('card'),
                    transition: colors.getMicroInteraction('transitions', 'smooth')
                };
            case 'medium':
            default:
                return {
                    glass: colors.getGlassEffect('medium'),
                    shadow: colors.getShadow('elevated'),
                    transition: colors.getMicroInteraction('transitions', 'spring')
                };
        }
    };

    const styles = getStyles();

    return (
        <div
            style={{
                ...styles.glass,
                boxShadow: styles.shadow,
                transition: styles.transition,
                padding: '2rem',
                borderRadius: '16px',
                cursor: 'pointer',
                maxWidth: '400px'
            }}
            onMouseEnter={(e) => {
                Object.assign(e.target.style, colors.getMicroInteraction('hover', 'lift'));
            }}
            onMouseLeave={(e) => {
                e.target.style.transform = '';
                e.target.style.boxShadow = styles.shadow;
            }}
        >
            <h3 style={{
                color: colors.getThemeColor('text', 'primary'),
                marginBottom: '1rem',
                fontSize: '1.5rem'
            }}>
                {title}
            </h3>
            <div style={{
                color: colors.getThemeColor('text', 'secondary'),
                lineHeight: 1.6
            }}>
                {content}
            </div>
        </div>
    );
};
```

### Premium Button Component

```jsx
import React from 'react';
import { useThemeColors } from '/path/to/Accepted_Colors_GeneralWebpages_Templates';

const PremiumButton = ({ children, variant = 'primary', ...props }) => {
    const colors = useThemeColors();

    // Button styles based on variant
    const getButtonStyles = () => {
        switch (variant) {
            case 'primary':
                return {
                    background: colors.getGradient('accent'),
                    color: '#FFFFFF',
                    shadow: colors.getShadow('elevated')
                };
            case 'secondary':
                return {
                    ...colors.getGlassEffect('medium'),
                    color: colors.getColor('accent', 500),
                    shadow: colors.getShadow('card')
                };
            case 'luxury':
                return {
                    background: colors.getGradient('luxury'),
                    border: `1px solid ${colors.withAlpha(colors.getColor('accent', 500), 0.5)}`,
                    color: colors.getThemeColor('text', 'primary'),
                    shadow: colors.getShadow('luxe')
                };
            default:
                return {
                    ...colors.getGlassEffect('subtle'),
                    color: colors.getThemeColor('text', 'primary'),
                    shadow: colors.getShadow('subtle')
                };
        }
    };

    const baseStyle = getButtonStyles();

    return (
        <button
            style={{
                ...baseStyle,
                padding: '12px 24px',
                borderRadius: '40px',
                border: baseStyle.border || 'none',
                cursor: 'pointer',
                transition: colors.getMicroInteraction('transitions', 'spring'),
                boxShadow: baseStyle.shadow,
                fontWeight: 500,
                fontSize: '14px',
                letterSpacing: '0.5px'
            }}
            onMouseEnter={(e) => {
                Object.assign(e.target.style, colors.getMicroInteraction('hover', 'glow'));
            }}
            onMouseLeave={(e) => {
                e.target.style.boxShadow = baseStyle.shadow;
            }}
            onMouseDown={(e) => {
                Object.assign(e.target.style, colors.getMicroInteraction('active', 'press'));
            }}
            onMouseUp={(e) => {
                Object.assign(e.target.style, colors.getMicroInteraction('hover', 'glow'));
            }}
            {...props}
        >
            {children}
        </button>
    );
};
```

## CSS Animation Example for Microinteractions

Add these CSS animations to your global stylesheet:

```css
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
}

@keyframes shimmer {
    0% { background-position: -200% 0; }
    100% { background-position: 200% 0; }
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slide-in {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}
```

## Implementing Premium Components with TACS Premium Colors

The TACS Premium Colors collection provides luxurious gradients for components that need to stand out. Here's how to use them:

```jsx
import React from 'react';
import { useThemeColors } from '/path/to/Accepted_Colors_GeneralWebpages_Templates';

const PremiumFeatureCard = ({ title, content }) => {
    const colors = useThemeColors();

    return (
        <div
            style={{
                background: colors.getTacsPremiumColor('purple', 5), // Premium orchid gradient
                ...colors.getGlassEffect('medium'),
                boxShadow: colors.getShadow('luxe'),
                padding: '2rem',
                borderRadius: '16px',
                color: '#FFFFFF',
                transition: colors.getMicroInteraction('transitions', 'elegant')
            }}
            onMouseEnter={(e) => {
                Object.assign(e.target.style, colors.getMicroInteraction('hover', 'glow'));
            }}
            onMouseLeave={(e) => {
                e.target.style.boxShadow = colors.getShadow('luxe');
            }}
        >
            <h3 style={{ marginBottom: '1rem' }}>{title}</h3>
            <p>{content}</p>
        </div>
    );
};
```

## Best Practices for Taytrom's Design System

1. **Use TACS Color Centurial**: For all new development, use the TACS Color Centurial system instead of deprecated COLOR_PALETTES.
2. **Glassmorphic First**: Always use glassmorphic effects for cards, modals, and containers.
3. **Depth with Shadows**: Use appropriate shadows to create a sense of depth and luxury.
4. **Microinteractions Everywhere**: Add subtle interactions to all clickable elements.
5. **Use Premium Gradients**: Use TACS Premium Colors for high-impact UI elements and special features.
6. **Use Accent Color Sparingly**: The brand color (#20B2AA, green.10) should be used as an accent, not as a primary color.
7. **Semantic Colors**: Follow the color meanings table for consistent UX:
   - Green: Success/Progress/Approval
   - Blue: Information/Trust/Core functionality
   - Red: Error/Warning/Danger
   - Yellow: Caution/Pending/In-progress
   - Purple: Special features/Premium functionality
   - Teal: Navigation/Guidance
   - Orange: Important notice/Required action
   - Gray: Neutral/Disabled
8. **Respect Light/Dark Themes**: Always use theme-aware colors via the `useThemeColors` hook.
9. **Accessibility Matters**: Check contrast ratios for text readability.
10. **Smooth Transitions**: Always use smooth transitions between states.

## Troubleshooting

### Glass Effects Not Working

If glassmorphic effects aren't displaying correctly:

1. Ensure the parent container has a background image or color.
2. Add a position property (e.g., `position: relative`) to the container.
3. Check if the browser supports `backdrop-filter` (add fallbacks for older browsers).

### Shadow Layering Issues

If shadows appear incorrectly:

1. Check z-index values to ensure proper stacking.
2. Adjust the shadow values to better fit your specific use case.
3. Consider using multiple shadow values for more realistic effects.

### Microinteraction Performance

If animations are sluggish:

1. Use `transform` and `opacity` for animations instead of properties that trigger layout.
2. Consider reducing the complexity of animations on mobile devices.
3. Use the `will-change` CSS property to optimize performance-critical animations.
*/