/**
 * Mailing List Utility Functions
 * Handles email validation, form interaction, and submission
 */

// Email validation regex - RFC 5322 compliant
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Validates an email address with multiple checks
 * @param {string} email - The email to validate
 * @returns {Object} Validation result with valid status and message
 */
export const validateEmail = (email) => {
    // Empty check
    if (!email.trim()) return {
        valid: false,
        message: "Email address is required"
    };

    // Length check
    if (email.length > 254) return {
        valid: false,
        message: "Email address is too long"
    };

    // Basic format check (RFC 5322 compliant)
    const formatValid = EMAIL_REGEX.test(email);
    if (!formatValid) return {
        valid: false,
        message: "Please enter a valid email address"
    };

    // Domain check (simple check for at least one dot after @)
    if (email.split('@')[1]?.indexOf('.') === -1) {
        return {
            valid: false,
            message: "Please check your email domain"
        };
    }

    // Common typo check
    const commonTypos = {
        'gmial': 'gmail', 'gmal': 'gmail', 'gmil': 'gmail', 'gmail.co': 'gmail.com',
        'hotmial': 'hotmail', 'yaho': 'yahoo', 'outloo': 'outlook',
        'yhaoo': 'yahoo', 'gmaill': 'gmail', 'outlok': 'outlook'
    };

    const domain = email.split('@')[1]?.split('.')[0];
    if (commonTypos[domain]) {
        return {
            valid: false,
            message: `Did you mean ${email.replace(domain, commonTypos[domain])}?`,
            suggestion: email.replace(domain, commonTypos[domain])
        };
    }

    return { valid: true };
};

/**
 * Debounce function for performance optimization
 * @param {Function} func - Function to debounce
 * @param {number} wait - Wait time in milliseconds
 * @returns {Function} Debounced function
 */
export const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
};

/**
 * Handles email input changes with real-time validation
 * @param {Event} e - Input change event
 * @param {Function} setEmail - State setter for email
 * @param {Function} setError - State setter for error
 * @param {Object} styles - Component inlineStyles
 */
export const handleUserEmail = (e, setEmail, setError, styles) => {
    const newEmail = e.target.value.trim();
    setEmail(newEmail);

    // Clear errors on input change
    if (error) setError("");

    // Real-time validation feedback
    if (newEmail.length > 5) {
        if (!newEmail.includes('@')) {
            setError("Email address should include @");
        } else if (newEmail.includes('@') && !newEmail.split('@')[1]?.includes('.')) {
            setError("Email domain should include a period");
        } else {
            setError("");
        }
    }

    // Enhance input field appearance
    if (e.target && newEmail.length > 0) {
        e.target.classList.add(styles['has-value']);

        // Add checkmark icon for likely valid emails
        if (newEmail.includes('@') && newEmail.includes('.')) {
            e.target.classList.add(styles['likely-valid']);
        } else {
            e.target.classList.remove(styles['likely-valid']);
        }
    } else if (e.target) {
        e.target.classList.remove(styles['has-value'], styles['likely-valid']);
    }
};

/**
 * Handle form submission with enhanced user experience
 * @param {Event} e - Form submission event
 * @param {Object} config - Configuration object with all dependencies
 */
export const handleUserSubmit = async (e, config) => {
    const {
        email,
        setEmail,
        setError,
        error,
        isLoading,
        setIsLoading,
        submitted,
        setSubmitted,
        lastSubmitTime,
        setLastSubmitTime,
        showSuggestion,
        setShowSuggestion,
        suggestedEmail,
        setSuggestedEmail,
        isDark,
        cardColors,
        mailinglistRef,
        subscribeButtonRef,
        generateDynamicShadow,
        generateNeuralGlow,
        createParticleEffect,
        logWx4H,
        PRIMARY_COLORS,
        SECONDARY_COLORS,
        styles,
        isExperimental
    } = config;

    e.preventDefault();

    // Don't allow submission while already loading or when recently submitted
    if (isLoading || (submitted && Date.now() - lastSubmitTime < 5000)) {
        return;
    }

    // Reset states
    setError("");
    setIsLoading(true);

    // Pre-submit visual feedback with neural card features
    if (mailinglistRef.current) {
        // Apply neural glow effect
        if (mailinglistRef.current.style) {
            mailinglistRef.current.style.boxShadow = generateDynamicShadow(
                isDark,
                cardColors.primary,
                1.2 // Increased intensity during submission
            );
        }
    }

    // Sanitize and validate input
    const sanitizedEmail = email.trim().toLowerCase();
    const validation = validateEmail(sanitizedEmail);

    if (!validation.valid) {
        setError(validation.message);
        setIsLoading(false);

        // Apply error animation
        if (subscribeButtonRef.current) {
            subscribeButtonRef.current.classList.add(styles['animate-shake']);

            // Add ARIA alert for accessibility
            subscribeButtonRef.current.setAttribute('aria-live', 'assertive');

            setTimeout(() => {
                subscribeButtonRef.current?.classList.remove(styles['animate-shake']);
            }, 500);
        }

        // If we have a suggestion, offer it
        if (validation.suggestion) {
            setShowSuggestion(true);
            setSuggestedEmail(validation.suggestion);
        }

        return;
    }

    try {
        // Log submission intent
        logWx4H(
            'MailingListForm',
            'Subscription submission',
            'User attempting to subscribe to mailing list',
            `Email: ${sanitizedEmail.substring(0, 3)}****@${sanitizedEmail.split('@')[1]}`
        );

        // Track load state for precise animation timing
        let loadingStage = 0;
        const updateLoadingVisuals = () => {
            if (!isLoading) return;

            if (subscribeButtonRef.current) {
                const loadingTexts = [
                    "Subscribing...",
                    "Processing...",
                    "Almost there..."
                ];

                subscribeButtonRef.current.textContent = loadingTexts[loadingStage % loadingTexts.length];
                loadingStage++;
            }
        };

        // Start loading animation
        const loadingInterval = setInterval(updateLoadingVisuals, 800);
        updateLoadingVisuals();

        // Simulate API call with realistic timing
        await new Promise(resolve => setTimeout(resolve, 1500));

        // Clear loading animation
        clearInterval(loadingInterval);

        // Success handling
        setEmail("");
        setLastSubmitTime(Date.now());
        setSubmitted(true);

        // Add to local storage to remember this device subscribed
        try {
            localStorage.setItem('taytrom_subscribed', 'true');
            localStorage.setItem('taytrom_subscribed_email', sanitizedEmail.split('@')[1]); // Store domain only for privacy
        } catch (storageErr) {
            // Silently fail if localStorage isn't available
        }

        // Success animation sequence utilizing Primary_Cards_Templates features
        if (mailinglistRef.current) {
            // Create particle effect for successful submission
            createParticleEffect(mailinglistRef.current, null, {
                particleCount: 20,
                colors: [
                    PRIMARY_COLORS[isDark ? 'dark' : 'light'][0],
                    SECONDARY_COLORS[isDark ? 'dark' : 'light'][0]
                ]
            });

            // Enhanced shadow effect for success state
            if (mailinglistRef.current.style) {
                mailinglistRef.current.style.boxShadow = generateNeuralGlow(
                    isDark,
                    { light: [PRIMARY_COLORS.light[0]], dark: [PRIMARY_COLORS.dark[0]] },
                    1.5, // Higher intensity
                    { pulseRate: 0.6, energyField: 0.5 }
                ).boxShadow;
            }

            // ARIA announcement for screen readers
            const ariaLiveRegion = document.getElementById('mailinglist-status') ||
                (() => {
                    const region = document.createElement('div');
                    region.id = 'mailinglist-status';
                    region.setAttribute('aria-live', 'polite');
                    region.classList.add(styles['sr-only']);
                    document.body.appendChild(region);
                    return region;
                })();

            ariaLiveRegion.textContent = 'Successfully subscribed to the mailing list. Thank you!';
        }

        // Track successful subscription
        if (window.gtag) {
            window.gtag('event', 'subscribe', {
                'event_category': 'engagement',
                'event_label': 'mailinglist'
            });
        }

        // Reset subscription status after delay
        setTimeout(() => {
            setSubmitted(false);

            // Reset neural effects
            if (mailinglistRef.current && mailinglistRef.current.style) {
                mailinglistRef.current.style.boxShadow = '';
            }
        }, isExperimental ? 8000 : 5000);

    } catch (err) {
        console.error("Subscription error:", err);

        // Determine error message based on error type
        let errorMessage = "An error occurred. Please try again.";

        if (err.message?.includes('network')) {
            errorMessage = "Network error. Please check your connection.";
        } else if (err.message?.includes('already subscribed')) {
            errorMessage = "This email is already subscribed.";
            setSubmitted(true); // Show success anyway
        } else if (err.message?.includes('invalid')) {
            errorMessage = "Invalid email address. Please check and try again.";
        }

        setError(errorMessage);

        // Error animation
        if (subscribeButtonRef.current) {
            subscribeButtonRef.current.classList.add(styles['animate-shake']);
            setTimeout(() => {
                subscribeButtonRef.current?.classList.remove(styles['animate-shake']);
            }, 500);
        }

        // Reset neural effects
        if (mailinglistRef.current && mailinglistRef.current.style) {
            mailinglistRef.current.style.boxShadow = '';
        }

        // Track failed subscription attempt
        if (window.gtag) {
            window.gtag('event', 'subscribe_error', {
                'event_category': 'error',
                'event_label': err.message || 'unknown_error'
            });
        }
    } finally {
        setIsLoading(false);
    }
};