import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Compiler_Chat_AI_Admin
    from "@/admin-scope/ai/chat/compiler/Compiler_Chat_AI_Admin.jsx";

export const ScrapThemeContext = createContext({
    isDark: true,
    toggleTheme: () => {},
    theme: 'dark',
    themeColors: {
        primary: '#20B2AA',
        secondary: '#4682B4',
        accent: '#FFD700'
    }
});

/**
 * ScrapApp - A temporary app component for testing Chat_AI_Admin
 *
 * This component provides a sandbox environment where you can experiment with
 * components from Chat_AI_Admin without affecting your main application.
 */
const ScrapApp = () => {
    // Replicate the theme state from your main FullDevApp
    const [isDark, setIsDark] = useState(() => {
        const savedTheme = localStorage.getItem('taytrom-theme');
        return savedTheme ? savedTheme === 'dark' : true;
    });

    // Define theme colors based on your existing styles
    const themeColors = {
        primary: isDark ? 'var(--tacs-primary-500)' : 'var(--tacs-primary-600)',
        secondary: isDark ? 'var(--tacs-secondary-500)' : 'var(--tacs-secondary-600)',
        accent: isDark ? 'var(--tacs-accent-500)' : 'var(--tacs-accent-600)',
        background: isDark ? 'var(--tacs-dark-bg-surface)' : 'var(--tacs-light-bg-surface)',
        text: isDark ? 'var(--tacs-dark-substance-primary)' : 'var(--tacs-light-substance-primary)'
    };

    // Toggle theme function
    const toggleTheme = () => {
        setIsDark(!isDark);
    };

    // Update document-panel theme when theme changes
    useEffect(() => {
        localStorage.setItem('taytrom-theme', isDark ? 'dark' : 'light');
        document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');
    }, [isDark]);

    // Sample navigation items to test with GoldenComponent
    const navigationItems = [
        { label: 'Home', path: '/', icon: 'home' },
        { label: 'Components', path: '/components', icon: 'layers' },
        { label: 'Documentation', path: '/docs', icon: 'book' },
        { label: 'Settings', path: '/settings', icon: 'settings' },
        { label: 'Profile', path: '/profile', icon: 'user' },
    ];

    return (
        <ScrapThemeContext.Provider value={{
            isDark,
            toggleTheme,
            theme: isDark ? 'dark' : 'light',
            themeColors
        }}>
            <Router>
                <div className="scrap-app" style={{
                    minHeight: '100vh',
                    backgroundColor: isDark ? '#121212' : '#f5f5f5',
                    color: isDark ? '#ffffff' : '#333333',
                    transition: 'all 0.3s ease'
                }}>
                    {/* This is where you can test your GoldenComponent component */}
                    <Compiler_Chat_AI_Admin
                        navigationItems={navigationItems}
                        horizontalNavigationProps={{ enabled: true }}
                        verticalNavigationProps={{
                            enabled: true,
                            position: 'left',
                            showLabels: true
                        }}
                        onThemeChange={(theme) => console.log('Theme changed:', theme)}
                    >
                        {/* Test content area */}
                        <div style={{
                            padding: '2rem',
                            maxWidth: '1200px',
                            margin: '0 auto'
                        }}>
                            <h1>Component Testing Sandbox</h1>
                            <p>This is a sandbox environment for testing components from Golden_Component_Templates.</p>

                            <Routes>
                                <Route path="/" element={<TestSection title="Home" />} />
                                <Route path="/components" element={<TestSection title="Components" />} />
                                <Route path="/docs" element={<TestSection title="Documentation" />} />
                                <Route path="/settings" element={<TestSection title="Settings" />} />
                                <Route path="/profile" element={<TestSection title="Profile" />} />
                            </Routes>
                        </div>
                    </Compiler_Chat_AI_Admin>
                </div>
            </Router>
        </ScrapThemeContext.Provider>
    );
};

// Simple test section component
const TestSection = ({ title }) => (
    <div>
        <h2>{title} Section</h2>
        <p>This is the {title.toLowerCase()} test section. You can replace this with any component you want to test.</p>

        <div style={{
            marginTop: '2rem',
            padding: '1rem',
            border: '1px dashed #666',
            borderRadius: '8px'
        }}>
            <h3>Component Testing Area</h3>
            <p>You can place any components from Golden_Component_Templates here to test them in isolation.</p>
        </div>
    </div>
);


export default ScrapApp;