// Phase1App.jsx
import React, {
    lazy,
    Suspense,
    createContext,
    useContext,
    useState,
    useEffect,
    useRef,
    useCallback,
    memo
} from 'react';
import { HashRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import styles from './Phase1App.module.css';
import { motion } from 'framer-motion';

// Import simplified navigation for Phase 1
import Phase1Navigation from './navigate-domain/horizontal/Horizontal_Navigate_Phase1.jsx';

// Eagerly load the Writing Platform as our main experience for Phase 1
import VisualWritingPlatform from './writing-scope/compiler/Compiler_Writing.jsx';

import HypnoticSpiralLanding
    from "@/homepage-scope/pages/hypnotic-spiral/HypnoticSpiral_Pages_Homepage.jsx";


// Import AuthProvider and ProtectedRoute from FrontAutocrat
import { AuthProvider, ProtectedRoute } from './FrontAutocrat.jsx';

// Import icons
import {
    ChevronDown, ChevronRight, FileText, PenTool, Brain, Sliders,
    LayoutGrid, Rocket, Sparkles, ArrowRight, Zap, MessageSquare, X,
    Check, CheckCircle, Activity, PieChart, Eye, Layers, Shield,
    Keyboard, ChevronLeft, Settings
} from 'lucide-react';

import AliceWonderland_OpeningExample from './writing-scope/specialists/showcase/alice-wonderland/AliceWonderland_Showcase_Specialists_Writing.jsx';

import AIComponent from "@/writing-scope/specialists/features/primary-view/ai-overlay/ai-component/AIComponent_Specialists_Writing.jsx";
import FixedAIAssistant
    from "@/writing-scope/specialists/features/primary-view/ai-overlay/ai-component/wrapper/Wrapper_AIComponent_Specialists_Writing.jsx";

const MemoizedAIComponent = memo(AIComponent);
const MemoizedFixedAIAssistant = memo(FixedAIAssistant);

// Environment check - true for development, false for production
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development' ||
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

// Context definitions
export const OrchestratorContext = createContext(null);
export const ThemeContext = createContext({
    isDark: true,
    toggleTheme: () => {},
    theme: 'dark',
    themeColors: {
        primary: '#20B2AA',
        secondary: '#4682B4',
        accent: '#FFD700'
    }
});

// Enhanced subscription context with more detailed feature mapping
export const SubscriptionContext = createContext({
    subscriptions: {
        aiContext: false,
        writingPlatform: false,
        documentStructuring: false,
        aiSuggestions: false,
        styleControls: false,
        analytics: false,
        progressContext: false,
        domainContext: false,
        mermaidGenerator: false,
        aiCoding: false
    },
    checkSubscription: () => {},
    triggerSubscription: () => {},
    isDevMode: IS_DEVELOPMENT
});

// Custom hooks - same as in original FullDevApp.jsx for API consistency
export const useOrchestrator = () => {
    const context = useContext(OrchestratorContext);
    if (!context) {
        console.warn('useOrchestrator must be used within an OrchestratorProvider');
    }
    return context;
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        console.warn('useTheme must be used within a ThemeProvider');
    }
    return context;
};

export const useSubscription = () => {
    const context = useContext(SubscriptionContext);
    if (!context) {
        console.warn('useSubscription must be used within a SubscriptionProvider');
    }
    return context;
};

// Lazy load other Phase 1 components
const LandingPage = lazy(() => import('./homepage-scope/Construction/Construction_TaytromLanding_Homepage.jsx'));
const SettingsPage = lazy(() => import('./user-scope/settings/Settings_UserScope.jsx'));
const Registration = lazy(() => import('./authentication-scope/registration/Front_Auth_Registration_Component.jsx'));
const SignIn = lazy(() => import('./authentication-scope/sign-in/Front_Auth_SignIn_Component.jsx'));
const ForgotPassword = lazy(() => import('./authentication-scope/forgot-password/Front_Auth_ForgotPassword_Component.jsx'));
const BillingComponent = lazy(() => import('./authentication-scope/billing/Billing_Authentication.jsx'));
const About_UserDomains = lazy(() => import('./user-scope/about/About_UserScope.jsx'));
const AboutPage = lazy(() => import('./user-scope/about/About_UserScope.jsx'));

// Add this with your other lazy-loaded components
const SubscriptionSelectionModal = lazy(() => import('./writing-scope/specialists/utils/subscription-modal/SubscriptionModal_Utils_Specialists_Writing.jsx'));
const FeatureLockedModal = lazy(() => import('./writing-scope/specialists/utils/feature-locked-modal/FeatureLockedModal_Utils_Specialists_Writing.jsx'));


// Production Mode Toggle component
const ProductionModeToggle = ({ isDark, isSimulating, onToggle }) => {
    if (!IS_DEVELOPMENT) return null; // Only show in actual development

    return (
        <div
            className={`${styles['taytrom-production-toggle']} ${isSimulating ? styles['taytrom-production-toggle--simulating'] : ''}`}
            onClick={onToggle}
        >
            <div className={styles['taytrom-toggle-switch']}>
                <div className={`${styles['taytrom-toggle-indicator']} ${isSimulating ? styles['taytrom-toggle-indicator--active'] : ''}`}></div>
            </div>
            <span>{isSimulating ? 'SIMULATING PRODUCTION' : 'DEV MODE'}</span>
        </div>
    );
};

const Phase1App = ({ orchestrator }) => {
    // Initialize theme state with dark mode as default
    const [isDark, setIsDark] = useState(() => {
        const savedTheme = localStorage.getItem('taytrom-theme');
        return savedTheme ? savedTheme === 'dark' : true;
    });

    // Add simulation mode toggle state
    const [simulateProduction, setSimulateProduction] = useState(() => {
        const savedMode = localStorage.getItem('taytrom-simulate-production');
        return savedMode ? savedMode === 'true' : false;
    });

    const [isAIComponentMinimized, setIsAIComponentMinimized] = useState(true);
    const [showAIComponent, setShowAIComponent] = useState(false);

    // Create an effective development flag that considers the simulation setting
    const effectiveDevMode = IS_DEVELOPMENT && !simulateProduction;

    // Theme handling (same as FullDevApp.jsx)
    const themeColors = {
        primary: isDark ? 'var(--tacs-primary-500)' : 'var(--tacs-primary-600)',
        secondary: isDark ? 'var(--tacs-secondary-500)' : 'var(--tacs-secondary-600)',
        accent: isDark ? 'var(--tacs-accent-500)' : 'var(--tacs-accent-600)',
        background: isDark ? 'var(--tacs-dark-bg-surface)' : 'var(--tacs-light-bg-surface)',
        text: isDark ? 'var(--tacs-dark-substance-primary)' : 'var(--tacs-light-substance-primary)'
    };

    const toggleTheme = () => {
        setIsDark(!isDark);
    };

    // Add a function to toggle between dev and simulated production mode
    const toggleProductionMode = () => {
        setSimulateProduction(prev => {
            const newValue = !prev;

            // Store preference in localStorage
            localStorage.setItem('taytrom-simulate-production', newValue.toString());

            // Reset subscription states based on new mode
            setSubscriptions({
                aiContext: !newValue, // Only true in real dev mode
                writingPlatform: !newValue,
                documentStructuring: !newValue,
                aiSuggestions: !newValue,
                styleControls: !newValue,
                analytics: !newValue,
                progressContext: !newValue,
                domainContext: !newValue,
                mermaidGenerator: !newValue,
                aiCoding: !newValue
            });

            // Reset demo mode based on new effective mode
            setIsDemoMode(newValue);

            return newValue;
        });
    };

    // Enhanced user subscription state with more detailed features
    const [subscriptions, setSubscriptions] = useState({
        aiContext: effectiveDevMode,
        writingPlatform: effectiveDevMode,
        documentStructuring: effectiveDevMode,
        aiSuggestions: effectiveDevMode,
        styleControls: effectiveDevMode,
        analytics: effectiveDevMode,
        progressContext: effectiveDevMode,
        domainContext: effectiveDevMode,
        mermaidGenerator: effectiveDevMode,
        aiCoding: effectiveDevMode
    });

    // Modal states
    const [featureLockedModal, setFeatureLockedModal] = useState({
        isOpen: false,
        feature: null
    });

    const [subscriptionSelectionModal, setSubscriptionSelectionModal] = useState(false);

    // Demo mode state - determines if we show full UI but intercept interactions
    // In development mode, never use demo mode
    const [isDemoMode, setIsDemoMode] = useState(!effectiveDevMode);

    // State for controlling the ProductionDemo component
    const [showProductionDemo, setShowProductionDemo] = useState(false);

    // State for user onboarding tour
    const [tourStep, setTourStep] = useState(null);

    // Ref to track if this is initial load
    const initialLoadRef = useRef(true);

    // State for tracking demo completion
    const [demoProgress, setDemoProgress] = useState({
        started: false,
        completed: false,
        lastSection: 0
    });

    // Update localStorage and document-panel class when theme changes
    useEffect(() => {
        localStorage.setItem('taytrom-theme', isDark ? 'dark' : 'light');
        document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');

        if (orchestrator && orchestrator.subscriptionManager) {
            orchestrator.subscriptionManager.notify('theme', {
                type: 'THEME_CHANGED',
                theme: isDark ? 'dark' : 'light',
                themeColors: {
                    primary: isDark ? 'var(--tacs-primary-500)' : 'var(--tacs-primary-600)',
                    secondary: isDark ? 'var(--tacs-secondary-500)' : 'var(--tacs-secondary-600)',
                    accent: isDark ? 'var(--tacs-accent-500)' : 'var(--tacs-accent-600)',
                    background: isDark ? 'var(--tacs-dark-bg-surface)' : 'var(--tacs-light-bg-surface)',
                    text: isDark ? 'var(--tacs-dark-substance-primary)' : 'var(--tacs-light-substance-primary)'
                }
            });
        }
    }, [isDark, orchestrator]);

    // Check if user is authenticated and update demo mode - only in production
    useEffect(() => {
        if (!effectiveDevMode && orchestrator && orchestrator.authManager) {
            const isAuthenticated = orchestrator.authManager.isAuthenticated();
            setIsDemoMode(!isAuthenticated);

            // If authenticated, check subscriptions
            if (isAuthenticated) {
                checkUserSubscriptions();
            }
        }
    }, [orchestrator, effectiveDevMode]);

    // Check if we should show the production demo on load
    useEffect(() => {
        // Only run on initial load
        if (initialLoadRef.current) {
            initialLoadRef.current = false;

            // Show the demo when the app launches for users who haven't seen it
            // Skip in development mode
            if (!effectiveDevMode) {
                const hasViewedDemo = localStorage.getItem('taytrom-demo-viewed') === 'true';
                if (!hasViewedDemo) {
                    // Wait a bit before showing the demo to allow the app to render first
                    setTimeout(() => {
                        setShowProductionDemo(true);
                    }, 800);
                }
            }

            // Load saved demo progress
            const savedProgress = localStorage.getItem('taytrom-demo-progress');
            if (savedProgress) {
                try {
                    setDemoProgress(JSON.parse(savedProgress));
                } catch (e) {
                    console.error("Error parsing saved demo progress", e);
                }
            }
        }
    }, [effectiveDevMode]);


// Mock responses for the AI assistant
    const getMockAIResponse = (userInput) => {
        // Lowercase the input for easier matching
        const input = userInput.toLowerCase();

        // Different response categories based on input keywords
        if (input.includes('hello') || input.includes('hi') || input.includes('hey')) {
            return "Hello! I'm Taytrom's AI Assistant. I can help you craft your document, suggest improvements, and answer questions about the platform. What would you like to work on today?";
        }
        else if (input.includes('help') || input.includes('how') || input.includes('what')) {
            return "I'd be happy to help! With Taytrom's AI-powered writing platform, you can:\n\n• Create structured documents with nested steps\n• Receive AI-powered content suggestions\n• Visualize your document's structure\n• Get style recommendations\n\nWhat specific feature would you like to learn more about?";
        }
        else if (input.includes('feature') || input.includes('can you')) {
            return "Taytrom offers powerful features like:\n\n• Interactive document structure visualization\n• AI-powered content suggestions\n• Smart formatting and styling\n• Contextual writing assistance\n• Document analytics\n\nTo access all features, you'll need to subscribe. Would you like information about our subscription plans?";
        }
        else if (input.includes('price') || input.includes('cost') || input.includes('subscription') || input.includes('plan')) {
            return "Taytrom offers flexible subscription plans:\n\n• Free: Basic document creation and limited AI suggestions\n• Premium ($9.99/month): Full access to all AI features, unlimited documents, and priority support\n• Enterprise: Custom pricing for teams\n\nTry our interactive demo to experience all premium features before subscribing!";
        }
        else if (input.includes('document') || input.includes('write') || input.includes('create')) {
            return "Creating documents with Taytrom is intuitive! Start by adding steps from the document panel, then use the structure visualization to organize them. Our AI can suggest content for each section based on your document's context. Would you like a specific tip for document creation?";
        }
        else if (input.includes('example') || input.includes('sample')) {
            return "Our Alice in Wonderland example demonstrates how Taytrom helps organize complex narratives. Notice how characters, themes, and scenes are structured hierarchically with visual connections. Try the demo to see how you can apply similar organization to your own content!";
        }
        else {
            // Default response for any other input
            return "Thanks for your interest in Taytrom! Our AI assistant can help with document creation, content suggestions, and answering questions about our platform. To experience the full power of our AI features, try our interactive demo or sign up for a subscription. What would you like to know more about?";
        }
    };

    // Add this wrapper component to your Phase1App.jsx (or modify the existing one)
    const DemoAIComponentWrapper = ({ orchestrator, isMinimized, onToggleMinimize }) => {
        const [messages, setMessages] = useState([
            {
                role: 'assistant',
                content: "Welcome to Taytrom's AI Assistant! I can help with document creation, suggest improvements, and answer questions about the platform. How can I assist you today?"
            }
        ]);
        const [inputValue, setInputValue] = useState('');
        const [isProcessing, setIsProcessing] = useState(false);

        // Function to handle user input
        const handleSendMessage = () => {
            if (!inputValue.trim()) return;

            // Add user message to the chat
            const userMessage = { role: 'user', content: inputValue };
            setMessages(prev => [...prev, userMessage]);

            // Show processing state
            setIsProcessing(true);

            // Clear input field
            setInputValue('');

            // Simulate AI processing delay (500-1500ms)
            setTimeout(() => {
                // Generate mock response
                const mockResponse = getMockAIResponse(userMessage.content);

                // Add AI response to the chat
                setMessages(prev => [...prev, { role: 'assistant', content: mockResponse }]);
                setIsProcessing(false);
            }, 500 + Math.random() * 1000);
        };

        // Return a simplified version of the AIComponent for demo purposes
        return (
            <div className={styles['taytrom-ai-component-wrapper']}>
                <div className={styles['taytrom-ai-messages']} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: isMinimized ? '0' : '300px',
                    overflow: 'auto',
                    padding: '16px',
                    gap: '12px',
                    transition: 'height 0.3s ease'
                }}>
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={`${styles['taytrom-message']} ${styles[`taytrom-message-${message.role}`]}`}
                            style={{
                                padding: '10px 14px',
                                borderRadius: '12px',
                                maxWidth: '80%',
                                alignSelf: message.role === 'user' ? 'flex-end' : 'flex-start',
                                background: message.role === 'user' ? 'var(--tacs-primary-500, #20b2aa)' : 'var(--tacs-dark-bg-floating, #334155)',
                                color: message.role === 'user' ? 'white' : 'var(--tacs-dark-substance-primary, #f8fafc)',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                            }}
                        >
                            {message.content}
                        </div>
                    ))}

                    {isProcessing && (
                        <div className={styles['taytrom-message-typing']} style={{
                            alignSelf: 'flex-start',
                            padding: '6px 12px',
                            borderRadius: '12px',
                            background: 'var(--tacs-dark-bg-floating, #334155)',
                            color: 'var(--tacs-dark-substance-secondary, #cbd5e1)'
                        }}>
                            <div className={styles['taytrom-typing-indicator']} style={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center'
                            }}>
              <span style={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  background: 'currentColor',
                  opacity: 0.6,
                  animation: 'taytromTypingDot 1.4s infinite'
              }}></span>
                                <span style={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    background: 'currentColor',
                                    opacity: 0.6,
                                    animation: 'taytromTypingDot 1.4s 0.2s infinite'
                                }}></span>
                                <span style={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    background: 'currentColor',
                                    opacity: 0.6,
                                    animation: 'taytromTypingDot 1.4s 0.4s infinite'
                                }}></span>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles['taytrom-ai-input']} style={{
                    display: 'flex',
                    padding: '10px',
                    borderTop: '1px solid var(--tacs-dark-border-subtle, #334155)',
                    background: 'var(--tacs-dark-bg-elevated, #1e293b)'
                }}>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleSendMessage();
                        }}
                        placeholder="Ask me about Taytrom..."
                        style={{
                            flex: 1,
                            padding: '8px 12px',
                            borderRadius: '8px',
                            border: '1px solid var(--tacs-dark-border-subtle, #334155)',
                            background: 'var(--tacs-dark-bg-depressed, #0a1020)',
                            color: 'var(--tacs-dark-substance-primary, #f8fafc)',
                            outline: 'none'
                        }}
                    />
                    <button
                        onClick={handleSendMessage}
                        disabled={!inputValue.trim()}
                        style={{
                            marginLeft: '8px',
                            padding: '8px 12px',
                            borderRadius: '8px',
                            border: 'none',
                            background: 'var(--tacs-primary-500, #20b2aa)',
                            color: 'white',
                            cursor: inputValue.trim() ? 'pointer' : 'not-allowed',
                            opacity: inputValue.trim() ? 1 : 0.6
                        }}
                    >
                        Send
                    </button>
                </div>

                <style jsx>{`
        @keyframes taytromTypingDot {
          0%, 60%, 100% { transform: translateY(0); }
          30% { transform: translateY(-4px); }
        }
      `}</style>
            </div>
        );
    };



    // Function to check user subscriptions - skip in development
    const checkUserSubscriptions = async () => {
        // In development mode, all subscriptions are enabled
        if (effectiveDevMode) {
            return;
        }

        try {
            if (orchestrator && orchestrator.subscriptionManager) {
                const currentUser = orchestrator.authManager?.getCurrentUser();
                if (!currentUser) return;

                const subscriptionData = await orchestrator.subscriptionManager.getUserSubscriptions(currentUser.id);

                // Update subscription state based on premium subscription
                if (subscriptionData?.hasPremium) {
                    setSubscriptions({
                        aiContext: true,
                        writingPlatform: true,
                        documentStructuring: true,
                        aiSuggestions: true,
                        styleControls: true,
                        analytics: true,
                        progressContext: false,
                        domainContext: false,
                        mermaidGenerator: false,
                        aiCoding: false
                    });
                } else {
                    setSubscriptions({
                        aiContext: false,
                        writingPlatform: false,
                        documentStructuring: false,
                        aiSuggestions: false,
                        styleControls: false,
                        analytics: false,
                        progressContext: false,
                        domainContext: false,
                        mermaidGenerator: false,
                        aiCoding: false
                    });
                }
            }
        } catch (error) {
            console.error("Error checking subscriptions:", error);
        }
    };

    // Function to trigger feature locked modal - only in production
    const triggerFeatureLocked = (feature) => {
        if (effectiveDevMode) return; // Skip in development mode

        setFeatureLockedModal({
            isOpen: true,
            feature
        });
    };

    // Function to close feature locked modal
    const closeFeatureLocked = () => {
        setFeatureLockedModal({
            isOpen: false,
            feature: null
        });
    };

    // Function to handle subscription selection
    const showSubscriptionSelection = () => {
        if (effectiveDevMode) return; // Skip in development mode

        setSubscriptionSelectionModal(true);
    };


    // Toggle AI Chat Component with enhanced animations
    const toggleAIComponent = useCallback(() => {
        // If component isn't visible, show it
        if (!showAIComponent) {
            setShowAIComponent(true);
            setIsAIComponentMinimized(false);

            // Pulse animation for AI icon in header
            const aiIcon = document.querySelector(`.${styles['taytrom-header']} [data-ai-icon="true"]`);
            if (aiIcon) {
                aiIcon.style.animation = 'pulse-icon 1s var(--tacs-ease-in-out-cubic)';
                setTimeout(() => {
                    aiIcon.style.animation = '';
                }, 1000);
            }
        } else {
            // If it's visible, toggle minimized state with animation
            setIsAIComponentMinimized(!isAIComponentMinimized);
        }

    }, [showAIComponent, isAIComponentMinimized]);


    // Function to handle subscription purchase
    const handleSubscribe = async (plan) => {
        if (effectiveDevMode) {
            console.log("Development mode: Simulating subscription to premium plan");

            // In dev mode, instantly "subscribe"
            setSubscriptions({
                aiContext: true,
                writingPlatform: true,
                documentStructuring: true,
                aiSuggestions: true,
                styleControls: true,
                analytics: true,
                progressContext: false,
                domainContext: false,
                mermaidGenerator: false,
                aiCoding: false
            });

            // Close modals
            closeFeatureLocked();
            setSubscriptionSelectionModal(false);

            // Start the feature tour after subscription
            setTimeout(() => {
                setTourStep(0);
            }, 500);

            return;
        }

        try {
            if (!orchestrator.authManager.isAuthenticated()) {
                // If not authenticated, store the plan and redirect to sign in
                localStorage.setItem('pendingSubscription', 'premium');
                window.location.href = '/#/signin';
                return;
            }

            // Process subscription
            if (orchestrator && orchestrator.subscriptionManager) {
                const currentUser = orchestrator.authManager?.getCurrentUser();
                if (!currentUser) return;

                const result = await orchestrator.subscriptionManager.startSubscription(currentUser.id, 'premium');

                if (result.success) {
                    // Update subscriptions after successful purchase
                    await checkUserSubscriptions();

                    // Close modals
                    closeFeatureLocked();
                    setSubscriptionSelectionModal(false);

                    // Set demo mode to false
                    setIsDemoMode(false);

                    // Start the feature tour after subscription
                    setTimeout(() => {
                        setTourStep(0);
                    }, 500);

                    // Show success notification
                    const successDiv = document.createElement('div');
                    successDiv.className = `${styles['taytrom-notification']} ${styles['taytrom-notification-success']}`;
                    successDiv.textContent = "Subscription activated successfully! Enjoy Taytrom's premium features.";
                    document.body.appendChild(successDiv);

                    setTimeout(() => {
                        if (document.body.contains(successDiv)) {
                            document.body.removeChild(successDiv);
                        }
                    }, 5000);
                } else {
                    const errorDiv = document.createElement('div');
                    errorDiv.className = `${styles['taytrom-notification']} ${styles['taytrom-notification-error']}`;
                    errorDiv.textContent = result.error || "Failed to process subscription. Please try again.";
                    document.body.appendChild(errorDiv);

                    setTimeout(() => {
                        if (document.body.contains(errorDiv)) {
                            document.body.removeChild(errorDiv);
                        }
                    }, 4000);
                }
            }
        } catch (error) {
            console.error("Error subscribing:", error);
            alert("An error occurred while processing your subscription. Please try again.");
        }
    };

    // Check for pending subscription after authentication - production only
    useEffect(() => {
        if (!effectiveDevMode) {
            const pendingSubscription = localStorage.getItem('pendingSubscription');
            if (pendingSubscription && orchestrator && orchestrator.authManager.isAuthenticated()) {
                // Clear pending subscription
                localStorage.removeItem('pendingSubscription');
                // Process the subscription
                handleSubscribe('premium');
            }
        }
    }, [orchestrator, effectiveDevMode]);

    // Handlers for the Production Demo component
    const handleDemoGetStarted = () => {
        // Mark that user has viewed the demo
        localStorage.setItem('taytrom-demo-viewed', 'true');

        // Update demo progress
        const updatedProgress = {
            ...demoProgress,
            completed: true
        };
        setDemoProgress(updatedProgress);
        localStorage.setItem('taytrom-demo-progress', JSON.stringify(updatedProgress));

        // Hide the demo
        setShowProductionDemo(false);

        // If the user is authenticated, show them the writing platform
        // Otherwise, show the subscription options
        if (orchestrator && orchestrator.authManager && orchestrator.authManager.isAuthenticated()) {
            setIsDemoMode(false);
            // Start the feature tour
            setTimeout(() => {
                setTourStep(0);
            }, 500);
        } else {
            showSubscriptionSelection();
        }
    };

    const handleDemoSignIn = () => {
        // Mark that user has viewed the demo
        localStorage.setItem('taytrom-demo-viewed', 'true');

        // Update demo progress
        const updatedProgress = {
            ...demoProgress,
            started: true
        };
        setDemoProgress(updatedProgress);
        localStorage.setItem('taytrom-demo-progress', JSON.stringify(updatedProgress));

        // Hide the demo
        setShowProductionDemo(false);

        // Redirect to sign in page
        window.location.href = '/#/signin';
    };

    const handleDismissDemo = () => {
        // Mark that user has viewed the demo
        localStorage.setItem('taytrom-demo-viewed', 'true');

        // Update demo progress
        const updatedProgress = {
            ...demoProgress,
            started: true
        };
        setDemoProgress(updatedProgress);
        localStorage.setItem('taytrom-demo-progress', JSON.stringify(updatedProgress));

        // Hide the demo
        setShowProductionDemo(false);
    };

    // Function to update demo progress when user interacts with the demo
    const updateDemoProgress = (section) => {
        const updatedProgress = {
            ...demoProgress,
            started: true,
            lastSection: Math.max(demoProgress.lastSection, section)
        };

        setDemoProgress(updatedProgress);
        localStorage.setItem('taytrom-demo-progress', JSON.stringify(updatedProgress));
    };

    // Tour management functions
    const handleNextTourStep = () => {
        if (tourStep === null) return;

        if (tourStep < 4) { // Updated to match the number of steps in TourGuide component
            setTourStep(tourStep + 1);
        } else {
            setTourStep(null);
        }
    };

    const handlePrevTourStep = () => {
        if (tourStep === null || tourStep === 0) return;

        setTourStep(tourStep - 1);
    };

    const handleCloseTour = () => {
        setTourStep(null);
    };


    // Writing Platform with demo mode and authentication trigger
    const WritingPlatformWrapper = ({ demoMode, orchestrator }) => {
        // In development mode, always show full version without demo buttons
        if (effectiveDevMode) {
            return (
                <div className={`${styles['taytrom-relative']} ${styles['taytrom-h-full']}`}>
                    <VisualWritingPlatform
                        triggerFeatureLocked={triggerFeatureLocked}
                        subscriptions={subscriptions}
                        orchestrator={orchestrator} // Pass orchestrator to the component
                    />
                    {/* No demo buttons in development mode */}
                </div>
            );
        }

        if (demoMode) {
            // In demo mode, intercept interactions to trigger auth/subscription
            return (
                <div className={`${styles['taytrom-demo-mode-container']}`}>
                    {/* Actual writing platform UI but with interactions disabled */}
                    <VisualWritingPlatform
                        demoMode={true}
                        orchestrator={orchestrator} // Pass orchestrator even in demo mode
                    />

                    {/* Enhanced overlay with more context and engaging visuals */}
                    <div className={styles['taytrom-demo-overlay']}>
                        <div className={`${styles['taytrom-platform-modal']} ${styles['taytrom-bg-gradient-primary']}`}>
                            <h2 className={styles['taytrom-heading-2']}>
                                Taytrom Writing Platform
                            </h2>

                            <p className={`${styles['taytrom-text']} ${styles['taytrom-text-center']}`}>
                                Transform your document creation with AI-powered
                                structure visualization, content suggestions, and style
                                controls.
                            </p>

                            <div className={styles['taytrom-feature-list']}>
                                <div className={`${styles['taytrom-flex']} ${styles['taytrom-items-center']} ${styles['taytrom-gap-2']}`}>
                                    <span>✓</span>
                                    <span>Interactive document structure visualization</span>
                                </div>
                                <div className={`${styles['taytrom-flex']} ${styles['taytrom-items-center']} ${styles['taytrom-gap-2']}`}>
                                    <span>✓</span>
                                    <span>AI-powered content suggestions</span>
                                </div>
                                <div className={`${styles['taytrom-flex']} ${styles['taytrom-items-center']} ${styles['taytrom-gap-2']}`}>
                                    <span>✓</span>
                                    <span>Customizable style controls and analytics</span>
                                </div>
                            </div>

                            <div className={`${styles['taytrom-flex']} ${styles['taytrom-justify-center']} ${styles['taytrom-gap-4']}`}>
                                <button
                                    onClick={() => {
                                        // Call subscription function directly
                                        if (orchestrator && orchestrator.authManager.isAuthenticated()) {
                                            showSubscriptionSelection();
                                        } else {
                                            // Show sign in for non-authenticated users
                                            window.location.href = '/#/signin';
                                        }
                                    }}
                                    className={`${styles['taytrom-button']} ${styles['taytrom-button-primary']} ${styles['taytrom-glow-accent']}`}
                                >
                                    <span>Subscribe Now</span>
                                    <ArrowRight size={18} />
                                </button>

                                <button
                                    onClick={() => {
                                        // Directly trigger the demo
                                        setShowProductionDemo(true);
                                    }}
                                    className={`${styles['taytrom-button']} ${styles['taytrom-button-secondary']}`}
                                >
                                    <span>Show Demo Tour</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            // Normal mode - check for required subscriptions
            const hasRequiredSubscriptions = subscriptions.aiContext && subscriptions.writingPlatform;

            if (!hasRequiredSubscriptions) {
                // If missing subscriptions, show again with demo mode
                return <WritingPlatformWrapper demoMode={true} orchestrator={orchestrator}/>;
            }

            // Full functioning version with demo buttons (only in production mode)
            return (
                <div className={`${styles['taytrom-relative']} ${styles['taytrom-h-full']}`}>
                    <VisualWritingPlatform
                        triggerFeatureLocked={triggerFeatureLocked}
                        subscriptions={subscriptions}
                        orchestrator={orchestrator} // Pass orchestrator to the component
                    />

                    {/* Enhanced Returning User Demo Tour Button - only in production */}
                    {!effectiveDevMode && !showProductionDemo && localStorage.getItem('taytrom-demo-viewed') === 'true' && (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.4, delay: 0.2 }}
                            className={styles['taytrom-returning-user-banner']}
                        >
                            <button
                                onClick={showWritingPlatformDemo}
                                aria-label="Continue your interactive demo tour"
                                role="button"
                                tabIndex={0}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        e.preventDefault();
                                        showWritingPlatformDemo();
                                    }
                                }}
                                className={`${styles['taytrom-button']} ${styles['taytrom-button-primary']} ${styles['taytrom-w-full']} ${styles['taytrom-glow-accent']}`}
                            >
                                {/* Progress indicator for returning users */}
                                <div className={styles['taytrom-progress-indicator']}></div>

                                <div className={`${styles['taytrom-flex']} ${styles['taytrom-items-center']} ${styles['taytrom-justify-center']} ${styles['taytrom-gap-2']}`}>
                                    <div className={styles['taytrom-icon-wrap']}>
                                        <Rocket size={22} />
                                        {/* Small play icon overlay */}
                                        <div className={styles['taytrom-play-badge']}>
                                            ▶
                                        </div>
                                    </div>
                                    <span>Continue Interactive Demo Tour</span>
                                </div>

                                {/* Subtle badge showing it's for returning users */}
                                <div className={styles['taytrom-badge']}>
                                    Resume
                                </div>
                            </button>

                            {/* Tooltip for returning users */}
                            <div className={styles['taytrom-tooltip']}>
                                Continue where you left off — your progress is saved
                            </div>
                        </motion.div>
                    )}
                </div>
            );
        }
    };

    // Modified version of ProtectedRoute for development mode
    const DevFriendlyRoute = ({ children }) => {
        // In development mode, always render children without authentication
        if (effectiveDevMode) {
            return children;
        }

        // In production, use the normal ProtectedRoute
        return <ProtectedRoute>{children}</ProtectedRoute>;
    };
    const LocationAwareContent = () => {
        const location = useLocation();

        // Hide demo banner when not on home route
        useEffect(() => {
            // Logic for hiding demo banner based on location
        }, [location]);

        return (
            <Suspense fallback={
                <div className={styles['taytrom-loading-screen']}>
                    <div className={styles['taytrom-loading-spinner']}></div>
                    <p className={`${styles['taytrom-text']} ${styles['taytrom-text-center']}`}>Loading Taytrom...</p>
                </div>
            }>
                <Routes>
                    {/* Main route - Landing page with spiral */}
                    <Route
                        path="/"
                        element={
                            <div className={`${styles['taytrom-relative']} ${styles['taytrom-h-full']}`}>
                                {/* Maintain the landing page with spiral here */}
                                <HypnoticSpiralLanding
                                    onExplore={() => window.location.href = '/#/showcase'}
                                    onGetStarted={() => window.location.href = '/#/signin'}
                                />
                            </div>
                        }
                    />

                    {/* New dedicated route for Alice in Wonderland showcase */}
                    <Route
                        path="/showcase"
                        element={
                            <div className={`${styles['taytrom-relative']} ${styles['taytrom-h-full']}`}>
                                <AliceWonderland_OpeningExample
                                    onGetStarted={() => {
                                        window.location.href = '/#/signin';
                                    }}
                                    onSignIn={() => {
                                        window.location.href = '/#/signin';
                                    }}
                                />
                            </div>
                        }
                    />

                    {/* Traditional landing page now redirects to main landing page */}
                    <Route path="/welcome" element={<Navigate to="/" replace />} />

                    {/* Redirect /demo to showcase with tour in development mode */}
                    {effectiveDevMode && (
                        <Route path="/demo" element={<Navigate to="/showcase" replace />} />
                    )}

                    {/* Add explicit handling for /demo in production mode */}
                    {!effectiveDevMode && (
                        <Route path="/demo" element={
                            <div className={`${styles['taytrom-relative']} ${styles['taytrom-h-full']}`}>
                                <AliceWonderland_OpeningExample
                                    startTourAutomatically={true}
                                    onGetStarted={() => {
                                        window.location.href = '/#/signin';
                                    }}
                                    onSignIn={() => {
                                        window.location.href = '/#/signin';
                                    }}
                                />
                            </div>
                        } />
                    )}

                    {/* Auth routes - not required in development */}
                    <Route path="/register" element={<Registration />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/billing" element={
                        <DevFriendlyRoute>
                            <BillingComponent />
                        </DevFriendlyRoute>
                    } />

                    <Route path="/settings" element={
                        <DevFriendlyRoute>
                            <SettingsPage />
                        </DevFriendlyRoute>
                    } />

                    {/* About pages - not required in development */}
                    <Route path="/about" element={
                        <DevFriendlyRoute>
                            <AboutPage />
                        </DevFriendlyRoute>
                    } />
                    <Route path="/about/construction" element={
                        <DevFriendlyRoute>
                            <About_UserDomains />
                        </DevFriendlyRoute>
                    } />

                    {/* Catch-all route that redirects to home */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Suspense>
        );
    };

    return (
        <OrchestratorContext.Provider value={orchestrator}>
            <ThemeContext.Provider value={{
                isDark,
                toggleTheme,
                theme: isDark ? 'dark' : 'light',
                themeColors
            }}>
                <SubscriptionContext.Provider value={{
                    subscriptions,
                    checkSubscription: checkUserSubscriptions,
                    triggerSubscription: showSubscriptionSelection,
                    isDevMode: effectiveDevMode
                }}>
                    <Router>
                        <AuthProvider orchestrator={orchestrator}>
                            <div className={styles['TANK-full-width']}>
                                {!showProductionDemo && (
                                    <Phase1Navigation
                                        frontgod={orchestrator}
                                        isDark={isDark}
                                        onToggleTheme={toggleTheme}
                                    />
                                )}

                                <main className={styles['taytrom-main-content']}>
                                    <LocationAwareContent />
                                </main>

                                {/* Feature Locked Modal - only in production */}
                                {!effectiveDevMode && featureLockedModal.isOpen && (
                                    <div className={styles['taytrom-modal-overlay']}>
                                        <div className={styles['taytrom-modal']}>
                                            <FeatureLockedModal
                                                feature={featureLockedModal.feature}
                                                onClose={closeFeatureLocked}
                                                onSubscribe={handleSubscribe}
                                                isDark={isDark}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Subscription Selection Modal - only in production */}
                                {!effectiveDevMode && subscriptionSelectionModal && (
                                    <div className={styles['taytrom-modal-overlay']}>
                                        <div className={styles['taytrom-modal']}>
                                            <SubscriptionSelectionModal
                                                onClose={() => setSubscriptionSelectionModal(false)}
                                                onSubscribe={handleSubscribe}
                                                isDark={isDark}
                                            />
                                        </div>
                                    </div>
                                )}





                                {/* Production Mode Toggle - only visible in actual development */}
                                <ProductionModeToggle
                                    isDark={isDark}
                                    isSimulating={simulateProduction}
                                    onToggle={toggleProductionMode}
                                />


                            </div>




                        </AuthProvider>
                    </Router>
                </SubscriptionContext.Provider>
            </ThemeContext.Provider>
        </OrchestratorContext.Provider>
    );
};

export default Phase1App;