// Value proposition rotating texts
export const ValueProposition_Hero_TaytromLanding = [
    "What if an entire generation could now build their own AI and internet platform," +
    " giving them:",
    "Direct access to the world.",
    "Creative independence.",
    "Financial freedom.",
    "Total occupational control.",
    "What if that was all one subscription away?",
    "Taytrom, giving others the blueprint to create...whatever they want."
];