import React, { useState, useEffect } from 'react';

/**
 * TaytromAcademyPillar - A breathtaking visualization of Taytrom's Academy pillar
 *
 * Features:
 * - Learning pathway visualization
 * - Certification progression system
 * - Neural-matched learning visualization
 * - Knowledge domain mapping
 * - Code anatomy visualization
 * - Interactive hover effects
 *
 * @param {Object} props Component properties
 * @param {string|number} props.size Size of the SVG (default: "800px")
 * @param {string} props.primaryColor Primary color (default: "#f59e0b" - amber)
 * @param {string} props.secondaryColor Secondary color (default: "#ef4444" - red)
 * @param {string} props.tertiaryColor Tertiary color (default: "#ec4899" - pink)
 * @param {string} props.bgColor Background color (default: "#18181b" - zinc-900)
 * @param {string} props.className Additional CSS classes
 * @param {Object} props.style Additional inline styles
 * @param {boolean} props.interactive Enable hover interactions (default: true)
 * @param {boolean} props.glowEffect Enable glow effects (default: true)
 * @param {string} props.animationMode Animation style: "pulse", "wave", "flow" (default: "pulse")
 * @param {number} props.animationSpeed Animation speed multiplier (default: 1)
 * @param {boolean} props.reducedMotion Respect reduced motion preferences (default: true)
 */
const TaytromAcademyPillar = ({
                                  size = "800px",
                                  primaryColor = "#f59e0b", // amber
                                  secondaryColor = "#ef4444", // red
                                  tertiaryColor = "#ec4899", // pink
                                  bgColor = "#18181b", // zinc-900
                                  className = "",
                                  style = {},
                                  interactive = true,
                                  glowEffect = true,
                                  animationMode = "pulse",
                                  animationSpeed = 1,
                                  reducedMotion = true,
                                  ...props
                              }) => {
    // Convert size to numeric value if needed
    const sizeValue = typeof size === 'string' && size.endsWith('px')
        ? parseInt(size)
        : (typeof size === 'number' ? size : 800);

    // State for hover interaction
    const [isHovered, setIsHovered] = useState(false);

    // State to track if user prefers reduced motion
    const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

    // Check for reduced motion preference
    useEffect(() => {
        if (typeof window !== 'undefined' && reducedMotion) {
            const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
            setPrefersReducedMotion(mediaQuery.matches);

            const handleChange = () => setPrefersReducedMotion(mediaQuery.matches);
            mediaQuery.addEventListener('change', handleChange);

            return () => mediaQuery.removeEventListener('change', handleChange);
        }
    }, [reducedMotion]);

    // Determine if animations should be active
    const animationsEnabled = !prefersReducedMotion;

    // Calculate animation durations based on speed
    const baseDuration = 4 / animationSpeed;
    const pulseDuration = 3 / animationSpeed;
    const pathDuration = 8 / animationSpeed;

    // Dynamic styles based on hover state
    const dynamicStyles = {
        pathwayScale: isHovered && interactive ? 1.05 : 1,
        pathOpacity: isHovered && interactive ? 0.9 : 0.7,
        nodeOpacity: isHovered && interactive ? 1 : 0.8,
        connectionOpacity: isHovered && interactive ? 0.8 : 0.6,
        glowFilter: glowEffect ? "url(#academy-glow)" : "",
        softGlowFilter: glowEffect ? "url(#academy-softGlow)" : "",
    };

    // Animation values based on mode
    const animationValues = {
        pulseScale: animationMode === "wave" ? "0.95;1.05;0.95" : "0.95;1.02;0.95",
        flowSpeed: animationMode === "flow" ? pathDuration * 0.8 : pathDuration,
        pathPulse: animationMode === "wave" ? "1000;0;1000" : "1000;0",
        fadeValues: animationMode === "pulse" ? "0.3;1;0.3" : "0.4;0.8;0.4",
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            {/* Definitions for gradients, filters and patterns */}
            <defs>
                <radialGradient id="backgroundGlow" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" stop-color="#E8F5E9" />
                    <stop offset="90%" stop-color="#f0f7f0" />
                    <stop offset="100%" stop-color="#dcf0dc" />
                </radialGradient>

                <linearGradient id="trunkGradient" x1="0%" y1="100%" x2="100%" y2="0%">
                    <stop offset="0%" stop-color="#33691E" />
                    <stop offset="60%" stop-color="#558B2F" />
                    <stop offset="100%" stop-color="#689F38" />
                </linearGradient>

                <linearGradient id="rootGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#33691E" />
                    <stop offset="50%" stop-color="#558B2F" />
                    <stop offset="100%" stop-color="#689F38" />
                </linearGradient>

                <linearGradient id="branchGradient" x1="0%" y1="100%" x2="100%" y2="0%">
                    <stop offset="0%" stop-color="#558B2F" />
                    <stop offset="50%" stop-color="#7CB342" />
                    <stop offset="100%" stop-color="#8BC34A" />
                </linearGradient>

                <linearGradient id="leafGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#7CB342" />
                    <stop offset="60%" stop-color="#8BC34A" />
                    <stop offset="100%" stop-color="#9CCC65" />
                </linearGradient>

                <linearGradient id="fruitGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#00C853" />
                    <stop offset="60%" stop-color="#00E676" />
                    <stop offset="100%" stop-color="#69F0AE" />
                </linearGradient>

                <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                    <feColorMatrix in="blur" type="matrix" values="0 0 0 0 0.4 0 0 0 0 0.8 0 0 0 0 0.4 0 0 0 1 0" result="glow" />
                    <feBlend in="SourceGraphic" in2="glow" mode="screen" />
                </filter>

                <filter id="fruitGlow" x="-50%" y="-50%" width="200%" height="200%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
                    <feColorMatrix in="blur" type="matrix" values="0 0 0 0 0.4 0 0 0 0 1 0 0 0 0 0.4 0 0 0 1 0" result="glow" />
                    <feBlend in="SourceGraphic" in2="glow" mode="screen" />
                </filter>

                <pattern id="knowledge" patternUnits="userSpaceOnUse" width="50" height="50">
                    <circle cx="25" cy="25" r="1" fill="#E8F5E9" />
                    <circle cx="10" cy="10" r="1" fill="#E8F5E9" />
                    <circle cx="40" cy="40" r="1" fill="#E8F5E9" />
                    <circle cx="10" cy="40" r="1" fill="#E8F5E9" />
                    <circle cx="40" cy="10" r="1" fill="#E8F5E9" />
                </pattern>
            </defs>

            {/* Background */}
            <circle cx="400" cy="400" r="350" fill="url(#backgroundGlow)" stroke="#2e8b57" stroke-width="5" />

            {/* Knowledge Particles Animation */}
            <g opacity="0.2" fill="#E8F5E9">
                <circle cx="400" cy="400" r="3">
                    <animate attributeName="cx" values="400;370;350;370;400" dur="20s" repeatCount="indefinite" />
                    <animate attributeName="cy" values="400;300;200;150;100" dur="20s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.2;0.8;0.2" dur="5s" repeatCount="indefinite" />
                </circle>
                <circle cx="400" cy="400" r="3">
                    <animate attributeName="cx" values="400;420;450;470;490" dur="15s" repeatCount="indefinite" />
                    <animate attributeName="cy" values="400;320;240;180;140" dur="15s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.2;0.8;0.2" dur="6s" repeatCount="indefinite" />
                </circle>
                <circle cx="400" cy="400" r="3">
                    <animate attributeName="cx" values="400;430;470;480;500" dur="25s" repeatCount="indefinite" />
                    <animate attributeName="cy" values="400;350;300;250;200" dur="25s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.2;0.8;0.2" dur="7s" repeatCount="indefinite" />
                </circle>
                <circle cx="400" cy="400" r="3">
                    <animate attributeName="cx" values="400;390;370;360;340" dur="22s" repeatCount="indefinite" />
                    <animate attributeName="cy" values="400;370;330;300;270" dur="22s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.2;0.8;0.2" dur="4s" repeatCount="indefinite" />
                </circle>
                <circle cx="400" cy="400" r="3">
                    <animate attributeName="cx" values="400;380;350;320;300" dur="18s" repeatCount="indefinite" />
                    <animate attributeName="cy" values="400;360;320;280;240" dur="18s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.2;0.8;0.2" dur="5.5s" repeatCount="indefinite" />
                </circle>
            </g>

            {/* Root System - Foundation Knowledge */}
            <path d="M370,600 C340,620 300,650 280,680" fill="none" stroke="url(#rootGradient)" stroke-width="9" />
            <path d="M380,600 C400,630 410,660 400,690" fill="none" stroke="url(#rootGradient)" stroke-width="10" />
            <path d="M390,600 C420,630 430,650 450,670" fill="none" stroke="url(#rootGradient)" stroke-width="8" />
            <path d="M400,600 C430,640 460,660 490,670" fill="none" stroke="url(#rootGradient)" stroke-width="7" />
            <path d="M410,600 C440,620 470,650 470,690" fill="none" stroke="url(#rootGradient)" stroke-width="9" />
            <path d="M420,600 C450,630 470,670 510,680" fill="none" stroke="url(#rootGradient)" stroke-width="8" />
            <path d="M430,600 C440,640 440,670 430,690" fill="none" stroke="url(#rootGradient)" stroke-width="6" />

            {/* Root Knowledge Nodes */}
            <circle cx="280" cy="680" r="8" fill="#CCFF90" filter="url(#fruitGlow)">
                <animate attributeName="r" values="8;10;8" dur="3s" repeatCount="indefinite" />
            </circle>
            <circle cx="400" cy="690" r="8" fill="#CCFF90" filter="url(#fruitGlow)">
                <animate attributeName="r" values="8;10;8" dur="4s" repeatCount="indefinite" />
            </circle>
            <circle cx="450" cy="670" r="8" fill="#CCFF90" filter="url(#fruitGlow)">
                <animate attributeName="r" values="8;10;8" dur="3.5s" repeatCount="indefinite" />
            </circle>
            <circle cx="490" cy="670" r="8" fill="#CCFF90" filter="url(#fruitGlow)">
                <animate attributeName="r" values="8;10;8" dur="2.8s" repeatCount="indefinite" />
            </circle>
            <circle cx="470" cy="690" r="8" fill="#CCFF90" filter="url(#fruitGlow)">
                <animate attributeName="r" values="8;10;8" dur="4.2s" repeatCount="indefinite" />
            </circle>
            <circle cx="510" cy="680" r="8" fill="#CCFF90" filter="url(#fruitGlow)">
                <animate attributeName="r" values="8;10;8" dur="3.7s" repeatCount="indefinite" />
            </circle>
            <circle cx="430" cy="690" r="8" fill="#CCFF90" filter="url(#fruitGlow)">
                <animate attributeName="r" values="8;10;8" dur="3.2s" repeatCount="indefinite" />
            </circle>

            {/* Super Beginner Label */}
            <text x="400" y="720" text-anchor="middle" fill="#1B5E20" font-size="16" font-weight="bold">Super Beginner</text>

            {/* Main Trunk - Core Learning Path */}
            <path d="M400,600 L400,300" stroke="url(#trunkGradient)" stroke-width="40" fill="none" stroke-linecap="round" />

            {/* Trunk Details */}
            <path d="M390,590 C395,585 405,585 410,590 C410,570 410,350 410,310 C405,305 395,305 390,310 C390,350 390,570 390,590 Z" fill="#33691E" opacity="0.2" />

            {/* Main Branches - Certification Areas */}
            <path d="M400,300 C320,280 270,240 210,220" stroke="url(#branchGradient)" stroke-width="20" fill="none" stroke-linecap="round" />
            <path d="M400,350 C340,340 300,320 240,320" stroke="url(#branchGradient)" stroke-width="20" fill="none" stroke-linecap="round" />
            <path d="M400,400 C340,400 300,410 240,420" stroke="url(#branchGradient)" stroke-width="20" fill="none" stroke-linecap="round" />
            <path d="M400,300 C480,280 530,240 590,220" stroke="url(#branchGradient)" stroke-width="20" fill="none" stroke-linecap="round" />
            <path d="M400,350 C460,340 500,320 560,320" stroke="url(#branchGradient)" stroke-width="20" fill="none" stroke-linecap="round" />
            <path d="M400,400 C460,400 500,410 560,420" stroke="url(#branchGradient)" stroke-width="20" fill="none" stroke-linecap="round" />

            {/* Smaller Sub-branches */}
            <path d="M210,220 C190,210 180,200 170,190" stroke="url(#branchGradient)" stroke-width="10" fill="none" stroke-linecap="round" />
            <path d="M210,220 C200,200 195,180 190,160" stroke="url(#branchGradient)" stroke-width="8" fill="none" stroke-linecap="round" />
            <path d="M240,320 C220,315 200,310 180,300" stroke="url(#branchGradient)" stroke-width="10" fill="none" stroke-linecap="round" />
            <path d="M240,320 C230,300 220,280 210,260" stroke="url(#branchGradient)" stroke-width="8" fill="none" stroke-linecap="round" />
            <path d="M240,420 C220,420 200,430 180,440" stroke="url(#branchGradient)" stroke-width="10" fill="none" stroke-linecap="round" />
            <path d="M240,420 C220,410 200,405 180,400" stroke="url(#branchGradient)" stroke-width="8" fill="none" stroke-linecap="round" />

            <path d="M590,220 C610,210 620,200 630,190" stroke="url(#branchGradient)" stroke-width="10" fill="none" stroke-linecap="round" />
            <path d="M590,220 C600,200 605,180 610,160" stroke="url(#branchGradient)" stroke-width="8" fill="none" stroke-linecap="round" />
            <path d="M560,320 C580,315 600,310 620,300" stroke="url(#branchGradient)" stroke-width="10" fill="none" stroke-linecap="round" />
            <path d="M560,320 C570,300 580,280 590,260" stroke="url(#branchGradient)" stroke-width="8" fill="none" stroke-linecap="round" />
            <path d="M560,420 C580,420 600,430 620,440" stroke="url(#branchGradient)" stroke-width="10" fill="none" stroke-linecap="round" />
            <path d="M560,420 C580,410 600,405 620,400" stroke="url(#branchGradient)" stroke-width="8" fill="none" stroke-linecap="round" />

            {/* Top Branch - Crown Achievement */}
            <path d="M400,300 L400,150" stroke="url(#branchGradient)" stroke-width="25" fill="none" stroke-linecap="round" />
            <path d="M400,150 C370,120 350,100 320,80" stroke="url(#branchGradient)" stroke-width="15" fill="none" stroke-linecap="round" />
            <path d="M400,150 C430,120 450,100 480,80" stroke="url(#branchGradient)" stroke-width="15" fill="none" stroke-linecap="round" />
            <path d="M400,150 C390,110 380,90 370,70" stroke="url(#branchGradient)" stroke-width="10" fill="none" stroke-linecap="round" />
            <path d="M400,150 C410,110 420,90 430,70" stroke="url(#branchGradient)" stroke-width="10" fill="none" stroke-linecap="round" />

            {/* Foliage - Knowledge Areas */}
            <circle cx="170" cy="190" r="20" fill="url(#leafGradient)" />
            <circle cx="190" cy="160" r="18" fill="url(#leafGradient)" />
            <circle cx="180" cy="300" r="20" fill="url(#leafGradient)" />
            <circle cx="210" cy="260" r="18" fill="url(#leafGradient)" />
            <circle cx="180" cy="440" r="20" fill="url(#leafGradient)" />
            <circle cx="180" cy="400" r="18" fill="url(#leafGradient)" />

            <circle cx="630" cy="190" r="20" fill="url(#leafGradient)" />
            <circle cx="610" cy="160" r="18" fill="url(#leafGradient)" />
            <circle cx="620" cy="300" r="20" fill="url(#leafGradient)" />
            <circle cx="590" cy="260" r="18" fill="url(#leafGradient)" />
            <circle cx="620" cy="440" r="20" fill="url(#leafGradient)" />
            <circle cx="620" cy="400" r="18" fill="url(#leafGradient)" />

            <circle cx="320" cy="80" r="20" fill="url(#leafGradient)" />
            <circle cx="480" cy="80" r="20" fill="url(#leafGradient)" />
            <circle cx="370" cy="70" r="15" fill="url(#leafGradient)" />
            <circle cx="430" cy="70" r="15" fill="url(#leafGradient)" />

            {/* Certification Fruits */}
            <circle cx="210" cy="220" r="25" fill="url(#fruitGradient)" filter="url(#fruitGlow)">
                <animate attributeName="r" values="25;28;25" dur="3s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.9;1;0.9" dur="3s" repeatCount="indefinite" />
            </circle>
            <circle cx="240" cy="320" r="25" fill="url(#fruitGradient)" filter="url(#fruitGlow)">
                <animate attributeName="r" values="25;28;25" dur="4s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.9;1;0.9" dur="4s" repeatCount="indefinite" />
            </circle>
            <circle cx="240" cy="420" r="25" fill="url(#fruitGradient)" filter="url(#fruitGlow)">
                <animate attributeName="r" values="25;28;25" dur="3.5s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.9;1;0.9" dur="3.5s" repeatCount="indefinite" />
            </circle>

            <circle cx="590" cy="220" r="25" fill="url(#fruitGradient)" filter="url(#fruitGlow)">
                <animate attributeName="r" values="25;28;25" dur="3.2s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.9;1;0.9" dur="3.2s" repeatCount="indefinite" />
            </circle>
            <circle cx="560" cy="320" r="25" fill="url(#fruitGradient)" filter="url(#fruitGlow)">
                <animate attributeName="r" values="25;28;25" dur="4.2s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.9;1;0.9" dur="4.2s" repeatCount="indefinite" />
            </circle>
            <circle cx="560" cy="420" r="25" fill="url(#fruitGradient)" filter="url(#fruitGlow)">
                <animate attributeName="r" values="25;28;25" dur="3.7s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.9;1;0.9" dur="3.7s" repeatCount="indefinite" />
            </circle>

            {/* TACS Master Certification */}
            <circle cx="400" cy="150" r="35" fill="url(#fruitGradient)" filter="url(#fruitGlow)">
                <animate attributeName="r" values="35;40;35" dur="5s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.9;1;0.9" dur="5s" repeatCount="indefinite" />
            </circle>

            {/* Certification Labels */}
            <text x="210" y="220" text-anchor="middle" fill="white" font-size="12" font-weight="bold">STACK</text>
            <text x="240" y="320" text-anchor="middle" fill="white" font-size="12" font-weight="bold">CSS</text>
            <text x="240" y="420" text-anchor="middle" fill="white" font-size="12" font-weight="bold">SPACE</text>

            <text x="590" y="220" text-anchor="middle" fill="white" font-size="12" font-weight="bold">TIES</text>
            <text x="560" y="320" text-anchor="middle" fill="white" font-size="12" font-weight="bold">AOS</text>
            <text x="560" y="420" text-anchor="middle" fill="white" font-size="12" font-weight="bold">CFE</text>

            <text x="400" y="150" text-anchor="middle" fill="white" font-size="14" font-weight="bold">TACS</text>
            <text x="400" y="170" text-anchor="middle" fill="white" font-size="10">MASTER</text>

            {/* Knowledge Flow */}
            <g stroke="#A5D6A7" stroke-width="1">
                <path d="M400,500 C390,450 380,400 390,350" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="3s" repeatCount="indefinite" />
                </path>
                <path d="M400,500 C410,450 420,400 410,350" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="4s" repeatCount="indefinite" />
                </path>
                <path d="M390,350 C380,330 390,310 400,300" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="3.5s" repeatCount="indefinite" />
                </path>
                <path d="M410,350 C420,330 410,310 400,300" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="4.5s" repeatCount="indefinite" />
                </path>

                <path d="M400,350 C380,340 360,330 340,330" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="3.2s" repeatCount="indefinite" />
                </path>
                <path d="M340,330 C320,330 300,325 280,325" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="3.8s" repeatCount="indefinite" />
                </path>
                <path d="M280,325 C260,325 250,322 240,320" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="4.2s" repeatCount="indefinite" />
                </path>

                <path d="M400,350 C420,340 440,330 460,330" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="3.7s" repeatCount="indefinite" />
                </path>
                <path d="M460,330 C480,330 500,325 520,325" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="4.7s" repeatCount="indefinite" />
                </path>
                <path d="M520,325 C540,325 550,322 560,320" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="5.2s" repeatCount="indefinite" />
                </path>

                <path d="M400,300 C390,250 380,200 390,150" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="3.4s" repeatCount="indefinite" />
                </path>
                <path d="M400,300 C410,250 420,200 410,150" fill="none">
                    <animate attributeName="stroke" values="#A5D6A7;#E8F5E9;#A5D6A7" dur="4.3s" repeatCount="indefinite" />
                </path>
            </g>

            {/* Titles */}
            <text x="400" y="50" text-anchor="middle" fill="#1B5E20" font-size="24" font-weight="bold">The Academy</text>
            <text x="400" y="80" text-anchor="middle" fill="#1B5E20" font-size="16">Learning Tree</text>

            {/* Educational Symbols */}
            <g transform="translate(130,150) scale(0.8)">
                <path d="M0,0 C5,-10 15,-10 20,0 C25,10 15,20 10,15 C5,20 -5,10 0,0Z" fill="#388E3C" opacity="0.7" />
                <text x="10" y="5" text-anchor="middle" fill="white" font-size="10">?</text>
            </g>

            <g transform="translate(650,150) scale(0.8)">
                <path d="M0,0 L20,0 L20,15 L10,20 L0,15 Z" fill="#388E3C" opacity="0.7" />
                <path d="M5,3 L15,3 M5,7 L15,7 M5,11 L15,11" stroke="white" stroke-width="1" />
            </g>

            <g transform="translate(120,400) scale(0.8)">
                <circle cx="10" cy="10" r="10" fill="#388E3C" opacity="0.7" />
                <path d="M5,10 L15,10 M10,5 L10,15" stroke="white" stroke-width="1.5" />
            </g>

            <g transform="translate(670,400) scale(0.8)">
                <rect x="0" y="0" width="20" height="20" rx="5" fill="#388E3C" opacity="0.7" />
                <path d="M5,5 L15,15 M5,15 L15,5" stroke="white" stroke-width="1.5" />
            </g>

            {/* Knowledge Flow Animation */}
            <g fill="#B9F6CA" opacity="0.6">
                <circle cx="400" cy="550" r="3">
                    <animate attributeName="cy" values="550;500;450;400;350;300;250;200;150" dur="10s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.6;0.8;0.6" dur="2s" repeatCount="indefinite" />
                </circle>
                <circle cx="400" cy="550" r="3">
                    <animate attributeName="cy" values="550;500;450;400;350;300;250;200;150" dur="12s" repeatCount="indefinite" begin="1s" />
                    <animate attributeName="opacity" values="0.6;0.8;0.6" dur="2.5s" repeatCount="indefinite" />
                </circle>
                <circle cx="400" cy="550" r="3">
                    <animate attributeName="cy" values="550;520;480;440;400;380;360;340;320" dur="8s" repeatCount="indefinite" />
                    <animate attributeName="cx" values="400;390;380;350;320;290;260;240" dur="8s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.6;0.8;0.6" dur="3s" repeatCount="indefinite" />
                </circle>
                <circle cx="400" cy="550" r="3">
                    <animate attributeName="cy" values="550;520;480;440;400;380;360;340;320" dur="9s" repeatCount="indefinite" begin="2s" />
                    <animate attributeName="cx" values="400;410;420;450;480;510;540;560" dur="9s" repeatCount="indefinite" begin="2s" />
                    <animate attributeName="opacity" values="0.6;0.8;0.6" dur="2.2s" repeatCount="indefinite" />
                </circle>
                <circle cx="400" cy="550" r="3">
                    <animate attributeName="cy" values="550;500;450;400;350;300;250;200;180;160" dur="7s" repeatCount="indefinite" begin="3s" />
                    <animate attributeName="cx" values="400;390;380;370;360;350;340;330;320" dur="7s" repeatCount="indefinite" begin="3s" />
                    <animate attributeName="opacity" values="0.6;0.8;0.6" dur="2.8s" repeatCount="indefinite" />
                </circle>
                <circle cx="400" cy="550" r="3">
                    <animate attributeName="cy" values="550;500;450;400;350;300;250;200;180;160" dur="11s" repeatCount="indefinite" begin="1.5s" />
                    <animate attributeName="cx" values="400;410;420;430;440;450;460;470;480" dur="11s" repeatCount="indefinite" begin="1.5s" />
                    <animate attributeName="opacity" values="0.6;0.8;0.6" dur="3.2s" repeatCount="indefinite" />
                </circle>
            </g>
        </svg>
    );
};

export default TaytromAcademyPillar;