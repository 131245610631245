

// FrontAutocrat.jsx - Enhanced version for Taytrom Learning Modules with comprehensive Claude AI integration
import React from 'react';
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import AIContextManager from "@/ai-scope/contexts/AIContextManager.js";


// Constants
export const MESSAGE_TYPES = {
    // Original message types
    USER_INPUT: 'USER_INPUT',
    AI_RESPONSE: 'AI_RESPONSE',
    SUBSCRIBE: 'SUBSCRIBE',
    UNSUBSCRIBE: 'UNSUBSCRIBE',
    ERROR: 'ERROR',
    NOTIFICATION: 'NOTIFICATION',
    STATE_UPDATE: 'STATE_UPDATE',

    // Enhanced cross-component message types
    PROFILE_UPDATE: 'PROFILE_UPDATE',
    PROGRESS_SYNC: 'PROGRESS_SYNC',
    EXPEDITION_PROGRESS: 'EXPEDITION_PROGRESS',
    SETTINGS_CHANGE: 'SETTINGS_CHANGE',
    AI_PERSONALIZATION_UPDATE: 'AI_PERSONALIZATION_UPDATE',
    MODULE_COMPLETION: 'MODULE_COMPLETION',
    ACHIEVEMENT_UNLOCKED: 'ACHIEVEMENT_UNLOCKED',
    LEARNING_CONTEXT_CHANGE: 'LEARNING_CONTEXT_CHANGE',
    DATA_REQUEST: 'DATA_REQUEST',
    DATA_RESPONSE: 'DATA_RESPONSE',
    DATA_UPDATE: 'DATA_UPDATE',
    DATA_UPDATE_RESPONSE: 'DATA_UPDATE_RESPONSE',
    SYNC_START: 'SYNC_START',
    SYNC_COMPLETE: 'SYNC_COMPLETE',
    AI_THINKING: 'AI_THINKING',


    // Add these new payment-related message types
    PAYMENT_INITIATED: 'PAYMENT_INITIATED',
    PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    SUBSCRIPTION_CREATED: 'SUBSCRIPTION_CREATED',
    SUBSCRIPTION_UPDATED: 'SUBSCRIPTION_UPDATED',
    SUBSCRIPTION_CANCELLED: 'SUBSCRIPTION_CANCELLED',
    BILLING_ERROR: 'BILLING_ERROR',

    // Add these new payment-related message types
    TRIAL_STARTED: 'TRIAL_STARTED',
    TRIAL_ENDING_SOON: 'TRIAL_ENDING_SOON',
    TRIAL_CONVERTED: 'TRIAL_CONVERTED',
};

export const COMPONENT_TYPES = {
    // Core system components
    FRONT_ORCHESTRATOR: 'FRONT_ORCHESTRATOR',
    AUTH_MANAGER: 'AUTH_MANAGER',
    CLAUDE_MANAGER: 'CLAUDE_MANAGER',
    PROGRESS_MANAGER: 'PROGRESS_MANAGER',
    SETTINGS_MANAGER: 'SETTINGS_MANAGER',
    EXPEDITION_MANAGER: 'EXPEDITION_MANAGER',
    PERSONALIZATION_BRIDGE: 'PERSONALIZATION_BRIDGE',

    AI_INTELLIGENCE_MANAGER: 'AI_INTELLIGENCE_MANAGER',


    // UI components
    AI_COMPONENT: 'AI_COMPONENT',
    USER_PROGRESS_PORTAL: 'USER_PROGRESS_PORTAL',
    USER_PROFILE: 'USER_PROFILE',
    SETTINGS_PANEL: 'SETTINGS_PANEL',
    EXPEDITION_VIEW: 'EXPEDITION_VIEW',
    NOTIFICATION_MANAGER: 'NOTIFICATION_MANAGER',


};

export const EVENT_TYPES = {
    // User events
    USER_LOGGED_IN: 'USER_LOGGED_IN',
    USER_LOGGED_OUT: 'USER_LOGGED_OUT',
    PASSWORD_RESET_REQUESTED: 'PASSWORD_RESET_REQUESTED',

    // Data events
    REQUEST_USER_DATA: 'REQUEST_USER_DATA',
    USER_DATA_RESPONSE: 'USER_DATA_RESPONSE',
    UPDATE_USER_DATA: 'UPDATE_USER_DATA',
    USER_DATA_UPDATE_RESPONSE: 'USER_DATA_UPDATE_RESPONSE',

    // Progress events
    MODULE_STARTED: 'MODULE_STARTED',
    MODULE_COMPLETED: 'MODULE_COMPLETED',
    ACHIEVEMENT_UNLOCKED: 'ACHIEVEMENT_UNLOCKED',
    EXPEDITION_REGION_COMPLETED: 'EXPEDITION_REGION_COMPLETED',

    // AI events
    AI_PERSONALIZATION_CHANGED: 'AI_PERSONALIZATION_CHANGED',
    AI_CONTEXT_UPDATED: 'AI_CONTEXT_UPDATED',

    // UI events
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
    HIDE_NOTIFICATION: 'HIDE_NOTIFICATION'
};

export const SYSTEM_STATES = {
    CREATED: 'CREATED',
    INITIALIZING: 'INITIALIZING',
    RUNNING: 'RUNNING',
    ERROR: 'ERROR',
    SHUTDOWN: 'SHUTDOWN',
    PAYMENT_PROCESSING: 'PAYMENT_PROCESSING'  // Add this line

};

// Production API URL configuration
const API_URL = import.meta.env.VITE_API_URL || window.location.origin;

// Utility Functions
const generateId = (prefix = 'msg') => {
    return `${prefix}_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
};

const logger = (component, action, message, details = '') => {
    console.log(
        `[${component}] ${action}: ${message}` + (details ? ` | ${details}` : '')
    );
};

/**
 * Base Manager class that all specialized managers extend
 */
class BaseManager {
    constructor(orchestrator, name) {
        this.orchestrator = orchestrator;
        this.name = name;
    }

    log(action, message, details = '') {
        logger(this.name, action, message, details);
    }

    handleError(error, context = {}) {
        this.log('ERROR', error.message, JSON.stringify(context));
        if (this.orchestrator) {
            this.orchestrator.handleError(error, { source: this.name, ...context });
        }
    }

    // Generic message handler that all managers should implement
    handleMessage(message) {
        this.log('HandleMessage', `Received message of type ${message.type}`,
            `From: ${message.from}, To: ${message.to || 'broadcast'}`);
        return { success: true, handled: false, message: 'Message not handled by this manager' };
    }

    cleanup() {
        this.log('Cleanup', 'Resources released');
    }
}

/**
 * Manages subscriptions for different event types
 */
class SubscriptionManager extends BaseManager {
    constructor(orchestrator) {
        super(orchestrator, 'SubscriptionManager');
        this._subscriptions = {
            ai: new Map(),      // AI responses
            module: new Map(),  // Learning module updates
            error: new Map(),   // Error notifications
            state: new Map(),   // State changes
            auth: new Map(),    // Authentication events
            progress: new Map(), // Progress updates
            settings: new Map(), // Settings changes
            expedition: new Map(), // Expedition updates
            global: new Map(),   // Global broadcasts
        };

        // Component-specific subscriptions
        Object.values(COMPONENT_TYPES).forEach(componentType => {
            this._subscriptions[componentType.toLowerCase()] = new Map();
        });

        this.log('Initialize', 'Subscription system ready');
    }

    /**
     * Subscribe to a particular event type
     */
    subscribe(type, handler, source) {
        if (!this._subscriptions[type]) {
            // Create a new subscription type if it doesn't exist
            this._subscriptions[type] = new Map();
            this.log('Info', `Created new subscription type: ${type}`);
        }

        const subscriptionId = generateId('sub');
        this._subscriptions[type].set(subscriptionId, {
            handler,
            source,
            timestamp: Date.now()
        });

        this.log('Subscribe', `New ${type} subscriber added`, subscriptionId);
        return {
            success: true,
            subscriptionId,
            type
        };
    }

    /**
     * Unsubscribe from events
     */
    unsubscribe(type, subscriptionId) {
        if (!this._subscriptions[type]) {
            return { success: false, error: 'Invalid subscription type' };
        }

        const result = this._subscriptions[type].delete(subscriptionId);
        this.log('Unsubscribe', `Subscriber ${subscriptionId} removed`, `Success: ${result}`);

        return {
            success: result,
            message: result ? 'Unsubscribed successfully' : 'Subscription not found'
        };
    }

    /**
     * Notify all subscribers of a particular event type
     */
    notify(type, data) {
        if (!this._subscriptions[type]) {
            this.log('Warning', `No subscriptions for type: ${type}`);
            return { success: false, error: 'Invalid notification type' };
        }

        const subscribers = this._subscriptions[type];
        if (subscribers.size === 0) {
            this.log('Info', `No subscribers for ${type}`);
            return { success: true, notified: 0 };
        }

        let notifiedCount = 0;
        const notification = {
            id: generateId('notify'),
            type,
            timestamp: Date.now(),
            data
        };

        subscribers.forEach((sub, id) => {
            try {
                sub.handler(notification);
                notifiedCount++;
            } catch (error) {
                this.handleError(error, {
                    operation: 'notify',
                    subscriptionId: id,
                    notificationType: type
                });
            }
        });

        this.log('Notify', `Notified ${notifiedCount} subscribers`, `Type: ${type}`);
        return {
            success: true,
            notified: notifiedCount,
            total: subscribers.size
        };
    }

    /**
     * Get statistics about current subscriptions
     */
    getSubscriptionStats() {
        const stats = {};

        Object.entries(this._subscriptions).forEach(([type, subscribers]) => {
            stats[type] = subscribers.size;
        });

        return stats;
    }

    cleanup() {
        Object.keys(this._subscriptions).forEach(type => {
            this._subscriptions[type].clear();
        });
        super.cleanup();
    }

    handleMessage(message) {
        if (message.type === MESSAGE_TYPES.SUBSCRIBE) {
            return this.subscribe(message.payload.type, message.payload.handler, message.from);
        } else if (message.type === MESSAGE_TYPES.UNSUBSCRIBE) {
            return this.unsubscribe(message.payload.type, message.payload.subscriptionId);
        }

        return super.handleMessage(message);
    }
}

/**
 * Manages user authentication for the Taytrom platform
 */
class AuthManager extends BaseManager {
    constructor(orchestrator) {
        super(orchestrator, 'AuthManager');
        this._currentUser = null;
        this._isAuthenticated = false;
        this._authListeners = new Set();
        this._tokenStorage = localStorage;

        // Store the auth service methods
        this.authService = authService;

        // Initialize by checking for existing token
        this._initializeAuth();

        this.log('Initialize', 'Auth Manager initialized');
    }

    /**
     * Initialize authentication from stored token
     */
    _initializeAuth() {
        try {
            // Use the auth service to check authentication status
            if (this.authService && this.authService.checkAuthStatus) {
                const { isAuthenticated, user } = this.authService.checkAuthStatus();
                this._isAuthenticated = isAuthenticated;
                this._currentUser = user;

                if (isAuthenticated) {
                    this.log('Auth', 'Restored auth state from storage');

                    // Validate token with the server
                    this._validateToken();
                }
            } else {
                // Fallback to the original localStorage method if auth service is not available
                const token = this._tokenStorage.getItem('taytrom_auth_token');
                const userData = this._tokenStorage.getItem('taytrom_user_data');

                if (token && userData) {
                    this._currentUser = JSON.parse(userData);
                    this._isAuthenticated = true;
                    this.log('Auth', 'Restored auth state from storage (fallback method)');
                }
            }
        } catch (error) {
            this.handleError(error, { operation: '_initializeAuth' });
            this._clearAuthData();
        }
    }

    /**
     * Validate the token with the server
     */
    async _validateToken() {
        if (this.authService && this.authService.getCurrentUser) {
            try {
                const result = await this.authService.getCurrentUser();

                if (!result.success) {
                    this._clearAuthData();
                } else if (result.user) {
                    // Update user data
                    this._currentUser = result.user;
                }
            } catch (error) {
                this.handleError(error, { operation: '_validateToken' });
            }
        }
    }

    /**
     * Register a new user with invitation code validation
     */
    async register(userData) {
        try {
            this.log('Register', 'Registering new user', userData.email);

            // If we have the auth service, use it
            if (this.authService && this.authService.register) {
                const result = await this.authService.register(userData);

                if (result.success) {
                    this._currentUser = result.user;
                    this._isAuthenticated = true;
                    this._notifyAuthStateChanged();
                }

                return result;
            }

            // Fallback to the original implementation
            const validInvitationCode = "TAYTROM2025";
            if (!userData.invitationCode || userData.invitationCode !== validInvitationCode) {
                return {
                    success: false,
                    error: 'Invalid invitation code'
                };
            }

            // For demo purposes, simulate a successful registration
            await new Promise(resolve => setTimeout(resolve, 1000));

            return {
                success: true,
                message: 'Registration successful'
            };
        } catch (error) {
            this.handleError(error, { operation: 'register', email: userData.email });
            return {
                success: false,
                error: error.message || 'Registration failed'
            };
        }
    }

    /**
     * Log in a user with email and password
     */
    async login(email, password, rememberMe = false) {
        try {
            this.log('Login', 'Authenticating user', email);

            // If we have the auth service, use it
            if (this.authService && this.authService.login) {
                const result = await this.authService.login(email, password, rememberMe);

                if (result.success) {
                    this._currentUser = result.user;
                    this._isAuthenticated = true;
                    this._notifyAuthStateChanged();

                    // Notify about successful login
                    if (this.orchestrator && this.orchestrator.subscriptionManager) {
                        this.orchestrator.subscriptionManager.notify('auth', {
                            type: 'USER_LOGGED_IN',
                            user: {
                                email: result.user.email,
                                id: result.user.id,
                                timestamp: Date.now()
                            }
                        });
                    }
                }

                return result;
            }

            // Fallback to the original implementation
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Create mock user data for demo
            const userData = {
                id: 'user_' + Date.now(),
                email,
                fullName: email.split('@')[0],
                preferences: {},
                roles: ['user'],
                createdAt: new Date().toISOString()
            };

            // Generate a mock token
            const token = `mock_token_${Date.now()}_${Math.random().toString(36).substring(2, 15)}`;

            // Store authentication data
            this._storeAuthData(userData, token, rememberMe);

            // Notify about successful login
            if (this.orchestrator && this.orchestrator.subscriptionManager) {
                this.orchestrator.subscriptionManager.notify('auth', {
                    type: 'USER_LOGGED_IN',
                    user: {
                        email: userData.email,
                        id: userData.id,
                        timestamp: Date.now()
                    }
                });
            }

            return {
                success: true,
                user: userData
            };
        } catch (error) {
            this.handleError(error, { operation: 'login', email });
            return {
                success: false,
                error: error.message || 'Authentication failed'
            };
        }
    }

    /**
     * Log out the current user
     */
    async logout() {
        try {
            this.log('Logout', 'Logging out user');

            // If we have the auth service, use it
            if (this.authService && this.authService.logout) {
                const result = await this.authService.logout();

                if (result.success) {
                    this._currentUser = null;
                    this._isAuthenticated = false;
                    this._notifyAuthStateChanged();
                }

                // Notify orchestrator about successful logout
                if (this.orchestrator && this.orchestrator.subscriptionManager) {
                    this.orchestrator.subscriptionManager.notify('auth', {
                        type: 'USER_LOGGED_OUT',
                        timestamp: Date.now()
                    });
                }

                return result;
            }

            // Fallback to the original implementation
            this._clearAuthData();
            this._notifyAuthStateChanged();

            // Notify orchestrator about successful logout
            if (this.orchestrator && this.orchestrator.subscriptionManager) {
                this.orchestrator.subscriptionManager.notify('auth', {
                    type: 'USER_LOGGED_OUT',
                    timestamp: Date.now()
                });
            }

            return { success: true };
        } catch (error) {
            this.handleError(error, { operation: 'logout' });
            return {
                success: false,
                error: error.message || 'Logout failed'
            };
        }
    }

    /**
     * Request a password reset
     */
    async forgotPassword(email) {
        try {
            this.log('ForgotPassword', 'Requesting password reset', email);

            // If we have the auth service, use it
            if (this.authService && this.authService.forgotPassword) {
                return await this.authService.forgotPassword(email);
            }

            // Fallback to the original implementation
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Notify about password reset request
            if (this.orchestrator && this.orchestrator.subscriptionManager) {
                this.orchestrator.subscriptionManager.notify('auth', {
                    type: 'PASSWORD_RESET_REQUESTED',
                    email,
                    timestamp: Date.now()
                });
            }

            return {
                success: true,
                message: 'Password reset instructions sent'
            };
        } catch (error) {
            this.handleError(error, { operation: 'forgotPassword', email });
            return {
                success: false,
                error: error.message || 'Failed to send reset instructions'
            };
        }
    }

    /**
     * Update the user's profile
     */
    async updateProfile(userData) {
        try {
            this.log('UpdateProfile', 'Updating user profile');

            // Ensure user is authenticated
            if (!this._isAuthenticated) {
                throw new Error('User must be authenticated to update profile');
            }

            // If we have the auth service, use it
            if (this.authService && this.authService.updateProfile) {
                const result = await this.authService.updateProfile(userData);

                if (result.success) {
                    this._currentUser = result.user;
                    this._notifyAuthStateChanged();

                    // Notify about profile update
                    if (this.orchestrator && this.orchestrator.subscriptionManager) {
                        this.orchestrator.subscriptionManager.notify('auth', {
                            type: MESSAGE_TYPES.PROFILE_UPDATE,
                            user: result.user,
                            timestamp: Date.now()
                        });
                    }
                }

                return result;
            }

            // Fallback to the original implementation
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Update the local user data
            const updatedUser = {
                ...this._currentUser,
                ...userData,
                updatedAt: new Date().toISOString()
            };

            // Store the updated user data
            this._tokenStorage.setItem('taytrom_user_data', JSON.stringify(updatedUser));
            this._currentUser = updatedUser;

            // Notify subscribers
            this._notifyAuthStateChanged();

            return {
                success: true,
                user: updatedUser
            };
        } catch (error) {
            this.handleError(error, { operation: 'updateProfile' });
            return {
                success: false,
                error: error.message || 'Failed to update profile'
            };
        }
    }

    /**
     * Store authentication data in localStorage
     */
    _storeAuthData(userData, token, rememberMe) {
        try {
            // Store token and user data
            this._tokenStorage.setItem('taytrom_auth_token', token);
            this._tokenStorage.setItem('taytrom_user_data', JSON.stringify(userData));
            this._tokenStorage.setItem('taytrom_remember_me', String(rememberMe));

            // Update internal state
            this._currentUser = userData;
            this._isAuthenticated = true;

            // Notify subscribers
            this._notifyAuthStateChanged();
        } catch (error) {
            this.handleError(error, { operation: '_storeAuthData' });
            throw error;
        }
    }

    /**
     * Clear authentication data
     */
    _clearAuthData() {
        try {
            // Remove all auth-related data from storage
            this._tokenStorage.removeItem('taytrom_auth_token');
            this._tokenStorage.removeItem('taytrom_user_data');
            this._tokenStorage.removeItem('taytrom_remember_me');

            // Update internal state
            this._currentUser = null;
            this._isAuthenticated = false;

            // Notify subscribers
            this._notifyAuthStateChanged();
        } catch (error) {
            this.handleError(error, { operation: '_clearAuthData' });
        }
    }

    /**
     * Notify subscribers about auth state changes
     */
    _notifyAuthStateChanged() {
        const authState = {
            isAuthenticated: this._isAuthenticated,
            user: this._currentUser,
            timestamp: Date.now()
        };

        this._authListeners.forEach(listener => {
            try {
                listener(authState);
            } catch (error) {
                this.handleError(error, { operation: '_notifyAuthStateChanged' });
            }
        });
    }

    /**
     * Subscribe to authentication state changes
     */
    onAuthStateChanged(listener) {
        if (typeof listener !== 'function') {
            throw new Error('Auth state listener must be a function');
        }

        this._authListeners.add(listener);

        // Immediately notify with current state
        listener({
            isAuthenticated: this._isAuthenticated,
            user: this._currentUser,
            timestamp: Date.now()
        });

        // Return unsubscribe function
        return () => {
            this._authListeners.delete(listener);
        };
    }

    /**
     * Get the current authentication state
     */
    getAuthState() {
        return {
            isAuthenticated: this._isAuthenticated,
            user: this._currentUser
        };
    }

    /**
     * Check if the user is authenticated
     */
    isAuthenticated() {
        return this._isAuthenticated;
    }

    /**
     * Get the current user
     */
    getCurrentUser() {
        return this._currentUser;
    }

    /**
     * Clean up resources
     */
    cleanup() {
        this._authListeners.clear();
        this.log('Cleanup', 'Auth Manager resources released');
    }

    /**
     * Handle messages directed to auth manager
     */
    handleMessage(message) {
        switch (message.type) {
            case MESSAGE_TYPES.DATA_REQUEST:
                if (message.event === EVENT_TYPES.REQUEST_USER_DATA) {
                    const userData = this.getCurrentUser();

                    // Send back the response
                    this.orchestrator.messageRelay({
                        type: MESSAGE_TYPES.DATA_RESPONSE,
                        from: COMPONENT_TYPES.AUTH_MANAGER,
                        to: message.from,
                        event: EVENT_TYPES.USER_DATA_RESPONSE,
                        payload: {
                            userData: userData,
                            timestamp: Date.now()
                        }
                    });

                    return { success: true, handled: true };
                }
                break;

            case MESSAGE_TYPES.DATA_UPDATE:
                if (message.event === EVENT_TYPES.UPDATE_USER_DATA) {
                    this.updateProfile(message.payload.data).then(result => {
                        // Send back the response
                        this.orchestrator.messageRelay({
                            type: MESSAGE_TYPES.DATA_UPDATE_RESPONSE,
                            from: COMPONENT_TYPES.AUTH_MANAGER,
                            to: message.from,
                            event: EVENT_TYPES.USER_DATA_UPDATE_RESPONSE,
                            payload: {
                                success: result.success,
                                error: result.error,
                                timestamp: Date.now()
                            }
                        });
                    });

                    return { success: true, handled: true };
                }
                break;
        }

        return super.handleMessage(message);
    }
}


/**
 * Manages user settings and app configuration
 */
class SettingsManager extends BaseManager {
    constructor(orchestrator) {
        super(orchestrator, 'SettingsManager');
        this._userSettings = new Map(); // userId -> settings
        this._appSettings = {}; // Global app settings
        this._defaultSettings = {
            theme: 'system', // 'light', 'dark', or 'system'
            fontSize: 'medium', // 'small', 'medium', or 'large'
            notifications: {
                achievements: true,
                progress: true,
                reminders: true,
                announcements: true
            },
            accessibility: {
                reducedMotion: false,
                highContrast: false,
                screenReader: false
            },
            ai: {
                showThinking: false,
                thinkingBudget: 2048,
                personalizationLevel: 2,
                activeContexts: {
                    module: true,
                    progress: true,
                    achievements: true,
                    analytics: true
                },
                contextWeighting: {
                    module: 0.4,
                    progress: 0.3,
                    achievements: 0.15,
                    analytics: 0.15
                }
            },
            privacy: {
                shareProgress: true,
                shareAnalytics: true,
                storeLearningHistory: true
            }
        };

        // Initialize app settings
        this._initializeAppSettings();

        this.log('Initialize', 'Settings Manager initialized');
    }

    /**
     * Initialize global app settings
     */
    _initializeAppSettings() {
        try {
            // Try to load from localStorage
            const storedSettings = localStorage.getItem('taytrom_app_settings');
            if (storedSettings) {
                this._appSettings = JSON.parse(storedSettings);
                this.log('AppSettings', 'Loaded app settings from localStorage');
            } else {
                // Set defaults
                this._appSettings = {
                    version: '1.0.0',
                    environment: import.meta.env.NODE_ENV || 'development',
                    features: {
                        aiCompanion: true,
                        expeditions: true,
                        achievements: true,
                        knowledgeGraph: true
                    },
                    apiEndpoints: {
                        claude: `${API_URL}/api/claude`,
                        progress: `${API_URL}/api/progress`,
                        auth: `${API_URL}/api/auth`
                    }
                };

                // Save to localStorage
                localStorage.setItem('taytrom_app_settings', JSON.stringify(this._appSettings));
            }
        } catch (error) {
            this.handleError(error, { operation: '_initializeAppSettings' });

            // Set defaults on error
            this._appSettings = {
                version: '1.0.0',
                environment: 'development',
                features: {
                    aiCompanion: true,
                    expeditions: true,
                    achievements: true,
                    knowledgeGraph: true
                }
            };
        }
    }

    /**
     * Get user-specific settings
     */
    getUserPreferences(userId) {
        // Try to get from memory first
        if (this._userSettings.has(userId)) {
            return this._userSettings.get(userId);
        }

        // Try to get from localStorage as fallback
        try {
            const storedSettings = localStorage.getItem(`taytrom_settings_${userId}`);
            if (storedSettings) {
                const settings = JSON.parse(storedSettings);
                this._userSettings.set(userId, settings);
                return settings;
            }
        } catch (error) {
            this.log('Warning', `Failed to load settings from localStorage: ${error.message}`);
        }

        // Use default settings
        this._userSettings.set(userId, this._defaultSettings);
        return this._defaultSettings;
    }

    /**
     * Update user settings
     */
    updateUserPreferences(userId, settings) {
        try {
            // Get current settings
            const currentSettings = this.getUserPreferences(userId);

            // Merge with updates (deep merge)
            const updatedSettings = this._deepMerge(currentSettings, settings);

            // Save the updated settings
            this._userSettings.set(userId, updatedSettings);

            // Store in localStorage as backup
            localStorage.setItem(`taytrom_settings_${userId}`, JSON.stringify(updatedSettings));

            // Notify about settings change
            this.orchestrator.subscriptionManager.notify('settings', {
                type: MESSAGE_TYPES.SETTINGS_CHANGE,
                userId,
                settings: updatedSettings,
                timestamp: Date.now()
            });

            this.log('UpdateSettings', `Updated settings for user ${userId}`);

            return { success: true, settings: updatedSettings };
        } catch (error) {
            this.handleError(error, { operation: 'updateUserPreferences', userId });

            return { success: false, error: error.message };
        }
    }

    /**
     * Update AI configuration settings
     */
    updateAIConfiguration(userId, aiConfig) {
        try {
            // Get current settings
            const currentSettings = this.getUserPreferences(userId);

            // Update AI settings
            const updatedSettings = {
                ...currentSettings,
                ai: {
                    ...currentSettings.ai,
                    ...aiConfig
                }
            };

            // Save the updated settings
            this._userSettings.set(userId, updatedSettings);

            // Store in localStorage as backup
            localStorage.setItem(`taytrom_settings_${userId}`, JSON.stringify(updatedSettings));

            // Notify about AI settings change
            this.orchestrator.subscriptionManager.notify('settings', {
                type: MESSAGE_TYPES.AI_PERSONALIZATION_UPDATE,
                userId,
                personalization: updatedSettings.ai,
                timestamp: Date.now()
            });

            // Also notify the PersonalizationBridge specifically
            if (this.orchestrator.personalizationBridge) {
                this.orchestrator.personalizationBridge.updatePersonalizationProfile(userId, aiConfig);
            }

            this.log('UpdateAIConfig', `Updated AI configuration for user ${userId}`);

            return { success: true, settings: updatedSettings };
        } catch (error) {
            this.handleError(error, { operation: 'updateAIConfiguration', userId });

            return { success: false, error: error.message };
        }
    }

    /**
     * Get global app settings
     */
    getAppSettings() {
        return this._appSettings;
    }

    /**
     * Update global app settings
     */
    updateAppSettings(settings) {
        try {
            // Merge with updates
            this._appSettings = {
                ...this._appSettings,
                ...settings
            };

            // Store in localStorage
            localStorage.setItem('taytrom_app_settings', JSON.stringify(this._appSettings));

            // Notify about settings change
            this.orchestrator.subscriptionManager.notify('settings', {
                type: MESSAGE_TYPES.SETTINGS_CHANGE,
                global: true,
                settings: this._appSettings,
                timestamp: Date.now()
            });

            this.log('UpdateAppSettings', 'Updated global app settings');

            return { success: true, settings: this._appSettings };
        } catch (error) {
            this.handleError(error, { operation: 'updateAppSettings' });

            return { success: false, error: error.message };
        }
    }

    /**
     * Apply a theme to the application
     */
    applyTheme(theme, userId = null) {
        try {
            // Update root element class
            if (typeof document !== 'undefined') {
                document.documentElement.setAttribute('data-theme', theme);
            }

            // Store in local storage for persistence
            localStorage.setItem('taytrom-theme', theme);

            // Update user preferences if a userId is provided
            if (userId) {
                const currentSettings = this.getUserPreferences(userId);
                const updatedSettings = {
                    ...currentSettings,
                    theme
                };

                this._userSettings.set(userId, updatedSettings);
                localStorage.setItem(`taytrom_settings_${userId}`, JSON.stringify(updatedSettings));
            }

            this.log('ApplyTheme', `Applied theme: ${theme}`);

            return { success: true, theme };
        } catch (error) {
            this.handleError(error, { operation: 'applyTheme', theme });

            return { success: false, error: error.message };
        }
    }

    /**
     * Helper method to deep merge objects
     */
    _deepMerge(target, source) {
        if (!source) return target;

        const output = { ...target };

        Object.keys(source).forEach(key => {
            if (source[key] === undefined) return;

            if (isObject(source[key]) && isObject(target[key])) {
                output[key] = this._deepMerge(target[key], source[key]);
            } else {
                output[key] = source[key];
            }
        });

        return output;

        function isObject(item) {
            return (item && typeof item === 'object' && !Array.isArray(item));
        }
    }

    /**
     * Reset user settings to defaults
     */
    resetUserSettings(userId) {
        try {
            // Save the default settings
            this._userSettings.set(userId, this._defaultSettings);

            // Store in localStorage as backup
            localStorage.setItem(`taytrom_settings_${userId}`, JSON.stringify(this._defaultSettings));

            // Notify about settings reset
            this.orchestrator.subscriptionManager.notify('settings', {
                type: MESSAGE_TYPES.SETTINGS_CHANGE,
                userId,
                settings: this._defaultSettings,
                reset: true,
                timestamp: Date.now()
            });

            this.log('ResetSettings', `Reset settings to defaults for user ${userId}`);

            return { success: true, settings: this._defaultSettings };
        } catch (error) {
            this.handleError(error, { operation: 'resetUserSettings', userId });

            return { success: false, error: error.message };
        }
    }

    /**
     * Clean up resources
     */
    cleanup() {
        this._userSettings.clear();
        super.cleanup();
    }

    /**
     * Handle messages directed to settings manager
     */
    handleMessage(message) {
        switch (message.type) {
            case MESSAGE_TYPES.SETTINGS_CHANGE:
            {
                const userId = this.orchestrator.authManager.getCurrentUser()?.id;
                if (!userId) {
                    return { success: false, handled: true, error: 'User not authenticated' };
                }

                const settings = message.payload.settings;
                return this.updateUserPreferences(userId, settings);
            }

            case MESSAGE_TYPES.AI_PERSONALIZATION_UPDATE:
            {
                const userId = this.orchestrator.authManager.getCurrentUser()?.id;
                if (!userId) {
                    return { success: false, handled: true, error: 'User not authenticated' };
                }

                const aiConfig = message.payload.personalization;
                return this.updateAIConfiguration(userId, aiConfig);
            }

            case MESSAGE_TYPES.DATA_REQUEST:
                if (message.event === EVENT_TYPES.REQUEST_USER_DATA &&
                    message.payload.dataType === 'settings') {

                    const userId = this.orchestrator.authManager.getCurrentUser()?.id;
                    if (!userId) {
                        // Send error response
                        this.orchestrator.messageRelay({
                            type: MESSAGE_TYPES.DATA_RESPONSE,
                            from: COMPONENT_TYPES.SETTINGS_MANAGER,
                            to: message.from,
                            event: EVENT_TYPES.USER_DATA_RESPONSE,
                            payload: {
                                error: 'User not authenticated',
                                timestamp: Date.now()
                            }
                        });

                        return { success: false, handled: true, error: 'User not authenticated' };
                    }

                    const settingsData = this.getUserPreferences(userId);

                    // Send back the response
                    this.orchestrator.messageRelay({
                        type: MESSAGE_TYPES.DATA_RESPONSE,
                        from: COMPONENT_TYPES.SETTINGS_MANAGER,
                        to: message.from,
                        event: EVENT_TYPES.USER_DATA_RESPONSE,
                        payload: {
                            userData: {
                                settings: settingsData
                            },
                            timestamp: Date.now()
                        }
                    });

                    return { success: true, handled: true };
                }
                break;
        }

        return super.handleMessage(message);
    }
}


// Add this class definition in FrontAutocrat.jsx before the initialize method

/**
 * Manages user progress through learning modules and expeditions
 */
class ProgressManager extends BaseManager {
    constructor(orchestrator) {
        super(orchestrator, 'ProgressManager');
        this._userProgress = new Map();
        this._expeditionData = new Map();

        this.log('Initialize', 'Progress Manager initialized');
    }

    /**
     * Get user progress data
     */
    getUserProgress(userId) {
        // Try to get from memory first
        if (this._userProgress.has(userId)) {
            return this._userProgress.get(userId);
        }

        // Try to get from localStorage as fallback
        try {
            const storedProgress = localStorage.getItem(`taytrom_progress_${userId}`);
            if (storedProgress) {
                const progress = JSON.parse(storedProgress);
                this._userProgress.set(userId, progress);
                return progress;
            }
        } catch (error) {
            this.log('Warning', `Failed to load progress from localStorage: ${error.message}`);
        }

        // Return default progress
        const defaultProgress = {
            completedModules: [],
            currentModule: null,
            achievements: [],
            lastActive: new Date().toISOString()
        };

        this._userProgress.set(userId, defaultProgress);
        return defaultProgress;
    }

    /**
     * Get user's current expedition
     */
    async getUserCurrentExpedition(userId) {
        // This is a minimal implementation
        return {
            id: 'expedition_intro',
            name: 'Introduction',
            progress: 0,
            regions: []
        };
    }

    /**
     * Sync all progress data with backend - stub implementation
     */
    async syncAllProgress() {
        return { success: true };
    }

    /**
     * Clean up resources
     */
    cleanup() {
        this._userProgress.clear();
        this._expeditionData.clear();
        super.cleanup();
    }
}



// Add this class after the SettingsManager class (around line 1130)
/**
 * Manages user subscriptions and billing
 */
class BillingManager extends BaseManager {
    constructor(orchestrator) {
        super(orchestrator, 'BillingManager');
        this._userSubscriptions = new Map(); // userId -> subscription data
        this._paymentMethods = new Map(); // userId -> payment methods
        // In the BillingManager constructor (around line 1138)
        this._subscriptionPlans = {
            'Taytrom Writer': {
                id: 'price_taytrom_writer_v1', // Updated price ID for the new plan
                price: 15, // Updated price point
                features: [
                    "Interactive Document Canvas with dynamic content organization",
                    "Structural List Visualization for document architecture",
                    "AI Suggestion Bubbles with contextual intelligence",
                    "AI Assistant Chat Overlay for natural language collaboration",
                    "Unlimited access to all platform features"
                ],
                trialDays: 7 // Add a free trial period
            }
        };
        this.log('Initialize', 'Billing Manager initialized');
    }

    // Add this method to the BillingManager class in FrontAutocrat.jsx
    /**
     * Get user subscriptions - compatible interface with Phase1App
     */
    async getUserSubscriptions(userId) {
        try {
            this.log('GetUserSubscriptions', `Checking subscription status for user ${userId}`);

            // First try to get local subscription data
            if (this._userSubscriptions.has(userId)) {
                const subscription = this._userSubscriptions.get(userId);
                return {
                    success: true,
                    hasPremium: subscription.status === 'active',
                    subscription
                };
            }

            // In production, this would call your backend API
            // For now, just return inactive status if not found locally
            return {
                success: true,
                hasPremium: false,
                subscription: null
            };
        } catch (error) {
            this.handleError(error, { operation: 'getUserSubscriptions', userId });
            return { success: false, error: error.message };
        }
    }

    /**
     * Create a subscription for a user
     */
    async createSubscription(userId, paymentMethodId, plan, email, couponCode = null) {
        try {
            this.log('CreateSubscription', `Creating subscription for user ${userId}`);

            // In production, this would call your backend API
            // Get API URL based on environment
            const apiUrl = typeof import.meta !== 'undefined' && import.meta.env && import.meta.env.VITE_API_URL
                ? import.meta.env.VITE_API_URL
                : window.location.origin;

            const response = await fetch(`${apiUrl}/api/billing/create-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('taytrom_auth_token') || ''}`,
                },
                body: JSON.stringify({
                    paymentMethodId,
                    plan,
                    email,
                    couponCode,
                    userId,
                    priceId: 'price_taytrom_writer_v1', // Update this line to use the new price ID

                }),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Subscription creation failed');
            }

            const data = await response.json();

            if (data.success) {
                // Store subscription locally
                this._userSubscriptions.set(userId, {
                    id: data.subscriptionId,
                    plan,
                    status: 'active',
                    currentPeriodEnd: data.currentPeriodEnd,
                    createdAt: new Date()
                });

                // Store payment method
                this._paymentMethods.set(userId, {
                    id: paymentMethodId,
                    lastUsed: new Date()
                });

                // Notify subscribers about subscription creation
                this.orchestrator.subscriptionManager.notify('billing', {
                    type: MESSAGE_TYPES.SUBSCRIPTION_CREATED,
                    userId,
                    plan,
                    timestamp: Date.now()
                });

                this.log('CreateSubscription', `Subscription created successfully for user ${userId}`);

                return {
                    success: true,
                    subscriptionId: data.subscriptionId,
                    clientSecret: data.clientSecret,
                    requiresAction: data.requiresAction,
                    currentPeriodEnd: data.currentPeriodEnd
                };
            } else {
                throw new Error(data.error || 'Unknown error creating subscription');
            }
        } catch (error) {
            this.handleError(error, { operation: 'createSubscription', userId, plan });
            return { success: false, error: error.message };
        }
    }

    /**
     * Get user's active subscription
     */
    getUserSubscription(userId) {
        // Check if we have subscription data in memory
        if (this._userSubscriptions.has(userId)) {
            return {
                success: true,
                subscription: this._userSubscriptions.get(userId),
                hasPremium: true
            };
        }

        // If we don't have data in memory, return default state
        // In a real app, we'd make an API call to check subscription status
        return {
            success: true,
            subscription: null,
            hasPremium: false
        };
    }

    /**
     * Cancel a user's subscription
     */
    async cancelSubscription(userId, subscriptionId) {
        try {
            this.log('CancelSubscription', `Cancelling subscription ${subscriptionId} for user ${userId}`);

            // In production, this would call your backend API
            const apiUrl = typeof import.meta !== 'undefined' && import.meta.env && import.meta.env.VITE_API_URL
                ? import.meta.env.VITE_API_URL
                : window.location.origin;

            const response = await fetch(`${apiUrl}/api/billing/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('taytrom_auth_token') || ''}`,
                },
                body: JSON.stringify({
                    subscriptionId,
                    userId
                }),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Subscription cancellation failed');
            }

            const data = await response.json();

            if (data.success) {
                // Update local subscription data
                if (this._userSubscriptions.has(userId)) {
                    const subscription = this._userSubscriptions.get(userId);
                    subscription.status = 'cancelled';
                    this._userSubscriptions.set(userId, subscription);
                }

                // Notify subscribers about cancellation
                this.orchestrator.subscriptionManager.notify('billing', {
                    type: MESSAGE_TYPES.SUBSCRIPTION_CANCELLED,
                    userId,
                    subscriptionId,
                    timestamp: Date.now()
                });

                this.log('CancelSubscription', `Subscription cancelled successfully for user ${userId}`);

                return { success: true };
            } else {
                throw new Error(data.error || 'Unknown error cancelling subscription');
            }
        } catch (error) {
            this.handleError(error, { operation: 'cancelSubscription', userId, subscriptionId });
            return { success: false, error: error.message };
        }
    }

    /**
     * Validate a coupon code
     */
    async validateCoupon(couponCode) {
        try {
            this.log('ValidateCoupon', `Validating coupon code: ${couponCode}`);

            // In production, this would call your backend API
            const apiUrl = typeof import.meta !== 'undefined' && import.meta.env && import.meta.env.VITE_API_URL
                ? import.meta.env.VITE_API_URL
                : window.location.origin;

            const response = await fetch(`${apiUrl}/api/billing/validate-coupon?code=${encodeURIComponent(couponCode)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('taytrom_auth_token') || ''}`,
                },
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Coupon validation failed');
            }

            const data = await response.json();

            this.log('ValidateCoupon', `Coupon validation result: ${data.valid ? 'Valid' : 'Invalid'}`);

            return data;
        } catch (error) {
            this.handleError(error, { operation: 'validateCoupon', couponCode });
            return { valid: false, error: error.message };
        }
    }

    /**
     * Handle billing webhooks from Stripe
     * This would be called by your backend when it receives webhooks
     */
    handleWebhookEvent(event) {
        try {
            this.log('HandleWebhook', `Processing webhook event: ${event.type}`);

            // Process different webhook event types
            switch (event.type) {
                case 'invoice.payment_succeeded':
                    this._handlePaymentSuccess(event.data.object);
                    break;
                case 'invoice.payment_failed':
                    this._handlePaymentFailure(event.data.object);
                    break;
                case 'customer.subscription.updated':
                    this._handleSubscriptionUpdate(event.data.object);
                    break;
                case 'customer.subscription.deleted':
                    this._handleSubscriptionCancelled(event.data.object);
                    break;
                default:
                    this.log('HandleWebhook', `Unhandled webhook event type: ${event.type}`);
            }

            return { success: true };
        } catch (error) {
            this.handleError(error, { operation: 'handleWebhookEvent', eventType: event.type });
            return { success: false, error: error.message };
        }
    }

    /**
     * Handle successful payment webhook
     * @private
     */
    _handlePaymentSuccess(invoice) {
        // Extract user ID from customer metadata
        const userId = invoice.customer.metadata?.userId;
        if (!userId) {
            this.log('Warning', 'Payment success webhook missing userId');
            return;
        }

        // Update local subscription state
        if (this._userSubscriptions.has(userId)) {
            const subscription = this._userSubscriptions.get(userId);
            subscription.status = 'active';
            subscription.currentPeriodEnd = new Date(invoice.lines.data[0].period.end * 1000);
            this._userSubscriptions.set(userId, subscription);
        }

        // Notify subscribers
        this.orchestrator.subscriptionManager.notify('billing', {
            type: MESSAGE_TYPES.PAYMENT_SUCCEEDED,
            userId,
            invoiceId: invoice.id,
            timestamp: Date.now()
        });

        this.log('PaymentSuccess', `Payment succeeded for user ${userId}`);
    }

    /**
     * Handle failed payment webhook
     * @private
     */
    _handlePaymentFailure(invoice) {
        // Extract user ID from customer metadata
        const userId = invoice.customer.metadata?.userId;
        if (!userId) {
            this.log('Warning', 'Payment failure webhook missing userId');
            return;
        }

        // Update local subscription state
        if (this._userSubscriptions.has(userId)) {
            const subscription = this._userSubscriptions.get(userId);
            subscription.status = 'past_due';
            this._userSubscriptions.set(userId, subscription);
        }

        // Notify subscribers
        this.orchestrator.subscriptionManager.notify('billing', {
            type: MESSAGE_TYPES.PAYMENT_FAILED,
            userId,
            invoiceId: invoice.id,
            timestamp: Date.now()
        });

        this.log('PaymentFailure', `Payment failed for user ${userId}`);
    }

    /**
     * Handle subscription update webhook
     * @private
     */
    _handleSubscriptionUpdate(subscription) {
        // Extract user ID from customer metadata
        const userId = subscription.customer.metadata?.userId;
        if (!userId) {
            this.log('Warning', 'Subscription update webhook missing userId');
            return;
        }

        // Add to the _handleSubscriptionUpdate method (around line 1348)
        this._userSubscriptions.set(userId, {
            id: subscription.id,
            status: subscription.status,
            plan: subscription.items.data[0].price.product,
            currentPeriodEnd: new Date(subscription.current_period_end * 1000),
            cancelAtPeriodEnd: subscription.cancel_at_period_end,
            trialEnd: subscription.trial_end ? new Date(subscription.trial_end * 1000) : null,
            onTrial: !!subscription.trial_end && subscription.trial_end > (Date.now() / 1000)
        });

        // In the _handleSubscriptionUpdate method (around line 1361)
        this.orchestrator.subscriptionManager.notify('billing', {
            type: MESSAGE_TYPES.SUBSCRIPTION_UPDATED,
            userId,
            subscriptionId: subscription.id,
            status: subscription.status,
            plan: 'Taytrom Writer', // Use the new plan name
            trialStatus: subscription.trial_end ? 'active' : 'inactive',
            timestamp: Date.now()
        });

        this.log('SubscriptionUpdate', `Subscription updated for user ${userId}, status: ${subscription.status}`);
    }

    /**
     * Handle subscription cancellation webhook
     * @private
     */
    _handleSubscriptionCancelled(subscription) {
        // Extract user ID from customer metadata
        const userId = subscription.customer.metadata?.userId;
        if (!userId) {
            this.log('Warning', 'Subscription cancelled webhook missing userId');
            return;
        }

        // Update local subscription state
        if (this._userSubscriptions.has(userId)) {
            const userSubscription = this._userSubscriptions.get(userId);
            userSubscription.status = 'cancelled';
            this._userSubscriptions.set(userId, userSubscription);
        }

        // Notify subscribers
        this.orchestrator.subscriptionManager.notify('billing', {
            type: MESSAGE_TYPES.SUBSCRIPTION_CANCELLED,
            userId,
            subscriptionId: subscription.id,
            timestamp: Date.now()
        });

        this.log('SubscriptionCancelled', `Subscription cancelled for user ${userId}`);
    }

    /**
     * Handle messages directed to billing manager
     */
    handleMessage(message) {
        switch (message.type) {
            case 'CREATE_SUBSCRIPTION':
                const { userId, paymentMethodId, plan, email, couponCode } = message.payload;
                return this.createSubscription(userId, paymentMethodId, plan, email, couponCode);

            case 'CANCEL_SUBSCRIPTION':
                return this.cancelSubscription(message.payload.userId, message.payload.subscriptionId);

            case 'VALIDATE_COUPON':
                return this.validateCoupon(message.payload.couponCode);

            case 'GET_USER_SUBSCRIPTION':
                return this.getUserSubscription(message.payload.userId);

            case 'WEBHOOK_EVENT':
                return this.handleWebhookEvent(message.payload.event);
        }

        return super.handleMessage(message);
    }

    /**
     * Clean up resources
     */
    cleanup() {
        this._userSubscriptions.clear();
        this._paymentMethods.clear();
        super.cleanup();
    }
}


/**
 * Context Provider for sharing state across components
 */
class ContextProvider {
    constructor(orchestrator) {
        this.orchestrator = orchestrator;
        this._contexts = {
            userProfile: null,
            currentExpedition: null,
            learningProgress: null,
            aiPersonalization: null,
            moduleContext: null,
            settings: null
        };
        this._listeners = new Map();

        logger('ContextProvider', 'Initialize', 'Context provider initialized');
    }

    /**
     * Set context value and notify listeners
     */
    setContext(key, value) {
        this._contexts[key] = value;
        this._notifyListeners(key);

        logger('ContextProvider', 'SetContext', `Updated "${key}" context`);
    }

    /**
     * Get context value
     */
    getContext(key) {
        return this._contexts[key];
    }

    /**
     * Register a listener for context changes
     */
    registerContextListener(key, listener) {
        if (!this._listeners.has(key)) {
            this._listeners.set(key, new Set());
        }

        const listenerSet = this._listeners.get(key);
        const listenerId = generateId('listener');

        listenerSet.add({
            id: listenerId,
            handler: listener
        });

        logger('ContextProvider', 'RegisterListener', `Registered listener for "${key}" context`);

        // Return unregister function
        return () => {
            if (this._listeners.has(key)) {
                const set = this._listeners.get(key);
                const listenerToRemove = Array.from(set).find(l => l.id === listenerId);
                if (listenerToRemove) {
                    set.delete(listenerToRemove);
                }
            }
        };
    }

    /**
     * Notify listeners of context changes
     */
    _notifyListeners(key) {
        if (this._listeners.has(key)) {
            const listeners = this._listeners.get(key);

            listeners.forEach(listener => {
                try {
                    listener.handler(this._contexts[key]);
                } catch (error) {
                    console.error(`Error in context listener for "${key}":`, error);
                }
            });

            logger('ContextProvider', 'NotifyListeners',
                `Notified ${listeners.size} listeners about "${key}" context change`);
        }
    }

    /**
     * Initialize all contexts from a user's data
     */
    async initializeUserContexts(userId) {
        try {
            // Get user profile
            const userProfile = this.orchestrator.authManager?.getCurrentUser();
            if (userProfile) {
                this.setContext('userProfile', userProfile);
            }

            // Get user progress
            if (this.orchestrator.progressManager) {
                const progressData = this.orchestrator.progressManager.getUserProgress(userId);
                this.setContext('learningProgress', progressData);

                // Get current expedition
                const expeditionData = await this.orchestrator.progressManager.getUserCurrentExpedition(userId);
                if (expeditionData) {
                    this.setContext('currentExpedition', expeditionData);
                }
            }

            // Get personalization settings
            if (this.orchestrator.personalizationBridge) {
                const personalization = this.orchestrator.personalizationBridge.getUserPersonalizationProfile(userId);
                this.setContext('aiPersonalization', personalization);
            }

            // Get user settings
            if (this.orchestrator.settingsManager) {
                const settings = this.orchestrator.settingsManager.getUserPreferences(userId);
                this.setContext('settings', settings);
            }

            logger('ContextProvider', 'InitializeContexts',
                `Initialized contexts for user ${userId}`);

            return true;
        } catch (error) {
            console.error('Error initializing contexts:', error);
            return false;
        }
    }

    /**
     * Clean up resources
     */
    cleanup() {
        this._listeners.clear();
        this._contexts = {
            userProfile: null,
            currentExpedition: null,
            learningProgress: null,
            aiPersonalization: null,
            moduleContext: null,
            settings: null
        };

        logger('ContextProvider', 'Cleanup', 'Context provider resources released');
    }
}

// Define authentication service methods
const authService = {
    // Register a new user with improved error handling
    register: async (userData) => {
        try {
            const response = await fetch(`${API_URL}/api/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
                credentials: 'include'
            });

            const data = await response.json();

            if (!response.ok) {
                return {
                    success: false,
                    error: data.error || 'Registration failed'
                };
            }

            // Save token to local storage
            localStorage.setItem('taytrom_auth_token', data.token);
            localStorage.setItem('taytrom_user_data', JSON.stringify(data.user));

            return {
                success: true,
                user: data.user,
                token: data.token
            };
        } catch (error) {
            console.error('[Auth] Registration error:', error);
            return {
                success: false,
                error: error.message || 'Network error during registration'
            };
        }
    },

    // Login user
    login: async (email, password, rememberMe = false) => {
        try {
            const response = await fetch(`${API_URL}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
                credentials: 'include'
            });

            const data = await response.json();

            if (!response.ok) {
                return {
                    success: false,
                    error: data.error || 'Login failed'
                };
            }

            // Save auth data
            localStorage.setItem('taytrom_auth_token', data.token);
            localStorage.setItem('taytrom_user_data', JSON.stringify(data.user));
            localStorage.setItem('taytrom_remember_me', String(rememberMe));

            return {
                success: true,
                user: data.user,
                token: data.token
            };
        } catch (error) {
            console.error('Login error:', error);
            return {
                success: false,
                error: error.message || 'Network error during login'
            };
        }
    },

    // Logout user
    logout: async () => {
        try {
            // Clear local storage
            localStorage.removeItem('taytrom_auth_token');
            localStorage.removeItem('taytrom_user_data');
            localStorage.removeItem('taytrom_remember_me');

            return { success: true };
        } catch (error) {
            console.error('Logout error:', error);
            return {
                success: false,
                error: error.message || 'Error during logout'
            };
        }
    },

    // Get current user
    getCurrentUser: async () => {
        try {
            const token = localStorage.getItem('taytrom_auth_token');

            if (!token) {
                return {
                    success: false,
                    error: 'No authentication token found'
                };
            }

            const response = await fetch(`${API_URL}/api/auth/me`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            const data = await response.json();

            if (!response.ok) {
                // Clear invalid auth data
                if (response.status === 401) {
                    localStorage.removeItem('taytrom_auth_token');
                    localStorage.removeItem('taytrom_user_data');
                }

                return {
                    success: false,
                    error: data.error || 'Failed to get user data'
                };
            }

            // Update stored user data
            localStorage.setItem('taytrom_user_data', JSON.stringify(data.user));

            return {
                success: true,
                user: data.user
            };
        } catch (error) {
            console.error('Get user error:', error);
            return {
                success: false,
                error: error.message || 'Network error while fetching user data'
            };
        }
    },

    // Update user profile
    updateProfile: async (userData) => {
        try {
            const token = localStorage.getItem('taytrom_auth_token');

            if (!token) {
                return {
                    success: false,
                    error: 'Not authenticated'
                };
            }

            const response = await fetch(`${API_URL}/api/auth/updateprofile`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
                credentials: 'include'
            });

            const data = await response.json();

            if (!response.ok) {
                return {
                    success: false,
                    error: data.error || 'Failed to update profile'
                };
            }

            // Update stored user data
            localStorage.setItem('taytrom_user_data', JSON.stringify(data.user));

            return {
                success: true,
                user: data.user
            };
        } catch (error) {
            console.error('Update profile error:', error);
            return {
                success: false,
                error: error.message || 'Network error during profile update'
            };
        }
    },

    // Request password reset
    forgotPassword: async (email) => {
        try {
            const response = await fetch(`${API_URL}/api/auth/forgotpassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
                credentials: 'include'
            });

            const data = await response.json();

            if (!response.ok) {
                return {
                    success: false,
                    error: data.error || 'Failed to send reset instructions'
                };
            }

            return {
                success: true,
                message: data.message || 'Password reset instructions sent'
            };
        } catch (error) {
            console.error('Forgot password error:', error);
            return {
                success: false,
                error: error.message || 'Network error during password reset request'
            };
        }
    },

    // Check if user is authenticated based on local storage
    checkAuthStatus: () => {
        const token = localStorage.getItem('taytrom_auth_token');
        const userData = localStorage.getItem('taytrom_user_data');

        if (!token || !userData) {
            return {
                isAuthenticated: false,
                user: null
            };
        }

        try {
            const user = JSON.parse(userData);
            return {
                isAuthenticated: true,
                user
            };
        } catch (error) {
            console.error('Error parsing user data:', error);
            return {
                isAuthenticated: false,
                user: null
            };
        }
    }
};

// Create the auth context
export const AuthContext = createContext({
    isAuthenticated: false,
    user: null,
    login: async () => {},
    register: async () => {},
    logout: async () => {},
    forgotPassword: async () => {},
    updateProfile: async () => {},
    loading: false
});

// Custom hook to use the auth context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        console.warn('useAuth must be used within an AuthProvider');
    }
    return context;
};

// ProtectedRoute component for route protection
export const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, loading } = useAuth();
    const location = useLocation();

    // Check if we're in development mode
    const isDevelopment = import.meta.env.NODE_ENV === 'development' ||
        window.location.hostname === 'localhost';

    // Save the current path when the component mounts if not authenticated
    useEffect(() => {
        if (!loading && !isAuthenticated && !isDevelopment) {
            sessionStorage.setItem('redirectPath', location.pathname);
        }
    }, [isAuthenticated, loading, location, isDevelopment]);

    // Show loading state while auth state is being determined
    if (loading && !isDevelopment) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-neutral-900">
                <div className="text-teal-500 text-xl">
                    Checking authentication...
                </div>
            </div>
        );
    }

    // If in development mode, bypass authentication
    if (isDevelopment) {
        return children;
    }

    // If not authenticated and not in development, redirect to signin page
    if (!isAuthenticated) {
        return <Navigate to="/signin" replace />;
    }

    // If authenticated, render the protected component
    return children;
};

// Auth provider component
export const AuthProvider = ({ children, orchestrator }) => {
    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        user: null,
        loading: true
    });

    const navigate = useNavigate();

    // Initialize auth state
    useEffect(() => {
        if (!orchestrator || !orchestrator.authManager) {
            console.warn('AuthManager not available in orchestrator');
            setAuthState(prevState => ({ ...prevState, loading: false }));
            return;
        }

        // Subscribe to auth state changes
        const unsubscribe = orchestrator.authManager.onAuthStateChanged(state => {
            setAuthState({
                isAuthenticated: state.isAuthenticated,
                user: state.user,
                loading: false
            });
        });

        // Clean up subscription
        return unsubscribe;
    }, [orchestrator]);

    // Login handler with redirect back to attempted route
    const login = useCallback(async (email, password, rememberMe) => {
        if (!orchestrator || !orchestrator.authManager) {
            return { success: false, error: 'Auth system not available' };
        }

        setAuthState(prevState => ({ ...prevState, loading: true }));
        const result = await orchestrator.authManager.login(email, password, rememberMe);
        setAuthState(prevState => ({ ...prevState, loading: false }));

        if (result.success) {
            // Check if we have a saved path from a redirect
            const savedPath = sessionStorage.getItem('redirectPath');
            if (savedPath) {
                sessionStorage.removeItem('redirectPath');
                navigate(savedPath);
            } else {
                navigate('/');
            }
        }

        return result;
    }, [orchestrator, navigate]);

    // Register handler
    const register = useCallback(async (userData) => {
        if (!orchestrator || !orchestrator.authManager) {
            return { success: false, error: 'Auth system not available' };
        }

        setAuthState(prevState => ({ ...prevState, loading: true }));
        const result = await orchestrator.authManager.register(userData);
        setAuthState(prevState => ({ ...prevState, loading: false }));

        if (result.success) {
            // After successful registration, redirect to login
            navigate('/signin');
        }

        return result;
    }, [orchestrator, navigate]);

    // Logout handler
    const logout = useCallback(async () => {
        if (!orchestrator || !orchestrator.authManager) {
            return { success: false, error: 'Auth system not available' };
        }

        setAuthState(prevState => ({ ...prevState, loading: true }));
        const result = await orchestrator.authManager.logout();
        setAuthState(prevState => ({ ...prevState, loading: false }));

        if (result.success) {
            navigate('/');
        }

        return result;
    }, [orchestrator, navigate]);

    // Forgot password handler
    const forgotPassword = useCallback(async (email) => {
        if (!orchestrator || !orchestrator.authManager) {
            return { success: false, error: 'Auth system not available' };
        }

        setAuthState(prevState => ({ ...prevState, loading: true }));
        const result = await orchestrator.authManager.forgotPassword(email);
        setAuthState(prevState => ({ ...prevState, loading: false }));

        return result;
    }, [orchestrator]);

    // Update profile handler
    const updateProfile = useCallback(async (userData) => {
        if (!orchestrator || !orchestrator.authManager) {
            return { success: false, error: 'Auth system not available' };
        }

        if (!authState.isAuthenticated) {
            return { success: false, error: 'User not authenticated' };
        }

        setAuthState(prevState => ({ ...prevState, loading: true }));
        const result = await orchestrator.authManager.updateProfile(userData);
        setAuthState(prevState => ({ ...prevState, loading: false }));

        return result;
    }, [orchestrator, authState.isAuthenticated]);

    // Create the context value
    const contextValue = {
        isAuthenticated: authState.isAuthenticated,
        user: authState.user,
        login,
        register,
        logout,
        forgotPassword,
        updateProfile,
        loading: authState.loading
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

/**
 * Main FrontAutocrat class to coordinate all managers
 */
class FrontAutocrat {
    constructor(config = {}) {
        this.systemState = SYSTEM_STATES.CREATED;
        this.config = config;
        this.environment = {
            mode: config.mode || 'production',
            startTime: Date.now(),
            debug: config.debug || false
        };

        // Initialize managers
        this.subscriptionManager = new SubscriptionManager(this);
        this.authManager = new AuthManager(this);

        // Initialize AIContextManager
        this.aiIntelligenceManager = new AIContextManager(this);

        // Initialize component registry
        this._registeredComponents = new Map();
        this._messageHandlers = {};

        // Initialize error tracking
        this._errors = [];
        this._errorSubscribers = new Set();

        // Initialize streams tracking
        this._activeStreams = new Map();

        logger('FrontAutocrat', 'Initialize', 'System created successfully');
        this.systemState = SYSTEM_STATES.INITIALIZING;

        this.billingManager = new BillingManager(this);

    }


    /**
     * Modified initialize method to initialize AIContextManager
     */
    async initialize() {
        try {
            logger('FrontAutocrat', 'Initialize', 'Starting initialization');

            // Check backend connectivity
            try {
                const response = await fetch(`${API_URL}/`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                    },
                    mode: 'cors',
                });

                if (!response.ok) {
                    throw new Error(`Server responded with error: ${response.status}`);
                }

                // Try to get JSON response (for API servers)
                try {
                    const data = await response.json();
                    logger('FrontAutocrat', 'Initialize', 'Backend connection verified',
                        JSON.stringify(data));
                } catch (e) {
                    // Not a JSON response, but connection is established
                    logger('FrontAutocrat', 'Initialize', 'Backend connection verified (non-JSON response)');
                }
            } catch (error) {
                logger('FrontAutocrat', 'Error', `Backend connectivity check failed: ${error.message}`);
                // We'll continue initialization, but log the error
            }

            // Initialize additional managers
            this.progressManager = new ProgressManager(this);
            this.settingsManager = new SettingsManager(this);
            this.expeditionManager = new ExpeditionManager(this);
            this.personalizationBridge = new PersonalizationBridge(this);

            // Initialize AI Intelligence Manager
            if (this.aiIntelligenceManager) {
                const aiInitialized = await this.aiIntelligenceManager.initialize() || true;
                if (!aiInitialized) {
                    logger('FrontAutocrat', 'Warning', 'AI Intelligence System initialization failed');
                } else {
                    logger('FrontAutocrat', 'Initialize', 'AI Intelligence System initialized successfully');
                }
            } else {
                logger('FrontAutocrat', 'Warning', 'AIContextManager not available');

                // Create AIContextManager if it doesn't exist
                this.aiIntelligenceManager = new AIContextManager(this);
                await this.aiIntelligenceManager.initialize();
                logger('FrontAutocrat', 'Initialize', 'Created and initialized AIContextManager');
            }

            // Initialize context provider
            this.contextProvider = new ContextProvider(this);

            // Register default message handlers
            this._registerDefaultMessageHandlers();

            this.systemState = SYSTEM_STATES.RUNNING;
            logger('FrontAutocrat', 'Initialize', 'System initialized successfully');

            // Initialize contexts if user is authenticated
            if (this.authManager.isAuthenticated()) {
                const currentUser = this.authManager.getCurrentUser();
                if (currentUser && currentUser.id) {
                    this.contextProvider.initializeUserContexts(currentUser.id);
                }
            }

            return true;
        } catch (error) {
            this.systemState = SYSTEM_STATES.ERROR;
            this.handleError(error, { operation: 'initialize' });
            return false;
        }
    }

    // Add this method to the FrontAutocrat class
    /**
     * Check user subscriptions and enable premium features if subscribed
     */
    async getUserSubscriptions(userId) {
        try {
            if (!this.billingManager) {
                return { success: false, error: 'Billing manager not initialized' };
            }

            // Get user subscription
            const result = this.billingManager.getUserSubscription(userId);
            return result;
        } catch (error) {
            this.handleError(error, { operation: 'getUserSubscriptions', userId });
            return { success: false, error: error.message };
        }
    }

    /**
     * Start subscription for user
     */
    // Update the startSubscription method in FrontAutocrat (around line 2017)
    async startSubscription(userId, plan = 'Taytrom Writer') { // Default to new plan name
        try {
            if (!this.billingManager) {
                return { success: false, error: 'Billing manager not initialized' };
            }

            // Forward to billing page with new plan parameters
            return {
                success: true,
                message: 'Subscription workflow initiated for Taytrom Writer platform',
                redirectUrl: '/#/billing',
                plan: 'Taytrom Writer',
                trialDays: 7
            };
        } catch (error) {
            this.handleError(error, { operation: 'startSubscription', userId, plan });
            return { success: false, error: error.message };
        }
    }

    /**
     * Register default message handlers for different message types
     */
    _registerDefaultMessageHandlers() {
        this._messageHandlers = {
            [MESSAGE_TYPES.PROFILE_UPDATE]: this._handleProfileUpdate.bind(this),
            [MESSAGE_TYPES.PROGRESS_SYNC]: this._handleProgressSync.bind(this),
            [MESSAGE_TYPES.SETTINGS_CHANGE]: this._handleSettingsChange.bind(this),
            [MESSAGE_TYPES.MODULE_COMPLETION]: this._handleModuleCompletion.bind(this),
            [MESSAGE_TYPES.AI_PERSONALIZATION_UPDATE]: this._handlePersonalizationUpdate.bind(this),
            [MESSAGE_TYPES.LEARNING_CONTEXT_CHANGE]: this._handleContextChange.bind(this),
            [MESSAGE_TYPES.NOTIFICATION]: this._handleNotification.bind(this)
        };
    }

    /**
     * Handle profile update messages
     */
    _handleProfileUpdate(message) {
        const user = message.user || message.payload?.user;

        if (user) {
            // Update context
            this.contextProvider.setContext('userProfile', user);

            logger('FrontAutocrat', 'ProfileUpdate', 'Updated user profile context');
        }

        return { success: true, handled: true };
    }

    /**
     * Handle progress sync messages
     */
    _handleProgressSync(message) {
        const userId = message.userId || message.payload?.userId;
        const success = message.success || message.payload?.success;

        if (userId && success) {
            // Refresh progress context
            if (this.progressManager) {
                const progressData = this.progressManager.getUserProgress(userId);
                if (progressData) {
                    this.contextProvider.setContext('learningProgress', progressData);
                }
            }

            logger('FrontAutocrat', 'ProgressSync', 'Synced progress data');
        }

        return { success: true, handled: true };
    }

    /**
     * Handle settings change messages
     */
    _handleSettingsChange(message) {
        const settings = message.settings || message.payload?.settings;

        if (settings) {
            // Update context
            this.contextProvider.setContext('settings', settings);

            // Apply theme if present
            if (settings.theme && this.settingsManager) {
                this.settingsManager.applyTheme(settings.theme);
            }

            logger('FrontAutocrat', 'SettingsChange', 'Updated settings context');
        }

        return { success: true, handled: true };
    }

    /**
     * Handle module completion messages
     */
    _handleModuleCompletion(message) {
        const userId = message.userId || message.payload?.userId;

        if (userId && this.progressManager) {
            // Refresh progress context
            const progressData = this.progressManager.getUserProgress(userId);
            if (progressData) {
                this.contextProvider.setContext('learningProgress', progressData);
            }

            logger('FrontAutocrat', 'ModuleCompletion', 'Updated module completion');
        }

        return { success: true, handled: true };
    }


    /**
     * FrontAutocrat API Communication Methods
     *
     * These methods handle the communications between the front-end and back-end,
     * ensuring that all AI/API requests flow through the FrontAutocrat
     * to the BackOrchestrator for consistent architecture.
     */

    /**
     * Sends a message to the BackOrchestrator using the API
     * This method is the main entry point for all communication with the backend
     *
     * @param {object} requestData - The data to send to the backend
     * @param {string} endpoint - The API endpoint to use (default: '/api/claude')
     * @param {object} options - Additional options for the request
     * @returns {object} - The response from the backend
     */
    async sendToBackOrchestrator(requestData, endpoint = '/api/claude', options = {}) {
        try {
            // Default API URL configuration from environment or window location
            const apiUrl = this._getApiUrl();
            const fullEndpoint = `${apiUrl}${endpoint}`;

            // Get authentication token
            const authToken = localStorage.getItem('taytrom_auth_token') || '';

            // Log the request if debug mode is enabled
            this._logRequest(requestData, fullEndpoint);

            // Make the API request
            const response = await fetch(fullEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify(requestData),
                credentials: 'include',
                mode: 'cors',
                ...options
            });

            // Handle non-OK response
            if (!response.ok) {
                // Try to get error details from response
                let errorDetails = '';
                try {
                    const errorData = await response.json();
                    errorDetails = errorData.error || errorData.message || '';
                } catch (e) {
                    errorDetails = await response.text();
                }

                throw new Error(`API error (${response.status}): ${errorDetails}`);
            }

            // Parse response
            const data = await response.json();

            // Log the response if debug mode is enabled
            this._logResponse(data);

            return data;
        } catch (error) {
            this.handleError(error, {
                operation: 'sendToBackOrchestrator',
                endpoint
            });

            // Rethrow to allow caller to handle
            throw error;
        }
    }

    /**
     * Implementation of sendTIESEnvelopeToBackend
     */
    async sendTIESEnvelopeToBackend(tiesEnvelope) {
        try {
            // Default API URL configuration from environment or window location
            const apiUrl = typeof import.meta !== 'undefined' && import.meta.env && import.meta.env.VITE_API_URL
                ? import.meta.env.VITE_API_URL
                : window.location.origin;

            const endpoint = `${apiUrl}/api/ties`;

            console.log("[FrontAutocrat] sendTIESEnvelopeToBackend called:",
                "target:", tiesEnvelope.raw.required.target,
                "messageType:", tiesEnvelope.raw.required.enum,
                "endpoint:", endpoint);

            // Get authentication token
            const authToken = localStorage.getItem('taytrom_auth_token') || '';

            // Make the API request
            console.log("[FrontAutocrat] Sending fetch request to:", endpoint);
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify(tiesEnvelope),
                credentials: 'include',
                mode: 'cors'
            });

            // Handle non-OK response
            if (!response.ok) {
                console.error("[FrontAutocrat] API error:", response.status);

                // Try to get error details from response
                let errorDetails = '';
                try {
                    const errorData = await response.json();
                    errorDetails = errorData.error || errorData.message || '';
                    console.error("[FrontAutocrat] Error details:", errorDetails);
                } catch (e) {
                    errorDetails = await response.text();
                    console.error("[FrontAutocrat] Error text:", errorDetails);
                }

                throw new Error(`API error (${response.status}): ${errorDetails}`);
            }

            // Parse response
            const data = await response.json();
            console.log("[FrontAutocrat] Received response data type:",
                data.raw?.required?.enum || 'unknown format');

            return data;
        } catch (error) {
            console.error("[FrontAutocrat] Error in sendTIESEnvelopeToBackend:", error);
            this.handleError(error, {
                operation: 'sendTIESEnvelopeToBackend',
                envelopeType: tiesEnvelope?.raw?.required?.enum
            });

            // Return error response in TIES format
            return this.createMessageEnvelope(
                'ERROR_RESPONSE',
                {
                    success: false,
                    error: error.message
                },
                {
                    target: tiesEnvelope?.raw?.required?.source || 'unknown',
                    source: 'front_orchestrator',
                    error: {
                        code: 'BACKEND_ERROR',
                        message: error.message
                    }
                }
            );
        }
    }



    /**
     * Helper method to get the API URL
     * @private
     */
    _getApiUrl() {
        // For local development pointing to real backend
        if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
            // Use full URL if in development mode
            return 'http://localhost:8080'; // Adjust port if your backend runs on a different port
        }

        // Try to get from environment variables (for Vite, etc.)
        if (typeof import.meta !== 'undefined' && import.meta.env) {
            if (import.meta.env.VITE_API_URL) {
                return import.meta.env.VITE_API_URL;
            }
        }

        // Default to current origin
        return window.location.origin;
    }

    /**
     * Logs API requests in debug mode
     * @private
     */
    _logRequest(data, endpoint) {
        if (this.environment.mode === 'development' || this.environment.debug) {
            console.log(`[FrontOrchestrator] API Request to ${endpoint}:`,
                data.length > 1000 ? `${JSON.stringify(data).substring(0, 1000)}...` : data);
        }
    }

    /**
     * Logs API responses in debug mode
     * @private
     */
    _logResponse(data) {
        if (this.environment.mode === 'development' || this.environment.debug) {
            console.log(`[FrontOrchestrator] API Response:`,
                data.length > 1000 ? `${JSON.stringify(data).substring(0, 1000)}...` : data);
        }
    }


    /**
     * Handle personalization update messages
     */
    _handlePersonalizationUpdate(message) {
        const personalization = message.personalization || message.payload?.personalization;

        if (personalization) {
            // Update context
            this.contextProvider.setContext('aiPersonalization', personalization);

            logger('FrontAutocrat', 'PersonalizationUpdate', 'Updated AI personalization context');
        }

        return { success: true, handled: true };
    }

    /**
     * Handle context change messages
     */
    _handleContextChange(message) {
        const expeditionId = message.expeditionId || message.payload?.expeditionId;
        const regionId = message.regionId || message.payload?.regionId;

        if (expeditionId && regionId) {
            // Refresh expedition context
            this.expeditionManager.loadExpeditionData(expeditionId).then(result => {
                if (result.success) {
                    this.contextProvider.setContext('currentExpedition', result.expedition);

                    logger('FrontAutocrat', 'ContextChange',
                        `Updated expedition context: ${expeditionId}, region: ${regionId}`);
                }
            });
        }

        return { success: true, handled: true };
    }

    /**
     * Handle notification messages
     */
    _handleNotification(message) {
        const notification = message.payload;

        if (notification) {
            // Broadcast notification to all subscribers
            this.subscriptionManager.notify('global', {
                type: MESSAGE_TYPES.NOTIFICATION,
                notification,
                timestamp: Date.now()
            });

            logger('FrontAutocrat', 'Notification',
                `Broadcast notification: ${notification.type}`);
        }

        return { success: true, handled: true };
    }

    /**
     * Register a component with the orchestrator
     */
    registerComponent(componentId, component) {
        this._registeredComponents.set(componentId, {
            component,
            registeredAt: Date.now(),
            interactions: []
        });

        logger('FrontAutocrat', 'RegisterComponent',
            `Component ${componentId} registered successfully`);

        return {
            unregister: () => {
                this._registeredComponents.delete(componentId);
                logger('FrontAutocrat', 'UnregisterComponent',
                    `Component ${componentId} unregistered`);
            }
        };
    }

    /**
     * Get a registered component by ID
     */
    getRegisteredComponent(componentId) {
        return this._registeredComponents.get(componentId)?.component || null;
    }

    /**
     * Sync user data between components and with the backend
     */
    async syncUserData(userId, dataType = 'all') {
        try {
            logger('FrontAutocrat', 'SyncUserData',
                `Syncing ${dataType} data for user ${userId}`);

            // Prepare notification for all subscribers
            const syncStartNotification = {
                type: MESSAGE_TYPES.SYNC_START,
                dataType,
                userId,
                timestamp: Date.now()
            };

            this.subscriptionManager.notify('state', syncStartNotification);

            // Sync different types of data
            let result = { success: true, syncedData: {} };

            if (dataType === 'all' || dataType === 'profile') {
                const profileData = await this.authManager.getCurrentUser();
                if (profileData.success) {
                    result.syncedData.profile = profileData.user;
                    // Update context
                    this.contextProvider.setContext('userProfile', profileData.user);
                }
            }

            if (dataType === 'all' || dataType === 'progress') {
                const progressData = this.progressManager?.getUserProgress(userId);
                result.syncedData.progress = progressData;
                // Update context
                this.contextProvider.setContext('learningProgress', progressData);

                // If we have a progress manager with server sync, trigger it
                if (this.progressManager && typeof this.progressManager.syncAllProgress === 'function') {
                    await this.progressManager.syncAllProgress();
                }
            }

            if (dataType === 'all' || dataType === 'expedition') {
                const expeditionData = await this.expeditionManager?.getUserCurrentExpedition(userId);
                result.syncedData.expedition = expeditionData;
                // Update context
                this.contextProvider.setContext('currentExpedition', expeditionData);
            }

            if (dataType === 'all' || dataType === 'settings') {
                const settingsData = this.settingsManager?.getUserPreferences(userId);
                result.syncedData.settings = settingsData;
                // Update context
                this.contextProvider.setContext('settings', settingsData);
            }

            // Notify about sync completion
            const syncCompleteNotification = {
                type: MESSAGE_TYPES.SYNC_COMPLETE,
                dataType,
                userId,
                syncedData: result.syncedData,
                timestamp: Date.now()
            };

            this.subscriptionManager.notify('state', syncCompleteNotification);

            return result;
        } catch (error) {
            this.handleError(error, { operation: 'syncUserData', userId, dataType });
            return { success: false, error: error.message };
        }
    }

    /**
     * Creates a standardized TACS TIES message envelope
     * TIES = TARGET, ID, ENUM, SOURCE
     *
     * @param {string} type - Message type/enum
     * @param {*} content - Message payload
     * @param {object} metadata - Additional message metadata
     * @returns {object} - Properly formatted TIES message envelope
     */
    createMessageEnvelope(type, content, metadata = {}) {
        // Generate a unique message ID if not provided
        const messageId = metadata.messageId || `${type.toLowerCase()}_${generateId('msg')}`;
        const timestamp = Date.now();

        return {
            raw: {
                required: {
                    target: metadata.target || "front_orchestrator",
                    id: messageId,
                    enum: type,
                    source: metadata.source || "front_orchestrator"
                },
                ad_hoc: {
                    message: {
                        content,
                        metadata: {
                            required: {
                                timestamp,
                                messageType: type,
                                messageId,
                                role: metadata.role || 'system',
                                status: metadata.status || 'initialized',
                                conversationId: metadata.conversationId || generateId('conv'),
                                source: metadata.source || 'front_orchestrator'
                            },
                            ad_hoc: {
                                processing: {
                                    processedAt: timestamp,
                                    processingTime: metadata.processingTime || 0,
                                    updatedAt: timestamp
                                },
                                state: {
                                    isHealthy: metadata.isHealthy ?? true,
                                    isInitialized: metadata.isInitialized ?? true,
                                    preserveContent: true,
                                    isSystemMessage: metadata.role === 'system'
                                },
                                environment: {
                                    isDark: this.contextProvider?.getContext('settings')?.theme === 'dark',
                                    locale: navigator.language || 'en-US',
                                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    clientInfo: {
                                        userAgent: navigator.userAgent,
                                        platform: navigator.platform,
                                        screenSize: {
                                            width: window.innerWidth,
                                            height: window.innerHeight
                                        }
                                    }
                                },
                                error: metadata.error && {
                                    code: metadata.error.code,
                                    message: metadata.error.message,
                                    details: metadata.error.details,
                                    timestamp: metadata.error.timestamp || timestamp
                                },
                                // Add module context if provided
                                ...(metadata.moduleContext && { moduleContext: metadata.moduleContext }),
                                // Add any other custom metadata
                                ...metadata.custom
                            }
                        }
                    }
                }
            },
            callbacks: metadata.callbacks || {}
        };
    }

    /**
     * Routes a message using the TIES envelope pattern
     *
     * @param {object} message - TIES formatted message envelope
     * @returns {object} - Response in TIES envelope format
     */
    async routeTIESMessage(message) {
        try {
            if (!message?.raw?.required) {
                throw new Error('Invalid TIES envelope structure');
            }

            const targetEntity = message.raw.required.target;
            const messageType = message.raw.required.enum;

            this.log('RouteTIESMessage', `Routing ${messageType} message to ${targetEntity}`,
                `From: ${message.raw.required.source}, ID: ${message.raw.required.id}`);

            // Route to manager based on target
            const managerMap = {
                'auth_manager': this.authManager,
                'claude_manager': this.aiIntelligenceManager, // Use AIContextManager for claude_manager targets
                'ai_intelligence_manager': this.aiIntelligenceManager,
                'progress_manager': this.progressManager,
                'settings_manager': this.settingsManager,
                'expedition_manager': this.expeditionManager,
                'personalization_bridge': this.personalizationBridge
            };


            // Look for a specific manager target
            const manager = managerMap[targetEntity];
            if (manager) {
                if (typeof manager.handleTIESMessage === 'function') {
                    return await manager.handleTIESMessage(message);
                } else {
                    // Try to adapt to standard message format if TIES handler not available
                    const result = manager.handleMessage({
                        type: messageType,
                        from: message.raw.required.source,
                        to: targetEntity,
                        payload: message.raw.ad_hoc.message.content
                    });

                    // Convert response back to TIES format
                    return this.createMessageEnvelope(
                        `${messageType}_RESPONSE`,
                        result,
                        {
                            target: message.raw.required.source,
                            source: targetEntity,
                            conversationId: message.raw.ad_hoc.message.metadata.required.conversationId
                        }
                    );
                }
            }

            // If target is the orchestrator itself
            if (targetEntity === 'front_orchestrator') {
                // Handle orchestrator-level messages
                const orchestratorHandlers = {
                    'SYSTEM_INFO_REQUEST': this._handleSystemInfoRequest.bind(this),
                    'SYNC_DATA': this.syncUserData.bind(this),
                    'ERROR_REPORT': this.handleError.bind(this)
                    // Add more orchestrator-level message handlers as needed
                };

                const handler = orchestratorHandlers[messageType];
                if (handler) {
                    const result = await handler(message.raw.ad_hoc.message.content);

                    return this.createMessageEnvelope(
                        `${messageType}_RESPONSE`,
                        result,
                        {
                            target: message.raw.required.source,
                            source: 'front_orchestrator',
                            conversationId: message.raw.ad_hoc.message.metadata.required.conversationId
                        }
                    );
                }
            }

            // If we reached here, we couldn't route the message
            throw new Error(`No handler found for message type ${messageType} to target ${targetEntity}`);

        } catch (error) {
            this.handleError(error, {
                operation: 'routeTIESMessage',
                messageType: message?.raw?.required?.enum,
                messageSource: message?.raw?.required?.source
            });

            // Return error response in TIES format
            return this.createMessageEnvelope(
                'ERROR_RESPONSE',
                {
                    success: false,
                    error: error.message,
                    originalMessage: message?.raw?.required
                },
                {
                    target: message?.raw?.required?.source || 'unknown',
                    source: 'front_orchestrator',
                    error: {
                        code: 'ROUTING_ERROR',
                        message: error.message,
                        details: error.stack
                    },
                    conversationId: message?.raw?.ad_hoc?.message?.metadata?.required?.conversationId
                }
            );
        }
    }

    /**
     * Example handler for system info requests
     * @private
     */
    _handleSystemInfoRequest(content) {
        return {
            systemState: this.systemState,
            uptime: Date.now() - this.environment.startTime,
            subscriptions: this.subscriptionManager.getSubscriptionStats(),
            components: this._registeredComponents.size,
            managers: {
                auth: !!this.authManager,
                claude: !!this.claudeManager,
                progress: !!this.progressManager,
                settings: !!this.settingsManager,
                expedition: !!this.expeditionManager,
                personalization: !!this.personalizationBridge
            },
            contextData: this.contextProvider ? Object.keys(this.contextProvider._contexts) : []
        };
    }

    /**
     * Updates to the sendMessageToClaudeWithTIES method
     */
    // In sendMessageToClaudeWithTIES method:
    async sendMessageToClaudeWithTIES(message, options = {}) {
        try {
            const conversationId = options.conversationId || this._generateId('conv');
            const requestId = this._generateId('req');

            console.log("[FrontAutocrat] sendMessageToClaudeWithTIES called:",
                "message length:", typeof message === 'string' ? message.length : 'non-string',
                "conversationId:", conversationId,
                "options:", Object.keys(options));

            // Create TIES message envelope for AI Intelligence Manager
            const messageEnvelope = this.createMessageEnvelope(
                'CLAUDE_MESSAGE_REQUEST',
                {
                    message,
                    options: {
                        thinkingEnabled: options.thinkingEnabled || false,
                        thinkingBudget: options.thinkingBudget || 2048,
                        moduleContext: options.moduleContext || {},
                        includeThinkingInResponse: options.includeThinkingInResponse
                    }
                },
                {
                    target: 'ai_intelligence_manager',
                    source: 'front_orchestrator',
                    role: 'user',
                    conversationId,
                    moduleContext: options.moduleContext,
                    custom: {
                        requestId,
                        personalizationParams: options.personalizationParams
                    }
                }
            );

            console.log("[FrontAutocrat] Created TIES envelope:",
                "target:", messageEnvelope.raw.required.target,
                "enum:", messageEnvelope.raw.required.enum);

            // Use API to send the request to the backend via TIES
            let response;
            try {
                console.log("[FrontAutocrat] Attempting to send TIES envelope to backend");
                response = await this.sendTIESEnvelopeToBackend(messageEnvelope);
                console.log("[FrontAutocrat] Received response from backend:",
                    response ? (response.raw?.required?.enum || 'unknown format') : 'no response');
            } catch (error) {
                // If backend communication fails, try handling locally with AIContextManager
                console.warn("[FrontAutocrat] Backend communication failed, falling back to local AIContextManager:", error.message);

                if (!this.aiIntelligenceManager) {
                    this.aiIntelligenceManager = new AIContextManager(this);
                    await this.aiIntelligenceManager.initialize();
                }

                // Process locally using AIContextManager
                console.log("[FrontAutocrat] Processing locally with AIContextManager");
                const result = await this.aiIntelligenceManager.handleTIESMessage(messageEnvelope);
                response = result;
            }

            // Check for successful response
            if (response.raw?.required?.enum === 'CLAUDE_MESSAGE_RESPONSE' &&
                response.raw?.ad_hoc?.message?.content?.success) {

                console.log("[FrontAutocrat] Successfully processed message, notifying subscribers");

                // Notify subscribers
                this.subscriptionManager.notify('ai', {
                    type: MESSAGE_TYPES.AI_RESPONSE,
                    requestId,
                    conversationId,
                    message: response.raw.ad_hoc.message.content.response,
                    thinking: response.raw.ad_hoc.message.content.thinking,
                    timestamp: response.raw.ad_hoc.message.metadata.required.timestamp
                });

                return {
                    success: true,
                    requestId,
                    conversationId,
                    response: response.raw.ad_hoc.message.content.response,
                    thinking: response.raw.ad_hoc.message.content.thinking
                };
            } else {
                console.error("[FrontAutocrat] Error in CLAUDE_MESSAGE_RESPONSE:",
                    response.raw?.ad_hoc?.message?.content?.error || 'Unknown error');
                throw new Error(response.raw?.ad_hoc?.message?.content?.error || 'Unknown error');
            }
        } catch (error) {
            console.error("[FrontAutocrat] Error in sendMessageToClaudeWithTIES:", error);
            this.handleError(error, {
                operation: 'sendMessageToClaudeWithTIES',
                message: message.substring(0, 100) + '...'
            });

            return {
                success: false,
                error: error.message
            };
        }
    }

// To update the ClaudeManager to handle TIES messages:

    /**
     * Handle TIES formatted messages for ClaudeManager
     *
     * @param {object} messageEnvelope - TIES message envelope
     * @returns {object} - Response in TIES envelope format
     */
    handleTIESMessage(messageEnvelope) {
        try {
            const { enum: messageType } = messageEnvelope.raw.required;
            const { content } = messageEnvelope.raw.ad_hoc.message;
            const metadata = messageEnvelope.raw.ad_hoc.message.metadata;

            // Handle different message types
            if (messageType === 'CLAUDE_MESSAGE_REQUEST') {
                // Extract message and options
                const userMessage = content.message;
                const options = {
                    ...content.options,
                    conversationId: metadata.required.conversationId,
                    requestId: metadata.ad_hoc.requestId,
                    personalizationParams: metadata.ad_hoc.personalizationParams
                };

                // Process the message using the existing API
                return this._processClaudeAPIRequest(userMessage, options, messageEnvelope);
            }

            // Handle other message types...

            // If message type not recognized
            return this.orchestrator.createMessageEnvelope(
                'ERROR_RESPONSE',
                {
                    success: false,
                    error: `Unsupported message type: ${messageType}`
                },
                {
                    target: messageEnvelope.raw.required.source,
                    source: 'claude_manager',
                    conversationId: metadata.required.conversationId,
                    error: {
                        code: 'UNSUPPORTED_MESSAGE',
                        message: `Message type not supported: ${messageType}`
                    }
                }
            );
        } catch (error) {
            this.handleError(error, {
                operation: 'handleTIESMessage',
                messageType: messageEnvelope?.raw?.required?.enum
            });

            // Return error response
            return this.orchestrator.createMessageEnvelope(
                'ERROR_RESPONSE',
                {
                    success: false,
                    error: error.message
                },
                {
                    target: messageEnvelope?.raw?.required?.source,
                    source: 'claude_manager',
                    conversationId: messageEnvelope?.raw?.ad_hoc?.message?.metadata?.required?.conversationId,
                    error: {
                        code: 'PROCESSING_ERROR',
                        message: error.message,
                        details: error.stack
                    }
                }
            );
        }
    }

    /**
     * Process a Claude API request using the TIES envelope format
     * @private
     */
    async _processClaudeAPIRequest(message, options, originalEnvelope) {
        // Format the API request body to match backend expectations
        const requestBody = {
            message, // This is the main message the backend expects
            conversationId: options.conversationId,
            moduleContext: options.moduleContext || {}
        };

        // Add thinking parameters if enabled
        if (options.thinkingEnabled) {
            requestBody.thinkingEnabled = true;
            requestBody.thinkingBudget = options.thinkingBudget;
            requestBody.includeThinkingInResponse = options.includeThinkingInResponse;
        }

        // Add personalization if available
        if (options.personalizationParams) {
            requestBody.personalization = options.personalizationParams;
        }

        // Add message history if needed
        const messageHistory = this._getConversationMessages(options.conversationId);
        if (messageHistory.length > 0) {
            requestBody.messages = messageHistory;
        }

        try {
            // Make API request
            const response = await fetch(this.apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('taytrom_auth_token') || ''}`,
                },
                body: JSON.stringify(requestBody),
                credentials: 'include',
                mode: 'cors'
            });

            if (!response.ok) {
                throw new Error(`API error (${response.status}): ${await response.text()}`);
            }

            const data = await response.json();

            if (!data.success) {
                throw new Error(data.error || 'Unknown error from backend');
            }

            // Update conversation history
            this._updateConversationHistory(options.conversationId, message, data.response);

            // Create TIES response envelope
            return this.orchestrator.createMessageEnvelope(
                'CLAUDE_MESSAGE_RESPONSE',
                {
                    success: true,
                    response: data.response,
                    thinking: data.thinking,
                    conversationId: data.conversationId || options.conversationId
                },
                {
                    target: originalEnvelope.raw.required.source,
                    source: 'claude_manager',
                    conversationId: options.conversationId,
                    messageId: `claude_resp_${generateId()}`
                }
            );
        } catch (error) {
            this.log('Error', `Claude API request failed: ${error.message}`);

            // Create error response envelope
            return this.orchestrator.createMessageEnvelope(
                'CLAUDE_MESSAGE_RESPONSE',
                {
                    success: false,
                    error: error.message
                },
                {
                    target: originalEnvelope.raw.required.source,
                    source: 'claude_manager',
                    conversationId: options.conversationId,
                    error: {
                        code: 'API_ERROR',
                        message: error.message
                    }
                }
            );
        }
    }

    /**
     * Helper to update conversation history
     * @private
     */
    _updateConversationHistory(conversationId, userMessage, aiResponse) {
        if (!this._conversationHistory.has(conversationId)) {
            this._conversationHistory.set(conversationId, []);
        }

        const history = this._conversationHistory.get(conversationId);

        // Add user message
        history.push({
            role: 'user',
            content: userMessage,
            timestamp: Date.now()
        });

        // Add AI response
        history.push({
            role: 'assistant',
            content: aiResponse,
            timestamp: Date.now()
        });
    }
// Enhanced handleUserMessage method in FrontAutocrat.jsx
    async handleUserMessage(message, options = {}) {
        try {
            console.log("[FrontAutocrat] handleUserMessage called with message length:",
                typeof message === 'string' ? message.length : 'non-string',
                "options:", Object.keys(options),
                "images:", options.images ? options.images.length : 0);

            // Check if we're running in development mode
            const isDevelopment = this.environment.mode === 'development' ||
                (typeof window !== 'undefined' && window.location.hostname === 'localhost');

            console.log("[FrontAutocrat] Environment mode:", this.environment.mode,
                "Dev detection:", isDevelopment ? "development" : "production");

            // Add personalization parameters if not explicitly provided
            if (this.personalizationBridge && !options.personalizationParams) {
                const userId = this.authManager.getCurrentUser()?.id;
                if (userId) {
                    options.personalizationParams = this.personalizationBridge.preparePersonalizationParams(userId);
                    console.log("[FrontAutocrat] Added personalization for user:", userId ? userId.substring(0, 8) : 'unknown');
                }
            }

            // Format domain information to include directly in the prompt
            let domainInfoText = "";
            let originalMessage = message;

            if (options.knowledgeDomains && Object.keys(options.knowledgeDomains).length > 0) {
                console.log("[FrontAutocrat] Formatting knowledge domains for prompt:",
                    Object.keys(options.knowledgeDomains).length, "domains");

                domainInfoText = "\n\n## Taytrom Knowledge Domains\n\n";

                // Group domains by category for better organization
                const domainsByCategory = {};

                Object.entries(options.knowledgeDomains).forEach(([key, domain]) => {
                    const category = domain.category || "general";
                    if (!domainsByCategory[category]) {
                        domainsByCategory[category] = [];
                    }
                    domainsByCategory[category].push(domain);
                });

                // Add formatted domains by category
                Object.entries(domainsByCategory).forEach(([category, domains]) => {
                    domainInfoText += `### ${category.charAt(0).toUpperCase() + category.slice(1)} Knowledge\n\n`;

                    domains.forEach(domain => {
                        domainInfoText += `#### ${domain.title}\n`;
                        domainInfoText += `${domain.description || ''}\n\n`;

                        // Include detailed steps if available
                        if (domain.sections && domain.sections.length > 0) {
                            domain.sections.forEach(section => {
                                domainInfoText += `**${section.title}**: ${section.content}\n\n`;
                            });
                        }
                    });
                });
            }

            // Prepare the request data
            let requestData = {
                conversationId: options.conversationId || `conv_${Date.now()}`,
                moduleContext: options.moduleContext || null,
                // Make sure messages is formatted as expected by Claude
                messages: options.conversationHistory ? options.conversationHistory.map(msg => {
                    // Check if the message has images
                    if (msg.images && Array.isArray(msg.images) && msg.images.length > 0) {
                        return {
                            role: msg.role,
                            content: this._formatMultiModalContent(msg.content, msg.images)
                        };
                    }

                    return {
                        role: msg.role,
                        content: msg.content
                    };
                }) : [],
                thinkingEnabled: options.thinkingEnabled || false,
                thinkingBudget: options.thinkingBudget || null,
                knowledgeDomains: options.knowledgeDomains, // Include raw domains
                adminKnowledge: options.adminKnowledge // Include admin knowledge
            };

            // Add enhanced user context handling
            if (options.personalizationParams) {
                requestData.personalization = options.personalizationParams;

                // Extract user context for system prompt enhancement
                if (options.personalizationParams.triadicData) {
                    requestData.userContext = {
                        personal: options.personalizationParams.triadicData.personal || {},
                        progress: options.personalizationParams.triadicData.progress || {},
                        settings: {
                            personalizationLevel: options.personalizationParams.level || 2,
                            theme: options.applicationState?.theme || 'dark'
                        }
                    };
                }

                // Flag to indicate user context is applied
                requestData.userContextApplied = true;
            }

            // Process images if they are present
            if (options.images && Array.isArray(options.images) && options.images.length > 0) {
                // Format the current message as multimodal content
                requestData.multiModalContent = this._formatMultiModalContent(message, options.images);
                console.log("[FrontAutocrat] Added multimodal content with", options.images.length, "images");
            } else {
                // Handle incorporating domain information
                if (domainInfoText) {
                    // Create a combined message with domain information
                    let combinedMessage = domainInfoText + "\n\n## User Query\n" + originalMessage;
                    requestData.message = combinedMessage;

                    // Also set a custom system prompt if one was provided
                    if (options.systemPrompt) {
                        requestData.systemPrompt = options.systemPrompt +
                            "\n\nYou have access to the following Taytrom-specific knowledge domains. " +
                            "Use this information to inform your responses and understanding of Taytrom concepts.";
                    } else {
                        requestData.systemPrompt = "You are Claude, integrated into the Taytrom learning platform. " +
                            "You have access to Taytrom-specific knowledge domains. " +
                            "Use this information to inform your responses when users ask about Taytrom concepts, " +
                            "architecture, or development practices. Always acknowledge and utilize the domain " +
                            "knowledge provided to you when responding to related questions.";
                    }
                } else {
                    // No domains, just use the original message
                    requestData.message = message;
                    requestData.systemPrompt = options.systemPrompt;
                }
            }

            // Include application state if provided
            if (options.applicationState) {
                requestData.applicationState = options.applicationState;
            }

            // Include TACS mode if provided
            if (options.tacsMode) {
                requestData.tacsMode = options.tacsMode;
            }

            console.log("[FrontAutocrat] Sending real API request to backend");
            if (domainInfoText) {
                console.log("[FrontAutocrat] Included domain information in prompt");
            }
            if (requestData.multiModalContent) {
                console.log("[FrontAutocrat] Sending multimodal content to backend");
            }
            if (requestData.userContextApplied) {
                console.log("[FrontAutocrat] Sending user context to backend");
            }

            // Make actual API call to backend
            const response = await this.sendToBackOrchestrator(requestData, '/api/claude');

            console.log("[FrontAutocrat] Received API response:",
                response ? (response.success ? "Success" : "Failure: " + (response.error || "Unknown error")) : "No response");

            if (!response || !response.success) {
                throw new Error(response?.error || 'Unknown error from backend');
            }

            // Generate a unique request ID for this response
            const requestId = `req_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
            const responseConversationId = response.conversationId || options.conversationId;

            console.log("[FrontAutocrat] Notifying subscribers about AI response:",
                "ID:", requestId,
                "Content preview:", response.response?.substring(0, 50) + "...");

            // Notify subscribers about successful response
            this.subscriptionManager.notify('ai', {
                type: MESSAGE_TYPES.AI_RESPONSE,
                requestId: requestId,
                conversationId: responseConversationId,
                message: response.response,
                thinking: response.thinking,
                timestamp: Date.now(),
                userContextApplied: requestData.userContextApplied // Pass whether user context was applied
            });

            return {
                success: true,
                response: response.response,
                thinking: response.thinking,
                conversationId: responseConversationId,
                requestId: requestId,
                userContextApplied: requestData.userContextApplied
            };
        } catch (error) {
            console.error("[FrontAutocrat] Error in handleUserMessage:", error);
            this.handleError(error, {
                operation: 'handleUserMessage',
                message: typeof message === 'string' ? message.substring(0, 100) : 'non-string message',
                options: JSON.stringify(Object.keys(options))
            });

            // Notify subscribers about error
            this.subscriptionManager.notify('error', {
                type: 'API_ERROR',
                error: error.message,
                context: 'handleUserMessage',
                timestamp: Date.now()
            });

            return {
                success: false,
                error: error.message
            };
        }
    }









    /**
     * Formats text and images into multimodal content for Claude
     * @param {string} textContent - The text message
     * @param {Array} images - Array of image data
     * @returns {Array} - Formatted content array for Claude's API
     */
    _formatMultiModalContent(textContent, images) {
        const content = [];

        // Add text content first
        if (textContent && textContent.trim()) {
            content.push({
                type: 'text',
                text: textContent
            });
        }

        // Add images
        const formattedImages = prepareImageData(images);
        content.push(...formattedImages);

        return content;
    }
















    /**
     * Send a message through the orchestrator's messaging system
     */
    messageRelay(message) {
        try {
            if (!message || !message.type || !message.from) {
                throw new Error('Invalid message format');
            }

            logger('FrontAutocrat', 'MessageRelay',
                `Routing message from ${message.from} to ${message.to || 'broadcast'}`,
                `Type: ${message.type}`);

            // Process the message with registered handler if available
            if (this._messageHandlers && this._messageHandlers[message.type]) {
                this._messageHandlers[message.type](message);
            }

            // If specific target is specified
            if (message.to) {
                // If target is a manager, route to that manager
                const managerMap = {
                    [COMPONENT_TYPES.AUTH_MANAGER]: this.authManager,
                    [COMPONENT_TYPES.PROGRESS_MANAGER]: this.progressManager,
                    [COMPONENT_TYPES.SETTINGS_MANAGER]: this.settingsManager,
                    [COMPONENT_TYPES.EXPEDITION_MANAGER]: this.expeditionManager,
                    [COMPONENT_TYPES.AI_INTELLIGENCE_MANAGER]: this.aiIntelligenceManager, // New mapping
                    [COMPONENT_TYPES.PERSONALIZATION_BRIDGE]: this.personalizationBridge
                };

                // Add backward compatibility for CLAUDE_MANAGER
                if (message.to === COMPONENT_TYPES.CLAUDE_MANAGER) {
                    return managerMap[COMPONENT_TYPES.AI_INTELLIGENCE_MANAGER].handleMessage(message);
                }

                if (managerMap[message.to]) {
                    return managerMap[message.to].handleMessage(message);
                }

                // If target is a registered component, notify through subscription
                this.subscriptionManager.notify(message.to.toLowerCase(), message);
            } else {
                // Broadcast to appropriate channel based on message type
                const channelMap = {
                    [MESSAGE_TYPES.USER_INPUT]: 'ai',
                    [MESSAGE_TYPES.AI_RESPONSE]: 'ai',
                    [MESSAGE_TYPES.PROFILE_UPDATE]: 'auth',
                    [MESSAGE_TYPES.PROGRESS_SYNC]: 'progress',
                    [MESSAGE_TYPES.SETTINGS_CHANGE]: 'settings',
                    [MESSAGE_TYPES.EXPEDITION_PROGRESS]: 'expedition',
                    [MESSAGE_TYPES.MODULE_COMPLETION]: 'progress',
                    [MESSAGE_TYPES.ACHIEVEMENT_UNLOCKED]: 'progress',
                    [MESSAGE_TYPES.AI_PERSONALIZATION_UPDATE]: 'ai'
                };

                const channel = channelMap[message.type] || 'global';
                this.subscriptionManager.notify(channel, message);
            }

            return { success: true };
        } catch (error) {
            this.handleError(error, {
                operation: 'messageRelay',
                messageType: message?.type,
                messageFrom: message?.from
            });
            return { success: false, error: error.message };
        }
    }

    /**
     * Subscribe to events (AI responses, errors, etc.)
     */
    subscribe(type, handler, source = 'app') {
        return this.subscriptionManager.subscribe(type, handler, source);
    }

    /**
     * Unsubscribe from events
     */
    unsubscribe(type, subscriptionId) {
        return this.subscriptionManager.unsubscribe(type, subscriptionId);
    }

    /**
     * Register a listener for specific component
     */
    registerListener(componentType, handler) {
        const listenerId = generateId('listener');

        // Create message handler
        const messageHandler = (notification) => {
            // Only forward messages intended for this component type
            if (notification.data && notification.data.to === componentType) {
                handler(notification.data);
            } else if (notification.data && !notification.data.to) {
                // Also handle broadcast messages
                handler(notification.data);
            }
        };

        // Subscribe to global channel
        const subscription = this.subscriptionManager.subscribe('global', messageHandler, componentType);

        logger('FrontAutocrat', 'RegisterListener',
            `Registered listener for component ${componentType}`, `ID: ${listenerId}`);

        return listenerId;
    }

    /**
     * Unregister a component listener
     */
    unregisterListener(listenerId) {
        if (!listenerId) return;

        this.subscriptionManager.unsubscribe('global', listenerId);

        logger('FrontAutocrat', 'UnregisterListener',
            `Unregistered listener ${listenerId}`);
    }

    /**
     * Centralized error handling
     */
    handleError(error, context = {}) {
        const errorInfo = {
            timestamp: Date.now(),
            message: error.message,
            stack: error.stack,
            context
        };

        logger('FrontAutocrat', 'Error', error.message, JSON.stringify(context));
        this._errors.push(errorInfo);

        // Notify error subscribers
        this._errorSubscribers.forEach(handler => {
            try {
                handler(errorInfo);
            } catch (handlerError) {
                console.error('Error in error handler:', handlerError);
            }
        });

        // If error subscription system is set up, also use that
        if (this.subscriptionManager) {
            this.subscriptionManager.notify('error', errorInfo);
        }

        return errorInfo;
    }

    /**
     * Register a direct error subscriber (different from subscription manager)
     */
    onError(handler) {
        if (typeof handler !== 'function') {
            throw new Error('Error handler must be a function');
        }
        this._errorSubscribers.add(handler);

        // Return unsubscribe function
        return () => {
            this._errorSubscribers.delete(handler);
        };
    }

    /**
     * Get system statistics and health information
     */
    getSystemStats() {
        return {
            systemState: this.systemState,
            uptime: Date.now() - this.environment.startTime,
            errors: this._errors.length,
            subscriptions: this.subscriptionManager.getSubscriptionStats(),
            components: this._registeredComponents.size,
            environment: this.environment,
            lastActivity: {
                error: this._errors.length > 0 ? this._errors[this._errors.length - 1].timestamp : null,
                message: this.aiIntelligenceManager ?
                    this.aiIntelligenceManager.getSystemStatus().operations.claudeRequests.size > 0 : null
            }
        };
    }

    /**
     * Clean up resources
     */
    cleanup() {
        logger('FrontAutocrat', 'Cleanup', 'Releasing all resources');

        // Cleanup all managers
        this.subscriptionManager.cleanup();
        if (this.aiIntelligenceManager) this.aiIntelligenceManager.cleanup();
        this.authManager.cleanup();

        if (this.progressManager) this.progressManager.cleanup();
        if (this.settingsManager) this.settingsManager.cleanup();
        if (this.expeditionManager) this.expeditionManager.cleanup();
        if (this.personalizationBridge) this.personalizationBridge.cleanup();
        if (this.contextProvider) this.contextProvider.cleanup();

        this._errorSubscribers.clear();
        this._registeredComponents.clear();
        this.systemState = SYSTEM_STATES.SHUTDOWN;
    }
}

/**
 * React HOC to ensure FrontAutocrat is initialized
 */
export const withFrontOrchestrator = (WrappedComponent) => {
    return function WithFrontOrchestratorComponent(props) {
        const { orchestrator, ...rest } = props;
        const [isInitialized, setIsInitialized] = React.useState(
            orchestrator?.systemState === SYSTEM_STATES.RUNNING
        );
        const [error, setError] = React.useState(null);

        React.useEffect(() => {
            if (!orchestrator || isInitialized) return;

            const initOrchestrator = async () => {
                try {
                    const result = await orchestrator.initialize();
                    setIsInitialized(result);
                } catch (err) {
                    setError(err);
                }
            };

            initOrchestrator();

            // Set up error handler
            const unsubscribe = orchestrator.onError(err => {
                console.error('Orchestrator error:', err);
            });

            return () => {
                unsubscribe();
            };
        }, [orchestrator, isInitialized]);

        if (error) {
            return (
                <div className="taytrom-error">
                    <h3>Error Initializing AI System</h3>
                    <p>{error.message}</p>
                </div>
            );
        }

        if (!isInitialized) {
            return (
                <div className="taytrom-loading">
                    <h3>Initializing AI System...</h3>
                </div>
            );
        }

        return <WrappedComponent orchestrator={orchestrator} {...rest} />;
    };
};

export default FrontAutocrat;