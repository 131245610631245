import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Download, FileText, PenTool, List, Plus, Star,
    MessageSquare, Sparkles, Award, Send, Save,
    CheckCircle, Coffee, FileDown, Share2, Settings
} from 'lucide-react';
import styles from './Compiler_6_Steps_InteractiveTour_Specialists_Writing.module.css'

const Compiler_6_Sections_InteractiveTour_Specialists_Writing = ({
                                                                     onNext,
                                                                     onPrevious,
                                                                     isDark = true,
                                                                     orchestrator = null
                                                                 }) => {
    const [documentSections, setDocumentSections] = useState([
        {
            id: 'section-1',
            type: 'heading',
            title: 'A Mad Tea-Party',
            content: '',
            parentId: null
        },
        {
            id: 'section-2',
            type: 'paragraph',
            title: '',
            content: 'There was a table set out under a tree in front of the house, and the March Hare and the Hatter were having tea at it: a Dormouse was sitting between them, fast asleep, and the other two were using it as a cushion, resting their elbows on it, and talking over its head.',
            parentId: null
        },
        {
            id: 'section-3',
            type: 'paragraph',
            title: '',
            content: '"Very uncomfortable for the Dormouse," thought Alice; "only, as it\'s asleep, I suppose it doesn\'t mind."',
            parentId: null
        },
        {
            id: 'section-4',
            type: 'heading',
            title: 'The Queen\'s Croquet-Ground',
            content: '',
            parentId: null
        },
        {
            id: 'section-5',
            type: 'paragraph',
            title: '',
            content: 'A large rose-tree stood near the entrance of the garden: the roses growing on it were white, but there were three gardeners at it, busily painting them red. Alice thought this a very curious thing, and she went nearer to watch them.',
            parentId: null
        }
    ]);

    const [suggestions, setSuggestions] = useState([
        {
            id: 'suggestion-1',
            type: 'content',
            text: 'Add a vivid description of the curious tea cups and platters on the table.',
            targetSection: 'section-2'
        },
        {
            id: 'suggestion-2',
            type: 'style',
            text: 'Enhance this paragraph with more whimsical adjectives and fantastical details.',
            targetSection: 'section-3'
        },
        {
            id: 'suggestion-3',
            type: 'structure',
            text: 'Add a new section describing the Queen of Hearts and her temperament.',
            targetSection: 'section-4'
        }
    ]);

    const [selectedSection, setSelectedSection] = useState(null);
    const [activeSuggestion, setActiveSuggestion] = useState(null);
    const [showExportMenu, setShowExportMenu] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [exportFormat, setExportFormat] = useState(null);
    const [tourStep, setTourStep] = useState(0);
    const [showCompletionAnimation, setShowCompletionAnimation] = useState(false);
    const [tourCompleted, setTourCompleted] = useState(false);

    const exportRef = useRef(null);
    const suggestionsRef = useRef(null);

    // Confetti animation elements
    const [confetti, setConfetti] = useState([]);

    // Tour steps specific to this final stage
    const tourSteps = [
        {
            title: "Welcome to the Final Tour Stage!",
            content: "You've made it to the last part of our interactive tour. Let's see how to finalize and share your work.",
            target: "document-panel",
            placement: "center"
        },
        {
            title: "AI-Powered Writing Suggestions",
            content: "These colorful bubbles contain AI suggestions to enhance your writing. Try clicking one to see how it works!",
            target: "suggestions-panel",
            placement: "left"
        },
        {
            title: "Export Your Work",
            content: "When you're finished, you can export your document in multiple formats. Click the export button to see options.",
            target: "export-button",
            placement: "top"
        },
        {
            title: "Tour Complete!",
            content: "Congratulations! You've completed the Taytrom interactive tour. You now know the essentials of our AI-powered writing platform.",
            target: "document-panel",
            placement: "center",
            isCompletion: true
        }
    ];

    useEffect(() => {
        // Handle tour step transitions
        if (tourStep === 1) {
            const timeout = setTimeout(() => {
                if (suggestionsRef.current) {
                    suggestionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 300);
            return () => clearTimeout(timeout);
        }

        if (tourStep === 2) {
            const timeout = setTimeout(() => {
                if (exportRef.current) {
                    exportRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 300);
            return () => clearTimeout(timeout);
        }

        if (tourStep === 3) {
            const timeout = setTimeout(() => {
                setShowCompletionAnimation(true);
                generateConfetti();
            }, 500);
            return () => clearTimeout(timeout);
        }
    }, [tourStep]);

    const generateConfetti = () => {
        const newConfetti = [];

        for (let i = 0; i < 60; i++) {
            newConfetti.push({
                id: `confetti-${i}`,
                x: Math.random() * 100,
                y: -20 - Math.random() * 80,
                size: 5 + Math.random() * 8,
                rotation: Math.random() * 360,
                color: [
                    '#4A6BFF', // Blue
                    '#FF6B6B', // Red
                    '#FFD166', // Yellow
                    '#06D6A0', // Green
                    '#9b5de5'  // Purple
                ][Math.floor(Math.random() * 5)],
                duration: 1 + Math.random() * 3
            });
        }

        setConfetti(newConfetti);
    };

    const handleSectionClick = (sectionId) => {
        setSelectedSection(sectionId);
    };

    const handleSuggestionClick = (suggestion) => {
        setActiveSuggestion(suggestion);
        setSelectedSection(suggestion.targetSection);

        // If this is the right tour step, advance
        if (tourStep === 1) {
            setTimeout(() => setTourStep(2), 1000);
        }
    };

    const applySuggestion = () => {
        if (!activeSuggestion || !selectedSection) return;

        // Find the target section
        const section = documentSections.find(s => s.id === activeSuggestion.targetSection);
        if (!section) return;

        let newContent = '';

        // Generate new content based on suggestion type
        switch(activeSuggestion.type) {
            case 'content':
                if (section.id === 'section-2') {
                    newContent = section.content + ' The table was a curious array indeed—teacups of every shape and size, some tall and thin, others short and stout. Platters stacked precariously high, with scones and cakes arranged in wonderfully chaotic patterns. Some cups were chipped, others had multiple handles, and a few seemed to change color depending on how you looked at them.';
                } else {
                    newContent = section.content + ' [Enhanced content based on AI suggestion]';
                }
                break;
            case 'style':
                if (section.id === 'section-3') {
                    newContent = '"How absolutely dreadful for the poor drowsy Dormouse," mused Alice with a flutter of concern; "only, as it\'s lost in the magical realm of slumber, I suppose it floats blissfully unaware in its dreamy wonderland."';
                } else {
                    newContent = 'This content has been stylistically enhanced by the AI.';
                }
                break;
            case 'structure':
                // For structure suggestions, we'll add a new section
                const newSection = {
                    id: `section-${documentSections.length + 1}`,
                    type: 'paragraph',
                    title: '',
                    content: 'The Queen of Hearts was a formidable figure, with a temper as red as the roses her gardeners painted. She ruled the croquet ground with an iron fist and a deck of cards, ready to shout "Off with their heads!" at the slightest provocation. Alice watched her warily, knowing that royal displeasure could be only a misstep away.',
                    parentId: null
                };

                // Insert the new section after the target
                const sectionIndex = documentSections.findIndex(s => s.id === section.id);
                const newSections = [...documentSections];
                newSections.splice(sectionIndex + 1, 0, newSection);
                setDocumentSections(newSections);
                setSelectedSection(newSection.id);

                // No need to update content since we added a section
                return;
        }

        // Update the section with new content
        setDocumentSections(sections =>
            sections.map(s =>
                s.id === section.id ? { ...s, content: newContent } : s
            )
        );

        // Show success message
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);

        // Clear active suggestion
        setActiveSuggestion(null);
    };

    const handleExportClick = () => {
        setShowExportMenu(!showExportMenu);

        // If this is the right tour step, advance
        if (tourStep === 2) {
            setTimeout(() => setTourStep(3), 1500);
        }
    };

    const handleExportFormat = (format) => {
        setExportFormat(format);
        setShowExportMenu(false);

        // Show success message
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };

    const finishTour = () => {
        setTourCompleted(true);
        if (onNext) {
            setTimeout(() => onNext(), 2000);
        }
    };

    // Types of icons for different sections
    const typeIcons = {
        heading: <FileText className={styles.typeIcon} size={18} />,
        paragraph: <PenTool className={styles.typeIcon} size={18} />,
        bulletList: <List className={styles.typeIcon} size={18} />
    };

    // Icons for different suggestion types
    const suggestionIcons = {
        content: <MessageSquare size={18} />,
        style: <Sparkles size={18} />,
        structure: <List size={18} />
    };

    // Colors for different suggestion types
    const suggestionColors = {
        content: '#4A6BFF', // Blue
        style: '#9b5de5',   // Purple
        structure: '#06D6A0' // Green
    };

    const renderSection = (section) => {
        const isSelected = selectedSection === section.id;
        const isActiveTarget = activeSuggestion && activeSuggestion.targetSection === section.id;

        return (
            <motion.div
                key={section.id}
                className={`${styles.section} ${isSelected ? styles.selected : ''} ${isActiveTarget ? styles.activeTarget : ''}`}
                onClick={() => handleSectionClick(section.id)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
            >
                <div className={styles.sectionHeader}>
                    {typeIcons[section.type]}
                    {section.type === 'heading' && (
                        <div className={styles.sectionTitle}>
                            <h3>{section.title}</h3>
                        </div>
                    )}
                </div>

                {section.type !== 'heading' && (
                    <div className={styles.sectionContent}>
                        <p>{section.content}</p>
                    </div>
                )}

                {isActiveTarget && (
                    <div className={styles.suggestionOverlay}>
                        <p className={styles.suggestionExplanation}>
                            {activeSuggestion.text}
                        </p>
                        <button
                            className={styles.applySuggestionButton}
                            onClick={applySuggestion}
                        >
                            Apply Suggestion
                        </button>
                    </div>
                )}
            </motion.div>
        );
    };

    return (
        <div className={`${styles.tourContainer} ${isDark ? styles.darkMode : ''}`} id="document-panel">
            {/* Main layout with split panels */}
            <div className={styles.layout}>
                {/* Left panel: Document */}
                <div className={styles.documentPanel}>
                    <div className={styles.documentHeader}>
                        <h2 className={styles.documentTitle}>Alice's Adventures in Wonderland</h2>
                        <div className={styles.documentActions}>
                            <button
                                ref={exportRef}
                                id="export-button"
                                className={styles.actionButton}
                                onClick={handleExportClick}
                            >
                                <Download size={20} />
                                <span>Export</span>
                            </button>

                            <AnimatePresence>
                                {showExportMenu && (
                                    <motion.div
                                        className={styles.exportMenu}
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                    >
                                        <button onClick={() => handleExportFormat('pdf')} className={styles.exportOption}>
                                            <FileDown size={16} /> PDF
                                        </button>
                                        <button onClick={() => handleExportFormat('docx')} className={styles.exportOption}>
                                            <FileText size={16} /> Word Document
                                        </button>
                                        <button onClick={() => handleExportFormat('markdown')} className={styles.exportOption}>
                                            <FileText size={16} /> Markdown
                                        </button>
                                        <button onClick={() => handleExportFormat('html')} className={styles.exportOption}>
                                            <FileText size={16} /> HTML
                                        </button>
                                    </motion.div>
                                )}
                            </AnimatePresence>

                            <button className={styles.actionButton}>
                                <Share2 size={20} />
                                <span>Share</span>
                            </button>

                            <button className={styles.actionButton}>
                                <Save size={20} />
                                <span>Save</span>
                            </button>
                        </div>
                    </div>

                    <div className={styles.documentSections}>
                        {documentSections.map(section => renderSection(section))}
                    </div>
                </div>

                {/* Right panel: AI Suggestions */}
                <div
                    className={styles.suggestionsPanel}
                    id="suggestions-panel"
                    ref={suggestionsRef}
                >
                    <h3 className={styles.panelTitle}>
                        <Sparkles size={18} /> AI Suggestions
                    </h3>

                    <div className={styles.suggestionsList}>
                        {suggestions.map(suggestion => (
                            <motion.div
                                key={suggestion.id}
                                className={`${styles.suggestionCard} ${activeSuggestion?.id === suggestion.id ? styles.activeSuggestion : ''}`}
                                style={{
                                    backgroundColor: `${suggestionColors[suggestion.type]}15`,
                                    borderColor: suggestionColors[suggestion.type]
                                }}
                                onClick={() => handleSuggestionClick(suggestion)}
                                whileHover={{ scale: 1.03, boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <div
                                    className={styles.suggestionType}
                                    style={{ backgroundColor: suggestionColors[suggestion.type] }}
                                >
                                    {suggestionIcons[suggestion.type]}
                                    <span>{suggestion.type}</span>
                                </div>
                                <p className={styles.suggestionText}>{suggestion.text}</p>
                            </motion.div>
                        ))}
                    </div>

                    <div className={styles.generateMore}>
                        <button className={styles.generateButton}>
                            <Sparkles size={16} /> Generate More Suggestions
                        </button>
                    </div>
                </div>
            </div>

            {/* Success Message */}
            <AnimatePresence>
                {showSuccessMessage && (
                    <motion.div
                        className={styles.successMessage}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                    >
                        <CheckCircle size={20} />
                        <span>
              {exportFormat
                  ? `Document exported as ${exportFormat.toUpperCase()}`
                  : 'Suggestion applied successfully!'}
            </span>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Tour Guide Overlay */}
            <AnimatePresence>
                {tourStep >= 0 && tourStep < tourSteps.length && !tourCompleted && (
                    <motion.div
                        className={styles.tourOverlay}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className={`${styles.tourTooltip} ${tourSteps[tourStep].isCompletion ? styles.completionTooltip : ''}`}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            style={{
                                top: tourStep === 1 ? '30%' : (tourStep === 2 ? '20%' : '50%'),
                                right: tourStep === 1 ? '60%' : 'auto',
                                bottom: 'auto',
                                left: tourStep === 2 ? '50%' : (tourStep === 1 ? 'auto' : '50%'),
                                transform: (tourStep === 0 || tourStep === 3 || tourStep === 2) ? 'translateX(-50%)' : 'none'
                            }}
                        >
                            {tourSteps[tourStep].isCompletion && showCompletionAnimation && (
                                <div className={styles.confettiContainer}>
                                    {confetti.map(item => (
                                        <motion.div
                                            key={item.id}
                                            className={styles.confettiPiece}
                                            style={{
                                                backgroundColor: item.color,
                                                width: item.size,
                                                height: item.size * (0.5 + Math.random() * 0.5)
                                            }}
                                            initial={{
                                                x: `${item.x}%`,
                                                y: `${item.y}%`,
                                                rotate: item.rotation
                                            }}
                                            animate={{
                                                y: '120%',
                                                rotate: item.rotation + (Math.random() > 0.5 ? 180 : -180)
                                            }}
                                            transition={{
                                                duration: item.duration,
                                                ease: "easeInOut"
                                            }}
                                        />
                                    ))}
                                </div>
                            )}

                            {tourSteps[tourStep].isCompletion && (
                                <div className={styles.completionBadge}>
                                    <Award size={40} />
                                </div>
                            )}

                            <h3>{tourSteps[tourStep].title}</h3>
                            <p>{tourSteps[tourStep].content}</p>

                            <div className={styles.tourProgress}>
                                {tourSteps.map((_, index) => (
                                    <div
                                        key={index}
                                        className={`${styles.tourDot} ${index === tourStep ? styles.activeDot : ''}`}
                                        onClick={() => setTourStep(index)}
                                    />
                                ))}
                            </div>

                            <div className={styles.tourButtons}>
                                {tourStep > 0 && (
                                    <button
                                        className={styles.tourButton}
                                        onClick={() => setTourStep(prev => prev - 1)}
                                    >
                                        Previous
                                    </button>
                                )}

                                {tourStep < tourSteps.length - 1 && (
                                    <button
                                        className={`${styles.tourButton} ${styles.nextButton}`}
                                        onClick={() => setTourStep(prev => prev + 1)}
                                    >
                                        Next
                                    </button>
                                )}

                                {tourStep === tourSteps.length - 1 && (
                                    <button
                                        className={`${styles.tourButton} ${styles.finishButton}`}
                                        onClick={finishTour}
                                    >
                                        Finish Tour
                                    </button>
                                )}
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Navigation controls for demo */}
            <div className={styles.navigationControls}>
                <button
                    className={styles.navButton}
                    onClick={onPrevious}
                >
                    Previous Step
                </button>
                <div className={styles.tourStatus}>
                    Step 6: Finalizing Your Document
                </div>
                <button
                    className={styles.navButton}
                    onClick={onNext}
                >
                    Next Step
                </button>
            </div>
        </div>
    );
};

export default Compiler_6_Sections_InteractiveTour_Specialists_Writing;