import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css'; // Assuming you have this file

// Import all app versions
import ScrapApp from './src_SCRAP/ScrapApp.jsx';
import Phase1App from '@/Phase1App.jsx';
import FullDevApp from './FullDevApp.jsx';
import FrontAutocrat from './FrontAutocrat.jsx';

// ========================================================
// TOGGLE FOR DEVELOPMENT - Choose which app version to run
// ========================================================
// Options:
// 'standard' - Use the standard App.jsx
// 'phase1' - Use Phase1App.jsx for AI-first MVP
// 'fulldev' - Use FullDevApp.jsx for complete development version
// 'scrap' - Use ScrapApp.jsx for experiments and testing
// ========================================================
const APP_VERSION = 'phase1'; // 👈 Change this to select which app to run
// ========================================================

async function initializeSystem() {
    try {
        // Initialize theme classes
        const initializeThemeClasses = () => {
            const savedTheme = localStorage.getItem('taytrom-theme');
            const prefersDarkScheme = window.matchMedia('(prefers-colors-scheme: dark)').matches;
            const initialTheme = savedTheme || (prefersDarkScheme ? 'dark' : 'light');
            document.documentElement.classList.add(initialTheme);
            if (!savedTheme) {
                localStorage.setItem('taytrom-theme', initialTheme);
            }
        };

        initializeThemeClasses();

        // Set API URL for development or production
        const apiBaseUrl = import.meta.env.MODE === 'production'
            ? "https://taytrom-backend.onrender.com"
            : "http://localhost:8080";

        window.API_URL = apiBaseUrl;

        if (APP_VERSION === 'scrap') {
            // Render ScrapApp for development/testing
            const root = createRoot(document.getElementById('root'));
            root.render(
                <React.StrictMode>
                    <ScrapApp />
                </React.StrictMode>
            );
            console.log("[Bootstrap] Running in ScrapApp development mode");
        } else {
            // Initialize the FrontAutocrat for other app versions
            console.log(`[Bootstrap.initializeSystem] Initializing Taytrom integration for ${APP_VERSION} version...`);

            const orchestrator = new FrontAutocrat({
                mode: import.meta.env.MODE,
                apiBaseUrl: apiBaseUrl,
                claude: {
                    model: 'claude-3-opus-20240229',
                    systemPrompt: `You are Claude, an AI assistant integrated into the Taytrom learning platform. 
                    Your main role is to help students understand complex concepts through clear explanations,
                    helpful analogies, and interactive guidance. You excel at making technical topics approachable
                    with real-world analogies and step-by-step explanations. Keep your responses focused on
                    the learning context provided.`
                }
            });

            await orchestrator.initialize();
            console.log("[Bootstrap.initializeSystem] Taytrom integration initialized successfully");

            const root = createRoot(document.getElementById('root'));

            // Render the appropriate app based on the APP_VERSION toggle
            switch (APP_VERSION) {
                case 'phase1':
                    root.render(
                        <React.StrictMode>
                            <Phase1App orchestrator={orchestrator} />
                        </React.StrictMode>
                    );
                    console.log("[Bootstrap] Running in Phase1App mode (AI-First MVP)");
                    break;
                case 'fulldev':
                    root.render(
                        <React.StrictMode>
                            <FullDevApp orchestrator={orchestrator} />
                        </React.StrictMode>
                    );
                    console.log("[Bootstrap] Running in FullDevApp mode (Complete Development Version)");
                    break;
                default: // 'standard'
                    root.render(
                        <React.StrictMode>
                            <App orchestrator={orchestrator} />
                        </React.StrictMode>
                    );
                    console.log("[Bootstrap] Running in standard App mode");
            }
        }
    } catch (error) {
        console.error("[Bootstrap.initializeSystem] System initialization failed:", {
            error: error.message,
            timestamp: Date.now()
        });

        // Fallback rendering without AI capabilities
        const root = createRoot(document.getElementById('root'));
        root.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        );
    }
}

initializeSystem();