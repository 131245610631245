import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Check,
    X,
    ChevronRight,
    ChevronDown,
    Edit,
    Trash2,
    Save,
    PlusCircle,
    FileEdit,
    AlignLeft,
    Heading,
    FileText,
    List,
    Code,
    Quote,
    Target,
    ArrowUp,
    ArrowDown,
    Move,
    Maximize2,
    Minimize2,
    ZoomIn,
    ZoomOut,
    RotateCcw,
    BookOpen,
    AlertTriangle,
    Type,
    Settings,
    Eye,
    EyeOff,
    Sparkles
} from 'lucide-react';
import styles from './ListEditor_Specialists_3_Sections_ProductionDemo_Specialists_Writing.module.css';

const StructuralListVisualization = ({
                                         documentContent,
                                         setDocumentContent,
                                         selectedSection,
                                         setSelectedSection,
                                         scrollSectionIntoView,
                                         isDark = true
                                     }) => {
    const [expandedSections, setExpandedSections] = useState({});
    const [editingSection, setEditingSection] = useState(null);
    const [draggingId, setDraggingId] = useState(null);
    const [dropTargetId, setDropTargetId] = useState(null);
    const [dropPosition, setDropPosition] = useState(null); // 'before', 'after', or 'child'
    const [successMessage, setSuccessMessage] = useState('');
    const [fullscreen, setFullscreen] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [keyboardMode, setKeyboardMode] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const containerRef = useRef(null);

    // Apply theme to document and detect keyboard usage
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');

        // Add keyboard detection
        const handleKeyDown = (e) => {
            if (e.key === 'Tab') {
                setKeyboardMode(true);
                document.body.classList.add(styles['taytrom-keyboard-mode']);
            }
        };

        const handleMouseDown = () => {
            setKeyboardMode(false);
            document.body.classList.remove(styles['taytrom-keyboard-mode']);
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('mousedown', handleMouseDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('mousedown', handleMouseDown);
        };
    }, [isDark]);

    // Node type icons and styling - using the enhanced colors from our CSS
    const nodeTypes = {
        introduction: {
            icon: <BookOpen size={16} />,
            colorClass: styles['taytrom-section-icon-introduction']
        },
        heading: {
            icon: <Heading size={16} />,
            colorClass: styles['taytrom-section-icon-heading']
        },
        subheading: {
            icon: <Type size={16} />,
            colorClass: styles['taytrom-section-icon-subheading']
        },
        paragraph: {
            icon: <AlignLeft size={16} />,
            colorClass: styles['taytrom-section-icon-paragraph']
        },
        bulletList: {
            icon: <List size={16} />,
            colorClass: styles['taytrom-section-icon-bulletList']
        },
        note: {
            icon: <AlertTriangle size={16} />,
            colorClass: styles['taytrom-section-icon-note']
        },
        quote: {
            icon: <Quote size={16} />,
            colorClass: styles['taytrom-section-icon-quote']
        },
        conclusion: {
            icon: <FileText size={16} />,
            colorClass: styles['taytrom-section-icon-conclusion']
        },
        code: {
            icon: <Code size={16} />,
            colorClass: styles['taytrom-section-icon-code']
        }
    };

    // Initialize expanded state for parent steps
    useEffect(() => {
        if (documentContent && documentContent.sections && documentContent.sections.length > 0) {
            const expanded = {};
            documentContent.sections.forEach(section => {
                if (!section.parentId) {
                    expanded[section.id] = true;
                }
            });
            setExpandedSections(expanded);
        }
    }, [documentContent.sections]);

    // Show success message temporarily with refined animation
    const showSuccessMessage = useCallback((message) => {
        setSuccessMessage(message);
        setTimeout(() => setSuccessMessage(''), 3000);
    }, []);

    // Toggle section expansion
    const toggleExpand = useCallback((id) => {
        setExpandedSections(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    }, []);

    // Check if section has children
    const hasChildren = useCallback((id) => {
        return documentContent.sections.some(section => section.parentId === id);
    }, [documentContent.sections]);

    // Get children of a section
    const getChildren = useCallback((id) => {
        return documentContent.sections
            .filter(section => section.parentId === id)
            .sort((a, b) => (a.position || 0) - (b.position || 0));
    }, [documentContent.sections]);

    // Add a new section with enhanced feedback
    const addSection = useCallback((parentId = null, position = 0) => {
        const newId = Date.now();
        const newSection = {
            id: newId,
            title: "New Section",
            content: parentId && documentContent.sections.find(s => s.id === parentId)?.type === 'bulletList'
                ? "• Bullet point 1\n• Bullet point 2\n• Bullet point 3"
                : "Enter content here...",
            type: parentId ? 'paragraph' : 'heading',
            parentId: parentId,
            position: position
        };

        // Update positions of siblings if needed
        const updatedSections = documentContent.sections.map(section => {
            if (section.parentId === parentId && section.position >= position) {
                return { ...section, position: section.position + 1 };
            }
            return section;
        });

        const newSections = [...updatedSections, newSection];

        setDocumentContent(prev => ({
            ...prev,
            sections: newSections
        }));

        setSelectedSection(newId);
        setEditingSection(newSection);

        // Expand parent if adding a child
        if (parentId !== null) {
            setExpandedSections(prev => ({
                ...prev,
                [parentId]: true
            }));
        }

        showSuccessMessage('New section added');

        // Scroll the new section into view in the document panel
        setTimeout(() => {
            scrollSectionIntoView(newId);
        }, 100);
    }, [documentContent.sections, setDocumentContent, setSelectedSection, showSuccessMessage, scrollSectionIntoView]);

    // Edit a section
    const editSection = useCallback((section) => {
        setSelectedSection(section.id);
        setEditingSection({ ...section });
    }, [setSelectedSection]);

    // Save edited section with enhanced feedback
    const saveSection = useCallback(() => {
        if (!editingSection) return;

        const updatedSections = documentContent.sections.map(section =>
            section.id === editingSection.id ? { ...editingSection } : section
        );

        setDocumentContent(prev => ({
            ...prev,
            sections: updatedSections
        }));

        setEditingSection(null);
        showSuccessMessage('Section updated successfully');
    }, [editingSection, documentContent.sections, setDocumentContent, showSuccessMessage]);

    // Cancel editing
    const cancelEdit = useCallback(() => {
        setEditingSection(null);
    }, []);

    // Delete a section and its children with enhanced confirmation
    const deleteSection = useCallback((id) => {
        // Find all descendants
        const idsToRemove = new Set([id]);

        const findDescendants = (parentId) => {
            const children = documentContent.sections.filter(s => s.parentId === parentId);
            children.forEach(child => {
                idsToRemove.add(child.id);
                findDescendants(child.id);
            });
        };

        findDescendants(id);

        // Filter out the section and its descendants
        const updatedSections = documentContent.sections.filter(section => !idsToRemove.has(section.id));

        setDocumentContent(prev => ({
            ...prev,
            sections: updatedSections
        }));

        if (selectedSection === id) {
            // Select the previous or next root section
            const rootSections = updatedSections.filter(s => s.parentId === null);
            if (rootSections.length > 0) {
                setSelectedSection(rootSections[0].id);
                // Scroll the new selection into view
                setTimeout(() => scrollSectionIntoView(rootSections[0].id), 100);
            } else {
                setSelectedSection(null);
            }
        }

        setEditingSection(null);
        showSuccessMessage('Section deleted');
    }, [documentContent.sections, selectedSection, setDocumentContent, setSelectedSection, showSuccessMessage, scrollSectionIntoView]);

    // Move a section up in the list with enhanced animation
    const moveUp = useCallback((id) => {
        const section = documentContent.sections.find(s => s.id === id);
        if (!section) return;

        // Find siblings with the same parent
        const siblings = documentContent.sections
            .filter(s => s.parentId === section.parentId)
            .sort((a, b) => (a.position || 0) - (b.position || 0));

        const index = siblings.findIndex(s => s.id === id);
        if (index <= 0) return; // Already at the top

        const updatedSections = documentContent.sections.map(s => {
            if (s.id === id) {
                return { ...s, position: (s.position || 0) - 1 };
            }
            if (s.id === siblings[index - 1].id) {
                return { ...s, position: (s.position || 0) + 1 };
            }
            return s;
        });

        setDocumentContent(prev => ({
            ...prev,
            sections: updatedSections
        }));

        showSuccessMessage('Section moved up');
    }, [documentContent.sections, setDocumentContent, showSuccessMessage]);

    // Move a section down in the list with enhanced animation
    const moveDown = useCallback((id) => {
        const section = documentContent.sections.find(s => s.id === id);
        if (!section) return;

        // Find siblings with the same parent
        const siblings = documentContent.sections
            .filter(s => s.parentId === section.parentId)
            .sort((a, b) => (a.position || 0) - (b.position || 0));

        const index = siblings.findIndex(s => s.id === id);
        if (index === -1 || index >= siblings.length - 1) return; // Already at the bottom

        const updatedSections = documentContent.sections.map(s => {
            if (s.id === id) {
                return { ...s, position: (s.position || 0) + 1 };
            }
            if (s.id === siblings[index + 1].id) {
                return { ...s, position: (s.position || 0) - 1 };
            }
            return s;
        });

        setDocumentContent(prev => ({
            ...prev,
            sections: updatedSections
        }));

        showSuccessMessage('Section moved down');
    }, [documentContent.sections, setDocumentContent, showSuccessMessage]);

    // Handle drag start with enhanced visual feedback
    const handleDragStart = useCallback((id, e) => {
        setDraggingId(id);
        // Set ghost drag image
        if (e && e.dataTransfer) {
            const section = documentContent.sections.find(s => s.id === id);
            const ghostElement = document.createElement('div');
            ghostElement.innerText = section?.title || 'Moving section';
            ghostElement.style.position = 'absolute';
            ghostElement.style.top = '-1000px';
            ghostElement.style.opacity = '0';
            document.body.appendChild(ghostElement);
            e.dataTransfer.setDragImage(ghostElement, 0, 0);
            setTimeout(() => {
                document.body.removeChild(ghostElement);
            }, 0);
        }
    }, [documentContent.sections]);

    // Handle drag over with enhanced visual indicators
    const handleDragOver = useCallback((id, position, e) => {
        e.preventDefault();
        if (id !== draggingId) {
            setDropTargetId(id);
            setDropPosition(position);
        }
    }, [draggingId]);

    // Handle drop to restructure with enhanced feedback
    const handleDrop = useCallback((e) => {
        e.preventDefault();

        if (!draggingId || !dropTargetId || !dropPosition) {
            setDraggingId(null);
            setDropTargetId(null);
            setDropPosition(null);
            return;
        }

        // Don't allow dropping on itself
        if (dropTargetId === draggingId) {
            setDraggingId(null);
            setDropTargetId(null);
            setDropPosition(null);
            return;
        }

        // Don't allow dropping on descendants (would create circular reference)
        const isDescendant = (parentId, childId) => {
            if (parentId === childId) return true;
            const child = documentContent.sections.find(s => s.id === childId);
            if (!child || !child.parentId) return false;
            return isDescendant(parentId, child.parentId);
        };

        if (dropPosition === 'child' && isDescendant(draggingId, dropTargetId)) {
            setDraggingId(null);
            setDropTargetId(null);
            setDropPosition(null);
            return;
        }

        const targetSection = documentContent.sections.find(s => s.id === dropTargetId);

        // Apply the restructuring based on drop position
        let updatedSections = [...documentContent.sections];

        if (dropPosition === 'child') {
            // Move as child of target
            updatedSections = documentContent.sections.map(s => {
                if (s.id === draggingId) {
                    // Get new position as last child
                    const siblingCount = documentContent.sections.filter(sibling =>
                        sibling.parentId === dropTargetId
                    ).length;

                    return {
                        ...s,
                        parentId: dropTargetId,
                        position: siblingCount
                    };
                }
                return s;
            });

            // Expand the parent
            setExpandedSections(prev => ({
                ...prev,
                [dropTargetId]: true
            }));

            showSuccessMessage(`Moved section as child of "${targetSection?.title}"`);
        } else {
            // Move before or after target in the same level
            const newParentId = targetSection?.parentId;
            const targetPosition = targetSection?.position || 0;

            // First, update all siblings at the same level to shift positions
            updatedSections = documentContent.sections.map(s => {
                if (s.parentId === newParentId && s.position >= targetPosition && s.id !== draggingId) {
                    // If dropping after, only shift those after the target position
                    if (dropPosition === 'after' && s.position > targetPosition) {
                        return { ...s, position: s.position + 1 };
                    }
                    // If dropping before, shift all including the target
                    else if (dropPosition === 'before') {
                        return { ...s, position: s.position + 1 };
                    }
                }
                return s;
            });

            // Then update the dragged section
            updatedSections = updatedSections.map(s => {
                if (s.id === draggingId) {
                    return {
                        ...s,
                        parentId: newParentId,
                        position: dropPosition === 'before' ? targetPosition : targetPosition + 1
                    };
                }
                return s;
            });

            showSuccessMessage(`Moved section ${dropPosition} "${targetSection?.title}"`);
        }

        setDocumentContent(prev => ({
            ...prev,
            sections: updatedSections
        }));

        setDraggingId(null);
        setDropTargetId(null);
        setDropPosition(null);
    }, [draggingId, dropTargetId, dropPosition, documentContent.sections, setDocumentContent, showSuccessMessage]);

    // Clear drag state when drag ends without a drop
    const handleDragEnd = useCallback(() => {
        setDraggingId(null);
        setDropTargetId(null);
        setDropPosition(null);
    }, []);

    // Toggle fullscreen mode with enhanced transitions
    const toggleFullscreen = useCallback(() => {
        setFullscreen(prev => !prev);
        // Reset zoom when toggling fullscreen for better user experience
        if (!fullscreen) {
            setZoomLevel(1);
        }
    }, [fullscreen]);

    // Enhanced zoom controls with smoother transitions
    const zoomIn = useCallback(() => {
        setZoomLevel(prev => Math.min(prev + 0.1, 2));
    }, []);

    const zoomOut = useCallback(() => {
        setZoomLevel(prev => Math.max(prev - 0.1, 0.5));
    }, []);

    const resetZoom = useCallback(() => {
        setZoomLevel(1);
    }, []);

    // Toggle preview mode
    const togglePreview = useCallback(() => {
        setShowPreview(prev => !prev);
        if (editingSection) {
            setEditingSection(null);
        }
    }, [editingSection]);

    // Render section and its children recursively with enhanced styling
    const renderSection = useCallback((section, level = 0) => {
        const hasChildSections = hasChildren(section.id);
        const isExpanded = expandedSections[section.id];
        const isSelected = selectedSection === section.id;
        const isEditing = editingSection?.id === section.id;
        const isDragging = draggingId === section.id;
        const isDropTarget = dropTargetId === section.id;
        const nodeType = nodeTypes[section.type] || nodeTypes.paragraph;

        // Don't render edit UI in preview mode
        if (showPreview && isEditing) return null;

        return (
            <div key={section.id}
                 className={styles['taytrom-section-group']}
                 style={{
                     transform: `scale(${zoomLevel})`,
                     marginLeft: `${level * 24}px`,
                     opacity: showPreview && section.type === 'heading' ? 1 : showPreview && section.type !== 'heading' ? 0.7 : 1
                 }}>

                {/* Drop target indicator - before */}
                {isDropTarget && dropPosition === 'before' && draggingId !== section.id && (
                    <div className={styles['taytrom-drop-indicator']} />
                )}

                {/* Section row */}
                <div className={styles['taytrom-section-row-wrapper']}>
                    <div
                        className={`${styles['taytrom-section-row']} 
                                   ${isSelected ? styles['taytrom-section-row-selected'] : ''} 
                                   ${isDragging ? styles['taytrom-section-row-dragging'] : ''} 
                                   ${isDropTarget && dropPosition === 'child' ? styles['taytrom-section-row-drop-target'] : ''}`}
                        onClick={() => {
                            if (!showPreview) {
                                setSelectedSection(section.id);
                                scrollSectionIntoView(section.id);
                            }
                        }}
                        draggable={!isEditing && !showPreview}
                        onDragStart={(e) => handleDragStart(section.id, e)}
                        onDragOver={(e) => handleDragOver(section.id, 'child', e)}
                        onDragEnd={handleDragEnd}
                        onDrop={handleDrop}
                        aria-selected={isSelected}
                        role="listitem"
                        tabIndex={showPreview ? -1 : 0}
                    >
                        {/* Expand/collapse button for steps with children */}
                        <div style={{ width: '24px', textAlign: 'center' }}>
                            {hasChildSections ? (
                                <button
                                    className={styles['taytrom-section-expand-btn']}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleExpand(section.id);
                                    }}
                                    aria-label={isExpanded ? "Collapse section" : "Expand section"}
                                    aria-expanded={isExpanded}
                                    tabIndex={showPreview ? -1 : 0}
                                >
                                    {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                                </button>
                            ) : (
                                <span style={{ width: '16px', display: 'inline-block' }}></span>
                            )}
                        </div>

                        {/* Section type icon with enhanced animation */}
                        <div className={`${styles['taytrom-section-icon']} ${nodeType.colorClass}`}
                             aria-hidden="true">
                            {nodeType.icon}
                        </div>

                        {/* Section title with enhanced typography */}
                        {isEditing ? (
                            <input
                                type="text"
                                value={editingSection.title}
                                onChange={(e) => setEditingSection({ ...editingSection, title: e.target.value })}
                                className={styles['taytrom-section-title-input']}
                                onClick={(e) => e.stopPropagation()}
                                autoFocus
                                aria-label="Section title"
                            />
                        ) : (
                            <div className={`${styles['taytrom-section-title']} ${section.parentId === null ? styles['taytrom-section-title-root'] : ''}`}>
                                {section.title}
                            </div>
                        )}

                        {/* Action buttons with enhanced interaction */}
                        {isSelected && !isEditing && !showPreview && (
                            <div className={styles['taytrom-section-actions']}>
                                <button
                                    className={`${styles['taytrom-action-btn']} ${styles['taytrom-btn-edit']}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        editSection(section);
                                    }}
                                    title="Edit section"
                                    aria-label="Edit section"
                                >
                                    <Edit size={14} />
                                </button>
                                <button
                                    className={`${styles['taytrom-action-btn']} ${styles['taytrom-btn-add']}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addSection(section.id, getChildren(section.id).length);
                                    }}
                                    title="Add child section"
                                    aria-label="Add child section"
                                >
                                    <PlusCircle size={14} />
                                </button>
                                <button
                                    className={`${styles['taytrom-action-btn']} ${styles['taytrom-btn-move']}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        moveUp(section.id);
                                    }}
                                    title="Move up"
                                    aria-label="Move section up"
                                    disabled={level === 0 && section.position === 0}
                                >
                                    <ArrowUp size={14} />
                                </button>
                                <button
                                    className={`${styles['taytrom-action-btn']} ${styles['taytrom-btn-move']}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        moveDown(section.id);
                                    }}
                                    title="Move down"
                                    aria-label="Move section down"
                                >
                                    <ArrowDown size={14} />
                                </button>
                                <button
                                    className={`${styles['taytrom-action-btn']} ${styles['taytrom-btn-delete']}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (confirm(`Are you sure you want to delete "${section.title}"${hasChildSections ? ' and all its children' : ''}?`)) {
                                            deleteSection(section.id);
                                        }
                                    }}
                                    title="Delete section"
                                    aria-label="Delete section"
                                >
                                    <Trash2 size={14} />
                                </button>
                            </div>
                        )}

                        {/* Edit mode buttons with enhanced styling */}
                        {isEditing && (
                            <div className={styles['taytrom-edit-actions']}>
                                <button
                                    className={`${styles['taytrom-edit-btn']} ${styles['taytrom-edit-btn-save']}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        saveSection();
                                    }}
                                    aria-label="Save changes"
                                >
                                    <Check size={14} />
                                    <span>Save</span>
                                </button>
                                <button
                                    className={`${styles['taytrom-edit-btn']} ${styles['taytrom-edit-btn-cancel']}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        cancelEdit();
                                    }}
                                    aria-label="Cancel editing"
                                >
                                    <X size={14} />
                                    <span>Cancel</span>
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Content editing area with enhanced form elements */}
                    {isEditing && (
                        <div className={styles['taytrom-edit-content']}>
                            {/* Section type selector with enhanced interactive buttons */}
                            <div className={styles['taytrom-type-selector']} role="radiogroup" aria-label="Section type">
                                {Object.entries(nodeTypes).map(([type, { icon, colorClass }]) => (
                                    <button
                                        key={type}
                                        onClick={() => setEditingSection({ ...editingSection, type })}
                                        className={styles['taytrom-type-option']}
                                        style={{
                                            borderColor: editingSection.type === type ?
                                                `var(--${colorClass.replace(styles['taytrom-section-icon-'], 'tacs-node-')})` :
                                                'var(--border-moderate)',
                                            backgroundColor: editingSection.type === type ?
                                                `var(--${colorClass.replace(styles['taytrom-section-icon-'], 'tacs-node-')})20` :
                                                'transparent',
                                            color: editingSection.type === type ?
                                                `var(--${colorClass.replace(styles['taytrom-section-icon-'], 'tacs-node-')})` :
                                                'var(--substance-secondary)'
                                        }}
                                        role="radio"
                                        aria-checked={editingSection.type === type}
                                        aria-label={`Section type: ${type}`}
                                    >
                                        {icon}
                                        <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                                    </button>
                                ))}
                            </div>

                            {/* Content textarea with enhanced styling */}
                            <textarea
                                value={editingSection.content}
                                onChange={(e) => setEditingSection({ ...editingSection, content: e.target.value })}
                                className={styles['taytrom-content-textarea']}
                                placeholder="Enter section content..."
                                onClick={(e) => e.stopPropagation()}
                                aria-label="Section content"
                            />
                        </div>
                    )}
                </div>

                {/* Drop target indicator - after */}
                {isDropTarget && dropPosition === 'after' && draggingId !== section.id && (
                    <div className={styles['taytrom-drop-indicator']} />
                )}

                {/* Render children if expanded */}
                {isExpanded && hasChildSections && (
                    <div role="list" aria-label={`Children of ${section.title}`}>
                        {getChildren(section.id).map(child => renderSection(child, level + 1))}
                    </div>
                )}
            </div>
        );
    }, [
        expandedSections, selectedSection, editingSection, draggingId, dropTargetId, dropPosition,
        nodeTypes, toggleExpand, hasChildren, getChildren, scrollSectionIntoView,
        setSelectedSection, handleDragStart, handleDragOver, handleDragEnd, handleDrop,
        editSection, addSection, moveUp, moveDown, deleteSection, saveSection, cancelEdit, zoomLevel,
        showPreview, styles
    ]);

    // Get top-level steps
    const rootSections = useMemo(() => {
        return documentContent.sections
            .filter(section => section.parentId === null)
            .sort((a, b) => (a.position || 0) - (b.position || 0));
    }, [documentContent.sections]);

    return (
        <div
            ref={containerRef}
            className={`${styles['taytrom-list-editor']} ${fullscreen ? styles['taytrom-list-editor-fullscreen'] : ''}`}

            onDragOver={(e) => {
                e.preventDefault();
                // Allow dropping at root level
                if (draggingId && e.target === containerRef.current) {
                    setDropTargetId('root');
                    setDropPosition('child');
                }
            }}
            onDrop={handleDrop}
            aria-label="Document structure editor"
            role="application"
        >
            {/* Header with controls - enhanced with premium styling */}
            <div
                className={styles['taytrom-list-header']}





            >
                <div className={styles['taytrom-list-header-title']}>
                    <FileEdit size={18} className={styles['taytrom-list-header-title-icon']} />
                    <h3 className={styles['taytrom-list-header-title-text']}>
                        {showPreview ? "Structure Preview" : "Structural List"}
                    </h3>
                </div>

                <div className={styles['taytrom-list-controls']}>
                    {/* Preview toggle */}
                    <button
                        onClick={togglePreview}
                        className={styles['taytrom-control-btn']}
                        title={showPreview ? "Exit preview" : "Preview document"}
                        aria-label={showPreview ? "Exit preview" : "Preview document"}
                        aria-pressed={showPreview}
                    >
                        {showPreview ? <Edit size={16} /> : <Eye size={16} />}
                    </button>

                    {/* Zoom controls with enhanced interaction */}
                    <div className={styles['taytrom-zoom-controls']} aria-label="Zoom controls">
                        <button
                            onClick={zoomOut}
                            className={styles['taytrom-control-btn']}
                            title="Zoom out"
                            aria-label="Zoom out"
                            disabled={zoomLevel <= 0.5}
                        >
                            <ZoomOut size={14} />
                        </button>

                        <span className={styles['taytrom-zoom-level']} aria-live="polite">
                            {Math.round(zoomLevel * 100)}%
                        </span>

                        <button
                            onClick={zoomIn}
                            className={styles['taytrom-control-btn']}
                            title="Zoom in"
                            aria-label="Zoom in"
                            disabled={zoomLevel >= 2}
                        >
                            <ZoomIn size={14} />
                        </button>

                        <button
                            onClick={resetZoom}
                            className={styles['taytrom-control-btn']}
                            title="Reset zoom"
                            aria-label="Reset zoom to 100%"
                        >
                            <RotateCcw size={14} />
                        </button>
                    </div>

                    {/* Add section button with enhanced styling */}
                    {!showPreview && (
                        <button
                            className={styles['taytrom-add-btn']}
                            onClick={() => addSection(null, rootSections.length)}
                            aria-label="Add new root section"
                        >
                            <PlusCircle size={14} />
                            <span>Add Section</span>
                        </button>
                    )}

                    {/* Fullscreen toggle with enhanced animation */}
                    <button
                        onClick={toggleFullscreen}
                        className={`${styles['taytrom-control-btn']} ${styles['taytrom-fullscreen-btn']}`}
                        title={fullscreen ? "Exit fullscreen" : "Enter fullscreen"}
                        aria-label={fullscreen ? "Exit fullscreen mode" : "Enter fullscreen mode"}
                        aria-pressed={fullscreen}
                    >
                        {fullscreen ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
                    </button>
                </div>
            </div>

            {/* List container with enhanced scroll behavior */}
            <div
                className={styles['taytrom-list-container']}
                role="region"
                aria-label="Document sections"

            >
                {/* Empty state with enhanced visuals */}
                {documentContent.sections.length === 0 ? (
                    <div className={styles['taytrom-list-empty']}>
                        <FileText size={40} className={styles['taytrom-list-empty-icon']} />
                        <p className={styles['taytrom-list-empty-text']}>No sections yet. Add your first section to begin.</p>
                        <button
                            onClick={() => addSection(null, 0)}
                            className={styles['taytrom-add-btn']}
                            aria-label="Add first section"
                        >
                            <PlusCircle size={16} />
                            <span>Add First Section</span>
                        </button>
                    </div>
                ) : (
                    <>
                        {/* Root steps - enhanced with role for accessibility */}
                        <div role="list" aria-label="Root sections">
                            {rootSections.map(section => renderSection(section))}
                        </div>

                        {/* Add section button at the end - hidden in preview mode */}
                        {!showPreview && (
                            <div
                                className={styles['taytrom-add-section']}
                                onClick={() => addSection(null, rootSections.length)}
                                role="button"
                                tabIndex={0}
                                aria-label="Add new section at the end"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        e.preventDefault();
                                        addSection(null, rootSections.length);
                                    }
                                }}
                            >
                                <div className={styles['taytrom-add-section-content']}>
                                    <PlusCircle size={16} />
                                    <span>Add Section</span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>

            {/* Helper message - hidden in preview mode */}
            {!showPreview && (
                <div className={styles['taytrom-helper-message']} aria-hidden="true">
                    <Move size={14} />
                    <span>Drag and drop sections to reorganize your document</span>
                </div>
            )}

            {/* Success toast with enhanced animation */}
            <AnimatePresence>
                {successMessage && (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3, ease: [0.19, 1, 0.22, 1] }}
                        className={styles['taytrom-success-toast']}
                        role="status"
                        aria-live="polite"
                    >
                        <Check size={18} />
                        <span>{successMessage}</span>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default StructuralListVisualization;