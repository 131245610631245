import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    MessageSquare,
    Edit,
    BookOpen,
    ChevronRight,
    Info,
    Sparkles,
    X
} from 'lucide-react';
import styles from './Compiler_1_Steps_InteractiveTour_Specialists_Writing.module.css';

const Compiler_1_Steps_InteractiveTour_Specialists_Writing = ({
                                                                  onNext,
                                                                  onComplete,
                                                                  isDark = true,
                                                                  orchestrator
                                                              }) => {
    // Tour state management
    const [tourStep, setTourStep] = useState(0);
    const [showTooltip, setShowTooltip] = useState(true);
    const [catPosition, setCatPosition] = useState({ x: 100, y: 100 });
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [hasInteracted, setHasInteracted] = useState(false);
    const [documentContent, setDocumentContent] = useState(INITIAL_DOCUMENT_CONTENT);
    const [isTypingEffect, setIsTypingEffect] = useState(true);
    const [typedText, setTypedText] = useState('');

    // References for animation and interaction
    const containerRef = useRef(null);
    const documentRef = useRef(null);
    const cheshireRef = useRef(null);

    // Track mouse movement for Cheshire Cat to follow
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (containerRef.current) {
                const { left, top } = containerRef.current.getBoundingClientRect();
                setCursorPosition({
                    x: e.clientX - left,
                    y: e.clientY - top
                });
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);

    // Animate Cheshire Cat movement with slight delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setCatPosition({
                x: cursorPosition.x - 50,
                y: cursorPosition.y - 50
            });
        }, 300); // Slight delay for smoother, lazy following

        return () => clearTimeout(timer);
    }, [cursorPosition]);

    // Typing effect for introduction text
    useEffect(() => {
        if (!isTypingEffect) return;

        const text = TOUR_STEPS[tourStep].description;
        let currentIndex = 0;

        const interval = setInterval(() => {
            if (currentIndex <= text.length) {
                setTypedText(text.substring(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(interval);
                setIsTypingEffect(false);
            }
        }, 25);

        return () => clearInterval(interval);
    }, [tourStep, isTypingEffect]);

    // Reset typing effect when tour step changes
    useEffect(() => {
        setIsTypingEffect(true);
        setTypedText('');
    }, [tourStep]);

    // Play sound effect if orchestrator is available
    const playSound = (soundName) => {
        if (orchestrator && orchestrator.soundManager) {
            orchestrator.soundManager.play(soundName);
        }
    };

    const handleDocumentClick = () => {
        if (tourStep === 0) {
            playSound('interaction');
            setHasInteracted(true);
            setTimeout(() => setTourStep(1), 1000);
        }
    };

    const handleNextStep = () => {
        if (tourStep < TOUR_STEPS.length - 1) {
            playSound('pageFlip');
            setTourStep(tourStep + 1);
        } else {
            onNext && onNext();
        }
    };

    const renderSpotlight = () => {
        const step = TOUR_STEPS[tourStep];

        return (
            <motion.div
                className={styles.spotlight}
                initial={{ opacity: 0 }}
                animate={{
                    opacity: 0.7,
                    x: step.spotlightPosition?.x || 0,
                    y: step.spotlightPosition?.y || 0,
                    width: step.spotlightSize?.width || 200,
                    height: step.spotlightSize?.height || 150
                }}
                transition={{ duration: 0.5 }}
            />
        );
    };

    const renderTooltip = () => {
        const step = TOUR_STEPS[tourStep];

        return (
            <AnimatePresence>
                {showTooltip && (
                    <motion.div
                        className={styles.tooltip}
                        style={{
                            top: step.tooltipPosition?.y || '50%',
                            left: step.tooltipPosition?.x || '50%'
                        }}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className={styles.tooltipHeader}>
                            <Sparkles size={18} className={styles.tooltipIcon} />
                            <h3>{step.title}</h3>
                            <button
                                className={styles.closeButton}
                                onClick={() => setShowTooltip(false)}
                            >
                                <X size={16} />
                            </button>
                        </div>
                        <div className={styles.tooltipContent}>
                            {isTypingEffect ? (
                                <p>{typedText}<span className={styles.cursor}>|</span></p>
                            ) : (
                                <p>{step.description}</p>
                            )}
                        </div>
                        <div className={styles.tooltipFooter}>
                            <div className={styles.progress}>
                                Step {tourStep + 1} of {TOUR_STEPS.length}
                            </div>
                            <button
                                className={styles.nextButton}
                                onClick={handleNextStep}
                            >
                                {tourStep < TOUR_STEPS.length - 1 ? 'Next' : 'Finish'}
                                <ChevronRight size={16} />
                            </button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    };

    const renderCheshireCat = () => {
        return (
            <motion.div
                ref={cheshireRef}
                className={styles.cheshireCat}
                initial={{ opacity: 0, scale: 0 }}
                animate={{
                    opacity: 1,
                    scale: 1,
                    x: catPosition.x,
                    y: catPosition.y,
                }}
                transition={{
                    duration: 0.4,
                    x: { type: "spring", stiffness: 100, damping: 20 },
                    y: { type: "spring", stiffness: 100, damping: 20 },
                }}
            >
                <motion.div
                    className={styles.catFace}
                    animate={{
                        rotate: [0, 5, 0, -5, 0],
                        scale: [1, 1.05, 1, 1.05, 1]
                    }}
                    transition={{
                        duration: 5,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                >
                    <div className={styles.eyes}>
                        <motion.div
                            className={styles.eye}
                            animate={{
                                scaleY: [1, 0.2, 1],
                            }}
                            transition={{
                                duration: 3,
                                repeat: Infinity,
                                repeatType: "reverse",
                                repeatDelay: 2
                            }}
                        />
                        <motion.div
                            className={styles.eye}
                            animate={{
                                scaleY: [1, 0.2, 1],
                            }}
                            transition={{
                                duration: 3,
                                repeat: Infinity,
                                repeatType: "reverse",
                                repeatDelay: 2
                            }}
                        />
                    </div>
                    <motion.div
                        className={styles.smile}
                        animate={{
                            scale: [1, 1.1, 1],
                        }}
                        transition={{
                            duration: 2,
                            repeat: Infinity,
                            repeatType: "reverse"
                        }}
                    />
                </motion.div>

                <AnimatePresence>
                    {!showTooltip && (
                        <motion.div
                            className={styles.catTooltip}
                            initial={{ opacity: 0, scale: 0, y: -20 }}
                            animate={{ opacity: 1, scale: 1, y: 0 }}
                            exit={{ opacity: 0, scale: 0 }}
                            transition={{ duration: 0.3 }}
                            onClick={() => setShowTooltip(true)}
                        >
                            <Info size={16} />
                            <span>Need help?</span>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        );
    };

    const renderDocumentSection = () => {
        return (
            <motion.div
                className={styles.documentPanel}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                ref={documentRef}
                onClick={handleDocumentClick}
            >
                <div className={styles.documentHeader}>
                    <motion.h2
                        className={styles.documentTitle}
                        animate={{
                            color: hasInteracted ? 'var(--tacs-wonderland-gold)' : 'var(--substance-primary)',
                        }}
                        transition={{ duration: 0.5 }}
                    >
                        Down the Rabbit Hole
                    </motion.h2>
                </div>

                <div className={styles.documentContent}>
                    {documentContent.map((section, index) => (
                        <motion.div
                            key={section.id}
                            className={styles.section}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{
                                opacity: 1,
                                y: 0,
                                backgroundColor: section.isHighlighted ? 'rgba(255, 215, 0, 0.1)' : 'var(--bg-depressed)'
                            }}
                            transition={{
                                duration: 0.5,
                                delay: 0.1 * index,
                            }}
                        >
                            <div className={styles.sectionHeader}>
                                <div className={styles.sectionType}>
                                    {section.type === 'paragraph' && <Edit size={16} />}
                                    {section.type === 'heading' && <BookOpen size={16} />}
                                </div>
                                <h3 className={styles.sectionTitle}>{section.title}</h3>
                            </div>
                            <div className={styles.sectionContent}>
                                <p>{section.content}</p>
                            </div>

                            {hasInteracted && index === 0 && (
                                <motion.div
                                    className={styles.interactionEffect}
                                    initial={{ opacity: 0, scale: 0 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Sparkles size={24} className={styles.sparkle} />
                                </motion.div>
                            )}
                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };

    const renderNavigationBar = () => {
        return (
            <motion.div
                className={styles.navigationBar}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className={styles.logo}>
                    <span className={styles.logoText}>Taytrom</span>
                    <span className={styles.logoAccent}>Wonderland</span>
                </div>

                <div className={styles.navigationControls}>
                    <button className={styles.navButton}>
                        <MessageSquare size={18} />
                        <span>AI Chat</span>
                    </button>
                </div>
            </motion.div>
        );
    };

    const renderFloatingElements = () => {
        return (
            <>
                <motion.div
                    className={styles.floatingMushroom}
                    animate={{
                        y: [0, -15, 0],
                        rotate: [0, 5, 0, -5, 0]
                    }}
                    transition={{
                        duration: 8,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                />

                <motion.div
                    className={styles.floatingCards}
                    animate={{
                        y: [0, -20, 0],
                        rotate: [0, 10, 0]
                    }}
                    transition={{
                        duration: 10,
                        repeat: Infinity,
                        repeatType: "reverse",
                        delay: 2
                    }}
                />

                <motion.div
                    className={styles.floatingButterfly}
                    animate={{
                        x: [0, 50, -30, 0],
                        y: [0, -30, -10, 0],
                        rotate: [0, 10, -10, 0]
                    }}
                    transition={{
                        duration: 15,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                />
            </>
        );
    };

    return (
        <div
            className={`${styles.container} ${isDark ? styles.darkTheme : styles.lightTheme}`}
            ref={containerRef}
        >
            {renderNavigationBar()}

            <div className={styles.mainContent}>
                {renderDocumentSection()}

                <motion.div
                    className={styles.rightPanel}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.3 }}
                >
                    <div className={styles.topRightPanel}>
                        <h3>Visualization Panel</h3>
                        <div className={styles.comingSoon}>
                            <span>We'll explore this in Step 3</span>
                        </div>
                    </div>

                    <div className={styles.bottomRightPanel}>
                        <h3>AI Suggestions</h3>
                        <div className={styles.comingSoon}>
                            <span>We'll explore this in Step 5</span>
                        </div>
                    </div>
                </motion.div>
            </div>

            {renderFloatingElements()}
            {renderCheshireCat()}
            {renderSpotlight()}
            {renderTooltip()}

            <div className={styles.tourProgress}>
                {TOUR_STEPS.map((_, index) => (
                    <div
                        key={index}
                        className={`${styles.tourStep} ${index === tourStep ? styles.active : ''} ${index < tourStep ? styles.completed : ''}`}
                        onClick={() => setTourStep(index)}
                    />
                ))}
            </div>
        </div>
    );
};

// Sample content for the initial document
const INITIAL_DOCUMENT_CONTENT = [
    {
        id: '1',
        type: 'heading',
        title: 'Chapter One',
        content: 'Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do...',
        isHighlighted: true
    },
    {
        id: '2',
        type: 'paragraph',
        title: 'The Rabbit Appears',
        content: 'There was nothing so very remarkable in that; nor did Alice think it so very much out of the way to hear the Rabbit say to itself, "Oh dear! Oh dear! I shall be late!"',
        isHighlighted: false
    },
    {
        id: '3',
        type: 'paragraph',
        title: 'Following the Rabbit',
        content: 'When the Rabbit actually took a watch out of its waistcoat-pocket, and looked at it, and then hurried on, Alice started to her feet...',
        isHighlighted: false
    }
];

// Tour step definitions
const TOUR_STEPS = [
    {
        title: "Welcome to Taytrom's Wonderland!",
        description: "I'm the Cheshire Cat, and I'll guide you through this marvelous writing platform. Click on the document to begin our adventure!",
        spotlightPosition: { x: 200, y: 180 },
        spotlightSize: { width: 400, height: 150 },
        tooltipPosition: { x: '30%', y: '30%' }
    },
    {
        title: "The Document Canvas",
        description: "This is where your writing comes to life! Each section can be edited, reordered, and styled. Notice how sections are organized with titles and content - just like chapters in Alice's story.",
        spotlightPosition: { x: 250, y: 250 },
        spotlightSize: { width: 450, height: 300 },
        tooltipPosition: { x: '60%', y: '40%' }
    },
    {
        title: "AI Assistance at Your Fingertips",
        description: "The AI Assistant helps you craft your story. Soon, you'll see how it can suggest improvements, expand your ideas, or help rewrite sections - all with a simple drag-and-drop.",
        spotlightPosition: { x: 650, y: 400 },
        spotlightSize: { width: 250, height: 200 },
        tooltipPosition: { x: '70%', y: '60%' }
    },
    {
        title: "Ready to Continue?",
        description: "You've learned the basics of the Document Canvas. In our next step, we'll explore how to edit and format your text. Curiouser and curiouser!",
        spotlightPosition: { x: 400, y: 300 },
        spotlightSize: { width: 500, height: 350 },
        tooltipPosition: { x: '50%', y: '50%' }
    }
];

export default Compiler_1_Steps_InteractiveTour_Specialists_Writing;