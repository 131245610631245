import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Save, Download, Upload, Undo, Redo,
    ChevronLeft, ChevronRight, ChevronUp, ChevronDown, Trash,
    FileText, X, RefreshCw, Brain, Zap, Wrench, Check
} from 'lucide-react';
import KeyboardShortcuts_Specialists_Writing from "@/writing-scope/specialists/utils/keyboard-shortcuts/KeyboardShortcuts_Specialists_Writing.jsx";
import styles from './ExpandableToolbar_Utils_Specialists_Writing.module.css';

/**
 * Expandable Toolbar Component
 * A collapsible toolbar that houses global document actions
 * Fully complies with Taytrom Enhanced CSS System - The Definitive Styling Mandate
 *
 * @param {Object} props - Component props
 * @param {Function} props.onClear - Callback for clearing the document
 * @param {Function} props.onImport - Callback for importing a document
 * @param {Function} props.onExport - Callback for exporting the document
 * @param {Function} props.onUndo - Callback for undo operation
 * @param {Function} props.onRedo - Callback for redo operation
 * @param {Function} props.onSave - Callback for saving the document
 * @param {Boolean} props.canUndo - Whether the undo operation is available
 * @param {Boolean} props.canRedo - Whether the redo operation is available
 * @param {Function} props.onToggleAI - Callback for toggling the AI assistant
 * @param {Boolean} props.isAIOpen - Whether the AI assistant is currently open
 * @param {Boolean} props.isSaving - Whether a save operation is in progress
 * @param {Boolean} props.isDark - Whether dark mode is enabled
 * @param {String} props.position - Toolbar position ('left', 'top', 'right', 'header')
 * @param {Boolean} props.defaultExpanded - Whether the toolbar is expanded by default
 * @param {Boolean} props.showKeyboardShortcutsButton - Whether to show keyboard shortcuts button
 * @param {Function} props.onShortcutTriggered - Callback when a shortcut is triggered
 * @param {Boolean} props.allowKeyboardCustomization - Whether to allow keyboard shortcut customization
 * @param {Object} props.orchestrator - Orchestrator object for keyboard shortcuts
 * @returns {React.Element} The Expandable Toolbar component
 */
const ExpandableToolbar_Utils_Specialists_Writing = ({
                                                         onClear,
                                                         onImport,
                                                         onExport,
                                                         onUndo,
                                                         onRedo,
                                                         onSave,
                                                         canUndo = false,
                                                         canRedo = false,
                                                         onToggleAI,
                                                         isAIOpen = false,
                                                         isSaving = false,
                                                         isDark = false,
                                                         position = 'left',
                                                         defaultExpanded = true,
                                                         showKeyboardShortcutsButton = true,
                                                         onShortcutTriggered = () => {},
                                                         allowKeyboardCustomization = false,
                                                         orchestrator = null
                                                     }) => {
    // Refs for handling click outside
    const importDropdownRef = useRef(null);
    const exportDropdownRef = useRef(null);

    // State for toolbar expansion
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    // State for import/export dropdowns
    const [showImportOptions, setShowImportOptions] = useState(false);
    const [showExportOptions, setShowExportOptions] = useState(false);

    // State for save feedback
    const [showSaveFeedback, setShowSaveFeedback] = useState(false);
    const [lastSaved, setLastSaved] = useState(null);

    // Handle save feedback animation
    useEffect(() => {
        if (isSaving) {
            // When saving starts, reset feedback
            setShowSaveFeedback(false);
        } else if (showSaveFeedback === false && isSaving === false && lastSaved !== null) {
            // When saving completes, show feedback
            setShowSaveFeedback(true);

            // Hide feedback after animation duration
            const timer = setTimeout(() => {
                setShowSaveFeedback(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isSaving, showSaveFeedback, lastSaved]);

    // Close dropdowns when toolbar collapses
    useEffect(() => {
        if (!isExpanded) {
            setShowImportOptions(false);
            setShowExportOptions(false);
        }
    }, [isExpanded]);

    // Handle save action with feedback
    const handleSave = useCallback(() => {
        if (onSave) {
            onSave();
            setLastSaved(new Date());
            // Feedback will be handled by the useEffect above when isSaving changes
        }
    }, [onSave]);

    // Keyboard shortcut handlers
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Ctrl/Cmd + S to save
            if ((e.ctrlKey || e.metaKey) && e.key === 's' && onSave) {
                e.preventDefault();
                handleSave();
                return;
            }

            // Ctrl/Cmd + Z to undo
            if ((e.ctrlKey || e.metaKey) && !e.shiftKey && e.key === 'z' && onUndo && canUndo) {
                e.preventDefault();
                onUndo();
                return;
            }

            // Ctrl/Cmd + Shift + Z or Ctrl/Cmd + Y to redo
            if (((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'z') ||
                ((e.ctrlKey || e.metaKey) && e.key === 'y')) {
                if (onRedo && canRedo) {
                    e.preventDefault();
                    onRedo();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onSave, onUndo, onRedo, canUndo, canRedo, handleSave]);

    // Toggle toolbar expansion
    const toggleExpansion = () => setIsExpanded(!isExpanded);

    // Get toggle icon based on position and state
    const getToggleIcon = () => {
        if (position === 'header') {
            return isExpanded ? <X size={16} /> : <Wrench size={16} />;
        } else if (position === 'left') {
            return isExpanded ? <ChevronLeft size={16} /> : <ChevronRight size={16} />;
        } else if (position === 'top') {
            return isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />;
        } else if (position === 'right') {
            return isExpanded ? <ChevronRight size={16} /> : <ChevronLeft size={16} />;
        }

        return isExpanded ? <ChevronLeft size={16} /> : <ChevronRight size={16} />; // Default
    };

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (importDropdownRef.current &&
                !importDropdownRef.current.contains(e.target)) {
                setShowImportOptions(false);
            }

            if (exportDropdownRef.current &&
                !exportDropdownRef.current.contains(e.target)) {
                setShowExportOptions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Get toolbar class names based on position and theme
    const getToolbarClassNames = () => {
        const baseClasses = [styles.expandableToolbar];

        // Add theme-specific class
        baseClasses.push(isDark ? styles.expandableToolbarDark : styles.expandableToolbarLight);

        // Add position-specific class if needed
        if (position === 'left' && !isExpanded) {
            baseClasses.push(styles.expandableToolbarLeftCollapsed);
        } else if (position === 'right' && !isExpanded) {
            baseClasses.push(styles.expandableToolbarRightCollapsed);
        } else if (position === 'top' && !isExpanded) {
            baseClasses.push(styles.expandableToolbarTopCollapsed);
        }

        return baseClasses.join(' ');
    };

    // Get toggle button class names
    const getToggleClassNames = () => {
        const baseClasses = [styles.toolbarToggle];

        // Add theme-specific class
        baseClasses.push(isDark ? styles.toolbarToggleDark : styles.toolbarToggleLight);

        // Add position-specific class if needed
        if (position === 'header') {
            baseClasses.push(styles.headerToggle);
            baseClasses.push(isDark ? styles.headerToggleDark : styles.headerToggleLight);
        }

        return baseClasses.join(' ');
    };

    // Get content class names
    const getContentClassNames = () => {
        const baseClasses = [styles.toolbarContent];

        // Add position-specific class if needed
        if (position === 'top') {
            baseClasses.push(styles.toolbarContentTop);
        } else if (position === 'header') {
            baseClasses.push(styles.toolbarContentHeader);
        }

        return baseClasses.join(' ');
    };

    // Get position styles for the toolbar (CSS modules don't handle everything, so some inline styles remain)
    const getPositionStyles = () => {
        if (position === 'left') {
            return {
                top: '70px',
                left: isExpanded ? '0' : '-60px',
                maxHeight: 'calc(100vh - 140px)',
                overflowY: 'auto'
            };
        } else if (position === 'right') {
            return {
                top: '70px',
                right: isExpanded ? '0' : '-60px',
                maxHeight: 'calc(100vh - 140px)',
                overflowY: 'auto'
            };
        } else if (position === 'top') {
            return {
                top: isExpanded ? '0' : '-60px',
                left: '50%',
                transform: 'translateX(-50%)'
            };
        } else if (position === 'header') {
            return {
                position: 'relative',
                backgroundColor: isExpanded ? undefined : 'transparent',
                boxShadow: isExpanded ? undefined : 'none'
            };
        }

        return {};
    };

    // Button class generator
    const getButtonClasses = (baseClass, isDarkTheme) => {
        return `${styles.toolbarButton} ${isDarkTheme ? styles.toolbarButtonDark : styles.toolbarButtonLight} ${baseClass || ''}`;
    };

    // Dropdown item class generator
    const getDropdownItemClasses = (isDarkTheme) => {
        return `${styles.dropdownItem} ${isDarkTheme ? styles.dropdownItemDark : styles.dropdownItemLight}`;
    };

    // Get save feedback tooltip position
    const getSaveFeedbackPosition = () => {
        if (position === 'left') {
            return {
                right: '-140px',
                left: 'auto',
                top: '50%',
            };
        } else if (position === 'right') {
            return {
                left: '-140px',
                right: 'auto',
                top: '50%',
            };
        } else if (position === 'top') {
            return {
                left: '50%',
                top: '100%',
                transform: 'translateX(-50%)'
            };
        } else if (position === 'header') {
            return {
                right: '0',
                top: '100%',
            };
        }

        return {};
    };

    // Main render
    return (
        <div className={getToolbarClassNames()} style={getPositionStyles()}>
            {/* Toggle button */}
            <div
                className={getToggleClassNames()}
                onClick={toggleExpansion}
                role="button"
                tabIndex={0}
                aria-label={isExpanded ? "Collapse toolbar" : "Expand toolbar"}
                aria-expanded={isExpanded}
            >
                {getToggleIcon()}
            </div>

            {/* Toolbar content */}
            {isExpanded && (
                <div className={getContentClassNames()}>
                    {/* Clear button */}
                    <button
                        className={getButtonClasses(null, isDark)}
                        onClick={onClear}
                        title="Clear document"
                        aria-label="Clear document"
                    >
                        <Trash size={16} />
                        <span>Clear</span>
                    </button>

                    {/* Import button with dropdown */}
                    <div className={styles.toolbarDropdownContainer} ref={importDropdownRef}>
                        <button
                            className={getButtonClasses(styles.toolbarDropdownTrigger, isDark)}
                            onClick={() => {
                                setShowImportOptions(!showImportOptions);
                                setShowExportOptions(false);
                            }}
                            title="Import document"
                            aria-label="Import document"
                            aria-haspopup="true"
                            aria-expanded={showImportOptions}
                        >
                            <Upload size={16} />
                            <span>Import</span>
                        </button>

                        {showImportOptions && (
                            <div
                                className={`${styles.toolbarDropdownContent} ${isDark ? styles.toolbarDropdownContentDark : styles.toolbarDropdownContentLight}`}
                                role="menu"
                                aria-label="Import options"
                            >
                                <button
                                    className={getDropdownItemClasses(isDark)}
                                    onClick={() => {
                                        if (onImport) onImport('docx');
                                        setShowImportOptions(false);
                                    }}
                                    role="menuitem"
                                >
                                    <FileText size={14} />
                                    <span>Import Word (.docx)</span>
                                </button>
                                <button
                                    className={getDropdownItemClasses(isDark)}
                                    onClick={() => {
                                        if (onImport) onImport('markdown');
                                        setShowImportOptions(false);
                                    }}
                                    role="menuitem"
                                >
                                    <FileText size={14} />
                                    <span>Import Markdown (.md)</span>
                                </button>
                                <button
                                    className={getDropdownItemClasses(isDark)}
                                    onClick={() => {
                                        if (onImport) onImport('text');
                                        setShowImportOptions(false);
                                    }}
                                    role="menuitem"
                                >
                                    <FileText size={14} />
                                    <span>Import Text (.txt)</span>
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Export button with dropdown */}
                    <div className={styles.toolbarDropdownContainer} ref={exportDropdownRef}>
                        <button
                            className={getButtonClasses(styles.toolbarDropdownTrigger, isDark)}
                            onClick={() => {
                                setShowExportOptions(!showExportOptions);
                                setShowImportOptions(false);
                            }}
                            title="Export document"
                            aria-label="Export document"
                            aria-haspopup="true"
                            aria-expanded={showExportOptions}
                        >
                            <Download size={16} />
                            <span>Export</span>
                        </button>

                        {showExportOptions && (
                            <div
                                className={`${styles.toolbarDropdownContent} ${isDark ? styles.toolbarDropdownContentDark : styles.toolbarDropdownContentLight}`}
                                role="menu"
                                aria-label="Export options"
                            >
                                <button
                                    className={getDropdownItemClasses(isDark)}
                                    onClick={() => {
                                        if (onExport) onExport('docx');
                                        setShowExportOptions(false);
                                    }}
                                    role="menuitem"
                                >
                                    <FileText size={14} />
                                    <span>Export as Word (.docx)</span>
                                </button>
                                <button
                                    className={getDropdownItemClasses(isDark)}
                                    onClick={() => {
                                        if (onExport) onExport('pdf');
                                        setShowExportOptions(false);
                                    }}
                                    role="menuitem"
                                >
                                    <FileText size={14} />
                                    <span>Export as PDF (.pdf)</span>
                                </button>
                                <button
                                    className={getDropdownItemClasses(isDark)}
                                    onClick={() => {
                                        if (onExport) onExport('markdown');
                                        setShowExportOptions(false);
                                    }}
                                    role="menuitem"
                                >
                                    <FileText size={14} />
                                    <span>Export as Markdown (.md)</span>
                                </button>
                                <button
                                    className={getDropdownItemClasses(isDark)}
                                    onClick={() => {
                                        if (onExport) onExport('html');
                                        setShowExportOptions(false);
                                    }}
                                    role="menuitem"
                                >
                                    <FileText size={14} />
                                    <span>Export as HTML (.html)</span>
                                </button>
                                <button
                                    className={getDropdownItemClasses(isDark)}
                                    onClick={() => {
                                        if (onExport) onExport('text');
                                        setShowExportOptions(false);
                                    }}
                                    role="menuitem"
                                >
                                    <FileText size={14} />
                                    <span>Export as Text (.txt)</span>
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Undo button */}
                    <button
                        className={getButtonClasses(null, isDark)}
                        onClick={onUndo}
                        disabled={!canUndo}
                        title="Undo (Ctrl+Z)"
                        aria-label="Undo"
                    >
                        <Undo size={16} />
                        <span>Undo</span>
                    </button>

                    {/* Redo button */}
                    <button
                        className={getButtonClasses(null, isDark)}
                        onClick={onRedo}
                        disabled={!canRedo}
                        title="Redo (Ctrl+Y)"
                        aria-label="Redo"
                    >
                        <Redo size={16} />
                        <span>Redo</span>
                    </button>

                    {/* Save button */}
                    <button
                        className={`${styles.toolbarButton} ${styles.saveButton} ${isDark ? styles.saveButtonDark : styles.saveButtonLight}`}
                        onClick={handleSave}
                        disabled={isSaving}
                        title="Save document (Ctrl+S)"
                        aria-label={isSaving ? "Saving document" : "Save document"}
                    >
                        {isSaving ? (
                            <RefreshCw size={16} className={styles.spinAnimation} />
                        ) : (
                            <Save size={16} />
                        )}
                        <span>
                            {isSaving ? 'Saving...' : 'Save'}
                        </span>
                    </button>

                    {/* AI Assistant button */}
                    {onToggleAI && (
                        <button
                            className={`${styles.toolbarButton} ${styles.aiButton} ${isDark ? styles.aiButtonDark : styles.aiButtonLight} ${isAIOpen ? styles.aiButtonActive : ''}`}
                            onClick={onToggleAI}
                            title="AI Writing Assistant"
                            aria-label="Toggle AI Writing Assistant"
                            aria-pressed={isAIOpen}
                        >
                            <Brain size={16} />
                            <span>AI Assistant</span>
                        </button>
                    )}

                    {/* Keyboard Shortcuts button */}
                    {showKeyboardShortcutsButton && (
                        <KeyboardShortcuts_Specialists_Writing
                            isDark={isDark}
                            allowCustomization={allowKeyboardCustomization}
                            onShortcutTriggered={onShortcutTriggered}
                            onShortcutChanged={() => console.log('Shortcuts updated')}
                            orchestrator={orchestrator}
                            className={`${styles.toolbarButton} ${styles.keyboardShortcutButton} ${isDark ? styles.keyboardShortcutButtonDark : styles.keyboardShortcutButtonLight}`}
                        />
                    )}
                </div>
            )}

            {/* Save feedback tooltip */}
            {showSaveFeedback && (
                <div
                    className={styles.saveFeedbackTooltip}
                    style={getSaveFeedbackPosition()}
                    role="status"
                    aria-live="polite"
                >
                    <Check size={14} />
                    <span>Document saved</span>
                </div>
            )}
        </div>
    );
};

export default ExpandableToolbar_Utils_Specialists_Writing;