import React, { useState, useEffect } from 'react';

/**
 * Taytrom TACS Pillar SVG Component
 *
 * A breathtaking visualization of Taytrom's TACS pillar featuring:
 * - Hexagonal architecture representing the universal language
 * - Dynamic visualization of the 5 interconnected principles (STACK, CSS, SPACE, TIES, AOS)
 * - Animated connection lines and data flows
 * - Interactive elements
 *
 * @param {Object} props Component properties
 * @param {string|number} props.size Size of the SVG (default: "100%")
 * @param {string} props.primaryColor Primary color (default: "#7C3AED")
 * @param {string} props.secondaryColor Secondary color (default: "#2563EB")
 * @param {string} props.accentColor Accent color (default: "#F97316")
 * @param {string} props.className Additional CSS classes
 * @param {Object} props.style Additional inline styles
 * @param {string} props.title SVG title for accessibility (default: "Taytrom TACS Pillar")
 * @param {boolean} props.animate Enable animations (default: true)
 * @param {boolean} props.interactive Enable hover effects (default: true)
 * @param {boolean} props.glowEffect Enable glow effect (default: true)
 * @param {number} props.animationSpeed Animation speed multiplier (default: 1)
 * @param {boolean} props.reducedMotion Respect reduced motion preferences (default: true)
 */
const TaytromTACSPillarSVG = ({
                                  size = "100%",
                                  primaryColor = "#7C3AED",
                                  secondaryColor = "#2563EB",
                                  accentColor = "#F97316",
                                  className = "",
                                  style = {},
                                  title = "Taytrom TACS Pillar",
                                  animate = true,
                                  interactive = true,
                                  glowEffect = true,
                                  animationSpeed = 1,
                                  reducedMotion = true,
                                  ...props
                              }) => {
    // Initialize state for hover effects
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredElement, setHoveredElement] = useState(null);

    // State to track if user prefers reduced motion
    const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

    // Check for reduced motion preference
    useEffect(() => {
        if (typeof window !== 'undefined' && reducedMotion) {
            const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
            setPrefersReducedMotion(mediaQuery.matches);

            const handleChange = () => setPrefersReducedMotion(mediaQuery.matches);
            mediaQuery.addEventListener('change', handleChange);

            return () => mediaQuery.removeEventListener('change', handleChange);
        }
    }, [reducedMotion]);

    // Determine if animations should be active
    const animationsEnabled = animate && !prefersReducedMotion;

    // Calculate animation durations based on speed
    const baseDuration = 10 / animationSpeed;
    const pulseDuration = 4 / animationSpeed;
    const flowDuration = 6 / animationSpeed;
    const particleDuration = 3 / animationSpeed;

    // Define TACS principles with colors and positions
    const tacsPrinciples = [
        { id: "stack", name: "STACK", desc: "Cognitive Topology", color: secondaryColor, x: 400, y: 200 },
        { id: "css", name: "CSS", desc: "Triadic Separation", color: accentColor, x: 550, y: 300 },
        { id: "space", name: "SPACE", desc: "Dimensional Organization", color: "#10B981", x: 470, y: 430 },
        { id: "ties", name: "TIES", desc: "Universal Communication", color: "#EF4444", x: 320, y: 430 },
        { id: "aos", name: "AOS", desc: "Constitutional Monarchy", color: "#8B5CF6", x: 250, y: 300 }
    ];

    // Function to create hexagon points
    const createHexagonPoints = (centerX, centerY, radius) => {
        const points = [];
        for (let i = 0; i < 6; i++) {
            const angle = (Math.PI / 3) * i;
            const x = centerX + radius * Math.cos(angle);
            const y = centerY + radius * Math.sin(angle);
            points.push({ x, y });
        }
        return points;
    };

    // Create central and outer hexagons
    const centerHex = createHexagonPoints(400, 300, 80);
    const outerHex = createHexagonPoints(400, 300, 150);

    // Formatted points strings for SVG
    const centerHexPoints = centerHex.map(p => `${p.x},${p.y}`).join(' ');
    const outerHexPoints = outerHex.map(p => `${p.x},${p.y}`).join(' ');

    // Create connections between principles
    const connections = [
        { from: "stack", to: "css" },
        { from: "css", to: "space" },
        { from: "space", to: "ties" },
        { from: "ties", to: "aos" },
        { from: "aos", to: "stack" },
        // Cross connections
        { from: "stack", to: "ties" },
        { from: "css", to: "aos" },
        { from: "space", to: "stack" }
    ];

    // Helper function to find principle by id
    const getPrinciple = (id) => tacsPrinciples.find(p => p.id === id);

    // Handle element hover
    const handleElementHover = (id) => {
        if (interactive) {
            setHoveredElement(id);
        }
    };

    // Handle element mouse leave
    const handleElementLeave = () => {
        if (interactive) {
            setHoveredElement(null);
        }
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            {/* Definitions for gradients, filters and patterns */}
            <defs>
                <radialGradient id="backgroundGlow" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" stop-color="#FFF3E0" />
                    <stop offset="90%" stop-color="#FFF0D9" />
                    <stop offset="100%" stop-color="#FFE8CC" />
                </radialGradient>

                <linearGradient id="stoneGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#FFB74D" />
                    <stop offset="50%" stop-color="#FFA726" />
                    <stop offset="100%" stop-color="#FF9800" />
                </linearGradient>

                <linearGradient id="darkStoneGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#F57C00" />
                    <stop offset="50%" stop-color="#EF6C00" />
                    <stop offset="100%" stop-color="#E65100" />
                </linearGradient>

                <linearGradient id="glowGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#FFD180" />
                    <stop offset="50%" stop-color="#FFAB40" />
                    <stop offset="100%" stop-color="#FF9100" />
                </linearGradient>

                <linearGradient id="humanGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#FFE0B2" />
                    <stop offset="100%" stop-color="#FFCC80" />
                </linearGradient>

                <linearGradient id="aiGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#FB8C00" />
                    <stop offset="100%" stop-color="#F57C00" />
                </linearGradient>

                <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                    <feColorMatrix in="blur" type="matrix" values="0 0 0 0 1 0 0 0 0 0.7 0 0 0 0 0.2 0 0 0 1 0" result="glow" />
                    <feBlend in="SourceGraphic" in2="glow" mode="screen" />
                </filter>

                <filter id="textGlow" x="-20%" y="-20%" width="140%" height="140%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
                    <feColorMatrix in="blur" type="matrix" values="0 0 0 0 1 0 0 0 0 0.7 0 0 0 0 0.2 0 0 0 1 0" result="glow" />
                    <feBlend in="SourceGraphic" in2="glow" mode="screen" />
                </filter>

                <pattern id="stoneTexture" patternUnits="userSpaceOnUse" width="100" height="100">
                    <rect width="100" height="100" fill="#FF9800" />
                    <rect width="50" height="50" fill="#FFA726" opacity="0.3" />
                    <rect x="50" y="50" width="50" height="50" fill="#FFA726" opacity="0.3" />
                    <circle cx="25" cy="25" r="10" fill="#FFB74D" opacity="0.3" />
                    <circle cx="75" cy="75" r="10" fill="#FFB74D" opacity="0.3" />
                    <circle cx="75" cy="25" r="8" fill="#F57C00" opacity="0.3" />
                    <circle cx="25" cy="75" r="8" fill="#F57C00" opacity="0.3" />
                </pattern>

                <pattern id="humanPattern" patternUnits="userSpaceOnUse" width="10" height="10">
                    <rect width="10" height="10" fill="none" />
                    <path d="M0,5 L10,5 M5,0 L5,10" stroke="#FFE0B2" stroke-width="0.5" opacity="0.5" />
                </pattern>

                <pattern id="aiPattern" patternUnits="userSpaceOnUse" width="10" height="10">
                    <rect width="10" height="10" fill="none" />
                    <path d="M0,0 L10,10 M10,0 L0,10" stroke="#F57C00" stroke-width="0.5" opacity="0.5" />
                </pattern>
            </defs>

            {/* Background */}
            <circle cx="400" cy="400" r="350" fill="url(#backgroundGlow)" stroke="#E65100" stroke-width="5" />

            {/* Energy field around the stone */}
            <circle cx="400" cy="400" r="280" fill="#FF9800" opacity="0.1">
                <animate attributeName="r" values="280;300;280" dur="10s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.1;0.2;0.1" dur="10s" repeatCount="indefinite" />
            </circle>

            {/* Main Rosetta Stone Structure */}
            <path d="M250,150 L550,150 L520,650 L280,650 Z" fill="url(#stoneTexture)" stroke="#E65100" stroke-width="2" />
            <path d="M250,150 L550,150 L520,650 L280,650 Z" fill="url(#stoneGradient)" fill-opacity="0.7" />

            {/* Stone Damage and Texture */}
            <path d="M250,150 L270,170 L530,170 L550,150" fill="#E65100" opacity="0.3" />
            <path d="M250,150 L260,210 L270,170" fill="#E65100" opacity="0.2" />
            <path d="M550,150 L540,220 L530,170" fill="#E65100" opacity="0.2" />
            <path d="M280,650 L290,600 L510,600 L520,650" fill="#E65100" opacity="0.3" />

            <path d="M270,250 C280,245 295,255 290,260" fill="#E65100" opacity="0.4" />
            <path d="M510,280 C520,275 525,290 515,290" fill="#E65100" opacity="0.4" />
            <path d="M300,600 C310,590 325,595 320,605" fill="#E65100" opacity="0.4" />
            <path d="M490,400 C500,390 510,400 505,410" fill="#E65100" opacity="0.4" />
            <path d="M310,350 C320,340 330,345 325,355" fill="#E65100" opacity="0.4" />

            {/* Dividing Lines on Stone */}
            <path d="M270,250 L530,250" stroke="#E65100" stroke-width="2" fill="none" />
            <path d="M275,350 L525,350" stroke="#E65100" stroke-width="2" fill="none" />
            <path d="M280,450 L520,450" stroke="#E65100" stroke-width="2" fill="none" />
            <path d="M285,550 L515,550" stroke="#E65100" stroke-width="2" fill="none" />

            <path d="M400,170 L400,600" stroke="#E65100" stroke-width="2" stroke-dasharray="5,5" fill="none" />

            {/* TACS Title */}
            <text x="400" y="210" text-anchor="middle" fill="#FFF3E0" font-size="40" font-weight="bold" filter="url(#textGlow)">TACS</text>
            <text x="400" y="230" text-anchor="middle" fill="#FFF3E0" font-size="16">Universal Language</text>

            {/* STACK Section */}
            <g transform="translate(0, 250)">
                <text x="335" y="30" text-anchor="end" fill="#FFF3E0" font-size="18" font-weight="bold">STACK</text>

                {/* Human Language Side */}
                <rect x="280" y="10" width="110" height="70" fill="url(#humanGradient)" opacity="0.7" rx="5" />
                <text x="335" y="30" text-anchor="middle" fill="#E65100" font-size="12">Human Understanding</text>
                <path d="M290,40 L370,40 M290,50 L340,50 M290,60 L360,60 M290,70 L350,70" stroke="#E65100" stroke-width="1" />

                {/* AI Language Side */}
                <rect x="410" y="10" width="110" height="70" fill="url(#aiGradient)" opacity="0.7" rx="5" />
                <text x="465" y="30" text-anchor="middle" fill="#FFF3E0" font-size="12">AI Understanding</text>
                <path d="M420,45 L500,45 L420,55 L500,55 L420,65 L500,65" stroke="#FFF3E0" stroke-width="1" />

                {/* Translation Beams */}
                <path d="M340,45 C360,45 380,45 400,45 C420,45 440,45 460,55" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="3s" repeatCount="indefinite" />
                </path>
                <path d="M340,65 C360,65 380,65 400,65 C420,65 440,65 460,65" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="4s" repeatCount="indefinite" />
                </path>
            </g>

            {/* CSS Section */}
            <g transform="translate(0, 350)">
                <text x="335" y="30" text-anchor="end" fill="#FFF3E0" font-size="18" font-weight="bold">CSS</text>

                {/* Human Language Side */}
                <rect x="280" y="10" width="110" height="70" fill="url(#humanGradient)" opacity="0.7" rx="5" />
                <text x="335" y="30" text-anchor="middle" fill="#E65100" font-size="12">Human Understanding</text>
                <path d="M290,40 L370,40 M310,50 L370,50 M290,60 L350,60 M310,70 L370,70" stroke="#E65100" stroke-width="1" />

                {/* AI Language Side */}
                <rect x="410" y="10" width="110" height="70" fill="url(#aiGradient)" opacity="0.7" rx="5" />
                <text x="465" y="30" text-anchor="middle" fill="#FFF3E0" font-size="12">AI Understanding</text>
                <path d="M420,45 L440,45 L480,45 L480,55 L440,55 L420,55 L420,65 L480,65" stroke="#FFF3E0" stroke-width="1" />

                {/* Translation Beams */}
                <path d="M340,45 C360,45 380,45 400,45 C420,45 440,45 460,50" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="3.5s" repeatCount="indefinite" />
                </path>
                <path d="M340,60 C360,60 380,60 400,60 C420,60 440,60 460,60" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="4.5s" repeatCount="indefinite" />
                </path>
            </g>

            {/* SPACE Section */}
            <g transform="translate(0, 450)">
                <text x="335" y="30" text-anchor="end" fill="#FFF3E0" font-size="18" font-weight="bold">SPACE</text>

                {/* Human Language Side */}
                <rect x="280" y="10" width="110" height="70" fill="url(#humanGradient)" opacity="0.7" rx="5" />
                <text x="335" y="30" text-anchor="middle" fill="#E65100" font-size="12">Human Understanding</text>
                <path d="M290,45 C310,45 330,35 350,45 C370,55 330,65 350,65" stroke="#E65100" stroke-width="1" fill="none" />

                {/* AI Language Side */}
                <rect x="410" y="10" width="110" height="70" fill="url(#aiGradient)" opacity="0.7" rx="5" />
                <text x="465" y="30" text-anchor="middle" fill="#FFF3E0" font-size="12">AI Understanding</text>
                <rect x="430" y="45" width="20" height="20" stroke="#FFF3E0" stroke-width="1" fill="none" />
                <rect x="460" y="45" width="10" height="10" stroke="#FFF3E0" stroke-width="1" fill="none" />
                <rect x="480" y="45" width="15" height="25" stroke="#FFF3E0" stroke-width="1" fill="none" />

                {/* Translation Beams */}
                <path d="M340,50 C360,50 380,50 400,50 C420,50 440,50 460,55" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="4s" repeatCount="indefinite" />
                </path>
                <path d="M340,60 C360,60 380,60 400,60 C420,60 440,60 470,65" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="3.2s" repeatCount="indefinite" />
                </path>
            </g>

            {/* TIES Section */}
            <g transform="translate(0, 550)">
                <text x="335" y="30" text-anchor="end" fill="#FFF3E0" font-size="18" font-weight="bold">TIES</text>

                {/* Human Language Side */}
                <rect x="280" y="10" width="110" height="70" fill="url(#humanGradient)" opacity="0.7" rx="5" />
                <text x="335" y="30" text-anchor="middle" fill="#E65100" font-size="12">Human Understanding</text>
                <path d="M290,50 L370,50 M290,60 L330,60 L330,70 L370,70" stroke="#E65100" stroke-width="1" />

                {/* AI Language Side */}
                <rect x="410" y="10" width="110" height="70" fill="url(#aiGradient)" opacity="0.7" rx="5" />
                <text x="465" y="30" text-anchor="middle" fill="#FFF3E0" font-size="12">AI Understanding</text>
                <path d="M430,50 L450,50 M460,50 L480,50 M430,60 L440,60 L440,70 L430,70 M460,60 L470,60 L470,70 L460,70" stroke="#FFF3E0" stroke-width="1" />

                {/* Translation Beams */}
                <path d="M340,50 C360,50 380,50 400,50 C420,50 440,50 460,50" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="2.5s" repeatCount="indefinite" />
                </path>
                <path d="M340,65 C360,65 380,65 400,65 C420,65 440,65 460,65" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="3.8s" repeatCount="indefinite" />
                </path>
            </g>

            {/* AOS Section at the bottom */}
            <g transform="translate(240, 620)">
                <rect x="60" y="10" width="200" height="30" fill="url(#darkStoneGradient)" opacity="0.9" rx="5" />
                <text x="160" y="30" text-anchor="middle" fill="#FFF3E0" font-size="18" font-weight="bold">AOS</text>

                {/* Connection lines from all steps */}
                <path d="M100,0 L100,-60" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="3s" repeatCount="indefinite" />
                </path>
                <path d="M120,0 L120,-160" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="4s" repeatCount="indefinite" />
                </path>
                <path d="M140,0 L140,-260" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="5s" repeatCount="indefinite" />
                </path>
                <path d="M160,0 L160,-360" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="6s" repeatCount="indefinite" />
                </path>

                <path d="M180,0 L180,-360" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="5.5s" repeatCount="indefinite" />
                </path>
                <path d="M200,0 L200,-260" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="4.5s" repeatCount="indefinite" />
                </path>
                <path d="M220,0 L220,-160" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="3.5s" repeatCount="indefinite" />
                </path>
                <path d="M240,0 L240,-60" stroke="url(#glowGradient)" stroke-width="2" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="2.5s" repeatCount="indefinite" />
                </path>
            </g>

            {/* Flying Translation Particles */}
            <g>
                <circle cx="300" cy="280" r="3" fill="#FFD180">
                    <animate attributeName="cx" values="300;350;400;450;500" dur="2s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0;1;0" dur="2s" repeatCount="indefinite" />
                </circle>
                <circle cx="300" cy="380" r="3" fill="#FFD180">
                    <animate attributeName="cx" values="300;350;400;450;500" dur="3s" repeatCount="indefinite" begin="0.5s" />
                    <animate attributeName="opacity" values="0;1;0" dur="3s" repeatCount="indefinite" begin="0.5s" />
                </circle>
                <circle cx="300" cy="480" r="3" fill="#FFD180">
                    <animate attributeName="cx" values="300;350;400;450;500" dur="2.5s" repeatCount="indefinite" begin="1s" />
                    <animate attributeName="opacity" values="0;1;0" dur="2.5s" repeatCount="indefinite" begin="1s" />
                </circle>
                <circle cx="300" cy="580" r="3" fill="#FFD180">
                    <animate attributeName="cx" values="300;350;400;450;500" dur="2.2s" repeatCount="indefinite" begin="1.5s" />
                    <animate attributeName="opacity" values="0;1;0" dur="2.2s" repeatCount="indefinite" begin="1.5s" />
                </circle>

                <circle cx="500" cy="290" r="3" fill="#FFD180">
                    <animate attributeName="cx" values="500;450;400;350;300" dur="2.8s" repeatCount="indefinite" begin="0.3s" />
                    <animate attributeName="opacity" values="0;1;0" dur="2.8s" repeatCount="indefinite" begin="0.3s" />
                </circle>
                <circle cx="500" cy="390" r="3" fill="#FFD180">
                    <animate attributeName="cx" values="500;450;400;350;300" dur="3.2s" repeatCount="indefinite" begin="0.8s" />
                    <animate attributeName="opacity" values="0;1;0" dur="3.2s" repeatCount="indefinite" begin="0.8s" />
                </circle>
                <circle cx="500" cy="490" r="3" fill="#FFD180">
                    <animate attributeName="cx" values="500;450;400;350;300" dur="2.7s" repeatCount="indefinite" begin="1.3s" />
                    <animate attributeName="opacity" values="0;1;0" dur="2.7s" repeatCount="indefinite" begin="1.3s" />
                </circle>
                <circle cx="500" cy="570" r="3" fill="#FFD180">
                    <animate attributeName="cx" values="500;450;400;350;300" dur="3.5s" repeatCount="indefinite" begin="1.8s" />
                    <animate attributeName="opacity" values="0;1;0" dur="3.5s" repeatCount="indefinite" begin="1.8s" />
                </circle>
            </g>

            {/* Energy Bursts */}
            <circle cx="400" cy="280" r="5" fill="#FFCC80" opacity="0">
                <animate attributeName="r" values="5;15;5" dur="4s" repeatCount="indefinite" begin="0.5s"/>
                <animate attributeName="opacity" values="0;0.7;0" dur="4s" repeatCount="indefinite" begin="0.5s"/>
            </circle>
            <circle cx="400" cy="380" r="5" fill="#FFCC80" opacity="0">
                <animate attributeName="r" values="5;15;5" dur="4s" repeatCount="indefinite" begin="1.5s"/>
                <animate attributeName="opacity" values="0;0.7;0" dur="4s" repeatCount="indefinite" begin="1.5s"/>
            </circle>
            <circle cx="400" cy="480" r="5" fill="#FFCC80" opacity="0">
                <animate attributeName="r" values="5;15;5" dur="4s" repeatCount="indefinite" begin="2.5s"/>
                <animate attributeName="opacity" values="0;0.7;0" dur="4s" repeatCount="indefinite" begin="2.5s"/>
            </circle>
            <circle cx="400" cy="580" r="5" fill="#FFCC80" opacity="0">
                <animate attributeName="r" values="5;15;5" dur="4s" repeatCount="indefinite" begin="3.5s"/>
                <animate attributeName="opacity" values="0;0.7;0" dur="4s" repeatCount="indefinite" begin="3.5s"/>
            </circle>

            {/* Ancient Symbols */}
            <g opacity="0.4">
                <path d="M230,200 L240,190 L250,200 L240,210 Z" fill="#E65100" />
                <path d="M230,300 C235,295 245,295 250,300 C255,305 245,315 240,310 C235,315 225,305 230,300 Z" fill="#E65100" />
                <path d="M230,400 L250,400 L240,380 Z" fill="#E65100" />
                <path d="M230,500 L250,500 L250,520 L230,520 Z" fill="#E65100" />
                <path d="M230,600 L240,580 L250,600 L240,620 Z" fill="#E65100" />

                <path d="M550,200 L570,200 M550,210 L570,210" stroke="#E65100" stroke-width="2" />
                <path d="M550,300 L560,320 L570,300" stroke="#E65100" stroke-width="2" fill="none" />
                <path d="M550,400 L570,400 L570,420 L550,420 Z" fill="none" stroke="#E65100" stroke-width="2" />
                <path d="M550,500 L570,510 L550,520" stroke="#E65100" stroke-width="2" fill="none" />
                <path d="M550,600 L560,590 L570,600 L560,610 Z" fill="#E65100" />
            </g>

            {/* Header and Footer Text */}
            <text x="400" y="100" text-anchor="middle" fill="#E65100" font-size="24" font-weight="bold">Universal Translator</text>
            <text x="400" y="675" text-anchor="middle" fill="#FFF3E0" font-size="14">The Language of Human-AI Collaboration</text>

            {/* Small Hieroglyphic-style Icons */}
            <g transform="translate(190, 260) scale(0.5)">
                <rect x="0" y="0" width="20" height="40" fill="#E65100" opacity="0.5" />
                <path d="M5,10 L15,10 M5,20 L15,20 M5,30 L15,30" stroke="#FFF3E0" stroke-width="1" />
            </g>

            <g transform="translate(190, 360) scale(0.5)">
                <rect x="0" y="0" width="40" height="20" fill="#E65100" opacity="0.5" />
                <rect x="0" y="25" width="20" height="20" fill="#E65100" opacity="0.5" />
                <rect x="25" y="25" width="15" height="15" fill="#E65100" opacity="0.5" />
            </g>

            <g transform="translate(190, 460) scale(0.5)">
                <circle cx="20" cy="20" r="20" fill="#E65100" opacity="0.5" />
                <path d="M10,20 L30,20 M20,10 L20,30" stroke="#FFF3E0" stroke-width="1" />
            </g>

            <g transform="translate(190, 560) scale(0.5)">
                <path d="M0,0 L40,0 L40,40 L0,40 Z" fill="#E65100" opacity="0.5" />
                <path d="M20,10 L30,20 L20,30 L10,20 Z" fill="#FFF3E0" />
            </g>

            <g transform="translate(585, 260) scale(0.5)">
                <path d="M0,20 C10,0 30,0 40,20 C30,40 10,40 0,20 Z" fill="#E65100" opacity="0.5" />
                <circle cx="20" cy="15" r="5" fill="#FFF3E0" />
                <path d="M15,25 L25,25" stroke="#FFF3E0" stroke-width="1" />
            </g>

            <g transform="translate(585, 360) scale(0.5)">
                <path d="M0,0 L40,0 L40,40 L0,40 Z" fill="#E65100" opacity="0.5" />
                <path d="M10,10 L30,10 L30,30 L10,30 Z" fill="none" stroke="#FFF3E0" stroke-width="1" />
                <path d="M15,15 L25,15 L25,25 L15,25 Z" fill="#FFF3E0" />
            </g>

            <g transform="translate(585, 460) scale(0.5)">
                <path d="M0,20 L10,0 L30,0 L40,20 L30,40 L10,40 Z" fill="#E65100" opacity="0.5" />
                <path d="M10,20 L30,20" stroke="#FFF3E0" stroke-width="1" />
            </g>

            <g transform="translate(585, 560) scale(0.5)">
                <path d="M20,0 L40,20 L20,40 L0,20 Z" fill="#E65100" opacity="0.5" />
                <circle cx="20" cy="20" r="10" fill="none" stroke="#FFF3E0" stroke-width="1" />
            </g>

            {/* Pulse Animation */}
            <circle cx="400" cy="400" r="100" fill="#FF9800" opacity="0.1">
                <animate attributeName="r" values="100;150;100" dur="8s" repeatCount="indefinite"/>
                <animate attributeName="opacity" values="0.1;0.2;0.1" dur="8s" repeatCount="indefinite"/>
            </circle>
        </svg>
    );
};

export default TaytromTACSPillarSVG;