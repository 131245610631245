import React, { useState, useEffect, useRef, memo, useMemo } from 'react';
import {
    ArrowRight,
    ChevronRight,
    Play,
    Plus,
    Star,
    Download,
    ExternalLink,
    Check,
    Sparkles,
    Zap
} from 'lucide-react';

const Premium3DButton = ({
                             text = "Get Started",
                             onClick,
                             className = "",
                             brandColor = "#20B2AA", // Taytrom teal as default
                             size = "default", // "small", "default", "large"
                             iconName = "arrow-right", // Specify icon by name
                             iconPosition = "after", // "before", "after", "none"
                             variant = "primary", // "primary", "outline", "gradient", "glass"
                             disabled = false,
                             loading = false,
                             fullWidth = false,
                             textBold = true,
                             glow = true,
                             nowrap = true,
                             textColor,
                             textAlign = "center",
                             animation = "default", // "default", "pulse", "float", "none"
                             intensity = 1.0, // Control the intensity of effects
                             iconBefore,
                             iconAfter,
                             children
                         }) => {
    // Refs for performance
    const buttonRef = useRef(null);
    const animationFrameRef = useRef(null);
    const hoverTimeoutRef = useRef(null);

    // States
    const [isHovered, setIsHovered] = useState(false);
    const [isPressed, setIsPressed] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [buttonDimensions, setButtonDimensions] = useState({ width: 0, height: 0 });
    const [hasInteracted, setHasInteracted] = useState(false);
    const [isGlinting, setIsGlinting] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    // Convert icon name to actual icon component
    const getLucideIcon = (name, size) => {
        const icons = {
            'arrow-right': ArrowRight,
            'chevron-right': ChevronRight,
            'play': Play,
            'plus': Plus,
            'star': Star,
            'download': Download,
            'external-link': ExternalLink,
            'check': Check,
            'sparkles': Sparkles,
            'zap': Zap
        };

        const IconComponent = icons[name] || ArrowRight;
        return <IconComponent size={size} strokeWidth={2.5} />;
    };

    // Dynamically generate color palette from brand color
    const hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    };

    const adjustBrightness = (hex, percent) => {
        const rgb = hexToRgb(hex);
        if (!rgb) return hex;

        const factor = percent / 100;
        const r = Math.min(255, Math.max(0, Math.round(rgb.r + (factor * 255))));
        const g = Math.min(255, Math.max(0, Math.round(rgb.g + (factor * 255))));
        const b = Math.min(255, Math.max(0, Math.round(rgb.b + (factor * 255))));

        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    };

    // Memoize color calculations for performance
    const colors = useMemo(() => {
        const baseRgb = hexToRgb(brandColor);
        const rgbString = baseRgb ? `${baseRgb.r},${baseRgb.g},${baseRgb.b}` : '32,178,170';

        return {
            gradientColors: {
                start: adjustBrightness(brandColor, 15),  // Lighter
                mid1: brandColor,                         // Base color
                mid2: adjustBrightness(brandColor, -10),  // Slightly darker
                end: adjustBrightness(brandColor, -20)    // Darker for depth
            },
            rgbString,
            lighter: adjustBrightness(brandColor, 30),
            darker: adjustBrightness(brandColor, -30),
            outline: variant === 'outline'
        };
    }, [brandColor, variant]);

    // Get button sizing based on size prop
    const sizeStyles = useMemo(() => {
        switch(size) {
            case "small":
                return {
                    fontSize: '0.875rem',
                    padding: '0.5rem 1.25rem',
                    minWidth: fullWidth ? '100%' : '140px',
                    iconSize: 16
                };
            case "large":
                return {
                    fontSize: '1.125rem',
                    padding: '1rem 2rem',
                    minWidth: fullWidth ? '100%' : '200px',
                    iconSize: 24
                };
            default:
                return {
                    fontSize: '1rem',
                    padding: '0.75rem 1.75rem',
                    minWidth: fullWidth ? '100%' : '160px',
                    iconSize: 20
                };
        }
    }, [size, fullWidth]);

    // Calculate dynamic lighting based on mouse position
    const getLightingStyles = () => {
        // Early return if not interactive or disabled
        if ((!isHovered && !isFocused) || disabled) return {};

        // Calculate primary light position with improved sensitivity
        const lightX = 50 + (mousePosition.x * 25);
        const lightY = 50 + (mousePosition.y * 25);

        // Create secondary ambient light on opposite side for depth
        const secondaryLightX = 100 - lightX;
        const secondaryLightY = 100 - lightY;

        // Calculate intensity based on mouse movement and state
        const baseIntensity = Math.min(0.4, 0.2 + Math.abs(mousePosition.x * mousePosition.y) * 0.1) * intensity;

        // Add subtle time-based pulsing effect
        const pulseIntensity = animation === 'pulse'
            ? 0.1 * Math.sin(Date.now() / 800) + 0.95
            : 1;

        // Calculate edge highlight position (slightly offset from main light)
        const edgeX = lightX + (mousePosition.x * 10);
        const edgeY = lightY + (mousePosition.y * 10);

        // Variant-specific styles
        if (variant === 'outline') {
            return {
                backgroundImage: `
          radial-gradient(
            circle at ${lightX}% ${lightY}%, 
            rgba(${colors.rgbString},${baseIntensity * 0.2 * pulseIntensity}), 
            rgba(${colors.rgbString},${baseIntensity * 0.05 * pulseIntensity}) 70%,
            transparent 100%
          )
        `,
                backgroundColor: `rgba(${colors.rgbString},0.1)`,
                borderColor: colors.gradientColors.mid1
            };
        }

        if (variant === 'glass') {
            return {
                backgroundImage: `
          linear-gradient(
            135deg, 
            rgba(255,255,255,0.2), 
            rgba(255,255,255,0.1) 40%, 
            rgba(${colors.rgbString},0.1) 60%, 
            rgba(${colors.rgbString},0.2)
          ),
          radial-gradient(
            circle at ${lightX}% ${lightY}%, 
            rgba(255,255,255,${baseIntensity * pulseIntensity}), 
            rgba(255,255,255,${baseIntensity * 0.3 * pulseIntensity}) 25%,
            transparent 60%
          )
        `,
                backdropFilter: 'blur(10px)',
                backgroundColor: `rgba(${colors.rgbString},0.15)`,
                boxShadow: `
          0 0 15px rgba(255,255,255,${baseIntensity * 0.3 * pulseIntensity}) inset,
          0 0 30px rgba(${colors.rgbString},0.2)
        `
            };
        }

        if (variant === 'gradient') {
            return {
                backgroundImage: `
          linear-gradient(
            135deg, 
            ${colors.gradientColors.start}, 
            ${colors.gradientColors.mid1} 30%, 
            ${colors.gradientColors.mid2} 70%, 
            ${colors.gradientColors.end}
          ),
          radial-gradient(
            circle at ${lightX}% ${lightY}%, 
            rgba(255,255,255,${baseIntensity * pulseIntensity}), 
            rgba(255,255,255,${baseIntensity * 0.5 * pulseIntensity}) 25%,
            transparent 60%
          ),
          radial-gradient(
            circle at ${secondaryLightX}% ${secondaryLightY}%, 
            rgba(255,255,255,${baseIntensity * 0.3 * pulseIntensity}), 
            transparent 40%
          )
        `,
                backgroundBlendMode: 'normal, screen, soft-light',
                boxShadow: `
          0 0 15px rgba(255,255,255,${baseIntensity * 0.3 * pulseIntensity}) inset,
          0 0 30px rgba(${colors.rgbString},0.2)
        `
            };
        }

        // Default primary style
        return {
            backgroundImage: `
        linear-gradient(
          135deg, 
          ${colors.gradientColors.start}, 
          ${colors.gradientColors.mid1} 40%, 
          ${colors.gradientColors.mid2} 60%, 
          ${colors.gradientColors.end}
        ),
        radial-gradient(
          circle at ${lightX}% ${lightY}%, 
          rgba(255,255,255,${baseIntensity * pulseIntensity}), 
          rgba(255,255,255,${baseIntensity * 0.5 * pulseIntensity}) 25%,
          transparent 60%
        ),
        radial-gradient(
          circle at ${secondaryLightX}% ${secondaryLightY}%, 
          rgba(255,255,255,${baseIntensity * 0.3 * pulseIntensity}), 
          transparent 40%
        ),
        radial-gradient(
          ellipse at ${edgeX}% ${edgeY}%,
          rgba(255,255,255,${baseIntensity * 0.7 * pulseIntensity}),
          transparent 25%
        )
      `,
            backgroundBlendMode: 'normal, screen, soft-light, overlay',
            boxShadow: `
        0 0 15px rgba(255,255,255,${baseIntensity * 0.3 * pulseIntensity}) inset,
        0 0 30px rgba(0,0,0,0.1)
      `
        };
    };

    /**
     * Generate a 3D transform based on element state and mouse position
     */
    const get3DTransform = () => {
        const config = {
            perspective: 1000,
            transitions: {
                duration: '0.3s',
                easing: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
            },
            transforms: {
                default: {
                    scale: 1,
                    translateZ: 0,
                    translateY: 0,
                },
                initial: {
                    scale: 0.95,
                    translateZ: 0,
                    translateY: 10,
                },
                hover: {
                    scale: 1.03,
                    translateZ: 10,
                    maxTilt: 4 * intensity, // Maximum rotation in degrees, scaled by intensity
                },
                press: {
                    scale: 0.98,
                    translateZ: -2,
                    translateY: 3,
                }
            }
        };

        // Apply transition except when pressed for immediate feedback
        const transition = isPressed ? 'none' :
            `transform ${config.transitions.duration} ${config.transitions.easing}`;

        // Calculate the correct transform based on state
        let transform;

        if (disabled) {
            transform = `perspective(${config.perspective}px) translateZ(0) scale(0.99)`;
        }
        else if (!hasInteracted) {
            const { scale, translateY } = config.transforms.initial;
            transform = `perspective(${config.perspective}px) translateY(${translateY}px) scale(${scale})`;
        }
        else if (isPressed) {
            const { scale, translateZ, translateY } = config.transforms.press;
            transform = `perspective(${config.perspective}px) translateZ(${translateZ}px) translateY(${translateY}px) scale(${scale})`;
        }
        else if (isHovered || isFocused) {
            const { scale, translateZ, maxTilt } = config.transforms.hover;

            // Calculate tilt with smooth clamping
            const clampedX = Math.max(-1, Math.min(1, mousePosition.x));
            const clampedY = Math.max(-1, Math.min(1, mousePosition.y));

            // Apply tilt based on mouse position
            const tiltX = clampedY * maxTilt;
            const tiltY = -clampedX * maxTilt;

            // Add subtle floating animation if enabled
            const floatY = animation === 'float' ? (Math.sin(Date.now() / 1000) * 3) : 0;

            transform = `perspective(${config.perspective}px) rotateX(${tiltX}deg) rotateY(${tiltY}deg) translateZ(${translateZ}px) translateY(${floatY}px) scale(${scale})`;
        }
        else {
            const { scale, translateZ, translateY } = config.transforms.default;
            transform = `perspective(${config.perspective}px) translateZ(${translateZ}px) translateY(${translateY}px) scale(${scale})`;
        }

        return {
            transform,
            transition,
            willChange: 'transform', // Performance hint for browsers
        };
    };

    /**
     * Generate shadow effects based on element state
     */
    const getShadowStyles = () => {
        // Don't calculate shadows if disabled
        if (disabled) {
            return '0 2px 5px rgba(0,0,0,0.1)';
        }

        // Internal helper to avoid recalculating
        const brandRgbStr = colors.rgbString;

        // Apply intensity multiplier to shadow values
        const adjust = (value) => Math.round(value * intensity);

        // Define shadow presets
        const shadows = {
            none: [
                '0 0 0 rgba(0,0,0,0)',
                '0 0 0 rgba(0,0,0,0)'
            ],

            pressed: variant === 'outline' ? [
                `0 ${adjust(1)}px ${adjust(2)}px rgba(0,0,0,0.1)`,
                `inset 0 0 ${adjust(5)}px rgba(${brandRgbStr},0.3)`
            ] : [
                `0 ${adjust(2)}px ${adjust(4)}px rgba(0,0,0,0.2)`,
                `0 ${adjust(1)}px ${adjust(2)}px rgba(0,0,0,0.3)`,
                `inset 0 ${adjust(1)}px ${adjust(1)}px rgba(255,255,255,0.4)`,
                `0 0 ${adjust(10)}px rgba(${brandRgbStr},${0.3 * intensity})`
            ],

            hover: variant === 'outline' ? [
                `0 ${adjust(5)}px ${adjust(15)}px rgba(0,0,0,0.1)`,
                `0 ${adjust(2)}px ${adjust(5)}px rgba(0,0,0,0.1)`,
                `0 0 ${adjust(15)}px rgba(${brandRgbStr},${0.3 * intensity})`,
                `inset 0 0 ${adjust(2)}px rgba(${brandRgbStr},0.4)`
            ] : [
                `0 ${adjust(10)}px ${adjust(25)}px rgba(0,0,0,0.2)`,
                `0 ${adjust(6)}px ${adjust(10)}px rgba(0,0,0,0.15)`,
                `0 ${adjust(20)}px ${adjust(40)}px -${adjust(10)}px rgba(${brandRgbStr},${0.5 * intensity})`,
                `inset 0 ${adjust(1)}px ${adjust(2)}px rgba(255,255,255,0.5)`,
                `0 0 ${adjust(20)}px rgba(${brandRgbStr},${0.4 * intensity})`
            ],

            default: variant === 'outline' ? [
                `0 ${adjust(3)}px ${adjust(6)}px rgba(0,0,0,0.08)`,
                `0 0 ${adjust(5)}px rgba(${brandRgbStr},${0.2 * intensity})`,
                `inset 0 0 ${adjust(1)}px rgba(${brandRgbStr},0.3)`
            ] : [
                `0 ${adjust(6)}px ${adjust(10)}px rgba(0,0,0,0.15)`,
                `0 ${adjust(3)}px ${adjust(6)}px rgba(0,0,0,0.1)`,
                `0 ${adjust(10)}px ${adjust(20)}px -${adjust(5)}px rgba(${brandRgbStr},${0.4 * intensity})`,
                `inset 0 ${adjust(1)}px ${adjust(2)}px rgba(255,255,255,0.4)`,
                `0 0 ${adjust(15)}px rgba(${brandRgbStr},${0.3 * intensity})`
            ]
        };

        // Select appropriate shadow based on state
        let selectedShadow;

        if (!hasInteracted) {
            selectedShadow = shadows.none;
        } else if (isPressed) {
            selectedShadow = shadows.pressed;
        } else if (isHovered || isFocused) {
            selectedShadow = shadows.hover;
        } else {
            selectedShadow = shadows.default;
        }

        // Join shadow array into CSS shadow string
        return selectedShadow.join(',\n        ');
    };

    // Track mouse position for lighting effects with optimized performance
    useEffect(() => {
        if (!buttonRef.current) return;

        const handleMouseMove = (e) => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }

            animationFrameRef.current = requestAnimationFrame(() => {
                if (!buttonRef.current) return;
                const rect = buttonRef.current.getBoundingClientRect();
                setMousePosition({
                    x: ((e.clientX - rect.left) / rect.width) * 2 - 1,
                    y: ((e.clientY - rect.top) / rect.height) * 2 - 1
                });
            });
        };

        // Setup dimensions observer
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setButtonDimensions({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                });
            }
        });

        resizeObserver.observe(buttonRef.current);
        buttonRef.current.addEventListener('mousemove', handleMouseMove);

        return () => {
            if (buttonRef.current) {
                buttonRef.current.removeEventListener('mousemove', handleMouseMove);
            }

            resizeObserver.disconnect();

            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, []);

    // Entry animation effect
    useEffect(() => {
        const timer = setTimeout(() => {
            setHasInteracted(true);
        }, 300);

        return () => clearTimeout(timer);
    }, []);

    // Create a glint effect on hover with a timeout
    useEffect(() => {
        if (isHovered && !isGlinting) {
            hoverTimeoutRef.current = setTimeout(() => {
                setIsGlinting(true);
                setTimeout(() => setIsGlinting(false), 1500);
            }, 300);
        }

        return () => {
            if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current);
            }
        };
    }, [isHovered, isGlinting]);

    // Prepare icons based on props
    const renderedIconBefore = iconPosition === 'before' ? (
        iconBefore || (iconName ? getLucideIcon(iconName, sizeStyles.iconSize) : null)
    ) : null;

    const renderedIconAfter = iconPosition === 'after' ? (
        iconAfter || (iconName ? getLucideIcon(iconName, sizeStyles.iconSize) : null)
    ) : null;

    // Set correct text color based on variant
    const buttonTextColor = useMemo(() => {
        if (textColor) return textColor;
        if (variant === 'outline') return brandColor;
        return 'white';
    }, [textColor, variant, brandColor]);

    // Get animation class based on animation prop
    const animationClass = useMemo(() => {
        if (animation === 'none' || disabled) return '';
        return `button-animation-${animation}`;
    }, [animation, disabled]);

    return (
        <button
            ref={buttonRef}
            onClick={disabled || loading ? undefined : onClick}
            onMouseEnter={() => !disabled && setIsHovered(true)}
            onMouseLeave={() => {
                setIsHovered(false);
                setIsPressed(false);
            }}
            onMouseDown={(e) => {
                if (!disabled && e.button === 0) {
                    setIsPressed(true);
                    setHasInteracted(true);
                }
            }}
            onMouseUp={() => !disabled && setIsPressed(false)}
            onTouchStart={(e) => {
                if (disabled) return;

                e.preventDefault();
                setIsPressed(true);
                setHasInteracted(true);

                if (e.touches?.[0] && buttonRef.current) {
                    const touch = e.touches[0];
                    const rect = buttonRef.current.getBoundingClientRect();
                    const x = ((touch.clientX - rect.left) / rect.width) * 2 - 1;
                    const y = ((touch.clientY - rect.top) / rect.height) * 2 - 1;
                    setMousePosition({ x, y });
                }
            }}
            onTouchEnd={(e) => {
                if (!disabled) {
                    e.preventDefault();
                    setIsPressed(false);
                }
            }}
            onTouchMove={(e) => {
                if (disabled) return;

                if (e.touches?.[0] && buttonRef.current) {
                    const touch = e.touches[0];
                    const rect = buttonRef.current.getBoundingClientRect();
                    const x = ((touch.clientX - rect.left) / rect.width) * 2 - 1;
                    const y = ((touch.clientY - rect.top) / rect.height) * 2 - 1;
                    setMousePosition({ x, y });
                }
            }}
            onFocus={() => !disabled && setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={(e) => {
                if (disabled) return;

                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    setIsPressed(true);
                    setHasInteracted(true);
                }
            }}
            onKeyUp={(e) => {
                if (disabled) return;

                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    setIsPressed(false);
                    onClick?.(e);
                }
            }}
            disabled={disabled}
            aria-label={text}
            aria-disabled={disabled}
            aria-pressed={isPressed}
            aria-busy={loading}
            role="button"
            tabIndex={disabled ? -1 : 0}
            style={{
                position: 'relative',
                fontSize: sizeStyles.fontSize,
                fontWeight: 700,
                padding: sizeStyles.padding,
                minWidth: sizeStyles.minWidth,
                width: fullWidth ? '100%' : 'auto',
                color: buttonTextColor,
                backgroundColor: variant === 'outline' ? 'transparent' : colors.gradientColors.mid1,
                border: variant === 'outline' ? `2px solid ${colors.gradientColors.mid1}` : 'none',
                borderRadius: '50px',
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.6 : 1,
                transition: `all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1.0), 
                  transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1.0), 
                  box-shadow 0.3s cubic-bezier(0.2, 0.8, 0.2, 1.0)`,
                ...get3DTransform(),
                boxShadow: getShadowStyles(),
                overflow: 'hidden',
                textShadow: variant === 'outline' ? 'none' : '0 1px 1px rgba(0,0,0,0.1)',
                outline: isFocused ? `3px solid ${colors.lighter}` : 'none',
                outlineOffset: '2px',
                userSelect: 'none',
                WebkitTapHighlightColor: 'transparent',
                willChange: 'transform, box-shadow',
                ...getLightingStyles()
            }}
            className={`premium-3d-button ${animationClass} ${isPressed ? 'is-pressed' : ''} ${isHovered ? 'is-hovered' : ''} ${isFocused ? 'is-focused' : ''} ${disabled ? 'is-disabled' : ''} ${loading ? 'is-loading' : ''} ${className}`}
        >
            {/* Glass effect overlay */}
            {(variant === 'primary' || variant === 'gradient') && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '50%',
                        background: `
              linear-gradient(
                to bottom, 
                rgba(255,255,255,${isPressed ? 0.3 : 0.45}) 0%, 
                rgba(255,255,255,0.2) 40%,
                rgba(255,255,255,0.1) 60%,
                rgba(255,255,255,0.05) 100%
              ),
              linear-gradient(
                ${45 + (isHovered ? 10 : 0)}deg,
                rgba(255,255,255,0.8) 0%,
                rgba(255,255,255,0) 20%
              ),
              radial-gradient(
                circle at ${50 + (mousePosition.x * 20)}% ${25 + (mousePosition.y * 15)}%,
                rgba(255,255,255,0.4) 0%,
                rgba(255,255,255,0.1) 30%,
                transparent 70%
              )
            `,
                        backgroundBlendMode: 'soft-light, overlay, screen',
                        borderTopLeftRadius: '50px',
                        borderTopRightRadius: '50px',
                        opacity: isPressed ? 0.3 : 0.75,
                        transition: 'all 0.3s ease',
                        boxShadow: `
              inset 0 1px 1px rgba(255,255,255,${isPressed ? 0.5 : 0.8}),
              inset 0 -8px 20px rgba(255,255,255,0.2)
            `,
                        backdropFilter: 'blur(5px)',
                        overflow: 'hidden',
                        pointerEvents: 'none'
                    }}
                >
                    {/* Glint effect */}
                    <div
                        style={{
                            position: 'absolute',
                            top: '-30%',
                            left: '-20%',
                            width: '40%',
                            height: '150%',
                            background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.15), transparent)',
                            transform: `rotate(35deg) translateX(${isGlinting ? '100%' : '-100%'})`,
                            transition: isGlinting ? 'transform 1s ease-in-out' : 'none',
                            opacity: isPressed ? 0.3 : 0.7,
                            filter: 'blur(3px)'
                        }}
                    />
                </div>
            )}

            {/* Button content wrapper */}
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: (renderedIconBefore || renderedIconAfter) ? '0.75rem' : '0.5rem',
                    zIndex: 2,
                    transform: isPressed
                        ? 'translateY(1px) scale(0.98)'
                        : isHovered && !disabled
                            ? 'translateY(-1px) scale(1.02)'
                            : 'translateY(0) scale(1)',
                    transition: 'transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                    width: '100%',
                    height: '100%',
                    padding: '0.1rem',
                    pointerEvents: 'none',
                    textRendering: 'optimizeLegibility',
                    backfaceVisibility: 'hidden',
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale',
                    letterSpacing: '0.01em',
                    fontFeatureSettings: '"ss01" 1, "ss02" 1, "case" 1',
                    color: 'inherit',
                    textShadow: isPressed
                        ? '0 1px 1px rgba(0,0,0,0.15)'
                        : isHovered && !disabled
                            ? '0 2px 3px rgba(0,0,0,0.12)'
                            : '0 1px 2px rgba(0,0,0,0.1)',
                    opacity: disabled ? 0.7 : 1,
                    filter: isHovered && !isPressed && !disabled
                        ? 'drop-shadow(0 1px 2px rgba(0,0,0,0.07))'
                        : 'none'
                }}
                aria-hidden="true"
            >
                {/* Loading spinner */}
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0,0,0,0.1)',
                            borderRadius: '50px',
                            zIndex: 3
                        }}
                    >
                        <div
                            style={{
                                width: sizeStyles.iconSize,
                                height: sizeStyles.iconSize,
                                borderRadius: '50%',
                                border: `2px solid ${buttonTextColor}`,
                                borderTopColor: 'transparent',
                                animation: 'spin 0.8s linear infinite'
                            }}
                        />
                    </div>
                )}

                {/* Icon before text if provided */}
                {renderedIconBefore && (
                    <span
                        className="button-icon button-icon--before"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transform: isPressed
                                ? 'scale(0.95)'
                                : isHovered && !disabled
                                    ? 'scale(1.1) translateX(-1px)'
                                    : 'scale(1)',
                            transition: 'transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                            opacity: isPressed ? 0.9 : 1,
                            color: 'currentColor'
                        }}
                    >
            {renderedIconBefore}
          </span>
                )}

                {/* Main text content */}
                <span
                    className="button-text"
                    style={{
                        position: 'relative',
                        display: 'block',
                        lineHeight: 1.2,
                        fontWeight: isPressed ? 700 : textBold ? 700 : 600,
                        whiteSpace: nowrap ? 'nowrap' : 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        transform: isPressed
                            ? 'scale(0.98)'
                            : 'scale(1)',
                        transition: 'all 0.15s ease',
                        flexGrow: 1,
                        textAlign: textAlign || 'center'
                    }}
                >
          {text}
        </span>

                {/* Icon after text if provided */}
                {renderedIconAfter && (
                    <span
                        className="button-icon button-icon--after"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transform: isPressed
                                ? 'scale(0.95)'
                                : isHovered && !disabled
                                    ? 'scale(1.1) translateX(1px)'
                                    : 'scale(1)',
                            transition: 'transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                            opacity: isPressed ? 0.9 : 1,
                            color: 'currentColor'
                        }}
                    >
            {renderedIconAfter}
          </span>
                )}

                {/* Allow for custom children */}
                {children}
            </div>

            {/* Edge highlight */}
            {(variant === 'primary' || variant === 'gradient') && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '2px',
                        background: isPressed ? 'transparent' : 'rgba(255,255,255,0.7)',
                        borderTopLeftRadius: '50px',
                        borderTopRightRadius: '50px',
                        zIndex: 1,
                        opacity: isPressed ? 0 : 0.7,
                        transition: 'opacity 0.1s ease'
                    }}
                />
            )}
            {/* Button after-image effect */}
            {glow && !disabled && (
                <div
                    style={{
                        position: 'absolute',
                        top: 2,
                        left: 2,
                        right: 2,
                        bottom: 0,
                        background: variant === 'outline' ? 'transparent' : colors.gradientColors.end,
                        borderRadius: '50px',
                        filter: 'blur(8px)',
                        opacity: variant === 'outline' ? 0.2 : 0.4,
                        transform: `scale(${isPressed ? 0.95 : 0.97})`,
                        transition: 'transform 0.3s ease, opacity 0.3s ease',
                        zIndex: -1
                    }}
                />
            )}

            {/* Optional pulsing effect */}
            {animation === 'pulse' && !disabled && (
                <div
                    style={{
                        position: 'absolute',
                        top: -4,
                        left: -4,
                        right: -4,
                        bottom: -4,
                        borderRadius: '60px',
                        border: `2px solid ${colors.lighter}`,
                        opacity: 0.2,
                        zIndex: -1,
                        animation: 'pulse 2s infinite ease-in-out'
                    }}
                />
            )}

            {/* CSS for animations */}
            <style jsx global>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-5px); }
        }
        
        @keyframes pulse {
          0% { transform: scale(1); opacity: 0.2; }
          50% { transform: scale(1.03); opacity: 0.4; }
          100% { transform: scale(1); opacity: 0.2; }
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        
        .button-animation-float {
          animation: float 3s ease-in-out infinite;
        }
        
        .button-animation-pulse:not(:hover):not(:focus) {
          animation: pulse 3s infinite;
        }
      `}</style>
        </button>
    );
};

export default memo(Premium3DButton);