import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Plus, ChevronDown, ChevronRight,
    Trash2, ArrowUp, ArrowDown,
    AlignLeft, Layers, Menu, X,
    Coffee, Sun, Cloud, Moon
} from 'lucide-react';
import styles from './Compiler_2_Steps_InteractiveTour_Specialists_Writing.module.css'

const Compiler_2_Steps_InteractiveDemo_Specialists_Writing = ({
                                                                  onNext,
                                                                  onPrevious,
                                                                  isDark = true,
                                                                  orchestrator = null
                                                              }) => {
    // Document structure state
    const [sections, setSections] = useState([
        {
            id: '1',
            title: 'The Garden of Live Sections',
            content: 'Welcome to the wondrous garden where document sections grow and flourish. Each section can be a heading, paragraph, or special content type.',
            type: 'heading',
            level: 1,
            parentId: null
        },
        {
            id: '2',
            title: 'Growing New Sections',
            content: 'Click the + button to plant a new section seed. Watch it grow into a full paragraph or heading.',
            type: 'heading',
            level: 2,
            parentId: null
        },
        {
            id: '3',
            content: 'Sections can be arranged in a hierarchy, like branches on a tree. Try nesting sections under others to create a beautiful structure.',
            type: 'paragraph',
            parentId: '2'
        },
        {
            id: '4',
            title: 'Rearranging the Garden',
            content: 'Drag sections up and down to change their order. The Caterpillar insists that proper arrangement leads to the most sensible documents.',
            type: 'heading',
            level: 2,
            parentId: null
        },
        {
            id: '5',
            content: 'In this curious garden, you can:',
            type: 'paragraph',
            parentId: '4'
        },
        {
            id: '6',
            content: [
                'Add new sections with the + button',
                'Delete unwanted sections',
                'Move sections up and down',
                'Nest sections under others',
                'Expand and collapse nested sections'
            ],
            type: 'bullet-list',
            parentId: '4'
        }
    ]);

    // Tour and animation states
    const [activeSection, setActiveSection] = useState(null);
    const [expandedSections, setExpandedSections] = useState({ '2': true, '4': true });
    const [showGuideTip, setShowGuideTip] = useState(true);
    const [tipIndex, setTipIndex] = useState(0);
    const [catPosition, setCatPosition] = useState({ x: 20, y: 80 });
    const [timeOfDay, setTimeOfDay] = useState('day');
    const [floating, setFloating] = useState([]);
    const [highlightedFeature, setHighlightedFeature] = useState(null);

    // References for animations
    const structuralViewRef = useRef(null);
    const documentViewRef = useRef(null);
    const catRef = useRef(null);

    // Tour guide tips
    const guideTips = [
        "Welcome to the Garden of Sections! I'm the Cheshire Cat, your guide.",
        "Each document section is like a plant in this garden. You can add, rearrange, or nest them.",
        "Try clicking the + button to add a new section. Don't worry - nothing's permanent here!",
        "See how sections can nest? Click the arrow to expand or collapse them.",
        "The left panel shows your document content, while the right shows its structure.",
        "Drag sections up and down to rearrange them. Structure is everything in Wonderland!",
        "Click on the trash icon to remove a section. But be careful what you delete...",
        "Ready to continue your journey? Click 'Next' when you're done exploring!"
    ];

    // Generate floating elements (butterflies, flowers)
    useEffect(() => {
        const newFloating = [];
        const items = ['🦋', '🌸', '🍄', '🐛', '🐇', '♠️', '♥️', '♦️', '♣️'];

        for (let i = 0; i < 12; i++) {
            newFloating.push({
                id: `float-${i}`,
                emoji: items[Math.floor(Math.random() * items.length)],
                x: Math.random() * 100,
                y: Math.random() * 100,
                size: 0.5 + Math.random() * 1.5,
                duration: 15 + Math.random() * 30,
                delay: Math.random() * 5
            });
        }

        setFloating(newFloating);

        // Cycle through day/night to simulate time passing
        const timeInterval = setInterval(() => {
            setTimeOfDay(prev => {
                if (prev === 'day') return 'sunset';
                if (prev === 'sunset') return 'night';
                return 'day';
            });
        }, 45000);

        return () => clearInterval(timeInterval);
    }, []);

    // Move Cheshire Cat around occasionally
    useEffect(() => {
        const moveInterval = setInterval(() => {
            if (Math.random() > 0.5) {
                setCatPosition({
                    x: 10 + Math.random() * 80,
                    y: 10 + Math.random() * 80
                });
            }
        }, 8000);

        // Cycle through guide tips
        const tipInterval = setInterval(() => {
            if (showGuideTip) {
                setTipIndex(prev => (prev + 1) % guideTips.length);
            }
        }, 12000);

        return () => {
            clearInterval(moveInterval);
            clearInterval(tipInterval);
        };
    }, [showGuideTip]);

    // Sound effects using orchestrator if available
    const playSound = (sound) => {
        if (orchestrator && orchestrator.soundManager) {
            orchestrator.soundManager.play(sound);
        }
    };

    // Handle section operations
    const addSection = (parentId = null) => {
        const newId = `new-${Date.now()}`;
        const newSection = {
            id: newId,
            title: parentId ? 'New Subsection' : 'New Section',
            content: 'Click to edit this curious new section...',
            type: 'paragraph',
            parentId
        };

        setSections([...sections, newSection]);
        setActiveSection(newId);
        playSound('pop');

        // Animate feature highlight
        setHighlightedFeature('addSection');
        setTimeout(() => setHighlightedFeature(null), 2000);
    };

    const deleteSection = (id) => {
        // Find all child sections recursively
        const findChildren = (sectionId) => {
            const children = sections.filter(s => s.parentId === sectionId).map(s => s.id);
            let allChildren = [...children];

            children.forEach(childId => {
                allChildren = [...allChildren, ...findChildren(childId)];
            });

            return allChildren;
        };

        const childrenIds = findChildren(id);
        const allIdsToRemove = [id, ...childrenIds];

        setSections(sections.filter(section => !allIdsToRemove.includes(section.id)));
        playSound('trash');

        // Animate feature highlight
        setHighlightedFeature('deleteSection');
        setTimeout(() => setHighlightedFeature(null), 2000);
    };

    const moveSection = (id, direction) => {
        // Get all top-level sections
        const topLevelSections = sections.filter(s => s.parentId === null);
        const sectionIndex = topLevelSections.findIndex(s => s.id === id);

        if (sectionIndex === -1) {
            // Handle nested section movement
            const parentId = sections.find(s => s.id === id)?.parentId;
            if (!parentId) return;

            const siblingIds = sections
                .filter(s => s.parentId === parentId)
                .map(s => s.id);

            const index = siblingIds.indexOf(id);
            if ((direction === 'up' && index === 0) ||
                (direction === 'down' && index === siblingIds.length - 1)) {
                return;
            }

            // Create new order for the siblings
            const newOrder = [...siblingIds];
            const targetIndex = direction === 'up' ? index - 1 : index + 1;
            [newOrder[index], newOrder[targetIndex]] = [newOrder[targetIndex], newOrder[index]];

            // Update all sections with new order
            let updatedSections = [...sections];
            siblingIds.forEach((sectionId, idx) => {
                const sectionIndex = updatedSections.findIndex(s => s.id === sectionId);
                if (sectionIndex !== -1) {
                    updatedSections[sectionIndex] = {
                        ...updatedSections[sectionIndex],
                        order: newOrder.indexOf(sectionId)
                    };
                }
            });

            setSections(updatedSections);
        } else {
            // Handle top-level section movement
            if ((direction === 'up' && sectionIndex === 0) ||
                (direction === 'down' && sectionIndex === topLevelSections.length - 1)) {
                return;
            }

            const newIndex = direction === 'up' ? sectionIndex - 1 : sectionIndex + 1;
            const newTopLevelSections = [...topLevelSections];
            [newTopLevelSections[sectionIndex], newTopLevelSections[newIndex]] =
                [newTopLevelSections[newIndex], newTopLevelSections[sectionIndex]];

            const updatedSections = sections.map(section => {
                if (section.parentId === null) {
                    const index = newTopLevelSections.findIndex(s => s.id === section.id);
                    return { ...section, order: index };
                }
                return section;
            });

            setSections(updatedSections);
        }

        playSound(direction === 'up' ? 'slideUp' : 'slideDown');

        // Animate feature highlight
        setHighlightedFeature('moveSection');
        setTimeout(() => setHighlightedFeature(null), 2000);
    };

    const toggleExpand = (id) => {
        setExpandedSections(prev => {
            const newState = { ...prev };
            newState[id] = !newState[id];
            return newState;
        });

        playSound('click');

        // Animate feature highlight
        setHighlightedFeature('toggleExpand');
        setTimeout(() => setHighlightedFeature(null), 2000);
    };

    // Render the structural view (right panel)
    const renderStructuralView = () => {
        // Get top-level sections
        const topLevelSections = sections
            .filter(section => section.parentId === null)
            .sort((a, b) => (a.order || 0) - (b.order || 0));

        const renderSection = (section, level = 0) => {
            const hasChildren = sections.some(s => s.parentId === section.id);
            const isExpanded = expandedSections[section.id];

            return (
                <div key={section.id} className={styles['taytrom-structural-section']}>
                    <motion.div
                        className={`${styles['taytrom-structural-item']} ${activeSection === section.id ? styles['taytrom-active-section'] : ''}`}
                        style={{ marginLeft: `${level * 20}px` }}
                        whileHover={{ backgroundColor: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)' }}
                        onClick={() => setActiveSection(section.id)}
                    >
                        {hasChildren && (
                            <button
                                className={styles['taytrom-expand-button']}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleExpand(section.id);
                                }}
                                aria-label={isExpanded ? "Collapse section" : "Expand section"}
                            >
                                {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                            </button>
                        )}

                        <div className={styles['taytrom-structural-item-content']}>
                            <span className={styles['taytrom-structural-item-title']}>
                                {section.title || (section.type === 'bullet-list' ? "Bullet List" : "Paragraph")}
                            </span>

                            <div className={styles['taytrom-structural-item-actions']}>
                                <button
                                    className={styles['taytrom-action-button']}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        moveSection(section.id, 'up');
                                    }}
                                    aria-label="Move section up"
                                >
                                    <ArrowUp size={16} />
                                </button>
                                <button
                                    className={styles['taytrom-action-button']}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        moveSection(section.id, 'down');
                                    }}
                                    aria-label="Move section down"
                                >
                                    <ArrowDown size={16} />
                                </button>
                                <button
                                    className={styles['taytrom-action-button']}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addSection(section.id);
                                    }}
                                    aria-label="Add subsection"
                                >
                                    <Plus size={16} />
                                </button>
                                <button
                                    className={styles['taytrom-action-button']}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteSection(section.id);
                                    }}
                                    aria-label="Delete section"
                                >
                                    <Trash2 size={16} />
                                </button>
                            </div>
                        </div>
                    </motion.div>

                    {hasChildren && isExpanded && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            {sections
                                .filter(s => s.parentId === section.id)
                                .sort((a, b) => (a.order || 0) - (b.order || 0))
                                .map(childSection => renderSection(childSection, level + 1))}
                        </motion.div>
                    )}
                </div>
            );
        };

        return (
            <div className={styles['taytrom-structural-view']} ref={structuralViewRef}>
                <div className={styles['taytrom-structural-header']}>
                    <h3>Structure View</h3>
                    <button
                        className={styles['taytrom-add-button']}
                        onClick={() => addSection()}
                        aria-label="Add new top-level section"
                    >
                        <Plus size={18} />
                    </button>
                </div>

                <div className={styles['taytrom-structural-content']}>
                    {topLevelSections.map(section => renderSection(section))}
                </div>
            </div>
        );
    };

    // Render the document view (left panel)
    const renderDocumentView = () => {
        const renderSectionContent = (section) => {
            if (section.type === 'bullet-list' && Array.isArray(section.content)) {
                return (
                    <ul className={styles['taytrom-bullet-list']}>
                        {section.content.map((item, index) => (
                            <li key={index} className={styles['taytrom-bullet-item']}>{item}</li>
                        ))}
                    </ul>
                );
            }

            return <p className={styles['taytrom-paragraph']}>{section.content}</p>;
        };

        const renderSection = (section) => {
            if (section.type === 'heading') {
                const HeadingTag = `h${section.level || 2}`;
                return (
                    <motion.div
                        key={section.id}
                        className={`${styles['taytrom-document-section']} ${activeSection === section.id ? styles['taytrom-active-document-section'] : ''}`}
                        onClick={() => setActiveSection(section.id)}
                        whileHover={{ backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)' }}
                    >
                        <HeadingTag className={styles['taytrom-section-heading']}>{section.title}</HeadingTag>
                        {section.content && <p className={styles['taytrom-paragraph']}>{section.content}</p>}
                    </motion.div>
                );
            }

            return (
                <motion.div
                    key={section.id}
                    className={`${styles['taytrom-document-section']} ${activeSection === section.id ? styles['taytrom-active-document-section'] : ''}`}
                    onClick={() => setActiveSection(section.id)}
                    whileHover={{ backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)' }}
                >
                    {section.title && <h3 className={styles['taytrom-section-title']}>{section.title}</h3>}
                    {renderSectionContent(section)}
                </motion.div>
            );
        };

        // Helper function to build hierarchical document
        const buildDocument = () => {
            // Get top-level sections
            const topLevelSections = sections
                .filter(section => section.parentId === null)
                .sort((a, b) => (a.order || 0) - (b.order || 0));

            const renderHierarchy = (parentSections) => {
                return parentSections.map(section => {
                    const childSections = sections
                        .filter(s => s.parentId === section.id)
                        .sort((a, b) => (a.order || 0) - (b.order || 0));

                    let children = null;
                    if (childSections.length > 0 && expandedSections[section.id]) {
                        children = (
                            <motion.div
                                className={styles['taytrom-nested-sections']}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.3 }}
                            >
                                {renderHierarchy(childSections)}
                            </motion.div>
                        );
                    }

                    return (
                        <React.Fragment key={section.id}>
                            {renderSection(section)}
                            {children}
                        </React.Fragment>
                    );
                });
            };

            return renderHierarchy(topLevelSections);
        };

        return (
            <div className={styles['taytrom-document-view']} ref={documentViewRef}>
                <div className={styles['taytrom-document-header']}>
                    <h2>Document Content</h2>
                </div>
                <div className={styles['taytrom-document-content']}>
                    {buildDocument()}
                </div>
            </div>
        );
    };

    // Render the Cheshire Cat guide
    const renderCheshireCat = () => {
        return (
            <motion.div
                className={styles['taytrom-cheshire-cat']}
                initial={{ opacity: 0 }}
                animate={{
                    opacity: [0, 1, 0.8, 1],
                    x: catPosition.x + '%',
                    y: catPosition.y + '%',
                }}
                transition={{
                    opacity: { duration: 2, repeat: Infinity, repeatType: 'reverse' },
                    x: { duration: 2 },
                    y: { duration: 2 }
                }}
                ref={catRef}
            >
                <div className={styles['taytrom-cat-face']}>
                    <div className={styles['taytrom-cat-eyes']}>
                        <motion.div
                            className={styles['taytrom-cat-eye']}
                            animate={{ scale: [1, 1.2, 1] }}
                            transition={{ duration: 3, repeat: Infinity }}
                        />
                        <motion.div
                            className={styles['taytrom-cat-eye']}
                            animate={{ scale: [1, 1.2, 1] }}
                            transition={{ duration: 3, repeat: Infinity, delay: 0.5 }}
                        />
                    </div>
                    <motion.div
                        className={styles['taytrom-cat-smile']}
                        animate={{ scale: [1, 1.1, 1] }}
                        transition={{ duration: 4, repeat: Infinity }}
                    />
                </div>

                <AnimatePresence>
                    {showGuideTip && (
                        <motion.div
                            className={styles['taytrom-guide-tip']}
                            initial={{ opacity: 0, y: 20, scale: 0.8 }}
                            animate={{ opacity: 1, y: 0, scale: 1 }}
                            exit={{ opacity: 0, y: 20, scale: 0.8 }}
                            transition={{ duration: 0.5 }}
                        >
                            <p>{guideTips[tipIndex]}</p>
                            <button
                                className={styles['taytrom-close-tip-button']}
                                onClick={() => setShowGuideTip(false)}
                                aria-label="Close tip"
                            >
                                <X size={14} />
                            </button>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        );
    };

    // Render floating decoration elements
    const renderFloatingElements = () => {
        return floating.map(item => (
            <motion.div
                key={item.id}
                className={styles['taytrom-floating-item']}
                initial={{
                    left: `${item.x}%`,
                    top: `${item.y}%`,
                    opacity: 0
                }}
                animate={{
                    left: [`${item.x}%`, `${(item.x + 30) % 100}%`, `${(item.x + 15) % 100}%`, `${item.x}%`],
                    top: [`${item.y}%`, `${(item.y + 20) % 100}%`, `${(item.y - 15) % 100}%`, `${item.y}%`],
                    opacity: [0, 1, 1, 0]
                }}
                transition={{
                    duration: item.duration,
                    repeat: Infinity,
                    delay: item.delay,
                    ease: "easeInOut"
                }}
                style={{ fontSize: `${item.size}rem` }}
            >
                {item.emoji}
            </motion.div>
        ));
    };

    // Background style based on time of day
    const getBackgroundStyle = () => {
        switch(timeOfDay) {
            case 'sunset':
                return {
                    background: isDark
                        ? 'linear-gradient(to bottom, #0f2027, #203a43, #2c5364)'
                        : 'linear-gradient(to bottom, #ff7e5f, #feb47b)'
                };
            case 'night':
                return {
                    background: isDark
                        ? 'linear-gradient(to bottom, #0f0c29, #302b63, #24243e)'
                        : 'linear-gradient(to bottom, #2c3e50, #4ca1af)'
                };
            default: // day
                return {
                    background: isDark
                        ? 'linear-gradient(to bottom, #134e5e, #71b280)'
                        : 'linear-gradient(to bottom, #2980b9, #6dd5fa, #ffffff)'
                };
        }
    };

    // Render feature highlight overlay
    const renderFeatureHighlight = () => {
        if (!highlightedFeature) return null;

        let highlightText = "";

        switch(highlightedFeature) {
            case 'addSection':
                highlightText = "Add a new section to your document!";
                break;
            case 'deleteSection':
                highlightText = "Remove unwanted sections with the trash button";
                break;
            case 'moveSection':
                highlightText = "Rearrange your sections in any order";
                break;
            case 'toggleExpand':
                highlightText = "Expand and collapse section hierarchies";
                break;
            default:
                return null;
        }

        return (
            <motion.div
                className={styles['taytrom-feature-highlight']}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className={styles['taytrom-highlight-bubble']}>
                    {highlightText}
                </div>
            </motion.div>
        );
    };

    // Time indicator
    const renderTimeIndicator = () => {
        let icon;
        let text;

        switch(timeOfDay) {
            case 'sunset':
                icon = <Sun size={18} />;
                text = "Sunset in Wonderland";
                break;
            case 'night':
                icon = <Moon size={18} />;
                text = "Night in Wonderland";
                break;
            default:
                icon = <Cloud size={18} />;
                text = "Day in Wonderland";
        }

        return (
            <div className={styles['taytrom-time-indicator']}>
                {icon}
                <span>{text}</span>
            </div>
        );
    };

    // Main render
    return (
        <div
            className={`${styles['taytrom-container']} ${isDark ? styles['taytrom-dark-theme'] : styles['taytrom-light-theme']}`}
            style={getBackgroundStyle()}
        >
            {/* Background elements */}
            {renderFloatingElements()}

            {/* Cheshire Cat guide */}
            {renderCheshireCat()}

            {/* Two-panel layout */}
            <div className={styles['taytrom-panels-container']}>
                {/* Left panel - Document View */}
                <motion.div
                    className={styles['taytrom-panel']}
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    {renderDocumentView()}
                </motion.div>

                {/* Right panel - Structural View */}
                <motion.div
                    className={styles['taytrom-panel']}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    {renderStructuralView()}
                </motion.div>
            </div>

            {/* Feature highlight overlay */}
            <AnimatePresence>
                {highlightedFeature && renderFeatureHighlight()}
            </AnimatePresence>

            {/* Time indicator */}
            {renderTimeIndicator()}

            {/* Navigation controls */}
            <div className={styles['taytrom-navigation-controls']}>
                <motion.button
                    className={styles['taytrom-nav-button']}
                    onClick={onPrevious}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    Previous
                </motion.button>

                <motion.button
                    className={styles['taytrom-nav-button']}
                    onClick={onNext}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    Next
                </motion.button>
            </div>

            {/* Show guide button - to toggle the cat's tips */}
            <motion.button
                className={`${styles['taytrom-show-guide-button']} ${showGuideTip ? styles['taytrom-active'] : ''}`}
                onClick={() => setShowGuideTip(!showGuideTip)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                <Coffee size={20} />
                <span>{showGuideTip ? "Hide Guide" : "Show Guide"}</span>
            </motion.button>
        </div>
    );
};

export default Compiler_2_Steps_InteractiveDemo_Specialists_Writing;