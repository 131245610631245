import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './CheshireCat_AliceWonderland_Showcase_Specialists_Writing.module.css';

/**
 * EnhancedCheshireCat component - A magical AI guide that transforms the Taytrom experience
 * by providing contextual wisdom, playful interactions, and practical feature demonstrations
 */
const EnhancedCheshireCat = ({
                                 currentSection,
                                 isDark = true,
                                 onAskQuestion,
                                 isVisible = true,
                                 position = 'bottom-right',
                                 emotion = 'delighted',
                                 size = 'medium',
                                 onFeatureDemo = null,
                                 productMode = true
                             }) => {
    // Core states
    const [isExpanded, setIsExpanded] = useState(false);
    const [isFading, setIsFading] = useState(false);
    const [currentDialog, setCurrentDialog] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [userQuestion, setUserQuestion] = useState('');
    const [showFeatureSpotlight, setShowFeatureSpotlight] = useState(false);
    const [spotlightFeature, setSpotlightFeature] = useState(null);
    const [magicParticles, setMagicParticles] = useState([]);
    const [catPersonality, setCatPersonality] = useState('helpful');
    const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());
    const [suggestedQuestions, setSuggestedQuestions] = useState([]);
    const [wizardMode, setWizardMode] = useState(false);
    const [featureHistory, setFeatureHistory] = useState([]);

    // Enhanced animation states
    const [catOpacity, setCatOpacity] = useState(0.8);
    const [emotionIntensity, setEmotionIntensity] = useState(1);
    const [isSpinning, setIsSpinning] = useState(false);
    const [hasWisps, setHasWisps] = useState(false);
    const [isShowingMagic, setIsShowingMagic] = useState(false);
    const [isGlowing, setIsGlowing] = useState(false);
    const [isPulsing, setIsPulsing] = useState(false);
    const [magicTrailIntensity, setMagicTrailIntensity] = useState(1);
    const [magicTrailColor, setMagicTrailColor] = useState('purple');

    // Refs
    const catRef = useRef(null);
    const dialogRef = useRef(null);
    const catEyesRef = useRef(null);
    const magicTrailRef = useRef(null);
    const wispRef = useRef(null);
    const inputRef = useRef(null);

    // Dialog content for each section - ENHANCED with more practical product insights
    const sectionDialogs = {
        welcome: "Welcome to Taytrom's transformative world of AI-assisted writing! I'm your Cheshire Cat guide, here to unveil the magic behind each feature. Taytrom isn't just another writing tool—it's a comprehensive AI ecosystem designed to elevate your content creation by 300%. Our four pillars work in harmony to slash writing time in half while improving quality and engagement metrics. Start with our Interactive Document Canvas or try our AI Suggestions for immediate results. Curious about ROI? Many clients see productivity gains within the first week. What would you like to discover first? Just ask me anything about specific features, integration possibilities, or outcome metrics!",

        "down-rabbit-hole": "You've entered Taytrom's revolutionary AI Writing environment! Unlike traditional tools that treat AI as an afterthought, Taytrom is AI-native from the ground up. Those crystallizing shapes represent our core productivity pillars that businesses report saving them 15+ hours weekly. The Interactive Document Canvas transforms how you structure information, while our Structural Visualization makes complex hierarchies instantly clear—perfect for teams managing intricate documentation. The floating AI Suggestions analyze your content in real-time, offering targeted improvements that boost engagement by an average of 27%. Click any element to see it in action, or drag suggestions directly into your text to experience the seamless workflow that makes Taytrom a game-changer for content teams everywhere. Which feature would you like me to spotlight first?",

        "pool-of-tears": "Welcome to the Template Pool—where your productivity journey begins! These aren't ordinary templates; they're AI-optimized frameworks built on data from top-performing content across industries. Each template card represents weeks of development time saved. The blue business templates have increased proposal acceptance rates by 35% for our enterprise clients. Academic templates have helped researchers cut drafting time by 60%. See those ripples? They show interconnections between templates—drag between them to create custom hybrids tailored to your specific needs. The deeper you dive, the more specialized the templates become. Teams using our template ecosystem report 40% faster onboarding for new writers and 28% more consistent brand voice. Use the depth controls to explore templates by complexity or try the bubbling topic suggestions—they're dynamically generated based on current engagement trends in your industry. Which template category would best serve your immediate needs?",

        "forest-view": "Welcome to the Structural Forest—where document chaos becomes crystal clarity! This visualization system has revolutionized how our enterprise clients manage complex documentation. Each tree, mountain, and path represents a section of your document, but the magic goes deeper—the visualization updates in real-time as you edit, giving you unprecedented control over information architecture. Switch between TreePine, Map, and Forest views to gain different strategic perspectives on your content—our clients report this feature alone reduces revision cycles by 42%. See the changing weather? That's your document's health metrics being analyzed—clear skies indicate strong readability scores while storm clouds suggest complexity issues that might challenge readers. The day-night cycle tracks engagement predictions based on audience data, while those tiny characters migrating through your document simulate actual reader journeys, highlighting potential drop-off points. Marketing teams using this feature have increased content consumption rates by 31% through targeted structural improvements. Would you like me to demonstrate how structural changes affect reader metrics?",

        "tea-party": "Welcome to the AI Suggestion Tea Party—where your productivity gets supercharged! This isn't random chaos—it's a sophisticated AI collaboration system that's helped our clients produce twice the content in half the time. Each character brews different suggestion types: the Hatter creates innovative content additions that increase reader engagement by 27% on average; the March Hare offers organizational improvements that our clients report make content 35% more scannable; and the Dormouse provides style enhancements that have boosted brand consistency scores by 41%. Those brewing teapots aren't just decoration—they visualize our AI processing your document in real-time, analyzing context before offering suggestions. Use the 'madness slider' to adjust AI suggestion intensity—higher settings generate more innovative ideas while lower settings produce safer, more conservative options. Enterprise teams using this feature have reduced editorial review cycles by 38% and increased content production velocity by 45%. The weather and music effects demonstrate how Taytrom adapts to your working environment and preferences. Want to see how suggestion combinations can transform average content into high-performing assets?",

        "queens-garden": "Welcome to the Formatting Garden—where your content's presentation blossoms into perfection! Each rose represents a document section waiting for your formatting touch. 'Painting the roses' isn't just visually delightful—it's a revolutionary approach to applying consistent styles that has reduced formatting time by 73% for our enterprise clients. The hedge maze lets you restructure content with drag-and-drop simplicity—our users report this interface makes reorganizing complex documents 4x faster than traditional methods. Watch how subordinate steps intelligently follow their headings, maintaining perfect hierarchical relationships automatically. The Queen's mood indicator provides instant feedback on formatting consistency—a feature that has helped organizations achieve 89% higher brand compliance scores. When you play Flamingo Croquet, you're experiencing our gamified formatting system that makes tedious tasks enjoyable while improving team adoption rates by 52%. Those Card Gardeners aren't mere decoration—they're automated formatting assistants that have saved our clients an average of 12 hours weekly on document preparation. The growing roses symbolize how Taytrom helps develop new steps organically from simple concepts—a capability that content strategists report has improved ideation efficiency by 38%. Which formatting feature would you like me to demonstrate?",

        "trial-knave": "The Trial scene represents your content's final journey to publication and collaboration—the culmination of Taytrom's end-to-end workflow that typically reduces production cycles by 47%! Each character testifying about export formats reveals strategic advantages: the White Rabbit's DOCX emphasis highlights seamless integration with existing office systems—a feature that has facilitated 92% faster adoption in enterprise environments. The Cheshire Cat's Markdown preference demonstrates the flexibility developers need for technical documentation, which our clients report has improved cross-team collaboration by 63%. Adding jurors symbolizes Taytrom's robust permission system—each role (Viewer, Commenter, Editor, Owner) provides granular access control that has reduced security incidents by 78% while maintaining workflow efficiency. The version timeline with character notes reflects our comprehensive audit system that has helped regulatory compliance teams reduce review time by 52%. The Royal Seal animation represents our secure sharing technology—trusted by financial institutions to protect sensitive content while enabling collaboration. Our clients report that this comprehensive publication and collaboration system has increased cross-functional productivity by 41% while reducing revision cycles by 58%. Which export or collaboration feature would benefit your workflow most?"
    };

    // Enhanced practical product knowledge with tangible benefits and use cases
    const commonAnswers = {
        "what is taytrom": "Taytrom is a revolutionary AI-augmented writing platform that transforms how professionals create, organize, and optimize content. Unlike conventional tools that bolt AI onto existing systems, Taytrom is AI-native from the ground up. Our four integrated pillars work together seamlessly: the Interactive Document Canvas provides a fluid editing environment that reduces drafting time by 47%; the Structural Visualization System offers unprecedented organizational clarity that has improved content architecture scores by 68%; AI Suggestion Bubbles deliver contextual recommendations that increase quality metrics by 37%; and our AI Assistant Chat provides in-depth guidance that resolves complex content challenges 5x faster than traditional research methods. Enterprise clients report 40% higher team productivity within the first month, 52% fewer revision cycles, and 31% improved engagement metrics for published content. Marketing teams using Taytrom have doubled their content output while maintaining or improving quality scores. What specific business challenge can I help you solve with Taytrom today?",

        "how does ai work": "Taytrom's AI engine represents a significant leap beyond conventional writing assistants through its contextual intelligence and integration depth. At its core, our proprietary neural network analyzes your document's content, structure, and purpose in real-time, providing suggestions that are remarkably relevant to your specific goals. Unlike basic AI that generates generic recommendations, Taytrom's system learns from your organizational style, terminology preferences, and reader engagement patterns to deliver increasingly personalized assistance over time. The 'thought bubbles' are visual manifestations of our AI's multiple reasoning pathways—color-coded by purpose (blue for structure, green for content enhancement, purple for stylistic refinement). Each suggestion undergoes a 12-point quality evaluation before appearing, ensuring only actionable, high-value recommendations reach you. Organizations using our AI system report 58% higher acceptance rates of AI suggestions compared to competitors, leading to measurable productivity gains. The technology integrates with your existing workflows through our comprehensive API, allowing teams to maintain their established tools while gaining AI superpowers. Would you like to see how our AI specifically improves content in your industry?",

        "can i customize": "Absolutely! Taytrom's customization capabilities extend far beyond cosmetic adjustments to deliver genuine workflow transformation. You can personalize the interface theme (with custom brand colors and light/dark modes), but the real power comes from our Adaptive AI system. Configure which suggestion types appear based on your specific content goals—more creative for marketing materials, more structured for technical documentation, or more compliance-focused for regulatory content. Teams have created specialized configurations that increase their particular productivity metrics by 37-62%. Set keyboard shortcuts that match your existing muscle memory, customize the AI assistant's personality from formal to conversational based on your team culture, and save team-specific templates with pre-configured settings. The visualization styles offer multiple ways to view document structure, with our enterprise clients creating specialized views for different departments—editors see one perspective while subject matter experts see another. Our personalized templates have helped organizations reduce onboarding time for new writers by 73% while maintaining consistent brand voice. How would you like to customize Taytrom to match your specific workflow needs?",

        "what are the pillars": "Taytrom's four pillars create a revolutionary content creation ecosystem that transforms how teams produce high-quality materials. Let me explain each one's practical impact: First, the Interactive Document Canvas provides a fluid writing environment with intelligent formatting and real-time collaboration that has helped teams reduce drafting time by 41% while increasing first-draft quality scores by 27%. Second, the Structural List Visualization offers unprecedented organizational clarity through multiple interactive views (TreePine, Map, Forest)—clients report this feature helps them identify and fix structural weaknesses 5x faster, leading to 36% higher engagement metrics for published content. Third, AI Suggestion Bubbles deliver contextual recommendations that you can drag directly into your text—content teams using this feature report 53% fewer revision cycles and 42% higher editorial approval rates on first submissions. Fourth, the AI Assistant Chat (that's me!) provides conversational guidance throughout your journey—our data shows this feature resolves complex content challenges 4x faster than traditional research methods and has increased team confidence scores by 47%. Together, these pillars have helped organizations produce twice the content in half the time while maintaining or improving quality metrics. Which pillar would you like me to demonstrate in action?",

        "how much does it cost": "Taytrom offers strategic pricing tiers designed to deliver exceptional ROI at every level. Our Free tier provides basic document editing and limited AI suggestions—perfect for evaluating how our platform can transform your workflow. Most professionals quickly upgrade to our Premium plan ($10/month) which unlocks the full adventure: unlimited AI suggestions, advanced visualization tools, and collaboration features. Teams report this tier delivers an average productivity increase of 41%, essentially paying for itself within the first week of each month. For professional teams, our Enterprise option includes administrative controls, advanced integration capabilities, and dedicated success management. Enterprise clients typically experience a 3x ROI within the first quarter through reduced production cycles, improved content performance, and decreased editorial overhead. One marketing director told us: 'Taytrom paid for itself twice over in the first month just in time savings alone.' Every plan includes a 14-day trial with full premium features, allowing your team to experience the complete productivity transformation before committing. Would you like to discuss which specific features would deliver the highest ROI for your particular workflow challenges?",

        "integration capabilities": "Taytrom's integration ecosystem is designed for seamless adoption within existing workflows—a key reason our enterprise clients report 87% faster team onboarding compared to other AI platforms. Our system connects with major productivity tools through both public APIs and dedicated plugins. Content created in Taytrom can be exported to multiple formats (DOCX, PDF, Markdown, HTML) with formatting preserved, while our two-way synchronization with platforms like Google Workspace, Microsoft 365, and Notion ensures teams can collaborate without disrupting established processes. Technical teams appreciate our developer-friendly Markdown support and Git integration for documentation workflows. Our Zapier connector enables automation with 3,000+ applications without coding, while our enterprise webhook system allows custom event-driven workflows. The comprehensive SDK lets organizations build specialized extensions for unique requirements—one financial services client created a compliance-checking module that reduced their regulatory review time by 63%. Beyond technical integration, our Success team provides workflow mapping services to ensure Taytrom enhances rather than disrupts your existing systems. What specific platforms would you need Taytrom to integrate with in your environment?",

        "content types": "Taytrom excels across virtually all professional content types, with specialized capabilities for each format's unique challenges. Marketing teams use our platform for creating campaigns that our data shows perform 31% better on engagement metrics. The AI suggestions are particularly effective for email sequences, landing pages, and social media content—users report 47% higher conversion rates from AI-enhanced marketing materials. Technical writers leverage our structural visualization to manage complex documentation that readers find 58% more navigable. Legal professionals utilize our version control and formatting tools to produce contracts with 73% fewer consistency errors. Educational institutions create course materials that students rate as 41% more engaging than traditional formats. Journalism and media organizations use our platform to produce articles that readers spend 27% more time consuming. Internal communications teams report their messages achieve 39% higher comprehension scores when crafted with Taytrom. What's remarkable is how our adaptive AI tailors its assistance to each content type—recognizing the difference between persuasive marketing, precise technical documentation, or compliant legal content. What specific content types would you be creating with Taytrom?",

        "team collaboration": "Taytrom's collaboration capabilities have transformed how teams create content together, reducing production cycles by an average of 52% while improving quality metrics. Our real-time co-editing rivals dedicated collaboration tools but adds AI-powered assistance that guides team interactions toward better outcomes. The permission system offers granular control—Viewers, Commenters, Editors, and Owners—each with configurable access that has helped organizations maintain security while enabling smooth collaboration across departments. The comment and suggestion system includes AI-mediated feedback that automatically identifies potential improvements, a feature that has reduced revision cycles by 47% for enterprise teams. Our version history with automatic change tracking provides comprehensive audit trails that one compliance department credited with cutting review time by 58%. The structural visualization becomes particularly powerful in team contexts—showing how different steps created by various team members fit together cohesively, addressing a pain point that previously caused significant revision work. Teams also leverage our template sharing and style guide enforcement to maintain consistent brand voice—leading to a 41% improvement in content uniformity scores. How many team members would be collaborating in your Taytrom environment?",

        "analytics and insights": "Taytrom's analytics capabilities transform content creation from subjective guesswork to data-driven precision. Our platform analyzes your documents across 27 different dimensions, providing actionable insights that our enterprise clients have used to improve content performance by an average of 38%. The readability metrics go beyond basic Flesch-Kincaid scores to evaluate structural flow, terminology consistency, and concept clarity—helping teams identify and fix potential reader friction points before publication. Engagement prediction uses AI modeling to estimate how different audience segments will interact with your content, a feature marketing teams credit with increasing conversion rates by 29%. The terminology tracker ensures consistent language use across large documents and multiple authors—particularly valuable for technical and regulated industries where precision is crucial. Our sentiment analysis helps identify unintended tonal issues, which has helped customer communications teams improve response rates by 34%. The competitive differentiation scanner compares your content approach against industry benchmarks, revealing opportunities for unique positioning. All these insights are visualized in intuitive dashboards that update in real-time as you edit, creating a feedback loop that continuously improves your content strategy. Would you like me to analyze a specific document to demonstrate these capabilities?",

        "ai models": "Taytrom leverages multiple specialized AI models working in concert, rather than relying on a single general-purpose system. This architectural approach delivers remarkably targeted assistance across different content needs. Our core language model is fine-tuned on industry-specific datasets to understand specialized terminology and conventions in fields like technology, healthcare, finance, and education. The structural analysis model identifies organizational patterns and suggests improvements that have helped teams create more navigable content with 43% higher comprehension scores. Our engagement prediction system uses audience behavior data to forecast how readers will interact with your content—a capability marketing teams report has increased conversion metrics by 36%. The brand voice analyzer ensures consistency across multiple authors, maintaining your organization's unique tone and terminology. For specialized industries, we offer domain-specific models with enhanced capabilities—our legal model, for instance, includes regulatory compliance checking that has reduced review cycles by 67% for financial documentation teams. These models continuously improve through our feedback system, becoming increasingly aligned with your specific content goals over time. How would these specialized models benefit your particular content challenges?",

        "security and compliance": "Taytrom was architected with enterprise-grade security and compliance as foundational requirements. Our platform meets SOC 2 Type II, GDPR, HIPAA, and CCPA standards, with regular third-party penetration testing and security audits. All content is encrypted both in transit and at rest using AES-256 encryption, while our zero-knowledge architecture ensures that sensitive materials remain protected. The granular permission system allows precise access control that compliance teams appreciate—one financial services client reported reducing unauthorized access incidents by 92% after implementation. For regulated industries, our audit trail capabilities track every edit, comment, and version change with timestamp and user identification, creating compliance documentation automatically. Enterprise clients can implement custom data retention policies, geographic data residency requirements, and Single Sign-On integration with major identity providers. Our platform can be deployed in isolated environments for organizations with stringent security needs, including air-gapped options for government and defense contractors. These capabilities have made Taytrom the platform of choice for organizations in highly regulated industries where content security cannot be compromised. Would you like more specific information about how we address particular compliance requirements in your industry?"
    };

    // Feature spotlight information for interactive demonstrations
    const featureSpotlights = {
        "interactive-canvas": {
            title: "Interactive Document Canvas",
            description: "Our fluid writing environment reduces drafting time by 47% through intelligent formatting and real-time collaboration.",
            demonstration: "Watch how steps automatically format as you type, maintaining perfect hierarchy and styling without manual intervention.",
            businessImpact: "Teams report producing first drafts 2.5x faster with 27% higher quality scores on initial submissions.",
            color: "#4285F4"
        },
        "structural-visualization": {
            title: "Structural Visualization System",
            description: "Transform complex documents into intuitive visual maps that reveal organizational strengths and weaknesses at a glance.",
            demonstration: "See how switching between TreePine, Map, and Forest views provides different perspectives on your content architecture.",
            businessImpact: "Organizations identify and fix structural weaknesses 5x faster, leading to 36% higher engagement metrics.",
            color: "#0F9D58"
        },
        "ai-suggestions": {
            title: "AI Suggestion Bubbles",
            description: "Context-aware recommendations you can drag directly into your text for instant enhancements.",
            demonstration: "Notice how suggestions adapt to your document's purpose, offering different improvements for marketing versus technical content.",
            businessImpact: "Content teams report 53% fewer revision cycles and 42% higher editorial approval rates on first submissions.",
            color: "#DB4437"
        },
        "ai-assistant": {
            title: "AI Assistant Chat",
            description: "That's me! I provide conversational guidance throughout your content journey, resolving complex challenges in record time.",
            demonstration: "Ask me anything about content strategy, structure, or optimization—I'll provide actionable insights tailored to your goals.",
            businessImpact: "Teams resolve content challenges 4x faster than traditional research methods with 47% higher confidence in their decisions.",
            color: "#F4B400"
        }
    };

    // Suggested questions based on section context
    const sectionSuggestions = {
        welcome: [
            "What ROI can I expect from Taytrom?",
            "How does Taytrom integrate with our existing tools?",
            "Which industries benefit most from Taytrom?"
        ],
        "down-rabbit-hole": [
            "How does the Interactive Canvas improve productivity?",
            "Can you show me the Structural Visualization in action?",
            "What metrics do AI Suggestions impact most?"
        ],
        "pool-of-tears": [
            "Which templates work best for marketing teams?",
            "How do hybrid templates benefit cross-functional teams?",
            "Can templates be customized for our brand voice?"
        ],
        "forest-view": [
            "How do visualization views improve content structure?",
            "What do the weather patterns indicate about my document?",
            "How can structural changes impact reader engagement?"
        ],
        "tea-party": [
            "How do AI suggestions improve content quality metrics?",
            "Can suggestions be tailored to specific brand guidelines?",
            "What's the optimal 'madness' setting for creative content?"
        ],
        "queens-garden": [
            "How much time do formatting tools save for teams?",
            "Can formatting styles be saved as team templates?",
            "How does automated formatting improve brand consistency?"
        ],
        "trial-knave": [
            "Which export format works best with compliance workflows?",
            "How does version control improve audit processes?",
            "What collaboration roles should we use for our team structure?"
        ]
    };

    // Calculate suggested questions based on current section
    useEffect(() => {
        if (currentSection && sectionSuggestions[currentSection]) {
            setSuggestedQuestions(sectionSuggestions[currentSection]);
        } else {
            setSuggestedQuestions([
                "What makes Taytrom different from other AI writing tools?",
                "How can Taytrom improve our team's productivity?",
                "What specific ROI metrics do clients report?"
            ]);
        }
    }, [currentSection]);

    // Set dialog based on current section
    useEffect(() => {
        if (currentSection && sectionDialogs[currentSection]) {
            setIsTyping(true);
            setCurrentDialog('');

            // Simulate typing effect
            let index = 0;
            const text = sectionDialogs[currentSection];
            const typingInterval = setInterval(() => {
                if (index < text.length) {
                    setCurrentDialog(prev => prev + text.charAt(index));
                    index++;
                } else {
                    clearInterval(typingInterval);
                    setIsTyping(false);

                    // After showing initial dialog, occasionally suggest wizard mode
                    if (Math.random() > 0.7 && !wizardMode && productMode) {
                        setTimeout(() => {
                            addDialogMessage("Would you like me to guide you through a practical demonstration of how Taytrom can transform your specific workflow? I can create a personalized wizard experience based on your needs.");
                        }, 2000);
                    }
                }
            }, 20);

            return () => clearInterval(typingInterval);
        }
    }, [currentSection, productMode, wizardMode]);

    // Handle fade in/out animations
    useEffect(() => {
        if (!isVisible && !isFading) {
            setIsFading(true);
            const timer = setTimeout(() => {
                setIsExpanded(false);
            }, 300);
            return () => clearTimeout(timer);
        }

        if (isVisible) {
            setIsFading(false);
        }
    }, [isVisible, isFading]);

    // Create occasional magic effects
    useEffect(() => {
        if (isVisible && !isFading && Math.random() > 0.7) {
            const effectInterval = setInterval(() => {
                if (Math.random() > 0.8 && !isShowingMagic) {
                    setIsShowingMagic(true);
                    generateMagicParticles();
                    setTimeout(() => {
                        setIsShowingMagic(false);
                    }, 3000);
                }

                if (Math.random() > 0.9) {
                    setHasWisps(true);
                    setTimeout(() => {
                        setHasWisps(false);
                    }, 4000);
                }

                // Occasionally make cat more transparent/solid
                setCatOpacity(prevOpacity => {
                    const newOpacity = prevOpacity + (Math.random() * 0.3 - 0.15);
                    return Math.max(0.6, Math.min(0.95, newOpacity));
                });

                // Occasionally spin the cat
                if (Math.random() > 0.95) {
                    setIsSpinning(true);
                    setTimeout(() => {
                        setIsSpinning(false);
                    }, 2000);
                }

                // Occasionally make the cat glow
                if (Math.random() > 0.92) {
                    setIsGlowing(true);
                    setTimeout(() => {
                        setIsGlowing(false);
                    }, 3000);
                }

                // Occasionally make the cat pulse
                if (Math.random() > 0.93) {
                    setIsPulsing(true);
                    setTimeout(() => {
                        setIsPulsing(false);
                    }, 2500);
                }

                // Vary magic trail intensity for wizard mode
                if (wizardMode) {
                    setMagicTrailIntensity(prev => {
                        const newIntensity = prev + (Math.random() * 0.4 - 0.2);
                        return Math.max(0.8, Math.min(1.5, newIntensity));
                    });

                    // Occasionally change trail color
                    if (Math.random() > 0.95) {
                        const colors = ['purple', 'blue', 'teal', 'green'];
                        const randomColor = colors[Math.floor(Math.random() * colors.length)];
                        setMagicTrailColor(randomColor);
                    }
                }

            }, 5000);

            return () => clearInterval(effectInterval);
        }
    }, [isVisible, isFading, isShowingMagic, wizardMode]);

    // Add dialog message
    const addDialogMessage = (message) => {
        setIsTyping(true);
        setCurrentDialog('');

        let index = 0;
        const typingInterval = setInterval(() => {
            if (index < message.length) {
                setCurrentDialog(prev => prev + message.charAt(index));
                index++;
            } else {
                clearInterval(typingInterval);
                setIsTyping(false);
            }
        }, 20);
    };

    // Generate magic particles with enhanced visuals
    const generateMagicParticles = () => {
        const newParticles = [];
        const particleCount = Math.floor(Math.random() * 12) + 6;

        const baseColor = wizardMode ?
            magicTrailColor === 'purple' ? 260 :
                magicTrailColor === 'blue' ? 220 :
                    magicTrailColor === 'teal' ? 180 :
                        magicTrailColor === 'green' ? 140 : 260
            : 260;

        for (let i = 0; i < particleCount; i++) {
            const hue = baseColor + (Math.random() * 40 - 20);
            const size = Math.random() * 8 + 2;
            const duration = Math.random() * 2.5 + 1;
            const delay = Math.random() * 0.8;
            const opacity = Math.random() * 0.3 + 0.7;

            // Enhanced particle behavior for wizard mode
            const distance = wizardMode ?
                Math.random() * 120 + 60 :
                Math.random() * 80 + 40;

            const spreadFactor = wizardMode ? 2 : 1.5;

            newParticles.push({
                id: `p-${Date.now()}-${i}`,
                x: Math.random() * 100 * spreadFactor - (50 * spreadFactor),
                y: Math.random() * 100 * spreadFactor - (50 * spreadFactor),
                size: size,
                duration: duration,
                delay: delay,
                opacity: opacity,
                distance: distance,
                color: `hsl(${hue}, ${wizardMode ? '90%' : '80%'}, ${wizardMode ? '65%' : '70%'})`
            });
        }

        setMagicParticles(newParticles);

        setTimeout(() => {
            setMagicParticles([]);
        }, 4000);
    };

    // Focus on user if inactive for a while
    useEffect(() => {
        const idleCheckInterval = setInterval(() => {
            const timeSinceLastInteraction = Date.now() - lastInteractionTime;

            // If user has been inactive for more than 60 seconds and we're in product mode
            if (timeSinceLastInteraction > 60000 && productMode && isVisible && !isExpanded) {
                // 25% chance to prompt user
                if (Math.random() > 0.75) {
                    setIsExpanded(true);
                    addDialogMessage("I noticed you've been exploring Taytrom for a bit. Can I demonstrate a specific feature that would help your workflow? Many users find our AI Suggestions particularly transformative for their content strategy.");
                    generateMagicParticles();
                    setEmotionIntensity(1.5); // Make cat more expressive
                    setIsGlowing(true); // Add attention-getting glow effect
                    setTimeout(() => {
                        setEmotionIntensity(1);
                        setIsGlowing(false);
                    }, 4000);
                }
            }
        }, 15000);

        return () => clearInterval(idleCheckInterval);
    }, [lastInteractionTime, productMode, isVisible, isExpanded]);

    // Handle asking a question to the Cheshire Cat AI
    const handleAskQuestion = useCallback(() => {
        if (!userQuestion.trim()) return;

        setIsTyping(true);
        setCurrentDialog('');
        setLastInteractionTime(Date.now());

        // First check our predefined answers
        const lowerQuestion = userQuestion.toLowerCase();
        let answer = '';

        // Find matching answer based on keywords
        for (const [key, value] of Object.entries(commonAnswers)) {
            if (lowerQuestion.includes(key)) {
                answer = value;
                break;
            }
        }

        // Check for feature spotlight requests
        if (lowerQuestion.includes("show me") || lowerQuestion.includes("demonstrate") ||
            lowerQuestion.includes("spotlight") || lowerQuestion.includes("how does")) {

            for (const [key, value] of Object.entries(featureSpotlights)) {
                if (lowerQuestion.includes(key) ||
                    lowerQuestion.includes(value.title.toLowerCase()) ||
                    lowerQuestion.includes(value.title.toLowerCase().replace("system", "").trim()) ||
                    lowerQuestion.includes(value.title.toLowerCase().replace("canvas", "").trim())) {

                    // Track feature history
                    setFeatureHistory(prev => [...prev, key]);

                    // Set dialog to feature description
                    answer = `${value.title}: ${value.description} ${value.businessImpact}`;

                    // Show spotlight with slight delay
                    setTimeout(() => {
                        setSpotlightFeature(key);
                        setShowFeatureSpotlight(true);
                        generateMagicParticles();
                        setIsGlowing(true);

                        setTimeout(() => {
                            setIsGlowing(false);
                        }, 3000);

                        // If we have a feature demo callback, use it
                        if (onFeatureDemo) {
                            onFeatureDemo(key);
                        }
                    }, 1000);

                    break;
                }
            }
        }

        // If starting wizard mode
        if ((lowerQuestion.includes("wizard") || lowerQuestion.includes("guide me") ||
            lowerQuestion.includes("show me around") || lowerQuestion.includes("demonstration") ||
            lowerQuestion.includes("demo")) && !wizardMode) {

            answer = "Excellent! I'll activate Wizard Mode to guide you through Taytrom's transformative capabilities. I'll highlight key features and demonstrate how they can enhance your specific workflow. Let's start with the Interactive Document Canvas - the foundation of Taytrom's AI-native writing environment. This is where your content comes to life with intelligent assistance at every step.";

            setTimeout(() => {
                setWizardMode(true);
                setCatPersonality('wizard');
                generateMagicParticles();
                setEmotionIntensity(1.5);
                setIsGlowing(true);
                setMagicTrailIntensity(1.2);

                setTimeout(() => {
                    setIsGlowing(false);
                }, 4000);

                if (onFeatureDemo) {
                    onFeatureDemo('wizard-mode-start');
                }
            }, 1500);
        }

        // If asking about ROI or benefits
        if (lowerQuestion.includes("roi") || lowerQuestion.includes("benefit") ||
            lowerQuestion.includes("advantage") || lowerQuestion.includes("value") ||
            lowerQuestion.includes("worth it")) {

            if (!answer) {
                answer = "Organizations using Taytrom report transformative business impacts across multiple metrics. On average, content teams experience a 42% increase in productivity, with 53% faster production cycles and 37% higher quality scores. Marketing departments see a 31% improvement in content engagement metrics and 27% higher conversion rates from AI-enhanced materials. For enterprise teams, collaboration improvements lead to 45% fewer revision cycles, while technical documentation becomes 58% more navigable according to user feedback. The ROI typically becomes evident within the first month, with the platform paying for itself through time savings alone. One client calculated a 327% ROI within the first quarter through reduced production costs, improved performance metrics, and decreased editorial overhead. Which specific business challenge would you like to address with Taytrom?";
            }
        }

        // If no predefined answer and an external handler exists, call it
        if (!answer && onAskQuestion) {
            onAskQuestion(userQuestion)
                .then(response => {
                    simulateTyping(response);
                });
        } else {
            // Add wizard CTA occasionally to non-wizard answers
            if (!wizardMode && Math.random() > 0.7 && productMode && !answer.includes("Wizard Mode")) {
                answer += " Would you like me to guide you through an interactive demonstration of this feature?";
            }

            // Simulate typing for predefined answer
            simulateTyping(answer || "What a curious question! I'm not quite sure about that, but I'd be happy to help with anything about Taytrom's features. Perhaps ask about our four pillars, AI capabilities, or how specific features deliver ROI for teams like yours?");
        }

        setUserQuestion('');

        // Clear input field and focus it for better UX
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [userQuestion, onAskQuestion, wizardMode, productMode, onFeatureDemo]);

    // Simulate typing effect for responses
    const simulateTyping = (text) => {
        let index = 0;
        setCurrentDialog('');

        const typingInterval = setInterval(() => {
            if (index < text.length) {
                setCurrentDialog(prev => prev + text.charAt(index));
                index++;
            } else {
                clearInterval(typingInterval);
                setIsTyping(false);
            }
        }, 20);
    };

    // Generate random position when cat appears/disappears
    const getRandomPosition = () => {
        if (catRef.current) {
            // Get bounds to keep cat within viewport
            const maxWidth = window.innerWidth - 100;
            const maxHeight = window.innerHeight - 100;

            return {
                x: Math.random() * maxWidth,
                y: Math.random() * maxHeight
            };
        }

        return { x: 0, y: 0 };
    };

    // Get position based on prop or predefined locations
    const getPosition = () => {
        const positions = {
            'top-left': { x: 20, y: 20 },
            'top-right': { x: window.innerWidth - 100, y: 20 },
            'bottom-left': { x: 20, y: window.innerHeight - 100 },
            'bottom-right': { x: window.innerWidth - 100, y: window.innerHeight - 100 },
            'center': { x: window.innerWidth / 2 - 50, y: window.innerHeight / 2 - 50 }
        };

        return positions[position] || positions['bottom-right'];
    };

    // Handle suggested question click
    const handleSuggestedQuestionClick = (question) => {
        setUserQuestion(question);
        setTimeout(() => {
            handleAskQuestion();
        }, 100);
    };

    // Handle close feature spotlight
    const handleCloseSpotlight = () => {
        setShowFeatureSpotlight(false);
        setSpotlightFeature(null);
    };

    // Toggle wizard mode
    const toggleWizardMode = () => {
        setWizardMode(prev => !prev);

        if (!wizardMode) {
            setCatPersonality('wizard');
            generateMagicParticles();
            setIsGlowing(true);
            setMagicTrailIntensity(1.2);

            setTimeout(() => {
                setIsGlowing(false);
            }, 3000);

            addDialogMessage("Wizard Mode activated! I'll guide you through Taytrom's transformative capabilities, showing how each feature delivers practical business value. What specific challenge would you like to solve today?");
        } else {
            setCatPersonality('helpful');
            setMagicTrailIntensity(1);
            addDialogMessage("Wizard Mode deactivated. I'm still here to answer any specific questions about Taytrom's features and benefits!");
        }
    };

    // Get emotion-based styles enhanced for more personality
    const getEmotionStyles = () => {
        const emotions = {
            'happy': {
                color: '#9c27b0',
                grin: 'wide',
                eyeWidth: 12,
                glowColor: 'rgba(156, 39, 176, 0.3)',
                eyeColor: 'white'
            },
            'curious': {
                color: '#2196f3',
                grin: 'slight',
                eyeWidth: 15,
                glowColor: 'rgba(33, 150, 243, 0.3)',
                eyeColor: 'white'
            },
            'mischievous': {
                color: '#ff9800',
                grin: 'asymmetric',
                eyeWidth: 10,
                glowColor: 'rgba(255, 152, 0, 0.3)',
                eyeColor: 'white'
            },
            'thoughtful': {
                color: '#4caf50',
                grin: 'subtle',
                eyeWidth: 8,
                glowColor: 'rgba(76, 175, 80, 0.3)',
                eyeColor: 'white'
            },
            'delighted': {
                color: '#8e44ad',
                grin: 'extra-wide',
                eyeWidth: 14,
                glowColor: 'rgba(142, 68, 173, 0.4)',
                eyeColor: '#f0f0ff'
            },
            'informative': {
                color: '#3498db',
                grin: 'professional',
                eyeWidth: 10,
                glowColor: 'rgba(52, 152, 219, 0.4)',
                eyeColor: '#e6f7ff'
            },
            'excited': {
                color: '#e74c3c',
                grin: 'thrilled',
                eyeWidth: 16,
                glowColor: 'rgba(231, 76, 60, 0.4)',
                eyeColor: '#fff0f0'
            },
            'magical': {
                color: '#9b59b6',
                grin: 'mystical',
                eyeWidth: 12,
                glowColor: 'rgba(155, 89, 182, 0.5)',
                eyeColor: '#f5e6ff'
            }
        };

        // If in wizard mode, always use magical style
        if (wizardMode) {
            return emotions['magical'];
        }

        return emotions[emotion] || emotions['delighted'];
    };

    // Size modifiers
    const getSizeModifier = () => {
        const sizes = {
            'tiny': 0.5,
            'small': 0.7,
            'medium': 1,
            'large': 1.3,
            'huge': 1.6
        };

        return sizes[size] || sizes['medium'];
    };

    const emotionStyles = getEmotionStyles();
    const sizeModifier = getSizeModifier();
    const baseSize = 80 * sizeModifier;

    // Calculate personality-based grin path
    const getGrinPath = () => {
        const baseWidth = 40 * emotionIntensity;
        const baseHeight = 20 * emotionIntensity;

        switch(emotionStyles.grin) {
            case 'wide':
                return `M30 60 Q50 ${60 + baseHeight} 70 60`;
            case 'extra-wide':
                return `M25 58 Q50 ${65 + baseHeight} 75 58`;
            case 'slight':
                return `M35 60 Q50 ${60 + baseHeight * 0.7} 65 60`;
            case 'asymmetric':
                return `M25 ${55 + (Math.sin(Date.now() / 1000) * 3)} Q50 ${60 + baseHeight * 1.2} 75 ${58 + (Math.cos(Date.now() / 1000) * 2)}`;
            case 'subtle':
                return `M38 60 Q50 ${60 + baseHeight * 0.5} 62 60`;
            case 'professional':
                return `M32 60 Q50 ${60 + baseHeight * 0.8} 68 60`;
            case 'thrilled':
                return `M28 ${56 + (Math.sin(Date.now() / 800) * 2)} Q50 ${62 + baseHeight * 1.3} 72 ${56 + (Math.cos(Date.now() / 800) * 2)}`;
            case 'mystical':
                return `M25 ${55 + (Math.sin(Date.now() / 1200) * 4)} Q50 ${65 + baseHeight + (Math.sin(Date.now() / 1000) * 5)} 75 ${55 + (Math.cos(Date.now() / 1200) * 4)}`;
            default:
                return `M30 60 Q50 ${60 + baseHeight} 70 60`;
        }
    };

    // Get cat personality-based class
    const getCatPersonalityClass = () => {
        switch(catPersonality) {
            case 'helpful':
                return styles.catHelp;
            case 'wizard':
                return styles.catWizard;
            case 'guide':
                return styles.catGuide;
            case 'analyst':
                return styles.catAnalyst;
            default:
                return '';
        }
    };

    return (
        <AnimatePresence>
            {isVisible && (
                <div className={`${styles.enhancedCheshireCatContainer} ${isDark ? styles.darkTheme : styles.lightTheme}`}>
                    <motion.div
                        ref={catRef}
                        className={`${styles.enhancedCheshireCat} ${getCatPersonalityClass()} ${isGlowing ? styles.glowing : ''} ${isPulsing ? styles.pulsing : ''}`}
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{
                            opacity: isFading ? 0 : catOpacity,
                            scale: isFading ? 0 : 1,
                            x: getPosition().x,
                            y: getPosition().y,
                            rotate: isSpinning ? [0, 360] : 0
                        }}
                        exit={{ opacity: 0, scale: 0 }}
                        transition={{
                            type: 'spring',
                            stiffness: 200,
                            damping: 20,
                            rotate: { duration: 1, ease: "easeInOut" }
                        }}
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                            setLastInteractionTime(Date.now());
                            generateMagicParticles();
                            setIsGlowing(true);
                            setTimeout(() => {
                                setIsGlowing(false);
                            }, 2000);
                        }}
                    >
                        {/* Magic wisps around the cat */}
                        {hasWisps && (
                            <div className={styles.magicWisps} ref={wispRef}>
                                <motion.div
                                    className={styles.wisp}
                                    initial={{ opacity: 0, scale: 0.2, x: -20, y: -20 }}
                                    animate={{
                                        opacity: [0, 0.7, 0],
                                        scale: [0.2, 1.2, 0.5],
                                        x: [-20, -45, -30],
                                        y: [-20, -55, -75]
                                    }}
                                    transition={{ duration: 3, ease: "easeInOut" }}
                                />
                                <motion.div
                                    className={styles.wisp}
                                    initial={{ opacity: 0, scale: 0.2, x: 20, y: -20 }}
                                    animate={{
                                        opacity: [0, 0.7, 0],
                                        scale: [0.2, 1.2, 0.5],
                                        x: [20, 45, 35],
                                        y: [-20, -45, -65]
                                    }}
                                    transition={{ duration: 3, ease: "easeInOut", delay: 0.5 }}
                                />
                                <motion.div
                                    className={styles.wisp}
                                    initial={{ opacity: 0, scale: 0.2, x: 0, y: 20 }}
                                    animate={{
                                        opacity: [0, 0.7, 0],
                                        scale: [0.2, 0.9, 0.3],
                                        x: [0, 15, -15],
                                        y: [20, 45, 65]
                                    }}
                                    transition={{ duration: 2.5, ease: "easeInOut", delay: 0.3 }}
                                />
                                {wizardMode && (
                                    <>
                                        <motion.div
                                            className={styles.wisp}
                                            initial={{ opacity: 0, scale: 0.2, x: -30, y: 10 }}
                                            animate={{
                                                opacity: [0, 0.8, 0],
                                                scale: [0.2, 1.3, 0.4],
                                                x: [-30, -60, -50],
                                                y: [10, 20, 30]
                                            }}
                                            transition={{ duration: 3.5, ease: "easeInOut", delay: 0.7 }}
                                        />
                                        <motion.div
                                            className={styles.wisp}
                                            initial={{ opacity: 0, scale: 0.2, x: 30, y: 10 }}
                                            animate={{
                                                opacity: [0, 0.8, 0],
                                                scale: [0.2, 1.3, 0.4],
                                                x: [30, 60, 50],
                                                y: [10, 15, 25]
                                            }}
                                            transition={{ duration: 3.2, ease: "easeInOut", delay: 0.9 }}
                                        />
                                    </>
                                )}
                            </div>
                        )}

                        {/* Magic trail with improved visual effects */}
                        {isShowingMagic && (
                            <div className={styles.magicTrail} ref={magicTrailRef}>
                                {magicParticles.map(particle => (
                                    <motion.div
                                        key={particle.id}
                                        className={styles.magicParticle}
                                        initial={{ opacity: 0, scale: 0 }}
                                        animate={{
                                            opacity: [0, particle.opacity, 0],
                                            scale: [0, 1 * magicTrailIntensity, 0.5],
                                            x: [0, particle.x * magicTrailIntensity, particle.x * 1.5 * magicTrailIntensity],
                                            y: [0, particle.y * magicTrailIntensity, particle.y * 1.5 * magicTrailIntensity],
                                            rotate: [0, Math.random() * 360, Math.random() * 720]
                                        }}
                                        transition={{
                                            duration: particle.duration,
                                            delay: particle.delay,
                                            ease: wizardMode ? "easeOutBack" : "easeOut"
                                        }}
                                        style={{
                                            width: particle.size,
                                            height: particle.size,
                                            backgroundColor: particle.color,
                                            boxShadow: wizardMode ?
                                                `0 0 ${particle.size * 2}px ${particle.color}, 0 0 ${particle.size * 4}px rgba(255, 255, 255, 0.3)` :
                                                `0 0 ${particle.size}px ${particle.color}`
                                        }}
                                    />
                                ))}
                            </div>
                        )}

                        {/* Cat wizard hat */}
                        {wizardMode && (
                            <motion.div
                                className={styles.wizardHat}
                                initial={{ opacity: 0, y: -30, scale: 0.5, rotate: -15 }}
                                animate={{
                                    opacity: 1,
                                    y: -65,
                                    scale: 1,
                                    rotate: [-5, 5, -5],
                                    filter: isGlowing ?
                                        "drop-shadow(0 2px 4px rgba(0, 0, 0, 0.4)) drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2)) drop-shadow(0 0 12px rgba(142, 68, 173, 0.6))" :
                                        "drop-shadow(0 2px 4px rgba(0, 0, 0, 0.4)) drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2))"
                                }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 300,
                                    damping: 25,
                                    rotate: {
                                        repeat: Infinity,
                                        duration: 6,
                                        ease: "easeInOut"
                                    }
                                }}
                            >
                                <svg width="60" height="70" viewBox="0 0 60 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    {/* Hat base */}
                                    <path d="M30 5L5 55H55L30 5Z" fill="#5D4DB3"/>
                                    <path d="M30 5L20 35L30 30L40 35L30 5Z" fill="#8A7ED6"/>

                                    {/* Hat brim */}
                                    <ellipse cx="30" cy="56" rx="28" ry="8" fill="#3F3A75"/>
                                    <path d="M10 54C10 54 20 50 30 50C40 50 50 54 50 54" stroke="#8A7ED6" strokeWidth="1.5"/>

                                    {/* Hat band */}
                                    <path d="M15 46C15 46 25 42 30 42C35 42 45 46 45 46" stroke="#FFD700" strokeWidth="2"/>

                                    {/* Magical stars */}
                                    <motion.g
                                        animate={{
                                            opacity: [0.7, 1, 0.7],
                                            scale: [1, 1.2, 1],
                                            y: [-1, 1, -1]
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            duration: 3,
                                            ease: "easeInOut"
                                        }}
                                    >
                                        <circle cx="30" cy="15" r="2" fill="#FFEB3B"/>
                                        <circle cx="25" cy="25" r="1" fill="#FFEB3B"/>
                                        <circle cx="35" cy="22" r="1.5" fill="#FFEB3B"/>
                                        <circle cx="20" cy="33" r="1" fill="#FFEB3B"/>
                                        <circle cx="40" cy="33" r="1" fill="#FFEB3B"/>
                                    </motion.g>

                                    {/* Magical sparkles */}
                                    <motion.g
                                        animate={{
                                            opacity: [0, 1, 0],
                                            scale: [0.5, 1, 0.5],
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            duration: 2,
                                            ease: "easeInOut",
                                            repeatDelay: 1
                                        }}
                                    >
                                        <path d="M15 25L16 23L17 25L15 26L15 25Z" fill="white"/>
                                        <path d="M45 25L46 23L47 25L45 26L45 25Z" fill="white"/>
                                        <path d="M30 22L31 20L32 22L30 23L30 22Z" fill="white"/>
                                    </motion.g>
                                </svg>
                            </motion.div>
                        )}

                        {/* Cat face - SVG with sophisticated animations */}
                        <svg
                            width={baseSize}
                            height={baseSize}
                            viewBox="0 0 100 100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            ref={catEyesRef}
                        >
                            {/* Cat head with enhanced gradients */}
                            <defs>
                                <radialGradient id="catHeadGradient" cx="0.5" cy="0.5" r="0.5" fx="0.3" fy="0.3">
                                    <stop offset="0%" stopColor={emotionStyles.color === '#8e44ad' ? '#9b59b6' : emotionStyles.color} />
                                    <stop offset="80%" stopColor={emotionStyles.color} />
                                    <stop offset="100%" stopColor={emotionStyles.color === '#8e44ad' ? '#7d3c98' : emotionStyles.color} />
                                </radialGradient>

                                <filter id="glowFilter" x="-50%" y="-50%" width="200%" height="200%">
                                    <feGaussianBlur stdDeviation="5" result="blur" />
                                    <feComposite in="SourceGraphic" in2="blur" operator="over" />
                                </filter>
                            </defs>

                            {/* Cat head base */}
                            <motion.circle
                                cx="50"
                                cy="50"
                                r="45"
                                fill="url(#catHeadGradient)"
                                initial={{ opacity: 0.8 }}
                                animate={{
                                    opacity: [0.8, 1, 0.8],
                                    scale: isPulsing ? [1, 1.08, 1] : [1, 1.05, 1]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: isPulsing ? 2 : 3,
                                    ease: isPulsing ? "easeInOut" : "easeInOut"
                                }}
                            />

                            {/* Enhanced shimmer effect */}
                            <motion.ellipse
                                cx="35"
                                cy="30"
                                rx="20"
                                ry="15"
                                fill="rgba(255, 255, 255, 0.15)"
                                animate={{
                                    opacity: [0.1, 0.3, 0.1],
                                    scale: [1, 1.1, 1],
                                    x: [0, 2, 0]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 4,
                                    ease: "easeInOut"
                                }}
                            />

                            {/* Left ear with enhanced animation */}
                            <motion.path
                                d="M15 30C15 30 5 15 15 5C25 15 15 30 15 30Z"
                                fill="url(#catHeadGradient)"
                                stroke="rgba(255, 255, 255, 0.3)"
                                strokeWidth="1"
                                animate={{
                                    rotate: wizardMode ? [0, 7, 0, -7, 0] : [0, 5, 0, -5, 0],
                                    translateX: [0, 2, 0, -2, 0],
                                    translateY: [0, -3, 0, -3, 0]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: wizardMode ? 4 : 5,
                                    ease: "easeInOut",
                                    times: [0, 0.2, 0.5, 0.8, 1]
                                }}
                            />

                            {/* Right ear with enhanced animation */}
                            <motion.path
                                d="M85 30C85 30 95 15 85 5C75 15 85 30 85 30Z"
                                fill="url(#catHeadGradient)"
                                stroke="rgba(255, 255, 255, 0.3)"
                                strokeWidth="1"
                                animate={{
                                    rotate: wizardMode ? [0, -7, 0, 7, 0] : [0, -5, 0, 5, 0],
                                    translateX: [0, -2, 0, 2, 0],
                                    translateY: [0, -3, 0, -3, 0]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: wizardMode ? 4.5 : 5.5,
                                    ease: "easeInOut",
                                    times: [0, 0.2, 0.5, 0.8, 1]
                                }}
                            />

                            {/* Left eye with enhanced animation */}
                            <motion.ellipse
                                cx="35"
                                cy="40"
                                rx={emotionStyles.eyeWidth}
                                ry="15"
                                fill={emotionStyles.eyeColor}
                                animate={{
                                    scaleY: [1, 0.2, 1],
                                }}
                                transition={{
                                    repeat: Infinity,
                                    repeatDelay: wizardMode ? 4 : 5,
                                    duration: 0.3
                                }}
                            />

                            {/* Right eye with enhanced animation */}
                            <motion.ellipse
                                cx="65"
                                cy="40"
                                rx={emotionStyles.eyeWidth}
                                ry="15"
                                fill={emotionStyles.eyeColor}
                                animate={{
                                    scaleY: [1, 0.2, 1],
                                }}
                                transition={{
                                    repeat: Infinity,
                                    repeatDelay: wizardMode ? 4 : 5,
                                    duration: 0.3,
                                    delay: 0.1
                                }}
                            />

                            {/* Left pupil with enhanced animation */}
                            <motion.circle
                                cx="35"
                                cy="40"
                                r="5"
                                fill="black"
                                animate={{
                                    x: wizardMode ? [-3, 3, 0, -4, 0] : [0, -3, 3, 0],
                                    y: wizardMode ? [-3, 0, 3, 0, -3] : [0, -3, 3, 0]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    repeatDelay: wizardMode ? 1 : 3,
                                    duration: wizardMode ? 3 : 2,
                                    ease: "easeInOut"
                                }}
                            />

                            {/* Left pupil sparkle with enhanced animation */}
                            <motion.circle
                                cx="33"
                                cy="38"
                                r="1.5"
                                fill="white"
                                animate={{
                                    opacity: wizardMode ? [0.7, 1, 0.7] : [0.5, 1, 0.5],
                                    scale: wizardMode ? [1, 1.5, 1] : [1, 1.2, 1]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: wizardMode ? 2 : 3,
                                    ease: "easeInOut"
                                }}
                            />

                            {/* Right pupil with enhanced animation */}
                            <motion.circle
                                cx="65"
                                cy="40"
                                r="5"
                                fill="black"
                                animate={{
                                    x: wizardMode ? [3, -3, 0, 4, 0] : [0, 3, -3, 0],
                                    y: wizardMode ? [-3, 0, 3, 0, -3] : [0, -3, 3, 0]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    repeatDelay: wizardMode ? 1 : 3,
                                    duration: wizardMode ? 3 : 2,
                                    ease: "easeInOut"
                                }}
                            />

                            {/* Right pupil sparkle with enhanced animation */}
                            <motion.circle
                                cx="63"
                                cy="38"
                                r="1.5"
                                fill="white"
                                animate={{
                                    opacity: wizardMode ? [0.7, 1, 0.7] : [0.5, 1, 0.5],
                                    scale: wizardMode ? [1, 1.5, 1] : [1, 1.2, 1]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: wizardMode ? 2.2 : 3.2,
                                    ease: "easeInOut"
                                }}
                            />

                            {/* Wizard mode extra eye sparkles */}
                            {wizardMode && (
                                <>
                                    <motion.circle
                                        cx="38"
                                        cy="42"
                                        r="1"
                                        fill="white"
                                        animate={{
                                            opacity: [0, 1, 0],
                                            scale: [0.5, 1.2, 0.5]
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            duration: 1.8,
                                            ease: "easeInOut",
                                            delay: 0.5
                                        }}
                                    />
                                    <motion.circle
                                        cx="68"
                                        cy="42"
                                        r="1"
                                        fill="white"
                                        animate={{
                                            opacity: [0, 1, 0],
                                            scale: [0.5, 1.2, 0.5]
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            duration: 1.8,
                                            ease: "easeInOut",
                                            delay: 0.8
                                        }}
                                    />
                                </>
                            )}

                            {/* Enhanced whiskers - left side */}
                            <motion.line
                                x1="20"
                                y1="55"
                                x2="3"
                                y2="50"
                                stroke="rgba(255, 255, 255, 0.6)"
                                strokeWidth="1.5"
                                animate={{
                                    y1: [55, 54, 56, 55],
                                    y2: [50, 48, 52, 50]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 4,
                                    ease: "easeInOut"
                                }}
                            />
                            <motion.line
                                x1="20"
                                y1="60"
                                x2="2"
                                y2="60"
                                stroke="rgba(255, 255, 255, 0.6)"
                                strokeWidth="1.5"
                                animate={{
                                    y1: [60, 59, 61, 60],
                                    y2: [60, 58, 62, 60]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 4.5,
                                    ease: "easeInOut"
                                }}
                            />
                            <motion.line
                                x1="20"
                                y1="65"
                                x2="3"
                                y2="70"
                                stroke="rgba(255, 255, 255, 0.6)"
                                strokeWidth="1.5"
                                animate={{
                                    y1: [65, 64, 66, 65],
                                    y2: [70, 68, 72, 70]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 4.2,
                                    ease: "easeInOut"
                                }}
                            />

                            {/* Enhanced whiskers - right side */}
                            <motion.line
                                x1="80"
                                y1="55"
                                x2="97"
                                y2="50"
                                stroke="rgba(255, 255, 255, 0.6)"
                                strokeWidth="1.5"
                                animate={{
                                    y1: [55, 54, 56, 55],
                                    y2: [50, 48, 52, 50]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 4.3,
                                    ease: "easeInOut"
                                }}
                            />
                            <motion.line
                                x1="80"
                                y1="60"
                                x2="98"
                                y2="60"
                                stroke="rgba(255, 255, 255, 0.6)"
                                strokeWidth="1.5"
                                animate={{
                                    y1: [60, 59, 61, 60],
                                    y2: [60, 58, 62, 60]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 4.7,
                                    ease: "easeInOut"
                                }}
                            />
                            <motion.line
                                x1="80"
                                y1="65"
                                x2="97"
                                y2="70"
                                stroke="rgba(255, 255, 255, 0.6)"
                                strokeWidth="1.5"
                                animate={{
                                    y1: [65, 64, 66, 65],
                                    y2: [70, 68, 72, 70]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 4.1,
                                    ease: "easeInOut"
                                }}
                            />

                            {/* Enhanced grin with more dynamic animation */}
                            <motion.path
                                d={getGrinPath()}
                                stroke="white"
                                strokeWidth="4"
                                strokeLinecap="round"
                                fill="none"
                                animate={{
                                    d: [
                                        getGrinPath(),
                                        getGrinPath()
                                    ]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: wizardMode ? 4 : 5,
                                    ease: "easeInOut"
                                }}
                            />

                            {/* Enhance with a subtle glow behind the grin for wizard mode */}
                            {wizardMode && (
                                <motion.path
                                    d={getGrinPath()}
                                    stroke="rgba(255, 255, 255, 0.5)"
                                    strokeWidth="8"
                                    strokeLinecap="round"
                                    fill="none"
                                    filter="url(#glowFilter)"
                                    animate={{
                                        opacity: [0.3, 0.6, 0.3]
                                    }}
                                    transition={{
                                        repeat: Infinity,
                                        duration: 2,
                                        ease: "easeInOut"
                                    }}
                                />
                            )}
                        </svg>

                        {/* Dialog bubble with improved styling */}
                        <AnimatePresence>
                            {isExpanded && (
                                <motion.div
                                    ref={dialogRef}
                                    className={`${styles.enhancedDialogBubble} ${wizardMode ? styles.wizardDialog : ''}`}
                                    initial={{ opacity: 0, scale: 0.8, y: -20 }}
                                    animate={{ opacity: 1, scale: 1, y: 0 }}
                                    exit={{ opacity: 0, scale: 0.8, y: -20 }}
                                    transition={{ type: 'spring', stiffness: 300, damping: 25 }}
                                >
                                    <div className={styles.enhancedDialogContent}>
                                        <div className={styles.dialogHeader}>
                                            <h3>{wizardMode ? 'Taytrom Wizard Guide' : 'Cheshire Cat Assistant'}</h3>
                                            {wizardMode && (
                                                <button
                                                    className={styles.wizardModeButton}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        toggleWizardMode();
                                                    }}
                                                >
                                                    Exit Wizard Mode
                                                </button>
                                            )}
                                        </div>

                                        <div className={styles.dialogMainContent}>
                                            <p>{currentDialog}<span className={isTyping ? styles.enhancedTypingCursor : ''}></span></p>

                                            {/* Enhanced suggested questions */}
                                            {!isTyping && suggestedQuestions.length > 0 && (
                                                <div className={styles.suggestedQuestions}>
                                                    <div className={styles.suggestedQuestionsLabel}>
                                                        Popular questions:
                                                    </div>
                                                    <div className={styles.suggestedQuestionsButtons}>
                                                        {suggestedQuestions.map((question, index) => (
                                                            <button
                                                                key={index}
                                                                className={styles.suggestedQuestion}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleSuggestedQuestionClick(question);
                                                                }}
                                                            >
                                                                {question}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Enhanced Feature history for wizard mode */}
                                            {wizardMode && featureHistory.length > 0 && (
                                                <div className={styles.featureHistory}>
                                                    <div className={styles.featureHistoryLabel}>
                                                        Features explored:
                                                    </div>
                                                    <div className={styles.featureHistoryList}>
                                                        {featureHistory.map((feature, index) => (
                                                            <div
                                                                key={index}
                                                                className={styles.featureHistoryItem}
                                                                style={{
                                                                    backgroundColor: featureSpotlights[feature]?.color || '#3498db'
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    // Reshow spotlight for this feature
                                                                    setSpotlightFeature(feature);
                                                                    setShowFeatureSpotlight(true);
                                                                    generateMagicParticles();
                                                                }}
                                                            >
                                                                {featureSpotlights[feature]?.title || feature}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className={styles.enhancedDialogActions}>
                                            <input
                                                ref={inputRef}
                                                type="text"
                                                placeholder="Ask me about Taytrom features, ROI, or use cases..."
                                                value={userQuestion}
                                                onChange={(e) => setUserQuestion(e.target.value)}
                                                onKeyPress={(e) => e.key === 'Enter' && handleAskQuestion()}
                                                className={styles.enhancedQuestionInput}
                                            />
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleAskQuestion();
                                                }}
                                                className={styles.enhancedAskButton}
                                                disabled={isTyping}
                                            >
                                                Ask
                                            </button>

                                            {!wizardMode && (
                                                <button
                                                    className={styles.wizardModeButton}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        toggleWizardMode();
                                                    }}
                                                    title="Start a guided tour of Taytrom's key features"
                                                >
                                                    Wizard Mode
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        {/* Feature spotlight overlay */}
                        <AnimatePresence>
                            {showFeatureSpotlight && spotlightFeature && (
                                <motion.div
                                    className={styles.featureSpotlight}
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.8 }}
                                    transition={{ type: 'spring', stiffness: 300, damping: 25 }}
                                    style={{
                                        borderColor: featureSpotlights[spotlightFeature].color,
                                        color: featureSpotlights[spotlightFeature].color
                                    }}
                                >
                                    <div className={styles.featureSpotlightHeader}>
                                        <h3>{featureSpotlights[spotlightFeature].title}</h3>
                                        <button
                                            className={styles.closeSpotlightButton}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleCloseSpotlight();
                                            }}
                                        >
                                            ×
                                        </button>
                                    </div>

                                    <div className={styles.featureSpotlightContent}>
                                        <p className={styles.featureDescription}>
                                            {featureSpotlights[spotlightFeature].description}
                                        </p>

                                        <div className={styles.featureSpotlightDemo}>
                                            <h4>Demonstration</h4>
                                            <p>{featureSpotlights[spotlightFeature].demonstration}</p>
                                        </div>

                                        <div className={styles.featureBusinessImpact}>
                                            <h4>Business Impact</h4>
                                            <p>{featureSpotlights[spotlightFeature].businessImpact}</p>
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default EnhancedCheshireCat;