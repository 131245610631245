import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import {
    Sparkles,
    ChevronRight,
    X,
    MessageSquare,
    Crown,
    Palette,
    Wand2,
    HeartHandshake,
    Brain,
    Lightbulb,
    PenLine,
    BookMarked,
    Edit3,
    Layout,
    Scissors
} from 'lucide-react';
import styles from './Compiler_5_Steps_InteractiveTour_Specialists_Writing.module.css'

const Compiler_5_Sections_InteractiveTour_Specialists_Writing = ({
                                                                     onNext,
                                                                     onPrevious,
                                                                     isDark = true,
                                                                     orchestrator
                                                                 }) => {
    // Tour state
    const [tourStep, setTourStep] = useState(0);
    const [showTooltip, setShowTooltip] = useState(true);
    const [catPosition, setCatPosition] = useState({ x: 500, y: 100 });
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [typedText, setTypedText] = useState('');
    const [isTypingEffect, setIsTypingEffect] = useState(true);

    // Garden state
    const [gardenMood, setGardenMood] = useState('neutral'); // 'happy', 'neutral', 'upset'
    const [weather, setWeather] = useState('sunny');
    const [timeOfDay, setTimeOfDay] = useState('day');
    const [documentHealth, setDocumentHealth] = useState(75); // 0-100
    const [roses, setRoses] = useState([]);
    const [cardGardeners, setCardGardeners] = useState([]);
    const [royalDecree, setRoyalDecree] = useState(null);

    // Document state
    const [documentContent, setDocumentContent] = useState(INITIAL_DOCUMENT_CONTENT);
    const [selectedSection, setSelectedSection] = useState(null);
    const [suggestions, setSuggestions] = useState(INITIAL_SUGGESTIONS);
    const [activeCategory, setActiveCategory] = useState('all');
    const [draggedSuggestion, setDraggedSuggestion] = useState(null);
    const [successfulDrop, setSuccessfulDrop] = useState(false);
    const [showDropConfetti, setShowDropConfetti] = useState(false);
    const [showCroquet, setShowCroquet] = useState(false);

    // References
    const containerRef = useRef(null);
    const documentRef = useRef(null);
    const cheshireRef = useRef(null);
    const gardenerAnimationControls = useAnimation();

    // Initialize roses and card gardeners
    useEffect(() => {
        // Generate roses based on document sections
        const newRoses = documentContent.map((section, index) => ({
            id: section.id,
            color: getRandomRoseColor(),
            bloom: calculateBloomState(section),
            position: {
                x: 50 + (index % 3) * 100,
                y: 100 + Math.floor(index / 3) * 120
            },
            section
        }));

        setRoses(newRoses);

        // Initialize card gardeners
        const newGardeners = [
            {
                id: 'gardener1',
                type: 'hearts',
                position: { x: 300, y: 400 },
                message: 'Paint them red!',
                state: 'working'
            },
            {
                id: 'gardener2',
                type: 'spades',
                position: { x: 500, y: 450 },
                message: 'The Queen will be here soon!',
                state: 'nervous'
            },
            {
                id: 'gardener3',
                type: 'clubs',
                position: { x: 150, y: 500 },
                message: 'This document needs more style!',
                state: 'working'
            }
        ];

        setCardGardeners(newGardeners);

        // Update garden environment based on document health
        updateGardenEnvironment();
    }, []);

    // Track mouse position for Cheshire Cat
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (containerRef.current) {
                const { left, top } = containerRef.current.getBoundingClientRect();
                setCursorPosition({
                    x: e.clientX - left,
                    y: e.clientY - top
                });
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);

    // Cheshire Cat follows cursor with delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setCatPosition({
                x: cursorPosition.x - 50,
                y: cursorPosition.y - 50
            });
        }, 300);

        return () => clearTimeout(timer);
    }, [cursorPosition]);

    // Typing effect for tooltips
    useEffect(() => {
        if (!isTypingEffect) return;

        const text = TOUR_STEPS[tourStep].description;
        let currentIndex = 0;

        const interval = setInterval(() => {
            if (currentIndex <= text.length) {
                setTypedText(text.substring(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(interval);
                setIsTypingEffect(false);
            }
        }, 25);

        return () => clearInterval(interval);
    }, [tourStep, isTypingEffect]);

    // Reset typing effect when tour step changes
    useEffect(() => {
        setIsTypingEffect(true);
        setTypedText('');
    }, [tourStep]);

    // Update garden based on document health
    const updateGardenEnvironment = () => {
        // Update weather based on document health
        if (documentHealth > 80) {
            setWeather('sunny');
            setGardenMood('happy');
        } else if (documentHealth > 50) {
            setWeather('partly-cloudy');
            setGardenMood('neutral');
        } else if (documentHealth > 30) {
            setWeather('cloudy');
            setGardenMood('neutral');
        } else {
            setWeather('stormy');
            setGardenMood('upset');
        }

        // Animate card gardeners based on garden mood
        if (gardenMood === 'upset') {
            gardenerAnimationControls.start({
                x: [0, 10, -10, 0],
                y: [0, -5, 0, -5, 0],
                transition: { duration: 0.5, repeat: 3 }
            });
        }
    };

    // Get random rose color
    const getRandomRoseColor = () => {
        const colors = ['red', 'white', 'pink', 'yellow', 'purple', 'blue'];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    // Calculate bloom state based on section content
    const calculateBloomState = (section) => {
        const contentLength = section.content.length;
        if (contentLength > 200) return 'full';
        if (contentLength > 100) return 'partial';
        return 'bud';
    };

    // Play sound if orchestrator available
    const playSound = (soundName) => {
        if (orchestrator && orchestrator.soundManager) {
            orchestrator.soundManager.play(soundName);
        }
    };

    // Handle navigation between tour steps
    const handleNextStep = () => {
        if (tourStep < TOUR_STEPS.length - 1) {
            playSound('pageFlip');
            setTourStep(tourStep + 1);
        } else {
            onNext && onNext();
        }
    };

    const handlePreviousStep = () => {
        if (tourStep > 0) {
            playSound('pageFlip');
            setTourStep(tourStep - 1);
        } else {
            onPrevious && onPrevious();
        }
    };

    // Handle section selection
    const handleSelectSection = (sectionId) => {
        playSound('click');
        setSelectedSection(sectionId);

        // Find the corresponding rose and highlight it
        const updatedRoses = roses.map(rose => ({
            ...rose,
            isHighlighted: rose.section.id === sectionId
        }));

        setRoses(updatedRoses);
    };

    // Handle suggestion drag start
    const handleDragStart = (e, suggestion) => {
        playSound('pickup');
        setDraggedSuggestion(suggestion);

        // Create a custom drag image
        const dragImage = document.createElement('div');
        dragImage.className = styles.dragImage;
        dragImage.textContent = suggestion.title.substring(0, 15) + '...';
        document.body.appendChild(dragImage);

        e.dataTransfer.setDragImage(dragImage, 20, 20);
        e.dataTransfer.setData('text/plain', JSON.stringify(suggestion));

        setTimeout(() => {
            document.body.removeChild(dragImage);
        }, 0);
    };

    // Handle drag over document section
    const handleDragOver = (e, sectionId) => {
        e.preventDefault();

        // Highlight the target section
        const updatedSections = documentContent.map(section => ({
            ...section,
            isDragOver: section.id === sectionId
        }));

        setDocumentContent(updatedSections);
    };

    // Handle drop on document section
    const handleDrop = (e, sectionId) => {
        e.preventDefault();
        playSound('magic');

        const targetSection = documentContent.find(section => section.id === sectionId);
        if (!targetSection) return;

        // Reset drag over state
        const resetDragOver = documentContent.map(section => ({
            ...section,
            isDragOver: false
        }));
        setDocumentContent(resetDragOver);

        // Apply the suggestion to the section
        const updatedContent = resetDragOver.map(section => {
            if (section.id === sectionId) {
                // Update content based on suggestion type
                let newContent = section.content;

                if (draggedSuggestion.type === 'structure') {
                    newContent = section.content + "\n\n" + draggedSuggestion.content;
                } else if (draggedSuggestion.type === 'style') {
                    newContent = draggedSuggestion.content;
                } else {
                    newContent = draggedSuggestion.content + " " + section.content;
                }

                return {
                    ...section,
                    content: newContent,
                    isEnhanced: true
                };
            }
            return section;
        });

        // Update document and show success effects
        setDocumentContent(updatedContent);
        setSuccessfulDrop(true);
        setShowDropConfetti(true);

        // Update document health
        setDocumentHealth(prev => Math.min(prev + 10, 100));

        // Update the rose for this section
        const updatedRoses = roses.map(rose => {
            if (rose.section.id === sectionId) {
                return {
                    ...rose,
                    bloom: 'full',
                    color: draggedSuggestion.type === 'structure' ? 'blue' :
                        draggedSuggestion.type === 'style' ? 'purple' : 'red',
                    isEnhanced: true
                };
            }
            return rose;
        });

        setRoses(updatedRoses);

        // Card gardeners react to the change
        const updatedGardeners = cardGardeners.map(gardener => ({
            ...gardener,
            message: getRandomGardenerMessage(draggedSuggestion.type),
            state: 'excited'
        }));

        setCardGardeners(updatedGardeners);
        gardenerAnimationControls.start({
            y: [0, -20, 0],
            transition: { duration: 0.5 }
        });

        // Remove the suggestion from the list
        const updatedSuggestions = suggestions.filter(s => s.id !== draggedSuggestion.id);
        setSuggestions(updatedSuggestions);

        // Add a new suggestion to replace it
        setTimeout(() => {
            const newSuggestion = {
                id: `suggestion-${Date.now()}`,
                title: getRandomSuggestionTitle(),
                content: getRandomSuggestionContent(),
                type: ['structure', 'content', 'style'][Math.floor(Math.random() * 3)]
            };

            setSuggestions([...updatedSuggestions, newSuggestion]);
        }, 2000);

        // Reset states after a delay
        setTimeout(() => {
            setSuccessfulDrop(false);
            setShowDropConfetti(false);
        }, 3000);

        // If this is the required tour step interaction, proceed to next step
        if (tourStep === 1) {
            setTimeout(() => {
                handleNextStep();
            }, 2000);
        }
    };

    // Handle filter change for suggestions
    const handleFilterChange = (category) => {
        playSound('click');
        setActiveCategory(category);
    };

    // Get random gardener message based on suggestion type
    const getRandomGardenerMessage = (type) => {
        const messages = {
            structure: [
                "The Queen will love this structure!",
                "Reorganizing the garden, I see!",
                "A place for everything, and everything in its place!"
            ],
            content: [
                "More content, more beautiful roses!",
                "The garden is growing splendidly!",
                "These words are blooming marvelously!"
            ],
            style: [
                "Such elegant styling!",
                "The Queen appreciates your taste!",
                "Painting the roses with style!"
            ]
        };

        const typeMessages = messages[type] || messages.content;
        return typeMessages[Math.floor(Math.random() * typeMessages.length)];
    };

    // Get random suggestion title
    const getRandomSuggestionTitle = () => {
        const titles = [
            "Enhance your conclusion",
            "Add supporting details",
            "Strengthen your introduction",
            "Clarify your argument",
            "Add a transition",
            "Make this more concise",
            "Use more descriptive language",
            "Add a counterpoint",
            "Improve paragraph structure"
        ];

        return titles[Math.floor(Math.random() * titles.length)];
    };

    // Get random suggestion content
    const getRandomSuggestionContent = () => {
        const contents = [
            "Consider adding more vivid descriptions of Wonderland's fantastical elements here.",
            "The Queen's character could be more imposing with additional dialogue in this section.",
            "A transition sentence would help connect these two paragraphs about Alice's journey.",
            "This passage about the Mad Hatter could benefit from more specific details about the tea party.",
            "The Cheshire Cat's disappearing act might be more impactful with a metaphor about identity.",
            "Adding the White Rabbit's perspective here would create an interesting contrast to Alice's."
        ];

        return contents[Math.floor(Math.random() * contents.length)];
    };

    // Issue a royal decree (bulk formatting)
    const issueRoyalDecree = (type) => {
        playSound('trumpet');

        setRoyalDecree({
            type,
            message: `The Queen decrees all roses shall be ${type}!`
        });

        // Update all roses
        const updatedRoses = roses.map(rose => ({
            ...rose,
            color: type === 'red' ? 'red' : type === 'white' ? 'white' : rose.color,
            isAffectedByDecree: true
        }));

        setRoses(updatedRoses);

        // Update document content
        const updatedSections = documentContent.map(section => ({
            ...section,
            style: type === 'red' ? 'bold' : type === 'white' ? 'italic' : section.style
        }));

        setDocumentContent(updatedSections);

        // Card gardeners react
        const updatedGardeners = cardGardeners.map(gardener => ({
            ...gardener,
            message: "Yes, Your Majesty!",
            state: 'fearful'
        }));

        setCardGardeners(updatedGardeners);
        gardenerAnimationControls.start({
            y: [0, -10, 0, -5, 0],
            transition: { duration: 0.3, repeat: 2 }
        });

        // Clear decree after a delay
        setTimeout(() => {
            setRoyalDecree(null);
        }, 4000);
    };

    // Start the croquet game for formatting
    const startCroquet = () => {
        playSound('gameStart');
        setShowCroquet(true);
    };

    // Handle croquet hit
    const handleCroquetHit = (style) => {
        playSound('croquetHit');

        if (selectedSection) {
            // Apply style to selected section
            const updatedSections = documentContent.map(section => {
                if (section.id === selectedSection) {
                    return {
                        ...section,
                        style: section.style === style ? undefined : style
                    };
                }
                return section;
            });

            setDocumentContent(updatedSections);

            // Update rose
            const updatedRoses = roses.map(rose => {
                if (rose.section.id === selectedSection) {
                    return {
                        ...rose,
                        color: style === 'bold' ? 'red' :
                            style === 'italic' ? 'white' :
                                style === 'underline' ? 'blue' : rose.color
                    };
                }
                return rose;
            });

            setRoses(updatedRoses);
        }

        // Close croquet after a delay
        setTimeout(() => {
            setShowCroquet(false);
        }, 2000);
    };

    // Render spotlight
    const renderSpotlight = () => {
        const step = TOUR_STEPS[tourStep];

        return (
            <motion.div
                className={styles.spotlight}
                initial={{ opacity: 0 }}
                animate={{
                    opacity: 0.7,
                    x: step.spotlightPosition?.x || 0,
                    y: step.spotlightPosition?.y || 0,
                    width: step.spotlightSize?.width || 200,
                    height: step.spotlightSize?.height || 150
                }}
                transition={{ duration: 0.5 }}
            />
        );
    };

    // Render tooltip
    const renderTooltip = () => {
        const step = TOUR_STEPS[tourStep];

        return (
            <AnimatePresence>
                {showTooltip && (
                    <motion.div
                        className={styles.tooltip}
                        style={{
                            top: step.tooltipPosition?.y || '50%',
                            left: step.tooltipPosition?.x || '50%'
                        }}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className={styles.tooltipHeader}>
                            <Sparkles size={18} className={styles.tooltipIcon} />
                            <h3>{step.title}</h3>
                            <button
                                className={styles.closeButton}
                                onClick={() => setShowTooltip(false)}
                            >
                                <X size={16} />
                            </button>
                        </div>
                        <div className={styles.tooltipContent}>
                            {isTypingEffect ? (
                                <p>{typedText}<span className={styles.cursor}>|</span></p>
                            ) : (
                                <p>{step.description}</p>
                            )}
                        </div>
                        <div className={styles.tooltipFooter}>
                            <div className={styles.progress}>
                                Step {tourStep + 1} of {TOUR_STEPS.length}
                            </div>
                            <div className={styles.navigationButtons}>
                                <button
                                    className={styles.prevButton}
                                    onClick={handlePreviousStep}
                                    disabled={tourStep === 0}
                                >
                                    Previous
                                </button>
                                <button
                                    className={styles.nextButton}
                                    onClick={handleNextStep}
                                >
                                    {tourStep < TOUR_STEPS.length - 1 ? 'Next' : 'Finish'}
                                    <ChevronRight size={16} />
                                </button>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    };

    // Render Cheshire Cat
    const renderCheshireCat = () => {
        return (
            <motion.div
                ref={cheshireRef}
                className={styles.cheshireCat}
                initial={{ opacity: 0, scale: 0 }}
                animate={{
                    opacity: 1,
                    scale: 1,
                    x: catPosition.x,
                    y: catPosition.y,
                }}
                transition={{
                    duration: 0.4,
                    x: { type: "spring", stiffness: 100, damping: 20 },
                    y: { type: "spring", stiffness: 100, damping: 20 },
                }}
            >
                <motion.div
                    className={styles.catFace}
                    animate={{
                        rotate: [0, 5, 0, -5, 0],
                        scale: [1, 1.05, 1, 1.05, 1]
                    }}
                    transition={{
                        duration: 5,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                >
                    <div className={styles.eyes}>
                        <motion.div
                            className={styles.eye}
                            animate={{
                                scaleY: [1, 0.2, 1],
                            }}
                            transition={{
                                duration: 3,
                                repeat: Infinity,
                                repeatType: "reverse",
                                repeatDelay: 2
                            }}
                        />
                        <motion.div
                            className={styles.eye}
                            animate={{
                                scaleY: [1, 0.2, 1],
                            }}
                            transition={{
                                duration: 3,
                                repeat: Infinity,
                                repeatType: "reverse",
                                repeatDelay: 2
                            }}
                        />
                    </div>
                    <motion.div
                        className={styles.smile}
                        animate={{
                            scale: [1, 1.1, 1],
                        }}
                        transition={{
                            duration: 2,
                            repeat: Infinity,
                            repeatType: "reverse"
                        }}
                    />
                </motion.div>

                <AnimatePresence>
                    {!showTooltip && (
                        <motion.div
                            className={styles.catTooltip}
                            initial={{ opacity: 0, scale: 0, y: -20 }}
                            animate={{ opacity: 1, scale: 1, y: 0 }}
                            exit={{ opacity: 0, scale: 0 }}
                            transition={{ duration: 0.3 }}
                            onClick={() => setShowTooltip(true)}
                        >
                            <span>Click for help</span>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        );
    };

    // Render garden elements
    const renderGarden = () => {
        return (
            <div className={`${styles.garden} ${styles[weather]} ${styles[timeOfDay]}`}>
                {/* Sky and background elements */}
                <div className={styles.sky}>
                    <div className={styles.sun} />

                    {weather === 'cloudy' || weather === 'partly-cloudy' || weather === 'stormy' ? (
                        <>
                            <motion.div
                                className={styles.cloud}
                                style={{ left: '10%', top: '15%' }}
                                animate={{ x: [0, 20, 0] }}
                                transition={{ duration: 20, repeat: Infinity, repeatType: "reverse" }}
                            />
                            <motion.div
                                className={styles.cloud}
                                style={{ left: '60%', top: '25%' }}
                                animate={{ x: [0, -30, 0] }}
                                transition={{ duration: 23, repeat: Infinity, repeatType: "reverse" }}
                            />
                        </>
                    ) : null}

                    {weather === 'stormy' && (
                        <>
                            <motion.div
                                className={styles.lightning}
                                animate={{
                                    opacity: [0, 1, 0.5, 1, 0],
                                    scale: [0.8, 1, 0.9, 1.1, 0.8]
                                }}
                                transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 5 }}
                            />
                            <motion.div
                                className={styles.rain}
                                animate={{ opacity: 1 }}
                            />
                        </>
                    )}
                </div>

                {/* Garden ground */}
                <div className={styles.ground}>
                    {/* Roses */}
                    {roses.map((rose) => (
                        <motion.div
                            key={rose.id}
                            className={`${styles.rose} ${styles[rose.color]} ${styles[rose.bloom]} ${rose.isHighlighted ? styles.highlighted : ''} ${rose.isEnhanced ? styles.enhanced : ''} ${rose.isAffectedByDecree ? styles.decreed : ''}`}
                            style={{
                                left: `${rose.position.x}px`,
                                top: `${rose.position.y}px`
                            }}
                            animate={{
                                rotate: [0, rose.isEnhanced ? 10 : 3, 0, rose.isEnhanced ? -10 : -3, 0],
                                scale: rose.isEnhanced ? [1, 1.2, 1] : [1, 1.05, 1]
                            }}
                            transition={{
                                duration: 5,
                                repeat: Infinity,
                                repeatType: "reverse"
                            }}
                            onClick={() => handleSelectSection(rose.section.id)}
                        >
                            <div className={styles.stem} />
                            <div className={styles.roseHead} />
                            <div className={styles.roseFace}>
                                {rose.isEnhanced && <Sparkles size={12} className={styles.roseSparkle} />}
                            </div>
                        </motion.div>
                    ))}

                    {/* Card Gardeners */}
                    {cardGardeners.map((gardener) => (
                        <motion.div
                            key={gardener.id}
                            className={`${styles.cardGardener} ${styles[gardener.type]} ${styles[gardener.state]}`}
                            style={{
                                left: `${gardener.position.x}px`,
                                top: `${gardener.position.y}px`
                            }}
                            animate={gardenerAnimationControls}
                        >
                            <div className={styles.cardBody} />
                            <div className={styles.cardHead} />
                            <div className={styles.cardSymbol} />

                            <AnimatePresence>
                                {gardener.message && (
                                    <motion.div
                                        className={styles.cardSpeech}
                                        initial={{ opacity: 0, y: -10, scale: 0 }}
                                        animate={{ opacity: 1, y: 0, scale: 1 }}
                                        exit={{ opacity: 0, y: -10, scale: 0 }}
                                    >
                                        {gardener.message}
                                    </motion.div>
                                )}
                            </AnimatePresence>

                            {gardener.state === 'working' && (
                                <motion.div
                                    className={styles.cardTool}
                                    animate={{ rotate: [0, 20, 0, 20, 0] }}
                                    transition={{ duration: 1, repeat: Infinity }}
                                />
                            )}
                        </motion.div>
                    ))}
                </div>

                {/* Royal decree overlay */}
                <AnimatePresence>
                    {royalDecree && (
                        <motion.div
                            className={styles.royalDecree}
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Crown size={40} className={styles.decreeCrown} />
                            <h2>Royal Decree!</h2>
                            <p>{royalDecree.message}</p>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Flamingo Croquet */}
                <AnimatePresence>
                    {showCroquet && (
                        <motion.div
                            className={styles.croquetGame}
                            initial={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 100 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h3>Flamingo Croquet</h3>
                            <p>Hit the ball through a hoop to apply a style!</p>

                            <div className={styles.croquetField}>
                                <motion.div
                                    className={`${styles.croquetBall} ${styles.redBall}`}
                                    animate={{ x: [-50, 50, -50] }}
                                    transition={{ duration: 3, repeat: Infinity }}
                                    onClick={() => handleCroquetHit('bold')}
                                />

                                <motion.div
                                    className={`${styles.croquetBall} ${styles.whiteBall}`}
                                    animate={{ x: [50, -50, 50] }}
                                    transition={{ duration: 3, repeat: Infinity, delay: 0.5 }}
                                    onClick={() => handleCroquetHit('italic')}
                                />

                                <motion.div
                                    className={`${styles.croquetBall} ${styles.blueBall}`}
                                    animate={{ x: [-30, 30, -30] }}
                                    transition={{ duration: 2.5, repeat: Infinity, delay: 1 }}
                                    onClick={() => handleCroquetHit('underline')}
                                />

                                <div className={styles.croquetHoops}>
                                    <div className={styles.hoop} />
                                    <div className={styles.hoop} />
                                    <div className={styles.hoop} />
                                </div>

                                <div className={styles.flamingo}>
                                    <div className={styles.flamingoHead} />
                                    <div className={styles.flamingoBody} />
                                </div>
                            </div>

                            <button
                                className={styles.closeButton}
                                onClick={() => setShowCroquet(false)}
                            >
                                Close
                            </button>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        );
    };

    // Render document panel
    const renderDocumentPanel = () => {
        return (
            <motion.div
                className={styles.documentPanel}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                ref={documentRef}
            >
                <div className={styles.documentHeader}>
                    <motion.h2 className={styles.documentTitle}>
                        Alice in the Queen's Garden
                    </motion.h2>
                </div>

                <div className={styles.documentContent}>
                    {documentContent.map((section) => (
                        <motion.div
                            key={section.id}
                            className={`${styles.section} ${section.isEnhanced ? styles.enhanced : ''} ${section.isDragOver ? styles.dragOver : ''} ${selectedSection === section.id ? styles.selected : ''}`}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{
                                opacity: 1,
                                y: 0,
                                backgroundColor: section.isDragOver
                                    ? 'rgba(245, 197, 66, 0.2)'
                                    : section.isEnhanced
                                        ? 'rgba(128, 0, 128, 0.1)'
                                        : 'transparent'
                            }}
                            transition={{ duration: 0.5 }}
                            onClick={() => handleSelectSection(section.id)}
                            onDragOver={(e) => handleDragOver(e, section.id)}
                            onDrop={(e) => handleDrop(e, section.id)}
                        >
                            <div className={styles.sectionHeader}>
                                <div className={styles.sectionType}>
                                    {section.type === 'heading' && <BookMarked size={16} />}
                                    {section.type === 'paragraph' && <PenLine size={16} />}
                                    {section.type === 'list' && <Layout size={16} />}
                                </div>
                                <h3 className={styles.sectionTitle}>{section.title}</h3>
                            </div>
                            <div className={`${styles.sectionContent} ${section.style ? styles[section.style] : ''}`}>
                                <p>{section.content}</p>
                            </div>

                            {section.isEnhanced && (
                                <motion.div
                                    className={styles.enhancedBadge}
                                    initial={{ opacity: 0, scale: 0 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Sparkles size={12} />
                                    <span>Enhanced</span>
                                </motion.div>
                            )}
                        </motion.div>
                    ))}
                </div>

                {/* Success drop effect */}
                <AnimatePresence>
                    {successfulDrop && (
                        <motion.div
                            className={styles.dropSuccessOverlay}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <motion.div
                                className={styles.successMessage}
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0 }}
                                transition={{ type: "spring", stiffness: 300, damping: 15 }}
                            >
                                <Sparkles size={24} />
                                <h3>Marvelous Enhancement!</h3>
                                <p>The Queen is pleased with your garden work!</p>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Confetti effect */}
                <AnimatePresence>
                    {showDropConfetti && (
                        <>
                            {Array.from({ length: 50 }).map((_, i) => (
                                <motion.div
                                    key={`confetti-${i}`}
                                    className={`${styles.confetti} ${styles[`confetti${i % 5}`]}`}
                                    initial={{
                                        opacity: 1,
                                        scale: 1,
                                        x: Math.random() * 800 - 400,
                                        y: -20,
                                        rotate: 0
                                    }}
                                    animate={{
                                        opacity: [1, 0.8, 0],
                                        scale: [1, 0.8, 0.5],
                                        y: 600,
                                        x: (Math.random() * 800 - 400) + (Math.random() * 100 - 50),
                                        rotate: Math.random() * 360
                                    }}
                                    transition={{
                                        duration: 2 + Math.random() * 2,
                                        ease: "easeOut"
                                    }}
                                />
                            ))}
                        </>
                    )}
                </AnimatePresence>
            </motion.div>
        );
    };

    // Render AI suggestions panel
    const renderSuggestionsPanel = () => {
        // Filter suggestions based on active category
        const filteredSuggestions = activeCategory === 'all'
            ? suggestions
            : suggestions.filter(s => s.type === activeCategory);

        return (
            <motion.div
                className={styles.suggestionsPanel}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
            >
                <div className={styles.suggestionsPanelHeader}>
                    <div className={styles.panelTitle}>
                        <h3>Royal Suggestions</h3>
                        <Crown size={18} className={styles.crownIcon} />
                    </div>

                    <div className={styles.filterTabs}>
                        <button
                            className={`${styles.filterTab} ${activeCategory === 'all' ? styles.active : ''}`}
                            onClick={() => handleFilterChange('all')}
                        >
                            All
                        </button>
                        <button
                            className={`${styles.filterTab} ${activeCategory === 'structure' ? styles.active : ''}`}
                            onClick={() => handleFilterChange('structure')}
                        >
                            <Layout size={14} />
                            Structure
                        </button>
                        <button
                            className={`${styles.filterTab} ${activeCategory === 'content' ? styles.active : ''}`}
                            onClick={() => handleFilterChange('content')}
                        >
                            <Edit3 size={14} />
                            Content
                        </button>
                        <button
                            className={`${styles.filterTab} ${activeCategory === 'style' ? styles.active : ''}`}
                            onClick={() => handleFilterChange('style')}
                        >
                            <Palette size={14} />
                            Style
                        </button>
                    </div>
                </div>

                <div className={styles.bubbleContainer}>
                    {filteredSuggestions.map((suggestion, index) => (
                        <motion.div
                            key={suggestion.id}
                            className={`${styles.suggestionBubble} ${styles[suggestion.type]}`}
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{
                                opacity: 1,
                                scale: 1,
                                rotate: (index % 3 - 1) * 5
                            }}
                            transition={{
                                duration: 0.5,
                                delay: index * 0.1
                            }}
                            draggable
                            onDragStart={(e) => handleDragStart(e, suggestion)}
                        >
                            <div className={styles.bubbleContent}>
                                <div className={styles.bubbleIcon}>
                                    {suggestion.type === 'structure' && <Layout size={18} />}
                                    {suggestion.type === 'content' && <Edit3 size={18} />}
                                    {suggestion.type === 'style' && <Palette size={18} />}
                                </div>
                                <h4>{suggestion.title}</h4>
                                <p>{suggestion.content.length > 70 ? suggestion.content.substring(0, 70) + '...' : suggestion.content}</p>
                            </div>

                            <div className={styles.bubbleDragHint}>
                                <p>Drag to a section</p>
                            </div>

                            <motion.div
                                className={styles.bubbleShine}
                                animate={{
                                    x: ['-100%', '100%'],
                                    opacity: [0, 0.5, 0]
                                }}
                                transition={{
                                    duration: 2,
                                    repeat: Infinity,
                                    repeatDelay: 3
                                }}
                            />
                        </motion.div>
                    ))}
                </div>

                <div className={styles.suggestionActions}>
                    <button
                        className={styles.actionButton}
                        onClick={() => issueRoyalDecree('red')}
                    >
                        <Crown size={16} />
                        <span>Decree: Paint Red</span>
                    </button>

                    <button
                        className={styles.actionButton}
                        onClick={() => issueRoyalDecree('white')}
                    >
                        <Crown size={16} />
                        <span>Decree: Paint White</span>
                    </button>

                    <button
                        className={styles.actionButton}
                        onClick={startCroquet}
                    >
                        <Wand2 size={16} />
                        <span>Play Croquet</span>
                    </button>

                    <button
                        className={styles.actionButton}
                        onClick={() => {
                            // Execute a gardener
                            if (cardGardeners.length > 0) {
                                const targetIndex = Math.floor(Math.random() * cardGardeners.length);
                                const updatedGardeners = [...cardGardeners];
                                updatedGardeners.splice(targetIndex, 1);
                                setCardGardeners(updatedGardeners);

                                playSound('scissors');
                            }
                        }}
                    >
                        <Scissors size={16} />
                        <span>OFF WITH THEIR HEAD!</span>
                    </button>
                </div>
            </motion.div>
        );
    };

    // Render navigation bar
    const renderNavigationBar = () => {
        return (
            <motion.div
                className={styles.navigationBar}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className={styles.logo}>
                    <span className={styles.logoText}>Taytrom</span>
                    <span className={styles.logoAccent}>Wonderland</span>
                </div>

                <div className={styles.navigationControls}>
                    <button className={styles.navButton}>
                        <MessageSquare size={18} />
                        <span>AI Chat</span>
                    </button>

                    <div className={styles.gardenHealth}>
                        <div className={styles.healthLabel}>Garden Health</div>
                        <div className={styles.healthBar}>
                            <motion.div
                                className={styles.healthFill}
                                initial={{ width: '0%' }}
                                animate={{ width: `${documentHealth}%` }}
                                transition={{ duration: 1 }}
                            />
                        </div>
                    </div>
                </div>
            </motion.div>
        );
    };

    // Main render
    return (
        <div
            className={`${styles.container} ${isDark ? styles.darkTheme : styles.lightTheme}`}
            ref={containerRef}
        >
            {renderNavigationBar()}

            <div className={styles.mainContent}>
                {/* Document panel on left */}
                <div className={styles.leftPanel}>
                    {renderDocumentPanel()}
                </div>

                {/* Right panel with garden and suggestions */}
                <div className={styles.rightPanel}>
                    <div className={styles.gardenPanel}>
                        {renderGarden()}
                    </div>

                    <div className={styles.aiBubblesPanel}>
                        {renderSuggestionsPanel()}
                    </div>
                </div>
            </div>

            {/* Overlays */}
            {renderCheshireCat()}
            {renderSpotlight()}
            {renderTooltip()}

            {/* Tour progress indicator */}
            <div className={styles.tourProgress}>
                {TOUR_STEPS.map((_, index) => (
                    <div
                        key={index}
                        className={`${styles.tourStep} ${index === tourStep ? styles.active : ''} ${index < tourStep ? styles.completed : ''}`}
                        onClick={() => setTourStep(index)}
                    />
                ))}
            </div>
        </div>
    );
};

// Sample document content
const INITIAL_DOCUMENT_CONTENT = [
    {
        id: 'section-1',
        type: 'heading',
        title: 'The Queen of Hearts',
        content: 'The Queen of Hearts, she made some tarts, all on a summer day.',
        style: undefined
    },
    {
        id: 'section-2',
        type: 'paragraph',
        title: 'Royal Gardens',
        content: 'The Queen\'s garden was the most magnificent Alice had ever seen. Roses of' +
            ' every color bloomed in perfect rows, tended by playing cards that scurried about with paintbrushes and watering cans.',
        style: undefined
    },
    {
        id: 'section-3',
        type: 'paragraph',
        title: 'Painting the Roses',
        content: 'The gardeners were frantically painting the white roses red. "The Queen will have our heads if she finds white roses in her garden!" they explained nervously.',
        style: undefined
    },
    {
        id: 'section-4',
        type: 'list',
        title: 'Royal Rules',
        content: '1. Never turn your back on the Queen\n2. Address her as "Your Majesty"\n3. Applaud all royal decrees\n4. Never question the rules of croquet',
        style: undefined
    },
    {
        id: 'section-5',
        type: 'paragraph',
        title: 'The Croquet Game',
        content: 'The Queen\'s croquet ground was unlike any Alice had seen before. The balls' +
            ' were live hedgehogs, the mallets were flamingos, and the soldiers had to bend over to form the hoops.',
        style: undefined
    }
];

// Initial AI suggestions
const INITIAL_SUGGESTIONS = [
    {
        id: 'suggestion-1',
        title: 'Add more visual detail',
        content: 'The roses glittered with morning dew, each petal precisely trimmed to the' +
            ' Queen\'s exacting standards.',
        type: 'content'
    },
    {
        id: 'suggestion-2',
        title: 'Restructure the introduction',
        content: 'Begin with the Queen\'s arrival to build tension. The trumpets sounded as the' +
            ' royal procession entered the garden, cards trembling in anticipation.',
        type: 'structure'
    },
    {
        id: 'suggestion-3',
        title: 'Use more formal language',
        content: 'Her Royal Majesty surveyed her dominion with a critical eye, searching for any imperfection that would warrant disciplinary action.',
        type: 'style'
    },
    {
        id: 'suggestion-4',
        title: 'Add character reaction',
        content: 'Alice felt her heart race as the Queen\'s gaze fell upon her. Would she be the' +
            ' next to hear those dreadful words?',
            type: 'content'
},
{
    id: 'suggestion-5',
        title: 'Include sensory details',
    content: 'The scent of fresh paint mingled with the sweet perfume of roses, while the snip-snip of gardening shears created a nervous rhythm.',
    type: 'style'
}
];

// Tour step definitions
const TOUR_STEPS = [
    {
        title: "Welcome to the Queen's Garden!",
        description: "This is where our AI suggestions bloom into reality! In this part of our tour, you'll learn how to enhance your document using our magical thought bubbles.",
        spotlightPosition: { x: 600, y: 300 },
        spotlightSize: { width: 450, height: 350 },
        tooltipPosition: { x: '70%', y: '30%' }
    },
    {
        title: "Try Dragging a Suggestion",
        description: "See those colorful bubbles? Each contains an AI-powered suggestion. Try dragging one onto a document section to enhance your writing!",
        spotlightPosition: { x: 700, y: 450 },
        spotlightSize: { width: 300, height: 200 },
        tooltipPosition: { x: '75%', y: '40%' }
    },
    {
        title: "The Garden Reflects Your Document",
        description: "Notice how the roses change as you enhance your document! Each rose represents a section of your writing, and its appearance shows how well-developed that section is.",
        spotlightPosition: { x: 600, y: 200 },
        spotlightSize: { width: 400, height: 250 },
        tooltipPosition: { x: '70%', y: '60%' }
    },
    {
        title: "Royal Decrees and Styling",
        description: "The Queen can issue decrees to style multiple sections at once! Try the 'Paint Red' or 'Paint White' buttons to see your garden transform.",
        spotlightPosition: { x: 700, y: 550 },
        spotlightSize: { width: 300, height: 150 },
        tooltipPosition: { x: '50%', y: '70%' }
    },
    {
        title: "Ready for the Next Adventure?",
        description: "You've mastered the art of enhancing your document with AI suggestions! Next, we'll explore how to format and style your text in even more creative ways.",
        spotlightPosition: { x: 400, y: 300 },
        spotlightSize: { width: 800, height: 400 },
        tooltipPosition: { x: '50%', y: '50%' }
    }
];

export default Compiler_5_Sections_InteractiveTour_Specialists_Writing;