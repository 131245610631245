import React, { useContext, createContext, useState, useEffect } from 'react';
import { ThemeContext } from '@/FullDevApp.jsx';
import { useThemeColors } from '@/templates-scope/colors/accepted/Accepted_Colors_GeneralWebpages_Templates.jsx';

/**
 * ✨ TAYTROM COLOR SYSTEM ✨
 * A comprehensive color management system for TACS-compliant UI
 * Enforces the use of #1C1C1C for text in light mode across all components
 */

// Create a context for the color system
const TaytromColorContext = createContext(null);

/**
 * Global color system provider that ensures color consistency
 * This should wrap your app or major steps of your application
 */
export const System_Colors_Templates = ({ children }) => {
    const { isDark } = useContext(ThemeContext);
    const colors = useThemeColors();

    // Generate the color system on mount and whenever theme changes
    useEffect(() => {
        applyGlobalColorOverrides(colors, isDark);
    }, [isDark, colors]);

    // Core text color palette that enforces #1C1C1C in light mode
    const textColors = {
        primary: isDark ? colors.getTacsColor('whiteKhaki', 1) : colors.getTacsColor('blackGray', 10), // #1C1C1C in light mode
        secondary: isDark ? colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.8) : colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.8),
        tertiary: isDark ? colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.6) : colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.6),
        inverse: isDark ? colors.getTacsColor('blackGray', 10) : colors.getTacsColor('whiteKhaki', 1)
    };

    // Background colors, with transparency for glassmorphic effects
    const backgroundColors = {
        primary: isDark ? colors.getTacsColor('blackGray', 9) : colors.getTacsColor('whiteKhaki', 1),
        elevated: isDark ? colors.getTacsColor('blackGray', 8) : colors.getTacsColor('whiteKhaki', 2),
        card: isDark ? colors.withAlpha(colors.getTacsColor('blackGray', 8), 0.7) : colors.withAlpha(colors.getTacsColor('whiteKhaki', 2), 0.7),
        surface: isDark ? colors.withAlpha(colors.getTacsColor('blackGray', 9), 0.85) : colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.95),
        subtle: isDark ? colors.withAlpha(colors.getTacsColor('blackGray', 7), 0.3) : colors.withAlpha(colors.getTacsColor('whiteKhaki', 3), 0.3)
    };

    // Border colors with appropriate transparency
    const borderColors = {
        subtle: isDark ? colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.1) : colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.1),
        default: isDark ? colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.2) : colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.2),
        strong: isDark ? colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.3) : colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.3),
        focus: colors.withAlpha(colors.getTacsColor('green', 8), 0.5) // Taytrom accent color for focus states
    };

    // Shadow system with different intensities
    const shadowColors = {
        subtle: isDark ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.07)',
        default: isDark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)',
        strong: isDark ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.15)',
        glow: colors.withAlpha(colors.getTacsColor('green', 8), isDark ? 0.25 : 0.15) // Taytrom accent glow
    };

    // Brand color palette
    const brandColors = {
        primary: colors.getTacsColor('green', 8), // #20B2AA - Taytrom brand accent
        secondary: colors.getTacsColor('blue', 7), // Steel blue secondary
        light: colors.getTacsColor('green', 5),
        dark: colors.getTacsColor('green', 9),
        gradient: `linear-gradient(135deg, ${colors.getTacsColor('green', 7)}, ${colors.getTacsColor('green', 8)})`
    };

    // State colors for feedback and notifications
    const stateColors = {
        success: {
            base: colors.getColor('success', 500),
            light: colors.withAlpha(colors.getColor('success', 500), 0.15),
            border: colors.withAlpha(colors.getColor('success', 500), 0.3),
            text: isDark ? colors.getColor('success', 300) : colors.getColor('success', 700)
        },
        error: {
            base: colors.getColor('error', 500),
            light: colors.withAlpha(colors.getColor('error', 500), 0.15),
            border: colors.withAlpha(colors.getColor('error', 500), 0.3),
            text: isDark ? colors.getColor('error', 300) : colors.getColor('error', 700)
        },
        warning: {
            base: colors.getColor('warning', 500),
            light: colors.withAlpha(colors.getColor('warning', 500), 0.15),
            border: colors.withAlpha(colors.getColor('warning', 500), 0.3),
            text: isDark ? colors.getColor('warning', 300) : colors.getColor('warning', 700)
        },
        info: {
            base: colors.getColor('info', 500),
            light: colors.withAlpha(colors.getColor('info', 500), 0.15),
            border: colors.withAlpha(colors.getColor('info', 500), 0.3),
            text: isDark ? colors.getColor('info', 300) : colors.getColor('info', 700)
        }
    };

    // Glassmorphic effect generator
    const createGlassEffect = (intensity = 'medium', accentColor = null) => {
        const baseColor = accentColor || (isDark ? colors.getTacsColor('blackGray', 8) : colors.getTacsColor('whiteKhaki', 1));
        const opacities = {
            subtle: isDark ? 0.4 : 0.3,
            medium: isDark ? 0.6 : 0.5,
            strong: isDark ? 0.75 : 0.65
        };

        const blurs = {
            subtle: '8px',
            medium: '12px',
            strong: '16px'
        };

        return {
            backgroundColor: colors.withAlpha(baseColor, opacities[intensity]),
            backdropFilter: `blur(${blurs[intensity]})`,
            WebkitBackdropFilter: `blur(${blurs[intensity]})`,
            borderRadius: '12px',
            border: `1px solid ${colors.withAlpha(isDark ? colors.getTacsColor('whiteKhaki', 1) : colors.getTacsColor('blackGray', 10), 0.1)}`,
            transition: 'all 0.3s ease'
        };
    };

    // Shadow generator
    const createShadow = (intensity = 'default', withGlow = false) => {
        const shadows = {
            subtle: `0 2px 6px ${shadowColors.subtle}`,
            default: `0 4px 12px ${shadowColors.default}`,
            strong: `0 8px 24px ${shadowColors.strong}`,
            elevated: `0 12px 28px ${shadowColors.default}, 0 4px 8px ${shadowColors.subtle}`,
            depth: `0 1px 2px ${shadowColors.subtle}, 0 4px 8px ${shadowColors.default}, 0 8px 16px ${shadowColors.subtle}`
        };

        return withGlow
            ? `${shadows[intensity]}, 0 0 16px ${shadowColors.glow}`
            : shadows[intensity];
    };

    // Gradient generator
    const createGradient = (type = 'brand', angle = '135deg') => {
        const gradients = {
            brand: `linear-gradient(${angle}, ${colors.getTacsColor('green', 7)}, ${colors.getTacsColor('green', 8)})`,
            success: `linear-gradient(${angle}, ${colors.getColor('success', 400)}, ${colors.getColor('success', 600)})`,
            info: `linear-gradient(${angle}, ${colors.getColor('info', 400)}, ${colors.getColor('info', 600)})`,
            warning: `linear-gradient(${angle}, ${colors.getColor('warning', 400)}, ${colors.getColor('warning', 600)})`,
            error: `linear-gradient(${angle}, ${colors.getColor('error', 400)}, ${colors.getColor('error', 600)})`,
            purple: `linear-gradient(${angle}, ${colors.getColor('purple', 400)}, ${colors.getColor('purple', 600)})`,
            dark: `linear-gradient(${angle}, ${colors.getTacsColor('blackGray', 8)}, ${colors.getTacsColor('blackGray', 9)})`,
            light: `linear-gradient(${angle}, ${colors.getTacsColor('whiteKhaki', 1)}, ${colors.getTacsColor('whiteKhaki', 2)})`,
            glass: isDark
                ? `linear-gradient(${angle}, ${colors.withAlpha(colors.getTacsColor('blackGray', 8), 0.7)}, ${colors.withAlpha(colors.getTacsColor('blackGray', 9), 0.6)})`
                : `linear-gradient(${angle}, ${colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.8)}, ${colors.withAlpha(colors.getTacsColor('whiteKhaki', 2), 0.7)})`
        };

        return gradients[type] || gradients.brand;
    };

    // The complete color system available from context
    const colorSystem = {
        isDark,
        text: textColors,
        background: backgroundColors,
        border: borderColors,
        brand: brandColors,
        state: stateColors,
        glass: createGlassEffect,
        shadow: createShadow,
        gradient: createGradient,

        // Helper functions
        withAlpha: (color, alpha) => colors.withAlpha(color, alpha),
        getAccessibleText: (backgroundColor) => {
            return colors.getAccessibleTextColor(backgroundColor);
        },

        // Raw color access for advanced use cases
        getTacsColor: colors.getTacsColor,
        getColor: colors.getColor,
        tacsColors: colors.tacsColors,
        palettes: colors.palettes,

        // Theme info
        theme: isDark ? 'dark' : 'light'
    };

    return (
        <TaytromColorContext.Provider value={colorSystem}>
            {children}
        </TaytromColorContext.Provider>
    );
};

/**
 * Hook for accessing the Taytrom color system
 */
export const useTaytromColors = () => {
    const context = useContext(TaytromColorContext);
    if (!context) {
        console.warn('useTaytromColors must be used within a TaytromColorSystemProvider');
        // Provide fallback using direct access if not in context
        const { isDark } = useContext(ThemeContext);
        const colors = useThemeColors();

        // Basic fallback
        return {
            isDark,
            text: {
                primary: isDark ? colors.getTacsColor('whiteKhaki', 1) : colors.getTacsColor('blackGray', 10),
                secondary: isDark ? colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.8) : colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.8)
            },
            getTacsColor: colors.getTacsColor,
            withAlpha: colors.withAlpha,
            theme: isDark ? 'dark' : 'light'
        };
    }
    return context;
};

/**
 * Text component with standardized colors
 * Ensures text uses #1C1C1C in light mode
 */
export const TaytromText = ({
                                children,
                                variant = 'primary',
                                element = 'span',
                                className = '',
                                style = {},
                                as
                            }) => {
    const colors = useTaytromColors();
    const CustomTag = as || element;

    // Text color variants that enforce #1C1C1C in light mode
    const getColorForVariant = (variant) => {
        if (variant.includes('.')) {
            // Support dot notation for accessing nested state colors
            const [category, specific] = variant.split('.');
            if (category === 'state' && colors.state[specific]) {
                return colors.state[specific].text;
            }
        }

        const standardVariants = {
            primary: colors.text.primary,
            secondary: colors.text.secondary,
            tertiary: colors.text.tertiary,
            inverse: colors.text.inverse,
            brand: colors.brand.primary,
            success: colors.state.success.text,
            error: colors.state.error.text,
            warning: colors.state.warning.text,
            info: colors.state.info.text
        };

        return standardVariants[variant] || colors.text.primary;
    };

    const textStyle = {
        color: getColorForVariant(variant),
        ...style
    };

    return (
        <CustomTag className={className} style={textStyle}>
            {children}
        </CustomTag>
    );
};

/**
 * Card component with standardized glassmorphic styling
 */
export const TaytromCard = ({
                                children,
                                variant = 'default',
                                intensity = 'medium',
                                withShadow = true,
                                withGlow = false,
                                accent = null,
                                className = '',
                                style = {},
                                onClick
                            }) => {
    const colors = useTaytromColors();

    // Determine accent color if variant is state-based
    let accentColor = accent;
    if (!accentColor && ['success', 'error', 'warning', 'info'].includes(variant)) {
        accentColor = colors.state[variant].base;
    } else if (variant === 'brand') {
        accentColor = colors.brand.primary;
    }

    // Base style with glassmorphic effect
    const cardStyle = {
        ...colors.glass(intensity, accentColor),
        boxShadow: withShadow ? colors.shadow(withGlow ? 'elevated' : 'default', accentColor && withGlow) : 'none',
        padding: '1.5rem',
        color: colors.text.primary, // Enforce correct text color
        ...style,
        // If variant is state-based, add a subtle left border
        ...((['success', 'error', 'warning', 'info', 'brand'].includes(variant) && !accent) ? {
            borderLeft: `4px solid ${variant === 'brand' ? colors.brand.primary : colors.state[variant].base}`
        } : {})
    };

    return (
        <div
            className={className}
            style={cardStyle}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

/**
 * Button component with standardized styling
 */
export const TaytromButton = ({
                                  children,
                                  variant = 'primary',
                                  size = 'default',
                                  outlined = false,
                                  glass = false,
                                  className = '',
                                  style = {},
                                  onClick,
                                  disabled = false,
                                  type = 'button',
                                  withIcon = false,
                                  icon = null
                              }) => {
    const colors = useTaytromColors();

    // Size variants
    const sizeStyles = {
        small: {
            padding: '0.5rem 1rem',
            fontSize: '0.875rem',
            borderRadius: '0.375rem',
        },
        default: {
            padding: '0.625rem 1.25rem',
            fontSize: '1rem',
            borderRadius: '0.5rem',
        },
        large: {
            padding: '0.75rem 1.5rem',
            fontSize: '1.125rem',
            borderRadius: '0.625rem',
        }
    };

    // Variant styling
    const getVariantStyle = () => {
        const variants = {
            primary: {
                background: outlined
                    ? 'transparent'
                    : glass
                        ? colors.glass('medium', colors.brand.primary).backgroundColor
                        : colors.brand.gradient,
                color: outlined ? colors.brand.primary : '#FFFFFF',
                border: outlined ? `2px solid ${colors.brand.primary}` : 'none',
                boxShadow: disabled ? 'none' : glass ? colors.shadow('default') : outlined ? 'none' : colors.shadow('default', true)
            },
            secondary: {
                background: outlined
                    ? 'transparent'
                    : glass
                        ? colors.glass('medium').backgroundColor
                        : colors.gradient('glass'),
                color: colors.text.primary,
                border: outlined ? `2px solid ${colors.border.default}` : `1px solid ${colors.border.subtle}`,
                boxShadow: disabled ? 'none' : glass ? colors.shadow('subtle') : outlined ? 'none' : colors.shadow('subtle')
            },
            success: {
                background: outlined
                    ? 'transparent'
                    : glass
                        ? colors.glass('medium', colors.state.success.base).backgroundColor
                        : colors.gradient('success'),
                color: outlined ? colors.state.success.base : '#FFFFFF',
                border: outlined ? `2px solid ${colors.state.success.base}` : 'none',
                boxShadow: disabled ? 'none' : glass ? colors.shadow('default') : outlined ? 'none' : colors.shadow('default')
            },
            error: {
                background: outlined
                    ? 'transparent'
                    : glass
                        ? colors.glass('medium', colors.state.error.base).backgroundColor
                        : colors.gradient('error'),
                color: outlined ? colors.state.error.base : '#FFFFFF',
                border: outlined ? `2px solid ${colors.state.error.base}` : 'none',
                boxShadow: disabled ? 'none' : glass ? colors.shadow('default') : outlined ? 'none' : colors.shadow('default')
            },
            warning: {
                background: outlined
                    ? 'transparent'
                    : glass
                        ? colors.glass('medium', colors.state.warning.base).backgroundColor
                        : colors.gradient('warning'),
                color: outlined ? colors.state.warning.base : colors.isDark ? '#000000' : '#FFFFFF',
                border: outlined ? `2px solid ${colors.state.warning.base}` : 'none',
                boxShadow: disabled ? 'none' : glass ? colors.shadow('default') : outlined ? 'none' : colors.shadow('default')
            },
            info: {
                background: outlined
                    ? 'transparent'
                    : glass
                        ? colors.glass('medium', colors.state.info.base).backgroundColor
                        : colors.gradient('info'),
                color: outlined ? colors.state.info.base : '#FFFFFF',
                border: outlined ? `2px solid ${colors.state.info.base}` : 'none',
                boxShadow: disabled ? 'none' : glass ? colors.shadow('default') : outlined ? 'none' : colors.shadow('default')
            },
            ghost: {
                background: 'transparent',
                color: colors.text.primary,
                border: 'none',
                boxShadow: 'none'
            }
        };

        return variants[variant] || variants.primary;
    };

    const buttonStyle = {
        ...sizeStyles[size],
        ...getVariantStyle(),
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: withIcon ? '0.5rem' : '0',
        fontWeight: 500,
        lineHeight: 1.5,
        cursor: disabled ? 'not-allowed' : 'pointer',
        transition: 'all 0.2s ease',
        opacity: disabled ? 0.6 : 1,
        ...style
    };

    return (
        <button
            type={type}
            className={className}
            style={buttonStyle}
            onClick={onClick}
            disabled={disabled}
        >
            {withIcon && icon && (
                <span style={{ display: 'flex', alignItems: 'center' }}>{icon}</span>
            )}
            {children}
        </button>
    );
};

/**
 * Badge/pill component with standardized styling
 */
export const TaytromBadge = ({
                                 children,
                                 variant = 'default',
                                 size = 'default',
                                 className = '',
                                 style = {}
                             }) => {
    const colors = useTaytromColors();

    // Badge backgrounds with proper contrast
    const badgeStyles = {
        default: {
            background: colors.glass('subtle').backgroundColor,
            color: colors.text.primary,
            border: `1px solid ${colors.border.subtle}`
        },
        primary: {
            background: colors.withAlpha(colors.brand.primary, 0.15),
            color: colors.isDark ? colors.withAlpha(colors.brand.primary, 0.9) : colors.brand.primary,
            border: `1px solid ${colors.withAlpha(colors.brand.primary, 0.3)}`
        },
        success: {
            background: colors.state.success.light,
            color: colors.state.success.text,
            border: `1px solid ${colors.state.success.border}`
        },
        error: {
            background: colors.state.error.light,
            color: colors.state.error.text,
            border: `1px solid ${colors.state.error.border}`
        },
        warning: {
            background: colors.state.warning.light,
            color: colors.state.warning.text,
            border: `1px solid ${colors.state.warning.border}`
        },
        info: {
            background: colors.state.info.light,
            color: colors.state.info.text,
            border: `1px solid ${colors.state.info.border}`
        }
    };

    const sizeStyles = {
        small: {
            padding: '0.125rem 0.5rem',
            fontSize: '0.75rem',
            borderRadius: '0.75rem'
        },
        default: {
            padding: '0.25rem 0.625rem',
            fontSize: '0.875rem',
            borderRadius: '1rem'
        },
        large: {
            padding: '0.375rem 0.75rem',
            fontSize: '1rem',
            borderRadius: '1.25rem'
        }
    };

    const badgeStyle = {
        ...badgeStyles[variant],
        ...sizeStyles[size],
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        ...style
    };

    return (
        <span className={className} style={badgeStyle}>
      {children}
    </span>
    );
};

/**
 * Heading component with standardized text colors
 */
export const TaytromHeading = ({
                                   children,
                                   level = 2,
                                   variant = 'primary',
                                   withAccent = false,
                                   className = '',
                                   style = {}
                               }) => {
    const colors = useTaytromColors();

    // Get proper heading level
    const headingLevel = Math.max(1, Math.min(6, level)); // Ensure level is between 1-6
    const HeadingTag = `h${headingLevel}`;

    // Font size mapping based on heading level
    const fontSizes = {
        1: '2.25rem',
        2: '1.875rem',
        3: '1.5rem',
        4: '1.25rem',
        5: '1.125rem',
        6: '1rem'
    };

    // Text color based on variant
    const getTextColor = () => {
        if (variant === 'brand') return colors.brand.primary;
        if (['success', 'error', 'warning', 'info'].includes(variant)) {
            return colors.state[variant].text;
        }
        return colors.text[variant] || colors.text.primary;
    };

    // Create gradient text effect if needed
    const gradientStyle = withAccent ? {
        background: variant === 'brand'
            ? colors.gradient('brand')
            : ['success', 'error', 'warning', 'info'].includes(variant)
                ? colors.gradient(variant)
                : colors.gradient('brand'),
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block'
    } : {};

    const headingStyle = {
        fontSize: fontSizes[headingLevel],
        fontWeight: 700,
        lineHeight: 1.2,
        marginBottom: '0.5em',
        color: withAccent ? undefined : getTextColor(),
        ...gradientStyle,
        ...style
    };

    return (
        <HeadingTag className={className} style={headingStyle}>
            {children}
        </HeadingTag>
    );
};

/**
 * Container component with standardized glassmorphic effects
 */
export const TaytromContainer = ({
                                     children,
                                     glass = true,
                                     intensity = 'subtle',
                                     withShadow = true,
                                     maxWidth = '1200px',
                                     className = '',
                                     style = {}
                                 }) => {
    const colors = useTaytromColors();

    const containerStyle = {
        width: '100%',
        maxWidth,
        margin: '0 auto',
        padding: '2rem',
        ...(glass ? colors.glass(intensity) : {}),
        ...(withShadow && glass ? { boxShadow: colors.shadow('default') } : {}),
        color: colors.text.primary, // Enforce correct text color
        ...style
    };

    return (
        <div className={className} style={containerStyle}>
            {children}
        </div>
    );
};

/**
 * Apply global color overrides to ensure consistent colors
 */
const applyGlobalColorOverrides = (colors, isDark) => {
    // Remove any previously added style element
    const existingStyle = document.getElementById('taytrom-color-overrides');
    if (existingStyle) {
        existingStyle.remove();
    }

    // Create the style element with all overrides
    const style = document.createElement('style');
    style.id = 'taytrom-color-overrides';

    // Set CSS variables and global styles with important to override any existing styles
    style.innerHTML = `
    :root {
      /* Core text colors with #1C1C1C for light mode */
      --tacs-light-substance-primary: ${colors.getTacsColor('blackGray', 10)} !important; /* #1C1C1C */
      --tacs-dark-substance-primary: ${colors.getTacsColor('whiteKhaki', 1)} !important;
      
      /* Text opacity variants */
      --text-primary-light: ${colors.getTacsColor('blackGray', 10)} !important;
      --text-secondary-light: ${colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.8)} !important;
      --text-tertiary-light: ${colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.6)} !important;
      
      --text-primary-dark: ${colors.getTacsColor('whiteKhaki', 1)} !important;
      --text-secondary-dark: ${colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.8)} !important;
      --text-tertiary-dark: ${colors.withAlpha(colors.getTacsColor('whiteKhaki', 1), 0.6)} !important;
      
      /* Brand color */
      --tacs-brand-primary: ${colors.getTacsColor('green', 8)} !important; /* #20B2AA */
    }
    
    /* Light theme text color overrides */
    [data-theme="light"] {
      --text-primary: var(--text-primary-light) !important;
      --text-secondary: var(--text-secondary-light) !important;
      --text-tertiary: var(--text-tertiary-light) !important;
      
      /* Override common text classes */
      color: var(--text-primary-light);
    }
    
    /* Dark theme text color overrides */
    [data-theme="dark"] {
      --text-primary: var(--text-primary-dark) !important;
      --text-secondary: var(--text-secondary-dark) !important;
      --text-tertiary: var(--text-tertiary-dark) !important;
      
      /* Override common text classes */
      color: var(--text-primary-dark);
    }
    
    /* Common text class overrides */
    .substance-primary {
      color: var(--text-primary) !important;
    }
    
    .substance-secondary {
      color: var(--text-secondary) !important;
    }
    
    .substance-tertiary {
      color: var(--text-tertiary) !important;
    }
    
    /* Heading overrides */
    h1, h2, h3, h4, h5, h6 {
      color: var(--text-primary) !important;
    }
    
    /* Link color standardization */
    a {
      color: var(--tacs-brand-primary);
    }

    /* Form element text color overrides */
    input, select, textarea, button {
        color: var(--text-primary) !important;
    }

    /* Table text color standardization */
    table, th, td {
        color: var(--text-primary) !important;
    }

    /* Force correct text colors in Mission Control Dashboard */
.dashboard-card h1, .dashboard-card h2, .dashboard-card h3,
.dashboard-card h4, .dashboard-card p, .dashboard-card span,
.dashboard-card div:not([class*="bg-"]) {
        color: var(--text-primary) !important;
    }

    /* Force correct text colors in Progress Portal */
.TANK-elevated h1, .TANK-elevated h2, .TANK-elevated h3,
.TANK-elevated h4, .TANK-elevated p, .TANK-elevated span,
.TANK-elevated div:not([class*="bg-"]) {
        color: var(--text-primary) !important;
    }

    /* Special elements - preserve original colors for these */
    [class*="gradient-text"], [class*="glass-text-gradient"],
        [style*="background-clip: text"], [style*="webkit-background-clip: text"] {
        -webkit-text-fill-color: transparent !important;
    }

    /* Preserve brand colors for icons and accents */
    [class*="brand-icon"], [class*="accent-icon"],
        [class*="tacs-icon"], svg {
        color: inherit !important;
    }
    `;
  
  document.head.appendChild(style);
};

/**
 * Apply the color system to a specific element's CSS variables
 * This is useful for dynamically updated components like modals
 */
export const applyColorSystemToElement = (element, colors, isDark) => {
  if (!element) return;
  
  // Set CSS variables on the specific element
  element.style.setProperty('--text-primary', isDark ? colors.text.primary : colors.getTacsColor('blackGray', 10));
  element.style.setProperty('--text-secondary', isDark ? colors.text.secondary : colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.8));
  element.style.setProperty('--text-tertiary', isDark ? colors.text.tertiary : colors.withAlpha(colors.getTacsColor('blackGray', 10), 0.6));
  
  // Additional custom properties for this element
  element.style.setProperty('--color-bg', isDark ? colors.background.primary : colors.background.primary);
  element.style.setProperty('--color-surface', isDark ? colors.background.elevated : colors.background.elevated);
  element.style.setProperty('--color-border', isDark ? colors.border.default : colors.border.default);
  
  // Force the element's text color 
  element.style.color = isDark ? colors.text.primary : colors.getTacsColor('blackGray', 10);
};

/**
 * Panel component with standardized styling
 * More lightweight than a card for grouping content
 */
export const TaytromPanel = ({
  children,
  padded = true,
  bordered = true,
  className = '',
  style = {}
}) => {
  const colors = useTaytromColors();
  
  const panelStyle = {
    ...(padded ? { padding: '1rem' } : {}),
    ...(bordered ? { 
      border: `1px solid ${colors.border.default}`,
      borderRadius: '0.5rem' 
    } : {}),
    color: colors.text.primary, // Enforce correct text color
    ...style
  };
  
  return (
    <div className={className} style={panelStyle}>
      {children}
    </div>
  );
};

/**
 * Divider component with standardized styling
 */
export const TaytromDivider = ({
  variant = 'default',
  vertical = false,
  spacing = 'default',
  width = 1,
  className = '',
  style = {}
}) => {
  const colors = useTaytromColors();
  
  // Get color based on variant
  const getColor = () => {
    if (variant === 'brand') return colors.withAlpha(colors.brand.primary, 0.3);
    if (['success', 'error', 'warning', 'info'].includes(variant)) {
      return colors.withAlpha(colors.state[variant].base, 0.3);
    }
    
    // Default divider colors
    const dividerColors = {
      default: colors.border.default,
      subtle: colors.border.subtle,
      strong: colors.border.strong
    };
    
    return dividerColors[variant] || dividerColors.default;
  };
  
  // Spacing variants
  const spacingValues = {
    none: '0',
    small: '0.5rem',
    default: '1rem',
    large: '2rem'
  };
  
  const dividerStyle = {
    ...(vertical ? {
      width: `${width}px`,
      height: 'auto',
      alignSelf: 'stretch',
      marginLeft: spacingValues[spacing],
      marginRight: spacingValues[spacing]
    } : {
      height: `${width}px`,
      width: '100%',
      marginTop: spacingValues[spacing],
      marginBottom: spacingValues[spacing]
    }),
    backgroundColor: getColor(),
    border: 'none',
    ...style
  };
  
  return (
    <hr className={className} style={dividerStyle} />
  );
};

/**
 * Alert/notification component with standardized styling
 */
export const TaytromAlert = ({
  children,
  variant = 'info',
  withIcon = true,
  icon = null,
  dismissible = false,
  onDismiss = null,
  className = '',
  style = {}
}) => {
  const colors = useTaytromColors();
  
  // Get the appropriate icon based on variant
  const getDefaultIcon = () => {
    // Default icons for each variant - these would be imported from your icon library
    // This is pseudocode - replace with actual icons
    const icons = {
      success: <span role="img" aria-label="success">✓</span>,
      error: <span role="img" aria-label="error">⚠</span>,
      warning: <span role="img" aria-label="warning">!</span>,
      info: <span role="img" aria-label="info">ℹ</span>
    };
    
    return icons[variant] || icons.info;
  };
  
  // Get styles based on variant
  const alertStyle = {
    display: 'flex',
    padding: '1rem',
    borderRadius: '0.5rem',
    backgroundColor: colors.state[variant].light,
    borderLeft: `4px solid ${colors.state[variant].base}`,
    color: colors.state[variant].text,
    ...style
  };
  
  return (
    <div className={className} style={alertStyle}>
      {withIcon && (
        <div style={{ marginRight: '0.75rem', display: 'flex', alignItems: 'flex-start' }}>
          {icon || getDefaultIcon()}
        </div>
      )}
      <div style={{ flex: 1 }}>
        {children}
      </div>
      {dismissible && onDismiss && (
        <button
          onClick={onDismiss}
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            marginLeft: '0.75rem',
            padding: '0.25rem',
            color: 'inherit',
            opacity: 0.7
          }}
        >
          {/* Close icon - replace with your actual icon */}
          <span role="img" aria-label="close">×</span>
        </button>
      )}
    </div>
  );
};

/**
 * Progress component with standardized styling
 */
export const TaytromProgress = ({
  value = 0,
  max = 100,
  variant = 'brand',
  showLabel = false,
  size = 'default',
  className = '',
  style = {}
}) => {
  const colors = useTaytromColors();
  
  // Calculate percentage
  const percentage = Math.min(100, Math.max(0, (value / max) * 100));
  
  // Get color based on variant
  const getColor = () => {
    if (variant === 'brand') return colors.brand.gradient;
    if (['success', 'error', 'warning', 'info'].includes(variant)) {
      return colors.gradient(variant);
    }
    return colors.brand.gradient;
  };
  
  // Size variants
  const sizeValues = {
    small: { height: '0.5rem', fontSize: '0.75rem' },
    default: { height: '0.75rem', fontSize: '0.875rem' },
    large: { height: '1rem', fontSize: '1rem' }
  };
  
  const containerStyle = {
    width: '100%',
    height: sizeValues[size].height,
    backgroundColor: colors.isDark ? 
      colors.withAlpha('#000000', 0.2) : 
      colors.withAlpha('#000000', 0.05),
    borderRadius: '9999px',
    overflow: 'hidden',
    position: 'relative',
    ...style
  };
  
  const barStyle = {
    width: `${percentage}%`,
    height: '100%',
    background: getColor(),
    borderRadius: '9999px',
    transition: 'width 0.3s ease'
  };
  
  return (
    <div className={className} style={{ width: '100%' }}>
      <div style={containerStyle}>
        <div style={barStyle}></div>
      </div>
      {showLabel && (
        <div style={{ 
          marginTop: '0.25rem', 
          fontSize: sizeValues[size].fontSize,
          color: colors.text.secondary,
          textAlign: 'right'
        }}>
          {percentage.toFixed(0)}%
        </div>
      )}
    </div>
  );
};

/**
 * Form input component with standardized styling
 */
export const TaytromInput = ({
  type = 'text',
  placeholder = '',
  label = '',
  name,
  value,
  onChange,
  disabled = false,
  error = null,
  helper = null,
  className = '',
  style = {},
  inputStyle = {}
}) => {
  const colors = useTaytromColors();
  
  // Input style with proper text color
  const baseInputStyle = {
    width: '100%',
    padding: '0.75rem 1rem',
    fontSize: '1rem',
    lineHeight: 1.5,
    backgroundColor: colors.isDark ? 
      colors.withAlpha(colors.background.elevated, 0.8) : 
      colors.background.primary,
    color: colors.text.primary, // Enforce correct text color
    border: `1px solid ${error ? colors.state.error.base : colors.border.default}`,
    borderRadius: '0.5rem',
    transition: 'all 0.2s ease',
    outline: 'none',
    ...inputStyle
  };
  
  // Focus state style
  const focusStyle = {
    boxShadow: `0 0 0 3px ${error ?
        colors.withAlpha(colors.state.error.base, 0.2) :
        colors.withAlpha(colors.brand.primary, 0.2)}`,
    borderColor: error ? colors.state.error.base : colors.brand.primary
  };
  
  // Container style
  const containerStyle = {
    marginBottom: '1rem',
    ...style
  };
  
  return (
    <div className={className} style={containerStyle}>
      {label && (
        <label 
          htmlFor={name} 
          style={{ 
            display: 'block', 
            marginBottom: '0.5rem', 
            fontSize: '0.875rem', 
            fontWeight: 500,
            color: error ? colors.state.error.text : colors.text.primary 
          }}
        >
          {label}
        </label>
      )}
      
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        style={baseInputStyle}
        onFocus={(e) => {
          Object.assign(e.target.style, focusStyle);
        }}
        onBlur={(e) => {
          e.target.style.boxShadow = 'none';
          e.target.style.borderColor = error ? colors.state.error.base : colors.border.default;
        }}
      />
      
      {(error || helper) && (
        <div style={{ 
          marginTop: '0.25rem', 
          fontSize: '0.75rem',
          color: error ? colors.state.error.text : colors.text.tertiary
        }}>
          {error || helper}
        </div>
      )}
    </div>
  );
};

/**
 * Avatar component with standardized styling
 */
export const TaytromAvatar = ({
  src = null,
  alt = 'User avatar',
  size = 'medium',
  initials = null,
  variant = 'default',
  className = '',
  style = {}
}) => {
  const colors = useTaytromColors();
  
  // Size variants
  const sizeValues = {
    small: '2rem',
    medium: '3rem',
    large: '4rem',
    xlarge: '6rem'
  };
  
  // Get background color based on variant
  const getBackground = () => {
    if (variant === 'brand') return colors.withAlpha(colors.brand.primary, 0.2);
    if (['success', 'error', 'warning', 'info'].includes(variant)) {
      return colors.withAlpha(colors.state[variant].base, 0.2);
    }
    
    // Default gradient for avatars
    return colors.isDark ? 
      colors.withAlpha(colors.background.elevated, 0.9) : 
      colors.withAlpha(colors.background.elevated, 0.4);
  };
  
  // Get text color for initials
  const getTextColor = () => {
    if (variant === 'brand') return colors.brand.primary;
    if (['success', 'error', 'warning', 'info'].includes(variant)) {
      return colors.state[variant].base;
    }
    return colors.text.primary;
  };
  
  const avatarStyle = {
    width: sizeValues[size],
    height: sizeValues[size],
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: getBackground(),
    color: getTextColor(),
    fontWeight: 600,
    fontSize: `calc(${sizeValues[size]} / 2.5)`,
...style
};

return (
    <div className={className} style={avatarStyle}>
        {src ? (
            <img
                src={src}
                alt={alt}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
        ) : (
            initials || alt.charAt(0).toUpperCase()
        )}
    </div>
);
};

/**
 * Export all components for easy import
 */
export {


    applyGlobalColorOverrides,
};