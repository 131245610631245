import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useTheme} from "@/Phase1App.jsx";
import HypnoticSpiral from "@/assets/HypnoticSpiral.jsx";

import { ArrowRight, Maximize2, Wand2, Rocket, Sparkles } from 'lucide-react';
import styles from './HypnoticSpiral_Pages_Homepage.module.css';

/**
 * HypnoticSpiralLanding - A standalone landing page featuring the hypnotic spiral
 *
 * This component creates a separate landing page experience focused on the
 * hypnotic spiral with CTAs to explore the showcase or sign in.
 */
const HypnoticSpiralLanding = ({ onExplore, onGetStarted }) => {
    const { isDark } = useTheme() || { isDark: false };
    const [particles, setParticles] = useState(
        Array.from({ length: 35 }, (_, i) => ({
            id: i,
            x: Math.random() * 100,
            y: Math.random() * 100,
            size: 2 + Math.random() * 6,
            alpha: 0.1 + Math.random() * 0.4,
            speed: 0.5 + Math.random() * 1.5,
            glowSize: 8 + Math.random() * 12
        }))
    );

    const containerRef = useRef(null);
    const animationFrameRef = useRef(null);
    const isComponentMounted = useRef(true);

    // FIX: Replace setInterval with requestAnimationFrame for particle animation
    useEffect(() => {
        // Animation function using requestAnimationFrame
        const animateParticles = () => {
            if (!isComponentMounted.current) return;

            setParticles(prev =>
                prev.map(particle => ({
                    ...particle,
                    y: (particle.y - particle.speed) % 100,
                    x: particle.x + (Math.random() - 0.5) * 0.3,
                    alpha: Math.max(0.1, Math.min(0.5, particle.alpha + (Math.random() - 0.5) * 0.05))
                }))
            );

            // Schedule the next frame
            animationFrameRef.current = requestAnimationFrame(animateParticles);
        };

        // Start the animation
        animationFrameRef.current = requestAnimationFrame(animateParticles);

        // Cleanup function
        return () => {
            isComponentMounted.current = false;
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, []);

    // Render the 3D title component
    const TaytromTitle3D = () => {
        return (
            <div
                className={styles.titleContainer}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflow: 'hidden',
                    zIndex: 9,
                    pointerEvents: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    perspective: '1000px',
                    perspectiveOrigin: 'center center',
                    opacity: 1
                }}
            >
                <h1
                    className={styles.title3d}
                    style={{
                        fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
                        fontWeight: 1000,
                        fontSize: 120,
                        letterSpacing: '0.05em',
                        textAlign: 'center',
                        position: 'relative',
                        transform: 'rotateX(10deg) rotateY(15deg)',
                        transformStyle: 'preserve-3d',
                        color: isDark ? '#ffffff' : '#1c1c1c',
                        margin: 0,
                        padding: 0,
                        userSelect: 'none',
                        textShadow: isDark
                            ? `
                            0 1px 0 #ccc,
                            0 2px 0 #c9c9c9,
                            0 3px 0 #bbb,
                            0 4px 0 #b9b9b9,
                            0 5px 0 #aaa,
                            0 6px 1px rgba(0,0,0,.1),
                            0 0 5px rgba(0,0,0,.1),
                            0 1px 3px rgba(0,0,0,.3),
                            0 3px 5px rgba(0,0,0,.2),
                            0 5px 10px rgba(0,0,0,.25),
                            0 10px 10px rgba(0,0,0,.2),
                            0 20px 20px rgba(0,0,0,.15)`
                            : `
                            0 1px 0 #4a4a4a,
                            0 2px 0 #444,
                            0 3px 0 #3d3d3d,
                            0 4px 0 #333,
                            0 5px 0 #2b2b2b,
                            0 6px 1px rgba(0,0,0,.1),
                            0 0 5px rgba(0,0,0,.1),
                            0 1px 3px rgba(0,0,0,.3),
                            0 3px 5px rgba(0,0,0,.2),
                            0 5px 10px rgba(0,0,0,.25),
                            0 10px 10px rgba(0,0,0,.2),
                            0 20px 20px rgba(0,0,0,.15)`,
                        animation: 'titleFloat 8s cubic-bezier(0.645, 0.045, 0.355, 1) infinite'
                    }}
                >
                    TAYTROM
                </h1>
            </div>
        );
    };

    return (
        <div className={styles.container} ref={containerRef}>
            {/* Background particles with glow effect */}
            <div className={styles.particlesContainer}>
                {particles.map(particle => (
                    <div
                        key={particle.id}
                        className={styles.particle}
                        style={{
                            left: `${particle.x}%`,
                            top: `${particle.y}%`,
                            width: `${particle.size}px`,
                            height: `${particle.size}px`,
                            opacity: particle.alpha
                        }}
                    >
                        {/* Add particle glow effect */}
                        <div
                            className={styles.particleGlow}
                            style={{
                                width: `${particle.glowSize}px`,
                                height: `${particle.glowSize}px`,
                                left: `${-(particle.glowSize - particle.size) / 2}px`,
                                top: `${-(particle.glowSize - particle.size) / 2}px`
                            }}
                        />
                    </div>
                ))}
            </div>

            {/* Title container with 3D effect */}
            <div className={styles.titleContainer} style={{
                width: "100%",
                height: "160px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "2rem",
                position: "relative",
                zIndex: "20"
            }}>
                <TaytromTitle3D />
            </div>

            {/* Hypnotic spiral integration */}
            <motion.div
                initial={{opacity: 0, scale: 0.9}}
                animate={{opacity: 1, scale: 1}}
                transition={{
                    duration: 0.8,
                    delay: 0.6,
                    ease: [0.175, 0.885, 0.32, 1.275]
                }}
            >
                <HypnoticSpiral
                    spiralImageSrc="@/assets/spiral.png"
                    onExplore={onExplore}
                />
            </motion.div>

            {/* Interactive header with CTA buttons */}
            <motion.header
                className={styles.heroHeader}
                initial="hidden"
                animate="visible"
                variants={{
                    hidden: { opacity: 0, y: 20 },
                    visible: { opacity: 1, y: 0, transition: { duration: 0.7, ease: [0.215, 0.61, 0.355, 1] } }
                }}
            >
                <h1 className={styles.heroTitle}>Creativity's AI Wonderland</h1>
                <p className={styles.subtitle}>
                    Experience AI-powered document creation with visual structure and
                    intelligent suggestions
                </p>

            </motion.header>

            {/* Animated sparkle effect */}
            <div className={styles.sparkleContainer}>
                {Array.from({length: 5}).map((_, i) => (
                    <div
                        key={`sparkle-${i}`}
                        className={`${styles.sparkle} ${styles[`sparkle${i + 1}`]}`}
                    >
                        <Sparkles size={16} className={styles.sparkleIcon} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HypnoticSpiralLanding;