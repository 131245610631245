import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Brain, SendHorizontal, Sparkles, Loader, RotateCcw, X, MessageSquare,
    Highlighter, FileEdit, Edit, Check, RefreshCw, Paperclip,
    ChevronDown, ChevronUp, ArrowDownCircle, ArrowUpCircle, AlertCircle } from 'lucide-react';
import { useTheme, useOrchestrator } from '@/FullDevApp.jsx';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark, oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Import the Attachments component
import Attachments_Specialists_Writing from '../attachments/Attachments_Specialists_Writing.jsx';
import GenerateFromTitle
    from "@/writing-scope/specialists/features/generation/from-title/FromTitle_Generation_Features_Specialists_Writing.jsx";

// Import CSS module
import styles from './AIComponent_Specialists_Writing.module.css';

/**
 * @component AIComponent_Specialists_Writing
 *
 * @description
 * A specialized AI component for the writing environment that provides
 * contextual suggestions, content generation, style transformations,
 * and editing capabilities specifically tailored for document-panel creation.
 * ENHANCED: With direct section modification capabilities, file attachments, improved scrolling,
 * and enhanced visual feedback based on Taytrom's design language
 */
const AIComponent_Specialists_Writing = ({
                                             selectedSection = null,
                                             documentContent = {},
                                             onApplySuggestion = () => {},
                                             onAddThought = () => {},
                                             onReplaceSectionContent = () => {},
                                             onExpandSection = () => {},
                                             className = "",
                                             isMinimized = false,
                                             onToggleMinimize = () => {},
                                             orchestrator = null
                                         }) => {
    // Theme Context
    const { isDark } = useTheme() || { isDark: false };
    const codeStyle = isDark ? atomDark : oneLight;

    // Core states
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [conversationId, setConversationId] = useState(null);
    const [extendedThinkingEnabled, setExtendedThinkingEnabled] = useState(false);
    const [thinkingBudget, setThinkingBudget] = useState(2048);
    const [currentThoughts, setCurrentThoughts] = useState('');
    const [isPending, setIsPending] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    // Writing-specific states
    const [targetSection, setTargetSection] = useState(null);
    const [lastGeneratedSection, setLastGeneratedSection] = useState(null);

    // New attachment states
    const [attachments, setAttachments] = useState([]);
    const [showAttachmentsPanel, setShowAttachmentsPanel] = useState(false);
    const [processingAttachment, setProcessingAttachment] = useState(false);
    const [messageAttachments, setMessageAttachments] = useState({}); // Map message IDs to their attachments

    // Section editing states
    const [pendingEditAction, setPendingEditAction] = useState(null);
    const [editActionSource, setEditActionSource] = useState(null);
    const [editConfirmationVisible, setEditConfirmationVisible] = useState(false);
    const [selectedSectionForEdit, setSelectedSectionForEdit] = useState(null);
    const [lastEditedSection, setLastEditedSection] = useState(null);
    const [sectionEditHistory, setSectionEditHistory] = useState({});
    const [sectionSearchQuery, setSectionSearchQuery] = useState('');
    const [sectionSearchResults, setSectionSearchResults] = useState([]);
    const [showSectionSelector, setShowSectionSelector] = useState(false);

    // Scroll control states
    const [autoScroll, setAutoScroll] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    const [preventScroll, setPreventScroll] = useState(false);

    // UI enhancement states
    const [headerHovered, setHeaderHovered] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);
    const [lastAddedMessageId, setLastAddedMessageId] = useState(null);
    const [inputFocused, setInputFocused] = useState(false);

    // References
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const inputRef = useRef(null);
    const aiManagerRef = useRef(null);

    // Initialize conversationId if not present
    useEffect(() => {
        if (!conversationId) {
            setConversationId(`writing_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`);
        }
    }, [conversationId]);

    // Update the target section when selectedSection changes
    useEffect(() => {
        if (selectedSection !== targetSection) {
            setTargetSection(selectedSection);
            setSelectedSectionForEdit(selectedSection);

            // Add visual feedback when section changes
            if (selectedSection && targetSection) {
                const sectionInfoElement = document.querySelector(`.${styles['taytrom-ai-section-info']}`);
                if (sectionInfoElement) {
                    sectionInfoElement.classList.add(styles['taytrom-ai-section-edited']);
                    setTimeout(() => {
                        sectionInfoElement.classList.remove(styles['taytrom-ai-section-edited']);
                    }, 1500);
                }
            }
        }
    }, [selectedSection, targetSection]);

    // Enhanced scroll handling - detect scroll position and control auto-scroll
    const handleScroll = useCallback(() => {
        if (!messagesContainerRef.current || preventScroll) return;

        const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
        const scrolledToBottom = Math.abs((scrollTop + clientHeight) - scrollHeight) < 50;

        // Update auto-scroll based on user's scroll position
        setAutoScroll(scrolledToBottom);

        // Show/hide scroll buttons based on content overflow
        setShowScrollButtons(scrollHeight > clientHeight);

        // Save current scroll position
        setScrollPosition(scrollTop);
    }, [preventScroll]);

    // Auto-scroll to bottom when new messages arrive if auto-scroll is enabled
    useEffect(() => {
        if (messagesEndRef.current && autoScroll && !preventScroll) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, currentThoughts, autoScroll, preventScroll]);

    // Attach scroll event listener
    useEffect(() => {
        const container = messagesContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            // Initial check for scroll buttons
            handleScroll();

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll]);



    // Manual scroll functions
    const scrollToBottom = useCallback(() => {
        if (messagesEndRef.current) {
            setPreventScroll(true); // Prevent scroll handler from firing during programmatic scroll
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            setAutoScroll(true);
            setTimeout(() => setPreventScroll(false), 300); // Re-enable scroll handler
        }
    }, []);

    const scrollToTop = useCallback(() => {
        if (messagesContainerRef.current) {
            setPreventScroll(true); // Prevent scroll handler from firing during programmatic scroll
            messagesContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            setAutoScroll(false);
            setTimeout(() => setPreventScroll(false), 300); // Re-enable scroll handler
        }
    }, []);

    // Add welcome message if no messages exist and component is fresh
    useEffect(() => {
        if (messages.length === 0) {
            const welcomeMessageId = `welcome_${Date.now()}`;
            setMessages([{
                id: welcomeMessageId,
                role: 'assistant',
                content: "I'm your writing assistant. You can ask me to enhance, rewrite, or expand steps, or even edit specific steps using natural language commands like 'rewrite the introduction to be more engaging' or 'expand the benefits section with more examples'.",
                timestamp: Date.now(),
                isWelcomeMessage: true
            }]);
            setLastAddedMessageId(welcomeMessageId);
        }
    }, [messages.length]);

    // Effect to highlight new messages
    useEffect(() => {
        if (lastAddedMessageId) {
            setTimeout(() => {
                setLastAddedMessageId(null);
            }, 1000);
        }
    }, [lastAddedMessageId]);

    // Subscribe to AI responses
    useEffect(() => {
        if (!orchestrator || !conversationId) return;

        const handleAIResponse = (notification) => {
            try {
                if (notification.type === 'AI_RESPONSE' || notification.data?.type === 'AI_RESPONSE') {
                    setIsLoading(false);
                    setIsPending(false);

                    // Get message content from notification
                    const messageContent = notification.message || notification.data?.message;
                    if (!messageContent) {
                        console.error("No message content in notification:", notification);
                        return;
                    }

                    // Check if this is a direct document update response
                    const isDirectDocumentUpdate = notification.directDocumentUpdate ||
                        notification.data?.directDocumentUpdate ||
                        notification.options?.directDocumentUpdate;

                    if (!isDirectDocumentUpdate) {
                        const newMessageId = notification.requestId ||
                            notification.data?.requestId ||
                            `resp_${Date.now()}`;

                        const newMessage = {
                            id: newMessageId,
                            role: 'assistant',
                            content: messageContent,
                            timestamp: notification.timestamp || notification.data?.timestamp || Date.now(),
                            hasExtendedThinking: extendedThinkingEnabled,
                            thinkingContent: notification.thinking || notification.data?.thinking || currentThoughts,
                            sectionId: targetSection,
                            attachmentIds: [] // Will be filled if this is a response to an attachment
                        };

                        setMessages(prevMessages => [...prevMessages, newMessage]);
                        setLastAddedMessageId(newMessageId);
                    }

                    setCurrentThoughts('');

                    // Process the response for pending edit actions - but don't create suggestions here
                    if (pendingEditAction) {
                        processEditAction(newMessage, pendingEditAction);
                    }

                    // Clear processing state for attachments
                    if (processingAttachment) {
                        setProcessingAttachment(false);
                    }

                    // Enable auto-scroll when a new message is received
                    setTimeout(() => {
                        setAutoScroll(true);
                        scrollToBottom();
                    }, 100);
                } else if (notification.type === 'AI_THINKING' || notification.data?.type === 'AI_THINKING') {
                    // Handle "thinking" state
                    setCurrentThoughts(notification.thinking || notification.data?.thinking || '');
                    setIsPending(true);
                }
            } catch (err) {
                console.error("Error processing AI response:", err);
                setIsLoading(false);
                setIsPending(false);
                setProcessingAttachment(false);
                setError(`Failed to process response: ${err.message}`);
            }
        };

        const subscription = orchestrator.subscribe('ai', handleAIResponse);

        return () => {
            orchestrator.unsubscribe('ai', subscription.subscriptionId);
        };
    }, [orchestrator, conversationId, extendedThinkingEnabled, currentThoughts, targetSection, pendingEditAction, processingAttachment, scrollToBottom]);

    // Attachment handlers
    const handleAttach = (attachment) => {
        // Add to attachments list
        setAttachments(prev => [...prev, attachment]);

        // Show the attachments panel if it's hidden
        if (!showAttachmentsPanel) {
            setShowAttachmentsPanel(true);
        }
    };

    const handleRemoveAttachment = (attachment) => {
        setAttachments(prev => prev.filter(a => a.id !== attachment.id));
    };

    const handleProcessAttachment = async (attachment, sectionId) => {
        if (!orchestrator) {
            setError("AI system is not available to process attachments");
            return;
        }

        setProcessingAttachment(true);

        try {
            const fileType = attachment.type.split('/')[0]; // Get main type (image, application, etc.)

            // Create user message with attachment info
            const userMessageId = `user_attach_${Date.now()}`;
            const userMessage = {
                id: userMessageId,
                role: 'user',
                content: `I've attached a ${fileType} file: "${attachment.name}". Please analyze this ${fileType} for me.`,
                timestamp: Date.now(),
                attachmentIds: [attachment.id],
                isAttachmentMessage: true
            };

            // Add to messages
            setMessages(prev => [...prev, userMessage]);
            setLastAddedMessageId(userMessageId);

            // Associate this attachment with the message
            setMessageAttachments(prev => ({
                ...prev,
                [userMessageId]: [attachment]
            }));

            // Create options for the AI request
            const options = {
                conversationId,
                moduleContext: {
                    title: 'Taytrom Writing Platform',
                    focus: 'attachment analysis',
                    sectionId: sectionId || targetSection,
                    attachmentType: fileType,
                    attachmentName: attachment.name
                },
                thinkingEnabled: extendedThinkingEnabled,
                thinkingBudget: extendedThinkingEnabled ? thinkingBudget : undefined,
                attachmentInfo: {
                    name: attachment.name,
                    type: attachment.type,
                    size: attachment.size
                }
            };

            // Create prompt based on file type
            let prompt = "";
            if (fileType === 'image') {
                prompt = `I've uploaded an image file named "${attachment.name}". Please describe what you can infer about this image based on the filename and help me understand how I might incorporate its content into my document.`;
            } else if (attachment.type.includes('pdf')) {
                prompt = `I've uploaded a PDF file named "${attachment.name}". Please help me analyze what this document might contain based on the filename and how I could incorporate its content into my current document.`;
            } else if (attachment.type.includes('spreadsheet') || attachment.type.includes('csv') || attachment.type.includes('excel')) {
                prompt = `I've uploaded a spreadsheet file named "${attachment.name}". Please suggest how I might analyze this data and incorporate insights from it into my document.`;
            } else if (attachment.type.includes('word') || attachment.type.includes('document')) {
                prompt = `I've uploaded a document file named "${attachment.name}". Please suggest how I might extract key points from this document and integrate them into my current work.`;
            } else {
                prompt = `I've uploaded a file named "${attachment.name}" of type "${attachment.type}". Please help me analyze and incorporate its content into my document.`;
            }

            // Send to AI
            const result = await orchestrator.handleUserMessage(prompt, options);

            if (!result.success) {
                throw new Error(result.error || "Failed to process attachment");
            }

            return result;
        } catch (error) {
            console.error("Error processing attachment:", error);
            setError(`Failed to process attachment: ${error.message}`);

            // Add error message to chat
            const errorMessageId = `system_${Date.now()}`;
            setMessages(prev => [...prev, {
                id: errorMessageId,
                role: 'system',
                content: `Error processing "${attachment.name}": ${error.message}`,
                timestamp: Date.now(),
                isErrorMessage: true
            }]);
            setLastAddedMessageId(errorMessageId);

            throw error;
        }
    };

    const toggleAttachmentsPanel = () => {
        setShowAttachmentsPanel(prev => !prev);
    };

    // Enhanced handleSubmit to include attachments
    const handleSubmit = async (e) => {
        if (e) e.preventDefault();

        if (!input.trim() && attachments.length === 0) return;

        // Check for section editing commands
        const editAction = detectEditAction(input);

        // Add user message to chat
        const messageId = `user_${Date.now()}`;
        const userMessage = {
            id: messageId,
            role: 'user',
            content: input,
            timestamp: Date.now(),
            attachmentIds: attachments.length > 0 ? attachments.map(a => a.id) : []
        };

        // If there are attachments, associate them with this message
        if (attachments.length > 0) {
            setMessageAttachments(prev => ({
                ...prev,
                [messageId]: [...attachments]
            }));
        }

        setMessages(prevMessages => [...prevMessages, userMessage]);
        setLastAddedMessageId(messageId);
        setIsLoading(true);
        setInput('');
        setAutoScroll(true); // Enable auto-scroll when sending a new message
        scrollToBottom();

        try {
            if (!orchestrator) {
                throw new Error('AI system is not initialized');
            }

            // If we detected an edit action, set it as pending
            if (editAction) {
                setPendingEditAction(editAction);

                // Try to find the target section
                let targetSectionForEdit = null;

                if (editAction.sectionRef) {
                    // Search for the section
                    const matchingSection = findMatchingSection(editAction.sectionRef);

                    if (matchingSection) {
                        targetSectionForEdit = matchingSection.id;
                        setSelectedSectionForEdit(targetSectionForEdit);

                        // If no section is currently selected, select this one
                        if (!targetSection) {
                            setTargetSection(targetSectionForEdit);
                        }
                    } else {
                        // If we can't find the section, show the section selector
                        setSectionSearchQuery(editAction.sectionRef);
                        setSectionSearchResults(searchSections(editAction.sectionRef));
                        setShowSectionSelector(true);

                        // Add a system message about finding a section
                        const systemMessageId = `system_search_${Date.now()}`;
                        setMessages(prev => [...prev, {
                            id: systemMessageId,
                            role: 'system',
                            content: `Looking for section matching "${editAction.sectionRef}"...`,
                            timestamp: Date.now(),
                            isSystemMessage: true
                        }]);
                        setLastAddedMessageId(systemMessageId);
                    }
                } else if (targetSection) {
                    // Use currently selected section if no specific reference
                    targetSectionForEdit = targetSection;
                    setSelectedSectionForEdit(targetSectionForEdit);
                }
            }

            // Get current section content for context
            let section = null;

            if (selectedSectionForEdit) {
                section = documentContent.sections?.find(s => s.id === selectedSectionForEdit);
            } else if (targetSection) {
                section = documentContent.sections?.find(s => s.id === targetSection);
            }

            // Create prompt with section context and attachments if available
            let promptContext = input;

            if (section) {
                promptContext = `I am working on a section titled "${section.title}" with the following content:\n\n${section.content}\n\n${input}`;
            }

            if (attachments.length > 0) {
                // Add attachment info to prompt
                promptContext += `\n\nI've also attached ${attachments.length} file${attachments.length > 1 ? 's' : ''}:`;
                attachments.forEach(attachment => {
                    promptContext += `\n- ${attachment.name} (${attachment.type})`;
                });
            }

            // Prepare options for the AI request
            const options = {
                conversationId,
                moduleContext: {
                    title: 'Taytrom Writing Platform',
                    focus: editAction ? editAction.type : 'conversation',
                    sectionId: selectedSectionForEdit || targetSection,
                    sectionTitle: section?.title,
                    hasAttachments: attachments.length > 0
                },
                thinkingEnabled: extendedThinkingEnabled,
                thinkingBudget: extendedThinkingEnabled ? thinkingBudget : undefined,
                attachmentInfo: attachments.length > 0 ?
                    attachments.map(a => ({
                        id: a.id,
                        name: a.name,
                        type: a.type,
                        size: a.size
                    })) :
                    undefined
            };

            // Send message to the orchestrator
            const result = await orchestrator.handleUserMessage(promptContext, options);

            if (!result || !result.success) {
                setError(result?.error || "Unknown error occurred");
                setIsLoading(false);
                setPendingEditAction(null);
            }

            // Clear attachments after sending
            setAttachments([]);
            setShowAttachmentsPanel(false);
        } catch (err) {
            console.error("Error in handleSubmit:", err);
            setIsLoading(false);
            setError(`Failed to send message: ${err.message}`);
            setPendingEditAction(null);
        }
    };

    // Helper function to detect action types
    const detectEditAction = (userInput) => {
        // Using the existing implementation
        // This should check if the input contains commands like "rewrite", "expand", etc.
        const input = userInput.toLowerCase();
        let actionType = null;
        let sectionIdentifier = null;

        // Common action keywords
        const rewriteKeywords = ['rewrite', 'revise', 'redo', 'rephrase', 'remake'];
        const expandKeywords = ['expand', 'elaborate', 'add more', 'add details', 'add to', 'extend'];
        const enhanceKeywords = ['enhance', 'improve', 'polish', 'refine', 'make better'];

        // Create a function to extract section references
        const extractSectionReference = (text) => {
            // Simple regex to extract "the [something] section" pattern
            const sectionMatch = text.match(/(?:the|this|that|my)\s+([a-z\s-]+(?:introduction|section|paragraph|content|part|bullet|list|points|heading|subheading|conclusion))/i);
            return sectionMatch ? sectionMatch[1].trim() : null;
        };

        // Determine action type
        if (rewriteKeywords.some(keyword => input.includes(keyword))) {
            actionType = 'rewrite';
        } else if (expandKeywords.some(keyword => input.includes(keyword))) {
            actionType = 'expand';
        } else if (enhanceKeywords.some(keyword => input.includes(keyword))) {
            actionType = 'enhance';
        }

        // Extract section reference if an action was detected
        if (actionType) {
            sectionIdentifier = extractSectionReference(input);

            // If we have a target section but couldn't find a specific reference,
            // assume the user is talking about the currently selected section
            if (!sectionIdentifier && targetSection) {
                const currentSection = documentContent.sections?.find(s => s.id === targetSection);
                sectionIdentifier = currentSection?.title?.toLowerCase() || `section ${targetSection}`;
            }

            // Return the detected action
            if (sectionIdentifier) {
                return {
                    type: actionType,
                    sectionRef: sectionIdentifier,
                    originalInput: userInput
                };
            }
        }

        return null;
    };

    // Helper functions for section matching
    const findMatchingSection = (sectionRef) => {
        // Assumed to be implemented, searches for a section matching the reference
        if (!documentContent.sections || documentContent.sections.length === 0) {
            return null;
        }

        // If reference is numeric, try to find by ID
        const numericId = parseInt(sectionRef.replace(/[^0-9]/g, ''));
        if (!isNaN(numericId)) {
            const sectionById = documentContent.sections.find(s => s.id === numericId);
            if (sectionById) return sectionById;
        }

        // Convert reference to lowercase for case-insensitive matching
        const ref = sectionRef.toLowerCase();

        // Get section types from the reference
        const sectionTypes = [
            'introduction', 'conclusion', 'heading', 'subheading',
            'paragraph', 'bullet', 'list', 'section', 'part'
        ];

        // Extract the core reference without section type words
        let coreRef = ref;
        for (const type of sectionTypes) {
            coreRef = coreRef.replace(type, '').trim();
        }

        // First, try exact title match
        const exactMatch = documentContent.sections.find(
            s => s.title.toLowerCase() === coreRef || s.title.toLowerCase() === ref
        );
        if (exactMatch) return exactMatch;

        // Next, try contains match for title
        const containsMatch = documentContent.sections.find(
            s => s.title.toLowerCase().includes(coreRef) || coreRef.includes(s.title.toLowerCase())
        );
        if (containsMatch) return containsMatch;

        // If still not found, look for steps with matching section type
        const typeMatch = documentContent.sections.find(s => {
            const typeLower = s.type?.toLowerCase() || '';
            for (const type of sectionTypes) {
                if (ref.includes(type) && typeLower.includes(type)) {
                    return true;
                }
            }
            return false;
        });
        if (typeMatch) return typeMatch;

        // If we have a selected section and all else fails, use that
        if (targetSection) {
            return documentContent.sections.find(s => s.id === targetSection);
        }

        // Default to the first section if no matches
        return null;
    };

    const searchSections = (query) => {
        // Assumed to be implemented, searches steps based on a query
        if (!query || !documentContent.sections) return [];

        const queryLower = query.toLowerCase();
        return documentContent.sections
            .filter(section =>
                section.title.toLowerCase().includes(queryLower) ||
                (section.type && section.type.toLowerCase().includes(queryLower)) ||
                section.content.toLowerCase().includes(queryLower)
            )
            .slice(0, 5); // Limit to 5 results
    };

    // Function to process edit actions
    const processEditAction = (message, action) => {
        // Clear the pending edit action
        setPendingEditAction(null);

        // Extract the section we want to edit
        let sectionToEdit = null;

        // If we have a specific section already set for edit, use that
        if (selectedSectionForEdit) {
            sectionToEdit = documentContent.sections?.find(s => s.id === selectedSectionForEdit);
        }
        // Otherwise, try to find the section based on the action reference
        else if (action.sectionRef) {
            sectionToEdit = findMatchingSection(action.sectionRef);
        }

        // If we couldn't find a section, show an error
        if (!sectionToEdit) {
            setError(`Couldn't find section "${action.sectionRef}". Please select a section and try again.`);
            return;
        }

        // Prepare edit confirmation
        const editAction = {
            type: action.type,
            sectionId: sectionToEdit.id,
            sectionTitle: sectionToEdit.title,
            originalContent: sectionToEdit.content,
            newContent: message.content,
            timestamp: Date.now(),
            messageId: message.id
        };

        // Store in history for potential undo
        setSectionEditHistory(prev => ({
            ...prev,
            [sectionToEdit.id]: [
                ...(prev[sectionToEdit.id] || []),
                {
                    timestamp: Date.now(),
                    originalContent: sectionToEdit.content,
                    actionType: action.type
                }
            ]
        }));

        // Apply the edit action directly to the section
        if (action.type === 'rewrite' || action.type === 'enhance') {
            onReplaceSectionContent(sectionToEdit.id, message.content);
        } else if (action.type === 'expand') {
            onExpandSection(sectionToEdit.id, message.content);
        }

        // Add a system message acknowledging the edit
        const systemMessageId = `system_edit_${Date.now()}`;
        setMessages(prev => [...prev, {
            id: systemMessageId,
            role: 'system',
            content: `Applied ${action.type} to "${sectionToEdit.title}" section.`,
            timestamp: Date.now(),
            isSystemMessage: true
        }]);
        setLastAddedMessageId(systemMessageId);

        // Track last edited section
        setLastEditedSection(sectionToEdit.id);
    };

    // Render attachment for a message
    const renderMessageAttachments = (messageId) => {
        const attachmentsForMessage = messageAttachments[messageId] || [];
        if (attachmentsForMessage.length === 0) return null;

        return (
            <div className={styles['taytrom-ai-attachments-display']}>
                {attachmentsForMessage.map(attachment => (
                    <div
                        key={attachment.id}
                        className={styles['taytrom-ai-attachment-chip']}
                    >
                        <Paperclip size={14} className={styles['taytrom-ai-attachment-icon']} />
                        <span className={styles['taytrom-ai-attachment-name']} title={attachment.name}>
                            {attachment.name}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    // Function to show only notification messages in chat, not content
    const showNotificationMessage = (message) => {
        // Add a simple notification message to the chat
        const notificationId = `notification_${Date.now()}`;
        setMessages(prev => [...prev, {
            id: notificationId,
            role: 'system',
            content: message,
            timestamp: Date.now(),
            isSystemMessage: true
        }]);
        setLastAddedMessageId(notificationId);
    };

    // If minimized, show the compact view
    if (isMinimized) {
        return (
            <div
                className={`${styles['taytrom-ai-compact']} ${className}`}
                onClick={onToggleMinimize}
                role="button"
                tabIndex={0}
                aria-label="Expand writing assistant"
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        onToggleMinimize();
                    }
                }}
            >
                <div className={styles['taytrom-ai-compact-title']}>
                    <Sparkles size={18} className={styles['taytrom-ai-compact-icon']} />
                    <span>Writing Assistant</span>
                </div>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggleMinimize();
                    }}
                    className={styles['taytrom-ai-compact-button']}
                    aria-label="Expand writing assistant"
                >
                    <MessageSquare size={18} />
                </button>
            </div>
        );
    }

    return (
        <div className={`${styles['taytrom-ai-component']} ${className}`} role="region" aria-label="AI Writing Assistant">
            {/* Header */}
            <div
                className={styles['taytrom-ai-header']}
                onMouseEnter={() => setHeaderHovered(true)}
                onMouseLeave={() => setHeaderHovered(false)}
            >
                <div className={styles['taytrom-ai-title-container']}>
                    <Sparkles size={20} className={styles['taytrom-ai-title-icon']} />
                    <h3 className={styles['taytrom-ai-title']}>Writing Assistant</h3>
                </div>

                <div className={styles['taytrom-ai-control-buttons']}>
                    {/* Minimize button */}
                    <button
                        onClick={onToggleMinimize}
                        aria-label="Minimize"
                        title="Minimize"
                        className={`${styles['taytrom-ai-button']} ${styles['taytrom-ai-button-close']}`}
                    >
                        <X size={18} />
                    </button>

                    {/* Extended thinking toggle */}
                    <button
                        onClick={() => setExtendedThinkingEnabled(!extendedThinkingEnabled)}
                        aria-label={`${extendedThinkingEnabled ? 'Disable' : 'Enable'} extended thinking mode`}
                        title={`${extendedThinkingEnabled ? 'Disable' : 'Enable'} extended thinking mode`}
                        className={`${styles['taytrom-ai-button']} ${styles['taytrom-ai-button-brain']} ${extendedThinkingEnabled ? styles['taytrom-ai-button-brain--active'] : ''}`}
                    >
                        <Brain size={18} />
                    </button>
                </div>
            </div>

            {/* Selection info */}
            {targetSection && (
                <div className={styles['taytrom-ai-section-info']}>
                    <strong>Selected: </strong>
                    {documentContent.sections?.find(s => s.id === targetSection)?.title || `Section ${targetSection}`}
                    {lastEditedSection === targetSection && (
                        <span className={styles['taytrom-ai-section-edited-indicator']}> (recently edited)</span>
                    )}
                </div>
            )}

            <div className={styles['taytrom-ai-generation-tools']}>
                {/* Pass the complete document content and proper functions */}
                <GenerateFromTitle
                    documentTitle={documentContent.title}
                    orchestrator={orchestrator}
                    setDocumentContent={onReplaceSectionContent}
                    showSuccessMessage={showNotificationMessage}
                    isDark={isDark}
                />
            </div>

            {/* Message container */}
            <div
                ref={messagesContainerRef}
                className={styles['taytrom-ai-messages-container']}
                onScroll={handleScroll}
                role="log"
                aria-live="polite"
                aria-atomic="false"
            >
                <div className={styles['taytrom-ai-messages-content']}>
                    {messages.map((message) => {
                        const isUserMessage = message.role === 'user';
                        const isSystemMessage = message.role === 'system';
                        const isNewMessage = message.id === lastAddedMessageId;

                        return (
                            <div
                                key={message.id}
                                className={`${styles['taytrom-ai-message-wrapper']} ${
                                    isUserMessage ? styles['taytrom-ai-message-wrapper--user'] :
                                        isSystemMessage ? styles['taytrom-ai-message-wrapper--system'] :
                                            styles['taytrom-ai-message-wrapper--assistant']
                                }`}
                            >
                                <div
                                    className={`${styles['taytrom-ai-message']} ${
                                        isUserMessage ? styles['taytrom-ai-message--user'] :
                                            isSystemMessage ? styles['taytrom-ai-message--system'] :
                                                styles['taytrom-ai-message--assistant']
                                    }`}
                                    role={isSystemMessage ? "status" : (isUserMessage ? "comment" : "article")}
                                >
                                    {isUserMessage || isSystemMessage ? (
                                        <div>{message.content}</div>
                                    ) : (
                                        <ReactMarkdown
                                            className={styles['taytrom-ai-markdown-content']}
                                            components={{
                                                code({node, inline, className, children, ...props}) {
                                                    const match = /language-(\w+)/.exec(className || '');
                                                    return !inline && match ? (
                                                        <SyntaxHighlighter
                                                            style={codeStyle}
                                                            language={match[1]}
                                                            PreTag="div"
                                                            className={styles['taytrom-ai-code']}
                                                            {...props}
                                                        >
                                                            {String(children).replace(/\n$/, '')}
                                                        </SyntaxHighlighter>
                                                    ) : (
                                                        <code className={className || ''} {...props}>
                                                            {children}
                                                        </code>
                                                    );
                                                }
                                            }}
                                        >
                                            {message.content}
                                        </ReactMarkdown>
                                    )}

                                    {/* Display attachments if any */}
                                    {renderMessageAttachments(message.id)}

                                    {/* Display thinking content if available */}
                                    {!isUserMessage && !isSystemMessage && message.thinkingContent && (
                                        <div
                                            className={styles['taytrom-ai-thinking-container']}
                                            role="complementary"
                                            aria-label="AI thinking process"
                                        >
                                            <div className={styles['taytrom-ai-thinking-header']}>
                                                <Brain size={12} />
                                                <span>Thinking Process</span>
                                            </div>
                                            {message.thinkingContent}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}

                    {/* Loading indicator */}
                    {isLoading && !isPending && (
                        <div className={styles['taytrom-ai-loading-indicator']} aria-live="polite">
                            <div className={styles['taytrom-ai-loading-bubble']}>
                                <Loader size={16} className={styles['taytrom-ai-loading-icon']} aria-hidden="true" />
                                <span>Generating response...</span>
                            </div>
                        </div>
                    )}

                    {/* Thinking in progress */}
                    {isPending && (
                        <div
                            className={styles['taytrom-ai-thinking-progress']}
                            aria-live="polite"
                            role="status"
                        >
                            <div className={styles['taytrom-ai-thinking-bubble']}>
                                <div className={styles['taytrom-ai-thinking-title']}>
                                    <Brain size={16} className={styles['taytrom-ai-thinking-brain-icon']} aria-hidden="true" />
                                    <span>Thinking...</span>
                                </div>
                                <div className={styles['taytrom-ai-thinking-content']}>
                                    {currentThoughts || 'Processing your request...'}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Error message */}
                    {error && (
                        <div
                            className={styles['taytrom-ai-error']}
                            role="alert"
                            aria-live="assertive"
                        >
                            <AlertCircle size={16} aria-hidden="true" />
                            <span>{error}</span>
                            <button
                                onClick={() => setError(null)}
                                className={styles['taytrom-ai-error-dismiss']}
                                aria-label="Dismiss error"
                            >
                                Dismiss
                            </button>
                        </div>
                    )}
                </div>

                {/* Scroll end marker - critical for scroll-to-bottom */}
                <div ref={messagesEndRef} style={{ height: '1px', marginTop: 'auto' }}></div>
            </div>

            {/* Scroll control buttons */}
            {showScrollButtons && (
                <div className={styles['taytrom-ai-scroll-controls']} aria-label="Scroll controls">
                    <button
                        onClick={scrollToTop}
                        className={styles['taytrom-ai-scroll-button']}
                        title="Scroll to top"
                        aria-label="Scroll to top"
                        disabled={scrollPosition <= 100}
                    >
                        <ArrowUpCircle size={20} aria-hidden="true" />
                    </button>

                    <button
                        onClick={scrollToBottom}
                        className={styles['taytrom-ai-scroll-button']}
                        title="Scroll to bottom"
                        aria-label="Scroll to bottom"
                        disabled={autoScroll}
                    >
                        <ArrowDownCircle size={20} aria-hidden="true" />
                    </button>
                </div>
            )}

            {/* Attachments panel */}
            {showAttachmentsPanel && (
                <div className={styles['taytrom-ai-attachments-panel']} aria-label="Attachments">
                    <div className={styles['taytrom-ai-attachments-header']}>
                        <h4 className={styles['taytrom-ai-attachments-title']}>
                            Attachments
                        </h4>
                        <button
                            onClick={toggleAttachmentsPanel}
                            className={styles['taytrom-ai-attachments-toggle']}
                            aria-label="Close attachments panel"
                        >
                            <ChevronUp size={16} aria-hidden="true" />
                        </button>
                    </div>

                    {/* Render the Attachments component */}
                    <Attachments_Specialists_Writing
                        orchestrator={orchestrator}
                        onAttach={handleAttach}
                        onRemove={handleRemoveAttachment}
                        onProcessAttachment={handleProcessAttachment}
                        attachments={attachments}
                        setAttachments={setAttachments}
                        isProcessing={processingAttachment}
                        isDark={isDark}
                        sectionId={targetSection}
                        isCompact={false}
                    />
                </div>
            )}

            {/* Input area */}
            <form
                onSubmit={handleSubmit}
                className={styles['taytrom-ai-input-area']}
            >
                <div className={styles['taytrom-ai-input-container']}>
                    <input
                        ref={inputRef}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSubmit(e);
                            }
                        }}
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                        placeholder={targetSection ?
                            `Ask about "${documentContent.sections?.find(s => s.id === targetSection)?.title || `Section ${targetSection}`}"...` :
                            "Select a section first..."}
                        disabled={isLoading}
                        className={styles['taytrom-ai-input']}
                        aria-label="Message input"
                    />

                    {/* Attachment button */}
                    <button
                        type="button"
                        onClick={toggleAttachmentsPanel}
                        disabled={isLoading}
                        className={`${styles['taytrom-ai-attach-button']} ${attachments.length > 0 ? styles['taytrom-ai-attach-button--with-attachments'] : ''}`}
                        aria-label={showAttachmentsPanel ? "Hide attachments" : "Add attachments"}
                        title={showAttachmentsPanel ? "Hide attachments" : "Add attachments"}
                    >
                        <Paperclip size={16} aria-hidden="true" />
                        {attachments.length > 0 && (
                            <span className={styles['taytrom-ai-attachment-count']}>
                                {attachments.length}
                            </span>
                        )}
                    </button>

                    <button
                        type="submit"
                        disabled={isLoading || (!input.trim() && attachments.length === 0)}
                        className={styles['taytrom-ai-send-button']}
                        aria-label="Send message"
                        title="Send message"
                    >
                        <SendHorizontal size={14} aria-hidden="true" />
                    </button>
                </div>

                <button
                    type="button"
                    onClick={() => {
                        const welcomeMessageId = `welcome_${Date.now()}`;
                        setMessages([{
                            id: welcomeMessageId,
                            role: 'assistant',
                            content: "I'm your writing assistant. You can ask me to enhance, rewrite, or expand steps, or even edit specific steps using natural language commands like 'rewrite the introduction to be more engaging' or 'expand the benefits section with more examples'.",
                            timestamp: Date.now(),
                            isWelcomeMessage: true
                        }]);
                        setLastAddedMessageId(welcomeMessageId);
                        setError(null);
                        setIsPending(false);
                        setCurrentThoughts('');
                        setInput('');
                        setPendingEditAction(null);
                        setEditActionSource(null);
                        setEditConfirmationVisible(false);
                        setAttachments([]);
                        setShowAttachmentsPanel(false);
                        // Reset scroll state on clear
                        setAutoScroll(true);
                        scrollToBottom();
                    }}
                    title="Clear conversation"
                    aria-label="Clear conversation"
                    className={styles['taytrom-ai-clear-button']}
                >
                    <RotateCcw size={16} aria-hidden="true" />
                </button>
            </form>
        </div>
    );
};

export default AIComponent_Specialists_Writing;