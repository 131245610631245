import React, { useState, useEffect, useRef } from 'react';
import { X, Brain } from 'lucide-react';
import styles from './Wrapper_AIComponent_Specialists_Writing.module.css';

/**
 * A wrapper component that properly positions the AI Assistant
 * to prevent it from being cut off by viewport boundaries
 * Fully implementing Taytrom's Enhanced CSS System - The Definitive Styling Mandate
 */
const FixedAIAssistant = ({
                              children,
                              isOpen,
                              isMinimized,
                              onClose,
                              onToggleMinimize,
                              isDark = false
                          }) => {
    const [position, setPosition] = useState({ bottom: 24, right: 24 });
    const [isAnimating, setIsAnimating] = useState(false);
    const containerRef = useRef(null);
    const initialRenderRef = useRef(true);

    // Adjust position when the component expands
    useEffect(() => {
        if (!isOpen || isMinimized || !containerRef.current) return;

        const updatePosition = () => {
            const container = containerRef.current;
            if (!container) return;

            const rect = container.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;

            // Check if the expanded component would be cut off
            const wouldOverflowBottom = rect.bottom > windowHeight;
            const wouldOverflowRight = rect.right > windowWidth;

            let newBottom = position.bottom;
            let newRight = position.right;

            // If it would overflow the bottom, move it up
            if (wouldOverflowBottom) {
                const overflow = rect.bottom - windowHeight;
                newBottom = position.bottom + overflow + 20; // Add 20px padding
            }

            // If it would overflow the right, move it left
            if (wouldOverflowRight) {
                const overflow = rect.right - windowWidth;
                newRight = position.right + overflow + 20; // Add 20px padding
            }

            // Update position if needed
            if (newBottom !== position.bottom || newRight !== position.right) {
                setPosition({ bottom: newBottom, right: newRight });
            }
        };

        // Update position after a short delay to allow for rendering
        const timer = setTimeout(updatePosition, 100);

        // Update position on window resize
        window.addEventListener('resize', updatePosition);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', updatePosition);
        };
    }, [isOpen, isMinimized, position]);

    // Set the theme attribute based on isDark prop
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');
    }, [isDark]);

    // Handle entrance animation
    useEffect(() => {
        if (isOpen && !isMinimized && initialRenderRef.current === false) {
            setIsAnimating(true);
            const timer = setTimeout(() => {
                setIsAnimating(false);
            }, 800); // Match animation duration

            return () => clearTimeout(timer);
        }

        if (initialRenderRef.current) {
            initialRenderRef.current = false;
        }
    }, [isOpen, isMinimized]);

    // Handle toggle button ripple effect
    const handleToggleClick = (e) => {
        // Create ripple effect
        const button = e.currentTarget;
        const circle = document.createElement('span');
        const diameter = Math.max(button.clientWidth, button.clientHeight);

        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${e.clientX - button.getBoundingClientRect().left - diameter / 2}px`;
        circle.style.top = `${e.clientY - button.getBoundingClientRect().top - diameter / 2}px`;
        circle.classList.add(styles['taytrom-ripple']);

        const ripple = button.getElementsByClassName(styles['taytrom-ripple'])[0];
        if (ripple) {
            ripple.remove();
        }

        button.appendChild(circle);

        // Call the original toggle handler
        onToggleMinimize(e);
    };

    if (!isOpen) {
        // Render just the toggle button when closed
        return (
            <button
                onClick={handleToggleClick}
                className={styles['taytrom-ai-toggle']}
                title="Open AI Writing Assistant"
                aria-label="Open AI Writing Assistant"
                aria-expanded="false"
            >
                <Brain size={28} aria-hidden="true" />
                <span className={styles['taytrom-visually-hidden']}>Open AI Writing Assistant</span>
            </button>
        );
    }

    return (
        <div
            ref={containerRef}
            className={`${styles['taytrom-ai-container']} ${isMinimized ? styles['taytrom-ai-container--minimized'] : ''}`}
            style={{
                bottom: `${position.bottom}px`,
                right: `${position.right}px`
            }}
            role="dialog"
            aria-labelledby="ai-assistant-title"
            aria-modal="true"
        >
            {/* Accessible title (visually hidden) */}
            <span id="ai-assistant-title" className={styles['taytrom-visually-hidden']}>
        AI Writing Assistant
      </span>

            {/* Close button */}
            <div className={styles['taytrom-ai-close-container']}>
                <button
                    onClick={onClose}
                    className={styles['taytrom-ai-close-button']}
                    title="Close AI Assistant"
                    aria-label="Close AI Assistant"
                >
                    <X size={18} aria-hidden="true" />
                    <span className={styles['taytrom-visually-hidden']}>Close AI Assistant</span>
                </button>
            </div>

            {/* Minimize/Maximize button - only shown when open */}
            {!isMinimized && (
                <button
                    onClick={handleToggleClick}
                    className={styles['taytrom-ai-minimize-button']}
                    title={isMinimized ? "Expand AI Assistant" : "Minimize AI Assistant"}
                    aria-label={isMinimized ? "Expand AI Assistant" : "Minimize AI Assistant"}
                    aria-expanded={!isMinimized}
                >
                    <span className={styles['taytrom-ai-minimize-icon']} aria-hidden="true"></span>
                    <span className={styles['taytrom-visually-hidden']}>
            {isMinimized ? "Expand AI Assistant" : "Minimize AI Assistant"}
          </span>
                </button>
            )}

            {/* AI Assistant content with transition effects */}
            <div className={styles['taytrom-ai-content']}>
                {/* Content wrapper for animation purposes */}
                <div className={`${styles['taytrom-ai-content-inner']} ${isAnimating ? styles['taytrom-ai-content-animating'] : ''}`}>
                    {children}
                </div>
            </div>

            {/* Minimized state toggle */}
            {isMinimized && (
                <button
                    onClick={handleToggleClick}
                    className={styles['taytrom-ai-minimized-button']}
                    aria-label="Expand AI Assistant"
                    aria-expanded="false"
                >
                    <Brain size={20} aria-hidden="true" />
                    <span className={styles['taytrom-ai-minimized-label']}>AI Assistant</span>
                </button>
            )}
        </div>
    );
};

export default FixedAIAssistant;