import React, { useState, useEffect } from 'react';

/**
 * Taytrom Exchange Pillar Visualization
 *
 * A sophisticated marketplace visualization representing Taytrom's Exchange pillar
 * focusing on component marketplace, blockchain verification, and certified dreamers.
 *
 * @param {Object} props Component properties
 * @param {string|number} props.size Size of the SVG (default: "100%")
 * @param {string} props.primaryColor Primary color (default: "#0891b2" - cyan)
 * @param {string} props.secondaryColor Secondary color (default: "#8b5cf6" - violet)
 * @param {string} props.className Additional CSS classes
 * @param {Object} props.style Additional inline styles
 * @param {boolean} props.isAnimated Enable animations (default: true)
 * @param {boolean} props.interactive Enable hover interactivity (default: true)
 * @param {boolean} props.glowEffect Enable glow effects (default: true)
 * @param {string} props.animationMode Animation style: "flow", "pulse", "float" (default: "flow")
 * @param {number} props.animationSpeed Animation speed multiplier (default: 1)
 * @param {boolean} props.reducedMotion Respect reduced motion preferences (default: true)
 */
const TaytromExchangePillar = ({
                                   size = "100%",
                                   primaryColor = "#0891b2",
                                   secondaryColor = "#8b5cf6",
                                   className = "",
                                   style = {},
                                   isAnimated = true,
                                   interactive = true,
                                   glowEffect = true,
                                   animationMode = "flow",
                                   animationSpeed = 1,
                                   reducedMotion = true,
                                   ...props
                               }) => {
    // States
    const [isHovered, setIsHovered] = useState(false);
    const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

    // Calculate accent color (blend of primary and secondary)
    const accentColor = blendColors(primaryColor, secondaryColor, 0.5);
    // Calculate highlight color (lighter version of primary)
    const highlightColor = adjustColorBrightness(primaryColor, 40);
    // Calculate tertiary color (gold/certificate color)
    const tertiaryColor = "#f59e0b"; // Amber/gold for certification

    // Check for reduced motion preference
    useEffect(() => {
        if (typeof window !== 'undefined' && reducedMotion) {
            const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
            setPrefersReducedMotion(mediaQuery.matches);

            const handleChange = () => setPrefersReducedMotion(mediaQuery.matches);
            mediaQuery.addEventListener('change', handleChange);

            return () => mediaQuery.removeEventListener('change', handleChange);
        }
    }, [reducedMotion]);

    // Determine if animations should be active
    const animationsEnabled = isAnimated && !prefersReducedMotion;

    // Adjust animation durations based on speed
    const baseDuration = 3 / animationSpeed;
    const flowDuration = 5 / animationSpeed;

    // Generate unique ID for this instance (for filters and gradients)
    const uniqueId = React.useId().replace(/:/g, '');

    // Dynamic styles based on hover state and animation mode
    const dynamicStyles = {
        centralHub: {
            scale: isHovered && interactive ? 1.1 : 1,
            opacity: isHovered && interactive ? 0.95 : 0.85,
        },
        components: {
            scale: isHovered && interactive ? 1.1 : 1,
            opacity: isHovered && interactive ? 1 : 0.8,
        },
        blockchainElements: {
            stroke: isHovered && interactive ? highlightColor : primaryColor,
            strokeWidth: isHovered && interactive ? 1.5 : 1,
        },
        verification: {
            scale: isHovered && interactive ? 1.15 : 1,
            opacity: isHovered && interactive ? 1 : 0.9,
        },
        filter: glowEffect ? `url(#${uniqueId}-glow)` : "",
    };

    // Helper function to create component blocks
    const createComponentBlock = (x, y, size, index, rotateOpt = false) => {
        const rotate = rotateOpt ? (index % 2 === 0 ? 0 : 45) : 0;
        const color = index % 3 === 0
            ? primaryColor
            : (index % 3 === 1 ? secondaryColor : accentColor);
        const delay = (index * 0.2) % 1.5;
        const blockSize = size - (index % 3 * 2);

        const translateX = animationsEnabled && animationMode === "float"
            ? `${(index % 2 === 0 ? '5' : '-5')}`
            : '0';

        return (
            <g
                key={`component-${index}`}
                transform={`translate(${x}, ${y}) rotate(${rotate})`}
                style={{
                    transform: `scale(${dynamicStyles.components.scale})`,
                    transformOrigin: `${x}px ${y}px`,
                    transition: 'transform 0.3s ease'
                }}
            >
                <rect
                    x={-blockSize/2}
                    y={-blockSize/2}
                    width={blockSize}
                    height={blockSize}
                    rx={blockSize/8}
                    fill={color}
                    stroke={highlightColor}
                    strokeWidth="1"
                    opacity={dynamicStyles.components.opacity}
                    filter={dynamicStyles.filter}
                >
                    {animationsEnabled && animationMode === "float" && (
                        <>
                            <animate
                                attributeName="transform"
                                type="translate"
                                values={`0,0; ${translateX},0; 0,0`}
                                dur={`${baseDuration + index % 3}s`}
                                begin={`${delay}s`}
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="opacity"
                                values={`${dynamicStyles.components.opacity};${dynamicStyles.components.opacity + 0.2};${dynamicStyles.components.opacity}`}
                                dur={`${baseDuration + index % 3}s`}
                                begin={`${delay}s`}
                                repeatCount="indefinite"
                            />
                        </>
                    )}
                </rect>

                {/* Component details/patterns */}
                <g opacity="0.7">
                    <path
                        d={`M${-blockSize/3},${-blockSize/4} L${blockSize/3},${-blockSize/4}`}
                        stroke="white"
                        strokeWidth="1.5"
                        strokeOpacity="0.7"
                    />
                    <path
                        d={`M${-blockSize/3},0 L${blockSize/4},0`}
                        stroke="white"
                        strokeWidth="1.5"
                        strokeOpacity="0.7"
                    />
                    <path
                        d={`M${-blockSize/3},${blockSize/4} L${blockSize/3},${blockSize/4}`}
                        stroke="white"
                        strokeWidth="1.5"
                        strokeOpacity="0.7"
                    />
                </g>
            </g>
        );
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            {/* Definitions for gradients, filters and patterns */}
            <defs>
                <radialGradient id="backgroundGlow" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" stop-color="#081b29" />
                    <stop offset="80%" stop-color="#0f172a" />
                    <stop offset="100%" stop-color="#020617" />
                </radialGradient>

                <linearGradient id="marketplaceGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#0891b2" />
                    <stop offset="100%" stop-color="#8b5cf6" />
                </linearGradient>

                <linearGradient id="blockchainGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#8b5cf6" stopOpacity="0.8" />
                    <stop offset="100%" stop-color="#0891b2" stopOpacity="0.8" />
                </linearGradient>

                <linearGradient id="certifiedGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#f59e0b" />
                    <stop offset="100%" stop-color="#d97706" />
                </linearGradient>

                <linearGradient id="componentGradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#0891b2" />
                    <stop offset="100%" stop-color="#06b6d4" />
                </linearGradient>

                <linearGradient id="componentGradient2" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#8b5cf6" />
                    <stop offset="100%" stop-color="#a78bfa" />
                </linearGradient>

                <linearGradient id="componentGradient3" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#7c3aed" />
                    <stop offset="100%" stop-color="#0ea5e9" />
                </linearGradient>

                <pattern id="gridPattern" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
                    <path d="M 40 0 L 0 0 0 40" fill="none" stroke="#0891b2" strokeWidth="0.5" strokeOpacity="0.2" />
                </pattern>

                <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                    <feColorMatrix in="blur" type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.5 0 0 0 0 0.8 0 0 0 1 0" result="glow" />
                    <feBlend in="SourceGraphic" in2="glow" mode="screen" />
                </filter>

                <filter id="certificateGlow" x="-30%" y="-30%" width="160%" height="160%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
                    <feColorMatrix in="blur" type="matrix" values="0 0 0 0 1 0 0 0 0 0.7 0 0 0 0 0.2 0 0 0 1 0" result="glow" />
                    <feBlend in="SourceGraphic" in2="glow" mode="screen" />
                </filter>

                <pattern id="dots" width="10" height="10" patternUnits="userSpaceOnUse">
                    <circle cx="5" cy="5" r="1" fill="#0891b2" opacity="0.5" />
                </pattern>
            </defs>

            {/* Background Circle */}
            <circle cx="400" cy="400" r="350" fill="url(#backgroundGlow)" stroke="#0f172a" stroke-width="5" />
            <circle cx="400" cy="400" r="330" fill="url(#gridPattern)" opacity="0.2" />

            {/* Background Pulse Animation */}
            <circle cx="400" cy="400" r="300" fill="#0891b2" opacity="0.05">
                <animate attributeName="r" values="300;320;300" dur="10s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.05;0.1;0.05" dur="10s" repeatCount="indefinite" />
            </circle>

            {/* Blockchain Orbits */}
            <g transform="translate(400, 400)">
                <ellipse
                    cx="0"
                    cy="0"
                    rx="250"
                    ry="200"
                    fill="none"
                    stroke="url(#blockchainGradient)"
                    stroke-width="1"
                    stroke-dasharray="5,3"
                    opacity="0.6"
                    transform="rotate(45)">
                    <animate attributeName="stroke-dashoffset" values="0;100" dur="20s" repeatCount="indefinite" />
                </ellipse>

                <ellipse
                    cx="0"
                    cy="0"
                    rx="220"
                    ry="170"
                    fill="none"
                    stroke="url(#blockchainGradient)"
                    stroke-width="1.5"
                    stroke-dasharray="5,3"
                    opacity="0.7"
                    transform="rotate(-30)">
                    <animate attributeName="stroke-dashoffset" values="0;100" dur="25s" repeatCount="indefinite" />
                </ellipse>

                <ellipse
                    cx="0"
                    cy="0"
                    rx="190"
                    ry="140"
                    fill="none"
                    stroke="url(#blockchainGradient)"
                    stroke-width="1"
                    stroke-dasharray="5,3"
                    opacity="0.8"
                    transform="rotate(15)">
                    <animate attributeName="stroke-dashoffset" values="0;100" dur="15s" repeatCount="indefinite" />
                </ellipse>
            </g>

            {/* Marketplace Central Hub */}
            <g>
                <circle
                    cx="400"
                    cy="400"
                    r="100"
                    fill="url(#marketplaceGradient)"
                    opacity="0.85"
                    filter="url(#glow)">
                    <animate attributeName="r" values="100;105;100" dur="5s" repeatCount="indefinite" />
                </circle>

                {/* Hub patterns */}
                <circle cx="400" cy="400" r="80" fill="none" stroke="white" stroke-width="1" stroke-opacity="0.3" />
                <circle cx="400" cy="400" r="60" fill="none" stroke="white" stroke-width="1" stroke-opacity="0.3" />
                <circle cx="400" cy="400" r="40" fill="none" stroke="white" stroke-width="1.5" stroke-opacity="0.4" />

                {/* Connection points on the dashboard_hub */}
                {[0, 45, 90, 135, 180, 225, 270, 315].map((angle, i) => {
                    const radian = angle * Math.PI / 180;
                    const x = 400 + Math.cos(radian) * 100;
                    const y = 400 + Math.sin(radian) * 100;

                    return (
                        <circle
                            key={`conn-${i}`}
                            cx={x}
                            cy={y}
                            r="5"
                            fill="white"
                            opacity="0.7"
                            filter="url(#glow)"
                        >
                            <animate
                                attributeName="r"
                                values="5;7;5"
                                dur={`${3 + i * 0.2}s`}
                                begin={`${i * 0.1}s`}
                                repeatCount="indefinite"
                            />
                        </circle>
                    );
                })}

                {/* Hub Icon - Marketplace Symbol */}
                <g opacity="0.9">
                    <rect x="380" y="380" width="40" height="40" rx="5" fill="white" opacity="0.2" />
                    <path d="M380,390 L420,390 M380,410 L420,410" stroke="white" stroke-width="2" stroke-opacity="0.8" />
                    <path d="M390,380 L390,420 M410,380 L410,420" stroke="white" stroke-width="2" stroke-opacity="0.8" />
                </g>
            </g>

            {/* Blockchain Nodes and Connections */}
            <g>
                {[0, 45, 90, 135, 180, 225, 270, 315].map((angle, i) => {
                    const radian = angle * Math.PI / 180;
                    const distance = 180;
                    const x = 400 + Math.cos(radian) * distance;
                    const y = 400 + Math.sin(radian) * distance;
                    const blockSize = 24;

                    return (
                        <g key={`block-${i}`}>
                            {/* Block */}
                            <rect
                                x={x - blockSize/2}
                                y={y - blockSize/2}
                                width={blockSize}
                                height={blockSize}
                                fill={i % 2 === 0 ? "#0891b2" : "#8b5cf6"}
                                stroke="#22d3ee"
                                stroke-width="1"
                                rx="3"
                                filter="url(#glow)"
                                opacity="0.9"
                            >
                                <animate
                                    attributeName="opacity"
                                    values="0.9;1;0.9"
                                    dur={`${3 + i * 0.3}s`}
                                    begin={`${i * 0.2}s`}
                                    repeatCount="indefinite"
                                />
                            </rect>

                            {/* Block Content */}
                            <path
                                d={`M${x-blockSize/4},${y-blockSize/6} L${x+blockSize/4},${y-blockSize/6}`}
                                stroke="white"
                                stroke-width="1"
                                stroke-opacity="0.7"
                            />
                            <path
                                d={`M${x-blockSize/4},${y} L${x+blockSize/6},${y}`}
                                stroke="white"
                                stroke-width="1"
                                stroke-opacity="0.7"
                            />
                            <path
                                d={`M${x-blockSize/4},${y+blockSize/6} L${x+blockSize/4},${y+blockSize/6}`}
                                stroke="white"
                                stroke-width="1"
                                stroke-opacity="0.7"
                            />

                            {/* Connection to next block */}
                            <line
                                x1={x}
                                y1={y}
                                x2={400 + Math.cos(((angle + 45) % 360) * Math.PI / 180) * distance}
                                y2={400 + Math.sin(((angle + 45) % 360) * Math.PI / 180) * distance}
                                stroke="#0ea5e9"
                                stroke-width="1"
                                opacity="0.6"
                                stroke-dasharray="5,3"
                            >
                                <animate
                                    attributeName="stroke-dashoffset"
                                    values="0;20"
                                    dur="10s"
                                    repeatCount="indefinite"
                                />
                            </line>

                            {/* Connection to dashboard_hub */}
                            <line
                                x1={x}
                                y1={y}
                                x2={400 + Math.cos(radian) * 100}
                                y2={400 + Math.sin(radian) * 100}
                                stroke="#0ea5e9"
                                stroke-width="0.5"
                                opacity="0.4"
                                stroke-dasharray="3,3"
                            >
                                <animate
                                    attributeName="stroke-dashoffset"
                                    values="0;12"
                                    dur="7s"
                                    repeatCount="indefinite"
                                />
                            </line>
                        </g>
                    );
                })}
            </g>

            {/* Component Artifacts - Positioned around the circle */}
            <g>
                {[
                    { x: 250, y: 180, size: 40, gradient: "componentGradient1", rotate: 15 },
                    { x: 550, y: 180, size: 36, gradient: "componentGradient2", rotate: -15 },
                    { x: 180, y: 400, size: 38, gradient: "componentGradient3", rotate: 0 },
                    { x: 620, y: 400, size: 42, gradient: "componentGradient1", rotate: 0 },
                    { x: 250, y: 620, size: 34, gradient: "componentGradient2", rotate: -15 },
                    { x: 550, y: 620, size: 36, gradient: "componentGradient3", rotate: 15 }
                ].map((comp, i) => (
                    <g
                        key={`component-${i}`}
                        transform={`translate(${comp.x}, ${comp.y}) rotate(${comp.rotate})`}
                    >
                        <rect
                            x={-comp.size/2}
                            y={-comp.size/2}
                            width={comp.size}
                            height={comp.size}
                            rx={comp.size/8}
                            fill={`url(#${comp.gradient})`}
                            stroke="#22d3ee"
                            stroke-width="1"
                            filter="url(#glow)"
                            opacity="0.85"
                        >
                            <animate
                                attributeName="opacity"
                                values="0.85;1;0.85"
                                dur={`${4 + i % 3}s`}
                                begin={`${i * 0.5}s`}
                                repeatCount="indefinite"
                            />
                        </rect>

                        {/* Component details/patterns */}
                        <g opacity="0.7">
                            <path
                                d={`M${-comp.size/3},${-comp.size/4} L${comp.size/3},${-comp.size/4}`}
                                stroke="white"
                                stroke-width="1.5"
                                stroke-opacity="0.7"
                            />
                            <path
                                d={`M${-comp.size/3},0 L${comp.size/4},0`}
                                stroke="white"
                                stroke-width="1.5"
                                stroke-opacity="0.7"
                            />
                            <path
                                d={`M${-comp.size/3},${comp.size/4} L${comp.size/3},${comp.size/4}`}
                                stroke="white"
                                stroke-width="1.5"
                                stroke-opacity="0.7"
                            />
                        </g>
                    </g>
                ))}
            </g>

            {/* Certified Dreamer Verification Elements */}
            <g>
                {[
                    { x: 300, y: 250, size: 28, rotate: 15 },
                    { x: 500, y: 250, size: 30, rotate: -15 },
                    { x: 250, y: 500, size: 24, rotate: -15 },
                    { x: 550, y: 500, size: 26, rotate: 15 },
                    { x: 400, y: 580, size: 32, rotate: 0 }
                ].map((cert, i) => (
                    <g
                        key={`certificate-${i}`}
                        transform={`translate(${cert.x}, ${cert.y}) rotate(${cert.rotate})`}
                    >
                        {/* Certificate/badge shape */}
                        <circle
                            cx="0"
                            cy="0"
                            r={cert.size}
                            fill="url(#certifiedGradient)"
                            stroke="#fbbf24"
                            stroke-width="1.5"
                            opacity="0.9"
                            filter="url(#certificateGlow)"
                        >
                            <animate
                                attributeName="opacity"
                                values="0.9;1;0.9"
                                dur={`${5 + i}s`}
                                begin={`${i * 0.5}s`}
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="r"
                                values={`${cert.size};${cert.size+2};${cert.size}`}
                                dur={`${5 + i}s`}
                                begin={`${i * 0.5}s`}
                                repeatCount="indefinite"
                            />
                        </circle>

                        {/* Star shape inside certificate */}
                        <path
                            d="M0,-18 L4,-6 L17,-6 L7,2 L11,15 L0,8 L-11,15 L-7,2 L-17,-6 L-4,-6 Z"
                            fill="white"
                            opacity="0.9"
                            transform={`scale(${cert.size/20})`}
                        />
                    </g>
                ))}
            </g>

            {/* Flow Particles - Data moving around the marketplace */}
            <g>
                {[...Array(12)].map((_, i) => {
                    const startAngle = (i * 30) * Math.PI / 180;
                    const endAngle = ((i * 30) + 180) % 360 * Math.PI / 180;

                    const startX = 400 + Math.cos(startAngle) * 100; // Start at dashboard_hub edge
                    const startY = 400 + Math.sin(startAngle) * 100;

                    const endX = 400 + Math.cos(endAngle) * 100; // End at opposite dashboard_hub edge
                    const endY = 400 + Math.sin(endAngle) * 100;

                    const controlX = 400 + Math.cos(startAngle) * 250; // Control point outside
                    const controlY = 400 + Math.sin(startAngle) * 250;

                    // Create path string for a curve through outer space
                    const pathDef = `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`;

                    return (
                        <g key={`particle-${i}`}>
                            <circle
                                r="4"
                                fill={i % 2 === 0 ? "#0891b2" : "#8b5cf6"}
                                opacity="0.8"
                                filter="url(#glow)"
                            >
                                <animateMotion
                                    path={pathDef}
                                    dur={`${8 + (i % 5)}s`}
                                    begin={`${i * 0.5}s`}
                                    repeatCount="indefinite"
                                    rotate="auto"
                                />
                            </circle>
                        </g>
                    );
                })}
            </g>

            {/* Inner data flow - around the dashboard_hub */}
            <g>
                {[...Array(8)].map((_, i) => {
                    const pathDef = `M${400},${300} A100,100 0 1,1 ${400},${500} A100,100 0 1,1 ${400},${300}`;

                    return (
                        <g key={`inner-flow-${i}`}>
                            <circle
                                r="3"
                                fill={i % 2 === 0 ? "#22d3ee" : "#c4b5fd"}
                                opacity="0.8"
                                filter="url(#glow)"
                            >
                                <animateMotion
                                    path={pathDef}
                                    dur={`${7 - (i % 3)}s`}
                                    begin={`${i * 0.7}s`}
                                    repeatCount="indefinite"
                                    rotate="auto"
                                />
                            </circle>
                        </g>
                    );
                })}
            </g>

            {/* Title */}
            <text x="400" y="120" text-anchor="middle" fill="#f0f9ff" font-size="24" font-weight="bold">
                The Exchange
            </text>
            <text x="400" y="150" text-anchor="middle" fill="#e0f2fe" font-size="16">
                Component Marketplace
            </text>

            {/* Marketplace Icons */}
            <g transform="translate(650, 150) scale(0.7)" opacity="0.6">
                <path d="M0,0 L40,0 L40,40 L0,40 Z" fill="#0ea5e9" opacity="0.5" />
                <path d="M5,10 L35,10 M5,20 L35,20 M5,30 L35,30" stroke="#f0f9ff" stroke-width="2" />
            </g>

            <g transform="translate(150, 150) scale(0.7)" opacity="0.6">
                <path d="M0,0 L40,0 L40,40 L0,40 Z" fill="#8b5cf6" opacity="0.5" />
                <path d="M10,5 L10,35 M20,5 L20,35 M30,5 L30,35" stroke="#f0f9ff" stroke-width="2" />
            </g>

            <g transform="translate(150, 650) scale(0.7)" opacity="0.6">
                <path d="M5,5 L35,5 L35,35 L5,35 Z" fill="#f59e0b" opacity="0.5" />
                <path d="M20,15 L30,20 L20,25 L10,20 Z" fill="#f0f9ff" />
            </g>

            <g transform="translate(650, 650) scale(0.7)" opacity="0.6">
                <path d="M20,0 L40,20 L20,40 L0,20 Z" fill="#0ea5e9" opacity="0.5" />
                <circle cx="20" cy="20" r="10" fill="none" stroke="#f0f9ff" stroke-width="2" />
            </g>
        </svg>
    );
};

/**
 * Helper function to blend two colors
 * @param {string} color1 First hex color
 * @param {string} color2 Second hex color
 * @param {number} ratio Blend ratio (0-1)
 * @return {string} Blended hex color
 */
function blendColors(color1, color2, ratio) {
    if (!color1.startsWith('#') || !color2.startsWith('#')) return color1;

    // Convert hex to RGB
    const r1 = parseInt(color1.substring(1, 3), 16);
    const g1 = parseInt(color1.substring(3, 5), 16);
    const b1 = parseInt(color1.substring(5, 7), 16);

    const r2 = parseInt(color2.substring(1, 3), 16);
    const g2 = parseInt(color2.substring(3, 5), 16);
    const b2 = parseInt(color2.substring(5, 7), 16);

    // Blend colors
    const r = Math.round(r1 * (1 - ratio) + r2 * ratio);
    const g = Math.round(g1 * (1 - ratio) + g2 * ratio);
    const b = Math.round(b1 * (1 - ratio) + b2 * ratio);

    // Convert back to hex
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

/**
 * Helper function to adjust color brightness
 * @param {string} color Hex color code
 * @param {number} percent Percentage to lighten (positive) or darken (negative)
 * @return {string} Modified hex color
 */
function adjustColorBrightness(color, percent) {
    if (!color) return color;
    if (!color.startsWith('#') || color.length !== 7) return color;

    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.min(255, Math.max(0, R + (R * percent / 100)));
    G = Math.min(255, Math.max(0, G + (G * percent / 100)));
    B = Math.min(255, Math.max(0, B + (B * percent / 100)));

    const RR = Math.round(R).toString(16).padStart(2, '0');
    const GG = Math.round(G).toString(16).padStart(2, '0');
    const BB = Math.round(B).toString(16).padStart(2, '0');

    return `#${RR}${GG}${BB}`;
}

export default TaytromExchangePillar;