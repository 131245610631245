/**
 * @component AIContextManager
 *
 * @description
 * Manages contextual information for AI interactions within Taytrom.
 * Coordinates specialist managers that handle specific aspects of context:
 * - User contexts (profiles, settings, progress)
 * - Conversation contexts (learning, technical, creative)
 * - Knowledge domains (TACS, architecture, etc.)
 *
 * This component enhances AI prompts with relevant contextual information
 * to improve response quality and relevance.
 */

import UserContextSpecialist
    from "@/ai-scope/contexts/user/specialist/Specialist_User_Contexts_AI.js";
import KnowledgeContextSpecialist
    from "@/ai-scope/contexts/knowledge/specialist/Specialist_Knowledge_Contexts_AI.js";
import { ContextUtilities, getSpecializedContext} from "@/ai-scope/contexts/conversation/specialist/Specialist_Conversation_Contexts_AI.js";

class AIContextManager {
    constructor(orchestrator = null) {
        this._orchestrator = orchestrator;
        this._isDevelopment = this._checkIfDevelopmentMode();
        this._state = {
            initialized: false,
            contextMode: 'standard', // 'standard', 'minimal', 'comprehensive', 'technical', 'learning'
            startTime: Date.now(),
            environment: this._isDevelopment ? 'development' : 'production'
        };

        // Initialize specialists
        this.userContextSpecialist = null;
        this.knowledgeContextSpecialist = null;

        // Knowledge file cache
        this._knowledgeFiles = {
            both: {},
            admin: {},
            user: {}
        };

        console.log(`[AIContextManager] Initializing in ${this._state.environment} mode`);
    }

    // Modified initialize method to load knowledge from all directories
    async initialize() {
        try {
            // Initialize file system first
            await this.initializeFileSystem();

            // Initialize specialists
            this.userContextSpecialist = new UserContextSpecialist(this._orchestrator);
            this.knowledgeContextSpecialist = new KnowledgeContextSpecialist(this._orchestrator);

            // Force loading of admin knowledge immediately
            if (this.knowledgeContextSpecialist) {
                // Force clear any existing cache
                this.knowledgeContextSpecialist.clearCache();

                // Preload admin and both domains
                this._state.contextMode = 'comprehensive'; // Use comprehensive mode for more domains
                this._state.preloadedDomains = this.knowledgeContextSpecialist.getKnowledgeDomains('both', {
                    isAdminMode: true,
                    maxDomains: 20  // Increase max domains to load
                });

                console.log(`[AIContextManager] Preloaded ${Object.keys(this._state.preloadedDomains).length} knowledge domains`);
            }

            // Try to attach to context events
            if (this._orchestrator) {
                this._setupContextListeners();
            }

            // Pre-load knowledge from all domains
            await this.loadAllKnowledgeFiles();

            this._state.initialized = true;
            console.log(`[AIContextManager] Initialization complete`);
            return true;
        } catch (error) {
            console.error("[AIContextManager] Initialization failed:", error);
            this._state.error = error.message;
            return false;
        }
    }

    /**
     * Initialize file system access with improved fallbacks
     * @returns {Promise<boolean>} - Whether file system initialization was successful
     */
    async initializeFileSystem() {
        console.log("[AIContextManager] Initializing file system access");

        // Check if we're in browser environment
        if (typeof window === 'undefined') {
            console.error("[AIContextManager] Not running in browser environment");
            return false;
        }

        // If window.fs already exists, we're good
        if (window.fs && typeof window.fs.readFile === 'function') {
            console.log("[AIContextManager] Native file system API detected");
            return true;
        }

        console.log("[AIContextManager] No native file system API found, setting up fallback system");

        // Set up our fallback file system
        try {
            // Create fs object if it doesn't exist
            window.fs = window.fs || {};

            // Flag to indicate we're using the fallback system
            window.fs._isFallback = true;

            // Create in-memory file system with some predefined files
            const inMemoryFiles = {
                'project-structure.txt': this._getHardcodedProjectStructure(),
                // Add path variations to ensure the file is found regardless of requested path
                'SCOPE/ai-scope/knowledge_domains/admin/structure/project-structure.txt': this._getHardcodedProjectStructure(),
                'src/SCOPE/ai-scope/knowledge_domains/admin/structure/project-structure.txt': this._getHardcodedProjectStructure(),
                'admin/project-structure.txt': this._getHardcodedProjectStructure(),
                'knowledge_domains/admin/structure/project-structure.txt': this._getHardcodedProjectStructure(),
                'ai-scope/knowledge_domains/admin/structure/project-structure.txt': this._getHardcodedProjectStructure()
            };

            // Implement readFile with better logging and error handling
            window.fs.readFile = async (path, options = {}) => {
                console.log(`[AIContextManager] Fallback readFile called for: ${path}`);

                // Try to find the file in our in-memory system
                if (inMemoryFiles[path]) {
                    console.log(`[AIContextManager] Found file in fallback system: ${path}`);

                    // If encoding is specified, return as string
                    if (options.encoding === 'utf8') {
                        return inMemoryFiles[path];
                    }

                    // Otherwise return as Uint8Array (simulating binary data)
                    const encoder = new TextEncoder();
                    return encoder.encode(inMemoryFiles[path]);
                }

                // Check if this might be requesting project-structure.txt with a different path
                if (path.includes('project-structure') || path.endsWith('.txt')) {
                    console.log(`[AIContextManager] Path contains 'project-structure' or is a .txt file, using hardcoded structure as fallback`);

                    if (options.encoding === 'utf8') {
                        return this._getHardcodedProjectStructure();
                    }

                    const encoder = new TextEncoder();
                    return encoder.encode(this._getHardcodedProjectStructure());
                }

                // Try to fetch from public directory as fallback
                try {
                    console.log(`[AIContextManager] Trying to fetch file from public directory: ${path}`);
                    const response = await fetch(path);

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    if (options.encoding === 'utf8') {
                        return await response.text();
                    }

                    return new Uint8Array(await response.arrayBuffer());
                } catch (fetchError) {
                    console.log(`[AIContextManager] Fetch fallback failed: ${fetchError.message}`);
                }

                // If we get here, the file wasn't found anywhere
                throw new Error(`File not found: ${path}`);
            };

            // Implement writeFile (as a dummy function that pretends to succeed)
            window.fs.writeFile = async (path, data, options = {}) => {
                console.log(`[AIContextManager] Fallback writeFile called for: ${path} (data will not be persisted)`);
                // Pretend to write the file
                return true;
            };

            // Add a fallback "readdir" implementation that returns hardcoded file lists
            // This is needed because browsers don't have native directory listing capabilities
            window.fs.readdir = async (path, options = {}) => {
                console.log(`[AIContextManager] Fallback readdir called for: ${path}`);

                // Define known files in each directory
                const knownDirectories = {
                    // Add the src directory entry
                    'src': [
                        'SCOPE',
                        'components',
                        'FrontAutocrat.jsx',
                        'FullDevApp.jsx',
                        'index.jsx'
                    ],
                    // Add the SCOPE directory for better navigation
                    'src/SCOPE': [
                        'ai-scope',
                        'admin-scope',
                        'authentication-scope',
                        'homepage-scope',
                        'templates-scope'
                    ],
                    'SCOPE/ai-scope/knowledge_domains/both': [
                        'aos/AOS_KnowledgeDomains_IntelligenceSystem_AI.js',
                        'ccss/CCSS_KnowledgeDomains_IntelligenceSystem_AI.js',
                        'space/SPACE_KnowledgeDomains_IntelligenceSystem_AI.js',
                        'stack/STACK_KnowledgeDomains_IntelligenceSystem_AI.js',
                        'ties/TIES_KnowledgeDomains_IntelligenceSystem_AI.js'
                    ],
                    'SCOPE/ai-scope/knowledge_domains/admin': [
                        'executive_blueprints/Executive Blueprint (v. 3-9-25).txt',
                        'structure/project-structure.txt'
                    ],
                    'SCOPE/ai-scope/knowledge_domains/user': [
                        // Add any known user-specific files here
                    ],
                    // Add entries for commonly accessed paths
                    'SCOPE/ai-scope': [
                        'contexts',
                        'knowledge_domains',
                        'intelligence_system',
                        'AI_Component.jsx'
                    ],
                    'SCOPE/ai-scope/contexts': [
                        'AIContextManager.js',
                        'user',
                        'knowledge',
                        'conversation'
                    ]
                };

                // Normalize the path to check against our known directories
                const normalizedPath = path.replace(/^\/+|\/+$/g, '');

                // Add a simple cache to prevent repeated failed calls
                if (!window._readdirCache) {
                    window._readdirCache = {};
                }

                // If we've already tried this path and have a cached result, return it
                if (window._readdirCache[normalizedPath]) {
                    console.log(`[AIContextManager] Using cached result for path: ${normalizedPath}`);
                    return window._readdirCache[normalizedPath];
                }

                // Check if this is a known directory
                for (const [dir, files] of Object.entries(knownDirectories)) {
                    if (normalizedPath === dir || normalizedPath.endsWith('/' + dir)) {
                        console.log(`[AIContextManager] Returning ${files.length} known files for directory: ${normalizedPath}`);

                        // Create result with proper file objects that include isDirectory method
                        const result = files.map(file => {
                            // Check if the entry is likely a directory (no file extension or ends with /)
                            const isDir = !file.includes('.') || file.endsWith('/');
                            const fileName = file.endsWith('/') ? file.slice(0, -1) : file;

                            return {
                                name: fileName,
                                isDirectory: () => isDir
                            };
                        });

                        // Cache the result
                        window._readdirCache[normalizedPath] = result;
                        return result;
                    }
                }

                // If path contains any of our known directories, it might be a parent
                // Try to infer what directories should be here
                const possibleEntries = [];
                for (const dir of Object.keys(knownDirectories)) {
                    const parts = dir.split('/');
                    if (parts.length > 1) {
                        // Get the parent directory
                        const parent = parts.slice(0, -1).join('/');
                        if (normalizedPath === parent) {
                            // The last part would be a directory in this parent
                            possibleEntries.push({
                                name: parts[parts.length - 1],
                                isDirectory: () => true
                            });
                        }
                    }
                }

                if (possibleEntries.length > 0) {
                    console.log(`[AIContextManager] Inferred ${possibleEntries.length} entries for path: ${normalizedPath}`);

                    // Remove duplicates by name
                    const uniqueEntries = Array.from(
                        new Set(possibleEntries.map(e => e.name))
                    ).map(name => {
                        return {
                            name,
                            isDirectory: () => true
                        };
                    });

                    // Cache the result
                    window._readdirCache[normalizedPath] = uniqueEntries;
                    return uniqueEntries;
                }

                // For any other path, return an empty array (no files)
                console.log(`[AIContextManager] No known files for directory: ${normalizedPath}`);

                // Cache the negative result to avoid repeated calls
                window._readdirCache[normalizedPath] = [];
                return [];
            };

            console.log("[AIContextManager] Fallback file system initialized successfully");
            return true;
        } catch (error) {
            console.error("[AIContextManager] Failed to initialize fallback file system:", error);
            return false;
        }
    }

    /**
     * Get hardcoded project structure content as fallback
     * @private
     * @returns {string} - Project structure content
     */
    _getHardcodedProjectStructure() {
        return `src/
├── SCOPE/
│   ├── academy-scope/
│   ├── ai-scope/
│   │   ├── constants/
│   │   ├── intelligence_system/
│   │   │   ├── AIContextManager.js
│   │   │   └── ... (other files)
│   │   ├── knowledge_domains/
│   │   │   ├── admin/
│   │   │   │   ├── executive_blueprints/
│   │   │   │   │   └── Executive Blueprint (v. 3-9-25).md
│   │   │   │   └── structure/
│   │   │   │       └── project-structure.txt
│   │   │   ├── both/
│   │   │   │   ├── aos/
│   │   │   │   │   └── AOS_KnowledgeDomains_IntelligenceSystem_AI.js
│   │   │   │   ├── ccss/
│   │   │   │   │   └── CCSS_KnowledgeDomains_IntelligenceSystem_AI.js
│   │   │   │   ├── space/
│   │   │   │   │   └── SPACE_KnowledgeDomains_IntelligenceSystem_AI.js
│   │   │   │   ├── stack/
│   │   │   │   │   └── STACK_KnowledgeDomains_IntelligenceSystem_AI.js
│   │   │   │   └── ties/
│   │   │   │       └── TIES_KnowledgeDomains_IntelligenceSystem_AI.js
│   │   │   └── user/
│   │   │       └── ... (user-specific knowledge files)
│   │   └── AI_Component.jsx
│   ├── authentication-scope/
│   ├── homepage-scope/
│   ├── templates-scope/
│   │   ├── ai/
│   │   │   └── chat/
│   │   │       └── Golden_AIChat_Component_Templates.jsx
│   │   └── ... (other template files)
└── ... (other directories and files)`;
    }

    /**
     * Determines if running in development mode
     * @private
     * @returns {boolean} - Whether running in development mode
     */
    _checkIfDevelopmentMode() {
        // Check for development indicators
        return (
            (typeof process !== 'undefined' && process.env && process.env.NODE_ENV === 'development') ||
            (typeof import.meta !== 'undefined' && import.meta.env && import.meta.env.MODE === 'development') ||
            (typeof window !== 'undefined' && window.location && (
                window.location.hostname === 'localhost' ||
                window.location.hostname === '127.0.0.1'
            ))
        );
    }

    /**
     * Check if the current context is admin mode
     * @private
     * @param {Object} state - Current state
     * @returns {boolean} - Whether current context is admin mode
     */
    _isAdminMode(state = {}) {
        // Check if the route indicates admin context
        const route = state.route || (typeof window !== 'undefined' ? window.location.pathname : '/');
        const activeComponent = state.activeComponent || '';

        // Match routes for admin console or direct file path patterns for admin
        return route === '/admin/ai-console' ||
            route.includes('Chat_AI_Admin.jsx') ||
            activeComponent.includes('Golden_AIChat_Component_Templates');
    }

    /**
     * Sets up context listeners
     * @private
     */
    _setupContextListeners() {
        if (!this._orchestrator || !this._orchestrator.subscriptionManager) {
            return;
        }

        // Subscribe to relevant events
        this._orchestrator.subscriptionManager.subscribe('auth', this._handleAuthChange.bind(this), 'AIContextManager');
        this._orchestrator.subscriptionManager.subscribe('settings', this._handleSettingsChange.bind(this), 'AIContextManager');
        this._orchestrator.subscriptionManager.subscribe('progress', this._handleProgressChange.bind(this), 'AIContextManager');

        console.log("[AIContextManager] Set up context listeners");
    }

    /**
     * Handle auth state changes
     * @private
     * @param {Object} notification - Auth notification
     */
    _handleAuthChange(notification) {
        // Clear caches when user changes
        if (this.userContextSpecialist) {
            this.userContextSpecialist.clearCache();
        }

        console.log("[AIContextManager] Auth state changed, cleared user context cache");
    }

    /**
     * Handle settings changes
     * @private
     * @param {Object} notification - Settings notification
     */
    _handleSettingsChange(notification) {
        // Update context mode if AI settings changed
        if (notification.settings?.ai) {
            const personalizationLevel = notification.settings.ai.personalizationLevel;
            if (personalizationLevel !== undefined) {
                this._updateContextMode(personalizationLevel);
            }
        }
    }

    /**
     * Handle progress changes
     * @private
     * @param {Object} notification - Progress notification
     */
    _handleProgressChange(notification) {
        // Nothing to do currently - user context specialist will handle cache
    }

    /**
     * Update context mode based on personalization level
     * @private
     * @param {number} personalizationLevel - User's personalization level (1-3)
     */
    _updateContextMode(personalizationLevel) {
        if (personalizationLevel === 1) {
            this._state.contextMode = 'minimal';
        } else if (personalizationLevel === 2) {
            this._state.contextMode = 'standard';
        } else if (personalizationLevel === 3) {
            this._state.contextMode = 'comprehensive';
        }

        console.log(`[AIContextManager] Context mode updated to: ${this._state.contextMode}`);
    }

    /**
     * Retrieves contextual knowledge based on application state and user context
     * @param {Object} state - Current application state
     * @param {string} userId - User ID to include context for
     * @returns {Promise<Object>} - Contextual knowledge
     */
    async getContextualKnowledge(state = {}, userId = null) {
        // Determine if we're in admin mode
        const isAdminMode = this._isAdminMode(state);

        // Build the knowledge structure
        const knowledge = {
            base: {
                currentState: {
                    route: state.route || (typeof window !== 'undefined' ? window.location.pathname : '/'),
                    section: state.section || null,
                    stackLevel: state.stackLevel || null,
                    activeComponent: state.activeComponent || null,
                    theme: state.theme || 'dark',
                    timestamp: Date.now(),
                    isAdminMode // Add flag to indicate admin mode
                },
                metadata: {
                    generatedBy: 'AIContextManager',
                    version: '1.0.0',
                    contextMode: this._state.contextMode,
                    environment: this._state.environment,
                    timestamp: Date.now()
                }
            },
            domains: {},
            user: null,
            knowledgeFiles: {
                both: this._knowledgeFiles.both, // Always include "both" files
                // Conditionally include admin or user files based on context
                ...(isAdminMode ? { admin: this._knowledgeFiles.admin } : { user: this._knowledgeFiles.user })
            }
        };

        // Get knowledge domains from specialist
        if (this.knowledgeContextSpecialist) {
            const domains = this.knowledgeContextSpecialist.getKnowledgeDomains('both', {
                isAdminMode,
                maxDomains: this._getDomainLimitForMode()
            });

            knowledge.domains = domains;
        }

        // Add user context if requested and available
        if (userId && this.userContextSpecialist) {
            // Get user context based on current mode
            const userContextLevel = this._getUserContextLevelForMode();

            if (userContextLevel > 0) {
                const userContexts = await this.userContextSpecialist.getAllUserContexts(userId, {
                    contextLevel: userContextLevel
                });

                if (userContexts) {
                    knowledge.user = userContexts;
                }
            }
        }

        // Load additional admin knowledge if in admin mode
        if (isAdminMode) {
            try {
                const adminKnowledge = await this.getAdminKnowledge();
                if (adminKnowledge && adminKnowledge.admin) {
                    knowledge.admin = adminKnowledge.admin;
                }
            } catch (error) {
                console.error("Error loading admin knowledge:", error);
            }
        }

        return knowledge;
    }

    /**
     * Gets domain limit based on current context mode
     * @private
     * @returns {number} - Domain limit
     */
    _getDomainLimitForMode() {
        const modeLimits = {
            'minimal': 2,
            'standard': 4,
            'comprehensive': 8,
            'technical': 6,
            'learning': 5
        };

        return modeLimits[this._state.contextMode] || 4;
    }

    /**
     * Gets user context level based on current context mode
     * @private
     * @returns {number} - User context level
     */
    _getUserContextLevelForMode() {
        const modeLevels = {
            'minimal': 1,  // Basic preferences only
            'standard': 2,  // Preferences and basic profile
            'comprehensive': 3,  // Full context
            'technical': 2,  // Preferences and technical profile
            'learning': 3   // Full context with learning focus
        };

        return modeLevels[this._state.contextMode] || 2;
    }

    /**
     * New method to load all knowledge files from both, admin, and user directories
     * @returns {Promise<Object>} - An object containing all loaded files by category
     */
    async loadAllKnowledgeFiles() {
        console.log("[AIContextManager] Loading all knowledge files from both, admin, and user directories");

        try {
            // Define base paths for different knowledge domains
            const basePaths = [
                '/Users/robtaylor/Documents/Mac/Taytrom/Coding/taytrom_landing-page/src/SCOPE/ai-scope/knowledge_domains',
                'SCOPE/ai-scope/knowledge_domains',
                'src/SCOPE/ai-scope/knowledge_domains',
                'knowledge_domains',
                'ai-scope/knowledge_domains',
            ];

            // Try loading from each base path
            for (const basePath of basePaths) {
                try {
                    await this._loadFilesFromDirectory(`${basePath}/both`, 'both');
                    await this._loadFilesFromDirectory(`${basePath}/admin`, 'admin');
                    await this._loadFilesFromDirectory(`${basePath}/user`, 'user');

                    console.log(`[AIContextManager] Successfully loaded files from: ${basePath}`);
                    console.log(`[AIContextManager] Loaded ${Object.keys(this._knowledgeFiles.both).length} 'both' files`);
                    console.log(`[AIContextManager] Loaded ${Object.keys(this._knowledgeFiles.admin).length} 'admin' files`);
                    console.log(`[AIContextManager] Loaded ${Object.keys(this._knowledgeFiles.user).length} 'user' files`);

                    // If we successfully loaded from this path, don't try others
                    if (Object.keys(this._knowledgeFiles.both).length > 0 ||
                        Object.keys(this._knowledgeFiles.admin).length > 0 ||
                        Object.keys(this._knowledgeFiles.user).length > 0) {
                        break;
                    }
                } catch (error) {
                    console.warn(`[AIContextManager] Could not load files from ${basePath}: ${error.message}`);
                    // Continue to the next base path
                }
            }

            // Explicitly try to load the Executive Blueprint
            if (!this._knowledgeFiles.admin['executive_blueprint']) {
                try {
                    const result = await this.loadAdminFile('Executive Blueprint (v. 3-9-25).txt');
                    if (result.success) {
                        this._knowledgeFiles.admin['executive_blueprint'] = result.content;
                        console.log("[AIContextManager] Successfully loaded Executive Blueprint");
                    }
                } catch (error) {
                    console.warn("[AIContextManager] Could not load Executive Blueprint:", error.message);
                }
            }

            return this._knowledgeFiles;
        } catch (error) {
            console.error("[AIContextManager] Error loading knowledge files:", error);
            return {
                both: {},
                admin: {},
                user: {}
            };
        }
    }
    /**
     * Helper method to load files from a specific directory
     * Fixed to properly handle file entries that might be objects
     * @private
     * @param {string} directoryPath - Path to the directory
     * @param {string} category - Category of the files (both, admin, or user)
     */
    async _loadFilesFromDirectory(directoryPath, category) {
        console.log(`[AIContextManager] Loading files from ${directoryPath} (${category})`);

        try {
            // Try to list files in the directory
            if (window.fs && window.fs.readdir) {
                const files = await window.fs.readdir(directoryPath);
                console.log(`[AIContextManager] Found ${files.length} files in ${directoryPath}`);

                // Process each file
                for (const file of files) {
                    try {
                        // Handle file entries that might be objects with a name property
                        const fileName = typeof file === 'object' && file.name ? file.name : file;

                        // For simplicity, we'll assume files in subdirectories have a path separator
                        const filePath = `${directoryPath}/${fileName}`;
                        const result = await window.fs.readFile(filePath, { encoding: 'utf8' });

                        // Create a file key based on the file name
                        const fileKey = fileName.split('/').pop().split('.')[0]
                            .replace(/\s+/g, '_')
                            .toLowerCase();

                        // Store the file content
                        this._knowledgeFiles[category][fileKey] = result;
                        console.log(`[AIContextManager] Loaded ${category}/${fileKey} (${result.length} bytes)`);
                    } catch (fileError) {
                        const errorFileName = typeof file === 'object' ? (file.name || 'unknown') : file;
                        console.warn(`[AIContextManager] Error loading ${errorFileName}: ${fileError.message}`);
                    }
                }
            } else {
                console.warn("[AIContextManager] readdir method not available, using fallback approach");

                // Try loading some known important files for each category
                if (category === 'both') {
                    await this._loadKnownFiles(directoryPath, category, [
                        'aos/AOS_KnowledgeDomains_IntelligenceSystem_AI.js',
                        'ccss/CCSS_KnowledgeDomains_IntelligenceSystem_AI.js',
                        'space/SPACE_KnowledgeDomains_IntelligenceSystem_AI.js',
                        'stack/STACK_KnowledgeDomains_IntelligenceSystem_AI.js',
                        'ties/TIES_KnowledgeDomains_IntelligenceSystem_AI.js'
                    ]);
                } else if (category === 'admin') {
                    await this._loadKnownFiles(directoryPath, category, [
                        'executive_blueprints/Executive Blueprint (v. 3-9-25).txt',
                        'structure/project-structure.txt'
                    ]);
                } else if (category === 'user') {
                    // Add any known user files here
                    await this._loadKnownFiles(directoryPath, category, []);
                }
            }
        } catch (error) {
            console.error(`[AIContextManager] Error loading files from ${directoryPath}: ${error.message}`);
            throw error;
        }
    }

    /**
     * Helper to load known files when directory listing is not available
     * @private
     * @param {string} basePath - Base directory path
     * @param {string} category - Category (both, admin, user)
     * @param {string[]} knownFiles - Array of known file paths
     */
    async _loadKnownFiles(basePath, category, knownFiles) {
        for (const filePath of knownFiles) {
            try {
                const fullPath = `${basePath}/${filePath}`;
                const content = await window.fs.readFile(fullPath, { encoding: 'utf8' });

                // Get file key from the last part of the path, without extension
                const pathParts = filePath.split('/');
                const fileName = pathParts[pathParts.length - 1];
                const fileKey = fileName.split('.')[0]
                    .replace(/\s+/g, '_')
                    .toLowerCase();

                this._knowledgeFiles[category][fileKey] = content;
                console.log(`[AIContextManager] Loaded ${category}/${fileKey} from ${fullPath} (${content.length} bytes)`);
            } catch (error) {
                console.warn(`[AIContextManager] Could not load ${filePath}: ${error.message}`);
            }
        }
    }

    /**
     * Load admin file with better error handling
     * @param {string} filename - File to load
     * @returns {Promise<Object>} - File content or error
     */
    async loadAdminFile(filename) {
        console.log(`[AIContextManager] Starting to load admin file: ${filename}`);

        // Check if we have the window.fs API
        if (typeof window === 'undefined') {
            console.error("[AIContextManager] window is undefined - running in non-browser environment");
            return { success: false, error: "Not in browser environment" };
        }

        if (!window.fs) {
            console.error("[AIContextManager] window.fs is undefined - file system API not available");
            return { success: false, error: "File system API not available (window.fs undefined)" };
        }

        if (!window.fs.readFile) {
            console.error("[AIContextManager] window.fs.readFile is undefined - readFile method not available");
            return { success: false, error: "readFile method not available" };
        }

        console.log("[AIContextManager] File system API available, proceeding with file loading");

        const paths = this.getAdminFilePath(filename);
        console.log(`[AIContextManager] Will try ${paths.length} different path variations:`, paths);

        for (const path of paths) {
            try {
                console.log(`[AIContextManager] Attempting to read: "${path}"`);
                const content = await window.fs.readFile(path, { encoding: 'utf8' });

                // Log success but not the full content (could be large)
                console.log(`[AIContextManager] Successfully loaded file from path: "${path}" (${content.length} bytes)`);
                console.log(`[AIContextManager] Content preview: "${content.substring(0, 100)}..."`);

                return {
                    success: true,
                    content,
                    path,
                    timestamp: Date.now()
                };
            } catch (error) {
                console.log(`[AIContextManager] Failed to read from path: "${path}" - Error: ${error.message}`);
                // Continue to next path
            }
        }

        // If we get here, all paths failed
        console.error(`[AIContextManager] All path attempts failed for ${filename}`);
        return {
            success: false,
            error: `Failed to load ${filename} from any path`,
            attemptedPaths: paths
        };
    }

    /**
     * Get possible paths for admin files
     * @param {string} filename - File to find
     * @returns {string[]} - Possible file paths
     */
    getAdminFilePath(filename) {
        // Try various reasonable paths with expanded paths for knowledge directories
        return [
            filename,
            `admin/${filename}`,
            `src/admin/${filename}`,
            // Structure directory paths
            `SCOPE/ai-scope/knowledge_domains/admin/structure/${filename}`,
            `src/SCOPE/ai-scope/knowledge_domains/admin/structure/${filename}`,
            `knowledge_domains/admin/structure/${filename}`,
            `ai-scope/knowledge_domains/admin/structure/${filename}`,
            // Executive blueprints paths (new)
            `SCOPE/ai-scope/knowledge_domains/admin/executive_blueprints/${filename}`,
            `src/SCOPE/ai-scope/knowledge_domains/admin/executive_blueprints/${filename}`,
            `knowledge_domains/admin/executive_blueprints/${filename}`,
            `ai-scope/knowledge_domains/admin/executive_blueprints/${filename}`,
            // Both directory paths (new)
            `SCOPE/ai-scope/knowledge_domains/both/${filename}`,
            `src/SCOPE/ai-scope/knowledge_domains/both/${filename}`,
            `knowledge_domains/both/${filename}`,
            `ai-scope/knowledge_domains/both/${filename}`,
            // User directory paths (new)
            `SCOPE/ai-scope/knowledge_domains/user/${filename}`,
            `src/SCOPE/ai-scope/knowledge_domains/user/${filename}`,
            `knowledge_domains/user/${filename}`,
            `ai-scope/knowledge_domains/user/${filename}`,
            // Full user path (new)
            `/Users/robtaylor/Documents/Mac/Taytrom/Coding/taytrom_landing-page/src/SCOPE/ai-scope/knowledge_domains/admin/executive_blueprints/${filename}`,
            `/Users/robtaylor/Documents/Mac/Taytrom/Coding/taytrom_landing-page/src/SCOPE/ai-scope/knowledge_domains/admin/structure/${filename}`,
            `/Users/robtaylor/Documents/Mac/Taytrom/Coding/taytrom_landing-page/src/SCOPE/ai-scope/knowledge_domains/both/${filename}`,
            `/Users/robtaylor/Documents/Mac/Taytrom/Coding/taytrom_landing-page/src/SCOPE/ai-scope/knowledge_domains/user/${filename}`,
            // Public directory as last resort
            `public/${filename}`
        ];
    }

    /**
     * Get admin knowledge for context enhancement with improved fallback
     * @returns {Promise<Object>} - Admin knowledge
     */
    async getAdminKnowledge() {
        console.log("[AIContextManager] getAdminKnowledge called");

        try {
            // Ensure we have loaded all knowledge files
            if (Object.keys(this._knowledgeFiles.both).length === 0 &&
                Object.keys(this._knowledgeFiles.admin).length === 0 &&
                Object.keys(this._knowledgeFiles.user).length === 0) {
                await this.loadAllKnowledgeFiles();
            }

            // Load the admin structure files as fallback if we didn't get them already
            let projectStructure = this._knowledgeFiles.admin.project_structure;
            let structureLoaded = !!projectStructure;
            let loadedPath = null;
            let structureError = null;

            if (!structureLoaded) {
                console.log("[AIContextManager] Attempting to load admin structure files");
                const structureData = await this.loadAdminStructureFiles();

                if (structureData.success) {
                    console.log("[AIContextManager] Successfully loaded admin structure files");
                    projectStructure = structureData.content;
                    structureLoaded = true;
                    loadedPath = structureData.path;
                } else {
                    console.error("[AIContextManager] Failed to load admin structure files:", structureData.error);
                    console.error("[AIContextManager] Attempted paths:", structureData.attemptedPaths);

                    // Use hardcoded structure as fallback when file can't be loaded
                    console.log("[AIContextManager] Using hardcoded project structure as fallback");
                    projectStructure = this._getHardcodedProjectStructure();
                    structureLoaded = true;
                    loadedPath = "fallback-hardcoded";
                    structureError = structureData.error;
                }
            }

            // Get knowledge domains from specialist with admin context forced
            let domains = {};
            if (this.knowledgeContextSpecialist) {
                domains = this.knowledgeContextSpecialist.getKnowledgeDomains('both', {
                    isAdminMode: true,
                    maxDomains: 20,
                    forceIncludeAdmin: true
                });
            }

            // Get general contextual knowledge
            console.log("[AIContextManager] Getting general contextual knowledge");
            const generalKnowledge = {
                base: {
                    currentState: {
                        route: '/admin/ai-console',
                        section: 'admin',
                        stackLevel: 'admin',
                        activeComponent: 'Golden_AIChat_Component_Templates',
                        theme: 'dark',
                        timestamp: Date.now(),
                        isAdminMode: true
                    },
                    metadata: {
                        generatedBy: 'AIContextManager',
                        version: '1.0.0',
                        contextMode: this._state.contextMode || 'comprehensive',
                        environment: this._state.environment || 'development',
                        timestamp: Date.now()
                    }
                },
                domains: domains,
                user: null
            };

            // Combine knowledge
            const combinedKnowledge = {
                ...generalKnowledge,
                admin: {
                    projectStructure: projectStructure,
                    structureLoaded: structureLoaded,
                    loadedPath: loadedPath,
                    timestamp: Date.now(),
                    error: structureError,
                    usedFallback: loadedPath === "fallback-hardcoded",
                    // Add all loaded knowledge files
                    knowledgeFiles: {
                        // Always include "both" files
                        both: this._knowledgeFiles.both,
                        // Include admin files for admin context
                        admin: this._knowledgeFiles.admin,
                        // Total files loaded
                        totalFilesLoaded: Object.keys(this._knowledgeFiles.both).length +
                            Object.keys(this._knowledgeFiles.admin).length
                    }
                }
            };

            // Check if we have the executive blueprint
            if (this._knowledgeFiles.admin.executive_blueprint) {
                combinedKnowledge.admin.executiveBlueprint = this._knowledgeFiles.admin.executive_blueprint;
                console.log("[AIContextManager] Included Executive Blueprint in admin knowledge");
            }

            console.log("[AIContextManager] Admin knowledge prepared:", {
                hasProjectStructure: !!combinedKnowledge.admin.projectStructure,
                structureLoaded: combinedKnowledge.admin.structureLoaded,
                loadedPath: combinedKnowledge.admin.loadedPath,
                error: combinedKnowledge.admin.error,
                domainsLoaded: Object.keys(combinedKnowledge.domains).length,
                hasExecutiveBlueprint: !!combinedKnowledge.admin.executiveBlueprint,
                knowledgeFilesLoaded: combinedKnowledge.admin.knowledgeFiles.totalFilesLoaded
            });

            return combinedKnowledge;
        } catch (error) {
            console.error("[AIContextManager] Error getting admin knowledge:", error);
            console.error("[AIContextManager] Error stack:", error.stack);

            // Return a minimal valid structure even on error
            return {
                success: false,
                error: error.message,
                stack: error.stack,
                admin: {
                    projectStructure: this._getHardcodedProjectStructure(),
                    structureLoaded: true,
                    loadedPath: "error-fallback",
                    timestamp: Date.now(),
                    error: error.message,
                    usedFallback: true,
                    // Include any knowledge files we were able to load
                    knowledgeFiles: {
                        both: this._knowledgeFiles.both || {},
                        admin: this._knowledgeFiles.admin || {}
                    }
                },
                domains: this.knowledgeContextSpecialist ?
                    this.knowledgeContextSpecialist.getKnowledgeDomains('both', {
                        isAdminMode: true,
                        maxDomains: 10,
                        forceIncludeAdmin: true
                    }) : {},
                base: {
                    currentState: {
                        isAdminMode: true,
                        timestamp: Date.now()
                    }
                }
            };
        }
    }

    /**
     * Load admin structure files
     * @returns {Promise<Object>} - Structure file content
     */
    async loadAdminStructureFiles() {
        console.log("[AIContextManager] loadAdminStructureFiles called");

        try {
            // Check if we're in the browser environment with file system access
            if (typeof window === 'undefined' || !window.fs || !window.fs.readFile) {
                console.error("[AIContextManager] File system access not available:", {
                    windowExists: typeof window !== 'undefined',
                    fsExists: typeof window !== 'undefined' && !!window.fs,
                    readFileExists: typeof window !== 'undefined' && window.fs && !!window.fs.readFile
                });

                return {
                    success: false,
                    error: "File system not available",
                    details: {
                        windowExists: typeof window !== 'undefined',
                        fsExists: typeof window !== 'undefined' && !!window.fs,
                        readFileExists: typeof window !== 'undefined' && window.fs && !!window.fs.readFile
                    }
                };
            }

            console.log("[AIContextManager] Attempting to load project-structure.txt");
            const result = await this.loadAdminFile('project-structure.txt');

            // Also try to load the executive blueprint
            try {
                console.log("[AIContextManager] Attempting to load Executive Blueprint");
                const blueprintResult = await this.loadAdminFile('Executive Blueprint (v. 3-9-25).txt');
                if (blueprintResult.success) {
                    console.log("[AIContextManager] Successfully loaded Executive Blueprint");
                    this._knowledgeFiles.admin.executive_blueprint = blueprintResult.content;
                }
            } catch (blueprintError) {
                console.warn("[AIContextManager] Error loading Executive Blueprint:", blueprintError.message);
            }

            return result;
        } catch (error) {
            console.error("[AIContextManager] Error in loadAdminStructureFiles:", error);
            console.error("[AIContextManager] Error stack:", error.stack);
            return {
                success: false,
                error: error.message,
                stack: error.stack
            };
        }
    }

    /**
     * Format knowledge for inclusion in prompts
     * @param {Object} knowledge - Knowledge to format
     * @param {Object} context - Context information
     * @returns {string} - Formatted knowledge for prompt
     */
    _formatKnowledgeForPrompt(knowledge, context) {
        // Delegate to specialists for specific formatting
        let formattedContext = "";

        // Add knowledge domains if available
        if (knowledge.domains && Object.keys(knowledge.domains).length > 0) {
            if (this.knowledgeContextSpecialist) {
                formattedContext += this.knowledgeContextSpecialist.formatKnowledgeForAI(
                    knowledge.domains,
                    { contextMode: this._state.contextMode }
                );
            } else {
                // Fallback basic formatting
                formattedContext += "## Relevant Taytrom knowledge:\n\n";
                for (const [domainId, domain] of Object.entries(knowledge.domains)) {
                    formattedContext += `### ${domain.title}\n`;
                    formattedContext += `${domain.description || ''}\n\n`;
                }
            }
        }

        // Add user context if available
        if (knowledge.user) {
            if (this.userContextSpecialist) {
                formattedContext += this.userContextSpecialist.formatUserContextForAI(
                    knowledge.user,
                    { contextMode: this._state.contextMode }
                );
            }
        }

        // Add knowledge files content based on context
        formattedContext += "## Knowledge Files\n\n";

        // Always include "both" knowledge files
        if (knowledge.knowledgeFiles && knowledge.knowledgeFiles.both) {
            formattedContext += "### Shared Knowledge\n";
            for (const [key, content] of Object.entries(knowledge.knowledgeFiles.both)) {
                // Only include a brief preview of each file to avoid overwhelming the context
                formattedContext += `**${key}**: ${content.substring(0, 150)}...\n\n`;
            }
        }

        // Add admin knowledge if in admin mode
        if (knowledge.knowledgeFiles && knowledge.knowledgeFiles.admin && knowledge.base.currentState.isAdminMode) {
            formattedContext += "### Admin Knowledge\n";
            for (const [key, content] of Object.entries(knowledge.knowledgeFiles.admin)) {
                formattedContext += `**${key}**: ${content.substring(0, 150)}...\n\n`;
            }
        }

        // Add user knowledge if not in admin mode
        if (knowledge.knowledgeFiles && knowledge.knowledgeFiles.user && !knowledge.base.currentState.isAdminMode) {
            formattedContext += "### User Knowledge\n";
            for (const [key, content] of Object.entries(knowledge.knowledgeFiles.user)) {
                formattedContext += `**${key}**: ${content.substring(0, 150)}...\n\n`;
            }
        }

        // Add admin context if available
        if (knowledge.admin) {
            formattedContext += "## Admin Context\n\n";
            formattedContext += "You are operating in admin mode, providing assistance with Taytrom system administration.\n";
            formattedContext += "You have access to additional technical information about the system architecture and file structure.\n\n";

            if (knowledge.admin.projectStructure) {
                formattedContext += "### Project Structure Information\n";
                formattedContext += "You have access to the Taytrom project structure and can help with file organization questions.\n\n";
            }

            // Include executive blueprint if available
            if (knowledge.admin.executiveBlueprint) {
                formattedContext += "### Executive Blueprint\n";
                formattedContext += "You have access to the Executive Blueprint for Taytrom, which outlines the system's architectural vision.\n\n";
            }
        }

        // Add system state information
        const state = knowledge.base.currentState;
        formattedContext += `## Taytrom Development Context\n\n`;
        formattedContext += `You are assisting with Taytrom development in the following context:\n`;
        formattedContext += `- Current route: ${state.route || 'Not specified'}\n`;
        formattedContext += `- Context mode: ${state.isAdminMode ? 'Admin' : 'User'}\n`;

        // Add more details based on context mode
        if (this._state.contextMode !== 'minimal') {
            formattedContext += `- Current section: ${state.section || 'Not specified'}\n`;
            formattedContext += `- Stack level focus: ${state.stackLevel || 'Not specified'}\n`;
            formattedContext += `- Active component: ${state.activeComponent || 'Not specified'}\n`;
        }

        formattedContext += `- Theme: ${state.theme || 'dark'}\n\n`;

        return formattedContext;
    }

    /**
     * Create a specialized context for a specific conversation type
     * @param {string} category - Context category (e.g., 'LEARNING', 'TECHNICAL')
     * @param {string} type - Context type (e.g., 'SUPER_BEGINNER', 'REACT_COMPOSITION')
     * @param {Object} overrides - Custom overrides to apply
     * @returns {Object} - Specialized context
     */
    getSpecializedContext(category, type, overrides = {}) {
        return getSpecializedContext(category, type, overrides);
    }

    /**
     * Enhances a prompt with contextual knowledge
     * @param {string} prompt - Original user prompt
     * @param {Object} context - Context object with app state and user info
     * @returns {Promise<Object>} - Object containing original, system context, and combined prompt
     */
    async enhancePrompt(prompt, context = {}) {
        try {
            const userId = context.userId;
            const appState = context.appState || {};
            const userContext = context.userContext || null;
            const conversationType = context.conversationType || null;

            // Get contextual knowledge
            const knowledge = await this.getContextualKnowledge(appState, userId);

            // If user context was directly provided, override knowledge.user
            if (userContext && Object.keys(userContext).length > 0) {
                knowledge.user = userContext;
            }

            // Apply specialized conversation context if provided
            let formattedContext = "";
            if (conversationType && conversationType.category && conversationType.type) {
                const specializedContext = this.getSpecializedContext(
                    conversationType.category,
                    conversationType.type,
                    conversationType.overrides || {}
                );

                formattedContext = ContextUtilities.createContextualPrompt(specializedContext, prompt);
            } else {
                // Format standard knowledge for prompt
                formattedContext = this._formatKnowledgeForPrompt(knowledge, context);
            }

            // Combine with original prompt
            const enhancedPrompt = {
                original: prompt,
                systemContext: formattedContext,
                combined: `${formattedContext}\n\n${prompt}`
            };

            return enhancedPrompt;
        } catch (error) {
            console.error("[AIContextManager] Error enhancing prompt:", error);
            // Return original prompt on error
            return {
                original: prompt,
                systemContext: '',
                combined: prompt,
                error: error.message
            };
        }
    }

    /**
     * Handle TIES messages for AI Context Management
     * @param {Object} messageEnvelope - TIES message envelope
     * @returns {Promise<Object>} - Response in TIES envelope format
     */
    async handleTIESMessage(messageEnvelope) {
        try {
            const { enum: messageType } = messageEnvelope.raw.required;
            const { content } = messageEnvelope.raw.ad_hoc.message;
            const metadata = messageEnvelope.raw.ad_hoc.message.metadata;

            // Handle different message types
            if (messageType === 'CONTEXT_REQUEST') {
                // Process context request
                const prompt = content.prompt;
                const contextOptions = content.options || {};

                const enhancedPrompt = await this.enhancePrompt(prompt, contextOptions);

                // Create response envelope
                return this._createTIESResponse(
                    'CONTEXT_RESPONSE',
                    {
                        success: true,
                        enhancedPrompt,
                        contextMode: this._state.contextMode
                    },
                    messageEnvelope
                );
            }

            // Handle admin knowledge request
            if (messageType === 'ADMIN_KNOWLEDGE_REQUEST') {
                const adminKnowledge = await this.getAdminKnowledge();

                return this._createTIESResponse(
                    'ADMIN_KNOWLEDGE_RESPONSE',
                    {
                        success: true,
                        adminKnowledge
                    },
                    messageEnvelope
                );
            }

            // If message type not recognized
            return this._createTIESResponse(
                'ERROR_RESPONSE',
                {
                    success: false,
                    error: `Unsupported message type: ${messageType}`
                },
                messageEnvelope
            );
        } catch (error) {
            console.error('[AIContextManager] Error handling TIES message:', error);

            // Return error response
            return this._createTIESResponse(
                'ERROR_RESPONSE',
                {
                    success: false,
                    error: error.message
                },
                messageEnvelope
            );
        }
    }

    /**
     * Create a TIES response envelope
     * @private
     * @param {string} responseType - Type of response
     * @param {Object} content - Response content
     * @param {Object} originalMessage - Original message envelope
     * @returns {Object} - TIES response envelope
     */
    _createTIESResponse(responseType, content, originalMessage) {
        return {
            raw: {
                required: {
                    target: originalMessage.raw.required.source,
                    id: `resp_${originalMessage.raw.required.id}`,
                    enum: responseType,
                    source: 'ai_intelligence_manager'
                },
                ad_hoc: {
                    message: {
                        content,
                        metadata: {
                            required: {
                                timestamp: Date.now(),
                                messageType: responseType,
                                messageId: `resp_${originalMessage.raw.required.id}`,
                                role: 'system',
                                status: 'completed',
                                conversationId: originalMessage.raw.ad_hoc.message.metadata.required.conversationId,
                                source: 'ai_intelligence_manager'
                            },
                            ad_hoc: {
                                processing: {
                                    processedAt: Date.now(),
                                    processingTime: Date.now() - originalMessage.raw.ad_hoc.message.metadata.required.timestamp,
                                    updatedAt: Date.now()
                                }
                            }
                        }
                    }
                }
            }
        };
    }

    /**
     * Get system status for monitoring
     * @returns {Object} - System status information
     */
    getSystemStatus() {
        return {
            state: this._state,
            specialists: {
                userContext: !!this.userContextSpecialist,
                knowledgeContext: !!this.knowledgeContextSpecialist
            },
            filesystemAvailable: typeof window !== 'undefined' && !!window.fs && !!window.fs.readFile,
            uptime: Date.now() - this._state.startTime,
            knowledgeFiles: {
                both: Object.keys(this._knowledgeFiles.both).length,
                admin: Object.keys(this._knowledgeFiles.admin).length,
                user: Object.keys(this._knowledgeFiles.user).length
            }
        };
    }

    /**
     * Clean up resources
     */
    cleanup() {
        if (this.userContextSpecialist) {
            this.userContextSpecialist.clearCache();
        }

        if (this.knowledgeContextSpecialist) {
            this.knowledgeContextSpecialist.clearCache();
        }

        this._knowledgeFiles = {
            both: {},
            admin: {},
            user: {}
        };

        console.log("[AIContextManager] Resources released");
    }
}

export default AIContextManager;