// Scroll_GeneralWebpages_Templates.jsx
// High-performance scroll tracking and animation system for general webpages

import { useState, useEffect, useRef, useMemo, useCallback } from "react";

export const Scroll_GeneralWebpages_Templates = ({
                                                     smoothingFactor = 0.1,
                                                     enableParallax = true,
                                                     scrollAnimations = true,
                                                     thresholds = [0.1, 0.25, 0.5, 0.75, 0.9],
                                                     debug = false
                                                 }) => {
    // Public states
    const [scrollInfo, setScrollInfo] = useState({
        progress: 0,               // Overall scroll progress (0-1)
        direction: "none",         // Current scroll direction
        isScrolling: false,        // Whether user is actively scrolling
        thresholdsPassed: {},      // Which thresholds have been crossed
        sections: {},              // Section visibility information
        velocity: 0                // Normalized scroll velocity
    });

    // Internal state stored in ref to prevent re-renders
    const scrollState = useRef({
        // Current values
        rawScrollY: 0,
        smoothScrollY: 0,
        lastScrollY: 0,
        scrollHeight: 0,
        clientHeight: 0,
        maxScroll: 0,

        // Timing and animation
        scrollVelocity: 0,
        lastScrollTime: 0,
        scrollTimeout: null,
        rafId: null,
        lastFrame: 0,
        velocityHistory: [],

        // Performance optimization
        isLowPowerDevice: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
            (navigator.hardwareConcurrency && navigator.hardwareConcurrency <= 4),
        reducedMotion: typeof window !== 'undefined' ?
            window.matchMedia?.('(prefers-reduced-motion: reduce)')?.matches : false,
        hasIntersectionObserver: typeof IntersectionObserver !== 'undefined',

        // Tracking
        updateCount: 0,
        sectionObserver: null,
        trackedSections: new Map(),
        thresholdStates: {},

        // Flags
        isInitialized: false
    });

    // Calculate scroll progress with enhanced accuracy
    const calculateScrollProgress = useCallback(() => {
        const state = scrollState.current;

        // Extract necessary values from state
        const {
            scrollHeight,
            clientHeight,
            rawScrollY,
        } = state;

        // Basic validation
        if (scrollHeight <= clientHeight) {
            return 0; // No scrollable cmd
        }

        // Calculate maximum scroll position with boundary protection
        const maxScroll = Math.max(0, scrollHeight - clientHeight);
        state.maxScroll = maxScroll;

        // Calculate linear progress from 0-1
        const linearProgress = Math.max(0, Math.min(1, rawScrollY / maxScroll));

        return linearProgress;
    }, []);

    // Update thresholds with optimized processing
    const updateThresholds = useCallback((progress) => {
        const state = scrollState.current;
        const currentStates = {...state.thresholdStates};
        let hasChanged = false;
        let changedThresholds = [];

        // Track scroll direction
        const direction = progress > scrollInfo.progress ? 'down' :
            progress < scrollInfo.progress ? 'up' : 'none';

        // Check each threshold and track crossing
        thresholds.forEach(threshold => {
            const id = `t${threshold.toFixed(2)}`;
            const oldState = currentStates[id] || false;
            const newState = progress >= threshold;

            // Detect state change
            if (oldState !== newState) {
                currentStates[id] = newState;
                hasChanged = true;

                // Record the change with metadata
                changedThresholds.push({
                    id,
                    threshold,
                    crossed: newState,
                    direction,
                    progress,
                    timestamp: performance.now()
                });
            }
        });

        // Save current states
        state.thresholdStates = currentStates;

        // Update global state if thresholds changed
        if (hasChanged) {
            // Add convenience groupings
            const enhancedStates = {
                ...currentStates,
                _direction: direction,
                _timestamp: performance.now(),
                _changed: changedThresholds.map(event => event.id)
            };

            // Update UI state
            setScrollInfo(prev => ({
                ...prev,
                thresholdsPassed: enhancedStates,
                thresholdEvents: changedThresholds
            }));
        }

        return {
            hasChanged,
            changedThresholds,
            allThresholds: currentStates,
            direction
        };
    }, [scrollInfo.progress, thresholds]);

    // Set up intersection observers for section tracking
    const setupIntersectionObserver = useCallback(() => {
        const state = scrollState.current;

        // Clear any existing trackers
        if (state.sectionObserver) {
            state.sectionObserver.disconnect();
        }

        // Configure observer based on device capability
        const observerThresholds = state.isLowPowerDevice ?
            [0, 0.25, 0.5, 0.75, 1.0] :
            [0, 0.1, 0.25, 0.5, 0.75, 0.9, 1.0];

        const rootMargin = "10% 0px 10% 0px";

        // Create the observer
        state.sectionObserver = new IntersectionObserver((entries) => {
            const updatedSections = {};

            entries.forEach(entry => {
                const element = entry.target;
                const id = element.id || `section-${element.dataset.scrollIndex}`;

                if (!id) return;

                // Update section data
                updatedSections[id] = {
                    visible: entry.isIntersecting,
                    ratio: entry.intersectionRatio,
                    position: entry.boundingClientRect.top < 0 ? 'above' : 'below',
                    element: entry.target
                };

                // Update element data attributes and classes
                element.dataset.inView = entry.isIntersecting ? "true" : "false";
                element.style.setProperty('--scroll-ratio', entry.intersectionRatio);

                if (entry.isIntersecting) {
                    element.classList.add('in-viewport');
                    element.classList.remove('out-of-viewport');
                } else {
                    element.classList.add('out-of-viewport');
                    element.classList.remove('in-viewport');
                }
            });

            // Update global state
            if (Object.keys(updatedSections).length > 0) {
                setScrollInfo(prev => ({
                    ...prev,
                    sections: {
                        ...prev.sections,
                        ...updatedSections
                    }
                }));
            }
        }, {
            threshold: observerThresholds,
            rootMargin
        });

        // Observe all currently registered steps
        state.trackedSections.forEach(section => {
            state.sectionObserver.observe(section);
        });

        return {
            observer: state.sectionObserver,
            refresh: setupIntersectionObserver
        };
    }, []);

    // Register a section for visibility tracking
    const registerSection = useCallback((element) => {
        const state = scrollState.current;

        // Skip if no element or observer
        if (!element || !state.sectionObserver) return null;

        // Validate if element is a DOM element
        if (!(element instanceof Element)) {
            console.error('Invalid element passed to registerSection:', element);
            return null;
        }

        // Apply section attribute if needed
        if (!element.dataset.scrollSection) {
            element.dataset.scrollSection = "true";
        }

        // Generate unique index if needed
        if (!element.id && !element.dataset.scrollIndex) {
            element.dataset.scrollIndex = state.trackedSections.size;
        }

        // Get section ID
        const id = element.id || `section-${element.dataset.scrollIndex}`;

        // Skip if already registered
        if (state.trackedSections.has(id)) {
            return id;
        }

        // Register with observer
        state.sectionObserver.observe(element);

        // Store in tracked steps
        state.trackedSections.set(id, element);

        // Initialize section state
        if (!scrollInfo.sections[id]) {
            setScrollInfo(prev => ({
                ...prev,
                sections: {
                    ...prev.sections,
                    [id]: {
                        element,
                        visible: false,
                        ratio: 0,
                        position: 'unknown'
                    }
                }
            }));
        }

        // Apply initial classes and attributes
        element.classList.add('scroll-section');
        element.classList.add('out-of-viewport');
        element.dataset.inView = "false";

        return id;
    }, [scrollInfo.sections]);

    // Unregister a section from visibility tracking
    const unregisterSection = useCallback((element) => {
        const state = scrollState.current;

        if (!element) return false;

        // Get section ID
        const id = element.id || element.dataset.scrollIndex && `section-${element.dataset.scrollIndex}`;

        if (!id || !state.trackedSections.has(id)) return false;

        // Unobserve section
        if (state.sectionObserver) {
            state.sectionObserver.unobserve(element);
        }

        // Remove from tracked steps
        state.trackedSections.delete(id);

        // Remove from steps state
        setScrollInfo(prev => {
            const sections = {...prev.sections};
            delete sections[id];
            return {
                ...prev,
                sections
            };
        });

        return true;
    }, []);

    // Scroll to element or position with advanced options
    const scrollTo = useCallback((target, options = {}) => {
        // Default options
        const defaultOptions = {
            offset: 0,
            duration: 1000,
            easing: 'easeInOutCubic',
            callback: null
        };

        // Merge options
        const mergedOptions = {...defaultOptions, ...options};

        // Resolve target element
        let targetElement = null;
        let targetPosition = 0;

        if (typeof target === 'number') {
            // Direct pixel position
            targetPosition = target;
        } else if (typeof target === 'string') {
            // CSS selector
            targetElement = document.querySelector(target);

            if (!targetElement) {
                console.error(`Scroll target not found: ${target}`);
                return false;
            }

            // Get position
            const rect = targetElement.getBoundingClientRect();
            targetPosition = window.pageYOffset + rect.top + mergedOptions.offset;
        } else if (target instanceof Element) {
            // Element reference
            targetElement = target;

            // Get position
            const rect = targetElement.getBoundingClientRect();
            targetPosition = window.pageYOffset + rect.top + mergedOptions.offset;
        } else {
            console.error('Invalid scroll target', target);
            return false;
        }

        // Ensure position is within bounds
        const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
        targetPosition = Math.max(0, Math.min(maxScroll, targetPosition));

        // Execute scroll with animation
        const startPosition = window.pageYOffset;
        const distance = targetPosition - startPosition;

        // If distance is very small, just jump
        if (Math.abs(distance) < 3) {
            window.scrollTo(0, targetPosition);

            if (mergedOptions.callback) {
                mergedOptions.callback();
            }

            return true;
        }

        // Animation easing functions
        const easings = {
            linear: t => t,
            easeInQuad: t => t * t,
            easeOutQuad: t => t * (2 - t),
            easeInOutQuad: t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
            easeInCubic: t => t * t * t,
            easeOutCubic: t => (--t) * t * t + 1,
            easeInOutCubic: t => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
        };

        // Get easing function
        const ease = easings[mergedOptions.easing] || easings.easeInOutCubic;

        // Start time
        const startTime = performance.now();

        // Animation function
        const animateScroll = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / mergedOptions.duration, 1);
            const easedProgress = ease(progress);

            window.scrollTo(0, startPosition + distance * easedProgress);

            if (progress < 1) {
                requestAnimationFrame(animateScroll);
            } else {
                if (mergedOptions.callback) {
                    mergedOptions.callback();
                }
            }
        };

        // Start animation
        requestAnimationFrame(animateScroll);

        return true;
    }, []);

    // Initialize scroll tracking
    useEffect(() => {
        const state = scrollState.current;

        if (state.isInitialized) return;

        // Initial scroll measurements
        state.rawScrollY = window.pageYOffset;
        state.smoothScrollY = window.pageYOffset;
        state.lastScrollY = window.pageYOffset;
        state.scrollHeight = document.documentElement.scrollHeight;
        state.clientHeight = window.innerHeight;
        state.maxScroll = Math.max(0, state.scrollHeight - state.clientHeight);
        state.lastScrollTime = performance.now();

        // Initialize threshold states
        thresholds.forEach(threshold => {
            const id = `t${threshold.toFixed(2)}`;
            state.thresholdStates[id] = state.rawScrollY / state.maxScroll >= threshold;
        });

        // Set up section observer
        if (state.hasIntersectionObserver) {
            setupIntersectionObserver();
        }
// Replace the current handleScroll function in your useEffect with this optimized version:
        const handleScroll = () => {
            const now = performance.now();

            // Skip processing if less than 16ms elapsed (60fps target)
            if (now - state.lastScrollTime < 16 && !state.forcedUpdate) {
                return;
            }

            // Only update raw scroll value (don't do calculations yet)
            state.rawScrollY = window.pageYOffset;
            state.isScrolling = true;

            // Defer expensive calculations to rAF
            if (!state.rafId) {
                state.rafId = requestAnimationFrame(() => {
                    // Calculate scroll velocity
                    const timeDelta = now - state.lastScrollTime;

                    if (timeDelta > 0) {
                        // Calculate raw velocity (pixels per second)
                        const scrollDelta = state.rawScrollY - state.lastScrollY;
                        const rawVelocity = scrollDelta / timeDelta * 1000;

                        // Store in velocity history for smoothing
                        state.velocityHistory.push(rawVelocity);
                        if (state.velocityHistory.length > 3) {
                            state.velocityHistory.shift();
                        }

                        // Get smoothed velocity
                        let smoothedVelocity = state.velocityHistory.reduce((a, b) => a + b, 0) /
                            state.velocityHistory.length;

                        // Normalize velocity to value between -1 and 1
                        state.scrollVelocity = Math.sign(smoothedVelocity) *
                            Math.min(1, Math.abs(smoothedVelocity) / 2000);
                    }

                    // Apply smooth scrolling with higher performance
                    if (smoothingFactor > 0) {
                        const delta = state.rawScrollY - state.smoothScrollY;
                        state.smoothScrollY += delta * (state.isLowPowerDevice ? smoothingFactor * 1.5 : smoothingFactor);
                    } else {
                        state.smoothScrollY = state.rawScrollY;
                    }

                    // Update state
                    state.lastScrollY = state.rawScrollY;
                    state.lastScrollTime = now;
                    state.rafId = null;
                    state.forcedUpdate = false;

                    // Update thresholds and other states
                    const progress = calculateScrollProgress();
                    updateThresholds(progress);

                    // Update UI state with minimal props
                    setScrollInfo(prev => {
                        const direction = progress > prev.progress ? 'down' :
                            progress < prev.progress ? 'up' : prev.direction;

                        return {
                            ...prev,
                            progress,
                            direction,
                            isScrolling: true,
                            velocity: state.scrollVelocity
                        };
                    });
                });
            }

            // Clear previous timeout
            if (state.scrollTimeout) {
                clearTimeout(state.scrollTimeout);
            }

            // Set timeout to detect scroll end
            state.scrollTimeout = setTimeout(() => {
                state.isScrolling = false;

                // Update UI
                setScrollInfo(prev => ({
                    ...prev,
                    isScrolling: false
                }));
            }, 100);
        };

// Add passive flag to scroll listener
        window.addEventListener('scroll', handleScroll, { passive: true });





        // Animation frame update function
        const updateScrollInfo = () => {
            state.rafId = null;
            state.updateCount++;

            // Apply smooth scrolling if needed
            if (smoothingFactor > 0) {
                const delta = state.rawScrollY - state.smoothScrollY;
                state.smoothScrollY += delta * smoothingFactor;
            } else {
                state.smoothScrollY = state.rawScrollY;
            }

            // Calculate scroll progress
            const progress = calculateScrollProgress();

            // Update thresholds
            const thresholdResult = updateThresholds(progress);

            // Update scroll direction
            const direction = progress > scrollInfo.progress ? 'down' :
                progress < scrollInfo.progress ? 'up' : scrollInfo.direction;

            // Update UI state
            setScrollInfo(prev => {
                // Only update changed values to prevent unnecessary renders
                const changes = {};

                if (progress !== prev.progress) changes.progress = progress;
                if (direction !== prev.direction) changes.direction = direction;
                if (state.isScrolling !== prev.isScrolling) changes.isScrolling = state.isScrolling;
                if (state.scrollVelocity !== prev.velocity) changes.velocity = state.scrollVelocity;

                // Skip update if nothing changed
                if (Object.keys(changes).length === 0) return prev;

                return {
                    ...prev,
                    ...changes
                };
            });

            // Schedule next update if still scrolling
            if (state.isScrolling) {
                state.rafId = requestAnimationFrame(updateScrollInfo);
            }
        };

        // Handle window resize
        const handleResize = () => {
            // Update dimensions
            state.scrollHeight = document.documentElement.scrollHeight;
            state.clientHeight = window.innerHeight;
            state.maxScroll = Math.max(0, state.scrollHeight - state.clientHeight);

            // Force update on significant dimension changes
            updateScrollInfo();
        };

        // Set up event listeners
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('resize', handleResize, { passive: true });

        // Trigger initial update
        handleScroll();

        // Mark as initialized
        state.isInitialized = true;

        // Cleanup function
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);

            if (state.rafId) {
                cancelAnimationFrame(state.rafId);
            }

            if (state.scrollTimeout) {
                clearTimeout(state.scrollTimeout);
            }

            if (state.sectionObserver) {
                state.sectionObserver.disconnect();
            }
        };
    }, [calculateScrollProgress, scrollInfo.direction, scrollInfo.progress, setupIntersectionObserver, smoothingFactor, thresholds, updateThresholds]);

    // Return public API
    return {
        // Scroll state
        progress: scrollInfo.progress,
        direction: scrollInfo.direction,
        isScrolling: scrollInfo.isScrolling,
        velocity: scrollInfo.velocity,
        thresholdsPassed: scrollInfo.thresholdsPassed,
        sections: scrollInfo.sections,

        // Methods
        registerSection,
        unregisterSection,
        scrollTo,

        // Additional utils
        setupIntersectionObserver,

        // Mouse position for parallax effects (if enabled)
        mousePosition: useMemo(() => ({ x: 0, y: 0 }), []),
        mouseRotation: useMemo(() => ({ x: 0, y: 0 }), []),
        parallaxLayers: useMemo(() => [], [])
    };
};

export default Scroll_GeneralWebpages_Templates;