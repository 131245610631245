import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Keyboard, X, Info, Settings, Search, Save, Command, Zap, Coffee, Edit,
    RotateCcw, ArrowRight, Maximize, Minimize, LayoutGrid, ChevronDown, ChevronUp,
    Eye, EyeOff, Sliders, PanelLeft, PanelRight, Type, AlertTriangle
} from 'lucide-react';
import styles from './KeyboardShortcuts_Specialists_Writing.module.css';

/**
 * KeyboardShortcuts_Specialists_Writing Component
 *
 * A comprehensive component for displaying and managing keyboard shortcuts
 * in the Taytrom Writing Platform. This component follows Taytrom's Enhanced CSS System
 * with dimensional design, microinteractions, and the dual-mode theming system.
 *
 * Features:
 * - Displays all available keyboard shortcuts in a categorized grid
 * - Allows filtering and searching for shortcuts
 * - Provides visual key combination references with 3D effects
 * - Supports customization of shortcuts with enhanced UI feedback
 * - Fully integrates with the application's theme system
 * - Implements sophisticated animations and interactions
 */
const KeyboardShortcuts_Specialists_Writing = ({
                                                   allowCustomization = false,
                                                   isDark = false,
                                                   onShortcutTriggered = () => {},
                                                   onShortcutChanged = () => {},
                                                   className = "",
                                                   orchestrator = null
                                               }) => {
    // UI state
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState("");
    const [activeCategory, setActiveCategory] = useState("All");
    const [customizing, setCustomizing] = useState(false);
    const [showTips, setShowTips] = useState(true);
    const [isSearchFocused, setIsSearchFocused] = useState(false);

    // References
    const shortcutsRef = useRef(null);
    const searchInputRef = useRef(null);
    const modalRef = useRef(null);

    // Define all shortcuts by category
    const shortcutCategories = [
        {
            name: "General",
            shortcuts: [
                { name: "Open keyboard shortcuts", keys: ["Ctrl", "K"], description: "Open this shortcuts panel" },
                { name: "Toggle fullscreen", keys: ["F11"], description: "Enter or exit fullscreen mode" },
                { name: "Save document-panel", keys: ["Ctrl", "S"], description: "Save current document-panel" },
                { name: "Print document-panel", keys: ["Ctrl", "P"], description: "Print current document-panel" },
            ]
        },
        {
            name: "Navigation",
            shortcuts: [
                { name: "Zoom in", keys: ["Ctrl", "+"], description: "Increase zoom level" },
                { name: "Zoom out", keys: ["Ctrl", "-"], description: "Decrease zoom level" },
                { name: "Reset zoom", keys: ["Ctrl", "0"], description: "Reset to default zoom level" },
                { name: "Toggle style panel", keys: ["Ctrl", "Shift", "S"], description: "Show or hide style panel" },
                { name: "Toggle analytics", keys: ["Ctrl", "Shift", "A"], description: "Show or hide analytics panel" },
                { name: "Focus document-panel", keys: ["Ctrl", "E"], description: "Focus on document-panel editor" },
            ]
        },
        {
            name: "Document Editing",
            shortcuts: [
                { name: "Undo", keys: ["Ctrl", "Z"], description: "Undo last action" },
                { name: "Redo", keys: ["Ctrl", "Shift", "Z"], description: "Redo last undone action" },
                { name: "Alternative redo", keys: ["Ctrl", "Y"], description: "Alternative shortcut for redo" },
                { name: "Cut", keys: ["Ctrl", "X"], description: "Cut selected text" },
                { name: "Copy", keys: ["Ctrl", "C"], description: "Copy selected text" },
                { name: "Paste", keys: ["Ctrl", "V"], description: "Paste from clipboard" },
                { name: "Select all", keys: ["Ctrl", "A"], description: "Select all content" },
                { name: "Find in document-panel", keys: ["Ctrl", "F"], description: "Search within document-panel" },
                { name: "Replace text", keys: ["Ctrl", "H"], description: "Find and replace text" },
            ]
        },
        {
            name: "Document Structure",
            shortcuts: [
                { name: "Add section", keys: ["Alt", "N"], description: "Add a new document-panel section" },
                { name: "Delete section", keys: ["Alt", "Delete"], description: "Delete selected section" },
                { name: "Move section up", keys: ["Alt", "↑"], description: "Move section up in document-panel" },
                { name: "Move section down", keys: ["Alt", "↓"], description: "Move section down in document-panel" },
                { name: "Add child section", keys: ["Alt", "Shift", "N"], description: "Add child section" },
                { name: "Expand bullet point", keys: ["Alt", "→"], description: "Expand a bullet point with AI" },
                { name: "Collapse section", keys: ["Alt", "←"], description: "Collapse section content" },
            ]
        },
        {
            name: "AI Features",
            shortcuts: [
                { name: "Generate suggestion", keys: ["N"], description: "Generate a new AI suggestion" },
                { name: "Toggle AI assistant", keys: ["Ctrl", "Shift", "A"], description: "Show or hide AI assistant" },
                { name: "Accept suggestion", keys: ["Ctrl", "Enter"], description: "Apply the selected AI suggestion" },
                { name: "Toggle grid view", keys: ["G"], description: "Switch between grid and spatial views" },
                { name: "Collapse expanded", keys: ["Escape"], description: "Collapse currently expanded item" },
                { name: "Request AI improvements", keys: ["Alt", "I"], description: "Ask AI to improve current section" },
            ]
        },
        {
            name: "Style Engine",
            shortcuts: [
                { name: "Increase formality", keys: ["Alt", "F", "↑"], description: "Increase formal tone" },
                { name: "Decrease formality", keys: ["Alt", "F", "↓"], description: "Decrease formal tone" },
                { name: "Increase technicality", keys: ["Alt", "T", "↑"], description: "Increase technical language" },
                { name: "Decrease technicality", keys: ["Alt", "T", "↓"], description: "Decrease technical language" },
                { name: "Apply professional style", keys: ["Alt", "P"], description: "Switch to professional style preset" },
                { name: "Apply conversational style", keys: ["Alt", "C"], description: "Switch to conversational style preset" },
                { name: "Reset styles", keys: ["Alt", "R"], description: "Reset all style settings to default" },
            ]
        },
        {
            name: "Collaboration",
            shortcuts: [
                { name: "Add comment", keys: ["Ctrl", "Alt", "M"], description: "Add comment at cursor position" },
                { name: "View all comments", keys: ["Ctrl", "Alt", "C"], description: "Show all document-panel comments" },
                { name: "Share document-panel", keys: ["Ctrl", "Alt", "S"], description: "Open sharing options" },
                { name: "Resolve comment", keys: ["Ctrl", "Alt", "R"], description: "Resolve selected comment" },
            ]
        },
        {
            name: "Visualization",
            shortcuts: [
                { name: "Reset view", keys: ["Ctrl", "Home"], description: "Reset visualization view" },
                { name: "Toggle legend", keys: ["Alt", "L"], description: "Show/hide visualization legend" },
                { name: "Pan view", keys: ["Space", "+ Drag"], description: "Pan the visualization view" },
                { name: "Fit to view", keys: ["Ctrl", "1"], description: "Fit visualization to window" },
                { name: "Enter edit mode", keys: ["Alt", "E"], description: "Edit visualization nodes" },
            ]
        }
    ];

    // Filtered shortcuts based on search and active category
    const filteredShortcuts = shortcutCategories
        .filter(category => activeCategory === "All" || category.name === activeCategory)
        .flatMap(category =>
            category.shortcuts.filter(shortcut =>
                filter === "" ||
                shortcut.name.toLowerCase().includes(filter.toLowerCase()) ||
                shortcut.description.toLowerCase().includes(filter.toLowerCase())
            ).map(shortcut => ({
                ...shortcut,
                category: category.name
            }))
        );

    // Animation effect when opening the modal
    useEffect(() => {
        if (isOpen && modalRef.current) {
            // Apply focus to search input after animation completes
            const timer = setTimeout(() => {
                searchInputRef.current?.focus();
            }, 300);

            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    // Handle global keyboard events
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Toggle shortcuts panel with Ctrl+K
            if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'k') {
                e.preventDefault();
                setIsOpen(prev => !prev);
                return;
            }

            // Close shortcuts panel with Escape
            if (e.key === 'Escape' && isOpen) {
                e.preventDefault();
                setIsOpen(false);
                return;
            }

            // Handle other keyboard shortcuts when panel is closed
            if (!isOpen && !customizing) {
                // Pass through to orchestrator if available
                if (orchestrator) {
                    orchestrator.messageRelay({
                        type: 'KEYBOARD_EVENT',
                        from: 'KEYBOARD_SHORTCUTS',
                        to: 'GLOBAL',
                        event: 'KEY_DOWN',
                        payload: {
                            key: e.key,
                            ctrlKey: e.ctrlKey,
                            shiftKey: e.shiftKey,
                            altKey: e.altKey,
                            metaKey: e.metaKey,
                            code: e.code
                        }
                    });
                }

                // Call the onShortcutTriggered callback
                onShortcutTriggered(e);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isOpen, customizing, onShortcutTriggered, orchestrator]);

    // Close when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (shortcutsRef.current && !shortcutsRef.current.contains(e.target) && isOpen) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    // Save custom shortcuts with enhanced feedback
    const saveCustomShortcuts = useCallback(() => {
        // Logic to save customized shortcuts would go here
        setCustomizing(false);

        // Notify parent component about changes
        onShortcutChanged(shortcutCategories);

        // Show success notification - in real implementation
    }, [onShortcutChanged, shortcutCategories]);

    // Handle customization of a specific shortcut
    const handleCustomizeShortcut = useCallback((shortcut) => {
        // In a real implementation, this would open a modal or interface
        // to record new key combinations
        console.log("Customizing shortcut:", shortcut.name);

        // Example implementation might show a modal that captures keypress events
        // and updates the shortcut accordingly
    }, []);

    // Handle search clear with animation
    const handleClearSearch = () => {
        // Add focus back to search input after clearing
        setFilter("");
        searchInputRef.current?.focus();
    };

    // Handle search focus state
    const handleSearchFocus = (focused) => {
        setIsSearchFocused(focused);
    };

    // Keyboard key component - visualizes a single key with enhanced 3D effect
    const KeyCap = ({ keyName }) => (
        <div className={styles['taytrom-keyboardShortcuts-keyCap']}>
            {keyName}
        </div>
    );

    // Category icon selector - returns appropriate icon for category
    const getCategoryIcon = (categoryName) => {
        switch(categoryName) {
            case "General": return <Command size={18} />;
            case "Navigation": return <Maximize size={18} />;
            case "Document Editing": return <Edit size={18} />;
            case "Document Structure": return <Type size={18} />;
            case "AI Features": return <Zap size={18} />;
            case "Style Engine": return <Sliders size={18} />;
            case "Collaboration": return <PanelRight size={18} />;
            case "Visualization": return <LayoutGrid size={18} />;
            default: return <Info size={18} />;
        }
    };

    return (
        <div className={styles['taytrom-keyboardShortcuts']}>
            {/* Keyboard Shortcuts Button with ripple effect */}
            <button
                onClick={() => setIsOpen(true)}
                className={`${styles['taytrom-keyboardShortcuts-button']} ${className}`}
                aria-label="Keyboard Shortcuts"
                title="Keyboard Shortcuts (Ctrl+K)"
            >
                <Keyboard size={18} />
            </button>

            {/* Keyboard Shortcuts Modal with enhanced animations */}
            {isOpen && (
                <div className={styles['taytrom-keyboardShortcuts-overlay']}>
                    <div
                        ref={(el) => {
                            shortcutsRef.current = el;
                            modalRef.current = el;
                        }}
                        className={styles['taytrom-keyboardShortcuts-modal']}
                        role="dialog"
                        aria-labelledby="keyboard-shortcuts-title"
                    >
                        {/* Header with gradient styling */}
                        <div className={styles['taytrom-keyboardShortcuts-header']}>
                            <div className={styles['taytrom-keyboardShortcuts-headerTitle']}>
                                <Keyboard size={20} color="#20b2aa" />
                                <h2 id="keyboard-shortcuts-title">Keyboard Shortcuts</h2>
                            </div>

                            <div className={styles['taytrom-keyboardShortcuts-headerActions']}>
                                {allowCustomization && (
                                    <button
                                        onClick={() => setCustomizing(!customizing)}
                                        className={customizing
                                            ? styles['taytrom-keyboardShortcuts-customizeBtn--active']
                                            : styles['taytrom-keyboardShortcuts-customizeBtn--inactive']
                                        }
                                        aria-pressed={customizing}
                                    >
                                        <Settings size={16} />
                                        <span>{customizing ? 'Done' : 'Customize'}</span>
                                    </button>
                                )}

                                <button
                                    onClick={() => setIsOpen(false)}
                                    className={styles['taytrom-keyboardShortcuts-closeBtn']}
                                    aria-label="Close shortcuts panel"
                                >
                                    <X size={20} />
                                </button>
                            </div>
                        </div>

                        {/* Search and Categories with enhanced interaction */}
                        <div className={styles['taytrom-keyboardShortcuts-controls']}>
                            {/* Search with focus effects */}
                            <div className={styles['taytrom-keyboardShortcuts-searchContainer']}>
                                <input
                                    ref={searchInputRef}
                                    type="text"
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                    onFocus={() => handleSearchFocus(true)}
                                    onBlur={() => handleSearchFocus(false)}
                                    placeholder="Search shortcuts..."
                                    className={styles['taytrom-keyboardShortcuts-searchInput']}
                                    aria-label="Search shortcuts"
                                />
                                <Search
                                    size={18}
                                    className={styles['taytrom-keyboardShortcuts-searchIcon']}
                                />
                                {filter && (
                                    <button
                                        onClick={handleClearSearch}
                                        className={styles['taytrom-keyboardShortcuts-clearSearch']}
                                        aria-label="Clear search"
                                    >
                                        <X size={16} />
                                    </button>
                                )}
                            </div>

                            {/* Categories with scroll mask */}
                            <div className={`${styles['taytrom-keyboardShortcuts-categories']} ${styles['taytrom-keyboardShortcuts-scrollbar']}`}>
                                <button
                                    onClick={() => setActiveCategory("All")}
                                    className={
                                        activeCategory === "All"
                                            ? styles['taytrom-keyboardShortcuts-categoryBtn--active']
                                            : styles['taytrom-keyboardShortcuts-categoryBtn--inactive']
                                    }
                                    aria-pressed={activeCategory === "All"}
                                >
                                    <Command size={14} />
                                    <span>All Shortcuts</span>
                                </button>

                                {shortcutCategories.map(category => (
                                    <button
                                        key={category.name}
                                        onClick={() => setActiveCategory(category.name)}
                                        className={
                                            activeCategory === category.name
                                                ? styles['taytrom-keyboardShortcuts-categoryBtn--active']
                                                : styles['taytrom-keyboardShortcuts-categoryBtn--inactive']
                                        }
                                        aria-pressed={activeCategory === category.name}
                                    >
                                        {getCategoryIcon(category.name)}
                                        <span>{category.name}</span>
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Shortcut List with staggered animation */}
                        <div className={`${styles['taytrom-keyboardShortcuts-content']} ${styles['taytrom-keyboardShortcuts-scrollbar']}`}>
                            {filteredShortcuts.length === 0 ? (
                                <div className={styles['taytrom-keyboardShortcuts-emptyState']}>
                                    <AlertTriangle size={32} className={styles['taytrom-keyboardShortcuts-emptyIcon']} />
                                    <p className={styles['taytrom-keyboardShortcuts-emptyText']}>
                                        No shortcuts found matching "{filter}"
                                    </p>
                                    <button
                                        onClick={handleClearSearch}
                                        className={styles['taytrom-keyboardShortcuts-clearBtn']}
                                    >
                                        Clear search
                                    </button>
                                </div>
                            ) : (
                                <div className={styles['taytrom-keyboardShortcuts-grid']}>
                                    {filteredShortcuts.map((shortcut, index) => (
                                        <div
                                            key={`${shortcut.category}-${shortcut.name}-${index}`}
                                            className={styles['taytrom-keyboardShortcuts-item']}
                                        >
                                            <div className={styles['taytrom-keyboardShortcuts-itemContent']}>
                                                <div className={styles['taytrom-keyboardShortcuts-itemHeader']}>
                                                    <span className={styles['taytrom-keyboardShortcuts-itemTitle']}>
                                                        {shortcut.name}
                                                    </span>

                                                    {activeCategory === "All" && (
                                                        <span className={styles['taytrom-keyboardShortcuts-itemCategory']}>
                                                            {shortcut.category}
                                                        </span>
                                                    )}
                                                </div>

                                                <div className={styles['taytrom-keyboardShortcuts-itemDescription']}>
                                                    {shortcut.description}
                                                </div>
                                            </div>

                                            <div className={styles['taytrom-keyboardShortcuts-itemKeys']}>
                                                {customizing ? (
                                                    <button
                                                        className={styles['taytrom-keyboardShortcuts-editBtn']}
                                                        onClick={() => handleCustomizeShortcut(shortcut)}
                                                        aria-label={`Edit shortcut for ${shortcut.name}`}
                                                    >
                                                        <Edit size={14} />
                                                        <span>Edit</span>
                                                    </button>
                                                ) : (
                                                    shortcut.keys.map((key, keyIndex) => (
                                                        <KeyCap key={`${key}-${keyIndex}`} keyName={key} />
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Tips section with hover effects */}
                        {!customizing && showTips && filter === "" && activeCategory === "All" && (
                            <div className={styles['taytrom-keyboardShortcuts-tips']}>
                                <div className={styles['taytrom-keyboardShortcuts-tipsHeader']}>
                                    <h3 className={styles['taytrom-keyboardShortcuts-tipsTitle']}>
                                        <Coffee size={14} color="#20b2aa" />
                                        <span>Keyboard Shortcut Tips</span>
                                    </h3>

                                    <button
                                        onClick={() => setShowTips(false)}
                                        className={styles['taytrom-keyboardShortcuts-hideBtn']}
                                        aria-label="Hide tips section"
                                    >
                                        <EyeOff size={14} />
                                        <span>Hide Tips</span>
                                    </button>
                                </div>

                                <div className={styles['taytrom-keyboardShortcuts-tipsGrid']}>
                                    <div className={styles['taytrom-keyboardShortcuts-tipItem']}>
                                        <KeyCap keyName="Ctrl" /> + <KeyCap keyName="K" />
                                        <span>to quickly open this shortcuts panel anytime</span>
                                    </div>

                                    <div className={styles['taytrom-keyboardShortcuts-tipItem']}>
                                        <KeyCap keyName="G" />
                                        <span>to toggle between grid and spatial views</span>
                                    </div>

                                    <div className={styles['taytrom-keyboardShortcuts-tipItem']}>
                                        <KeyCap keyName="Alt" /> + <KeyCap keyName="↑" /> / <KeyCap keyName="↓" />
                                        <span>to reorder document sections</span>
                                    </div>

                                    <div className={styles['taytrom-keyboardShortcuts-tipItem']}>
                                        <KeyCap keyName="N" />
                                        <span>to generate new AI suggestions</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Customization footer with enhanced button effects */}
                        {customizing && (
                            <div className={styles['taytrom-keyboardShortcuts-footer']}>
                                <button
                                    onClick={() => setCustomizing(false)}
                                    className={styles['taytrom-keyboardShortcuts-cancelBtn']}
                                >
                                    Cancel
                                </button>

                                <button
                                    onClick={saveCustomShortcuts}
                                    className={styles['taytrom-keyboardShortcuts-saveBtn']}
                                >
                                    <Save size={16} />
                                    <span>Save Changes</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default KeyboardShortcuts_Specialists_Writing;