/**
 * @file Specialist_User_Contexts_AI.js
 *
 * @description
 * Specialized context manager for user data in AI interactions.
 * This module centralizes and formats user settings, profile, and progress data
 * for consumption by the AIContextManager, enhancing AI prompts with personalized
 * contextual information about the user.
 *
 * Works in conjunction with AIContextManager.js to provide rich context for AI responses.
 */

/**
 * Class that manages user-specific contexts for AI interactions
 */
class UserContextSpecialist {
    constructor(orchestrator = null) {
        this._orchestrator = orchestrator;
        this._cachedContexts = new Map();
        this._lastRefreshTime = new Map();
        this._refreshInterval = 5 * 60 * 1000; // 5 minutes in milliseconds

        console.log('[UserContextSpecialist] Initializing');
    }

    /**
     * Get user profile context data
     *
     * @param {string} userId - User ID to get profile for
     * @returns {object} - Formatted profile context
     */
    async getUserProfileContext(userId) {
        try {
            if (!userId) return null;

            // Check cache first
            if (this._isCacheValid(userId, 'profile')) {
                return this._cachedContexts.get(userId).profile;
            }

            // Try to get from orchestrator
            if (this._orchestrator && this._orchestrator.authManager) {
                const userData = this._orchestrator.authManager.getCurrentUser();

                if (userData) {
                    // Format the profile data for AI context
                    const profileContext = this._formatProfileContext(userData);

                    // Cache the result
                    this._updateCache(userId, 'profile', profileContext);

                    return profileContext;
                }
            }

            // Fallback to default profile context
            return this._getDefaultProfileContext();
        } catch (error) {
            console.error('[UserContextSpecialist] Error getting user profile context:', error);
            return this._getDefaultProfileContext();
        }
    }

    /**
     * Get user settings context data
     *
     * @param {string} userId - User ID to get settings for
     * @returns {object} - Formatted settings context
     */
    async getUserSettingsContext(userId) {
        try {
            if (!userId) return null;

            // Check cache first
            if (this._isCacheValid(userId, 'settings')) {
                return this._cachedContexts.get(userId).settings;
            }

            // Try to get from orchestrator
            if (this._orchestrator && this._orchestrator.settingsManager) {
                const userSettings = this._orchestrator.settingsManager.getUserPreferences(userId);

                if (userSettings) {
                    // Format the settings data for AI context
                    const settingsContext = this._formatSettingsContext(userSettings);

                    // Cache the result
                    this._updateCache(userId, 'settings', settingsContext);

                    return settingsContext;
                }
            }

            // Fallback to default settings context
            return this._getDefaultSettingsContext();
        } catch (error) {
            console.error('[UserContextSpecialist] Error getting user settings context:', error);
            return this._getDefaultSettingsContext();
        }
    }

    /**
     * Get user progress context data
     *
     * @param {string} userId - User ID to get progress for
     * @returns {object} - Formatted progress context
     */
    async getUserProgressContext(userId) {
        try {
            if (!userId) return null;

            // Check cache first
            if (this._isCacheValid(userId, 'progress')) {
                return this._cachedContexts.get(userId).progress;
            }

            // Try to get from orchestrator
            if (this._orchestrator && this._orchestrator.progressManager) {
                const userProgress = this._orchestrator.progressManager.getUserProgress(userId);
                const userExpedition = await this._orchestrator.progressManager.getUserCurrentExpedition(userId);

                if (userProgress) {
                    // Format the progress data for AI context
                    const progressContext = this._formatProgressContext(userProgress, userExpedition);

                    // Cache the result
                    this._updateCache(userId, 'progress', progressContext);

                    return progressContext;
                }
            }

            // Fallback to default progress context
            return this._getDefaultProgressContext();
        } catch (error) {
            console.error('[UserContextSpecialist] Error getting user progress context:', error);
            return this._getDefaultProgressContext();
        }
    }

    /**
     * Get combined user contexts for AI
     *
     * @param {string} userId - User ID to get contexts for
     * @param {object} options - Options for context retrieval
     * @returns {object} - Combined user contexts
     */
    async getAllUserContexts(userId, options = {}) {
        if (!userId) return null;

        const contextLevel = options.contextLevel || 2;
        const includeProfile = contextLevel >= 2;
        const includeSettings = contextLevel >= 1;
        const includeProgress = contextLevel >= 3;

        const contexts = {
            metadata: {
                contextLevel,
                userId,
                timestamp: Date.now()
            }
        };

        // Add contexts based on level
        if (includeProfile) {
            contexts.profile = await this.getUserProfileContext(userId);
        }

        if (includeSettings) {
            contexts.settings = await this.getUserSettingsContext(userId);
        }

        if (includeProgress) {
            contexts.progress = await this.getUserProgressContext(userId);
        }

        return contexts;
    }

    /**
     * Format the profile context for AI consumption
     *
     * @private
     * @param {object} userData - Raw user data
     * @returns {object} - Formatted profile context
     */
    _formatProfileContext(userData) {
        // Extract relevant profile data and format it for AI context
        const profile = {
            basicInfo: {
                displayName: userData.displayName || userData.fullName || null,
                fullName: userData.fullName || null,
                email: userData.email || null,
                joined: userData.createdAt || null
            },
            learningStyle: {
                primary: userData.learningStyle || null,
                secondary: userData.secondaryLearningStyle || null,
                notes: userData.learningStyleNotes || null
            },
            professionalExperience: {
                domain: userData.professionalDomain || null,
                role: userData.professionalRole || null,
                yearsExperience: userData.yearsExperience || null,
                skills: userData.technicalSkills || []
            },
            interests: userData.interests || [],
            culturalBackground: {
                region: userData.region || null,
                languages: userData.languages || [],
                culturalContext: userData.culturalContext || null
            },
            metaphorDomains: userData.metaphorDomains || []
        };

        return profile;
    }

    /**
     * Format the settings context for AI consumption
     *
     * @private
     * @param {object} settings - Raw settings data
     * @returns {object} - Formatted settings context
     */
    _formatSettingsContext(settings) {
        // Extract relevant settings data and format it for AI context
        return {
            theme: settings.theme || 'system',
            accessibility: {
                ...settings.accessibility
            },
            ai: {
                personalizationLevel: settings.ai?.personalizationLevel || 2,
                activeContexts: {
                    ...settings.ai?.activeContexts
                },
                contextWeighting: {
                    ...settings.ai?.contextWeighting
                }
            },
            privacy: {
                shareProgress: settings.privacy?.shareProgress,
                shareAnalytics: settings.privacy?.shareAnalytics,
                storeLearningHistory: settings.privacy?.storeLearningHistory
            }
        };
    }

    /**
     * Format the progress context for AI consumption
     *
     * @private
     * @param {object} progress - Raw progress data
     * @param {object} expedition - Current expedition data
     * @returns {object} - Formatted progress context
     */
    _formatProgressContext(progress, expedition) {
        // Extract core statistics
        const stats = progress.stats || {};

        // Get active program data
        const activeProgram = this._findActiveProgramData(progress);

        // Format the progress context
        const formattedProgress = {
            stats: {
                completedModules: stats.completedModules || 0,
                inProgressModules: stats.inProgressModules || 0,
                totalModules: stats.totalModules || 0,
                completionRate: stats.completionRate || 0,

                averageScore: stats.averageScore || 0,
                learningStreak: stats.learningStreak || 0,
                activeDays: stats.activeDays || 0,

                totalAchievements: stats.totalAchievements || 0,
                unlockedAchievements: stats.unlockedAchievements || 0,

                preferredLearningPath: stats.preferredLearningPath,
                challengeAreas: stats.challengeAreas || [],
                strengths: stats.strengths || []
            },
            activeProgram: activeProgram ? {
                name: activeProgram.name,
                progress: this._calculateProgramProgress(activeProgram),
                learningVelocity: activeProgram.learningVelocity || 'normal',
                recommendedPath: activeProgram.recommendedPath,
                nextMilestone: activeProgram.nextMilestone,
                recentActivities: this._extractRecentActivities(activeProgram)
            } : null,
            expedition: expedition ? {
                id: expedition.id,
                title: expedition.title,
                progress: expedition.progress,
                currentRegion: expedition.currentRegion,
                focus: expedition.focus
            } : null
        };

        return formattedProgress;
    }

    /**
     * Find the most active program in user progress data
     *
     * @private
     * @param {object} progress - User progress data
     * @returns {object|null} - Active program or null
     */
    _findActiveProgramData(progress) {
        if (!progress.programs || progress.programs.length === 0) {
            return null;
        }

        // Sort programs by activity level (inProgress count, then lastActive time)
        const sortedPrograms = [...progress.programs].sort((a, b) => {
            // First check in-progress modules count
            const aInProgress = this._countInProgressModules(a);
            const bInProgress = this._countInProgressModules(b);

            if (aInProgress !== bInProgress) {
                return bInProgress - aInProgress;
            }

            // Then check last active time
            const aLastActive = this._getLastActiveDate(a);
            const bLastActive = this._getLastActiveDate(b);

            return bLastActive - aLastActive;
        });

        return sortedPrograms[0];
    }

    /**
     * Count in-progress modules in a program
     *
     * @private
     * @param {object} program - Program data
     * @returns {number} - Count of in-progress modules
     */
    _countInProgressModules(program) {
        if (!program.modules) return 0;

        return program.modules.filter(m =>
            !m.completed && m.progress && m.progress > 0
        ).length;
    }

    /**
     * Get the last active date for a program
     *
     * @private
     * @param {object} program - Program data
     * @returns {number} - Timestamp of last activity
     */
    _getLastActiveDate(program) {
        if (!program.modules) return 0;

        let latestDate = 0;

        program.modules.forEach(module => {
            if (module.lastActiveTimestamp) {
                latestDate = Math.max(latestDate, module.lastActiveTimestamp);
            }
        });

        return latestDate;
    }

    /**
     * Calculate overall program progress
     *
     * @private
     * @param {object} program - Program data
     * @returns {number} - Progress percentage
     */
    _calculateProgramProgress(program) {
        if (!program.modules || program.modules.length === 0) {
            return 0;
        }

        const totalModules = program.modules.length;
        const completedModules = program.modules.filter(m => m.completed).length;
        const inProgressTotal = program.modules
            .filter(m => !m.completed && m.progress > 0)
            .reduce((sum, m) => sum + (m.progress / 100), 0);

        return Math.floor(((completedModules + inProgressTotal) / totalModules) * 100);
    }

    /**
     * Extract recent activities from program data
     *
     * @private
     * @param {object} program - Program data
     * @returns {Array} - Recent activities
     */
    _extractRecentActivities(program) {
        const activities = [];

        // Add module activities
        if (program.modules) {
            program.modules.forEach(module => {
                if (module.lastActive === 'Today' || module.lastActive === 'Yesterday' || module.lastActive === 'Just now') {
                    activities.push({
                        type: 'module',
                        name: module.title,
                        progress: module.progress,
                        completed: module.completed,
                        timestamp: module.lastActiveTimestamp || Date.now()
                    });
                }
            });
        }

        // Add achievement activities
        if (program.achievements) {
            program.achievements.forEach(achievement => {
                if (achievement.achieved && achievement.unlockedAt) {
                    // Check if unlocked within last 7 days
                    const unlockTime = new Date(achievement.unlockedAt).getTime();
                    const now = Date.now();
                    const daysAgo = (now - unlockTime) / (1000 * 60 * 60 * 24);

                    if (daysAgo <= 7) {
                        activities.push({
                            type: 'achievement',
                            name: achievement.name,
                            description: achievement.description,
                            timestamp: unlockTime
                        });
                    }
                }
            });
        }

        // Sort by recency and limit to 5
        return activities
            .sort((a, b) => b.timestamp - a.timestamp)
            .slice(0, 5);
    }

    /**
     * Check if cached context is still valid
     *
     * @private
     * @param {string} userId - User ID
     * @param {string} contextType - Type of context to check
     * @returns {boolean} - Whether cache is valid
     */
    _isCacheValid(userId, contextType) {
        if (!this._cachedContexts.has(userId)) {
            return false;
        }

        const userCache = this._cachedContexts.get(userId);

        if (!userCache[contextType]) {
            return false;
        }

        // Check if refresh time has passed
        const lastRefreshTime = this._lastRefreshTime.get(userId)?.[contextType] || 0;
        const now = Date.now();

        return (now - lastRefreshTime) < this._refreshInterval;
    }

    /**
     * Update cache with new context data
     *
     * @private
     * @param {string} userId - User ID
     * @param {string} contextType - Type of context to update
     * @param {object} contextData - New context data
     */
    _updateCache(userId, contextType, contextData) {
        // Create user cache entry if it doesn't exist
        if (!this._cachedContexts.has(userId)) {
            this._cachedContexts.set(userId, {});
        }

        // Create refresh time entry if it doesn't exist
        if (!this._lastRefreshTime.has(userId)) {
            this._lastRefreshTime.set(userId, {});
        }

        // Update context data
        const userCache = this._cachedContexts.get(userId);
        userCache[contextType] = contextData;

        // Update refresh time
        const refreshTimes = this._lastRefreshTime.get(userId);
        refreshTimes[contextType] = Date.now();
    }

    /**
     * Clear cache for a user
     *
     * @param {string} userId - User ID to clear cache for
     */
    clearCache(userId) {
        if (userId) {
            this._cachedContexts.delete(userId);
            this._lastRefreshTime.delete(userId);
        } else {
            this._cachedContexts.clear();
            this._lastRefreshTime.clear();
        }
    }

    /**
     * Get default profile context
     *
     * @private
     * @returns {object} - Default profile context
     */
    _getDefaultProfileContext() {
        return {
            basicInfo: {
                displayName: null,
                fullName: null,
                email: null,
                joined: null
            },
            learningStyle: {
                primary: null,
                secondary: null,
                notes: null
            },
            professionalExperience: {
                domain: null,
                role: null,
                yearsExperience: null,
                skills: []
            },
            interests: [],
            culturalBackground: {
                region: null,
                languages: [],
                culturalContext: null
            },
            metaphorDomains: []
        };
    }

    /**
     * Get default settings context
     *
     * @private
     * @returns {object} - Default settings context
     */
    _getDefaultSettingsContext() {
        return {
            theme: 'system',
            accessibility: {
                reducedMotion: false,
                highContrast: false,
                screenReader: false
            },
            ai: {
                personalizationLevel: 2,
                activeContexts: {
                    module: true,
                    progress: true,
                    achievements: true,
                    analytics: true
                },
                contextWeighting: {
                    module: 0.4,
                    progress: 0.3,
                    achievements: 0.15,
                    analytics: 0.15
                }
            },
            privacy: {
                shareProgress: true,
                shareAnalytics: true,
                storeLearningHistory: true
            }
        };
    }

    /**
     * Get default progress context
     *
     * @private
     * @returns {object} - Default progress context
     */
    _getDefaultProgressContext() {
        return {
            stats: {
                completedModules: 0,
                inProgressModules: 0,
                totalModules: 0,
                completionRate: 0,

                averageScore: 0,
                learningStreak: 0,
                activeDays: 0,

                totalAchievements: 0,
                unlockedAchievements: 0,

                preferredLearningPath: null,
                challengeAreas: [],
                strengths: []
            },
            activeProgram: null,
            expedition: null
        };
    }

    /**
     * Format user context for AIContextManager consumption
     *
     * @param {object} contexts - User contexts
     * @param {object} options - Formatting options
     * @returns {string} - Formatted user context as AI prompt text
     */
    formatUserContextForAI(contexts, options = {}) {
        if (!contexts) return "";

        const { profile, settings, progress, metadata } = contexts;
        const level = metadata?.contextLevel || 2;

        let promptText = "";

        // Add user identity section if profile is available
        if (profile && profile.basicInfo) {
            promptText += "## User Profile\n\n";

            if (profile.basicInfo.displayName) {
                promptText += `User Name: ${profile.basicInfo.displayName}\n`;
            }

            // Add learning style if available
            if (profile.learningStyle && profile.learningStyle.primary) {
                promptText += `Learning Style: ${profile.learningStyle.primary}\n`;

                // Learning style-specific advice for AI
                switch(profile.learningStyle.primary) {
                    case 'visual':
                        promptText += "- Use visual metaphors and diagrams when explaining concepts\n";
                        promptText += "- Reference spatial relationships and visual patterns\n";
                        break;
                    case 'auditory':
                        promptText += "- Use conversational explanations and verbal analogies\n";
                        promptText += "- Explain concepts in terms of how they'd be verbally described\n";
                        break;
                    case 'kinesthetic':
                        promptText += "- Use hands-on, practical examples\n";
                        promptText += "- Emphasize physical metaphors and 'learning by doing'\n";
                        break;
                    case 'logical':
                        promptText += "- Use structured, systematic explanations\n";
                        promptText += "- Emphasize patterns and logical sequences\n";
                        break;
                }

                promptText += "\n";
            }

            // Add interests if available and not empty
            if (profile.interests && profile.interests.length > 0) {
                promptText += `Interests: ${profile.interests.join(', ')}\n`;
                promptText += "- Use these interests for relevant examples\n\n";
            }

            // Add professional experience if available (high context level)
            if (level >= 3 && profile.professionalExperience) {
                const exp = profile.professionalExperience;

                if (exp.domain) {
                    promptText += `Professional Domain: ${exp.domain}\n`;
                }

                if (exp.skills && exp.skills.length > 0) {
                    promptText += `Technical Skills: ${exp.skills.join(', ')}\n`;
                }

                promptText += "\n";
            }
        }

        // Add user progress information if available
        if (progress && progress.stats) {
            promptText += "## Learning Progress\n\n";

            const stats = progress.stats;

            // Basic progress stats
            if (stats.totalModules > 0) {
                promptText += `Completion: ${stats.completedModules}/${stats.totalModules} modules (${stats.completionRate}%)\n`;
            }

            if (stats.learningStreak > 0) {
                promptText += `Learning Streak: ${stats.learningStreak} days\n`;
            }

            // More detailed progress info for high context level
            if (level >= 3) {
                if (stats.preferredLearningPath) {
                    promptText += `Preferred Learning Path: ${stats.preferredLearningPath}\n`;

                    // Add learning path-specific advice for AI
                    switch(stats.preferredLearningPath) {
                        case 'builder':
                            promptText += "- Focus on practical implementation details\n";
                            promptText += "- Emphasize coding patterns over theory\n";
                            break;
                        case 'scientist':
                            promptText += "- Include deeper technical explanations\n";
                            promptText += "- Explain why certain approaches work better\n";
                            break;
                        case 'storyteller':
                            promptText += "- Frame explanations in narrative contexts\n";
                            promptText += "- Emphasize design and user experience aspects\n";
                            break;
                    }

                    promptText += "\n";
                }

                // Add strengths and challenge areas
                if (stats.strengths && stats.strengths.length > 0) {
                    promptText += `Strengths: ${stats.strengths.join(', ')}\n`;
                    promptText += "- Build upon these established strengths\n";
                }

                if (stats.challengeAreas && stats.challengeAreas.length > 0) {
                    promptText += `Areas for Improvement: ${stats.challengeAreas.join(', ')}\n`;
                    promptText += "- Provide extra explanation in these areas\n";
                }

                promptText += "\n";
            }

            // Add active program information if available
            if (progress.activeProgram) {
                const program = progress.activeProgram;

                promptText += `Current Program: ${program.name}\n`;
                promptText += `Program Progress: ${program.progress}%\n`;

                if (program.nextMilestone) {
                    promptText += `Next Milestone: ${program.nextMilestone}\n`;
                }

                promptText += "\n";
            }

            // Add expedition information if available
            if (progress.expedition) {
                const expedition = progress.expedition;

                promptText += `Active Expedition: ${expedition.title}\n`;

                if (expedition.currentRegion) {
                    promptText += `Current Region: ${expedition.currentRegion}\n`;
                }

                if (expedition.focus) {
                    promptText += `Learning Focus: ${expedition.focus}\n`;
                }

                promptText += "\n";
            }
        }

        // Add AI personalization settings if available
        if (settings && settings.ai) {
            promptText += "## AI Personalization Settings\n\n";

            promptText += `Personalization Level: ${settings.ai.personalizationLevel}/3\n`;

            if (level >= 3 && settings.ai.activeContexts) {
                promptText += "Active Context Areas:\n";

                Object.entries(settings.ai.activeContexts).forEach(([key, value]) => {
                    if (value) {
                        promptText += `- ${key}\n`;
                    }
                });
            }

            if (settings.accessibility) {
                const accessibilityNeeds = Object.entries(settings.accessibility)
                    .filter(([key, value]) => value === true)
                    .map(([key]) => key);

                if (accessibilityNeeds.length > 0) {
                    promptText += "\nAccessibility Needs:\n";
                    accessibilityNeeds.forEach(need => {
                        promptText += `- ${need}\n`;
                    });
                }
            }

            promptText += "\n";
        }

        return promptText;
    }
}

export default UserContextSpecialist;