/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                       /SCOPE/templates-report/TANK/generalWebpages/ATOM/effects/text/Accepted_ThreeDText.jsx
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

import React, { useContext } from 'react';
import { ThemeContext } from '@/FullDevApp.jsx';

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                          CONSTANTS & UTILITY FUNCTIONS
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

// Animation keyframes for 3D text effects - directly included in this file
const keyframes3D = `
@keyframes float {
    0% {
        transform: translateY(0px) translateZ(0) rotateX(0deg);
        text-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
    50% {
        transform: translateY(-10px) translateZ(10px) rotateX(5deg);
        text-shadow: 0 25px 20px rgba(0, 0, 0, 0.2);
    }
    100% {
        transform: translateY(0px) translateZ(0) rotateX(0deg);
        text-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
}

@keyframes pulse3D {
    0% {
        transform: scale(1) translateZ(0px);
    }
    50% {
        transform: scale(1.05) translateZ(5px);
    }
    100% {
        transform: scale(1) translateZ(0px);
    }
}

@keyframes glowPulse {
    0% {
        text-shadow: 0 0 5px rgba(78, 205, 196, 0.5),
                    0 0 10px rgba(78, 205, 196, 0.3),
                    0 0 15px rgba(78, 205, 196, 0.1);
    }
    50% {
        text-shadow: 0 0 10px rgba(78, 205, 196, 0.8),
                    0 0 20px rgba(78, 205, 196, 0.5),
                    0 0 30px rgba(78, 205, 196, 0.3);
    }
    100% {
        text-shadow: 0 0 5px rgba(78, 205, 196, 0.5),
                    0 0 10px rgba(78, 205, 196, 0.3),
                    0 0 15px rgba(78, 205, 196, 0.1);
    }
}

@keyframes rotateText {
    0% {
        transform: perspective(800px) rotateX(0deg) rotateY(0deg);
    }
    25% {
        transform: perspective(800px) rotateX(2deg) rotateY(-2deg);
    }
    50% {
        transform: perspective(800px) rotateX(0deg) rotateY(0deg);
    }
    75% {
        transform: perspective(800px) rotateX(-2deg) rotateY(2deg);
    }
    100% {
        transform: perspective(800px) rotateX(0deg) rotateY(0deg);
    }
}

@keyframes shadowShift {
    0% {
        text-shadow: 1px 1px 0 rgba(0,0,0,0.3),
                    2px 2px 0 rgba(0,0,0,0.3),
                    3px 3px 0 rgba(0,0,0,0.3);
    }
    50% {
        text-shadow: 2px 2px 0 rgba(0,0,0,0.5),
                    4px 4px 0 rgba(0,0,0,0.3),
                    6px 6px 0 rgba(0,0,0,0.1);
    }
    100% {
        text-shadow: 1px 1px 0 rgba(0,0,0,0.3),
                    2px 2px 0 rgba(0,0,0,0.3),
                    3px 3px 0 rgba(0,0,0,0.3);
    }
}

@keyframes popIn {
    0% {
        transform: scale(0.8) translateZ(-10px);
        opacity: 0;
    }
    80% {
        transform: scale(1.1) translateZ(5px);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateZ(0);
        opacity: 1;
    }
}

@keyframes typewriter3D {
    from {
        width: 0;
        text-shadow: none;
    }
    to {
        width: 100%;
        text-shadow: inherit;
    }
}

@keyframes neonFlicker {
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
        text-shadow: 0 0 5px rgba(78, 205, 196, 0.7),
                     0 0 10px rgba(78, 205, 196, 0.7),
                     0 0 15px rgba(78, 205, 196, 0.7),
                     0 0 20px rgba(78, 205, 196, 0.9),
                     0 0 35px rgba(78, 205, 196, 0.7),
                     0 0 40px rgba(78, 205, 196, 0.6);
    }
    20%, 24%, 55% {
        text-shadow: none;
    }
}

@keyframes shimmer3D {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
`;

// Utility for logging (if available in the main color system)
const logWx4H = (who, what, why, how) => {
    if (typeof window !== 'undefined' && window.DEBUG_LOGGING) {
        console.log(`[WHO: ${who}] [WHAT: ${what}] [WHY: ${why}] [HOW: ${how}]`);
    }
};

// 3D text effect presets
const TEXT_3D_EFFECTS = {
    light: {
        // Classic letterpress effect with deep shadows
        classic: {
            textShadow: `0 1px 0 #4a4a4a,
                0 2px 0 #3a3a3a,
                0 3px 0 #2a2a2a,
                0 4px 0 #1a1a1a,
                0 5px 0 #0a0a0a,
                0 6px 0 #000000,
                0 7px 0 rgba(0,0,0,0.9),
                0 8px 1px rgba(0,0,0,0.8),
                0 9px 2px rgba(0,0,0,0.7),
                1px 1px 0 rgba(71,188,179,0.2),
                -1px -1px 0 rgba(255,255,255,0.7),
                0 10px 5px rgba(0,0,0,0.4),
                0 12px 10px rgba(0,0,0,0.3),
                0 15px 15px rgba(0,0,0,0.2),
                0 20px 20px rgba(0,0,0,0.15)`,
            WebkitTextStroke: '1px rgba(78, 205, 196, 0.8)',
            textStroke: '1px rgba(78, 205, 196, 0.8)',
            transform: 'perspective(800px) rotateX(0deg) translateZ(0)'
        },
        // Modern flat style with subtle depth
        modern: {
            textShadow: `0 2px 2px rgba(0,0,0,0.2),
                0 4px 4px rgba(0,0,0,0.1),
                0 8px 8px rgba(0,0,0,0.05)`,
            WebkitTextStroke: '0.5px rgba(78, 205, 196, 0.5)',
            textStroke: '0.5px rgba(78, 205, 196, 0.5)',
            transform: 'perspective(800px) rotateX(0deg) translateZ(0)'
        },
        // Bold extrusion style with strong shadows
        extrude: {
            textShadow: `1px 1px 0 #555,
                2px 2px 0 #444,
                3px 3px 0 #333,
                4px 4px 0 #222,
                5px 5px 0 #111,
                6px 6px 0 #000,
                7px 7px 10px rgba(0,0,0,0.5)`,
            WebkitTextStroke: '1px rgba(78, 205, 196, 1)',
            textStroke: '1px rgba(78, 205, 196, 1)',
            transform: 'perspective(800px) rotateX(10deg) translateZ(0)'
        },
        // Neon glow effect
        neon: {
            textShadow: `0 0 5px rgba(78, 205, 196, 0.5),
                0 0 10px rgba(78, 205, 196, 0.5),
                0 0 15px rgba(78, 205, 196, 0.5),
                0 0 20px rgba(78, 205, 196, 0.7),
                0 0 35px rgba(78, 205, 196, 0.5),
                0 0 40px rgba(78, 205, 196, 0.4)`,
            WebkitTextStroke: '1px rgba(78, 205, 196, 0.5)',
            textStroke: '1px rgba(78, 205, 196, 0.5)',
            transform: 'perspective(800px) rotateX(0deg) translateZ(0)'
        },
        // Subtle elegant elevation
        elegant: {
            textShadow: `0 1px 1px rgba(0,0,0,0.15),
                0 2px 2px rgba(0,0,0,0.1),
                0 4px 8px rgba(0,0,0,0.05)`,
            WebkitTextStroke: '0.5px rgba(78, 205, 196, 0.3)',
            textStroke: '0.5px rgba(78, 205, 196, 0.3)',
            transform: 'perspective(800px) rotateX(0deg) translateZ(0)'
        }
    },
    dark: {
        // Classic letterpress effect for dark mode
        classic: {
            textShadow: `0 1px 0 #ccc,
                0 2px 0 #c9c9c9,
                0 3px 0 #bbb,
                0 4px 0 #b9b9b9,
                0 5px 0 #aaa,
                0 6px 1px rgba(0,0,0,.1),
                0 0 5px rgba(0,0,0,.1),
                0 1px 3px rgba(0,0,0,.3),
                0 3px 5px rgba(0,0,0,.2),
                0 5px 10px rgba(0,0,0,.25),
                0 10px 10px rgba(0,0,0,.2),
                0 20px 20px rgba(0,0,0,.15)`,
            WebkitTextStroke: '1px rgba(78, 205, 196, 0.7)',
            textStroke: '1px rgba(78, 205, 196, 0.7)',
            transform: 'perspective(800px) rotateX(0deg) translateZ(0)'
        },
        // Modern flat style for dark mode
        modern: {
            textShadow: `0 2px 2px rgba(0,0,0,0.5),
                0 4px 4px rgba(0,0,0,0.3),
                0 8px 8px rgba(0,0,0,0.2)`,
            WebkitTextStroke: '0.5px rgba(78, 205, 196, 0.6)',
            textStroke: '0.5px rgba(78, 205, 196, 0.6)',
            transform: 'perspective(800px) rotateX(0deg) translateZ(0)'
        },
        // Bold extrusion style for dark mode
        extrude: {
            textShadow: `1px 1px 0 #222,
                2px 2px 0 #333,
                3px 3px 0 #444,
                4px 4px 0 #555,
                5px 5px 0 #666,
                6px 6px 0 #777,
                7px 7px 10px rgba(0,0,0,0.9)`,
            WebkitTextStroke: '1px rgba(78, 205, 196, 0.8)',
            textStroke: '1px rgba(78, 205, 196, 0.8)',
            transform: 'perspective(800px) rotateX(10deg) translateZ(0)'
        },
        // Neon glow effect for dark mode
        neon: {
            textShadow: `0 0 5px rgba(78, 205, 196, 0.7),
                0 0 10px rgba(78, 205, 196, 0.7),
                0 0 15px rgba(78, 205, 196, 0.7),
                0 0 20px rgba(78, 205, 196, 0.9),
                0 0 35px rgba(78, 205, 196, 0.7),
                0 0 40px rgba(78, 205, 196, 0.6)`,
            WebkitTextStroke: '1px rgba(78, 205, 196, 0.7)',
            textStroke: '1px rgba(78, 205, 196, 0.7)',
            transform: 'perspective(800px) rotateX(0deg) translateZ(0)'
        },
        // Subtle elegant elevation for dark mode
        elegant: {
            textShadow: `0 1px 1px rgba(255,255,255,0.1),
                0 2px 2px rgba(255,255,255,0.05),
                0 4px 8px rgba(0,0,0,0.1)`,
            WebkitTextStroke: '0.5px rgba(78, 205, 196, 0.4)',
            textStroke: '0.5px rgba(78, 205, 196, 0.4)',
            transform: 'perspective(800px) rotateX(0deg) translateZ(0)'
        }
    }
};

// Helper function to create CSS styles for text reflection
const createTextReflectionStyles = (reflectionColor = 'rgba(255, 255, 255, 0.2)', endColor = 'rgba(255, 255, 255, 0)') => `
.text-3d-reflection {
    position: relative;
}
.text-3d-reflection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40%;
    background: linear-gradient(
        to bottom,
        ${reflectionColor},
        ${endColor}
    );
    z-index: -1;
    pointer-events: none;
}
`;

// Helper function to create CSS styles for 3D letter animation
const createLetterAnimationStyles = () => `
.text-3d-animated .letter {
    display: inline-block;
    transform-style: preserve-3d;
    transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.text-3d-animated:hover .letter {
    transform: translateY(-5px) translateZ(5px) rotateX(-10deg);
    transition-duration: 0.3s;
    transition-delay: calc(0.03s * var(--letter-index));
}
`;

/**
 * Get 3D text effect styles based on theme and style selection
 * @param {boolean} isDark - Whether dark theme is active
 * @param {string} style - The style name (classic, modern, extrude, neon, elegant)
 * @param {Object} customOptions - Custom style properties to override defaults
 * @returns {Object} Style object with 3D text effect properties
 */
const get3DTextEffect = (isDark, style = 'classic', customOptions = {}) => {
    logWx4H(
        'get3DTextEffect',
        'Get 3D text effect',
        'To create depth and dimension in text elements',
        'Returns a style object with 3D text effect properties'
    );

    try {
        const themeMode = isDark ? 'dark' : 'light';

        if (!TEXT_3D_EFFECTS[themeMode][style]) {
            console.warn(`3D text effect "${style}" not found. Using classic.`);
            style = 'classic';
        }

        return {
            ...TEXT_3D_EFFECTS[themeMode][style],
            ...customOptions
        };
    } catch (error) {
        console.error('Error getting 3D text effect:', error);
        return TEXT_3D_EFFECTS[isDark ? 'dark' : 'light'].classic; // Fallback
    }
};

/**
 * Create function to wrap text in animated letter spans
 * @returns {Function} Function that wraps each character in a span with appropriate classes
 */
const create3DLetterWrapperFunc = () => {
    logWx4H(
        'create3DLetterWrapperFunc',
        'Create function to wrap text in animated letter spans',
        'To enable per-letter 3D animations',
        'Returns a function that wraps each character in a span with appropriate classes'
    );

    return (text) => {
        if (!text) return '';
        return text.split('').map((char, index) => {
            return `<span class="letter" style="--letter-index: ${index}">${char}</span>`;
        }).join('');
    };
};

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                            REACT HOOK FOR 3D TEXT EFFECTS
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

/**
 * useThreeDText - React hook for accessing theme-aware 3D text effects
 * @returns {Object} Object containing 3D text effect utilities
 */
const useThreeDText = () => {
    // Get current theme from context
    const themeContext = useContext(ThemeContext) || { isDark: false };
    const { isDark } = themeContext;

    return {
        /**
         * Get 3D text effect styles
         * @param {string} style - The style name (classic, modern, extrude, neon, elegant)
         * @param {Object} customOptions - Custom style properties to override defaults
         * @returns {Object} Style object with 3D text effect properties
         */
        get3DTextEffect: (style = 'classic', customOptions = {}) => {
            return get3DTextEffect(isDark, style, customOptions);
        },

        /**
         * Create function to wrap text in animated letter spans
         * @returns {Function} Function that wraps each character in a span
         */
        create3DLetterWrapper: () => {
            return create3DLetterWrapperFunc();
        },

        /**
         * Get CSS keyframes for 3D animations
         * @returns {string} CSS keyframes definitions
         */
        get3DKeyframes: () => keyframes3D,

        /**
         * Create CSS for text reflection effect
         * @param {string} reflectionColor - Start color for reflection gradient
         * @param {string} endColor - End color for reflection gradient
         * @returns {string} CSS for text reflection
         */
        createTextReflectionStyles: (
            reflectionColor = isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.4)',
            endColor = isDark ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0.1)'
        ) => {
            return createTextReflectionStyles(reflectionColor, endColor);
        },

        /**
         * Create CSS for letter-by-letter animation
         * @returns {string} CSS for letter animation
         */
        createLetterAnimationStyles: () => createLetterAnimationStyles(),

        /**
         * Direct access to effect presets
         */
        text3DEffects: isDark ? TEXT_3D_EFFECTS.dark : TEXT_3D_EFFECTS.light,

        /**
         * Current theme state
         */
        isDark
    };
};

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                                    COMPONENT & EXPORTS
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

/**
 * Accepted_ThreeDText - A component for creating 3D text effects
 * Export constants and functions for direct access
 */
const Accepted_ThreeDText = {
    TEXT_3D_EFFECTS,
    get3DTextEffect,
    create3DLetterWrapperFunc,
    createTextReflectionStyles,
    createLetterAnimationStyles,
    useThreeDText,
    keyframes3D
};

// Add display name for dev tools
Accepted_ThreeDText.displayName = 'Accepted_ThreeDText';

export {
    TEXT_3D_EFFECTS,
    get3DTextEffect,
    create3DLetterWrapperFunc,
    createTextReflectionStyles,
    createLetterAnimationStyles,
    useThreeDText,
    keyframes3D
};

export default Accepted_ThreeDText;