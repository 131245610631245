/**
 * Specialist for conversation management in the Chat AI Admin component
 */
const Specialist_Conversation_Contexts_Chat_AI_Admin = {
    // State initialization
    initializeConversationState() {
        return {
            messages: [],
            input: '',
            isLoading: false,
            error: null,
            conversationId: null,
            currentThoughts: '',
            isPending: false,
            attachedImages: [],
            isImageUploading: false,
            conversationHistory: []
        };
    },

    // Add welcome message
    createWelcomeMessage(operationalMode, detectedContextType) {
        return {
            id: `admin_welcome_${Date.now()}`,
            role: 'assistant',
            content: `# Welcome to the Taytrom Admin AI Console

You now have access to enhanced AI capabilities for administrative tasks. This interface provides:

- Extended thinking capabilities (up to 128K tokens)
- Access to experimental operational modes
- System architecture insights
- Custom system prompts
- Debugging and diagnostics tools
- Image upload and analysis capabilities

**Current Mode**: ${operationalMode}
**Detected Context Type**: ${detectedContextType === 'admin' ? 'Admin Mode' : 'User Mode'}

How can I assist with Taytrom development today?`,
            timestamp: Date.now(),
            isWelcomeMessage: true
        };
    },

    // Handle file selection
    handleFileSelect: (event, setIsImageUploading, setAttachedImages, addToSessionLogs) => {
        const files = event.target.files;
        if (!files || files.length === 0) return;

        setIsImageUploading(true);

        Array.from(files).forEach(file => {
            if (!file.type.startsWith('image/')) {
                addToSessionLogs(`File ${file.name} is not an image`, 'warning');
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                setAttachedImages(prevImages => [
                    ...prevImages,
                    {
                        id: `img_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`,
                        data: e.target.result,
                        name: file.name,
                        type: file.type,
                        size: file.size
                    }
                ]);
            };

            reader.onerror = (error) => {
                addToSessionLogs(`Error reading file ${file.name}: ${error}`, 'error');
            };

            reader.onloadend = () => {
                setIsImageUploading(false);
            };

            reader.readAsDataURL(file);
        });

        // Clear the file input
        event.target.value = null;
    },

    // Handle paste events for images
    handlePaste: (event, setIsImageUploading, setAttachedImages, addToSessionLogs) => {
        const items = (event.clipboardData || window.clipboardData).items;
        let imageFound = false;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                imageFound = true;
                setIsImageUploading(true);

                const file = items[i].getAsFile();
                const reader = new FileReader();

                reader.onload = (e) => {
                    setAttachedImages(prevImages => [
                        ...prevImages,
                        {
                            id: `img_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`,
                            data: e.target.result,
                            name: file.name || 'Pasted image',
                            type: file.type,
                            size: file.size
                        }
                    ]);
                };

                reader.onerror = (error) => {
                    addToSessionLogs(`Error reading pasted image: ${error}`, 'error');
                };

                reader.onloadend = () => {
                    setIsImageUploading(false);
                };

                reader.readAsDataURL(file);
            }
        }

        // If an image was found and processed, prevent the default paste behavior
        if (imageFound) {
            event.preventDefault();
        }
    },

    // Handle drag and drop for images
    handleDrop: (event, setIsImageUploading, setAttachedImages, addToSessionLogs) => {
        event.preventDefault();

        if (event.dataTransfer.items) {
            setIsImageUploading(true);
            let processedFiles = 0;
            const totalFiles = event.dataTransfer.items.length;

            for (let i = 0; i < totalFiles; i++) {
                if (event.dataTransfer.items[i].kind === 'file') {
                    const file = event.dataTransfer.items[i].getAsFile();

                    if (!file.type.startsWith('image/')) {
                        processedFiles++;
                        if (processedFiles === totalFiles) {
                            setIsImageUploading(false);
                        }
                        continue;
                    }

                    const reader = new FileReader();

                    reader.onload = (e) => {
                        setAttachedImages(prevImages => [
                            ...prevImages,
                            {
                                id: `img_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`,
                                data: e.target.result,
                                name: file.name,
                                type: file.type,
                                size: file.size
                            }
                        ]);

                        processedFiles++;
                        if (processedFiles === totalFiles) {
                            setIsImageUploading(false);
                        }
                    };

                    reader.onerror = (error) => {
                        addToSessionLogs(`Error reading dropped file ${file.name}: ${error}`, 'error');
                        processedFiles++;
                        if (processedFiles === totalFiles) {
                            setIsImageUploading(false);
                        }
                    };

                    reader.readAsDataURL(file);
                }
            }
        }
    },

    // Handle message submission
    handleSubmit: async (e, input, attachedImages, isLoading, isAuthenticated, setMessages, setIsLoading, setInput, orchestratorRef, conversationId, prepareAIRequestWithDomains, clearAttachedImages, addToSessionLogs, conversationHistory, setConversationHistory, setError) => {
        if (e) e.preventDefault();
        if ((!input.trim() && attachedImages.length === 0) || isLoading || !isAuthenticated) return;

        // Add user message to chat
        const userMessage = {
            id: `admin_user_${Date.now()}`,
            role: 'user',
            content: input,
            timestamp: Date.now(),
            images: attachedImages.length > 0 ? [...attachedImages] : undefined
        };

        setMessages(prevMessages => [...prevMessages, userMessage]);
        setIsLoading(true);
        setInput('');

        // Update conversation history with user message
        const updatedHistory = [...conversationHistory, {
            role: 'user',
            content: input,
            images: attachedImages.length > 0 ? attachedImages.map(img => img.data) : undefined
        }];

        setConversationHistory(updatedHistory);

        addToSessionLogs(`User message sent: ${input.substring(0, 40)}...${attachedImages.length > 0 ? ` with ${attachedImages.length} images` : ''}`);

        try {
            if (!orchestratorRef.current) {
                throw new Error('AI system is not initialized');
            }

            // Prepare enhanced options with knowledge domains, using the JUST updated history
            const options = await prepareAIRequestWithDomains(input, updatedHistory);

            // Add image data to the options
            if (attachedImages.length > 0) {
                options.images = attachedImages.map(img => img.data);
            }

            // Send message with conversation history included
            const result = await orchestratorRef.current.handleUserMessage(input, options);

            // Clear attached images after sending
            clearAttachedImages();

            if (!result || !result.success) {
                setError(result?.error || "Unknown error occurred");
                setIsLoading(false);
                addToSessionLogs(`Error from AI system: ${result?.error || "Unknown error"}`, 'error');
            }
        } catch (err) {
            console.error("[Admin] Error sending message:", err);
            setIsLoading(false);
            setError(`Failed to send message: ${err.message}`);
            addToSessionLogs(`Error sending message: ${err.message}`, 'error');
        }
    },

    // Handle AI responses
    handleAIResponse: (notification, extendedThinkingEnabled, thinkingBudget, currentThoughts, operationalMode, contextualKnowledge, isContextLoaded, temperature, systemPrompt, detectedContextType, conversationHistory, addToSessionLogs, setMessages, setConversationHistory, setCurrentThoughts, setIsLoading, setIsPending, aiManagerRef) => {
        try {
            if (notification.type === 'AI_RESPONSE' || notification.data?.type === 'AI_RESPONSE') {
                setIsLoading(false);
                setIsPending(false);

                // Get message content from notification
                const messageContent = notification.message || notification.data?.message;
                if (!messageContent) {
                    console.error("[Admin] No message content in notification:", notification);
                    addToSessionLogs("Error: Empty message response from AI", 'error');
                    return;
                }

                const newMessage = {
                    id: notification.requestId || notification.data?.requestId || `admin_resp_${Date.now()}`,
                    role: 'assistant',
                    content: messageContent,
                    timestamp: notification.timestamp || notification.data?.timestamp || Date.now(),
                    hasExtendedThinking: extendedThinkingEnabled,
                    thinkingBudget: extendedThinkingEnabled ? thinkingBudget : undefined,
                    thinkingContent: notification.thinking || notification.data?.thinking || currentThoughts,
                    operationalMode: operationalMode,
                    contextualKnowledge: contextualKnowledge,
                    userContextApplied: isContextLoaded,
                    temperature: temperature,
                    systemPrompt: systemPrompt,
                    detectedContextType: detectedContextType
                };

                setMessages(prevMessages => [...prevMessages, newMessage]);

                // Update conversation history with AI response
                const updatedHistory = [...conversationHistory, {
                    role: 'assistant',
                    content: messageContent
                }];

                setConversationHistory(updatedHistory);
                setCurrentThoughts('');

                addToSessionLogs(`AI response received: ${newMessage.content.substring(0, 40)}...`);

                // Update system status after response
                if (aiManagerRef.current) {
                    setTimeout(() => {
                        // Update system status if needed
                    }, 500);
                }
            } else if (notification.type === 'AI_THINKING' || notification.data?.type === 'AI_THINKING') {
                // Handle "thinking" state
                setCurrentThoughts(notification.thinking || notification.data?.thinking || '');
                setIsPending(true);
            }
        } catch (err) {
            console.error("[Admin] Error processing AI response:", err);
            setIsLoading(false);
            setIsPending(false);
            addToSessionLogs(`Error processing AI response: ${err.message}`, 'error');
        }
    },

    // Clear conversation
    handleClearConversation: (persistKey, addToSessionLogs, setMessages, setConversationHistory, setCurrentThoughts, setError, setIsPending, setInput, clearAttachedImages) => {
        if (window.confirm('Are you sure you want to clear this administrative conversation?')) {
            // Clear messages from state
            setMessages([]);

            // Also clear conversation history
            setConversationHistory([]);

            // Clear from localStorage
            if (typeof window !== 'undefined') {
                localStorage.removeItem(persistKey);
                localStorage.removeItem(`${persistKey}_history`);
            }

            // Reset related states
            setCurrentThoughts('');
            setError(null);
            setIsPending(false);

            // Reset input field
            setInput('');

            // Clear attached images
            clearAttachedImages();

            addToSessionLogs("Conversation cleared");
        }
    },

    // Export conversation logs
    exportConversation: (conversationId, operationalMode, detectedContextType, systemPrompt, messages, addToSessionLogs, sessionLogsRef) => {
        try {
            const exportData = {
                metadata: {
                    exportTime: new Date().toISOString(),
                    conversationId,
                    operationalMode,
                    detectedContextType,
                    systemPrompt,
                    totalMessages: messages.length
                },
                messages,
                sessionLogs: sessionLogsRef.current
            };

            const dataStr = JSON.stringify(exportData, null, 2);
            const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(dataStr)}`;

            const exportFileDefaultName = `taytrom-admin-chat-${Date.now()}.json`;

            // Create link and trigger download
            const linkElement = document.createElement('a');
            linkElement.setAttribute('href', dataUri);
            linkElement.setAttribute('download', exportFileDefaultName);
            linkElement.click();

            addToSessionLogs(`Conversation exported as ${exportFileDefaultName}`);
        } catch (err) {
            console.error("[Admin] Error exporting conversation:", err);
            addToSessionLogs(`Error exporting conversation: ${err.message}`, 'error');
        }
    }
};

export default Specialist_Conversation_Contexts_Chat_AI_Admin;