import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import {
    Sparkles,
    Loader,
    StarIcon,
    Filter,
    Award,
    PlusCircle,
    X,
    ThumbsUp,
    ThumbsDown,
    Wand2,
    RefreshCw
} from 'lucide-react';
import styles from './AISuggestions_Specialists_Writing.module.css';

// Memoized Category filter button component
const CategoryFilterButton = memo(({ category, active, onClick, color }) => (
    <button
        className={`${styles['taytrom-filter-button']} ${active ?
            category === 'Structure' ? styles['taytrom-filter-button--structure'] :
                category === 'Content' ? styles['taytrom-filter-button--content'] :
                    category === 'Style' ? styles['taytrom-filter-button--style'] :
                        styles['taytrom-filter-button--all'] : ''} ${active ? styles['taytrom-filter-button--active'] : ''}`}
        onClick={() => onClick(category)}
        aria-pressed={active}
        aria-label={`Filter by ${category}`}
    >
        {category}
    </button>
));

// Define a set of diverse example suggestions for different categories
const DIVERSE_SUGGESTIONS = {
    'Structure': [
        "Add a clear introduction that outlines the main points",
        "Consider reorganizing to put related concepts together",
        "End with a strong conclusion that summarizes key takeaways",
        "Break longer steps into smaller, focused paragraphs",
        "Create a logical flow between paragraphs with transitions",
        "Add subheadings to create a clearer document-panel structure",
        "Consider using a problem-solution structure for clarity",
        "Organize content chronologically to build understanding",
        "Add a table of contents for easier navigation"
    ],
    'Content': [
        "Include specific examples that illustrate your main points",
        "Support your claims with relevant data or statistics",
        "Add expert quotes to strengthen your argument",
        "Incorporate real-world case studies to demonstrate concepts",
        "Define key terms to ensure reader understanding",
        "Explore counterarguments to strengthen your position",
        "Add context about why this topic matters to readers",
        "Incorporate visual elements to explain complex concepts",
        "Include actionable takeaways for practical application"
    ],
    'Style': [
        "Use more active voice to strengthen your writing",
        "Simplify complex sentences for better readability",
        "Add metaphors to make abstract concepts more concrete",
        "Use a more conversational tone to engage readers",
        "Incorporate industry-specific terminology appropriately",
        "Vary sentence structure to create a better rhythm",
        "Use stronger verbs to create more impactful statements",
        "Add sensory details to make descriptions more vivid",
        "Consider a more formal tone for this technical section"
    ]
};

// Define a diverse set of colors for different categories
const CATEGORY_COLORS = {
    'Structure': ['#3b82f6', '#0ea5e9', '#06b6d4', '#0891b2', '#0e7490'],
    'Content': ['#10b981', '#059669', '#16a34a', '#22c55e', '#15803d'],
    'Style': ['#8b5cf6', '#a855f7', '#d946ef', '#7c3aed', '#9333ea']
};

const AISuggestions = ({
                           thoughts,
                           handleAddThought,
                           setThoughts,
                           isProcessing,
                           generateNewThought,
                           generateMultipleThoughts,
                           isDark,
                           useInternalButtons = false // Prop to control button placement
                       }) => {
    // State for expanded thought
    const [expandedThoughtId, setExpandedThoughtId] = useState(null);
    // State for filtered thoughts and categories
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [categories] = useState(['All', 'Structure', 'Content', 'Style']);
    // Track available positions to prevent overlapping
    const [occupiedSectors, setOccupiedSectors] = useState([]);
    // State for drag mode - Now true by default!
    const [isDragMode] = useState(true);
    // State for drag hint
    const [showDragHint, setShowDragHint] = useState(false);
    // State for onboarding
    const [showOnboarding, setShowOnboarding] = useState(true);
    // State for celebration effect (optional)
    const [showCelebration, setShowCelebration] = useState(false);

    // Constants for grid calculations
    const gridSize = 10; // 10x10 grid for positioning
    const bubbleRadius = 10; // approximate percentage size of a bubble

    // Show onboarding tutorial on first load
    useEffect(() => {
        // Check if onboarding has been shown before
        const hasSeenOnboarding = localStorage.getItem('hasSeenDragOnboarding');

        if (!hasSeenOnboarding) {
            setShowOnboarding(true);

            // Set a timeout to hide the onboarding after 8 seconds
            const timer = setTimeout(() => {
                setShowOnboarding(false);
                localStorage.setItem('hasSeenDragOnboarding', 'true');
            }, 8000);

            return () => clearTimeout(timer);
        } else {
            setShowOnboarding(false);
        }
    }, []);

    // Show drag hint when the component is mounted (since drag mode is now the default)
    useEffect(() => {
        setShowDragHint(true);
        const timer = setTimeout(() => {
            setShowDragHint(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    // Memoized function to find an available position
    const findAvailablePosition = useCallback((existingThoughts) => {
        // Create a copy of the occupied sectors
        const occupied = [...occupiedSectors];

        // Define grid boundaries to keep bubbles from edge
        const minPos = bubbleRadius;
        const maxPos = 100 - bubbleRadius;

        // Try to find an unoccupied sector
        let attempts = 0;
        let x, y, sectorKey;

        while (attempts < 50) { // Limit attempts to prevent infinite loops
            // Generate a position within safe boundaries
            x = minPos + Math.random() * (maxPos - minPos);
            y = minPos + Math.random() * (maxPos - minPos);

            // Convert to grid sector
            const sectorX = Math.floor(x / gridSize);
            const sectorY = Math.floor(y / gridSize);
            sectorKey = `${sectorX},${sectorY}`;

            // Check if this sector and adjacent sectors are occupied
            const isSectorFree = !occupied.includes(sectorKey);
            const areAdjacentSectorsFree = ![-1, 0, 1].some(dx =>
                [-1, 0, 1].some(dy => {
                    const adjacentKey = `${sectorX + dx},${sectorY + dy}`;
                    return occupied.includes(adjacentKey);
                })
            );

            if (isSectorFree && areAdjacentSectorsFree) {
                // We found an available position
                occupied.push(sectorKey);
                setOccupiedSectors(occupied);
                return { x, y, sectorKey };
            }

            attempts++;
        }

        // If all attempts failed, use a fallback position with some randomness
        x = 20 + Math.random() * 60;
        y = 20 + Math.random() * 60;
        return { x, y, sectorKey: null }; // No sector key as fallback
    }, [occupiedSectors, bubbleRadius, gridSize]);

    // Effect for organizing thoughts with categories and positions
    useEffect(() => {
        if (thoughts.length > 0) {
            // First clear occupied sectors when thoughts change dramatically
            if (thoughts.length === 0) {
                setOccupiedSectors([]);
            }

            const thoughtsWithCategoriesAndPositions = thoughts.map(thought => {
                let updatedThought = { ...thought };

                // Assign a random category if none exists
                if (!updatedThought.category) {
                    const availableCategories = ['Structure', 'Content', 'Style'];
                    const randomCategory = availableCategories[Math.floor(Math.random() * availableCategories.length)];
                    updatedThought.category = randomCategory;

                    // Assign a category-appropriate color
                    const categoryColors = CATEGORY_COLORS[randomCategory];
                    updatedThought.color = categoryColors[Math.floor(Math.random() * categoryColors.length)];
                }

                // Ensure position exists and is valid
                if (!updatedThought.position ||
                    (updatedThought.position.x < 0 || updatedThought.position.x > 100 ||
                        updatedThought.position.y < 0 || updatedThought.position.y > 100)) {
                    // Find a non-overlapping position
                    const newPosition = findAvailablePosition(thoughts);
                    updatedThought.position = {
                        x: newPosition.x,
                        y: newPosition.y
                    };

                    // Add the sector to occupied list if it's not already there
                    if (newPosition.sectorKey && !occupiedSectors.includes(newPosition.sectorKey)) {
                        setOccupiedSectors(prev => [...prev, newPosition.sectorKey]);
                    }
                }

                return updatedThought;
            });

            // Only update if we actually modified thoughts
            if (JSON.stringify(thoughts) !== JSON.stringify(thoughtsWithCategoriesAndPositions)) {
                setThoughts(thoughtsWithCategoriesAndPositions);
            }
        }
    }, [thoughts, setThoughts, findAvailablePosition, occupiedSectors]);

    // Memoized filtered thoughts based on selected category
    const filteredThoughts = useMemo(() => {
        return selectedCategory === 'All'
            ? thoughts
            : thoughts.filter(t => t.category === selectedCategory);
    }, [thoughts, selectedCategory]);

    // Callbacks for interaction handlers
    const handleThoughtClick = useCallback((thought) => {
        // Only support click functionality for expanded view
        setExpandedThoughtId(prevId => prevId === thought.id ? null : thought.id);
    }, []);

    const handleRemoveThought = useCallback((thought) => {
        // Find the thought's sector if it exists
        if (thought.position) {
            const sectorX = Math.floor(thought.position.x / gridSize);
            const sectorY = Math.floor(thought.position.y / gridSize);
            const sectorKey = `${sectorX},${sectorY}`;

            // Remove this sector from occupied sectors
            setOccupiedSectors(prev => prev.filter(sector => sector !== sectorKey));
        }

        setThoughts(prev => prev.filter(t => t.id !== thought.id));
        setExpandedThoughtId(prevId => prevId === thought.id ? null : prevId);
    }, [gridSize, setThoughts]);

    const handleLikeThought = useCallback((thought) => {
        // Update the thought to show it was liked
        setThoughts(prev => prev.map(t =>
            t.id === thought.id ? {...t, liked: true, disliked: false} : t
        ));

        // Add visual feedback that it was liked
        setTimeout(() => {
            handleAddThought(thought);
            // Show celebration effect
            setShowCelebration(true);
            setTimeout(() => setShowCelebration(false), 3000);
        }, 300);
    }, [handleAddThought, setThoughts]);

    const handleDislikeThought = useCallback((thought) => {
        setThoughts(prev => prev.map(t =>
            t.id === thought.id ? {...t, disliked: true, liked: false} : t
        ));

        // Remove the thought after a short delay
        setTimeout(() => {
            handleRemoveThought(thought);
        }, 300);
    }, [handleRemoveThought, setThoughts]);

    const clearAllThoughts = useCallback(() => {
        setThoughts([]);
        setExpandedThoughtId(null);
        setOccupiedSectors([]); // Clear occupied sectors
    }, [setThoughts]);

    // Generate a new diverse thought
    const generateDiverseThought = useCallback(() => {
        // Choose a random category
        const randomCategory = categories.filter(c => c !== 'All')[
            Math.floor(Math.random() * (categories.length - 1))
            ];

        // Select a suggestion from the category that isn't already used
        const existingContents = thoughts.map(t => t.content);
        const availableSuggestions = DIVERSE_SUGGESTIONS[randomCategory].filter(
            suggestion => !existingContents.includes(suggestion)
        );

        // If all suggestions are used, recycle one
        const content = availableSuggestions.length > 0
            ? availableSuggestions[Math.floor(Math.random() * availableSuggestions.length)]
            : DIVERSE_SUGGESTIONS[randomCategory][Math.floor(Math.random() * DIVERSE_SUGGESTIONS[randomCategory].length)];

        // Find a non-overlapping position
        const position = findAvailablePosition(thoughts);

        // Select a color based on category
        const categoryColors = CATEGORY_COLORS[randomCategory];
        const color = categoryColors[Math.floor(Math.random() * categoryColors.length)];

        // Create a new thought
        const newThought = {
            id: `thought_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`,
            content,
            category: randomCategory,
            color,
            position: {
                x: position.x,
                y: position.y
            }
        };

        // Add to thoughts
        setThoughts(prevThoughts => [...prevThoughts, newThought]);

        return newThought;
    }, [categories, thoughts, findAvailablePosition, setThoughts]);

    // Function to generate confetti elements for celebration
    const generateConfetti = useCallback(() => {
        const confetti = [];
        const colors = ['#4f46e5', '#10b981', '#8b5cf6', '#ffcd1f', '#ef4444'];

        for (let i = 0; i < 50; i++) {
            const left = Math.random() * 100;
            const animationDuration = 3 + Math.random() * 2;
            const color = colors[Math.floor(Math.random() * colors.length)];
            const size = 5 + Math.random() * 10;
            const delay = Math.random() * 0.5;

            confetti.push(
                <div
                    key={i}
                    className={styles['taytrom-confetti']}
                    style={{
                        left: `${left}%`,
                        width: `${size}px`,
                        height: `${size}px`,
                        backgroundColor: color,
                        animationDuration: `${animationDuration}s`,
                        animationDelay: `${delay}s`
                    }}
                />
            );
        }

        return confetti;
    }, []);

    // Handle keyboard shortcuts
    useEffect(() => {
        const handleKeyDown = (e) => {
            // 'n' to generate new thought
            if (e.key === 'n' && !isProcessing) {
                generateNewThought();
            }
            // 'Escape' to collapse expanded thought
            else if (e.key === 'Escape') {
                if (expandedThoughtId) {
                    setExpandedThoughtId(null);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [expandedThoughtId, generateNewThought, isProcessing]);

    // Render skeleton loading items for loading state
    const renderSkeletonItems = () => {
        return Array(6).fill(0).map((_, i) => (
            <div
                key={`skeleton_${i}`}
                className={styles['taytrom-skeleton']}
                style={{
                    height: '150px',
                    borderRadius: 'var(--tacs-radius-xl)'
                }}
            />
        ));
    };

    return (
        <div
            className={styles['taytrom-ai-suggestions']}
            role="region"
            aria-label="AI Suggestions"
        >
            {/* Header with title and controls */}
            <div className={styles['taytrom-ai-suggestions-header']}>
                <h3 className={styles['taytrom-ai-suggestions-title']}>
                    <div className={styles['taytrom-ai-suggestions-badge']}>
                        <Sparkles size={16} className={styles['taytrom-sparkle-animation']} />
                        <span className={styles['taytrom-ai-suggestions-badge-text']}>AI Magic</span>
                    </div>
                    Smart Suggestions
                    {isProcessing && <Loader size={16} className={styles['taytrom-spin-animation']} />}
                </h3>

                <div>
                    {/* Clear all thoughts */}
                    <button
                        className={`${styles['taytrom-ai-suggestions-action']} ${thoughts.length === 0 ? styles['taytrom-ai-suggestions-action--disabled'] : ''}`}
                        onClick={clearAllThoughts}
                        disabled={thoughts.length === 0}
                        title="Clear all suggestions"
                        aria-label="Clear all suggestions"
                    >
                        <X size={18} />
                    </button>
                </div>
            </div>

            {/* Category filter bar */}
            <div
                className={styles['taytrom-ai-suggestions-filters']}
                role="group"
                aria-label="Filter suggestions by category"
            >
                {categories.map(category => (
                    <CategoryFilterButton
                        key={category}
                        category={category}
                        active={selectedCategory === category}
                        onClick={setSelectedCategory}
                        color={
                            category === 'Structure' ? '#3b82f6' :
                                category === 'Content' ? '#10b981' :
                                    category === 'Style' ? '#8b5cf6' :
                                        '#64748b'
                        }
                    />
                ))}
            </div>

            {/* Drag instructions - updated for enhancement tone */}
            <div className={styles['taytrom-ai-suggestions-instructions']}>
                <div className={styles['taytrom-ai-suggestions-instructions-text']}>
                    <Wand2 size={18} className={styles['taytrom-pulse-icon']} />
                    <span><strong>Drag suggestions to transform sections with AI magic</strong></span>
                </div>
            </div>

            {/* Onboarding overlay for first-time users */}
            {showOnboarding && (
                <div className={styles['taytrom-ai-suggestions-onboarding']}>
                    <div className={styles['taytrom-ai-suggestions-onboarding-content']}>
                        <div className={styles['taytrom-ai-suggestions-onboarding-icon']}>
                            <Sparkles size={28} className={styles['taytrom-sparkle-animation']} />
                        </div>

                        <h3 className={styles['taytrom-ai-suggestions-onboarding-title']}>
                            Instant Content Transformation
                        </h3>

                        <p className={styles['taytrom-ai-suggestions-onboarding-description']}>
                            <strong style={{color: 'var(--tacs-brand-primary)'}}>Enhance your document with smart suggestions!</strong> Drag any suggestion directly onto a section to transform it with AI magic.
                        </p>

                        <div className={styles['taytrom-ai-suggestions-onboarding-steps']}>
                            <div className={styles['taytrom-ai-suggestions-onboarding-step']}>
                                <div className={styles['taytrom-ai-suggestions-onboarding-step-number']}>1</div>
                                <div className={styles['taytrom-ai-suggestions-onboarding-step-text']}>
                                    Drag any suggestion bubble to a document section
                                </div>
                            </div>

                            <div className={styles['taytrom-ai-suggestions-onboarding-step']}>
                                <div className={styles['taytrom-ai-suggestions-onboarding-step-number']}>2</div>
                                <div className={styles['taytrom-ai-suggestions-onboarding-step-text']}>
                                    The section will be instantly transformed with AI magic
                                </div>
                            </div>

                            <div className={styles['taytrom-ai-suggestions-onboarding-step']}>
                                <div className={styles['taytrom-ai-suggestions-onboarding-step-number']}>3</div>
                                <div className={styles['taytrom-ai-suggestions-onboarding-step-text']}>
                                    Use Ctrl+Z or the Undo button if you want to revert changes
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => {
                                setShowOnboarding(false);
                                localStorage.setItem('hasSeenDragOnboarding', 'true');
                            }}
                            className={styles['taytrom-ai-suggestions-onboarding-button']}
                        >
                            Got it!
                        </button>
                    </div>
                </div>
            )}

            {/* Drag mode hint tooltip */}
            {showDragHint && (
                <div className={styles['taytrom-ai-suggestions-drag-hint']}>
                    <Sparkles size={24} className={styles['taytrom-sparkle-animation']} style={{marginBottom: '12px'}} />
                    <p className={styles['taytrom-ai-suggestions-drag-hint-title']}>AI Magic Active!</p>
                    <p className={styles['taytrom-ai-suggestions-drag-hint-text']}>
                        Drag suggestions to transform your content instantly
                    </p>
                </div>
            )}

            {/* Confetti celebration effect */}
            {showCelebration && (
                <div className={styles['taytrom-celebrate-container']}>
                    {generateConfetti()}
                </div>
            )}

            {/* Main content area */}
            <div className={styles['taytrom-ai-suggestions-content']}>
                {/* Grid view */}
                <div
                    className={styles['taytrom-ai-suggestions-grid']}
                    role="list"
                    aria-label="Suggestion grid"
                >
                    {isProcessing && filteredThoughts.length === 0 && renderSkeletonItems()}

                    {filteredThoughts.map((thought) => (
                        <div
                            key={thought.id}
                            className={`${styles['taytrom-suggestion-bubble']} ${styles['taytrom-draggable-suggestion']} ${thought.liked ? styles['taytrom-suggestion-liked'] : ''} ${thought.disliked ? styles['taytrom-suggestion-disliked'] : ''}`}
                            style={{backgroundColor: thought.color}}
                            onClick={() => handleThoughtClick(thought)}
                            tabIndex={0}
                            role="listitem"
                            aria-label={`Suggestion: ${thought.content}. Drag to enhance section`}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    e.preventDefault();
                                    handleThoughtClick(thought);
                                }
                            }}
                            draggable={true}
                            onDragStart={(e) => {
                                e.dataTransfer.setData('application/json', JSON.stringify(thought));
                                e.dataTransfer.effectAllowed = 'copy';
                                e.currentTarget.classList.add(styles['taytrom-dragging']);
                            }}
                            onDragEnd={(e) => {
                                e.currentTarget.classList.remove(styles['taytrom-dragging']);
                            }}
                        >
                            {/* Enhancement badge */}
                            <div className={styles['taytrom-suggestion-badge']}>
                                ENHANCE
                            </div>

                            <div className={styles['taytrom-suggestion-category']}>
                                {thought.category}
                            </div>

                            <div className={styles['taytrom-suggestion-content']}>
                                {thought.content}
                            </div>

                            {/* Shine effect to indicate draggability */}
                            <div className={styles['taytrom-suggestion-shine']} />

                            <div className={styles['taytrom-suggestion-actions']}>
                                <div className={styles['taytrom-suggestion-drag-hint']}>
                                    <Wand2 size={12} className={styles['taytrom-drag-icon-animation']} />
                                    <span>Drag to transform</span>
                                </div>

                                <button
                                    className={styles['taytrom-suggestion-remove']}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveThought(thought);
                                    }}
                                    title="Remove suggestion"
                                    aria-label="Remove suggestion"
                                >
                                    <X size={12} color="white" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Empty state */}
                {filteredThoughts.length === 0 && !isProcessing && (
                    <div
                        className={styles['taytrom-ai-suggestions-empty']}
                        aria-live="polite"
                    >
                        <div className={styles['taytrom-ai-suggestions-empty-icon']}>
                            <Sparkles size={32} className={styles['taytrom-pulse-icon']} />
                        </div>
                        <p>No enhancement suggestions available. Generate ideas below.</p>

                        {/* Generate buttons inside the component */}
                        <div className={styles['taytrom-ai-suggestions-buttons']}>
                            <button
                                className={`${styles['taytrom-button']} ${styles['taytrom-button--primary']}`}
                                onClick={generateDiverseThought}
                            >
                                <Sparkles size={14} className={styles['taytrom-sparkle-animation']} />
                                Generate Suggestion
                            </button>

                            <button
                                className={`${styles['taytrom-button']} ${styles['taytrom-button--secondary']}`}
                                onClick={generateMultipleThoughts}
                            >
                                <PlusCircle size={14} />
                                Generate Multiple
                            </button>
                        </div>
                    </div>
                )}

                {/* Loading state */}
                {filteredThoughts.length === 0 && isProcessing && (
                    <div
                        className={styles['taytrom-ai-suggestions-loading']}
                        aria-live="polite"
                    >
                        <Loader size={24} className={styles['taytrom-spin-animation']} />
                        <p>AI is generating new suggestions...</p>
                    </div>
                )}
            </div>

            {/* Keyboard shortcuts help */}
            <div className={styles['taytrom-ai-suggestions-shortcuts']}>
                <div className={styles['taytrom-ai-suggestions-shortcut-title']}>Keyboard Shortcuts:</div>
                <div><span className={styles['taytrom-ai-suggestions-shortcut-key']}>N</span> Generate new suggestion</div>
                <div><span className={styles['taytrom-ai-suggestions-shortcut-key']}>Esc</span> Collapse expanded suggestion</div>
                <div><span className={styles['taytrom-ai-suggestions-shortcut-key']}>Tab</span> Navigate between suggestions</div>
                <div><span className={styles['taytrom-ai-suggestions-shortcut-key']}>Enter</span> Select suggestion</div>
            </div>

            {/* Action buttons - now inside the component body instead of floating */}
            {useInternalButtons && filteredThoughts.length > 0 && (
                <div className={styles['taytrom-ai-suggestions-actions']}>
                    {/* Generate multiple button */}
                    <button
                        className={`${styles['taytrom-button']} ${styles['taytrom-button--secondary']} ${isProcessing ? styles['taytrom-button--disabled'] : ''}`}
                        disabled={isProcessing}
                        title="Generate multiple suggestions"
                        aria-label="Generate multiple suggestions"
                        onClick={generateMultipleThoughts}
                    >
                        <PlusCircle size={18} />
                        <span>Generate More</span>
                    </button>

                    {/* Single generate button (main action) */}
                    <button
                        className={`${styles['taytrom-button']} ${styles['taytrom-button--primary']} ${isProcessing ? styles['taytrom-button--disabled'] : ''}`}
                        disabled={isProcessing}
                        title="Generate new suggestion"
                        aria-label="Generate new suggestion"
                        onClick={generateNewThought}
                    >
                        {isProcessing ?
                            <><Loader size={18} className={styles['taytrom-spin-animation']} /> Processing...</> :
                            <><Sparkles size={18} className={styles['taytrom-sparkle-animation']} /> New Suggestion</>
                        }
                    </button>
                </div>
            )}
        </div>
    );
};

export default AISuggestions;