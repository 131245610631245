// In Substance_Pillars_TaytromLanding_Homepage.jsx

import React from "react";
import {
    Brain,
    Fingerprint,
    BrainCircuit,
    Lightbulb,
    Network,
    GitPullRequest,
    Shield,
    Crown,
    Flame,
    Award,
    Microscope,
    BookMarked,
    Hexagon,
    Layers,
    TableCellsSplit,
    Landmark
} from "lucide-react";

// Updated import paths with absolute imports
// Make sure these match your actual file structure
import TaytromTACSPillarSVG from "@/homepage-scope/pillars/CELL/CSS/svg/tacs/TACS_SVG_Platforms_TaytromLanding.jsx";
import TaytromExchangePillar from "@/homepage-scope/pillars/CELL/CSS/svg/exchange/Exchage_SVG_Platforms_TaytromLanding.jsx";
import TaytromAIPillar from "@/homepage-scope/pillars/CELL/CSS/svg/ai/AI_SVG_Platforms_TaytromLanding.jsx";
import TaytromAcademyPillar from "@/homepage-scope/pillars/CELL/CSS/svg/academy/Academy_SVG_Platforms_TaytromLanding.jsx";

// Helper component for consistent SVG integration - updated for better positioning
const PillarVisualization = ({ component: Component, colorScheme }) => {
    // Map color scheme to appropriate colors
    const getPillarColors = (scheme) => {
        switch (scheme) {
            case "primary":
                return {
                    primaryColor: "#7C3AED", // Purple
                    secondaryColor: "#0EA5E9", // Blue
                    accentColor: "#F97316" // Orange
                };
            case "info":
                return {
                    primaryColor: "#0891B2", // Cyan
                    secondaryColor: "#8B5CF6", // Violet
                    accentColor: "#10B981" // Green
                };
            case "accent":
                return {
                    primaryColor: "#F97316", // Orange
                    secondaryColor: "#7C3AED", // Purple
                    accentColor: "#2563EB" // Blue
                };
            default:
                return {
                    primaryColor: "#14B8A6", // Teal
                    secondaryColor: "#0EA5E9", // Blue
                    accentColor: "#2563EB" // Deeper blue
                };
        }
    };

    // Get colors based on colorScheme
    const colors = getPillarColors(colorScheme);

    return (
        <div className="flex justify-center items-center w-full h-full">
            <div style={{
                width: "100%",
                maxWidth: "280px", // Control maximum width
                transform: "scale(0.85)", // Scale down slightly for better fit
                display: "flex",
                justifyContent: "center"
            }}>
                <Component
                    size="100%"
                    primaryColor={colors.primaryColor}
                    secondaryColor={colors.secondaryColor}
                    accentColor={colors.accentColor}
                    reducedMotion={false}
                    glowEffect={true}
                    animate={true}
                    interactive={true}
                    className="w-full h-auto"
                />
            </div>
        </div>
    );
};

export const Substance_Pillars_TaytromLanding_Homepage = [
    {
        id: 'ai',
        title: 'AI: Contextual Intelligence',
        description: "At the heart of Taytrom lies a sophisticated AI system that understands your unique context. Through neural-matched communication and personalized guidance, our AI evolves with your development journey, making complex concepts immediately intuitive by bridging to your existing knowledge domains.",
        visualization: <PillarVisualization
            component={TaytromAIPillar}
            colorScheme="primary"
        />,
        features: [
            { text: 'Personalized Intelligence', icon: <Fingerprint size={16} /> },
            { text: 'Neural-Matched Communication', icon: <BrainCircuit size={16} /> },
            { text: 'Cognitive Familiarity Enhancement', icon: <Lightbulb size={16} /> }
        ],
        detailedFeatures: [
            "**Personal Context Revolution:** Through a comprehensive orientation profile, Taytrom creates a multi-dimensional understanding of your learning style, professional background, personal interests, and technical experience.",
            "**Context-Aware Intelligence:** The system automatically analyzes your current development context and delivers relevant guidance without requiring explicit queries.",
            "**Neural-Matched Communication:** All explanations are automatically translated to align with your cognitive preferences, making complex concepts immediately intuitive.",
            "**Progressive Knowledge Adaptation:** As you advance through certification levels, the AI adjusts its guidance to match your growing expertise.",
            "**Intelligent Pair Programming:** The AI becomes an active collaborator in your development process, suggesting approaches aligned with your specific context."
        ],
        linkTo: '/ai',
        colorScheme: "primary",
    },
    {
        id: 'exchange',
        title: 'Exchange: Evolution of the Marketplace',
        description: "The Exchange is a revolutionary marketplace where code becomes cultural artifact. Through the Component Factory, blockchain verification, and the Certified Dreamer requirement, we ensure that components are not just functional code but verified digital assets with established provenance and assured quality.",
        visualization: <PillarVisualization
            component={TaytromExchangePillar}
            colorScheme="info"
        />,
        features: [
            { text: 'Component Factory', icon: <GitPullRequest size={16} /> },
            { text: 'Blockchain Verification', icon: <Shield size={16} /> },
            { text: 'Certified Dreamer Economy', icon: <Crown size={16} /> }
        ],
        detailedFeatures: [
            "**The Component Factory:** A revolutionary approach to software creation that bridges the gap between visual design and technical implementation, enabling anyone to create professional-quality components.",
            "**Blockchain-Verified Provenance:** Complete ownership and exhibition history maintained on the blockchain, creating an immutable record of each component's lifecycle.",
            "**TACS Compliance Verification:** Each component undergoes rigorous automated testing against all TACS principles, receiving detailed scores across multiple dimensions of quality.",
            "**The Certified Dreamer Requirement:** Only developers who have completed all six TACS certifications can list components for sale, creating a meritocratic system that scales quality assurance.",
            "**Smart License Contracts:** Blockchain-based licensing automatically manages usage rights, attribution requirements, and royalty distributions."
        ],
        linkTo: '/exchange',
        colorScheme: "info",
    },
    {
        id: 'academy',
        title: 'Academy: Neural Revolution in Education',
        description: "The Academy transforms programming education through neural-matched learning, specialized environments, and cognitive familiarity enhancement. By mapping new concepts to your existing knowledge domains, we collapse traditional learning timelines from months to days.",
        visualization: <PillarVisualization
            component={TaytromAcademyPillar}
            colorScheme="primary"
        />,
        features: [
            { text: 'Six TACS Certifications', icon: <Award size={16} /> },
            { text: 'The Anatomy of Code', icon: <Microscope size={16} /> },
            { text: 'Golden Template Approach', icon: <BookMarked size={16} /> }
        ],
        detailedFeatures: [
            "**Progressive Certification Path:** Six comprehensive certifications that build from foundational concepts to complete mastery of the TACS framework.",
            "**The Anatomy of Code:** An immersive educational environment where code is dissected like biological specimens, revealing the intricate structures that power applications.",
            "**Cognitive Familiarity Enhancement:** A revolutionary methodology that adapts programming concepts to knowledge domains you already understand.",
            "**Super Beginner Program:** For those with no prior experience, an accessible entry point using block-based visual programming and sequence-based learning.",
            "**Golden Template Approach:** AI-powered personalization generates infinite variations of content tailored to your unique cognitive profile."
        ],
        linkTo: '/academy',
        colorScheme: "primary",
    },
    {
        id: 'tacs',
        title: 'TACS: The Universal Language',
        description: "TACS is the universal language of human-AI collaboration, establishing predictable patterns that both intelligences can navigate efficiently. Its five integrated principles—STACK, CSS, SPACE, TIES, and AOS—create a comprehensive framework that enables seamless collaboration between human creativity and artificial intelligence.",
        visualization: <PillarVisualization
            component={TaytromTACSPillarSVG}
            colorScheme="accent"
        />,
        features: [
            { text: 'Cognitive Topology (STACK)', icon: <Layers size={16} /> },
            { text: 'Triadic Separation (CSS)', icon: <TableCellsSplit size={16} /> },
            { text: 'Constitutional Monarchy (AOS)', icon: <Landmark size={16} /> }
        ],
        detailedFeatures: [
            "**STACK: Cognitive Topology:** A five-level hierarchy (Scope, Tank, Atom, Cell, Kernel) that creates a universal cognitive topology both human and artificial intelligence can navigate efficiently.",
            "**CSS: Triadic Separation:** Clean separation of concerns (Construction, Substance, Style) that creates boundaries along cognitive lines both intelligences can navigate effectively.",
            "**SPACE: Dimensional Organization:** Organization of styling properties into natural categories (Structure, Positioning, Animation, Color, Environment) that reflect the inherent structure of visual styling.",
            "**TIES: Universal Communication:** Standardized protocol (Target, ID, Enum, Source) that creates clarity, traceability, and consistency across system interactions.",
            "**AOS: Constitutional Monarchy:** Centralized architecture providing order, predictability, and traceability essential for human-AI collaboration."
        ],
        linkTo: '/tacs',
        colorScheme: "accent",
    }
];
// Updated TaytromPillarsSection component with empty icon containers removed
export const TaytromPillarsSection = () => {
    return (
        <section className="py-16 bg-gray-50">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-12">The Four Pillars of Taytrom</h2>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                    {Substance_Pillars_TaytromLanding_Homepage.map((pillar) => (
                        <div
                            key={pillar.id}
                            className="bg-white rounded-xl shadow-xl overflow-hidden"
                        >
                            <div className="p-6">
                                <h3 className="text-xl font-semibold mb-4">{pillar.title}</h3>

                                <p className="text-gray-600 mb-6">{pillar.description}</p>

                                <div className="h-72 mb-6">
                                    {pillar.visualization}
                                </div>

                                {/* Fixed feature list to handle icons properly */}
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-6">
                                    {pillar.features.map((feature, index) => (
                                        <div key={index} className="flex items-center">
                                            {feature.icon && (
                                                <span className="text-primary-500 mr-2">
                                                    {feature.icon}
                                                </span>
                                            )}
                                            <span className="text-sm">{feature.text}</span>
                                        </div>
                                    ))}
                                </div>

                                <a
                                    href={pillar.linkTo}
                                    className="inline-block px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition"
                                >
                                    Learn More
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
export default TaytromPillarsSection;