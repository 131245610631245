// FullDevApp.jsx updated for Vite
import React, { lazy, Suspense, createContext, useContext, useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import styles from './TACS2.module.css';

// Import the Navigation component
import Horizontal_Navigate_Phase1
    from "@/navigate-domain/horizontal/Horizontal_Navigate_Phase1.jsx";

// Import the TaytromColorSystemProvider
import { System_Colors_Templates} from "@/templates-scope/colors/system/System_Colors_Templates.jsx";

// Eagerly load the landing page since it's our main entry point
import TaytromLanding from './homepage-scope/Construction/Construction_TaytromLanding_Homepage.jsx';

// Import AuthProvider and ProtectedRoute from FrontAutocrat
import { AuthProvider, ProtectedRoute } from './FrontAutocrat.jsx';

// Create contexts for the Claude AI integration and theme
export const OrchestratorContext = createContext(null);
export const ThemeContext = createContext({
    isDark: true,
    toggleTheme: () => {},
    theme: 'dark',
    themeColors: {
        primary: '#20B2AA',
        secondary: '#4682B4',
        accent: '#FFD700'
    }
});

// Custom hook to use the orchestrator
export const useOrchestrator = () => {
    const context = useContext(OrchestratorContext);
    if (!context) {
        console.warn('useOrchestrator must be used within an OrchestratorProvider');
    }
    return context;
};

// Custom hook to use the theme
export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        console.warn('useTheme must be used within a ThemeProvider');
    }
    return context;
};

// Lazy load all other pages - updated to use dynamic imports with Vite

//-------------ADMIN-----------------------
const AdminAIChat = lazy(() => import('./admin-scope/ai/chat/compiler/Compiler_Chat_AI_Admin.jsx'));


//-------------WRITING-----------------------
const VisualWritingPlatform = lazy(() => import('./writing-scope/compiler/Compiler_Writing.jsx'));


const BAndALanding = lazy(() => import('./academy-scope/Front_BAndAAcademy_BAndALanding_Component.jsx'));
const ProgramsLanding = lazy(() => import('./academy-scope/programs/Front_BAndAAcademy_ProgramsLanding_Component.jsx'));
const ReactCompositionProgramLanding = lazy(() => import('./academy-scope/programs/react-composition/Front_BAndAAcademy_ReactCompositionProgramLanding_Component.jsx'));
const Expedition1 = lazy(() => import('./academy-scope/programs/react-composition/expeditions/expedition_1/compiler/Compiler_Expedition_1.jsx'));
const SoftwareArchitectureProgramLanding = lazy(() => import('./academy-scope/programs/software-architecture/Front_BAndAAcademy_SoftwareArchitectureProgramLanding_Component.jsx'));
const SettingsPage = lazy(() => import('./user-scope/settings/Settings_UserScope.jsx'));
// Add this with the other lazy-loaded components




// ACADEMY
const SuperBeginnerProgramLanding = lazy(() => import('./academy-scope/programs/super_beginner/SuperBeginner_Program_Academy.jsx'));
const SuperBeginnerModule1 = lazy(() => import('./academy-scope/programs/super_beginner/modules/1/1_Modules_SuperBeginner_Program_Academy.jsx'));
const SuperBeginnerModule2 = lazy(() => import('./academy-scope/programs/super_beginner/modules/2/2_Modules_SuperBeginner_Program_Academy.jsx'));
const SuperBeginnerModule3 = lazy(() => import('./academy-scope/programs/super_beginner/modules/3/3_Modules_SuperBeginner_Program_Academy.jsx'));
const SuperBeginnerModule4 = lazy(() => import('./academy-scope/programs/super_beginner/modules/4/4_Modules_SuperBeginner_Program_Academy.jsx'));
const SuperBeginnerModule5 = lazy(() => import('./academy-scope/programs/super_beginner/modules/5/5_Modules_SuperBeginner_Program_Academy.jsx'));
// Add imports for individual lessons of Module 1
const SuperBeginnerModule1Lesson1 = lazy(() => import('./academy-scope/programs/super_beginner/modules/1/lessons/1/compiler/Compiler_1_Lessons_1_Modules_SuperBeginner_Program_Academy.jsx'));
const SuperBeginnerModule1Lesson2 = lazy(() => import('./academy-scope/programs/super_beginner/modules/1/lessons/2/2_Lessons_1_Modules_SuperBeginner_Program_Academy.jsx'));
const SuperBeginnerModule1Lesson3 = lazy(() => import('./academy-scope/programs/super_beginner/modules/1/lessons/3/3_Lessons_1_Modules_SuperBeginner_Program_Academy.jsx'));
const SuperBeginnerModule1Lesson4 = lazy(() => import('./academy-scope/programs/super_beginner/modules/1/lessons/4/4_Lessons_1_Modules_SuperBeginner_Program_Academy.jsx'));

// Exchange components
const ExchangeLanding = lazy(() => import('./exchange_domain/Front_Exchange_ExchangeLanding_Component.jsx'));
const DiscussionLanding = lazy(() => import('./exchange_domain/discussion-platform/DiscussionLanding_Exchange_Component.jsx'));
const GalleriaLanding = lazy(() => import('./exchange_domain/galleria/GalleriaLanding_Exchange.jsx'));

// TACS components
const TACSLanding = lazy(() => import('./tacs-domain/TACSLanding_TACSDomain.jsx'));
const TACSDocs = lazy(() => import('./tacs-domain/documentation/TACSDocumentation_TACSDomain.jsx'));
const TACSTermsGallery = lazy(() => import('./tacs-domain/terms/gallery/TACSTermsGallery_TACSDomain.jsx'));
const DefinitionsRelationshipGraph = lazy(() => import('./tacs-domain/terms/graph/TermsRelationshipGraph_TACSDomain.jsx'));
const TACSCertification = lazy(() => import('./tacs-domain/certification/TACSCertification_TACSDomain.jsx'));
const TACSExamples = lazy(() => import('./tacs-domain/examples/TACSExamples_TACSDomain.jsx'));
const Interactive_Manifesto = lazy(() => import("./tacs-domain/tacs_ten/carousel/Carousel_TACS_Ten.jsx"));
const Manifesto_Landing = lazy(() => import("./tacs-domain/tacs_ten/landing/Landing_TACS_Ten.jsx"));

const About_UserDomains = lazy(()=>import('./user-scope/about/About_UserScope.jsx'));
// Auth components
const Registration = lazy(() => import('./authentication-scope/registration/Front_Auth_Registration_Component.jsx'));
const SignIn = lazy(() => import('./authentication-scope/sign-in/Front_Auth_SignIn_Component.jsx'));
const ForgotPassword = lazy(() => import('./authentication-scope/forgot-password/Front_Auth_ForgotPassword_Component.jsx'));
const BillingComponent = lazy(() => import('./authentication-scope/billing/Billing_Authentication.jsx'));












// TACS Construction components
const CssConstruction = lazy(() => import('./tacs-domain/construction/css/CSS_Construction_Tacs.jsx'));
const CcssConstruction = lazy(() => import('./tacs-domain/construction/stack/Stack_System_Tacs.jsx'));
const SpaceConstruction = lazy(() => import('./tacs-domain/construction/space/Space_Construction_TACS.jsx'));
const StackConstruction = lazy(() => import('./tacs-domain/construction/ties/Ties_Construction_Tacs.jsx'));
const TiesConstruction = lazy(() => import('./tacs-domain/construction/ccss/CCSS_Construction_TACS.jsx'));





// Loading component for Suspense fallback
const LoadingScreen = () => (
    <div className={`${styles['flex-center']} ${styles['min-h-screen']} ${styles['bg-surface']}`}>
        <div className={`${styles['substance-primary']} ${styles['substance-xl']} ${styles['animate-pulse']}`}>
            Loading...
        </div>
    </div>
);

const FullDevApp = ({ orchestrator }) => {
    // Initialize theme state with dark mode as default
    // Use localStorage to persist the theme preference
    const [isDark, setIsDark] = useState(() => {
        const savedTheme = localStorage.getItem('taytrom-theme');
        // If theme is saved in localStorage, use it; otherwise default to dark
        return savedTheme ? savedTheme === 'dark' : true;
    });

    // Define theme colors based on TACS variables
    const themeColors = {
        primary: isDark ? 'var(--tacs-primary-500)' : 'var(--tacs-primary-600)',
        secondary: isDark ? 'var(--tacs-secondary-500)' : 'var(--tacs-secondary-600)',
        accent: isDark ? 'var(--tacs-accent-500)' : 'var(--tacs-accent-600)',
        background: isDark ? 'var(--tacs-dark-bg-surface)' : 'var(--tacs-light-bg-surface)',
        text: isDark ? 'var(--tacs-dark-substance-primary)' : 'var(--tacs-light-substance-primary)'
    };

    // Toggle theme function
    const toggleTheme = () => {
        setIsDark(!isDark);
    };

    // Update localStorage and document-panel class when theme changes
    useEffect(() => {
        // Save theme preference to localStorage
        localStorage.setItem('taytrom-theme', isDark ? 'dark' : 'light');

        // Update the document-panel element with data-theme attribute according to TACS standard
        document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');

        // If orchestrator is available, notify it about the theme change
        if (orchestrator && orchestrator.subscriptionManager) {
            orchestrator.subscriptionManager.notify('theme', {
                type: 'THEME_CHANGED',
                theme: isDark ? 'dark' : 'light',
                themeColors: {
                    primary: isDark ? 'var(--tacs-primary-500)' : 'var(--tacs-primary-600)',
                    secondary: isDark ? 'var(--tacs-secondary-500)' : 'var(--tacs-secondary-600)',
                    accent: isDark ? 'var(--tacs-accent-500)' : 'var(--tacs-accent-600)',
                    background: isDark ? 'var(--tacs-dark-bg-surface)' : 'var(--tacs-light-bg-surface)',
                    text: isDark ? 'var(--tacs-dark-substance-primary)' : 'var(--tacs-light-substance-primary)'
                }
            });
        }
    }, [isDark, orchestrator]);

    // Log theme information according to TACS Wx4H standard
    const logWx4H = (who, what, why, how) => {
        console.log(`[WHO: ${who}] [WHAT: ${what}] [WHY: ${why}] [HOW: ${how}]`);
    };

    // Log theme orchestration information
    useEffect(() => {
        logWx4H(
            'FullDevApp',
            'Theme Orchestration',
            'Centralizing theme management according to TACS standards',
            'Using ThemeContext with data-theme attribute and CSS variables from TACS.module.css'
        );
    }, []);

    return (
        <OrchestratorContext.Provider value={orchestrator}>
            <ThemeContext.Provider value={{
                isDark,
                toggleTheme,
                theme: isDark ? 'dark' : 'light',
                themeColors
            }}>
                {/* Wrap everything with TaytromColorSystemProvider to ensure consistent colors */}
                <System_Colors_Templates>
                <Router>
                    <AuthProvider orchestrator={orchestrator}>
                        <div className={styles['TANK-full-width']}>
                            {/* Navigation component with theme props */}
                            <Horizontal_Navigate_Phase1
                                frontgod={orchestrator}
                                isDark={isDark}
                                onToggleTheme={toggleTheme}
                            />

                            <Suspense fallback={<LoadingScreen />}>
                                <Routes>

                                    {/* Public route_patterns - accessible without authentication */}
                                    <Route path="/" element={<TaytromLanding />} />
                                    {/* ---------------AUTH------------------- */}
                                    <Route path="/register" element={<Registration />} />
                                    <Route path="/signin" element={<SignIn />} />
                                    <Route path="/forgot-password" element={<ForgotPassword />} />
                                    <Route path="/billing" element={
                                        <ProtectedRoute>
                                            <BillingComponent />
                                        </ProtectedRoute>
                                    } />
                                    {/* Protected route_patterns - require authentication */}
                                    {/* Academy route_patterns */}
                                    <Route path="/academy" element={
                                        <ProtectedRoute>
                                            <BAndALanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs" element={
                                        <ProtectedRoute>
                                            <ProgramsLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/react-composition" element={
                                        <ProtectedRoute>
                                            <ReactCompositionProgramLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/react-composition/expeditions/1" element={
                                        <ProtectedRoute>
                                            <Expedition1 />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/software-architecture" element={
                                        <ProtectedRoute>
                                            <SoftwareArchitectureProgramLanding />
                                        </ProtectedRoute>
                                    } />













                                    <Route path="/settings" element={
                                        <ProtectedRoute>
                                            <SettingsPage />
                                        </ProtectedRoute>
                                    } />

                                    {/* Exchange route_patterns */}
                                    <Route path="/exchange" element={
                                        <ProtectedRoute>
                                            <ExchangeLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/exchange/browse" element={
                                        <ProtectedRoute>
                                            <ExchangeLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/exchange/become-seller" element={
                                        <ProtectedRoute>
                                            <ExchangeLanding />
                                        </ProtectedRoute>
                                    } />

                                    {/* Marketplace route_patterns */}
                                    <Route path="/exchange/galleria" element={
                                        <ProtectedRoute>
                                            <GalleriaLanding />
                                        </ProtectedRoute>
                                    } />


                                    {/* Discussion Platform route_patterns */}
                                    <Route path="/exchange/discussions" element={
                                        <ProtectedRoute>
                                            <DiscussionLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/exchange/discussions/browse" element={
                                        <ProtectedRoute>
                                            <DiscussionLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/exchange/discussions/new" element={
                                        <ProtectedRoute>
                                            <DiscussionLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/exchange/discussions/guidelines" element={
                                        <ProtectedRoute>
                                            <DiscussionLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/exchange/discussions/:topicId" element={
                                        <ProtectedRoute>
                                            <DiscussionLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/exchange/discussions/thread/:threadId" element={
                                        <ProtectedRoute>
                                            <DiscussionLanding />
                                        </ProtectedRoute>
                                    } />

                                    {/* TACS route_patterns */}
                                    <Route path="/tacs" element={
                                        <ProtectedRoute>
                                            <TACSLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/documentation" element={
                                        <ProtectedRoute>
                                            <TACSDocs />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/terms/gallery" element={
                                        <ProtectedRoute>
                                            <TACSTermsGallery />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/certification" element={
                                        <ProtectedRoute>
                                            <TACSCertification />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/examples" element={
                                        <ProtectedRoute>
                                            <TACSExamples />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/github" element={
                                        <ProtectedRoute>
                                            <TACSLanding />
                                        </ProtectedRoute>
                                    } />
                                    {/* About page route */}
                                    <Route path="/about" element={
                                        <ProtectedRoute>

                                        <About_UserDomains />
                                            </ProtectedRoute>

                                            } />
                                    {/* Redirect legacy TACS route_patterns */}
                                    <Route path="/exchange/tacs-certification" element={
                                        <ProtectedRoute>
                                            <TACSCertification />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/exchange/tacs-standard" element={
                                        <ProtectedRoute>
                                            <TACSLanding />
                                        </ProtectedRoute>
                                    } />
                                    {/* Manifesto Routes */}
                                    <Route path="/tacs/manifesto" element={
                                        <ProtectedRoute>
                                            <Manifesto_Landing />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/manifesto/interactive" element={
                                        <ProtectedRoute>
                                            <Interactive_Manifesto />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/manifesto/concise" element={
                                        <ProtectedRoute>
                                            <Interactive_Manifesto />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/manifesto/treatise" element={
                                        <ProtectedRoute>
                                            <Interactive_Manifesto />
                                        </ProtectedRoute>
                                    } />
                                    {/* TACS Construction Routes */}
                                    <Route path="/tacs/construction/css" element={
                                        <ProtectedRoute>
                                            <CssConstruction />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/construction/ccss" element={
                                        <ProtectedRoute>
                                            <CcssConstruction />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/construction/space" element={
                                        <ProtectedRoute>
                                            <SpaceConstruction />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/construction/stack" element={
                                        <ProtectedRoute>
                                            <StackConstruction />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/tacs/construction/ties" element={
                                        <ProtectedRoute>
                                            <TiesConstruction />
                                        </ProtectedRoute>
                                    } />




                                    {/* ---------------WRITING------------------- */}

                                    <Route path="/ai/writing" element={
                                        <ProtectedRoute>
                                            <VisualWritingPlatform />
                                        </ProtectedRoute>
                                    } />

                                    {/* ---------------ACADEMY------------------- */}
                                    <Route path="/academy/programs/super-beginner" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerProgramLanding />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/super-beginner/modules/1" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerModule1 />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/super-beginner/modules/2" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerModule2 />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/super-beginner/modules/3" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerModule3 />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/super-beginner/modules/4" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerModule4 />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/super-beginner/modules/5" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerModule5 />
                                        </ProtectedRoute>
                                    } />

                                    {/* Individual Lessons for Module 1 */}
                                    <Route path="/academy/programs/super-beginner/modules/1/lessons/1" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerModule1Lesson1 />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/super-beginner/modules/1/lessons/2" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerModule1Lesson2 />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/super-beginner/modules/1/lessons/3" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerModule1Lesson3 />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/academy/programs/super-beginner/modules/1/lessons/4" element={
                                        <ProtectedRoute>
                                            <SuperBeginnerModule1Lesson4 />
                                        </ProtectedRoute>
                                    } />
                                    {/* Admin Routes - restricted access */}
                                    <Route path="/admin/ai-console" element={
                                        <ProtectedRoute>
                                            <AdminAIChat />
                                        </ProtectedRoute>
                                    } />
                                </Routes>
                            </Suspense>
                        </div>
                    </AuthProvider>
                </Router>
                </System_Colors_Templates>
            </ThemeContext.Provider>
        </OrchestratorContext.Provider>
    );
};

export default FullDevApp;