import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Map,
    GitBranch,
    Zap,
    FileArchive,
    MoveHorizontal,
    MoveVertical,
    ChevronRight,
    ChevronDown,
    Info
} from 'lucide-react';
import styles from './Compiler_3_Steps_InteractiveTour_Specialists_Writing.module.css';

const Compiler_3_Steps_InteractiveTour_Specialists_Writing = ({
                                                                  isDark = true,
                                                                  onNext,
                                                                  onPrevious,
                                                                  orchestrator = null,
                                                                  tourProgress = 3,
                                                                  totalSteps = 7
                                                              }) => {
    // State management
    const [activeView, setActiveView] = useState('map'); // 'map', 'tree', 'outline'
    const [showTooltip, setShowTooltip] = useState(true);
    const [tooltipPosition, setTooltipPosition] = useState(1);
    const [diagramNodes, setDiagramNodes] = useState(initialNodes);
    const [showMinimap, setShowMinimap] = useState(false);
    const [expandedNodes, setExpandedNodes] = useState(['root', 'chapter1', 'chapter2']);
    const [draggedNode, setDraggedNode] = useState(null);
    const [catPosition, setCatPosition] = useState({ x: 50, y: 50 });
    const [catMessage, setCatMessage] = useState("Welcome to the Diagram View! Here you can visualize your document's structure.");

    const containerRef = useRef(null);
    const diagramRef = useRef(null);

    // Simulated document data
    const documentTitle = "Alice's Adventures in Wonderland";

    // Effects for animation sequences
    useEffect(() => {
        // Sequence for tooltip positions
        const tooltipTimer = setTimeout(() => {
            if (tooltipPosition < 4) {
                setTooltipPosition(prev => prev + 1);
            } else {
                setShowTooltip(false);
            }
        }, 8000);

        return () => clearTimeout(tooltipTimer);
    }, [tooltipPosition]);

    useEffect(() => {
        // Animate Cheshire cat moving to different diagram areas
        const catInterval = setInterval(() => {
            const randomX = Math.floor(Math.random() * 80);
            const randomY = Math.floor(Math.random() * 80);

            setCatPosition({ x: randomX, y: randomY });

            // Update cat messages
            const messages = [
                "Try dragging nodes to reorganize your document structure!",
                "Toggle between different visualization modes to see your document in new ways.",
                "Expand and collapse nodes to focus on specific parts of your document.",
                "The diagram automatically updates as you edit your document content.",
                "Click on any node to jump to that section in your document."
            ];

            const randomMessage = messages[Math.floor(Math.random() * messages.length)];
            setCatMessage(randomMessage);

        }, 12000);

        return () => clearInterval(catInterval);
    }, []);

    // Handlers
    const handleNodeClick = (nodeId) => {
        // Toggle expanded state for the clicked node
        if (expandedNodes.includes(nodeId)) {
            setExpandedNodes(expandedNodes.filter(id => id !== nodeId));
        } else {
            setExpandedNodes([...expandedNodes, nodeId]);
        }

        // Highlight effect on the corresponding document section
        const updatedNodes = diagramNodes.map(node => ({
            ...node,
            active: node.id === nodeId
        }));

        setDiagramNodes(updatedNodes);

        // Create spotlight effect around selected node
        const nodeElement = document.getElementById(`node-${nodeId}`);
        if (nodeElement) {
            nodeElement.classList.add(styles.nodePulse);
            setTimeout(() => nodeElement.classList.remove(styles.nodePulse), 1500);
        }

        // Update cat message based on selected node
        if (nodeId === 'root') {
            setCatMessage("This is the main document node. All sections branch from here!");
        } else if (nodeId.includes('chapter')) {
            setCatMessage("Chapters are high-level sections in your document. Try expanding this to see subsections!");
        } else if (nodeId.includes('scene')) {
            setCatMessage("Scenes are nested under chapters. You can drag them to reorganize your narrative!");
        }
    };

    const handleDragStart = (e, nodeId) => {
        setDraggedNode(nodeId);
        // Add visual feedback
        e.target.classList.add(styles.nodeDragging);

        // Update cat message
        setCatMessage("Wonderful! Now drag this node to a new position to rearrange your document!");
    };

    const handleDragEnd = (e) => {
        if (draggedNode) {
            e.target.classList.remove(styles.nodeDragging);

            // Show success animation
            const successElement = document.createElement('div');
            successElement.className = styles.successIndicator;
            successElement.textContent = 'Node Moved!';
            e.target.appendChild(successElement);

            setTimeout(() => {
                if (e.target.contains(successElement)) {
                    e.target.removeChild(successElement);
                }
            }, 2000);

            // Update cat message
            setCatMessage("Excellent! The document structure has been updated. Your document sections will now appear in this new order.");

            // Reset state
            setDraggedNode(null);
        }
    };

    const handleSwitchView = (view) => {
        setActiveView(view);

        // Update cat message based on view
        if (view === 'map') {
            setCatMessage("The Map view shows relationships between sections as a network diagram.");
        } else if (view === 'tree') {
            setCatMessage("The Tree view displays your document as a hierarchical structure, perfect for seeing nested sections.");
        } else if (view === 'outline') {
            setCatMessage("The Outline view presents a traditional document outline, making it easy to see your document's organization.");
        }

        // Display and animate a view change indicator
        const viewIndicator = document.createElement('div');
        viewIndicator.className = styles.viewChangeIndicator;
        viewIndicator.textContent = `${view.charAt(0).toUpperCase() + view.slice(1)} View`;
        diagramRef.current.appendChild(viewIndicator);

        setTimeout(() => {
            if (diagramRef.current.contains(viewIndicator)) {
                diagramRef.current.removeChild(viewIndicator);
            }
        }, 2000);
    };

    // Render functions
    const renderNode = (node, level = 0) => {
        const isExpanded = expandedNodes.includes(node.id);
        const hasChildren = node.children && node.children.length > 0;

        return (
            <div
                key={node.id}
                id={`node-${node.id}`}
                className={`${styles.diagramNode} ${node.active ? styles.activeNode : ''}`}
                style={{
                    marginLeft: `${level * 20}px`,
                    borderColor: node.color,
                    backgroundColor: node.active ? `${node.color}22` : 'transparent'
                }}
                onClick={() => handleNodeClick(node.id)}
                draggable={true}
                onDragStart={(e) => handleDragStart(e, node.id)}
                onDragEnd={handleDragEnd}
            >
                <div className={styles.nodeHeader}>
                    {hasChildren && (
                        <span className={styles.expandIcon}>
                            {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                        </span>
                    )}
                    <span className={styles.nodeIcon} style={{ backgroundColor: node.color }}>
                        {node.icon}
                    </span>
                    <span className={styles.nodeTitle}>{node.title}</span>
                </div>

                {isExpanded && hasChildren && (
                    <div className={styles.nodeChildren}>
                        {node.children.map(child => renderNode(child, level + 1))}
                    </div>
                )}
            </div>
        );
    };

    const renderTooltip = () => {
        if (!showTooltip) return null;

        const tooltips = [
            {
                position: { top: '20%', left: '20%' },
                title: "Document Structure",
                content: "This diagram visualizes your entire document structure. Each node represents a section of your document."
            },
            {
                position: { top: '40%', left: '60%' },
                title: "Interactive Nodes",
                content: "Click on any node to select it. You can expand, collapse, and reorganize nodes to change your document structure."
            },
            {
                position: { top: '70%', left: '30%' },
                title: "Visualization Modes",
                content: "Switch between different views to see your document structure in various ways - as a map, tree, or traditional outline."
            },
            {
                position: { top: '50%', left: '40%' },
                title: "Drag & Drop",
                content: "Try dragging a node to reorganize your document. The document content will automatically update to match your new structure."
            }
        ];

        const currentTooltip = tooltips[tooltipPosition - 1];

        return (
            <motion.div
                className={styles.tooltip}
                style={currentTooltip.position}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
            >
                <div className={styles.tooltipTitle}>
                    <Info size={16} />
                    <span>{currentTooltip.title}</span>
                </div>
                <div className={styles.tooltipContent}>
                    {currentTooltip.content}
                </div>
                <div className={styles.tooltipProgress}>
                    {tooltips.map((_, index) => (
                        <span
                            key={index}
                            className={`${styles.progressDot} ${tooltipPosition === index + 1 ? styles.activeDot : ''}`}
                        />
                    ))}
                </div>
            </motion.div>
        );
    };

    const renderCheshireCat = () => {
        return (
            <motion.div
                className={styles.cheshireCat}
                style={{
                    top: `${catPosition.y}%`,
                    left: `${catPosition.x}%`
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className={styles.catImage}>
                    <img src="/assets/cheshire-cat.svg" alt="Cheshire Cat" />
                </div>
                <motion.div
                    className={styles.catMessage}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    key={catMessage}
                >
                    {catMessage}
                </motion.div>
            </motion.div>
        );
    };

    const renderViewControls = () => {
        return (
            <div className={styles.viewControls}>
                <button
                    className={`${styles.viewButton} ${activeView === 'map' ? styles.activeView : ''}`}
                    onClick={() => handleSwitchView('map')}
                >
                    <Map size={18} />
                    <span>Map View</span>
                </button>
                <button
                    className={`${styles.viewButton} ${activeView === 'tree' ? styles.activeView : ''}`}
                    onClick={() => handleSwitchView('tree')}
                >
                    <GitBranch size={18} />
                    <span>Tree View</span>
                </button>
                <button
                    className={`${styles.viewButton} ${activeView === 'outline' ? styles.activeView : ''}`}
                    onClick={() => handleSwitchView('outline')}
                >
                    <FileArchive size={18} />
                    <span>Outline View</span>
                </button>
                <div className={styles.viewSeparator} />
                <button
                    className={`${styles.viewButton} ${showMinimap ? styles.activeView : ''}`}
                    onClick={() => setShowMinimap(!showMinimap)}
                >
                    <Map size={16} />
                    <span>Minimap</span>
                </button>
            </div>
        );
    };

    const renderTourProgress = () => {
        return (
            <div className={styles.tourProgress}>
                <div className={styles.progressTrack}>
                    <div
                        className={styles.progressFill}
                        style={{ width: `${(tourProgress / totalSteps) * 100}%` }}
                    />
                </div>
                <div className={styles.progressText}>
                    Step {tourProgress} of {totalSteps}
                </div>
                <div className={styles.progressNav}>
                    <button
                        className={styles.navButton}
                        onClick={onPrevious}
                    >
                        Previous
                    </button>
                    <button
                        className={styles.navButton}
                        onClick={onNext}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    };

    // Main render
    return (
        <div
            className={`${styles.container} ${isDark ? styles.darkTheme : styles.lightTheme}`}
            ref={containerRef}
        >
            <div className={styles.header}>
                <h2 className={styles.title}>{documentTitle}</h2>
                {renderViewControls()}
            </div>

            <div className={styles.diagramContainer} ref={diagramRef}>
                <div className={`${styles.diagram} ${styles[activeView]}`}>
                    {diagramNodes.map(node => renderNode(node))}

                    {/* Connector lines will be different based on view */}
                    {activeView === 'map' && (
                        <svg className={styles.connections}>
                            {/* Example connections - in real implementation these would be dynamic */}
                            <path d="M100,100 C150,100 150,200 200,200" className={styles.connection} />
                            <path d="M100,100 C150,120 150,250 200,250" className={styles.connection} />
                            <path d="M200,200 C250,200 250,220 300,220" className={styles.connection} />
                        </svg>
                    )}
                </div>

                {showMinimap && (
                    <div className={styles.minimap}>
                        <div className={styles.minimapContent}>
                            {/* Simplified version of the document structure */}
                            <div className={styles.minimapNode} style={{ top: '10%', left: '20%' }} />
                            <div className={styles.minimapNode} style={{ top: '30%', left: '40%' }} />
                            <div className={styles.minimapNode} style={{ top: '45%', left: '60%' }} />
                            <div className={styles.minimapConnection} />
                        </div>
                        <div className={styles.minimapViewport} />
                    </div>
                )}

                <AnimatePresence>
                    {renderTooltip()}
                </AnimatePresence>

                {renderCheshireCat()}
            </div>

            <div className={styles.dragInstructions}>
                <div className={styles.instruction}>
                    <MoveHorizontal size={18} />
                    <span>Drag horizontally to reposition nodes</span>
                </div>
                <div className={styles.instruction}>
                    <MoveVertical size={18} />
                    <span>Drag vertically to nest nodes under parents</span>
                </div>
                <div className={styles.instruction}>
                    <Zap size={18} />
                    <span>Double-click to focus on a specific branch</span>
                </div>
            </div>

            {renderTourProgress()}
        </div>
    );
};

// Sample initial data for diagram nodes
const initialNodes = [
    {
        id: 'root',
        title: "Alice's Adventures in Wonderland",
        color: '#8B5CF6',
        icon: '📚',
        active: true,
        children: [
            {
                id: 'chapter1',
                title: "Down the Rabbit Hole",
                color: '#EC4899',
                icon: '🐇',
                active: false,
                children: [
                    {
                        id: 'scene1_1',
                        title: "Alice by the riverbank",
                        color: '#2563EB',
                        icon: '🌿',
                        active: false,
                        children: []
                    },
                    {
                        id: 'scene1_2',
                        title: "Following the White Rabbit",
                        color: '#2563EB',
                        icon: '👣',
                        active: false,
                        children: []
                    },
                    {
                        id: 'scene1_3',
                        title: "The Long Fall",
                        color: '#2563EB',
                        icon: '⬇️',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                id: 'chapter2',
                title: "The Pool of Tears",
                color: '#EC4899',
                icon: '💧',
                active: false,
                children: [
                    {
                        id: 'scene2_1',
                        title: "Growing and Shrinking",
                        color: '#2563EB',
                        icon: '📏',
                        active: false,
                        children: []
                    },
                    {
                        id: 'scene2_2',
                        title: "Meeting the Mouse",
                        color: '#2563EB',
                        icon: '🐭',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                id: 'chapter3',
                title: "A Caucus Race and a Long Tale",
                color: '#EC4899',
                icon: '🏃',
                active: false,
                children: []
            },
            {
                id: 'chapter4',
                title: "The Rabbit Sends in a Little Bill",
                color: '#EC4899',
                icon: '📜',
                active: false,
                children: []
            }
        ]
    }
];

export default Compiler_3_Steps_InteractiveTour_Specialists_Writing;