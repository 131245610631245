
// region
// ▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
// SCOPE:  IMPORTS


import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    AlertCircle,
    ArrowRight,
    CheckCircle,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    Copy,
    Grid,
    ChartPie,
    Home,
    Layers,
    Mail,
    Menu,
    MessageSquare,
    SlidersHorizontal,
    X,
    Hexagon,
    Brain,
GitPullRequest,
    BookOpen,
    Database,
    Code,
    FolderPlus,
    Zap
} from "lucide-react";

import Title_GeneralWebpages_Template from "@/templates-scope/title/Title_GeneralWebpages_Template.jsx";


// Internal imports
import {ThemeContext} from "@/FullDevApp.jsx";
import styles from '@/TACS2.module.css';


import {
    handleUserEmail,
    handleUserSubmit
} from "@/homepage-scope/userEmail/UserEmail_TaytromLanding_Homepage.jsx";


import  {useThemeColors}
    from "@/templates-scope/colors/accepted/Accepted_Colors_GeneralWebpages_Templates.jsx";



import Premium3DButton
    from "@/homepage-scope/get_started_button/GetStartedButton_TaytromLanding.jsx";


import {
    Scroll_GeneralWebpages_Templates as useScrollTracking
} from "@/templates-scope/scroll/Scroll_GeneralWebpages_Templates.jsx";



import {
    ValueProposition_Hero_TaytromLanding
} from '../hero/value_proposition/ValueProposition_Hero_TaytromLanding.jsx';



import ValuePropositionDisplay from "@/homepage-scope/hero/value_proposition/ValuePropositionDisplay.jsx";




import {
    Substance_Pillars_TaytromLanding_Homepage
} from "@/homepage-scope/pillars/CELL/CSS/substance/Substance_Pillars_TaytromLanding_Homepage.jsx";

import Vertical_Navigate from "@/navigate-domain/vertical/Vertical_Navigate.jsx";
import {
    AI_MODELS,
    pillarTestimonials,
    overviewTestimonials
} from "@/homepage-scope/testimonials/CELL/CSS/Substance/Substance_Testimonials_Homepage.jsx";







import ThemedContainer from "@/ThemedContainer.jsx";

import { useThreeDText } from '@/templates-scope/text/Accepted_Text_Templates.jsx';



import { Link } from 'react-router-dom';
import { useOrchestrator } from '@/FullDevApp.jsx';





// region
// ▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
// SCOPE:  NON-DEPENDENT CONSTANTS












// region
// ▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
// SCOPE:  MAIN COMPONENT



const TaytromLanding = () => {












// region
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// TANK :  EXTERNAL STATE (useContext) DECLARATIONS
//
// pulls in existing state/values from a parent provider component
// useContext is fundamentally different from useState because:
// - It consumes external data vs. creating internal state
// - It pulls values from parent providers vs. generating local state
// - It updates automatically with provider changes vs. requiring explicit setters
// - Other hooks and functions often depend on context values
//
// Placing context hooks first establishes the "environment" in which the component
// operates before defining its own behavior and state.


    // Theme context
    const { isDark } = useContext(ThemeContext);
    const { get3DTextEffect} = useThreeDText();



    // Scroll tracking integration
    const { registerSection, unregisterSection } = useScrollTracking({
        smoothingFactor: 0.15,
        enableParallax: true,
        scrollAnimations: true,
        thresholds: [0.1, 0.25, 0.5, 0.75, 0.9],
        debug: false
    });




// Choose a style (classic, modern, extrude, neon, elegant)
    const textStyle = get3DTextEffect('elegant', {
        // Custom tweaks to the default 'elegant' style
        fontSize: '1.1rem',
        lineHeight: 1.7,
        letterSpacing: '0.01em',
        fontWeight: 400,
    });









    // Use the TACS color system
    const {
        getTacsColor,

        withAlpha
    } = useThemeColors();






    // Define color constants using the TACS color system
    const PRIMARY_COLORS = {
        light: [getTacsColor('teal', 8), getTacsColor('blue', 8), getTacsColor('blue', 5)],
        dark: [getTacsColor('teal', 8), getTacsColor('blue', 8), getTacsColor('blue', 5)]
    };





    const SECONDARY_COLORS = {
        light: [getTacsColor('blue', 7), getTacsColor('purple', 8), getTacsColor('blue', 8)],
        dark: [getTacsColor('blue', 7), getTacsColor('purple', 8), getTacsColor('blue', 8)]
    };


















    //▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
    // TANK: STATE (useState) DECLARATIONS
    //
    // creates new local state specific to this component instance; is for internal component
    // state management; requires explicit setter calls within the component





    // State management
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [suggestedEmail, setSuggestedEmail] = useState("");
    const [lastSubmitTime, setLastSubmitTime] = useState(0);
    const [cardColors, setCardColors] = useState({
        primary: getTacsColor('teal', 8),
        secondary: getTacsColor('blue', 7),
        accent: getTacsColor('orange', 6)
    });
    const [valuePropositionIndex, setValuePropositionIndex] = useState(0);
    const [activeSectionIndex, setActiveSectionIndex] = useState(0);
    const [expandedCard, setExpandedCard] = useState(null);
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
    const [testimonialsPaused] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [showNavigation, setShowNavigation] = useState(false);
    const [selectedPillar, setSelectedPillar] = useState('all-pillars'); // Added missing state variable



    const [isMobile, setIsMobile] = useState(false);
    const [isExperimental, setIsExperimental] = useState(false);
    const [initialDelayPassed, setInitialDelayPassed] = useState(false);
    // State hooks for the enhanced testimonials section
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [viewMode, setViewMode] = useState('grid');
    const [activeCarouselSlide, setActiveCarouselSlide] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');


























    //▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
    // TANK : REFERENCE DECLARATIONS
    //
    // Element references and mutable values that don't trigger re-renders upon change


    // Refs




    const carouselRef = useRef(null);
    const particleCanvasRef = useRef(null);



    const containerRef = useRef(null);
    const sectionRefs = useRef({});
    const mailinglistRef = useRef(null);
    const titleRef = useRef(null);
    const testimonialsRef = useRef(null);
    const heroRef = useRef(null);
    const platformSectionRef = useRef(null);
    const footerRef = useRef(null);
    const inputRef = useRef(null);
    const subscribeButtonRef = useRef(null);

    // Setup refs for platform steps
    Substance_Pillars_TaytromLanding_Homepage.forEach(section => {
        sectionRefs.current[section.id] = useRef(null);
    });











// region
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// TANK :  DEFINITIONS REQUIRED FOR OPERATIONS



    const allTestimonials = [...overviewTestimonials];



    const filteredTestimonials = allTestimonials
        .filter(testimonial => selectedFilter === 'all' || testimonial?.model?.id === selectedFilter);


// Use orchestrator for authentication check
    const orchestrator = useOrchestrator();


    // Determine the target URL based on authentication status
    const getTargetUrl = () => {
        if (orchestrator && orchestrator.authManager && orchestrator.authManager.isAuthenticated()) {
            return '/dashboard';
        }
        return '/signin';
    };


// region
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// TANK :  OPERATIONS



    // region
    // ====================================================================================================================================================
    // ATOM:  REACT FUNCTIONS



    // region
    // ====================================================================================================================================================
    // CELL:  STATE HOOKS






    // region
    // ====================================================================================================================================================
    // CELL:  EFFECT HOOKS (useEffects)
    //
    //    Simply put, these are listeners that respond to changes in your component:
    //    - They watch for changes to specific values (dependencies)
    //    - They run code when those values change
    //    - They're perfect for responding to state/prop changes without direct user interaction





    // Initialization & Setup Effects
    useEffect(() => {
        // Check for mobile device
        const checkMobile = () => {
            const mobile = window.innerWidth < 768;
            setIsMobile(mobile);

            // Disable experimental features on mobile for performance
            if (mobile && isExperimental) {
                setIsExperimental(false);
            }
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        // Check for saved email in localStorage
        try {
            const hasSubscribed = localStorage.getItem('taytrom_subscribed');
            if (hasSubscribed === 'true') {
                setSubmitted(true);
            }
        } catch (e) {
            // Silently fail if localStorage isn't available
        }

        // Register steps for scroll tracking
        if (heroRef.current) registerSection(heroRef.current);
        if (platformSectionRef.current) registerSection(platformSectionRef.current);
        if (testimonialsRef.current) registerSection(testimonialsRef.current);
        if (mailinglistRef.current) registerSection(mailinglistRef.current);

        // Initialize colors based on theme
        updateCardColors();

        // Handle initial visibility
        setTimeout(() => {
            setIsVisible(true);
        }, 100);

        // Handle initial scroll detection for scroll indicator
        const handleInitialScroll = () => {
            if (window.scrollY > 50) {
                // Use the correctly named setter functions
                window.removeEventListener('scroll', handleInitialScroll);
            }
        };

        window.addEventListener('scroll', handleInitialScroll);

        // Cleanup function
        return () => {
            window.removeEventListener('resize', checkMobile);
            window.removeEventListener('scroll', handleInitialScroll);

            // Unregister steps from scroll tracking
            if (heroRef.current) unregisterSection(heroRef.current);
            if (platformSectionRef.current) unregisterSection(platformSectionRef.current);
            if (testimonialsRef.current) unregisterSection(testimonialsRef.current);
            if (mailinglistRef.current) unregisterSection(mailinglistRef.current);
        };
    }, []);











    useEffect(() => {
        const handleScroll = () => {
            if (platformSectionRef.current) {
                const platformRect = platformSectionRef.current.getBoundingClientRect();
                const scrollThreshold = platformRect.top <= window.innerHeight / 2;

                setShowNavigation(scrollThreshold);
            }
        };

        // Initial check
        handleScroll();

        // Add scroll listener
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);













    // Optimize scroll performance
    useEffect(() => {
        // Disable animations during scroll for better performance
        let scrollTimer;
        const handleScroll = () => {
            if (!document.body.classList.contains('is-scrolling')) {
                document.body.classList.add('is-scrolling');
            }

            clearTimeout(scrollTimer);
            scrollTimer = setTimeout(() => {
                document.body.classList.remove('is-scrolling');
            }, 100);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(scrollTimer);
        };
    }, []);









    useEffect(() => {
        // Don't show value proposition initially
        setValuePropositionIndex(-1);

        // Set a timer to show first proposition after 1 second
        const initialTimer = setTimeout(() => {
            setValuePropositionIndex(0);
            setInitialDelayPassed(true);
        }, 1000);

        return () => clearTimeout(initialTimer);
    }, []);























    // Testimonial auto-rotation
    useEffect(() => {
        if (!testimonialsPaused && isVisible && testimonialsRef.current) {
            // Check if currentTestimonial is within bounds
            if (currentTestimonial >= 0 && currentTestimonial < Substance_Pillars_TaytromLanding_Homepage.length) {
                const currentContent = Substance_Pillars_TaytromLanding_Homepage[currentTestimonial];
                // Use optional chaining here as well
                const contentLength = currentContent?.quote?.length || 0;

                // Calculate dynamic duration based on quote length
                const baseDuration = 8000;
                const readingSpeed = 20; // Characters per second
                const minDuration = 5000;

                const calculatedDuration = Math.max(
                    minDuration,
                    baseDuration + (contentLength / readingSpeed * 1000)
                );

                const interval = setInterval(() => {
                    setCurrentTestimonial((prevIndex) =>
                        (prevIndex + 1) % (allTestimonials.length || 1)
                    );
                }, calculatedDuration);

                return () => clearInterval(interval);
            }
        }
    }, [testimonialsPaused, isVisible, currentTestimonial, allTestimonials]);

    useEffect(() => {
        const addRippleEffect = (element) => {
            element.addEventListener('click', function(e) {
                if (element.disabled) return;

                const rippleContainer = element.querySelector('.ripple-container');
                if (!rippleContainer) return;

                const rect = rippleContainer.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                const ripple = document.createElement('span');
                ripple.classList.add('ripple');
                ripple.style.left = x + 'px';
                ripple.style.top = y + 'px';

                rippleContainer.appendChild(ripple);

                setTimeout(() => {
                    ripple.remove();
                }, 600);
            });
        };

        const prevButton = document.querySelector('button[aria-label="Previous proposition"]');
        const nextButton = document.querySelector('button[aria-label="Next proposition"]');

        if (prevButton) addRippleEffect(prevButton);
        if (nextButton) addRippleEffect(nextButton);

        return () => {
            if (prevButton) prevButton.removeEventListener('click', () => {});
            if (nextButton) nextButton.removeEventListener('click', () => {});
        };
    }, []);




    // Set reflection colors based on theme
    useEffect(() => {
        document.documentElement.style.setProperty(
            '--reflection-start-color',
            isDark ? withAlpha(getTacsColor('whiteKhaki', 1), 0.2) : withAlpha(getTacsColor('blackGray', 10), 0.1)
        );
        document.documentElement.style.setProperty(
            '--reflection-end-color',
            isDark ? withAlpha(getTacsColor('whiteKhaki', 1), 0) : withAlpha(getTacsColor('blackGray', 10), 0)
        );
    }, [isDark, getTacsColor, withAlpha]);



// region
// ====================================================================================================================================================
// CELL:  REFERENCE (useRef) HOOKS


// CELL: VERTICAL NAVSECTIONS



    // Navigation steps for main menu
    const navSections = [
        { id: "hero", label: "Home", ref: heroRef, icon: Home },
        { id: "platforms", label: "Platforms", ref: platformSectionRef, icon: Layers },
        { id: "testimonials", label: "Testimonials", ref: testimonialsRef, icon: MessageSquare },
        { id: "mailinglist", label: "Connect", ref: mailinglistRef, icon: Mail }
    ];











    const scrollToSlide = (index) => {
        if (carouselRef.current) {
            const slides = carouselRef.current.children;
            if (slides[index]) {
                slides[index].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center'
                });
            }
        }
    };








    const scrollToSection = useCallback((sectionRef) => {
        if (!sectionRef?.current) return;

        // Scroll to the section
        sectionRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });

        // Update active section index
        const sectionIndex = navSections.findIndex(section => section.ref === sectionRef);
        if (sectionIndex !== -1) {
            setActiveSectionIndex(sectionIndex);
        }
    }, [navSections]);












// region
// ====================================================================================================================================================
// CELL:  CALLBACK (useCallback) HOOKS


    // Callback functions
    const updateCardColors = useCallback(() => {
        setCardColors({
            primary: getTacsColor('teal', 8),
            secondary: getTacsColor('blue', 7),
            accent: getTacsColor('orange', 6)
        });
    }, [getTacsColor]);











    // Email handlers
    const debouncedEmailHandler = useCallback(
        (e) => debounce((event) => handleUserEmail(event, setEmail, setError), 100)(e),
        [setEmail, setError]
    );

    const onSubmit = useCallback(
        (e) => handleUserSubmit(e, {
            email,
            setEmail,
            setError,
            error,
            isLoading,
            setIsLoading,
            submitted,
            setSubmitted,
            lastSubmitTime,
            setLastSubmitTime,
            showSuggestion,
            setShowSuggestion,
            suggestedEmail,
            setSuggestedEmail,
            isDark,
            cardColors,
            mailinglistRef,
            subscribeButtonRef,
            styles,
        }),
        [
            email, error, isLoading, submitted, lastSubmitTime,
            showSuggestion, suggestedEmail, isDark, cardColors,
        ]
    );













    // region
    // ====================================================================================================================================================
    // ATOM:  COMPONENT FUNCTIONS



// region
// ---------------------------------------------------------------------------------------------------------------------------------------------------
// CELL:  EVENT HANDLERS
//
// e.g.,  handleClick, handleSubmit, handleChange, etc.
// functions that respond to direct user interactions








    // Key navigation handling
    const handleKeyDown = (e) => {
        // Close any alerts on Escape
        if (e.key === 'Escape') {
            setShowSuggestion(false);
            setError("");
        }
    };







    // Handle Get Started button click
    const handleGetStartedClick = () => {
        // Check if user is authenticated using the orchestrator
        if (orchestrator && orchestrator.authManager) {
            const isAuthenticated = orchestrator.authManager.isAuthenticated();

            if (isAuthenticated) {
                // User is logged in, redirect to dashboard
                navigate('/dashboard');
            } else {
                // User is not logged in, redirect to sign in
                navigate('/signin');
            }
        } else {
            // Fallback if orchestrator is not available
            navigate('/signin');
        }
    };









    // Mobile menu toggle (placeholder)
    const toggleMobileMenu = () => {
        // Implementation for mobile menu toggle would go here
    };







    // region
    // ---------------------------------------------------------------------------------------------------------------------------------------------------
    // CELL:  STATE MANAGEMENT FUNCTIONS
    //
    // e.g., toggleMenu, setFormValues, clearForm, etc.
    // effect state



// region
// ---------------------------------------------------------------------------------------------------------------------------------------------------
// CELL:  BUSINESS LOGIC FUNCTIONS
//
//no hook references
// e.g., calculateTotal, validateInput, formatData, etc.
// Core application logic not specific to React
// can be moved outside the component











// region
// ---------------------------------------------------------------------------------------------------------------------------------------------------
// CELL:  UTILITY
//
// e.g., formatDate, slugify, debounce, etc.
// no hook or state references
// can be moved outside the component












// region
// ---------------------------------------------------------------------------------------------------------------------------------------------------
// CELL:  COMPONENT DEPENDENT FUNCTIONS
//
// e.g., currentExpedition
// functions that do not meet any of the other categories but are dependent on an
// intra-component defition




    // Helper Functions
    const generateDynamicShadow = (options = {}) => {
        const {
            baseColor = getTacsColor('teal', 8),
            elevation = 2,
            mode = 'glass',
            intensity = 1.0,
            interactive = false,
            direction = 'top-left',
            isDark = false
        } = options;

        const elevationMap = {
            1: { blur: 8, spread: 0, distance: 4 },
            2: { blur: 16, spread: 1, distance: 8 },
            3: { blur: 24, spread: 2, distance: 12 },
            4: { blur: 32, spread: 4, distance: 16 },
            5: { blur: 48, spread: 6, distance: 24 }
        };

        const { blur, spread, distance } = elevationMap[elevation] || elevationMap[2];
        const darkModifier = isDark ? 0.7 : 1.0;

        const directionMap = {
            'top': { x: 0, y: -distance },
            'top-right': { x: distance, y: -distance },
            'right': { x: distance, y: 0 },
            'bottom-right': { x: distance, y: distance },
            'bottom': { x: 0, y: distance },
            'bottom-left': { x: -distance, y: distance },
            'left': { x: -distance, y: 0 },
            'top-left': { x: -distance, y: -distance },
            'center': { x: 0, y: 0 }
        };

        const { x, y } = directionMap[direction] || directionMap['top-left'];

        const generateShadowByMode = (mode) => {
            const scaledIntensity = intensity * darkModifier;

            switch (mode) {
                case 'glass':
                default:
                    const ambientShadowColor = isDark ? getTacsColor('blackGray', 10) : getTacsColor('blackGray', 10);
                    const highlightColor = isDark ? getTacsColor('whiteKhaki', 1) : getTacsColor('whiteKhaki', 1);

                    return `
                    ${x}px ${y}px ${blur}px ${withAlpha(ambientShadowColor, 0.15 * scaledIntensity)},
                    0 ${Math.abs(y/2)}px ${blur/2}px ${withAlpha(baseColor, 0.1 * scaledIntensity)},
                    inset 0 -1px 2px ${withAlpha(highlightColor, 0.05 * scaledIntensity)}
                    `;
            }
        };

        let shadowValue = generateShadowByMode(mode);

        // Add carousel enhancements if specified
        if (interactive) {
            shadowValue = `
                ${shadowValue},
                0 0 2px ${withAlpha(baseColor, 0.3 * intensity)},
                0 0 ${blur/2}px ${withAlpha(baseColor, 0.2 * intensity)}
            `;
        }

        return shadowValue.replace(/\s+/g, ' ').trim();
    };







    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

























































// region
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// TANK : COMPONENT RETURN (with React fragment for two containers)



    {/* region */}
    {/* ==================================================================================================================================================== */}
    {/* ATOM:  TOP CONTAINER */}

















    return (
        <>
            {/*
            ================================================================================================================
                                                        REACT FRAGMENTS (CREATION OF MULTIPLE SCOPES)
            ================================================================================================================
            */}


            {/* region */}
            {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* CELL:  VERTICAL NAVIGATE COMPONENT */}


            {/* Option 1: Conditional Rendering */}
            {showNavigation && (


                <Vertical_Navigate

                    sections={navSections}
                    activeSectionIndex={activeSectionIndex}
                    onSectionChange={(index) => {
                        setActiveSectionIndex(index);
                        scrollToSection(navSections[index].ref);
                    }}
                    colorScheme="primary"
                    position="right"
                    customStyles={{
                        '--tooltip-background': isDark ? 'rgba(20, 20, 30, 0.95)' : 'rgba(50, 50, 60, 0.95)',
                        '--tooltip-text-color': getTacsColor('whiteKhaki', 1),
                        '--tooltip-shadow': `0 4px 12px ${withAlpha(getTacsColor('blackGray', 10), 0.3)}`,
                        boxShadow: generateDynamicShadow({
                            baseColor: PRIMARY_COLORS[isDark ? 'dark' : 'light'][0],
                            elevation: 2,
                            mode: 'glass',
                            isDark,
                            withAlpha
                        })
                    }}
                />
            )}



            {/* region */}
            {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* CELL:  Separate mobile menu button container */}


            {isMobile && (
                <button
                    aria-label="Open navigation menu"
                    className={`${styles.fixed} ${styles['z-1001']} ${styles.button} ${styles['glass-btn']} ${styles['rounded-full']}`}
                    style={{
                        position: 'fixed',
                        top: '1rem',
                        right: '1rem',
                        width: '48px',
                        height: '48px',
                        minWidth: 'unset',
                        padding: '0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: isDark
                            ? withAlpha(getTacsColor('blackGray', 9), 0.65)
                            : withAlpha(getTacsColor('whiteKhaki', 1), 0.75),
                        backdropFilter: 'blur(12px)',
                        WebkitBackdropFilter: 'blur(12px)',
                        border: `1px solid ${isDark
                            ? withAlpha(getTacsColor('whiteKhaki', 1), 0.1)
                            : withAlpha(getTacsColor('whiteKhaki', 1), 0.5)}`,
                        boxShadow: `0 4px 14px ${withAlpha(
                            isDark ? getTacsColor('blackGray', 10) : getTacsColor('blackGray', 10),
                            isDark ? 0.3 : 0.1
                        )}`
                    }}
                    onClick={toggleMobileMenu}
                >
                    <Menu
                        size={22}
                        color={isDark ? withAlpha(getTacsColor('whiteKhaki', 1), 0.9) : withAlpha(getTacsColor('blackGray', 10), 0.85)}
                        strokeWidth={2.25}
                    />
                </button>
            )}

            {/* region */}
            {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* ATOM: MAIN CONTAINER */}

            <ThemedContainer
                ref={containerRef}
                variant="full"
                onKeyDown={handleKeyDown}
                aria-label="Main application container"
                role="region"
            >
                {/* region */}
                {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* CELL: HERO SECTION */}

                <ThemedContainer
                    ref={heroRef}
                    id="hero"
                    variant="section"
                    role="banner"
                    aria-labelledby="main-title"
                    // Track mouse movement for parallax effect
                    style={{
                        position: 'relative',  // Make sure this is relative
                        minHeight: '100vh',    // Ensure it takes up at least full viewport height
                    }}
                    onMouseMove={(e) => {
                        const container = e.currentTarget;
                        const {
                            left,
                            top,
                            width,
                            height
                        } = container.getBoundingClientRect();
                        const x = (e.clientX - left) / width;
                        const y = (e.clientY - top) / height;

                        document.documentElement.style.setProperty('--mouse-x', x.toString());
                        document.documentElement.style.setProperty('--mouse-y', y.toString());
                    }}
                >
                    <div
                        className={`${styles.absolute} ${styles.inset0}`}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                            pointerEvents: 'none' // This ensures clicks pass through this container
                        }}
                    >
                        {/* Separate div for the background itself with the correct pointer-event handling */}


                    </div>

                    {/* region */}
                    {/*  -    -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -    -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   - */}
                    {/* KERNEL:  TITLE */}

                    <div
                        className={`${styles.relative} ${styles.z10} ${styles['will-change-transform']} ${styles['hardware-accelerated']}`}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            overflow: 'hidden',
                            zIndex: 9,
                            pointerEvents: 'auto' // Ensure pointer events pass through
                        }}
                    >
                        <Title_GeneralWebpages_Template
                            id="main-title"
                            ref={titleRef}
                            text="TAYTROM"
                            to="/about"
                            isDark={isDark}
                            getTacsColor={getTacsColor}
                            withAlpha={withAlpha}
                            className={`${styles.m0} ${styles.p2} ${styles.z2} ${styles.relative} ${styles.revealFadeUp}`}
                            style={{
                                fontWeight: 900,
                                fontSize: 'clamp(3rem, 15vw, 8rem)',
                                letterSpacing: '0.06em',
                                position: 'relative',
                                zIndex: 5,
                                paddingTop: '-150px',
                                marginTop: '-50px',
                                background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                animation: 'textShimmer 6s infinite linear',
                                backgroundSize: '200% 100%',
                                marginLeft: '50px',
                            }}
                            showOutline={false}
                            letterByLetter={true}
                            reflectionEffect={true}
                            ariaLabel="Go to About page"
                        />


                        {/* region */}
                        {/*  -    -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -    -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   - */}
                        {/* KERNEL:  VALUE PROPOSITION */}


                        <style jsx>{`
                            @keyframes textShimmer {
                                0% {
                                    background-position: 200% 0;
                                }
                                100% {
                                    background-position: 0 0;
                                }
                            }

                            @keyframes fadeUpIn {
                                0% {
                                    opacity: 0;
                                    transform: translateY(30px);
                                }
                                100% {
                                    opacity: 1;
                                    transform: translateY(0);
                                }
                            }

                            .revealFadeUp {
                                animation: fadeUpIn 0.8s ease forwards;
                            }
                        `}</style>

                        {/* VALUE PROPOSITION */}
                        <ValuePropositionDisplay
                            valueProposition={ValueProposition_Hero_TaytromLanding[valuePropositionIndex]}
                            isVisible={isVisible}
                            initialDelayPassed={initialDelayPassed}
                        />

                    </div>


                    {/* region */}
                    {/*  -    -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -    -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   - */}
                    {/* KERNEL:  GET STARTED */}

                    <div
                        className={`${styles.relative} ${styles.flex} ${styles['justify-center']} ${styles['items-center']} ${styles['w-full']}`}
                        style={{
                            marginTop: '800px',
                            position: 'relative',
                            zIndex: 100,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <Link to={getTargetUrl()}>
                            <Premium3DButton/>
                        </Link>
                    </div>

                </ThemedContainer>



                {/* region */}
                {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* CELL: WRITING PLATFORM SECTION */}


                {/* Visual Writing Platform Section - Replace the SuperBeginner section with this */}
                <ThemedContainer
                    id="visualWriting"
                    variant="section"
                    role="region"
                    aria-labelledby="visualWriting-title"
                    style={{
                        padding: '6rem 2rem',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    {/* Decorative Background Elements */}
                    <div
                        style={{
                            position: 'absolute',
                            width: '600px',
                            height: '600px',
                            top: '-300px',
                            right: '-200px',
                            borderRadius: '50%',
                            background: isDark
                                ? `radial-gradient(circle, ${withAlpha(getTacsColor('purple', 8), 0.1)} 0%, transparent 70%)`
                                : `radial-gradient(circle, ${withAlpha(getTacsColor('purple', 5), 0.05)} 0%, transparent 70%)`,
                            zIndex: 0
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            width: '400px',
                            height: '400px',
                            bottom: '-200px',
                            left: '-200px',
                            borderRadius: '50%',
                            background: isDark
                                ? `radial-gradient(circle, ${withAlpha(getTacsColor('blue', 8), 0.1)} 0%, transparent 70%)`
                                : `radial-gradient(circle, ${withAlpha(getTacsColor('blue', 5), 0.05)} 0%, transparent 70%)`,
                            zIndex: 0
                        }}
                    />

                    <div
                        className={styles.container}
                        style={{
                            maxWidth: '1200px',
                            margin: '0 auto',
                            position: 'relative',
                            zIndex: 5
                        }}
                    >
                        {/* Two-column layout */}
                        <div
                            className={`${styles.grid}`}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                                gap: '3rem',
                                alignItems: 'center'
                            }}
                        >
                            {/* Left column - Content */}
                            <div>
                                <div
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                >
                    <span
                        style={{
                            display: 'inline-block',
                            padding: '0.5rem 1rem',
                            backgroundColor: isDark
                                ? withAlpha(getTacsColor('purple', 8), 0.2)
                                : withAlpha(getTacsColor('purple', 8), 0.1),
                            border: `1px solid ${withAlpha(getTacsColor('purple', 8), isDark ? 0.3 : 0.2)}`,
                            borderRadius: '2rem',
                            color: getTacsColor('purple', 8),
                            fontWeight: '600',
                            fontSize: '0.9rem',
                            marginBottom: '1rem'
                        }}
                    >
                        Just $5/month
                    </span>
                                </div>

                                <h2
                                    id="visualWriting-title"
                                    style={{
                                        marginBottom: '1.5rem',
                                        fontWeight: 700,
                                        fontSize: 'clamp(2rem, 5vw, 3rem)',
                                        background: `linear-gradient(135deg, ${getTacsColor('purple', 8)}, ${getTacsColor('blue', 8)})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        lineHeight: 1.2
                                    }}
                                >
                                    Visual Writing Platform
                                </h2>

                                <p
                                    style={{
                                        fontSize: '1.1rem',
                                        lineHeight: 1.6,
                                        marginBottom: '1.5rem',
                                        color: isDark
                                            ? withAlpha(getTacsColor('whiteKhaki', 1), 0.85)
                                            : withAlpha(getTacsColor('blackGray', 8), 0.85)
                                    }}
                                >
                                    Transform the writing experience with our <strong>immersive visual platform</strong>.
                                    Unlike traditional text editors that trap ideas in static paragraphs, this breakthrough tool
                                    <strong> visualizes your content in 3D space</strong> and uses <strong>AI-powered thought bubbles</strong> that make writing feel natural and intuitive.
                                </p>

                                <div
                                    style={{
                                        marginBottom: '2rem'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '0.75rem'
                                        }}
                                    >
                                        {[
                                            { icon: 'Cube', text: '3D Structure Visualization - Manipulate your document-panel as a three-dimensional mind map' },
                                            { icon: 'MessageCircle', text: 'Thought Bubbles - Grab and place AI suggestions that appear as you write' },
                                            { icon: 'Sliders', text: 'Interactive Style Engine - Adjust tone, formality and complexity with visual controls' },
                                            { icon: 'BarChart2', text: 'Visual Analytics - See real-time stats about your writing style and habits' }
                                        ].map((feature, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'start',
                                                    gap: '1rem'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                        minWidth: '24px',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        background: getTacsColor('purple', 8),
                                                        color: 'white',
                                                        marginTop: '3px'
                                                    }}
                                                >
                                                    {feature.icon === 'Cube' && <Hexagon size={14} />}
                                                    {feature.icon === 'MessageCircle' && <MessageSquare size={14} />}
                                                    {feature.icon === 'Sliders' && <SlidersHorizontal size={14} />}
                                                    {feature.icon === 'BarChart2' && <ChartPie size={14} />}
                                                </div>
                                                <span
                                                    style={{
                                                        fontSize: '0.95rem',
                                                        lineHeight: 1.4,
                                                        color: isDark
                                                            ? withAlpha(getTacsColor('whiteKhaki', 1), 0.8)
                                                            : withAlpha(getTacsColor('blackGray', 8), 0.8)
                                                    }}
                                                >
                                    {feature.text}
                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '1rem',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Link to="/ai/writing">
                                        <button
                                            className={`${styles.button} ${styles['button-primary']}`}
                                            style={{
                                                background: `linear-gradient(135deg, ${getTacsColor('purple', 8)}, ${getTacsColor('blue', 8)})`,
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                                padding: '0.75rem 1.5rem',
                                                borderRadius: '0.5rem',
                                                fontWeight: 600,
                                                border: 'none',
                                                color: 'white',
                                                cursor: 'pointer',
                                                boxShadow: `0 4px 14px ${withAlpha(getTacsColor('purple', 8), 0.4)}`,
                                                transition: 'all 0.3s ease'
                                            }}
                                        >
                                            Try It Now - Just $5/month
                                            <ArrowRight size={16} />
                                        </button>
                                    </Link>

                                    <Link to="/ai/writing">
                                        <button
                                            className={`${styles.button} ${styles['button-outline']}`}
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                                padding: '0.75rem 1.5rem',
                                                borderRadius: '0.5rem',
                                                fontWeight: 600,
                                                background: 'transparent',
                                                border: `2px solid ${getTacsColor('purple', 8)}`,
                                                color: getTacsColor('purple', 8),
                                                cursor: 'pointer',
                                                transition: 'all 0.3s ease'
                                            }}
                                        >
                                            See Demo
                                            <ChevronRight size={16} />
                                        </button>
                                    </Link>
                                </div>
                            </div>

                            {/* Right column - Stylized Mockup */}
                            <div
                                style={{
                                    position: 'relative',
                                    height: '100%',
                                    minHeight: '400px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        maxWidth: '500px',
                                        background: isDark
                                            ? `linear-gradient(145deg, ${withAlpha(getTacsColor('blackGray', 8), 0.7)}, ${withAlpha(getTacsColor('blackGray', 9), 0.7)})`
                                            : `linear-gradient(145deg, ${withAlpha(getTacsColor('whiteKhaki', 1), 0.7)}, ${withAlpha(getTacsColor('whiteKhaki', 2), 0.7)})`,
                                        border: isDark
                                            ? `1px solid ${withAlpha(getTacsColor('purple', 8), 0.3)}`
                                            : `1px solid ${withAlpha(getTacsColor('purple', 8), 0.2)}`,
                                        borderRadius: '1rem',
                                        boxShadow: `0 20px 40px ${withAlpha(getTacsColor('blackGray', 10), isDark ? 0.4 : 0.1)}, 0 0 30px ${withAlpha(getTacsColor('purple', 8), 0.2)}`,
                                        backdropFilter: 'blur(10px)',
                                        WebkitBackdropFilter: 'blur(10px)',
                                        overflow: 'hidden',
                                        padding: '1.5rem',
                                        zIndex: 10
                                    }}
                                >
                                    {/* Platform Interface Mock UI */}
                                    <div
                                        style={{
                                            marginBottom: '1.5rem',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.75rem'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '36px',
                                                    height: '36px',
                                                    borderRadius: '0.5rem',
                                                    background: getTacsColor('purple', 8),
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: 'white'
                                                }}
                                            >
                                                <Hexagon size={20} />
                                            </div>
                                            <div>
                                                <h4
                                                    style={{
                                                        margin: 0,
                                                        fontSize: '1rem',
                                                        fontWeight: 600,
                                                        color: isDark
                                                            ? withAlpha(getTacsColor('whiteKhaki', 1), 0.9)
                                                            : withAlpha(getTacsColor('blackGray', 8), 0.9)
                                                    }}
                                                >
                                                    Visual Writing
                                                </h4>
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        fontSize: '0.8rem',
                                                        color: isDark
                                                            ? withAlpha(getTacsColor('whiteKhaki', 1), 0.6)
                                                            : withAlpha(getTacsColor('blackGray', 8), 0.6)
                                                    }}
                                                >
                                                    Interactive Document
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '0.25rem'
                                                }}
                                            >
                                                <div style={{
                                                    width: '6px',
                                                    height: '6px',
                                                    borderRadius: '50%',
                                                    background: getTacsColor('red', 6)
                                                }}/>
                                                <div style={{
                                                    width: '6px',
                                                    height: '6px',
                                                    borderRadius: '50%',
                                                    background: getTacsColor('yellow', 6)
                                                }}/>
                                                <div style={{
                                                    width: '6px',
                                                    height: '6px',
                                                    borderRadius: '50%',
                                                    background: getTacsColor('green', 6)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 3D Document Canvas Representation */}
                                    <div
                                        style={{
                                            height: '150px',
                                            background: isDark
                                                ? withAlpha(getTacsColor('blackGray', 10), 0.3)
                                                : withAlpha(getTacsColor('blackGray', 8), 0.05),
                                            borderRadius: '0.75rem',
                                            marginBottom: '1rem',
                                            position: 'relative',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {/* Stylized 3D nodes */}
                                        <div style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '220px',
                                            height: '100px',
                                            perspective: '500px',
                                            perspectiveOrigin: 'center',
                                        }}>
                                            {/* Introduction Node */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '20px',
                                                left: '10px',
                                                width: '80px',
                                                height: '40px',
                                                background: getTacsColor('purple', 8),
                                                borderRadius: '10px',
                                                transform: 'rotateY(-10deg) rotateX(5deg)',
                                                boxShadow: `0 5px 15px ${withAlpha(getTacsColor('purple', 8), 0.4)}`,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                fontSize: '10px',
                                                fontWeight: 'bold'
                                            }}>
                                                Introduction
                                            </div>

                                            {/* Key Points Node */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '30px',
                                                left: '110px',
                                                width: '70px',
                                                height: '40px',
                                                background: getTacsColor('blue', 8),
                                                borderRadius: '10px',
                                                transform: 'rotateY(5deg) rotateX(-5deg)',
                                                boxShadow: `0 5px 15px ${withAlpha(getTacsColor('blue', 8), 0.4)}`,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                fontSize: '10px',
                                                fontWeight: 'bold'
                                            }}>
                                                Key Points
                                            </div>

                                            {/* Conclusion Node */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '65px',
                                                left: '50px',
                                                width: '85px',
                                                height: '35px',
                                                background: getTacsColor('teal', 8),
                                                borderRadius: '10px',
                                                transform: 'rotateY(-5deg) rotateX(10deg)',
                                                boxShadow: `0 5px 15px ${withAlpha(getTacsColor('teal', 8), 0.4)}`,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                fontSize: '10px',
                                                fontWeight: 'bold'
                                            }}>
                                                Conclusion
                                            </div>

                                            {/* Connection lines */}
                                            <svg
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    pointerEvents: 'none',
                                                    zIndex: 0
                                                }}
                                            >
                                                <path
                                                    d="M 50 40 L 110 50"
                                                    stroke={withAlpha(getTacsColor('purple', 8), 0.6)}
                                                    strokeWidth="2"
                                                    fill="none"
                                                />
                                                <path
                                                    d="M 110 50 L 90 80"
                                                    stroke={withAlpha(getTacsColor('blue', 8), 0.6)}
                                                    strokeWidth="2"
                                                    fill="none"
                                                />
                                                <path
                                                    d="M 50 40 L 90 80"
                                                    stroke={withAlpha(getTacsColor('teal', 8), 0.6)}
                                                    strokeWidth="2"
                                                    fill="none"
                                                    strokeDasharray="4"
                                                />
                                            </svg>
                                        </div>
                                    </div>

                                    {/* Thought Bubbles Representation */}
                                    <div
                                        style={{
                                            height: '80px',
                                            position: 'relative',
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        {/* Bubble 1 */}
                                        <div style={{
                                            position: 'absolute',
                                            top: '10px',
                                            left: '20px',
                                            width: '80px',
                                            height: '40px',
                                            background: getTacsColor('blue', 6),
                                            borderRadius: '20px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: 'white',
                                            fontSize: '8px',
                                            boxShadow: `0 4px 12px ${withAlpha(getTacsColor('blue', 6), 0.3)}`,
                                            animation: 'float 3s infinite ease-in-out'
                                        }}>
                                            Add evidence here
                                        </div>

                                        {/* Bubble 2 */}
                                        <div style={{
                                            position: 'absolute',
                                            top: '25px',
                                            right: '30px',
                                            width: '90px',
                                            height: '40px',
                                            background: getTacsColor('purple', 6),
                                            borderRadius: '20px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: 'white',
                                            fontSize: '8px',
                                            boxShadow: `0 4px 12px ${withAlpha(getTacsColor('purple', 6), 0.3)}`,
                                            animation: 'float 4s infinite ease-in-out'
                                        }}>
                                            Expand on this concept
                                        </div>
                                    </div>

                                    {/* Style Controls Representation */}
                                    <div
                                        style={{
                                            background: isDark
                                                ? `linear-gradient(135deg, ${withAlpha(getTacsColor('purple', 8), 0.2)}, ${withAlpha(getTacsColor('blue', 8), 0.15)})`
                                                : `linear-gradient(135deg, ${withAlpha(getTacsColor('purple', 8), 0.1)}, ${withAlpha(getTacsColor('blue', 8), 0.05)})`,
                                            padding: '0.75rem',
                                            borderRadius: '0.75rem',
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        {/* Formality Slider */}
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '0.5rem'
                                        }}>
                                            <div style={{
                                                width: '70px',
                                                fontSize: '10px',
                                                color: isDark
                                                    ? withAlpha(getTacsColor('whiteKhaki', 1), 0.8)
                                                    : withAlpha(getTacsColor('blackGray', 8), 0.8)
                                            }}>
                                                Formality
                                            </div>
                                            <div style={{
                                                flex: 1,
                                                height: '4px',
                                                background: isDark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                                                borderRadius: '2px',
                                                position: 'relative'
                                            }}>
                                                <div style={{
                                                    position: 'absolute',
                                                    width: '65%',
                                                    height: '100%',
                                                    background: getTacsColor('blue', 8),
                                                    borderRadius: '2px'
                                                }}/>
                                                <div style={{
                                                    position: 'absolute',
                                                    right: '35%',
                                                    top: '50%',
                                                    transform: 'translate(50%, -50%)',
                                                    width: '12px',
                                                    height: '12px',
                                                    borderRadius: '50%',
                                                    background: 'white',
                                                    border: `2px solid ${getTacsColor('blue', 8)}`,
                                                    boxShadow: '0 1px 3px rgba(0,0,0,0.3)'
                                                }}/>
                                            </div>
                                        </div>

                                        {/* Complexity Slider */}
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <div style={{
                                                width: '70px',
                                                fontSize: '10px',
                                                color: isDark
                                                    ? withAlpha(getTacsColor('whiteKhaki', 1), 0.8)
                                                    : withAlpha(getTacsColor('blackGray', 8), 0.8)
                                            }}>
                                                Technical
                                            </div>
                                            <div style={{
                                                flex: 1,
                                                height: '4px',
                                                background: isDark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                                                borderRadius: '2px',
                                                position: 'relative'
                                            }}>
                                                <div style={{
                                                    position: 'absolute',
                                                    width: '40%',
                                                    height: '100%',
                                                    background: getTacsColor('purple', 8),
                                                    borderRadius: '2px'
                                                }}/>
                                                <div style={{
                                                    position: 'absolute',
                                                    right: '60%',
                                                    top: '50%',
                                                    transform: 'translate(50%, -50%)',
                                                    width: '12px',
                                                    height: '12px',
                                                    borderRadius: '50%',
                                                    background: 'white',
                                                    border: `2px solid ${getTacsColor('purple', 8)}`,
                                                    boxShadow: '0 1px 3px rgba(0,0,0,0.3)'
                                                }}/>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Analytics Representation */}
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div style={{
                                            padding: '0.3rem 0.5rem',
                                            background: isDark ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
                                            borderRadius: '0.5rem',
                                            fontSize: '8px',
                                            color: isDark
                                                ? withAlpha(getTacsColor('whiteKhaki', 1), 0.7)
                                                : withAlpha(getTacsColor('blackGray', 8), 0.7),
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '3px'
                                        }}>
                                            <span>Reading Grade</span>
                                            <span style={{fontWeight: 'bold', color: getTacsColor('blue', 8)}}>9.2</span>
                                        </div>

                                        <div style={{
                                            padding: '0.3rem 0.5rem',
                                            background: isDark ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
                                            borderRadius: '0.5rem',
                                            fontSize: '8px',
                                            color: isDark
                                                ? withAlpha(getTacsColor('whiteKhaki', 1), 0.7)
                                                : withAlpha(getTacsColor('blackGray', 8), 0.7),
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '3px'
                                        }}>
                                            <span>Word Count</span>
                                            <span style={{fontWeight: 'bold', color: getTacsColor('purple', 8)}}>436</span>
                                        </div>

                                        <div style={{
                                            padding: '0.3rem 0.5rem',
                                            background: isDark ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
                                            borderRadius: '0.5rem',
                                            fontSize: '8px',
                                            color: isDark
                                                ? withAlpha(getTacsColor('whiteKhaki', 1), 0.7)
                                                : withAlpha(getTacsColor('blackGray', 8), 0.7),
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '3px'
                                        }}>
                                            <span>Read Time</span>
                                            <span style={{fontWeight: 'bold', color: getTacsColor('teal', 8)}}>2m</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Decorative elements */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '10%',
                                        right: '-5%',
                                        width: '100px',
                                        height: '100px',
                                        background: withAlpha(getTacsColor('purple', 8), 0.2),
                                        borderRadius: '50%',
                                        filter: 'blur(30px)',
                                        zIndex: 1
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '20%',
                                        left: '0',
                                        width: '80px',
                                        height: '80px',
                                        background: withAlpha(getTacsColor('blue', 8), 0.2),
                                        borderRadius: '50%',
                                        filter: 'blur(25px)',
                                        zIndex: 1
                                    }}
                                />
                            </div>
                        </div>

                        {/* Stats or social proof */}
                        <div
                            style={{
                                marginTop: '3rem',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '3rem',
                                flexWrap: 'wrap'
                            }}
                        >
                            {[
                                { value: '80%', label: 'More Visual & Intuitive' },
                                { value: '3x', label: 'Faster Ideation' },
                                { value: '$5/mo', label: 'Affordable Price' },
                                { value: '24/7', label: 'AI Support' }
                            ].map((stat, index) => (
                                <div
                                    key={index}
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: 'clamp(1.5rem, 3vw, 2rem)',
                                            fontWeight: 700,
                                            color: getTacsColor('purple', 8),
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        {stat.value}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '0.9rem',
                                            color: isDark
                                                ? withAlpha(getTacsColor('whiteKhaki', 1), 0.7)
                                                : withAlpha(getTacsColor('blackGray', 8), 0.7)
                                        }}
                                    >
                                        {stat.label}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Add keyframes for floating animation */}
                    <style jsx global>{`
        @keyframes float {
            0%, 100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-5px);
            }
        }
    `}</style>
                </ThemedContainer>
































                {/* region */}
                {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* CELL: PLATFORM SECTION */}


                {/* region */}
                {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* KERNEL: PLATFORM SECTION TITLE */}


                <ThemedContainer
                    ref={platformSectionRef}
                    id="platforms"
                    variant="section"
                    role="region"
                    aria-labelledby="platforms-title"
                    style={{
                        padding: '4rem 1rem',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    {/* Decorative Background Elements */}
                    {/* Section Header with Enhanced Styling */}
                    <div className={`${styles.relative} ${styles.mb10}`} style={{
                        textAlign: 'center',
                        position: 'relative',
                        zIndex: 5,
                        maxWidth: '900px',
                        margin: '0 auto 3.5rem'
                    }}>
                        <div id="the four pillars-title" style={{
                            position: 'relative',
                            marginBottom: '1.75rem',
                            textAlign: 'center'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '0.5em',
                                width: '100%'
                            }}>
                                {/* Word 1: What */}
                                <Title_GeneralWebpages_Template
                                    text="The"
                                    to="#pillars"
                                    isDark={isDark}
                                    getTacsColor={getTacsColor}
                                    withAlpha={withAlpha}
                                    className={`${styles.revealFadeUp}`}
                                    style={{
                                        display: 'inline-block',
                                        fontSize: '3.5rem',
                                        fontWeight: '500',
                                        letterSpacing: '0.05em',
                                        background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        animation: 'textShimmer 6s infinite linear',
                                        backgroundSize: '200% 100%'
                                    }}
                                    showOutline={false}
                                    letterByLetter={true}
                                    paddingTop="0"
                                    ariaLabel="What the AIs Say title part 1"
                                />

                                {/* Word 2: the */}
                                <Title_GeneralWebpages_Template
                                    text="Four"
                                    to="#pillars"
                                    isDark={isDark}
                                    getTacsColor={getTacsColor}
                                    withAlpha={withAlpha}
                                    className={`${styles.revealFadeUp}`}
                                    style={{
                                        display: 'inline-block',
                                        fontSize: '3.5rem',
                                        fontWeight: '500',
                                        letterSpacing: '0.05em',
                                        background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        animation: 'textShimmer 6s infinite linear',
                                        backgroundSize: '200% 100%'
                                    }}
                                    showOutline={false}
                                    letterByLetter={true}
                                    paddingTop="0"
                                    ariaLabel="What the AIs Say title part 2"
                                />

                                {/* Word 3: AIs */}
                                <Title_GeneralWebpages_Template
                                    text="Pillars"
                                    to="#pillars"
                                    isDark={isDark}
                                    getTacsColor={getTacsColor}
                                    withAlpha={withAlpha}
                                    className={`${styles.revealFadeUp}`}
                                    style={{
                                        display: 'inline-block',
                                        fontSize: '3.5rem',
                                        fontWeight: '500',
                                        letterSpacing: '0.05em',
                                        background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        animation: 'textShimmer 6s infinite linear',
                                        backgroundSize: '200% 100%'
                                    }}
                                    showOutline={false}
                                    letterByLetter={true}
                                    paddingTop="0"
                                    ariaLabel="What the AIs Say title part 3"
                                />
</div>
                            {/* Decorative underline */}
                            <div style={{
                                position: 'relative',
                                height: '6px',
                                width: '150px',
                                margin: '1rem auto 0',
                                background: `linear-gradient(90deg, 
      transparent, 
      ${getTacsColor('blue', 8)}, 
      transparent)`,
                                borderRadius: '3px',
                                animation: 'borderPulse 3s infinite ease-in-out',
                                opacity: 0.8
                            }} />
                        </div>
                        <div
                            className={`${styles['text-center']} ${styles['max-w-lg']} ${styles.mx4} ${styles.revealFadeUp}`}
                            style={{
                                color: isDark ?
                                    withAlpha(getTacsColor('whiteKhaki', 1), 0.85) :
                                    withAlpha(getTacsColor('blackGray', 8), 0.85),
                                fontSize: '1.1rem',
                                lineHeight: 1.8,
                                fontWeight: 400,
                                margin: '0 auto 2.5rem',
                                maxWidth: '800px',
                                padding: '0 1rem',
                                position: 'relative',
                                zIndex: 2,
                                animation: 'fadeUpIn 0.8s ease forwards',
                                animationDelay: '0.2s',
                                opacity: 0
                            }}
                        >
                            <p>
                                Create your own AI and internet platform with Taytrom's four foundational pillars.
                                These integrated systems provide everything you need to build, learn, collaborate,
                                and exchange in the AI-native future.
                            </p>
                        </div>
                    </div>
                    {/* Enhanced Platforms Grid with Colorful Cards */}
                    <div
                        className={`${styles.grid} ${styles['grid-cols-1']} ${styles['md:grid-cols-2']} ${styles['lg:grid-cols-2']} ${styles['gap-8']}`}
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
                            gap: 'clamp(2rem, 5vw, 3.5rem)',
                            maxWidth: '1200px',
                            margin: '0 auto',
                            padding: '1rem',
                            position: 'relative',
                            zIndex: 5
                        }}
                    >
                        {Substance_Pillars_TaytromLanding_Homepage.map((section, idx) => {
                            // Define color palettes based on each pillar's SVG theme
                            let primaryColor, secondaryColor, accentColor, gradientStart, gradientEnd, glowColor;
                            let cardStyle = {};

                            // Set colors based on the pillar type
                            switch(section.id) {
                                case 'tacs':
                                    // Orange/Amber tones from TACS SVG (Rosetta stone visualization)
                                    primaryColor = "#FF9800";
                                    secondaryColor = "#F57C00";
                                    accentColor = "#FFD180";
                                    gradientStart = isDark ? "rgba(50, 30, 0, 0.9)" : "rgba(255, 248, 240, 0.95)";
                                    gradientEnd = isDark ? "rgba(40, 25, 0, 0.85)" : "rgba(255, 243, 224, 0.9)";
                                    glowColor = "rgba(255, 152, 0, 0.3)";
                                    cardStyle = {
                                        borderImage: isDark ?
                                            "linear-gradient(135deg, #F57C00, #FFB74D) 1" :
                                            "linear-gradient(135deg, #FFB74D, #F57C00) 1"
                                    };
                                    break;
                                case 'exchange':
                                    // Blue/Cyan tones from Exchange SVG (Marketplace visualization)
                                    primaryColor = "#0891B2";
                                    secondaryColor = "#0EA5E9";
                                    accentColor = "#22D3EE";
                                    gradientStart = isDark ? "rgba(0, 25, 50, 0.9)" : "rgba(240, 249, 255, 0.95)";
                                    gradientEnd = isDark ? "rgba(0, 20, 40, 0.85)" : "rgba(224, 242, 254, 0.9)";
                                    glowColor = "rgba(8, 145, 178, 0.3)";
                                    cardStyle = {
                                        borderImage: isDark ?
                                            "linear-gradient(135deg, #0891B2, #22D3EE) 1" :
                                            "linear-gradient(135deg, #22D3EE, #0891B2) 1"
                                    };
                                    break;
                                case 'ai':
                                    // Pink/Magenta tones from AI SVG (Brain visualization)
                                    primaryColor = "#E91E63";
                                    secondaryColor = "#C2185B";
                                    accentColor = "#F8BBD0";
                                    gradientStart = isDark ? "rgba(50, 0, 25, 0.9)" : "rgba(255, 240, 247, 0.95)";
                                    gradientEnd = isDark ? "rgba(40, 0, 20, 0.85)" : "rgba(253, 224, 235, 0.9)";
                                    glowColor = "rgba(233, 30, 99, 0.3)";
                                    cardStyle = {
                                        borderImage: isDark ?
                                            "linear-gradient(135deg, #C2185B, #F8BBD0) 1" :
                                            "linear-gradient(135deg, #F8BBD0, #C2185B) 1"
                                    };
                                    break;
                                case 'academy':
                                    // Green tones from Academy SVG (Learning tree visualization)
                                    primaryColor = "#558B2F";
                                    secondaryColor = "#33691E";
                                    accentColor = "#8BC34A";
                                    gradientStart = isDark ? "rgba(0, 40, 0, 0.9)" : "rgba(240, 255, 240, 0.95)";
                                    gradientEnd = isDark ? "rgba(0, 30, 0, 0.85)" : "rgba(220, 240, 220, 0.9)";
                                    glowColor = "rgba(85, 139, 47, 0.3)";
                                    cardStyle = {
                                        borderImage: isDark ?
                                            "linear-gradient(135deg, #33691E, #8BC34A) 1" :
                                            "linear-gradient(135deg, #8BC34A, #33691E) 1"
                                    };
                                    break;
                                default:
                                    // Fallback colors
                                    primaryColor = getTacsColor(section.colorScheme || 'teal', 8);
                                    secondaryColor = getTacsColor(section.colorScheme || 'blue', 7);
                                    accentColor = getTacsColor(section.colorScheme || 'orange', 6);
                                    gradientStart = isDark ? withAlpha(getTacsColor('blackGray', 8), 0.85) : withAlpha(getTacsColor('whiteKhaki', 1), 0.95);
                                    gradientEnd = isDark ? withAlpha(getTacsColor('blackGray', 9), 0.75) : withAlpha(getTacsColor('whiteKhaki', 2), 0.9);
                                    glowColor = withAlpha(primaryColor, 0.3);
                            }

                            // Generate unique gradient direction for visual variety
                            const gradientDirection = idx % 2 === 0 ? '145deg' : '135deg';
                            const gradientBackground = `linear-gradient(${gradientDirection}, ${withAlpha(primaryColor, isDark ? 0.4 : 0.15)}, ${withAlpha(secondaryColor, isDark ? 0.3 : 0.1)})`;

                            return (
                                <div
                                    key={section.id}
                                    className="platform-card"
                                    style={{
                                        background: `linear-gradient(${gradientDirection}, ${gradientStart}, ${gradientEnd})`,
                                        borderRadius: '1.5rem',
                                        padding: '2.5rem 2rem',
                                        boxShadow: isDark
                                            ? `0 20px 40px ${withAlpha(getTacsColor('blackGray', 10), 0.45)}, 0 0 30px ${glowColor}, inset 0 1px 1px ${withAlpha(accentColor, 0.1)}`
                                            : `0 20px 40px ${withAlpha(getTacsColor('blackGray', 10), 0.2)}, 0 0 30px ${glowColor}, inset 0 1px 1px ${withAlpha(accentColor, 0.2)}`,
                                        border: `1px solid ${isDark ? withAlpha(primaryColor, 0.35) : withAlpha(accentColor, 0.35)}`,
                                        backdropFilter: 'blur(15px)',
                                        WebkitBackdropFilter: 'blur(15px)',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                                        ...cardStyle
                                    }}
                                    onMouseEnter={(e) => {
                                        if (interactive) {
                                            e.currentTarget.style.transform = 'translateY(-8px) scale(1.02)';
                                            e.currentTarget.style.boxShadow = isDark
                                                ? `0 25px 50px ${withAlpha(getTacsColor('blackGray', 10), 0.5)}, 0 0 40px ${withAlpha(primaryColor, 0.4)}, inset 0 1px 1px ${withAlpha(accentColor, 0.15)}`
                                                : `0 25px 50px ${withAlpha(getTacsColor('blackGray', 10), 0.25)}, 0 0 40px ${withAlpha(primaryColor, 0.3)}, inset 0 1px 1px ${withAlpha(accentColor, 0.25)}`;
                                        }
                                    }}
                                    onMouseLeave={(e) => {
                                        if (interactive) {
                                            e.currentTarget.style.transform = '';
                                            e.currentTarget.style.boxShadow = isDark
                                                ? `0 20px 40px ${withAlpha(getTacsColor('blackGray', 10), 0.45)}, 0 0 30px ${glowColor}, inset 0 1px 1px ${withAlpha(accentColor, 0.1)}`
                                                : `0 20px 40px ${withAlpha(getTacsColor('blackGray', 10), 0.2)}, 0 0 30px ${glowColor}, inset 0 1px 1px ${withAlpha(accentColor, 0.2)}`;
                                        }
                                    }}
                                >

                                    {/* Enhanced background shape with dynamic glow */}
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        width: '90%',
                                        height: '90%',
                                        background: gradientBackground,
                                        borderRadius: '50%',
                                        transform: 'translate(40%, -40%)',
                                        opacity: 0.6,
                                        filter: `blur(70px)`,
                                        zIndex: 0,
                                        animation: 'glowPulse 8s infinite ease-in-out'
                                    }} />

                                    {/* Accent decorative elements to match SVG theme */}
                                    <div style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        left: '5%',
                                        width: '60%',
                                        height: '40%',
                                        background: `radial-gradient(circle, ${withAlpha(accentColor, 0.1)}, transparent 70%)`,
                                        borderRadius: '50%',
                                        opacity: 0.8,
                                        filter: 'blur(40px)',
                                        zIndex: 0,
                                        animation: 'glowPulse 6s infinite ease-in-out',
                                        animationDelay: '1s'
                                    }} />

                                    <div style={{
                                        position: 'absolute',
                                        top: '40%',
                                        right: '5%',
                                        width: '40%',
                                        height: '25%',
                                        background: `radial-gradient(circle, ${withAlpha(primaryColor, 0.1)}, transparent 70%)`,
                                        borderRadius: '50%',
                                        opacity: 0.6,
                                        filter: 'blur(30px)',
                                        zIndex: 0,
                                        animation: 'glowPulse 7s infinite ease-in-out',
                                        animationDelay: '0.5s'
                                    }} />

                                    {/* Title with enhanced gradient and styling */}
                                    <h3 style={{
                                        fontSize: '1.5rem',
                                        fontWeight: '700',
                                        marginBottom: '1rem',
                                        background: `linear-gradient(135deg, ${primaryColor}, ${secondaryColor}, ${accentColor})`,
                                        backgroundSize: '200% auto',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        display: 'inline-block',
                                        position: 'relative',
                                        zIndex: 2,
                                        animation: 'gradientShift 5s infinite linear',
                                        textShadow: isDark ? `0 2px 4px rgba(0,0,0,0.2)` : `0 2px 4px rgba(0,0,0,0.05)`
                                    }}>
                                        {section.title}
                                    </h3>

                                    {/* Description with improved styling */}
                                    <p style={{
                                        fontSize: '1rem',
                                        lineHeight: '1.6',
                                        marginBottom: '1.5rem',
                                        color: isDark
                                            ? withAlpha(getTacsColor('whiteKhaki', 1), 0.9)
                                            : withAlpha(getTacsColor('blackGray', 8), 0.9),
                                        flex: '0 0 auto',
                                        position: 'relative',
                                        zIndex: 2
                                    }}>
                                        {section.description}
                                    </p>

                                    {/* Visualization container with improved positioning */}
                                    <div style={{
                                        marginTop: "2rem",
                                        height: "240px",
                                        width: "100%",
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        zIndex: 2,
                                        transition: "transform 0.3s ease",
                                    }}>
                                        {section.visualization}
                                    </div>

                                    {/* Features list with enhanced styling */}
                                    <div style={{
                                        marginTop: 'auto',
                                        flex: '1 1 auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        position: 'relative',
                                        zIndex: 2
                                    }}>
                                        {section.features.map((feature, fidx) => (
                                            <div
                                                key={fidx}
                                                className="feature-item"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.75rem',
                                                    marginBottom: '0.85rem',
                                                    padding: '0.5rem 0.75rem',
                                                    position: 'relative',
                                                    borderRadius: '0.5rem',
                                                    transition: 'all 0.3s ease',
                                                    background: fidx % 2 === 0
                                                        ? withAlpha(primaryColor, isDark ? 0.15 : 0.08)
                                                        : 'transparent',
                                                }}
                                            >
                        <span style={{
                            color: fidx % 2 === 0 ? primaryColor : secondaryColor,
                            flexShrink: 0,
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'transform 0.3s ease',
                            transform: 'scale(1.2)'
                        }}>
                            {feature.icon}
                        </span>
                                                <span style={{
                                                    fontSize: '0.95rem',
                                                    fontWeight: 500,
                                                    color: isDark
                                                        ? withAlpha(getTacsColor('whiteKhaki', 1), 0.85)
                                                        : withAlpha(getTacsColor('blackGray', 8), 0.85)
                                                }}>
                            {feature.text}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {/* Animation keyframes for pillar cards */}
                    <style jsx global>{`
                        @keyframes gradientShift {
                            0% {
                                background-position: 0% 50%;
                            }
                            50% {
                                background-position: 100% 50%;
                            }
                            100% {
                                background-position: 0% 50%;
                            }
                        }

                        @keyframes glowPulse {
                            0%, 100% {
                                opacity: 0.6;
                                filter: blur(70px);
                            }
                            50% {
                                opacity: 0.8;
                                filter: blur(60px);
                            }
                        }

                        @keyframes floatEffect {
                            0%, 100% {
                                transform: translateY(0);
                            }
                            50% {
                                transform: translateY(-8px);
                            }
                        }

                        @keyframes shineEffect {
                            0% { transform: translateX(-100%) rotate(45deg); }
                            100% { transform: translateX(100%) rotate(45deg); }
                        }

                        .platform-card {
                            isolation: isolate;
                            will-change: transform, box-shadow;
                            transform: perspective(800px) rotateY(0deg);
                            backface-visibility: hidden;
                        }

                        .platform-card:hover .feature-item {
                            transform: translateX(5px);
                            transition-delay: calc(0.05s * var(--index));
                        }

                        .platform-card:hover h3 {
                            animation-duration: 3s !important;
                        }

                        .platform-card:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 200%;
                            height: 200%;
                            background: linear-gradient(
                                    60deg,
                                    rgba(255, 255, 255, 0) 20%,
                                    rgba(255, 255, 255, 0.1) 50%,
                                    rgba(255, 255, 255, 0) 80%
                            );
                            transform: translateX(-100%) rotate(45deg);
                            animation: shineEffect 6s infinite;
                            pointer-events: none;
                        }

                        /* Custom scrollbar for feature list if needed */
                        .feature-list::-webkit-scrollbar {
                            width: 4px;
                        }

                        .feature-list::-webkit-scrollbar-thumb {
                            background-color: rgba(255, 255, 255, 0.2);
                            border-radius: 4px;
                        }

                        /* Specific theme pulsing effects */
                        .platform-card.tacs-theme .accent-glow {
                            animation: pulseTacs 4s infinite alternate;
                        }

                        .platform-card.exchange-theme .accent-glow {
                            animation: pulseExchange 4s infinite alternate;
                        }

                        .platform-card.ai-theme .accent-glow {
                            animation: pulseAI 4s infinite alternate;
                        }

                        .platform-card.academy-theme .accent-glow {
                            animation: pulseAcademy 4s infinite alternate;
                        }

                        @keyframes pulseTacs {
                            0%, 100% { box-shadow: 0 0 20px rgba(255, 152, 0, 0.3); }
                            50% { box-shadow: 0 0 30px rgba(255, 152, 0, 0.5); }
                        }

                        @keyframes pulseExchange {
                            0%, 100% { box-shadow: 0 0 20px rgba(8, 145, 178, 0.3); }
                            50% { box-shadow: 0 0 30px rgba(8, 145, 178, 0.5); }
                        }

                        @keyframes pulseAI {
                            0%, 100% { box-shadow: 0 0 20px rgba(233, 30, 99, 0.3); }
                            50% { box-shadow: 0 0 30px rgba(233, 30, 99, 0.5); }
                        }

                        @keyframes pulseAcademy {
                            0%, 100% { box-shadow: 0 0 20px rgba(85, 139, 47, 0.3); }
                            50% { box-shadow: 0 0 30px rgba(85, 139, 47, 0.5); }
                        }
                    `}</style>
                </ThemedContainer>

              {/* region */}
              {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
              {/* CELL:  TESTIMONIALS SECTION */}




<ThemedContainer
    ref={testimonialsRef}
    id="testimonials"
    variant="section"
    className={`${styles.relative}`}
    style={{
        position: 'relative',
        padding: '6rem 1rem',
        overflow: 'hidden',
        isolation: 'isolate'
    }}
    role="region"
    aria-labelledby="testimonials-title"
>
    {/* Simplified Background Elements */}
    <div style={{
        position: 'absolute',
        top: '5%',
        left: '3%',
        width: '40%',
        height: '50%',
        background: `radial-gradient(ellipse, ${withAlpha(getTacsColor('purple', 8), 0.05)}, transparent 70%)`,
        zIndex: 0,
        filter: 'blur(40px)',
        transform: 'rotate(-10deg)'
    }} />

    <div style={{
        position: 'absolute',
        bottom: '10%',
        right: '5%',
        width: '30%',
        height: '40%',
        background: `radial-gradient(ellipse, ${withAlpha(getTacsColor('teal', 8), 0.05)}, transparent 70%)`,
        zIndex: 0,
        filter: 'blur(30px)',
        transform: 'rotate(15deg)'
    }} />

    {/* Essential Animation Keyframes */}
    <style jsx global>{`
        @keyframes fadeScale {
            0% { opacity: 0; transform: translateY(10px); }
            100% { opacity: 1; transform: translateY(0); }
        }

        @keyframes textShimmer {
            0% { background-position: 200% 0; }
            100% { background-position: 0 0; }
        }

        .testimonial-card {
            transition: all 0.3s ease;
            animation: fadeScale 0.5s backwards;
        }

        .testimonial-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 15px 30px ${withAlpha(getTacsColor('blackGray', 10), isDark ? 0.3 : 0.1)};
        }

        .pillar-button {
            transition: all 0.2s ease;
        }

        .pillar-button:hover {
            transform: translateY(-2px);
        }

        .carousel-control {
            transition: all 0.2s ease;
        }

        .carousel-control:hover {
            transform: scale(1.05);
        }
    `}</style>

    {/* Section Header - Simplified */}
    <div className={`${styles.relative} ${styles.mb10}`} style={{
        textAlign: 'center',
        position: 'relative',
        zIndex: 5,
        maxWidth: '900px',
        margin: '0 auto 3rem'
    }}>
        <div id="testimonials-title" style={{
            position: 'relative',
            marginBottom: '1.5rem',
            textAlign: 'center'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '0.5em',
                width: '100%'
            }}>
                {/* Title Words */}
                {["What", "the", "AIs", "Say"].map((word, idx) => (
                    <Title_GeneralWebpages_Template
                        key={`title-word-${idx}`}
                        text={word}
                        to="#testimonials"
                        isDark={isDark}
                        getTacsColor={getTacsColor}
                        withAlpha={withAlpha}
                        className={`${styles.revealFadeUp}`}
                        style={{
                            display: 'inline-block',
                            fontSize: '3.5rem',
                            fontWeight: '500',
                            letterSpacing: '0.05em',
                            background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: 'textShimmer 6s infinite linear',
                            backgroundSize: '200% 100%'
                        }}
                        showOutline={false}
                        letterByLetter={true}
                        paddingTop="0"
                        ariaLabel={`What the AIs Say title part ${idx + 1}`}
                    />
                ))}
            </div>

            {/* Decorative underline - Simplified */}
            <div style={{
                position: 'relative',
                height: '4px',
                width: '120px',
                margin: '0.75rem auto 0',
                background: `linear-gradient(90deg, transparent, ${getTacsColor('blue', 8)}, transparent)`,
                borderRadius: '2px',
                opacity: 0.7
            }} />
        </div>

        {/* Description - More concise */}
        <p style={{
            ...get3DTextEffect('elegant', {
                fontSize: '1.1rem',
                lineHeight: 1.6,
                letterSpacing: '0.01em',
                fontWeight: 400,
                textShadow: isDark ?
                    '0 1px 1px rgba(0,0,0,0.15)' :
                    '0 1px 1px rgba(0,0,0,0.05)'
            }),
            color: isDark ?
                withAlpha(getTacsColor('whiteKhaki', 1), 0.85) :
                withAlpha(getTacsColor('blackGray', 8), 0.85),
            maxWidth: '700px',
            margin: '0 auto 2rem',
            padding: '0 1rem'
        }}>
            Leading AI models share insights about Taytrom's revolutionary approach
        </p>
    </div>

    {/* Simplified Pillar Filter Tabs */}
    <div style={{
        maxWidth: '1000px',
        margin: '0 auto 3rem',
        position: 'relative',
        zIndex: 5
    }}>
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '0.75rem',
            marginBottom: '2.5rem',
            padding: '0 1rem'
        }}>
            {/* All Pillars Button */}
            <button
                className="pillar-button"
                style={{
                    padding: '0.75rem 1.25rem',
                    borderRadius: '2rem',
                    background: selectedPillar === 'all-pillars'
                        ? `linear-gradient(135deg, ${withAlpha("#F59E0B", isDark ? 0.25 : 0.15)}, ${withAlpha("#F97316", isDark ? 0.25 : 0.15)})`
                        : withAlpha(isDark ? getTacsColor('blackGray', 8) : getTacsColor('whiteKhaki', 1), 0.4),
                    border: `1px solid ${withAlpha("#F59E0B", selectedPillar === 'all-pillars' ? (isDark ? 0.5 : 0.4) : (isDark ? 0.1 : 0.08))}`,
                    color: selectedPillar === 'all-pillars'
                        ? "#F59E0B"
                        : (isDark ?
                            withAlpha(getTacsColor('whiteKhaki', 1), 0.8) :
                            withAlpha(getTacsColor('blackGray', 8), 0.8)),
                    fontWeight: selectedPillar === 'all-pillars' ? 600 : 400,
                    backdropFilter: 'blur(8px)',
                    WebkitBackdropFilter: 'blur(8px)',
                    boxShadow: selectedPillar === 'all-pillars' ?
                        `0 5px 15px ${withAlpha("#F59E0B", isDark ? 0.2 : 0.1)}` : 'none',
                    cursor: 'pointer'
                }}
                onClick={() => setSelectedPillar('all-pillars')}
                aria-pressed={selectedPillar === 'all-pillars'}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    position: 'relative',
                    zIndex: 1
                }}>
                    <Hexagon size={14} color={selectedPillar === 'all-pillars' ? "#F59E0B" : withAlpha("#F59E0B", 0.8)} />
                    <span>All Pillars</span>
                </div>
            </button>

            {/* AI Pillar Button */}
            <button
                className="pillar-button"
                style={{
                    padding: '0.75rem 1.25rem',
                    borderRadius: '2rem',
                    background: selectedPillar === 'ai'
                        ? `linear-gradient(135deg, ${withAlpha("#E91E63", isDark ? 0.25 : 0.15)}, ${withAlpha("#C2185B", isDark ? 0.25 : 0.15)})`
                        : withAlpha(isDark ? getTacsColor('blackGray', 8) : getTacsColor('whiteKhaki', 1), 0.4),
                    border: `1px solid ${withAlpha("#E91E63", selectedPillar === 'ai' ? (isDark ? 0.5 : 0.4) : (isDark ? 0.1 : 0.08))}`,
                    color: selectedPillar === 'ai'
                        ? "#E91E63"
                        : (isDark ?
                            withAlpha(getTacsColor('whiteKhaki', 1), 0.8) :
                            withAlpha(getTacsColor('blackGray', 8), 0.8)),
                    fontWeight: selectedPillar === 'ai' ? 600 : 400,
                    backdropFilter: 'blur(8px)',
                    WebkitBackdropFilter: 'blur(8px)',
                    boxShadow: selectedPillar === 'ai' ?
                        `0 5px 15px ${withAlpha("#E91E63", isDark ? 0.2 : 0.1)}` : 'none',
                    cursor: 'pointer'
                }}
                onClick={() => setSelectedPillar('ai')}
                aria-pressed={selectedPillar === 'ai'}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    position: 'relative',
                    zIndex: 1
                }}>
                    <Brain size={14} color={selectedPillar === 'ai' ? "#E91E63" : withAlpha("#E91E63", 0.8)} />
                    <span>AI</span>
                </div>
            </button>

            {/* Exchange Pillar Button */}
            <button
                className="pillar-button"
                style={{
                    padding: '0.75rem 1.25rem',
                    borderRadius: '2rem',
                    background: selectedPillar === 'exchange'
                        ? `linear-gradient(135deg, ${withAlpha("#0891B2", isDark ? 0.25 : 0.15)}, ${withAlpha("#0EA5E9", isDark ? 0.25 : 0.15)})`
                        : withAlpha(isDark ? getTacsColor('blackGray', 8) : getTacsColor('whiteKhaki', 1), 0.4),
                    border: `1px solid ${withAlpha("#0891B2", selectedPillar === 'exchange' ? (isDark ? 0.5 : 0.4) : (isDark ? 0.1 : 0.08))}`,
                    color: selectedPillar === 'exchange'
                        ? "#0891B2"
                        : (isDark ?
                            withAlpha(getTacsColor('whiteKhaki', 1), 0.8) :
                            withAlpha(getTacsColor('blackGray', 8), 0.8)),
                    fontWeight: selectedPillar === 'exchange' ? 600 : 400,
                    backdropFilter: 'blur(8px)',
                    WebkitBackdropFilter: 'blur(8px)',
                    boxShadow: selectedPillar === 'exchange' ?
                        `0 5px 15px ${withAlpha("#0891B2", isDark ? 0.2 : 0.1)}` : 'none',
                    cursor: 'pointer'
                }}
                onClick={() => setSelectedPillar('exchange')}
                aria-pressed={selectedPillar === 'exchange'}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    position: 'relative',
                    zIndex: 1
                }}>
                    <GitPullRequest size={14} color={selectedPillar === 'exchange' ? "#0891B2" : withAlpha("#0891B2", 0.8)} />
                    <span>Exchange</span>
                </div>
            </button>

            {/* Academy Pillar Button */}
            <button
                className="pillar-button"
                style={{
                    padding: '0.75rem 1.25rem',
                    borderRadius: '2rem',
                    background: selectedPillar === 'academy'
                        ? `linear-gradient(135deg, ${withAlpha("#558B2F", isDark ? 0.25 : 0.15)}, ${withAlpha("#8BC34A", isDark ? 0.25 : 0.15)})`
                        : withAlpha(isDark ? getTacsColor('blackGray', 8) : getTacsColor('whiteKhaki', 1), 0.4),
                    border: `1px solid ${withAlpha("#558B2F", selectedPillar === 'academy' ? (isDark ? 0.5 : 0.4) : (isDark ? 0.1 : 0.08))}`,
                    color: selectedPillar === 'academy'
                        ? "#558B2F"
                        : (isDark ?
                            withAlpha(getTacsColor('whiteKhaki', 1), 0.8) :
                            withAlpha(getTacsColor('blackGray', 8), 0.8)),
                    fontWeight: selectedPillar === 'academy' ? 600 : 400,
                    backdropFilter: 'blur(8px)',
                    WebkitBackdropFilter: 'blur(8px)',
                    boxShadow: selectedPillar === 'academy' ?
                        `0 5px 15px ${withAlpha("#558B2F", isDark ? 0.2 : 0.1)}` : 'none',
                    cursor: 'pointer'
                }}
                onClick={() => setSelectedPillar('academy')}
                aria-pressed={selectedPillar === 'academy'}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    position: 'relative',
                    zIndex: 1
                }}>
                    <BookOpen size={14} color={selectedPillar === 'academy' ? "#558B2F" : withAlpha("#558B2F", 0.8)} />
                    <span>Academy</span>
                </div>
            </button>

            {/* TACS Pillar Button */}
            <button
                className="pillar-button"
                style={{
                    padding: '0.75rem 1.25rem',
                    borderRadius: '2rem',
                    background: selectedPillar === 'tacs'
                        ? `linear-gradient(135deg, ${withAlpha("#FF9800", isDark ? 0.25 : 0.15)}, ${withAlpha("#F57C00", isDark ? 0.25 : 0.15)})`
                        : withAlpha(isDark ? getTacsColor('blackGray', 8) : getTacsColor('whiteKhaki', 1), 0.4),
                    border: `1px solid ${withAlpha("#FF9800", selectedPillar === 'tacs' ? (isDark ? 0.5 : 0.4) : (isDark ? 0.1 : 0.08))}`,
                    color: selectedPillar === 'tacs'
                        ? "#FF9800"
                        : (isDark ?
                            withAlpha(getTacsColor('whiteKhaki', 1), 0.8) :
                            withAlpha(getTacsColor('blackGray', 8), 0.8)),
                    fontWeight: selectedPillar === 'tacs' ? 600 : 400,
                    backdropFilter: 'blur(8px)',
                    WebkitBackdropFilter: 'blur(8px)',
                    boxShadow: selectedPillar === 'tacs' ?
                        `0 5px 15px ${withAlpha("#FF9800", isDark ? 0.2 : 0.1)}` : 'none',
                    cursor: 'pointer'
                }}
                onClick={() => setSelectedPillar('tacs')}
                aria-pressed={selectedPillar === 'tacs'}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    position: 'relative',
                    zIndex: 1
                }}>
                    <Database size={14} color={selectedPillar === 'tacs' ? "#FF9800" : withAlpha("#FF9800", 0.8)} />
                    <span>TACS</span>
                </div>
            </button>
        </div>

        {/* Simplified Carousel View */}
        <div style={{
            position: 'relative',
            width: '100%',
            marginBottom: '2rem'
        }}>
            <div
                style={{
                    display: 'flex',
                    overflow: 'hidden',
                    padding: '2rem 1rem',
                    scrollSnapType: 'x mandatory',
                    position: 'relative'
                }}
                ref={carouselRef}
            >
                {/* Filter testimonials based on selected pillar and just show top 3-4 */}
                {(selectedPillar === 'all-pillars' ? overviewTestimonials : pillarTestimonials[selectedPillar.replace('-', '')] || [])
                    .slice(0, 4) // Just show top 4 testimonials
                    .map((testimonial, idx) => {
                        const modelColor = testimonial.model.color;
                        const pillarColor = testimonial.pillar ? testimonial.pillar.color : modelColor;
                        const isActive = idx === activeCarouselSlide;

                        return (
                            <div
                                key={testimonial.id}
                                className="testimonial-card"
                                style={{
                                    flex: '0 0 auto',
                                    width: '100%',
                                    maxWidth: '700px',
                                    padding: '0 1rem',
                                    scrollSnapAlign: 'center',
                                    opacity: Math.abs(idx - activeCarouselSlide) > 1 ? 0.5 : 1,
                                    transform: isActive ? 'scale(1.02)' : 'scale(1)',
                                    transition: 'all 0.3s ease',
                                    margin: '0 auto',
                                    animationDelay: `${0.1 + idx * 0.05}s`
                                }}
                            >
                                <div
                                    style={{
                                        height: '100%',
                                        background: isDark
                                            ? `linear-gradient(145deg, ${withAlpha(getTacsColor('blackGray', 8), 0.9)}, ${withAlpha(getTacsColor('blackGray', 9), 0.85)})`
                                            : `linear-gradient(145deg, ${withAlpha(getTacsColor('whiteKhaki', 1), 0.95)}, ${withAlpha(getTacsColor('whiteKhaki', 2), 0.9)})`,
                                        padding: '2.5rem',
                                        borderRadius: '3.25rem',
                                        border: `3px solid ${withAlpha(modelColor, isDark ? 0.4 : 0.3)}`,
                                        boxShadow: `0px 15px 30px ${withAlpha(getTacsColor('blackGray', 10), isDark ? 0.3 : 0.1)}`,
                                        backdropFilter: 'blur(12px)',
                                        WebkitBackdropFilter: 'blur(12px)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: 'relative'
                                    }}
                                >

                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        marginBottom: '1.5rem'
                                    }}>
                                        {/* Avatar */}
                                        <div
                                            className="model-avatar"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '3.5rem',
                                                height: '3.5rem',
                                                marginRight: '1.25rem',
                                                borderRadius: '50%',
                                                background: `linear-gradient(135deg, ${withAlpha(modelColor, 0.2)}, ${withAlpha(modelColor, 0.4)})`,
                                                border: `2px solid ${withAlpha(modelColor, 0.5)}`,
                                                boxShadow: `0 5px 15px ${withAlpha(modelColor, 0.25)}`,
                                                position: 'relative'
                                            }}
                                        >
                                            {/* Initial letter */}
                                            <div style={{
                                                color: isDark ? getTacsColor('whiteKhaki', 1) : modelColor,
                                                fontSize: '1.5rem',
                                                fontWeight: 'bold'
                                            }}>
                                                {testimonial.model.shortName.charAt(0)}
                                            </div>
                                        </div>

                                        <div>
                                            <h3
                                                style={{
                                                    fontSize: '1.25rem',
                                                    lineHeight: 1.4,
                                                    fontWeight: 600,
                                                    color: isDark ?
                                                        withAlpha(getTacsColor('whiteKhaki', 1), 0.95) :
                                                        withAlpha(getTacsColor('blackGray', 8), 0.95),
                                                    margin: '0 0 0.25rem'
                                                }}
                                            >
                                                {testimonial.model.name}
                                            </h3>
                                            <p
                                                style={{
                                                    fontSize: '0.875rem',
                                                    lineHeight: 1.2,
                                                    color: isDark ?
                                                        withAlpha(getTacsColor('whiteKhaki', 1), 0.6) :
                                                        withAlpha(getTacsColor('blackGray', 8), 0.6),
                                                    margin: 0
                                                }}
                                            >
                                                {testimonial.model.title}
                                            </p>
                                        </div>

                                        {/* Pillar badge */}
                                        {testimonial.pillar && (
                                            <div
                                                style={{
                                                    marginLeft: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.25rem',
                                                    borderRadius: '2rem',
                                                    padding: '0.4rem 0.8rem',
                                                    background: withAlpha(pillarColor, isDark ? 0.2 : 0.15),
                                                    border: `1px solid ${withAlpha(pillarColor, isDark ? 0.5 : 0.4)}`
                                                }}
                                            >
                                                <div style={{color: pillarColor}}>
                                                    {testimonial.pillar.icon}
                                                </div>
                                                <span
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        lineHeight: 1.2,
                                                        fontWeight: 600,
                                                        color: pillarColor
                                                    }}
                                                >
                                                    {testimonial.pillar.id === 'all-pillars'
                                                        ? 'Overview'
                                                        : testimonial.pillar.name}
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                    {/* Quote Content */}
                                    <div style={{
                                        position: 'relative',
                                        flexGrow: 1
                                    }}>
                                        <blockquote
                                            style={{
                                                position: 'relative',
                                                zIndex: 1,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        >
                                            {/* Quote mark */}
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    fontSize: '4rem',
                                                    lineHeight: 1,
                                                    fontFamily: 'serif',
                                                    transform: 'translate(-0.5rem, -1.5rem)',
                                                    color: withAlpha(modelColor, 0.15),
                                                    userSelect: 'none'
                                                }}
                                                aria-hidden="true"
                                            >
                                                "
                                            </span>

                                            <div
                                                style={{
                                                    fontSize: '1.125rem',
                                                    lineHeight: 1.6,
                                                    fontWeight: 400,
                                                    position: 'relative',
                                                    zIndex: 2,
                                                    paddingLeft: '1rem',
                                                    color: isDark ?
                                                        withAlpha(getTacsColor('whiteKhaki', 1), 0.85) :
                                                        withAlpha(getTacsColor('blackGray', 8), 0.85)
                                                }}
                                            >
                                                {/* Truncate long quotes */}
                                                {testimonial.quote.length > 220
                                                    ? testimonial.quote.substring(0, 220) + '...'
                                                    : testimonial.quote}
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>

            {/* Simplified Carousel Navigation */}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
                marginTop: '1.5rem'
            }}>
                <button
                    className="carousel-control"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '2.5rem',
                        height: '2.5rem',
                        borderRadius: '50%',
                        background: isDark ?
                            withAlpha(getTacsColor('blackGray', 8), 0.7) :
                            withAlpha(getTacsColor('whiteKhaki', 1), 0.7),
                        border: `1px solid ${withAlpha(
                            isDark ? getTacsColor('whiteKhaki', 1) : getTacsColor('blackGray', 10),
                            isDark ? 0.1 : 0.1
                        )}`,
                        boxShadow: `0 4px 10px ${withAlpha(getTacsColor('blackGray', 10), isDark ? 0.2 : 0.1)}`,
                        backdropFilter: 'blur(5px)',
                        WebkitBackdropFilter: 'blur(5px)',
                        cursor: 'pointer',
                        opacity: activeCarouselSlide === 0 ? 0.5 : 1,
                        pointerEvents: activeCarouselSlide === 0 ? 'none' : 'auto'
                    }}
                    onClick={() => {
                        if (activeCarouselSlide > 0) {
                            setActiveCarouselSlide(activeCarouselSlide - 1);
                            scrollToSlide(activeCarouselSlide - 1);
                        }
                    }}
                    disabled={activeCarouselSlide === 0}
                    aria-label="Previous testimonial"
                >
                    <ChevronLeft size={20}
                                 color={isDark ?
                                     withAlpha(getTacsColor('whiteKhaki', 1), 0.8) :
                                     withAlpha(getTacsColor('blackGray', 8), 0.8)}/>
                </button>

                <div style={{
                    display: 'flex',
                    gap: '0.5rem'
                }}>
                    {(selectedPillar === 'all-pillars' ? overviewTestimonials : pillarTestimonials[selectedPillar.replace('-', '')] || [])
                        .slice(0, 4)
                        .map((_, idx) => {
                            const isActive = idx === activeCarouselSlide;
                            return (
                                <button
                                    key={idx}
                                    style={{
                                        width: isActive ? '2rem' : '0.5rem',
                                        height: '0.5rem',
                                        borderRadius: '1rem',
                                        background: isActive
                                            ? PRIMARY_COLORS[isDark ? 'dark' : 'light'][0]
                                            : (isDark ?
                                                withAlpha(getTacsColor('whiteKhaki', 1), 0.2) :
                                                withAlpha(getTacsColor('blackGray', 10), 0.1)),
                                        border: 'none',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        padding: 0
                                    }}
                                    onClick={() => {
                                        setActiveCarouselSlide(idx);
                                        scrollToSlide(idx);
                                    }}
                                    aria-label={`Go to testimonial ${idx + 1}`}
                                    aria-current={isActive ? 'step' : undefined}
                                />
                            );
                        })}
                </div>

                <button
                    className="carousel-control"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '2.5rem',
                        height: '2.5rem',
                        borderRadius: '50%',
                        background: isDark ?
                            withAlpha(getTacsColor('blackGray', 8), 0.7) :
                            withAlpha(getTacsColor('whiteKhaki', 1), 0.7),
                        border: `1px solid ${withAlpha(
                            isDark ? getTacsColor('whiteKhaki', 1) : getTacsColor('blackGray', 10),
                            isDark ? 0.1 : 0.1
                        )}`,
                        boxShadow: `0 4px 10px ${withAlpha(getTacsColor('blackGray', 10), isDark ? 0.2 : 0.1)}`,
                        backdropFilter: 'blur(5px)',
                        WebkitBackdropFilter: 'blur(5px)',
                        cursor: 'pointer',
                        opacity: activeCarouselSlide >= Math.min(3, (selectedPillar === 'all-pillars' ? overviewTestimonials : pillarTestimonials[selectedPillar.replace('-', '')] || []).length - 1) ? 0.5 : 1,
                        pointerEvents: activeCarouselSlide >= Math.min(3, (selectedPillar === 'all-pillars' ? overviewTestimonials : pillarTestimonials[selectedPillar.replace('-', '')] || []).length - 1) ? 'none' : 'auto'
                    }}
                    onClick={() => {
                        const maxSlide = Math.min(3, (selectedPillar === 'all-pillars' ? overviewTestimonials : pillarTestimonials[selectedPillar.replace('-', '')] || []).length - 1);
                        if (activeCarouselSlide < maxSlide) {
                            setActiveCarouselSlide(activeCarouselSlide + 1);
                            scrollToSlide(activeCarouselSlide + 1);
                        }
                    }}
                    disabled={activeCarouselSlide >= Math.min(3, (selectedPillar === 'all-pillars' ? overviewTestimonials : pillarTestimonials[selectedPillar.replace('-', '')] || []).length - 1)}
                    aria-label="Next testimonial"
                >
                    <ChevronRight size={20}
                                  color={isDark ?
                                      withAlpha(getTacsColor('whiteKhaki', 1), 0.8) :
                                      withAlpha(getTacsColor('blackGray', 8), 0.8)}/>
                </button>
            </div>
        </div>
    </div>

    {/* Simplified footer accent */}
    <div style={{
        textAlign: 'center',
        marginTop: '2rem'
    }}>
        <div style={{
            display: 'inline-block',
            width: '60px',
            height: '3px',
            background: `linear-gradient(90deg, transparent, ${getTacsColor('blue', 8)}, transparent)`,
            borderRadius: '2px',
            opacity: 0.6
        }} />
    </div>
</ThemedContainer>

                {/* region */}
                {/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* CELL:  MAILING LIST SECTION */}


                {/* Mailing List Section */}
                {/* Mailing List Section */}
                <ThemedContainer
                    ref={mailinglistRef}
                    id="mailinglist"
                    variant="section"
                    className={`${styles.relative} ${styles.py24} ${styles.px8} ${styles['flex-column']} ${styles['items-center']} ${styles.transition} ${styles.overflow} ${isVisible ? styles.opacity100 : styles.opacity0}`}
                    style={{
        minHeight: '80vh', // Increased from 60vh for more vertical space
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
        padding: '8rem 2rem' // Increased padding from 4rem to 8rem
                    }}
                    role="region"
                    aria-labelledby="mailinglist-title"
                >
    {/* Title section with increased spacing */}
    <div
        style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: '0.5em',
            width: '100%',
            marginBottom: '5rem' // Added significant bottom margin
        }}
    >
                        {/* Word 1: Sign */}
                        <Title_GeneralWebpages_Template
                            text="Sign"
                            to="#mailinglist"
                            isDark={isDark}
                            getTacsColor={getTacsColor}
                            withAlpha={withAlpha}
                            className={`${styles.revealFadeUp}`}
                            style={{
                                display: 'inline-block',
                                fontSize: '3.5rem',
                                fontWeight: '500',
                                letterSpacing: '0.05em',
                                background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                animation: 'textShimmer 6s infinite linear',
                                backgroundSize: '200% 100%'
                            }}
                            showOutline={false}
                            letterByLetter={true}
                            paddingTop="0"
                            ariaLabel="Sign up for beta access title part 1"
                        />

                        {/* Word 2: Up */}
                        <Title_GeneralWebpages_Template
                            text="Up"
                            to="#mailinglist"
                            isDark={isDark}
                            getTacsColor={getTacsColor}
                            withAlpha={withAlpha}
                            className={`${styles.revealFadeUp}`}
                            style={{
                                display: 'inline-block',
                                fontSize: '3.5rem',
                                fontWeight: '500',
                                letterSpacing: '0.05em',
                                background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                animation: 'textShimmer 6s infinite linear',
                                backgroundSize: '200% 100%'
                            }}
                            showOutline={false}
                            letterByLetter={true}
                            paddingTop="0"
                            ariaLabel="Sign up for beta access title part 2"
                        />

                        {/* Word 3: For */}
                        <Title_GeneralWebpages_Template
                            text="For"
                            to="#mailinglist"
                            isDark={isDark}
                            getTacsColor={getTacsColor}
                            withAlpha={withAlpha}
                            className={`${styles.revealFadeUp}`}
                            style={{
                                display: 'inline-block',
                                fontSize: '3.5rem',
                                fontWeight: '500',
                                letterSpacing: '0.05em',
                                background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                animation: 'textShimmer 6s infinite linear',
                                backgroundSize: '200% 100%'
                            }}
                            showOutline={false}
                            letterByLetter={true}
                            paddingTop="0"
                            ariaLabel="Sign up for beta access title part 3"
                        />

                        {/* Word 4: Beta */}
                        <Title_GeneralWebpages_Template
                            text="Beta"
                            to="#mailinglist"
                            isDark={isDark}
                            getTacsColor={getTacsColor}
                            withAlpha={withAlpha}
                            className={`${styles.revealFadeUp}`}
                            style={{
                                display: 'inline-block',
                                fontSize: '3.5rem',
                                fontWeight: '500',
                                letterSpacing: '0.05em',
                                background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                animation: 'textShimmer 6s infinite linear',
                                backgroundSize: '200% 100%'
                            }}
                            showOutline={false}
                            letterByLetter={true}
                            paddingTop="0"
                            ariaLabel="Sign up for beta access title part 4"
                        />

                        {/* Word 5: Access */}
                        <Title_GeneralWebpages_Template
                            text="Access"
                            to="#mailinglist"
                            isDark={isDark}
                            getTacsColor={getTacsColor}
                            withAlpha={withAlpha}
                            className={`${styles.revealFadeUp}`}
                            style={{
                                display: 'inline-block',
                                fontSize: '3.5rem',
                                fontWeight: '500',
                                letterSpacing: '0.05em',
                                background: `linear-gradient(135deg, ${getTacsColor('teal', 8)}, ${getTacsColor('blue', 8)})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                animation: 'textShimmer 6s infinite linear',
                                backgroundSize: '200% 100%'
                            }}
                            showOutline={false}
                            letterByLetter={true}
                            paddingTop="0"
                            ariaLabel="Sign up for beta access title part 5"
                        />
                    </div>

                    {/* Form or Success Message */}
                    <div
                        className={`${styles['w-full']} ${styles['max-w-xl']} ${styles['mb-8']} ${styles.transition} ${styles.relative}`}
                    >
                        {submitted ? (
                            <div
                                className={`${styles.p10} ${styles['rounded-xl']} ${styles['text-center']} ${styles['flex-column']} ${styles['items-center']} ${styles['gap-6']} ${styles.border} ${isDark ? styles['frost-glass'] : styles['dark-glass']}`}
                                style={{
                                    padding: '2rem',
                                    borderRadius: '1rem',
                                    background: isDark ?
                                        withAlpha(getTacsColor('blackGray', 8), 0.7) :
                                        withAlpha(getTacsColor('whiteKhaki', 1), 0.7),
                                    backdropFilter: 'blur(10px)',
                                    WebkitBackdropFilter: 'blur(10px)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '1.5rem'
                                }}
                            >
                                <div
                                    className={`${styles['flex-center']} ${styles['w-18']} ${styles['h-18']} ${styles['rounded-full']} ${styles['gradient-background']} ${styles['shadow-md']}`}
                                    style={{
                                        width: '4rem',
                                        height: '4rem',
                                        borderRadius: '50%',
                                        background: PRIMARY_COLORS[isDark ? 'dark' : 'light'][0],
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CheckCircle size={35} color={getTacsColor('whiteKhaki', 1)}/>
                                </div>

                                <div>
                                    <h3
                                        className={`${styles['text-2xl']} ${styles['font-bold']} ${styles['mb-3']} text-3d-reflection`}
                                        style={{
                                            ...get3DTextEffect('elegant', {
                                                fontSize: '1.5rem',
                                                lineHeight: 1.4,
                                                letterSpacing: '0.01em',
                                                fontWeight: 700,
                                                textShadow: isDark ?
                                                    '0 1px 2px rgba(0,0,0,0.2), 0 2px 4px rgba(0,0,0,0.1)' :
                                                    '0 1px 1px rgba(0,0,0,0.15), 0 2px 2px rgba(0,0,0,0.05)'
                                            })
                                        }}
                                    >
                                        You're in! Welcome to the Beta
                                    </h3>

    <p
        className={`${styles['text-center']} ${styles['max-w-lg']} ${styles.px4} ${styles.lead} text-3d-reflection`}
        style={{
            ...get3DTextEffect('elegant', {
                fontSize: '1.1rem',
                lineHeight: 1.6,
                letterSpacing: '0.01em',
                fontWeight: 400,
                textShadow: isDark ?
                    '0 1px 1px rgba(0,0,0,0.2), 0 1px 2px rgba(0,0,0,0.1)' :
                    '0 1px 1px rgba(0,0,0,0.15), 0 1px 1px rgba(0,0,0,0.05)'
            }),
            color: isDark ?
                withAlpha(getTacsColor('whiteKhaki', 1), 0.8) :
                withAlpha(getTacsColor('blackGray', 8), 0.8),
            marginBottom: '4rem' // Increased bottom margin
        }}
    >
        What if an entire generation were sitting in waiting—ready to
        create, ready to innovate, ready to change the world?
    </p>

                                </div>
                            </div>
                        ) : (
                            <form
                                onSubmit={onSubmit}
                                className={`${styles['w-full']} ${styles['flex-column']} ${styles['gap-8']} ${styles.relative}`}
                            >
                                <div
                                    className={`${styles.flex} ${styles['rounded-xl']} ${styles.overflow} ${styles['shadow-lg']} ${isDark ? styles['frost-glass'] : styles['dark-glass']} ${styles.relative}`}
                                    style={{
                                        borderRadius: '0.75rem',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        boxShadow: `0 10px 25px ${withAlpha(getTacsColor('blackGray', 10), 0.1)}`
                                    }}
                                >
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={debouncedEmailHandler}
                                        placeholder="Enter your email for beta access"
                                        ref={inputRef}
                                        className={`${styles.flex1} ${styles.border0} ${styles.p5} ${styles['text-md']} ${styles['bg-transparent']} ${styles.outline0} ${styles.relative} ${styles.z1} text-3d-reflection`}
                                        style={{
                                            background: 'transparent',
                                            border: 'none',
                                            padding: '1rem 1.5rem',
                                            color: isDark ?
                                                withAlpha(getTacsColor('whiteKhaki', 1), 0.9) :
                                                withAlpha(getTacsColor('blackGray', 8), 0.9),
                                            flex: 1,
                                            outline: 'none',
                                            ...get3DTextEffect('elegant', {
                                                fontSize: '1rem',
                                                lineHeight: 1.5,
                                                letterSpacing: '0.01em',
                                                textShadow: isDark ? '0 1px 1px rgba(0,0,0,0.1)' : '0 1px 1px rgba(0,0,0,0.05)'
                                            })
                                        }}
                                        aria-label="Email for beta access"
                                        aria-describedby={error ? "email-error" : undefined}
                                    />

                                    <button
                                        ref={subscribeButtonRef}
                                        type="submit"
                                        disabled={isLoading}
                                        className={`${styles.min150} ${styles.border0} ${styles['flex-center']} ${styles['text-md']} ${styles['font-semibold']} ${styles['cursor-pointer']} ${styles.relative} ${styles.overflow} ${styles.transition} ${styles.z1} ${styles['gradient-background']}`}
                                        style={{
                                            minWidth: '150px',
                                            background: `linear-gradient(135deg, ${PRIMARY_COLORS[isDark ? 'dark' : 'light'][0]}, ${SECONDARY_COLORS[isDark ? 'dark' : 'light'][0]})`,
                                            color: getTacsColor('whiteKhaki', 1),
                                            border: 'none',
                                            padding: '1rem 1.5rem',
                                            fontWeight: 600,
                                            cursor: isLoading ? 'wait' : 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: 'all 0.3s ease'
                                        }}
                                    >
                                        {isLoading ? (
                                            <div
                                                className={`${styles['w-6']} ${styles['h-6']} ${styles.border2} ${styles['border-white-30']} ${styles['rounded-full']} ${styles['border-t-white']} ${styles['animate-spin']}`}
                                                style={{
                                                    width: '1.5rem',
                                                    height: '1.5rem',
                                                    border: `2px solid ${withAlpha(getTacsColor('whiteKhaki', 1), 0.3)}`,
                                                    borderTop: `2px solid ${getTacsColor('whiteKhaki', 1)}`,
                                                    borderRadius: '50%',
                                                    animation: 'spin 1s linear infinite'
                                                }}
                                            />
                                        ) : (
                                            <div
                                                className={`${styles['flex-row']} ${styles['items-center']} ${styles['gap-2']}`}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5rem'
                                                }}
                                            >
                                <span className="text-3d-reflection" style={{
                                    ...get3DTextEffect('elegant', {
                                        fontSize: '1rem',
                                        fontWeight: 600,
                                        textShadow: '0 1px 2px rgba(0,0,0,0.2)'
                                    })
                                }}>Get Access</span> <ArrowRight size={18}/>
                                            </div>
                                        )}
                                    </button>
                                </div>

                                {/* Error Message */}
                                {error && (
                                    <div
                                        id="email-error"
                                        className={`${styles['flex-row']} ${styles['items-center']} ${styles['gap-2']} ${styles['mt-neg-6']} ${styles.px2} ${styles['animate-fade-in']}`}
                                        style={{
                                            color: getTacsColor('red', 8),
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            marginTop: '-0.5rem',
                                            padding: '0 0.5rem',
                                            fontSize: '0.875rem'
                                        }}
                                        role="alert"
                                    >
                                        <AlertCircle size={16}/>
                                        <span className="text-3d-reflection" style={{
                                            ...get3DTextEffect('elegant', {
                                                fontSize: '0.875rem',
                                                textShadow: isDark ? '0 1px 1px rgba(0,0,0,0.1)' : '0 1px 1px rgba(0,0,0,0.05)'
                                            })
                                        }}>{error}</span>
                                    </div>
                                )}
                            </form>
                        )}
                    </div>

                    <p
        className={`${styles['text-center']} ${styles['max-w-lg']} ${styles.px4} ${styles.lead} text-3d-reflection`}
                        style={{
                            ...get3DTextEffect('elegant', {
                                fontSize: '1.1rem',
                                lineHeight: 1.6,
                                letterSpacing: '0.01em',
                                fontWeight: 400,
                                bottomMargin: 100,
                                textShadow: isDark ?
                                    '0 1px 1px rgba(0,0,0,0.2), 0 1px 2px rgba(0,0,0,0.1)' :
                                    '0 1px 1px rgba(0,0,0,0.15), 0 1px 1px rgba(0,0,0,0.05)'
                            }),
                            color: isDark ?
                                withAlpha(getTacsColor('whiteKhaki', 1), 0.8) :
                withAlpha(getTacsColor('blackGray', 8), 0.8),
            marginTop: '3rem' // Added top margin
                        }}
                    >
                        It&apos;s time to create your own door to the world.
                    </p>
                </ThemedContainer>

                {/* Footer */}
                <footer
                    ref={footerRef}
                    className={`${styles['w-full']} ${styles.py6} ${styles.relative} ${styles.overflow}`}
                    style={{
                        background: isDark
                            ? `linear-gradient(180deg, ${withAlpha(getTacsColor('blackGray', 9), 0)} 0%, ${withAlpha(getTacsColor('blackGray', 9), 0.8)} 100%)`
                            : `linear-gradient(180deg, ${withAlpha(getTacsColor('whiteKhaki', 2), 0)} 0%, ${withAlpha(getTacsColor('whiteKhaki', 2), 0.8)} 100%)`,
                        borderTop: isDark
                            ? `1px solid ${withAlpha(getTacsColor('whiteKhaki', 1), 0.05)}`
                            : `1px solid ${withAlpha(getTacsColor('blackGray', 10), 0.05)}`,
                        padding: '1.5rem'
                    }}
                >
                    <div className={`${styles['max-w-2xl']} ${styles['mx-auto']} ${styles.px4}`}>
                        {/* Top row with logo, tagline, and social icons */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '16px'
                        }}>

                        </div>

                        {/* Copyright */}
                        <p
                            className="text-3d-reflection"
                            style={{
                                ...get3DTextEffect('elegant', {
                                    fontSize: '12px',
                                    letterSpacing: '0.01em',
                                    textShadow: isDark ? '0 1px 1px rgba(0,0,0,0.1)' : '0 1px 1px rgba(0,0,0,0.05)'
                                }),
                                color: isDark ?
                                    withAlpha(getTacsColor('whiteKhaki', 1), 0.4) :
                                    withAlpha(getTacsColor('blackGray', 8), 0.4),
                                margin: '12px 0 0 0',
                                textAlign: 'center'
                            }}
                        >
                            © {new Date().getFullYear()} TAYTROM | All rights reserved
                        </p>
                    </div>
                </footer>

            </ThemedContainer>
        </>
    );
};

export default TaytromLanding;