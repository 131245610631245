import React, { useContext, useState, useEffect, useRef } from 'react';
import { ThemeContext } from "@/FullDevApp.jsx";
import styles from '@/TACS2.module.css';
import { useThemeColors } from "@/templates-scope/colors/accepted/Accepted_Colors_GeneralWebpages_Templates.jsx";

const ValuePropositionDisplay = ({
                                     valueProposition,
                                     isVisible,
                                     initialDelayPassed
                                 }) => {
    const { isDark } = useContext(ThemeContext);
    const { getTacsColor, withAlpha, getTacsPremiumColor } = useThemeColors();

    // States for animation effects
    const [scrambledText, setScrambledText] = useState('');
    const [isDecoding, setIsDecoding] = useState(false);
    const [decodingPhase, setDecodingPhase] = useState(0); // 0: not started, 1: binary, 2: hex, 3: alpha, 4: complete
    const [showCursor, setShowCursor] = useState(true);
    const [commandHistory, setCommandHistory] = useState([]);
    const [particlesActive, setParticlesActive] = useState(false);
    const [decodingProgress, setDecodingProgress] = useState(0);
    const [isGlitching, setIsGlitching] = useState(false);
    const [displayMode, setDisplayMode] = useState('quantum'); // 'terminal', 'holographic', 'quantum'
    const [memoryUsage, setMemoryUsage] = useState(16);
    const [cpuUsage, setCpuUsage] = useState(24);
    const [uptime, setUptime] = useState('724d 12h 37m');
    // Index tracker for the current statement
    const [valuePropositionIndex, setValuePropositionIndex] = useState(0);
    // Track if we've completed the cycle
    const [cycleCompleted, setCycleCompleted] = useState(false);
    // New state for color pulse effect
    const [colorPulse, setColorPulse] = useState(0);
    // New state for accent color cycling
    const [accentColorIndex, setAccentColorIndex] = useState(0);

    // Enhanced accent colors that cycle for more vibrancy - MODIFIED: Using a single vibrant color and white
    const accentColors = [
        getTacsColor('teal', 6),
    ];
// Individual timing configuration for each statement (in milliseconds)
    const statementTimings = [
        { statement: "What if an entire generation could now build their own AI and internet platform, giving them:", duration: 8000 },
        { statement: "Direct access to the world.", duration: 5000 },
        { statement: "Creative independence.", duration: 5000 },
        { statement: "Financial freedom.", duration: 5000 },
        { statement: "Total occupational control.", duration: 5000 },
        { statement: "What if that was all one subscription away?", duration: 7000 },
        { statement: "Taytrom, giving others the blueprint to create...whatever they want.", duration: 0 } // 0 means stay on this indefinitely
    ];

    // DOM refs
    const terminalRef = useRef(null);
    const canvasRef = useRef(null);
    const containerRef = useRef(null);

    // Characters for scrambling effect by phase
    const scrambleChars = {
        binary: "01",
        hex: "0123456789ABCDEF",
        alpha: "!@#$%^&*()_+-=[]{}|;:,./<>?`~ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    };

    // MODIFIED: Simplified color scheme with emphasis on value proposition
    const getThemeColors = () => {
        // Get the current accent color in the rotation
        const currentAccent = accentColors[accentColorIndex];

        const baseColors = {
            terminal: {
                background: isDark ? '#000000' : '#ffffff',
                text: isDark ? '#a0a0a0' : '#505050',
                prompt: isDark ? '#a0a0a0' : '#505050',
                commands: isDark ? '#a0a0a0' : '#505050',
                variables: isDark ? '#a0a0a0' : '#505050',
                strings: isDark ? '#a0a0a0' : '#505050',
                functions: isDark ? '#a0a0a0' : '#505050',
                keywords: isDark ? '#a0a0a0' : '#505050',
                caretColor: isDark ? '#ffffff' : '#000000',
                headerButtons: ['#555', '#555', '#555'],
                headerBackground: isDark ? '#111' : '#f0f0f0',
                accent: currentAccent,
                glow: withAlpha(currentAccent, 0.2),
                success: '#505050',
                valueProposition: '#20b2aa', // Teal for value proposition
            },
            holographic: {
                background: withAlpha(isDark ? '#000000' : '#ffffff', 0.85),
                text: isDark ? '#a0a0a0' : '#505050',
                prompt: isDark ? '#a0a0a0' : '#505050',
                commands: isDark ? '#a0a0a0' : '#505050',
                variables: isDark ? '#a0a0a0' : '#505050',
                strings: isDark ? '#a0a0a0' : '#505050',
                functions: isDark ? '#a0a0a0' : '#505050',
                keywords: isDark ? '#a0a0a0' : '#505050',
                caretColor: isDark ? '#ffffff' : '#000000',
                headerButtons: ['#555', '#555', '#555'],
                headerBackground: withAlpha(isDark ? '#111' : '#f0f0f0', 0.8),
                accent: currentAccent,
                glow: withAlpha(currentAccent, 0.3),
                success: '#505050',
                valueProposition: '#20b2aa', // Teal for value proposition
            },
            quantum: {
                background: withAlpha(isDark ? '#000000' : '#ffffff', 0.95),
                text: isDark ? '#a0a0a0' : '#505050',
                prompt: isDark ? '#a0a0a0' : '#505050',
                commands: isDark ? '#a0a0a0' : '#505050',
                variables: isDark ? '#a0a0a0' : '#505050',
                strings: isDark ? '#a0a0a0' : '#505050',
                functions: isDark ? '#a0a0a0' : '#505050',
                keywords: isDark ? '#a0a0a0' : '#505050',
                caretColor: isDark ? '#ffffff' : '#000000',
                headerButtons: ['#555', '#555', '#555'],
                headerBackground: withAlpha(isDark ? '#111' : '#f0f0f0', 0.9),
                accent: currentAccent,
                glow: withAlpha(currentAccent, 0.2 + colorPulse*0.1),
                success: '#505050',
                valueProposition: '#20b2aa', // Teal for value proposition
            }
        };

        return baseColors[displayMode];
    };

    const colors = getThemeColors();

    // Initialize effects on component mount
    useEffect(() => {
        if (canvasRef.current) {
            initCanvas();
        }

        // Simulate changing system stats
        const statsInterval = setInterval(() => {
            setMemoryUsage(Math.floor(Math.random() * 30) + 10); // 10-40%
            setCpuUsage(Math.floor(Math.random() * 40) + 15); // 15-55%
        }, 3000);

        // Add color cycling for more dynamic visuals
        const colorCycleInterval = setInterval(() => {
            setAccentColorIndex((prev) => (prev + 1) % accentColors.length);
        }, 10000);

        // Add subtle color pulsing effect for quantum mode
        const colorPulseInterval = setInterval(() => {
            setColorPulse(prev => {
                const newValue = prev + 0.1;
                return newValue >= 1 ? 0 : newValue;
            });
        }, 100);

        return () => {
            clearInterval(statsInterval);
            clearInterval(colorCycleInterval);
            clearInterval(colorPulseInterval);
        };
    }, []);

    // Update effects when theme changes
    useEffect(() => {
        const colors = getThemeColors();

        // Apply theme-specific CSS variable updates
        if (containerRef.current) {
            containerRef.current.style.setProperty('--accent-color', colors.accent);
            containerRef.current.style.setProperty('--glow-color', colors.glow);
            containerRef.current.style.setProperty('--text-color', colors.text);
            containerRef.current.style.setProperty('--bg-color', colors.background);
            containerRef.current.style.setProperty('--value-proposition-color', colors.valueProposition);
        }
    }, [isDark, displayMode, accentColorIndex, colorPulse]);

    // Value Proposition rotation with individual timing
    useEffect(() => {
        if (!isVisible || cycleCompleted) return;

        // Get current statement's duration
        const currentStatement = statementTimings[valuePropositionIndex]?.statement || "";
        const currentDuration = statementTimings[valuePropositionIndex]?.duration || 4000;

        // If we're at the last statement, don't set a timeout (stay on this indefinitely)
        if (valuePropositionIndex === statementTimings.length - 1) {
            setCycleCompleted(true);
            return;
        }

        // Set up the timer for the current statement
        const timer = setTimeout(() => {
            // Move to next statement
            setValuePropositionIndex(prevIndex => {
                const nextIndex = prevIndex + 1;
                // If this is the last statement, mark cycle as completed
                if (nextIndex === statementTimings.length - 1) {
                    setCycleCompleted(true);
                }
                return nextIndex;
            });
        }, currentDuration);

        return () => clearTimeout(timer);
    }, [isVisible, valuePropositionIndex, cycleCompleted]);

    // Enhanced particle animation system for quantum effects
    const initCanvas = () => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let particles = [];
        const particleCount = 150; // Increased particle count for more visual density

        // Resize canvas to fit container
        const resizeCanvas = () => {
            if (containerRef.current) {
                canvas.width = containerRef.current.offsetWidth;
                canvas.height = containerRef.current.offsetHeight;
            }
        };

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        // MODIFIED: Create particles with more subdued colors unless near value proposition
        for (let i = 0; i < particleCount; i++) {
            // Use more subdued colors for particles
            const particleColor = isDark ? '#333333' : '#e0e0e0';

            particles.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: Math.random() * 2 + 0.5,
                color: particleColor,
                vx: Math.random() * 0.6 - 0.3,
                vy: Math.random() * 0.6 - 0.3,
                alpha: Math.random() * 0.6 + 0.2,
                pulse: Math.random() * 0.1,
                pulseSpeed: 0.005 + Math.random() * 0.01,
            });
        }

        // Animation loop
        let animationFrame;
        const animate = () => {
            if (!particlesActive) {
                animationFrame = requestAnimationFrame(animate);
                return;
            }

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw and update particles with enhanced effects
            particles.forEach(particle => {
                // Apply pulsing effect to particles
                particle.pulse += particle.pulseSpeed;
                if (particle.pulse > 1) particle.pulse = 0;

                // Sine wave for smooth pulsing
                const pulseAlpha = particle.alpha * (0.7 + 0.3 * Math.sin(particle.pulse * Math.PI * 2));

                ctx.globalAlpha = pulseAlpha;
                ctx.fillStyle = particle.color;
                ctx.beginPath();
                ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
                ctx.fill();

                // Update position
                particle.x += particle.vx;
                particle.y += particle.vy;

                // Wrap around canvas edges
                if (particle.x < 0) particle.x = canvas.width;
                if (particle.x > canvas.width) particle.x = 0;
                if (particle.y < 0) particle.y = canvas.height;
                if (particle.y > canvas.height) particle.y = 0;
            });

            // Draw connections between nearby particles with subdued colors
            particles.forEach((p1, i) => {
                for (let j = i + 1; j < particles.length; j++) {
                    const p2 = particles[j];
                    const dx = p1.x - p2.x;
                    const dy = p1.y - p2.y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < 100) {
                        ctx.beginPath();
                        const connectionColor = isDark ? '#222222' : '#f0f0f0';
                        ctx.strokeStyle = withAlpha(connectionColor, (1 - distance / 100) * 0.3);
                        ctx.globalAlpha = (1 - distance / 100) * 0.4;
                        ctx.lineWidth = 0.6;
                        ctx.moveTo(p1.x, p1.y);
                        ctx.lineTo(p2.x, p2.y);
                        ctx.stroke();
                    }
                }
            });

            animationFrame = requestAnimationFrame(animate);
        };

        animate();

        // Cleanup
        return () => {
            window.removeEventListener('resize', resizeCanvas);
            cancelAnimationFrame(animationFrame);
        };
    };

    // Text scrambling effect with multiple phases
    useEffect(() => {
        if (!isVisible || !initialDelayPassed) return;

        // Get the current value proposition from the timing configuration
        const currentProposition = statementTimings[valuePropositionIndex]?.statement || "";
        if (!currentProposition) return;

        setIsDecoding(true);
        setDecodingPhase(1); // Start with binary phase
        setParticlesActive(true);

        // Add initial command to history
        setCommandHistory([{
            type: 'command',
            text: 'initiate_signal_decryption.sh --algorithm=quantum --target="encrypted_transmission"'
        }]);

        // Phase 1: Binary scrambling
        let phase1Iterations = 0;
        const phase1MaxIterations = 8;

        const phase1Interval = setInterval(() => {
            phase1Iterations++;

            // Create binary scrambled text
            const binaryText = currentProposition.split('').map(() => {
                return scrambleChars.binary.charAt(Math.floor(Math.random() * scrambleChars.binary.length));
            }).join('');

            setScrambledText(binaryText);
            setDecodingProgress(Math.round((phase1Iterations / phase1MaxIterations) * 25));

            if (phase1Iterations >= phase1MaxIterations) {
                clearInterval(phase1Interval);

                // Add phase completion to history
                setCommandHistory(prev => [
                    ...prev,
                    { type: 'output', text: 'Binary decryption layer complete.' }
                ]);

                // Move to Phase 2: Hexadecimal scrambling
                setDecodingPhase(2);
                let phase2Iterations = 0;
                const phase2MaxIterations = 10;

                const phase2Interval = setInterval(() => {
                    phase2Iterations++;

                    // Create hex scrambled text
                    const hexText = currentProposition.split('').map(() => {
                        return scrambleChars.hex.charAt(Math.floor(Math.random() * scrambleChars.hex.length));
                    }).join('');

                    setScrambledText(hexText);
                    setDecodingProgress(25 + Math.round((phase2Iterations / phase2MaxIterations) * 25));

                    if (phase2Iterations >= phase2MaxIterations) {
                        clearInterval(phase2Interval);

                        // Add phase completion to history
                        setCommandHistory(prev => [
                            ...prev,
                            { type: 'output', text: 'Hexadecimal decryption layer complete.' }
                        ]);

                        // Move to Phase 3: Alpha scrambling with progressive reveal
                        setDecodingPhase(3);
                        let phase3Iterations = 0;
                        const phase3MaxIterations = 15;

                        const phase3Interval = setInterval(() => {
                            phase3Iterations++;

                            // Create a mix of scrambled and correct characters, gradually increasing correct ones
                            const alphaText = currentProposition.split('').map((char, index) => {
                                // Chance of showing the correct character increases with iterations
                                if (Math.random() < phase3Iterations / phase3MaxIterations) {
                                    return char;
                                }
                                // Otherwise show a random character
                                return scrambleChars.alpha.charAt(Math.floor(Math.random() * scrambleChars.alpha.length));
                            }).join('');

                            setScrambledText(alphaText);
                            setDecodingProgress(50 + Math.round((phase3Iterations / phase3MaxIterations) * 50));

                            // Random glitch effect
                            if (Math.random() < 0.3) {
                                setIsGlitching(true);
                                setTimeout(() => setIsGlitching(false), 150);
                            }

                            if (phase3Iterations >= phase3MaxIterations) {
                                clearInterval(phase3Interval);

                                // Final decoding complete
                                setDecodingPhase(4);
                                setScrambledText(currentProposition);
                                setIsDecoding(false);
                                setDecodingProgress(100);

                                // Add to command history
                                setCommandHistory(prev => [
                                    ...prev,
                                    { type: 'output', text: 'Decryption complete.' },
                                    { type: 'result', text: currentProposition }
                                ]);

                                // Scroll to bottom
                                if (terminalRef.current) {
                                    terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
                                }

                                // Random glitch effects after completion
                                const glitchInterval = setInterval(() => {
                                    if (Math.random() < 0.2) {
                                        setIsGlitching(true);
                                        setTimeout(() => setIsGlitching(false), 150);
                                    }
                                }, 3000);

                                return () => clearInterval(glitchInterval);
                            }
                        }, 70);

                        return () => clearInterval(phase3Interval);
                    }
                }, 80);

                return () => clearInterval(phase2Interval);
            }
        }, 100);

        // Blinking cursor
        const cursorInterval = setInterval(() => {
            setShowCursor(prev => !prev);
        }, 530);

        return () => {
            clearInterval(phase1Interval);
            clearInterval(cursorInterval);
        };
    }, [valuePropositionIndex, isVisible, initialDelayPassed]);

    // Scroll to bottom when history updates
    useEffect(() => {
        if (terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        }
    }, [commandHistory]);

    // Don't render anything if conditions aren't met
    if (!initialDelayPassed) return null;

    // Get current value proposition for display
    const currentStatement = statementTimings[valuePropositionIndex]?.statement || "";

    return (
        <div
            ref={containerRef}
            className="value-proposition-container"
            style={{
                position: 'relative',
                width: '100%',
                maxWidth: '800px',
                margin: '100px auto 12px',
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
                transition: 'all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)',
                zIndex: 10,
                perspective: '1500px',
            }}
        >
            {/* Background canvas for particles */}
            <canvas
                ref={canvasRef}
                className="particle-canvas"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -1,
                    opacity: displayMode === 'quantum' ? 0.8 : 0,
                    transition: 'opacity 0.5s ease',
                }}
            />

            {/* Mode selector buttons */}
            <div className="display-mode-buttons"
                 style={{
                     position: 'absolute',
                     top: '-30px',
                     right: '10px',
                     display: 'flex',
                     gap: '10px',
                     zIndex: 20,
                 }}
            >
                {['terminal', 'holographic', 'quantum'].map(mode => (
                    <button
                        key={mode}
                        onClick={() => setDisplayMode(mode)}
                        className={`mode-button ${displayMode === mode ? 'active' : ''}`}
                        style={{
                            background: displayMode === mode
                                ? isDark ? '#333' : '#e0e0e0'
                                : 'transparent',
                            color: isDark ? '#a0a0a0' : '#505050',
                            border: `1px solid ${isDark ? '#555' : '#ccc'}`,
                            padding: '2px 8px',
                            fontSize: '10px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            letterSpacing: '0.5px',
                            boxShadow: 'none',
                            transition: 'all 0.3s ease',
                        }}
                    >
                        {mode}
                    </button>
                ))}
            </div>

            {/* Terminal Window with advanced styling */}
            <div
                className={`terminal-window ${displayMode}-mode ${isGlitching ? 'glitching' : ''}`}
                style={{
                    background: colors.background,
                    border: `1px solid ${isDark ? '#333' : '#ddd'}`,
                    boxShadow: `0 10px 30px ${withAlpha(getTacsColor('blackGray', 10), isDark ? 0.35 : 0.2)}`,
                    borderRadius: '8px',
                    overflow: 'hidden',
                    fontFamily: "'JetBrains Mono', 'Fira Code', 'Roboto Mono', monospace",
                    maxWidth: '100%',
                    transform: 'perspective(1500px) rotateX(0deg)',
                    transition: 'transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1), box-shadow 0.5s ease',
                    position: 'relative',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'perspective(1500px) rotateX(2deg) translateY(-5px)';
                    e.currentTarget.style.boxShadow = `
                        0 15px 40px ${withAlpha(getTacsColor('blackGray', 10), isDark ? 0.4 : 0.25)}`;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'perspective(1500px) rotateX(0deg) translateY(0)';
                    e.currentTarget.style.boxShadow = `
                        0 10px 30px ${withAlpha(getTacsColor('blackGray', 10), isDark ? 0.35 : 0.2)}`;
                }}
            >
                {/* Enhanced glass reflection effect */}
                <div className="glass-reflection"
                     style={{
                         position: 'absolute',
                         top: 0,
                         left: 0,
                         right: 0,
                         height: '40%',
                         background: `linear-gradient(to bottom, 
                                       ${withAlpha('#fff', 0.05)}, 
                                       ${withAlpha('#fff', 0)})`,
                         borderTopLeftRadius: '8px',
                         borderTopRightRadius: '8px',
                         pointerEvents: 'none',
                         zIndex: 10,
                         opacity: 0.3,
                     }}
                />

                {/* Terminal Header with advanced styling */}
                <div
                    className="terminal-header"
                    style={{
                        background: colors.headerBackground,
                        borderBottom: `1px solid ${isDark ? '#333' : '#ddd'}`,
                        padding: '8px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        boxShadow: `0 2px 8px ${withAlpha('#000', 0.15)}`,
                    }}
                >
                    {/* Window buttons with enhanced hover effect */}
                    <div className="window-buttons"
                         style={{
                             display: 'flex',
                             gap: '6px',
                         }}
                    >
                        {colors.headerButtons.map((color, index) => (
                            <div
                                key={index}
                                className="window-button"
                                style={{
                                    width: '12px',
                                    height: '12px',
                                    borderRadius: '50%',
                                    background: color,
                                    position: 'relative',
                                    transition: 'all 0.2s ease',
                                }}
                            />
                        ))}
                    </div>

                    {/* Window title with enhanced mode-specific styling */}
                    <div
                        className="terminal-title"
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            color: colors.text,
                            fontWeight: 500,
                            fontSize: '12px',
                            letterSpacing: '0.5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '6px',
                        }}
                    >
                        <span>taytrom-{displayMode} ~ /quantum-decoder</span>

                        {/* Status indicator with enhanced glow */}
                        <span className={`status-indicator ${isDecoding ? 'active' : 'complete'}`}
                              style={{
                                  width: '8px',
                                  height: '8px',
                                  borderRadius: '50%',
                                  background: isDecoding
                                      ? '#888'
                                      : '#888',
                              }}
                        />
                    </div>

                    {/* Connection indicator with enhanced styling */}
                    <div
                        className="connection-indicator"
                        style={{
                            color: colors.text,
                            fontSize: '12px',
                            fontWeight: 500,
                            background: isDark ? '#222' : '#f0f0f0',
                            padding: '3px 8px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            border: `1px solid ${isDark ? '#333' : '#ddd'}`,
                        }}
                    >
                        <span>●</span> SECURE
                    </div>
                </div>

                {/* System Info Bar with enhanced styling */}
                <div
                    className="system-info-bar"
                    style={{
                        background: colors.headerBackground,
                        borderBottom: `1px solid ${isDark ? '#333' : '#ddd'}`,
                        color: colors.text,
                        fontSize: '12px',
                        padding: '4px 12px',
                        display: 'flex',
                        gap: '16px',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <span><span style={{ color: colors.text }}>os</span>=TaytromOS</span>
                    <span><span style={{ color: colors.text }}>version</span>=2.5.72</span>
                    <span><span style={{ color: colors.text }}>kernel</span>=Quantum-Core</span>
                    <span><span style={{ color: colors.text }}>uptime</span>={uptime}</span>
                </div>

                {/* Enhanced progress bar with glowing effect */}
                <div className="progress-container"
                     style={{
                         height: '3px',
                         background: isDark ? '#222' : '#f0f0f0',
                         position: 'relative',
                         overflow: 'hidden',
                     }}
                >
                    <div className="progress-bar"
                         style={{
                             height: '100%',
                             width: `${decodingProgress}%`,
                             background: isDark ? '#444' : '#ccc',
                             transition: 'width 0.3s ease',
                         }}
                    />
                </div>

                {/* Terminal Content with enhanced styling */}
                <div
                    ref={terminalRef}
                    className={`terminal-content ${displayMode}-content`}
                    style={{
                        color: colors.text,
                        maxHeight: '300px',
                        minHeight: '180px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        lineHeight: 1.6,
                        padding: '16px',
                        paddingBottom: '16px',
                        fontSize: '13px',
                        position: 'relative',
                    }}
                >
                    {/* Initial welcome message with enhanced styling */}
                    <div className="welcome-message"
                         style={{
                             color: colors.text,
                             marginBottom: '12px',
                         }}
                    >
                        <span style={{ color: colors.text }}>TaytromOS</span> [Version 2.5.72]<br/>
                        <span style={{ color: colors.text }}>
                          {displayMode === 'quantum' ? 'Quantum' : displayMode === 'holographic' ? 'Holographic' : 'Advanced'} Decryption Module
                        </span> initialized. Standing by...
                    </div>

                    {/* Command history with enhanced styling and animations */}
                    {commandHistory.map((entry, idx) => (
                        <div key={idx}
                             className={`history-entry ${entry.type}-entry`}
                             style={{
                                 marginBottom: '8px',
                             }}
                        >
                            {entry.type === 'command' && (
                                <div className="command-line">
                                    <span style={{ color: colors.text }}>taytrom@{displayMode}:~$</span>{' '}
                                    <span style={{ color: colors.text }}>{entry.text}</span>
                                </div>
                            )}

                            {entry.type === 'output' && (
                                <div className="output-line"
                                     style={{
                                         color: colors.text,
                                         paddingLeft: '8px',
                                         borderLeft: `2px solid ${isDark ? '#333' : '#ddd'}`,
                                         marginLeft: '4px',
                                     }}
                                >
                                    {entry.text}
                                </div>
                            )}

                            {entry.type === 'result' && (
                                <div className="result-container"
                                     style={{
                                         background: isDark ? '#111' : '#f8f8f8',
                                         borderLeft: `3px solid ${colors.valueProposition}`,
                                         color: colors.valueProposition,
                                         fontWeight: 700,
                                         fontSize: '18px',
                                         borderRadius: '0 4px 4px 0',
                                         padding: '12px 16px',
                                         marginTop: '8px',
                                         marginBottom: '12px',
                                         position: 'relative',
                                         boxShadow: `0 5px 15px ${withAlpha('#000', 0.1)}`,
                                         textShadow: `0 0 10px ${withAlpha(colors.valueProposition, 0.3)}`,
                                     }}
                                >
                                    {entry.text}

                                    {/* Add subtle pulse animation */}
                                    <div className="result-pulse"
                                         style={{
                                             position: 'absolute',
                                             top: '-2px',
                                             left: '-2px',
                                             right: '-2px',
                                             bottom: '-2px',
                                             borderRadius: '0 4px 4px 0',
                                             border: `1px solid ${withAlpha(colors.valueProposition, 0.3)}`,
                                             pointerEvents: 'none',
                                             animation: 'resultPulse 3s infinite',
                                             opacity: 0,
                                         }}
                                    />
                                </div>
                            )}
                        </div>
                    ))}

                    {/* Current command line with enhanced animation effects */}
                    <div className="current-command-line">
                        <span style={{ color: colors.text }}>taytrom@{displayMode}:~$</span>{' '}
                        {isDecoding ? (
                            <>
                                <span style={{ color: colors.text }}>
                                  {decodingPhase === 1 ? 'decoding_binary' :
                                      decodingPhase === 2 ? 'decoding_hexadecimal' :
                                          decodingPhase === 3 ? 'decoding_alphanumeric' : 'decoding_complete'}
                                </span>
                                <span style={{ color: colors.text }}>
                                  [<span className="decoding-progress" style={{ animation: 'progress 2s infinite linear' }}>⣾⣽⣻⢿⡿⣟⣯⣷</span>]
                                </span>
                                {' '}
                                <span
                                    className={`scrambled-text phase-${decodingPhase} ${isGlitching ? 'glitching' : ''}`}
                                    style={{
                                        color: decodingPhase === 4 ? colors.valueProposition : colors.text,
                                        fontWeight: decodingPhase === 4 ? 700 : 400,
                                        fontSize: decodingPhase === 4 ? '18px' : '13px',
                                        position: 'relative',
                                        textShadow: decodingPhase === 4 ? `0 0 10px ${withAlpha(colors.valueProposition, 0.3)}` : 'none',
                                    }}
                                >
                                  {scrambledText}
                                </span>
                            </>
                        ) : (
                            <>
                                <span
                                    className="terminal-cursor"
                                    style={{
                                        opacity: showCursor ? 1 : 0,
                                        color: colors.caretColor,
                                        transition: 'opacity 0.1s',
                                        marginLeft: '4px',
                                    }}
                                >▎</span>
                            </>
                        )}
                    </div>
                </div>

                {/* Terminal Status Bar with enhanced styling */}
                <div
                    className="terminal-status-bar"
                    style={{
                        background: colors.headerBackground,
                        borderTop: `1px solid ${isDark ? '#333' : '#ddd'}`,
                        color: colors.text,
                        fontSize: '12px',
                        padding: '5px 14px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', gap: '16px' }}>
                        <span>✓ AUTHENTICATED</span>
                        <span>CIPHER: QUANTUM-256</span>
                    </div>
                    <div style={{ display: 'flex', gap: '16px' }}>
                        <span>MEMORY: {memoryUsage}%</span>
                        <span>CPU: {cpuUsage}%</span>
                        <span>NEURAL: ACTIVE</span>
                    </div>
                </div>
            </div>

            {/* Status details below terminal with enhanced styling */}
            <div className="status-details"
                 style={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     fontSize: '11px',
                     color: colors.text,
                     marginTop: '8px',
                     fontFamily: "'JetBrains Mono', monospace",
                     paddingLeft: '8px',
                     paddingRight: '8px',
                 }}
            >
                <div>{displayMode.toUpperCase()} MODE</div>
                <div>
                    {isDecoding
                        ? `DECRYPTION IN PROGRESS: ${decodingProgress}%`
                        : decodingPhase === 4
                            ? 'DECRYPTION COMPLETE'
                            : 'STANDBY'
                    }
                </div>
                <div>SECURE CHANNEL</div>
            </div>

            {/* Add animations and complex effects via CSS */}
            <style jsx>{`
                /* Enhanced animations */
                @keyframes progress {
                    0% { content: "⣾"; }
                    12.5% { content: "⣽"; }
                    25% { content: "⣻"; }
                    37.5% { content: "⢿"; }
                    50% { content: "⡿"; }
                    62.5% { content: "⣟"; }
                    75% { content: "⣯"; }
                    87.5% { content: "⣷"; }
                    100% { content: "⣾"; }
                }

                @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }

                @keyframes slideInUp {
                    from {
                        opacity: 0;
                        transform: translateY(15px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                @keyframes pulse {
                    0% { transform: scale(1); opacity: 1; }
                    50% { transform: scale(1.1); opacity: 0.8; }
                    100% { transform: scale(1); opacity: 1; }
                }
                
                @keyframes resultPulse {
                    0% { opacity: 0; box-shadow: 0 0 0 rgba(32, 178, 170, 0); }
                    50% { opacity: 1; box-shadow: 0 0 15px rgba(32, 178, 170, 0.5); }
                    100% { opacity: 0; box-shadow: 0 0 0 rgba(32, 178, 170, 0); }
                }

                /* Enhanced glitch effect */
                .glitching {
                    animation: glitch 0.3s cubic-bezier(.25, .46, .45, .94) both infinite;
                }

                @keyframes glitch {
                    0% {
                        transform: translate(0);
                    }
                    25% {
                        transform: translate(-4px, 0);
                    }
                    35% {
                        transform: translate(4px, 0);
                    }
                    50% {
                        transform: translate(-2px, -2px);
                    }
                    65% {
                        transform: translate(2px, 2px);
                    }
                    75% {
                        transform: translate(-1px, -1px);
                    }
                    100% {
                        transform: translate(0);
                    }
                }

                /* Enhanced terminal cursor animation */
                .terminal-cursor {
                    animation: blink 1.06s step-end infinite;
                }

                @keyframes blink {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0; }
                }

                /* Enhanced scrollbar styling */
                .terminal-content::-webkit-scrollbar {
                    width: 6px;
                }

                .terminal-content::-webkit-scrollbar-track {
                    background: ${colors.background};
                }

                .terminal-content::-webkit-scrollbar-thumb {
                    background: ${isDark ? '#333' : '#ccc'};
                    border-radius: 10px;
                }

                .terminal-content::-webkit-scrollbar-thumb:hover {
                    background: ${isDark ? '#444' : '#bbb'};
                }
            `}</style>
        </div>
    );
};

export default ValuePropositionDisplay;