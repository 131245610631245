// FromTitle_Generation_Features_Specialists_Writing.jsx

import React, { useState, useEffect, useRef } from 'react';
import { FileText, Wand2, AlertTriangle, Loader, Check, X, ChevronRight } from 'lucide-react';
import styles from './FromTitle_Generation_Features_Specialists_Writing.module.css';

/**
 * A component that provides title-based document generation functionality
 * Enhanced to align with Taytrom's Styling Mandate
 */
const GenerateFromTitle = ({
                               documentTitle,
                               orchestrator,
                               setDocumentContent,
                               showSuccessMessage,
                               isDark = false
                           }) => {
    // States for the generation process
    const [isGenerating, setIsGenerating] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [generationType, setGenerationType] = useState('standard');
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [showSuccess, setShowSuccess] = useState(false);

    // Refs for animations and transitions
    const containerRef = useRef(null);
    const progressBarRef = useRef(null);

    // Effect to handle progress animation
    useEffect(() => {
        if (progress >= 100 && isGenerating) {
            // Show success state briefly before completing
            setShowSuccess(true);
            const timer = setTimeout(() => {
                setIsGenerating(false);
                setProgress(0);
                setShowOptions(false);
                setShowSuccess(false);
            }, 1500);

            return () => clearTimeout(timer);
        }
    }, [progress, isGenerating]);

    // Available generation types
    const generationTypes = {
        standard: {
            label: 'Standard Outline',
            description: 'A balanced structure with introduction, key points, and conclusion',
            sections: 4,
            icon: <FileText size={16} />
        },
        detailed: {
            label: 'Detailed Structure',
            description: 'A comprehensive document with multiple steps and subsections',
            sections: 8,
            icon: <FileText size={16} />
        },
        creative: {
            label: 'Creative Exploration',
            description: 'A creative approach with unique perspectives on the topic',
            sections: 5,
            icon: <Wand2 size={16} />
        },
        academic: {
            label: 'Academic Format',
            description: 'Structured as a formal academic document with thesis and evidence',
            sections: 6,
            icon: <FileText size={16} />
        }
    };

    // Handle generation
    const handleGenerate = async () => {
        if (!documentTitle || !orchestrator) {
            setError("Cannot generate: missing title or AI service");
            return;
        }

        setIsGenerating(true);
        setError(null);
        setProgress(5);

        try {
            // Create a conversation ID for this generation
            const conversationId = `gen_title_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;

            // Prepare prompt based on generation type
            const promptTemplates = {
                standard: `Create a standard document structure based on this title: "${documentTitle}". 
                  Include an introduction, 2-3 main sections, and a conclusion. 
                  For each section, provide a title and 1-2 paragraphs of content.`,
                detailed: `Create a detailed document structure based on this title: "${documentTitle}". 
                  Include an introduction, 4-6 main sections with subsections, and a conclusion. 
                  For each section, provide a descriptive title and 2-3 paragraphs of content.`,
                creative: `Create a creative document structure based on this title: "${documentTitle}". 
                  Use an engaging introduction, 3-4 uniquely-themed sections, and a thought-provoking conclusion.
                  Make each section title catchy and provide imaginative content for each.`,
                academic: `Create an academic document structure based on this title: "${documentTitle}". 
                  Include an abstract, introduction with thesis statement, literature review, 
                  methodology, findings, discussion, and conclusion sections.
                  For each section, provide appropriate scholarly content.`
            };

            const prompt = promptTemplates[generationType] || promptTemplates.standard;

            // Add structure instructions
            const fullPrompt = `${prompt}
      
      IMPORTANT: Format your response as a JSON structure that I can parse. Use this exact format:
      {
        "sections": [
          {
            "title": "Section Title",
            "type": "introduction|heading|subheading|paragraph|bulletList|conclusion",
            "content": "Section content goes here...",
            "parentId": null or ID of parent section
          },
          ...more sections
        ]
      }
      
      Do not include any explanatory text outside the JSON structure.`;

            // Simulate initial thinking progress
            await simulateProgress(5, 20);

            // Send to AI
            const options = {
                conversationId,
                moduleContext: {
                    title: 'Document Generation',
                    focus: `${generationType} outline`,
                    documentTitle
                },
                thinkingEnabled: true,
                thinkingBudget: 4096, // Allow good thinking space for complex generation
                directDocumentUpdate: true // Flag to indicate this response should update document directly
            };

            setProgress(25);

            // Call the AI
            const result = await orchestrator.handleUserMessage(fullPrompt, options);

            // Simulate processing progress
            await simulateProgress(30, 60);

            if (!result.success) {
                throw new Error(result.error || "Failed to generate document");
            }

            // Extract the JSON from the response
            let jsonMatch;
            try {
                // Find JSON in the response
                jsonMatch = result.response.match(/\{[\s\S]*\}/);
                if (!jsonMatch) throw new Error("Could not find valid JSON in response");

                const parsedData = JSON.parse(jsonMatch[0]);

                if (!parsedData.sections || !Array.isArray(parsedData.sections)) {
                    throw new Error("Generated content missing steps array");
                }

                // Simulate formatting progress
                await simulateProgress(65, 85);

                // Process the steps to ensure they have IDs and proper structure
                const processedSections = parsedData.sections.map((section, index) => {
                    const newId = Date.now() + index;
                    return {
                        id: newId,
                        title: section.title || "Untitled Section",
                        type: section.type || 'paragraph',
                        content: section.content || "",
                        parentId: section.parentId === null ? null :
                            typeof section.parentId === 'number' ? section.parentId : null,
                        position: index
                    };
                });

                // Map parent references properly (they come as indexes in the array)
                const idMap = processedSections.reduce((map, section, index) => {
                    map[index] = section.id;
                    return map;
                }, {});

                // Update parent references
                const finalSections = processedSections.map(section => {
                    if (section.parentId !== null && typeof section.parentId === 'number') {
                        return { ...section, parentId: idMap[section.parentId] || null };
                    }
                    return section;
                });

                // Final progress before completion
                await simulateProgress(90, 100);

                // Direct document content update
                setDocumentContent({
                    title: documentTitle,
                    sections: finalSections
                });

                // Only show success notification in chat, NOT content
                showSuccessMessage("Document generated successfully");

                console.log("Document content updated with generated steps:", finalSections.length);

            } catch (parseError) {
                console.error("Error parsing AI response:", parseError);
                setError("Failed to parse generated content");
                setProgress(0);
            }

        } catch (error) {
            console.error("Error generating document:", error);
            setError(`Generation failed: ${error.message}`);
            setProgress(0);
        }
    };

    // Helper function to simulate progress for a smoother UX
    const simulateProgress = async (start, end) => {
        const duration = 500; // ms
        const steps = 10;
        const increment = (end - start) / steps;

        for (let i = 0; i < steps; i++) {
            await new Promise(resolve => setTimeout(resolve, duration / steps));
            setProgress(prev => Math.min(start + increment * (i + 1), end));
        }
    };

    // Determine if we should show success state
    const showCompletionState = progress >= 100 && showSuccess;

    return (
        <div className={styles['taytrom-generator']} ref={containerRef}>
            {/* Main generation button */}
            {!showOptions && !isGenerating && (
                <button
                    onClick={() => setShowOptions(true)}
                    className={styles['taytrom-button-generate']}
                >
                    <Wand2 size={18} />
                    <span>Generate Content from Title</span>
                </button>
            )}

            {/* Generation options */}
            {showOptions && !isGenerating && (
                <div className={styles['taytrom-options-panel']}>
                    <div className={styles['taytrom-options-header']}>
                        <h4 className={styles['taytrom-options-title']}>
                            Generate from: "{documentTitle}"
                        </h4>
                        <button
                            onClick={() => setShowOptions(false)}
                            className={styles['taytrom-options-close']}
                            aria-label="Close options"
                        >
                            <X size={16} />
                        </button>
                    </div>

                    <p className={styles['taytrom-options-description']}>
                        Choose a generation style to create a document structure:
                    </p>

                    <div className={styles['taytrom-options-grid']}>
                        {Object.entries(generationTypes).map(([type, info]) => (
                            <button
                                key={type}
                                onClick={() => setGenerationType(type)}
                                className={`${styles['taytrom-option-button']} ${
                                    generationType === type ? styles['taytrom-option-button--selected'] : ''
                                }`}
                            >
                                <div className={styles['taytrom-option-title']}>
                                    {info.label}
                                </div>
                                <div className={styles['taytrom-option-description']}>
                                    {info.description}
                                </div>
                                <div className={styles['taytrom-option-info']}>
                                    ~{info.sections} sections
                                </div>
                            </button>
                        ))}
                    </div>

                    <button
                        onClick={handleGenerate}
                        className={styles['taytrom-button-action']}
                    >
                        <Wand2 size={18} />
                        <span>Generate {generationTypes[generationType].label}</span>
                    </button>
                </div>
            )}

            {/* Generation in progress */}
            {isGenerating && (
                <div className={styles['taytrom-progress-panel']}>
                    <div className={styles['taytrom-progress-content']}>
                        <div className={styles['taytrom-progress-icon-container']}>
                            {showCompletionState ? (
                                <Check size={24} className={styles['taytrom-spinner']} />
                            ) : (
                                <Loader size={24} className={styles['taytrom-spinner']} />
                            )}
                        </div>

                        <div className={styles['taytrom-progress-text']}>
                            <div className={styles['taytrom-progress-title']}>
                                {showCompletionState
                                    ? "Generation Complete!"
                                    : `Generating ${generationTypes[generationType].label}`}
                            </div>
                            <div className={styles['taytrom-progress-description']}>
                                {showCompletionState
                                    ? "Your document structure has been created successfully."
                                    : getProgressMessage(progress, generationType)}
                            </div>
                        </div>

                        {/* Progress bar */}
                        <div className={styles['taytrom-progress-bar-container']}>
                            <div
                                ref={progressBarRef}
                                className={styles['taytrom-progress-bar']}
                                style={{ width: `${progress}%` }}
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* Error message */}
            {error && (
                <div className={styles['taytrom-error-message']}>
                    <AlertTriangle size={18} className={styles['taytrom-error-icon']} />
                    <div className={styles['taytrom-error-text']}>
                        {error}
                    </div>
                    <button
                        onClick={() => setError(null)}
                        className={styles['taytrom-error-close']}
                        aria-label="Dismiss error"
                    >
                        <X size={14} />
                    </button>
                </div>
            )}
        </div>
    );
};

// Helper function to get contextual progress messages
function getProgressMessage(progress, type) {
    if (progress < 20) {
        return "Analyzing your title and preparing generation parameters...";
    } else if (progress < 40) {
        return "Creating an outline based on your document title...";
    } else if (progress < 60) {
        return "Generating section content with appropriate structure...";
    } else if (progress < 80) {
        return "Refining and organizing the document steps...";
    } else {
        return "Finalizing your document structure and formatting content...";
    }
}

export default GenerateFromTitle;