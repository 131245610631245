import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
    ChevronDown, ChevronUp, Edit, Check, X, Maximize, Minimize,
    PlusCircle, Zap, MoveDown, Trash, RefreshCw, ChevronRight,
    ArrowRight, Type, List, Heading, FileText, AlignLeft, Quote,
    AlertTriangle, BookOpen, Save, Sparkles
} from 'lucide-react';
import styles from './Compiler_DocumentPanel_Specialists_Writing.module.css';

/**
 * Document Panel Component for the Writing Platform
 * Renders the interactive document editor with section management
 *
 * @param {Object} documentContent - Document data structure
 * @param {Function} setDocumentContent - Function to update document content
 * @param {Number|String} selectedSection - ID of currently selected section
 * @param {Function} setSelectedSection - Function to update selected section
 * @param {Object} expandedBullets - Map of expanded bullet points
 * @param {Function} setExpandedBullets - Function to update expanded bullets
 * @param {Function} handleExpandBullet - Function to expand a bullet point
 * @param {Boolean} isDark - Whether dark mode is enabled
 * @param {Function} onDropSuggestion - Handler for dropped AI suggestions
 * @param {Object} styleSettings - Style configuration options
 */
const Compiler_DocumentPanel_Specialists_Writing = ({
                                                        documentContent,
                                                        setDocumentContent,
                                                        selectedSection,
                                                        setSelectedSection,
                                                        expandedBullets,
                                                        setExpandedBullets,
                                                        handleExpandBullet,
                                                        isDark,
                                                        onDropSuggestion,
                                                        styleSettings = { formality: 50, technicality: 50, emotionality: 50, clarity: 50 }
                                                    }) => {
    // Refs for text editor and document container
    const editorRef = useRef(null);
    const documentContainerRef = useRef(null);
    const titleInputRef = useRef(null);

    // Local state for enhanced functionality
    const [editingTitle, setEditingTitle] = useState(false);
    const [localTitle, setLocalTitle] = useState(documentContent.title);
    const [addingSectionAt, setAddingSectionAt] = useState(null);
    const [newSectionType, setNewSectionType] = useState('paragraph');
    const [newSectionParentId, setNewSectionParentId] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [hoveredSection, setHoveredSection] = useState(null);
    const [contextMenuSection, setContextMenuSection] = useState(null);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [draggedSection, setDraggedSection] = useState(null);
    const [dragOverSection, setDragOverSection] = useState(null);
    const [dragOverPosition, setDragOverPosition] = useState(null); // 'before', 'after', or 'child'
    const [showTypeSelector, setShowTypeSelector] = useState(null);
    const [replacingSectionId, setReplacingSectionId] = useState(null);
    const [sectionBackupContent, setSectionBackupContent] = useState({});
    const [dropFeedback, setDropFeedback] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [lastSavedTime, setLastSavedTime] = useState(null);
    const [editableContentState, setEditableContentState] = useState({});
    const [saveFeedback, setSaveFeedback] = useState(null);
    const [documentReplaced, setDocumentReplaced] = useState(false);
    const [cursorPositions, setCursorPositions] = useState({});
    const [selectionRanges, setSelectionRanges] = useState({});
    const [activeEditableId, setActiveEditableId] = useState(null);

    // Enhanced debounce function for auto-save
    const debounce = (func, delay) => {
        let timer;
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    // Show save feedback temporarily
    const showSaveFeedback = useCallback((message = "Changes saved") => {
        setSaveFeedback(message);
        setTimeout(() => setSaveFeedback(null), 1500);
    }, []);

    // Function to save selection/cursor position
    const saveSelection = useCallback((elementId) => {
        if (window.getSelection && window.getSelection().rangeCount > 0) {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);

            setSelectionRanges(prev => ({
                ...prev,
                [elementId]: {
                    startContainer: range.startContainer,
                    startOffset: range.startOffset,
                    endContainer: range.endContainer,
                    endOffset: range.endOffset
                }
            }));
        }
    }, []);

    // Helper function to check for major document structure changes
    const hasDocumentStructureChanged = useCallback((prevSections, currentSections) => {
        if (!prevSections || !currentSections) return true;
        if (prevSections.length !== currentSections.length) return true;

        // Check if section IDs match (basic structure check)
        const prevIds = new Set(prevSections.map(s => s.id));
        const currentIds = new Set(currentSections.map(s => s.id));

        // Check if all current IDs exist in previous
        for (const id of currentIds) {
            if (!prevIds.has(id)) return true;
        }

        return false;
    }, []);

    // Function to restore selection/cursor position
    const restoreSelection = useCallback((elementId, elementRef) => {
        const savedRange = selectionRanges[elementId];
        if (savedRange && window.getSelection && document.createRange) {
            try {
                const selection = window.getSelection();
                const range = document.createRange();

                range.setStart(savedRange.startContainer, savedRange.startOffset);
                range.setEnd(savedRange.endContainer, savedRange.endOffset);

                selection.removeAllRanges();
                selection.addRange(range);

                // Focus the element
                if (elementRef && elementRef.current) {
                    elementRef.current.focus();
                }
            } catch (e) {
                console.error("Failed to restore selection:", e);
                // If restoration fails, at least try to focus the element
                if (elementRef && elementRef.current) {
                    elementRef.current.focus();
                }
            }
        }
    }, [selectionRanges]);

    // Debounced save function
    const debouncedSave = useCallback(
        debounce((updatedSections) => {
            setIsSaving(true);
            setDocumentContent(prev => ({
                ...prev,
                sections: updatedSections
            }));
            setIsSaving(false);
            setLastSavedTime(new Date());
            showSaveFeedback();
        }, 800),
        [setDocumentContent, showSaveFeedback]
    );

    // Function to save document title
    const saveDocumentTitle = useCallback((newTitle) => {
        if (newTitle !== documentContent.title) {
            setIsSaving(true);
            setDocumentContent(prev => ({
                ...prev,
                title: newTitle
            }));
            setLastSavedTime(new Date());
            setTimeout(() => {
                setIsSaving(false);
                showSaveFeedback("Title updated");
            }, 300);
        }
        setEditingTitle(false);
    }, [documentContent.title, setDocumentContent, showSaveFeedback]);

    // Calculate section hierarchy and depth
    const sectionHierarchy = React.useMemo(() => {
        const rootSections = documentContent.sections
            .filter(section => section.parentId === null)
            .map(section => section.id);

        const depthMap = new Map();
        rootSections.forEach(id => depthMap.set(id, 1));

        const calculateDepth = (parentId, depth) => {
            const children = documentContent.sections
                .filter(section => section.parentId === parentId)
                .map(section => section.id);

            children.forEach(childId => {
                depthMap.set(childId, depth);
                calculateDepth(childId, depth + 1);
            });
        };

        rootSections.forEach(rootId => calculateDepth(rootId, 2));
        return depthMap;
    }, [documentContent.sections]);

    // Get ordered steps for display
    const orderedSections = React.useMemo(() => {
        const result = [];

        const addSectionWithChildren = (sectionId, orderIndex) => {
            const section = documentContent.sections.find(s => s.id === sectionId);
            if (!section) return;

            result.push({
                ...section,
                orderIndex,
                depth: sectionHierarchy.get(sectionId) || 1
            });

            const children = documentContent.sections
                .filter(s => s.parentId === sectionId)
                .sort((a, b) => {
                    if (a.position !== undefined && b.position !== undefined) {
                        return a.position - b.position;
                    }
                    return a.id - b.id;
                });

            children.forEach((child, index) => {
                addSectionWithChildren(child.id, `${orderIndex}.${index + 1}`);
            });
        };

        const rootSections = documentContent.sections
            .filter(section => section.parentId === null)
            .sort((a, b) => {
                if (a.position !== undefined && b.position !== undefined) {
                    return a.position - b.position;
                }
                return a.id - b.id;
            });

        rootSections.forEach((section, index) => {
            addSectionWithChildren(section.id, String(index + 1));
        });

        return result;
    }, [documentContent.sections, sectionHierarchy]);

    // Effect to handle document content changes
    useEffect(() => {
        // When document content changes completely, update local state and reset selections
        if (documentContent) {
            // Reset editable content state
            setEditableContentState({});

            // Reset expanded bullets if document structure changed significantly
            const sectionIds = documentContent.sections.map(s => s.id);
            const existingExpandedKeys = Object.keys(expandedBullets);
            const validExpandedKeys = existingExpandedKeys.filter(key => {
                const sectionId = parseInt(key.split('-')[0]);
                return sectionIds.includes(sectionId);
            });

            // Only update if we have keys that need to be removed
            if (validExpandedKeys.length !== existingExpandedKeys.length) {
                const newExpandedBullets = {};
                validExpandedKeys.forEach(key => {
                    newExpandedBullets[key] = expandedBullets[key];
                });
                setExpandedBullets(newExpandedBullets);
            }

            // If no section is selected, select the first one
            if (!selectedSection && documentContent.sections.length > 0) {
                setSelectedSection(documentContent.sections[0].id);
            } else if (selectedSection) {
                // Verify the selected section still exists in the new document
                const sectionExists = documentContent.sections.some(s => s.id === selectedSection);
                if (!sectionExists && documentContent.sections.length > 0) {
                    setSelectedSection(documentContent.sections[0].id);
                }
            }

            // Update local title
            setLocalTitle(documentContent.title || "");
        }
    }, [documentContent, selectedSection, expandedBullets, setExpandedBullets, setSelectedSection]);

    // Effect to restore cursor position after render
    useEffect(() => {
        if (activeEditableId && selectionRanges[activeEditableId]) {
            const elementRef = editorRef;
            if (elementRef) {
                // Small delay to ensure DOM is updated
                setTimeout(() => {
                    restoreSelection(activeEditableId, elementRef);
                }, 10);
            }
        }
    }, [activeEditableId, selectionRanges, restoreSelection, documentContent.sections]);

    // Initialize title
    useEffect(() => {
        setLocalTitle(documentContent.title);
    }, [documentContent.title]);

    // Set selectedSection to first section if not already set and steps are available
    useEffect(() => {
        if (!selectedSection && documentContent.sections.length > 0) {
            setSelectedSection(documentContent.sections[0].id);
        }
    }, [documentContent.sections, selectedSection, setSelectedSection]);

    // Auto-focus title input when editing title
    useEffect(() => {
        if (editingTitle && titleInputRef.current) {
            titleInputRef.current.focus();
            titleInputRef.current.select();
        }
    }, [editingTitle]);

    // Handle keyboard shortcuts
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Ctrl/Cmd + = zoom in
            if ((e.ctrlKey || e.metaKey) && e.key === '=') {
                e.preventDefault();
                setZoomLevel(prev => Math.min(prev + 0.1, 2));
            }
            // Ctrl/Cmd + - zoom out
            else if ((e.ctrlKey || e.metaKey) && e.key === '-') {
                e.preventDefault();
                setZoomLevel(prev => Math.max(prev - 0.1, 0.5));
            }
            // Ctrl/Cmd + 0 reset zoom
            else if ((e.ctrlKey || e.metaKey) && e.key === '0') {
                e.preventDefault();
                setZoomLevel(1);
            }
            // Esc to exit fullscreen or close context menu
            else if (e.key === 'Escape') {
                if (isFullscreen) {
                    setIsFullscreen(false);
                }
                if (contextMenuSection) {
                    setContextMenuSection(null);
                }
                if (showTypeSelector) {
                    setShowTypeSelector(null);
                }
                if (editingTitle) {
                    setEditingTitle(false);
                    setLocalTitle(documentContent.title); // Reset to original
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isFullscreen, contextMenuSection, showTypeSelector, editingTitle, documentContent.title]);

    // Hide context menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (contextMenuSection && !e.target.closest(`.${styles['taytrom-context-menu']}`)) {
                setContextMenuSection(null);
            }
            if (showTypeSelector && !e.target.closest(`.${styles['taytrom-type-selector']}`)) {
                setShowTypeSelector(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [contextMenuSection, showTypeSelector]);

    // Clean up drop feedback after 2 seconds
    useEffect(() => {
        if (dropFeedback) {
            const timeout = setTimeout(() => {
                setDropFeedback(null);
            }, 2000);

            return () => clearTimeout(timeout);
        }
    }, [dropFeedback]);

    // Get icon for section type
    const getSectionTypeIcon = (type, size = 16) => {
        switch (type) {
            case 'introduction':
                return <BookOpen size={size} />;
            case 'heading':
                return <Heading size={size} />;
            case 'subheading':
                return <Type size={size} />;
            case 'paragraph':
                return <AlignLeft size={size} />;
            case 'bulletList':
                return <List size={size} />;
            case 'conclusion':
                return <FileText size={size} />;
            case 'note':
                return <AlertTriangle size={size} />;
            case 'quote':
                return <Quote size={size} />;
            default:
                return <AlignLeft size={size} />;
        }
    };

    // Toggle fullscreen mode
    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
        setTimeout(() => {
            if (documentContainerRef.current) {
                documentContainerRef.current.focus();
            }
        }, 100);
    };

    // Open context menu for a section
    const openContextMenu = (e, sectionId) => {
        e.preventDefault();
        e.stopPropagation();
        setContextMenuSection(sectionId);
        setContextMenuPosition({ x: e.clientX, y: e.clientY });
    };

    // Open type selector for a section
    const openTypeSelector = (e, sectionId) => {
        e.stopPropagation();
        setShowTypeSelector(sectionId);
    };

    // Change section type
    const changeSectionType = (sectionId, newType) => {
        const updatedSections = documentContent.sections.map(s =>
            s.id === sectionId ? { ...s, type: newType } : s
        );

        setDocumentContent(prev => ({
            ...prev,
            sections: updatedSections
        }));

        setShowTypeSelector(null);
        setLastSavedTime(new Date());
        showSaveFeedback("Section type updated");
    };

    // Add a new section with parent handling
    const addNewSection = (index, parentId = null) => {
        if (addingSectionAt === null) return;

        const newSectionId = Date.now();
        const newSection = {
            id: newSectionId,
            title: "New Section",
            content: newSectionType === 'bulletList'
                ? "• Bullet point 1\n• Bullet point 2\n• Bullet point 3"
                : "Enter content here...",
            type: newSectionType,
            parentId: newSectionParentId
        };

        let newSections = [...documentContent.sections];

        // If we're adding a new root section at a specific position
        if (parentId === null && index !== null) {
            const rootSections = documentContent.sections.filter(s => s.parentId === null);

            let inserted = false;
            let count = 0;

            newSections = [];
            for (const section of documentContent.sections) {
                newSections.push(section);

                if (section.parentId === null) {
                    count++;

                    if (count === index && !inserted) {
                        newSections.push(newSection);
                        inserted = true;
                    }
                }
            }

            if (!inserted) {
                newSections.push(newSection);
            }
        }
        // If we're adding a child section to a parent
        else if (parentId !== null) {
            newSection.parentId = parentId;
            newSections.push(newSection);
        }
        // Default case - just add to the end
        else {
            newSections.push(newSection);
        }

        setDocumentContent(prev => ({
            ...prev,
            sections: newSections
        }));

        setAddingSectionAt(null);
        setNewSectionParentId(null);
        setSelectedSection(newSectionId);

        // Auto-focus on the new section
        setTimeout(() => {
            const element = document.getElementById(`section-${newSectionId}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                element.classList.add(styles['taytrom-section-appear']);
                setTimeout(() => element.classList.remove(styles['taytrom-section-appear']), 500);

                // Set focus to the editable content
                const contentEl = element.querySelector('[contenteditable]');
                if (contentEl) {
                    contentEl.focus();
                    // Select all text to make it easy to replace
                    const selection = window.getSelection();
                    const range = document.createRange();
                    range.selectNodeContents(contentEl);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }
        }, 50);

        setLastSavedTime(new Date());
        showSaveFeedback("New section added");
    };

    // Delete a section with child handling
    const deleteSection = (id) => {
        // Check if section has children
        const hasChildren = documentContent.sections.some(s => s.parentId === id);

        if (hasChildren) {
            if (!window.confirm('This section has subsections. Delete all of them?')) {
                return;
            }

            // Delete section and all its descendants
            const allDescendants = getAllDescendants(id);
            const newSections = documentContent.sections.filter(s => !allDescendants.includes(s.id));

            // If deleting the selected section, select the previous or next root section
            if (id === selectedSection) {
                const rootSections = newSections.filter(s => s.parentId === null);
                if (rootSections.length > 0) {
                    setSelectedSection(rootSections[0].id);
                }
            }

            setDocumentContent(prev => ({
                ...prev,
                sections: newSections
            }));
        } else {
            // Just delete this section
            const newSections = documentContent.sections.filter(s => s.id !== id);

            // If deleting the selected section, select the previous or next one
            if (id === selectedSection && newSections.length > 0) {
                const index = documentContent.sections.findIndex(s => s.id === id);
                const newIndex = Math.min(index, newSections.length - 1);
                setSelectedSection(newSections[newIndex].id);
            }

            setDocumentContent(prev => ({
                ...prev,
                sections: newSections
            }));
        }

        // Close context menu if it was open for this section
        if (contextMenuSection === id) {
            setContextMenuSection(null);
        }

        setLastSavedTime(new Date());
        showSaveFeedback("Section deleted");
    };

    // Get all descendant steps recursively
    const getAllDescendants = (sectionId) => {
        const descendants = [sectionId];

        const addChildren = (parentId) => {
            const children = documentContent.sections
                .filter(s => s.parentId === parentId)
                .map(s => s.id);

            children.forEach(childId => {
                descendants.push(childId);
                addChildren(childId);
            });
        };

        addChildren(sectionId);
        return descendants;
    };

    // Move a section up or down
    const moveSection = (id, direction) => {
        const section = documentContent.sections.find(s => s.id === id);
        if (!section) return;

        // Get siblings (steps with same parent)
        const siblings = documentContent.sections
            .filter(s => s.parentId === section.parentId)
            .sort((a, b) => {
                if (a.position !== undefined && b.position !== undefined) {
                    return a.position - b.position;
                }
                return a.id - b.id;
            });

        const index = siblings.findIndex(s => s.id === id);
        if ((direction === -1 && index === 0) ||
            (direction === 1 && index === siblings.length - 1)) {
            return; // Can't move further
        }

        // Swap positions with the sibling
        const targetIndex = index + direction;
        const targetSection = siblings[targetIndex];

        // Update position property
        const updatedSections = documentContent.sections.map(s => {
            if (s.id === id) {
                return { ...s, position: targetIndex };
            } else if (s.id === targetSection.id) {
                return { ...s, position: index };
            }
            return s;
        });

        setDocumentContent(prev => ({
            ...prev,
            sections: updatedSections
        }));

        setLastSavedTime(new Date());
        showSaveFeedback(direction === -1 ? "Section moved up" : "Section moved down");
    };

    // Add a child section
    const addChildSection = (parentId) => {
        setAddingSectionAt(0);
        setNewSectionParentId(parentId);
    };

    // Handle drop of suggestion
    const handleDrop = (e, sectionId) => {
        e.preventDefault();
        e.stopPropagation();

        setDragOverSection(null);
        setDragOverPosition(null);

        try {
            // Get the thought data from the dropped item
            const thoughtData = JSON.parse(e.dataTransfer.getData('application/json'));

            // If we have a handler for dropped suggestions, call it
            if (onDropSuggestion && thoughtData) {
                // Show replacing state
                setReplacingSectionId(sectionId);

                // Find the section that's being replaced
                const section = documentContent.sections.find(s => s.id === sectionId);
                if (section) {
                    // Back up the original content
                    setSectionBackupContent(prev => ({
                        ...prev,
                        [sectionId]: section.content
                    }));

                    // Set a temporary "replacing" content
                    const updatedSections = documentContent.sections.map(s =>
                        s.id === sectionId
                            ? {
                                ...s,
                                content: "Rewriting section based on suggestion...",
                                previousContent: s.content
                            }
                            : s
                    );

                    setDocumentContent(prev => ({
                        ...prev,
                        sections: updatedSections
                    }));
                }

                // Call the handler to process the replacement
                onDropSuggestion(sectionId, thoughtData);

                // Show drop feedback
                setDropFeedback({
                    sectionId,
                    content: thoughtData.content,
                    isReplacement: true
                });
            }
        } catch (error) {
            console.error("Error processing dropped suggestion:", error);

            // Clear replacing state on error
            setReplacingSectionId(null);

            // Restore original content if there was an error
            if (sectionBackupContent[sectionId]) {
                const updatedSections = documentContent.sections.map(s =>
                    s.id === sectionId
                        ? { ...s, content: sectionBackupContent[sectionId] }
                        : s
                );

                setDocumentContent(prev => ({
                    ...prev,
                    sections: updatedSections
                }));
            }
        }
    };

    // Handle drag start for section reordering
    const handleSectionDragStart = (e, sectionId) => {
        setDraggedSection(sectionId);
        e.dataTransfer.setData('text/plain', sectionId);
        e.dataTransfer.effectAllowed = 'move';

        // Use a ghost image
        const ghostElement = document.createElement('div');
        ghostElement.textContent = documentContent.sections.find(s => s.id === sectionId)?.title || 'Section';
        ghostElement.className = styles['taytrom-section-drag-ghost'];
        ghostElement.style.position = 'absolute';
        ghostElement.style.top = '-1000px';
        document.body.appendChild(ghostElement);
        e.dataTransfer.setDragImage(ghostElement, 0, 0);

        setTimeout(() => {
            document.body.removeChild(ghostElement);
        }, 0);
    };

    // Handle drag over for section reordering
    const handleSectionDragOver = (e, sectionId) => {
        e.preventDefault();
        if (draggedSection === null) return;

        // Don't allow dropping on self
        if (draggedSection === sectionId) {
            setDragOverSection(null);
            setDragOverPosition(null);
            return;
        }

        setDragOverSection(sectionId);

        // Determine if we're dropping before, after, or as a child
        const rect = e.currentTarget.getBoundingClientRect();
        const y = e.clientY - rect.top;
        const height = rect.height;

        if (y < height * 0.25) {
            setDragOverPosition('before');
        } else if (y > height * 0.75) {
            setDragOverPosition('after');
        } else {
            setDragOverPosition('child');
        }
    };

    // Handle section drop for reordering
    const handleSectionDrop = (e, sectionId) => {
        e.preventDefault();
        if (draggedSection === null || draggedSection === sectionId) {
            setDraggedSection(null);
            setDragOverSection(null);
            setDragOverPosition(null);
            return;
        }

        // Get the steps
        const draggedSectionObj = documentContent.sections.find(s => s.id === draggedSection);
        const targetSectionObj = documentContent.sections.find(s => s.id === sectionId);

        if (!draggedSectionObj || !targetSectionObj) {
            setDraggedSection(null);
            setDragOverSection(null);
            setDragOverPosition(null);
            return;
        }

        // Create a copy of the steps
        let newSections = [...documentContent.sections];

        if (dragOverPosition === 'child') {
            // Make dragged section a child of target section
            newSections = newSections.map(s => {
                if (s.id === draggedSection) {
                    return { ...s, parentId: sectionId };
                }
                return s;
            });
        } else {
            // Get siblings of the target section
            const siblings = newSections
                .filter(s => s.parentId === targetSectionObj.parentId)
                .sort((a, b) => {
                    if (a.position !== undefined && b.position !== undefined) {
                        return a.position - b.position;
                    }
                    return a.id - b.id;
                });

            // Get the target index in the siblings array
            const targetIndex = siblings.findIndex(s => s.id === sectionId);

            // Remove the dragged section from its current position
            newSections = newSections.map(s => {
                if (s.id === draggedSection) {
                    return {
                        ...s,
                        parentId: targetSectionObj.parentId,
                        position: undefined // Will be set correctly below
                    };
                }
                return s;
            });

            // Get the updated siblings (excluding the target for now)
            const updatedSiblings = newSections
                .filter(s => s.parentId === targetSectionObj.parentId && s.id !== draggedSection)
                .sort((a, b) => {
                    if (a.position !== undefined && b.position !== undefined) {
                        return a.position - b.position;
                    }
                    return a.id - b.id;
                });

            // Insert the dragged section at the appropriate position
            let newPosition = targetIndex;
            if (dragOverPosition === 'after') {
                newPosition += 1;
            }

            // Update positions for all siblings
            newSections = newSections.map(s => {
                if (s.parentId !== targetSectionObj.parentId) {
                    return s; // Not a sibling, leave unchanged
                }

                const siblingIndex = updatedSiblings.findIndex(sib => sib.id === s.id);

                if (s.id === draggedSection) {
                    return { ...s, position: newPosition };
                } else if (siblingIndex >= newPosition) {
                    return { ...s, position: siblingIndex + 1 };
                } else {
                    return { ...s, position: siblingIndex };
                }
            });
        }

        // Update the document content
        setDocumentContent(prev => ({
            ...prev,
            sections: newSections
        }));

        // Reset drag state
        setDraggedSection(null);
        setDragOverSection(null);
        setDragOverPosition(null);

        setLastSavedTime(new Date());
        showSaveFeedback("Section reorganized");
    };

    // Handle drag end
    const handleSectionDragEnd = () => {
        setDraggedSection(null);
        setDragOverSection(null);
        setDragOverPosition(null);
    };

    // Get drop indicator class based on position
    const getDropIndicatorClass = (section) => {
        if (dragOverSection !== section.id || !dragOverPosition) {
            return '';
        }

        if (dragOverPosition === 'before') {
            return styles['taytrom-drop-indicator-before'];
        } else if (dragOverPosition === 'after') {
            return styles['taytrom-drop-indicator-after'];
        } else if (dragOverPosition === 'child') {
            return styles['taytrom-drop-indicator-child'];
        }

        return '';
    };

    // Function to update section content with auto-save and cursor preservation
    const updateSectionContent = (sectionId, newContent) => {
        // Check if section still exists (in case of document replacement)
        const sectionExists = documentContent.sections.find(s => s.id === sectionId);
        if (!sectionExists) {
            console.warn("Attempted to update content for non-existent section:", sectionId);
            return;
        }

        const updatedSections = documentContent.sections.map(s =>
            s.id === sectionId ? { ...s, content: newContent } : s
        );

        // Update immediately for responsive UI
        setDocumentContent(prev => ({
            ...prev,
            sections: updatedSections
        }));

        // Trigger debounced save
        debouncedSave(updatedSections);
    };

    // Update section title with auto-save
    const updateSectionTitle = (sectionId, newTitle) => {
        const updatedSections = documentContent.sections.map(s =>
            s.id === sectionId ? { ...s, title: newTitle } : s
        );

        setDocumentContent(prev => ({
            ...prev,
            sections: updatedSections
        }));

        setLastSavedTime(new Date());
        showSaveFeedback("Section title updated");
    };

    // Render bullet points with expand feature and editable content
    const renderBulletPoints = (content, sectionId) => {
        const bullets = content.split('\n');

        return bullets.map((bullet, index) => {
            const expandedKey = `${sectionId}-${index}`;
            const isExpanded = expandedBullets[expandedKey];
            const bulletText = bullet.startsWith('• ') ? bullet.substring(2) : bullet;
            const bulletEditableId = `bullet-${sectionId}-${index}`;

            return (
                <div key={index} className={styles['taytrom-bullet-point-container']}>
                    <div
                        className={`${styles['taytrom-bullet-point']} ${isExpanded ? styles['taytrom-bullet-point--expanded'] : ''}`}
                        onMouseEnter={() => document.getElementById(`bullet-expand-${expandedKey}`)?.classList.add(styles['visible'])}
                        onMouseLeave={() => document.getElementById(`bullet-expand-${expandedKey}`)?.classList.remove(styles['visible'])}
                    >
                        <div className={styles['taytrom-bullet-marker']}
                             onClick={() => handleExpandBullet(sectionId, index)}>
                            •
                        </div>

                        <div
                            id={bulletEditableId}
                            contentEditable
                            suppressContentEditableWarning
                            className={styles['taytrom-bullet-content']}
                            onFocus={() => {
                                setActiveEditableId(bulletEditableId);
                            }}
                            onBlur={(e) => {
                                const bullets = content.split('\n');
                                bullets[index] = `• ${e.target.innerText}`;
                                const updatedContent = bullets.join('\n');
                                updateSectionContent(sectionId, updatedContent);
                                setActiveEditableId(null);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    // Focus on next bullet if it exists, otherwise create new bullet
                                    const nextBulletId = `bullet-${sectionId}-${index + 1}`;
                                    const nextBullet = document.getElementById(nextBulletId);
                                    if (nextBullet) {
                                        nextBullet.focus();
                                    } else {
                                        // Add new bullet point
                                        const bullets = content.split('\n');
                                        bullets.push('• New bullet point');
                                        const updatedContent = bullets.join('\n');
                                        updateSectionContent(sectionId, updatedContent);

                                        // Focus will be set in useEffect that runs after state update
                                        setTimeout(() => {
                                            const newBulletId = `bullet-${sectionId}-${bullets.length - 1}`;
                                            const newBullet = document.getElementById(newBulletId);
                                            if (newBullet) {
                                                newBullet.focus();

                                                // Place cursor at beginning
                                                const selection = window.getSelection();
                                                const range = document.createRange();
                                                range.setStart(newBullet.firstChild || newBullet, 0);
                                                range.collapse(true);
                                                selection.removeAllRanges();
                                                selection.addRange(range);
                                            }
                                        }, 50);
                                    }
                                }
                            }}
                            onInput={(e) => {
                                // Save the current cursor position
                                saveSelection(bulletEditableId);

                                const bullets = content.split('\n');
                                bullets[index] = `• ${e.target.innerText}`;
                                const updatedContent = bullets.join('\n');

                                // Save the current editable state
                                setEditableContentState(prev => ({
                                    ...prev,
                                    [bulletEditableId]: updatedContent
                                }));

                                // Use debounced save
                                debouncedSave(documentContent.sections.map(s =>
                                    s.id === sectionId ? { ...s, content: updatedContent } : s
                                ));
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {bulletText}
                        </div>

                        <div className={styles['taytrom-bullet-actions']}>
                            <button
                                className={styles['taytrom-bullet-action-button']}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // Add new bullet point
                                    const bullets = content.split('\n');
                                    bullets.splice(index + 1, 0, '• New bullet point');
                                    const updatedContent = bullets.join('\n');
                                    updateSectionContent(sectionId, updatedContent);
                                }}
                                title="Add bullet point"
                                aria-label="Add bullet point"
                            >
                                <PlusCircle size={14} />
                            </button>

                            <button
                                className={styles['taytrom-bullet-action-button']}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // Remove bullet point
                                    const bullets = content.split('\n');
                                    if (bullets.length <= 1) return; // Don't remove if it's the last one

                                    bullets.splice(index, 1);
                                    const updatedContent = bullets.join('\n');
                                    updateSectionContent(sectionId, updatedContent);
                                }}
                                title="Remove bullet point"
                                aria-label="Remove bullet point"
                            >
                                <X size={14} />
                            </button>
                        </div>

                        {!isExpanded && (
                            <span
                                id={`bullet-expand-${expandedKey}`}
                                className={styles['taytrom-bullet-expand-prompt']}
                                onClick={() => handleExpandBullet(sectionId, index)}
                            >
                                <Sparkles size={10} /> Expand
                            </span>
                        )}
                    </div>

                    {isExpanded && (
                        <div className={styles['taytrom-bullet-expansion']}>
                            <div className={styles['taytrom-expanded-content']}>
                                {expandedBullets[expandedKey]}
                            </div>
                            <div className={styles['taytrom-ai-attribution']}>
                                <Zap size={12} />
                                <span>AI-expanded</span>
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    };

    // Create a unique ID for an editable section
    const getEditableSectionId = (sectionId) => `editable-section-${sectionId}`;

    return (
        <div
            ref={documentContainerRef}
            className={`${styles['taytrom-document-container']} ${isFullscreen ? styles['taytrom-document-container--fullscreen'] : ''}`}
            tabIndex="0"
        >
            {/* Header with title and controls */}
            <div className={styles['taytrom-document-header']}>
                <div className={styles['taytrom-document-title']}>
                    <FileText size={16} color={isDark ? '#64748b' : '#64748b'} />
                    Interactive Document

                    {/* Save indicator */}
                    {isSaving && (
                        <div className={styles['taytrom-save-indicator']}>
                            <RefreshCw size={12} className={styles['taytrom-spin-animation']} />
                            <span>Saving...</span>
                        </div>
                    )}

                    {!isSaving && lastSavedTime && (
                        <div className={styles['taytrom-saved-indicator']}>
                            <Save size={12} />
                            <span>Auto-saved</span>
                        </div>
                    )}
                </div>
                <div className={styles['taytrom-document-controls']}>
                    <div className={styles['taytrom-zoom-controls']}>
                        <button
                            onClick={() => setZoomLevel(prev => Math.max(prev - 0.1, 0.5))}
                            className={styles['taytrom-zoom-button']}
                            title="Zoom out"
                            aria-label="Zoom out"
                        >
                            -
                        </button>
                        <span className={styles['taytrom-zoom-level']}>
                            {Math.round(zoomLevel * 100)}%
                        </span>
                        <button
                            onClick={() => setZoomLevel(prev => Math.min(prev + 0.1, 2))}
                            className={styles['taytrom-zoom-button']}
                            title="Zoom in"
                            aria-label="Zoom in"
                        >
                            +
                        </button>
                    </div>
                    <button
                        onClick={toggleFullscreen}
                        className={styles['taytrom-fullscreen-button']}
                        title={isFullscreen ? "Exit fullscreen" : "Fullscreen mode"}
                        aria-label={isFullscreen ? "Exit fullscreen" : "Enter fullscreen mode"}
                    >
                        {isFullscreen ? <Minimize size={16} /> : <Maximize size={16} />}
                    </button>
                </div>
            </div>

            {/* Main document content with zoom */}
            <div
                className={styles['taytrom-document-content-container']}
                key={`doc-${documentContent.sections.length}-${documentContent.sections[0]?.id || 'empty'}`}
                style={{
                    transform: `scale(${zoomLevel})`,
                    maxHeight: '1000px',  // Add a max height
                    overflowY: 'auto',   // Enable vertical scrolling
                    transformOrigin: 'top center' // Keep scaling from top
                }}
            >
                {/* Document title */}
                {editingTitle ? (
                    <div className={styles['taytrom-document-title-container']}>
                        <input
                            ref={titleInputRef}
                            type="text"
                            value={localTitle}
                            onChange={(e) => setLocalTitle(e.target.value)}
                            onBlur={() => saveDocumentTitle(localTitle)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') saveDocumentTitle(localTitle);
                                else if (e.key === 'Escape') {
                                    setEditingTitle(false);
                                    setLocalTitle(documentContent.title); // Reset to original
                                }
                            }}
                            className={styles['taytrom-document-title-input']}
                            aria-label="Document title"
                        />
                    </div>
                ) : (
                    <h1
                        onClick={() => setEditingTitle(true)}
                        className={styles['taytrom-document-heading']}
                    >
                        {documentContent.title}
                        <Edit size={16} className={styles['taytrom-document-heading-edit-icon']}/>
                    </h1>
                )}

                {/* Add section button at the top */}
                <div className={styles['taytrom-add-section-container']}>
                    {addingSectionAt === 0 && newSectionParentId === null ? (
                        <div className={styles['taytrom-section-type-selector']}>
                            <div className={styles['taytrom-section-type-grid']}>
                                {[
                                    {id: 'heading', label: 'Heading', icon: <Heading size={16}/>},
                                    {
                                        id: 'subheading',
                                        label: 'Subheading',
                                        icon: <Type size={16}/>
                                    },
                                    {
                                        id: 'paragraph',
                                        label: 'Paragraph',
                                        icon: <AlignLeft size={16}/>
                                    },
                                    {
                                        id: 'bulletList',
                                        label: 'Bullet List',
                                        icon: <List size={16}/>
                                    },
                                    {
                                        id: 'introduction',
                                        label: 'Introduction',
                                        icon: <BookOpen size={16}/>
                                    },
                                    {
                                        id: 'conclusion',
                                        label: 'Conclusion',
                                        icon: <FileText size={16}/>
                                    },
                                    {id: 'note', label: 'Note', icon: <AlertTriangle size={16}/>},
                                    {id: 'quote', label: 'Quote', icon: <Quote size={16}/>}
                                ].map(type => (
                                    <button
                                        key={type.id}
                                        onClick={() => setNewSectionType(type.id)}
                                        className={`${styles['taytrom-section-type-button']} ${newSectionType === type.id ? styles['taytrom-section-type-button--selected'] : ''}`}
                                        aria-pressed={newSectionType === type.id}
                                        aria-label={`Select ${type.label} section type`}
                                    >
                                        {type.icon}
                                        <span
                                            className={styles['taytrom-section-type-label']}>{type.label}</span>
                                    </button>
                                ))}
                            </div>

                            <div className={styles['taytrom-section-type-actions']}>
                                <button
                                    onClick={() => addNewSection(0)}
                                    className={styles['taytrom-add-section-confirm-button']}
                                    aria-label="Add new section"
                                >
                                    <PlusCircle size={14}/>
                                    <span>Add Section</span>
                                </button>
                                <button
                                    onClick={() => {
                                        setAddingSectionAt(null);
                                        setNewSectionParentId(null);
                                    }}
                                    className={styles['taytrom-add-section-cancel-button']}
                                    aria-label="Cancel adding section"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    ) : (
                        <button
                            onClick={() => setAddingSectionAt(0)}
                            className={styles['taytrom-add-section-button']}
                            aria-label="Start adding new section"
                        >
                            <PlusCircle size={16}/>
                            <span>Add New Section</span>
                        </button>
                    )}
                </div>

                {/* Document steps with enhanced hierarchy */}
                {orderedSections.map((section) => {
                    const editableSectionId = getEditableSectionId(section.id);
                    const isSelected = selectedSection === section.id;
                    const isHovered = hoveredSection === section.id;
                    const sectionClasses = `
                        ${styles['taytrom-document-section']}
                        ${styles[`taytrom-document-section--${section.type}`]}
                        ${isSelected ? styles['taytrom-document-section--selected'] : ''}
                        ${section.depth > 1 ? styles[`taytrom-document-section--depth-${section.depth}`] : ''}
                    `;
                    const dropIndicator = getDropIndicatorClass(section);

                    return (
                        <div key={section.id} id={`section-${section.id}`}>
                            <div
                                className={sectionClasses}
                                onClick={() => setSelectedSection(section.id)}
                                onMouseEnter={() => setHoveredSection(section.id)}
                                onMouseLeave={() => setHoveredSection(null)}
                                onContextMenu={(e) => openContextMenu(e, section.id)}
                                draggable="true"
                                onDragStart={(e) => handleSectionDragStart(e, section.id)}
                                onDragOver={(e) => handleSectionDragOver(e, section.id)}
                                onDragEnter={(e) => e.preventDefault()}
                                onDragLeave={() => {
                                    if (dragOverSection === section.id) {
                                        setDragOverSection(null);
                                        setDragOverPosition(null);
                                    }
                                }}
                                onDrop={(e) => handleSectionDrop(e, section.id)}
                                onDragEnd={handleSectionDragEnd}
                                onDrop={(e) => handleDrop(e, section.id)}
                                aria-selected={isSelected}
                                role="region"
                                aria-label={`${section.type} section: ${section.title}`}
                            >
                                {/* Drop indicator for drag and drop */}
                                {dropIndicator && <div className={styles[dropIndicator]}/>}

                                {/* Section header with type indicator and controls */}
                                <div className={styles['taytrom-section-header']}>
                                    <div className={styles['taytrom-section-info']}>
                                        {/* Section type indicator with icon */}
                                        <div
                                            className={`${styles['taytrom-section-type-indicator']} ${styles[`taytrom-section-type-indicator--${section.type}`]}`}
                                            onClick={(e) => openTypeSelector(e, section.id)}
                                            role="button"
                                            aria-label={`Change section type, currently ${section.type}`}
                                        >
                                            {getSectionTypeIcon(section.type, 14)}
                                            <span style={{textTransform: 'capitalize'}}>
                                                {section.type === 'bulletList' ? 'Bullet List' : section.type}
                                            </span>
                                        </div>

                                        {/* Section title - now inline editable */}
                                        <h3
                                            contentEditable
                                            suppressContentEditableWarning
                                            onBlur={(e) => updateSectionTitle(section.id, e.target.innerText)}
                                            onKeyDown={(e) => {
                                                // Capture Enter and Escape to blur the element
                                                if (e.key === 'Enter' || e.key === 'Escape') {
                                                    e.preventDefault();
                                                    e.target.blur();
                                                }
                                            }}
                                            className={styles['taytrom-section-title']}
                                            onInput={() => setIsSaving(true)}
                                            onClick={(e) => e.stopPropagation()}
                                            aria-label="Section title, click to edit"
                                        >
                                            {section.title}
                                        </h3>

                                        {/* Section hierarchy indicator */}
                                        {section.depth > 1 && (
                                            <span
                                                className={styles['taytrom-section-hierarchy-label']}>
                                                {section.orderIndex}
                                            </span>
                                        )}
                                    </div>

                                    {/* Section controls - only show when hovered or selected */}
                                    {(isSelected || isHovered) && (
                                        <div className={styles['taytrom-section-controls']}>
                                            {/* Show move buttons */}
                                            <button
                                                className={styles['taytrom-control-button']}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    moveSection(section.id, -1);
                                                }}
                                                title="Move up"
                                                aria-label="Move section up"
                                            >
                                                <ChevronUp size={14}/>
                                            </button>
                                            <button
                                                className={styles['taytrom-control-button']}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    moveSection(section.id, 1);
                                                }}
                                                title="Move down"
                                                aria-label="Move section down"
                                            >
                                                <ChevronDown size={14}/>
                                            </button>

                                            {/* Add child section */}
                                            <button
                                                className={`${styles['taytrom-control-button']} ${styles['taytrom-control-button--text']}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    addChildSection(section.id);
                                                }}
                                                title="Add child section"
                                                aria-label="Add child section"
                                            >
                                                <ChevronRight size={12}/>
                                                <span>Add Child</span>
                                            </button>

                                            {/* Delete section */}
                                            <button
                                                className={`${styles['taytrom-control-button']} ${styles['taytrom-control-button--delete']}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteSection(section.id);
                                                }}
                                                title="Delete section"
                                                aria-label="Delete section"
                                            >
                                                <X size={14}/>
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {/* Section content based on type */}
                                <div className={styles['taytrom-section-content']}>
                                    {section.type === "bulletList" ? (
                                        renderBulletPoints(section.content, section.id)
                                    ) : (
                                        <div
                                            ref={isSelected ? editorRef : null}
                                            id={editableSectionId}
                                            contentEditable
                                            suppressContentEditableWarning
                                            className={`${styles['taytrom-section-content-editable']} ${isSelected ? styles['taytrom-section-content-editable--selected'] : ''}`}
                                            onFocus={() => {
                                                setActiveEditableId(editableSectionId);
                                            }}
                                            onBlur={(e) => {
                                                updateSectionContent(section.id, e.target.innerText);
                                                setActiveEditableId(null);
                                            }}
                                            onInput={(e) => {
                                                // Save selection before state update
                                                saveSelection(editableSectionId);

                                                setIsSaving(true);
                                                const updatedSections = documentContent.sections.map(s =>
                                                    s.id === section.id ? {
                                                        ...s,
                                                        content: e.target.innerText
                                                    } : s
                                                );
                                                debouncedSave(updatedSections);
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (selectedSection !== section.id) {
                                                    setSelectedSection(section.id);
                                                }
                                            }}
                                            aria-label="Section content, click to edit"
                                        >
                                            {section.content}
                                        </div>
                                    )}
                                </div>

                                {/* Section replace feedback */}
                                {dropFeedback?.sectionId === section.id && (
                                    <div
                                        className={`${styles['taytrom-replacement-feedback']} ${dropFeedback.isReplacement ? '' : styles['taytrom-replacement-feedback--success']}`}>
                                        {dropFeedback.isReplacement ? (
                                            <>
                                                <RefreshCw size={12}/>
                                                <span>AI Replacing...</span>
                                            </>
                                        ) : (
                                            <>
                                                <Zap size={12}/>
                                                <span>AI Enhancing...</span>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>

                            {/* Type selector popup */}
                            {showTypeSelector === section.id && (
                                <div className={styles['taytrom-type-selector']} role="dialog"
                                     aria-label="Change section type">
                                    {[
                                        {
                                            id: 'heading',
                                            label: 'Heading',
                                            icon: <Heading size={16}/>
                                        },
                                        {
                                            id: 'subheading',
                                            label: 'Subheading',
                                            icon: <Type size={16}/>
                                        },
                                        {
                                            id: 'paragraph',
                                            label: 'Paragraph',
                                            icon: <AlignLeft size={16}/>
                                        },
                                        {
                                            id: 'bulletList',
                                            label: 'Bullet List',
                                            icon: <List size={16}/>
                                        },
                                        {
                                            id: 'introduction',
                                            label: 'Introduction',
                                            icon: <BookOpen size={16}/>
                                        },
                                        {
                                            id: 'conclusion',
                                            label: 'Conclusion',
                                            icon: <FileText size={16}/>
                                        },
                                        {
                                            id: 'note',
                                            label: 'Note',
                                            icon: <AlertTriangle size={16}/>
                                        },
                                        {id: 'quote', label: 'Quote', icon: <Quote size={16}/>}
                                    ].map(type => (
                                        <button
                                            key={type.id}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                changeSectionType(section.id, type.id);
                                            }}
                                            className={`${styles['taytrom-section-type-button']} ${section.type === type.id ? styles['taytrom-section-type-button--selected'] : ''}`}
                                            aria-pressed={section.type === type.id}
                                            aria-label={`Change to ${type.label} section type`}
                                        >
                                            {type.icon}
                                            <span
                                                className={styles['taytrom-section-type-label']}>{type.label}</span>
                                        </button>
                                    ))}
                                </div>
                            )}

                            {/* Context menu */}
                            {contextMenuSection === section.id && (
                                <div
                                    className={styles['taytrom-context-menu']}
                                    style={{
                                        left: contextMenuPosition.x,
                                        top: contextMenuPosition.y
                                    }}
                                    role="menu"
                                    aria-label="Section actions menu"
                                >
                                    <div className={styles['taytrom-context-menu-header']}>
                                        Section Actions
                                    </div>

                                    <button
                                        className={styles['taytrom-context-menu-item']}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openTypeSelector(e, section.id);
                                            setContextMenuSection(null);
                                        }}
                                        role="menuitem"
                                    >
                                        <RefreshCw size={14}/>
                                        <span>Change Type</span>
                                    </button>

                                    <button
                                        className={styles['taytrom-context-menu-item']}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            addChildSection(section.id);
                                            setContextMenuSection(null);
                                        }}
                                        role="menuitem"
                                    >
                                        <ChevronRight size={14}/>
                                        <span>Add Child Section</span>
                                    </button>

                                    <button
                                        className={styles['taytrom-context-menu-item']}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            moveSection(section.id, -1);
                                            setContextMenuSection(null);
                                        }}
                                        role="menuitem"
                                    >
                                        <ChevronUp size={14}/>
                                        <span>Move Up</span>
                                    </button>

                                    <button
                                        className={styles['taytrom-context-menu-item']}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            moveSection(section.id, 1);
                                            setContextMenuSection(null);
                                        }}
                                        role="menuitem"
                                    >
                                        <ChevronDown size={14}/>
                                        <span>Move Down</span>
                                    </button>

                                    <div className={styles['taytrom-context-menu-divider']}
                                         role="separator"/>

                                    <button
                                        className={`${styles['taytrom-context-menu-item']} ${styles['taytrom-context-menu-item--delete']}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            deleteSection(section.id);
                                            setContextMenuSection(null);
                                        }}
                                        role="menuitem"
                                    >
                                        <Trash size={14}/>
                                        <span>Delete Section</span>
                                    </button>
                                </div>
                            )}

                            {/* Add section button after each section - Only show for the same hierarchy level */}
                            {(section.parentId === null || addingSectionAt === section.id + 1) && (
                                <div
                                    className={styles['taytrom-add-section-container']}
                                    style={{
                                        marginLeft: section.depth > 1 ? `${(section.depth - 1) * 24}px` : '0'
                                    }}
                                >
                                    {addingSectionAt === section.id + 1 ? (
                                        <div className={styles['taytrom-section-type-selector']}>
                                            <div className={styles['taytrom-section-type-grid']}>
                                                {[
                                                    {
                                                        id: 'heading',
                                                        label: 'Heading',
                                                        icon: <Heading size={16}/>
                                                    },
                                                    {
                                                        id: 'subheading',
                                                        label: 'Subheading',
                                                        icon: <Type size={16}/>
                                                    },
                                                    {
                                                        id: 'paragraph',
                                                        label: 'Paragraph',
                                                        icon: <AlignLeft size={16}/>
                                                    },
                                                    {
                                                        id: 'bulletList',
                                                        label: 'Bullet List',
                                                        icon: <List size={16}/>
                                                    },
                                                    {
                                                        id: 'introduction',
                                                        label: 'Introduction',
                                                        icon: <BookOpen size={16}/>
                                                    },
                                                    {
                                                        id: 'conclusion',
                                                        label: 'Conclusion',
                                                        icon: <FileText size={16}/>
                                                    },
                                                    {
                                                        id: 'note',
                                                        label: 'Note',
                                                        icon: <AlertTriangle size={16}/>
                                                    },
                                                    {
                                                        id: 'quote',
                                                        label: 'Quote',
                                                        icon: <Quote size={16}/>
                                                    }
                                                ].map(type => (
                                                    <button
                                                        key={type.id}
                                                        onClick={() => setNewSectionType(type.id)}
                                                        className={`${styles['taytrom-section-type-button']} ${newSectionType === type.id ? styles['taytrom-section-type-button--selected'] : ''}`}
                                                        aria-pressed={newSectionType === type.id}
                                                        aria-label={`Select ${type.label} section type`}
                                                    >
                                                        {type.icon}
                                                        <span
                                                            className={styles['taytrom-section-type-label']}>{type.label}</span>
                                                    </button>
                                                ))}
                                            </div>

                                            <div className={styles['taytrom-section-type-actions']}>
                                                <button
                                                    onClick={() => addNewSection(section.id + 1)}
                                                    className={styles['taytrom-add-section-confirm-button']}
                                                    aria-label="Add section"
                                                >
                                                    <PlusCircle size={14}/>
                                                    <span>Add Section</span>
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setAddingSectionAt(null);
                                                        setNewSectionParentId(null);
                                                    }}
                                                    className={styles['taytrom-add-section-cancel-button']}
                                                    aria-label="Cancel adding section"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={() => setAddingSectionAt(section.id + 1)}
                                            className={styles['taytrom-add-section-button']}
                                            aria-label="Add section after this one"
                                        >
                                            <PlusCircle size={14}/>
                                            <span>Add Section Here</span>
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {/* Save feedback toast */}
            {saveFeedback && (
                <div className={styles['taytrom-save-feedback']} role="status" aria-live="polite">
                    <Check size={16}/>
                    <span>{saveFeedback}</span>
                </div>
            )}

            {/* Screen reader only announcements for accessibility */}
            <div className={styles['taytrom-sr-only']} aria-live="polite">
                {isSaving ? 'Saving document...' : ''}
                {lastSavedTime ? `Document last saved at ${lastSavedTime.toLocaleTimeString()}` : ''}
            </div>
        </div>
    );
};

export default Compiler_DocumentPanel_Specialists_Writing;