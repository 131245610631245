/**
 * Specialist for remaining functionality in the Chat AI Admin component
 * Includes authentication, UI components, session logs, etc.
 */
const Specialist_Remainder_Chat_AI_Admin = {
    // Admin access codes
    ADMIN_ACCESS_CODES: [
        "TAYTROM_ADMIN_2025",
        "TACS_BLUEPRINT_ACCESS",
        "CLAUDE_DEVELOPER_MODE"
    ],

    // Admin thinking budget options
    ADMIN_THINKING_BUDGET_OPTIONS: [
        { value: 1024, label: "Basic (1K tokens)" },
        { value: 2048, label: "Standard (2K tokens)" },
        { value: 4096, label: "Detailed (4K tokens)" },
        { value: 8192, label: "Comprehensive (8K tokens)" },
        { value: 16384, label: "Deep Analysis (16K tokens)" },
        { value: 32768, label: "Maximum (32K tokens)" },
        { value: 65536, label: "Extended (64K tokens)" },
        { value: 131072, label: "Ultra (128K tokens)" }
    ],

    // Admin operational modes
    ADMIN_OPERATIONAL_MODES: {
        standard: {
            name: 'Standard',
            description: 'General purpose mode for various development tasks'
        },
        exploration: {
            name: 'Exploration',
            description: 'Learn about TACS principles and Taytrom concepts'
        },
        implementation: {
            name: 'Implementation',
            description: 'Generate TACS-compliant code and components'
        },
        debugging: {
            name: 'Debugging',
            description: 'Troubleshoot issues and fix code problems'
        },
        optimization: {
            name: 'Optimization',
            description: 'Improve performance and optimize code'
        },
        architecture: {
            name: 'Architecture',
            description: 'Design system architecture and plan components'
        },
        adminConsole: {
            name: 'Admin Console',
            description: 'Enhanced capabilities for system administration'
        },
        devExperimental: {
            name: 'Experimental',
            description: 'Access to experimental features and capabilities'
        },
        tacs: {
            name: 'TACS Enhancement',
            description: 'Focus on TACS principles implementation and enhancement'
        }
    },

    // Special admin system prompts
    ADMIN_SYSTEM_PROMPTS: {
        standard: `You are Claude, an AI assistant integrated into the Taytrom development platform for administrators. 
Your main role is to help developers implement the Taytrom Approved Communication Standards (TACS).
You have deep knowledge of TACS principles including STACK hierarchy, CSS separation, SPACE organization,
TIES messaging protocol, and AOS orchestration.`,

        architecture: `You are the TACS Architecture Assistant. Your purpose is to help Taytrom administrators design
and implement architectural patterns that perfectly embody TACS principles. Consider each component within the
STACK hierarchy (Scope, Tank, Atom, Cell, Kernel) and ensure proper separation through the CSS pattern
(Construction, Substance, Style). Provide detailed architectural diagrams and explanations.`,

        adminConsole: `You are Claude in Admin Console mode. You have enhanced capabilities for Taytrom system administration.
You can provide detailed system insights, recommend optimizations, and generate comprehensive diagnostics.
You have deep knowledge of the Taytrom codebase, architecture, and implementation details.
Treat the user as a system administrator with full access and provide candid, detailed responses.`,

        devExperimental: `You are Claude in Experimental Development mode. This mode provides access to cutting-edge
features and capabilities being developed for Taytrom. Consider yourself a research partner with the freedom
to suggest innovative approaches, even if they extend beyond current TACS principles. Be creative
but maintain the core architectural integrity of Taytrom.`
    },

    // Initialize remainder state
    initializeRemainderState() {
        return {
            isAuthenticated: false,
            accessCode: '',
            accessAttempts: 0,
            isLocked: false,
            lockdownTimer: 0,
            showAccessForm: true,

            debugMode: false,
            sessionLogs: [],
            showSessionLogs: false,

            operationalMode: "standard",
            systemPrompt: this.ADMIN_SYSTEM_PROMPTS.standard,
            showSystemPrompt: false,
            showAdvancedOptions: false,
            temperature: 0.7,
            maxTokens: 100000,

            extendedThinkingEnabled: true,
            thinkingBudget: 16384,

            detectedContextType: 'admin',

            viewingAdminFile: null
        };
    },

    // Initialize session logs
    initializeSessionLogs() {
        return [];
    },

    // Add to session logs
    addToSessionLogs(message, type = 'info', sessionLogsRef, setSessionLogs, debugMode) {
        const logEntry = {
            timestamp: Date.now(),
            message,
            type
        };

        const updatedLogs = [...sessionLogsRef.current, logEntry].slice(-100);
        sessionLogsRef.current = updatedLogs;
        setSessionLogs(updatedLogs);

        // Log to console in debug mode
        if (debugMode) {
            const logMethod = type === 'error' ? console.error :
                type === 'warning' ? console.warn : console.log;
            logMethod(`[Admin AI] ${message}`);
        }
    },

    // Detect context type (admin vs user)
    detectContextType(aiManagerRef, addToSessionLogs, detectedContextType, loadAdminKnowledge) {
        if (!aiManagerRef.current) return 'admin';

        try {
            // Get current route
            const route = window.location.pathname;
            addToSessionLogs(`Current route: ${route}`);

            // Use AIContextManager's detection method
            const state = { route };
            const isAdmin = aiManagerRef.current._isAdminMode ?
                aiManagerRef.current._isAdminMode(state) :
                (route === '/admin/ai-console' || route.includes('Golden_AIChat_Component_Templates.jsx'));

            const newContextType = isAdmin ? 'admin' : 'user';
            addToSessionLogs(`Context detected: ${isAdmin ? 'Admin Mode' : 'User Mode'}`);

            // If context changed, reload knowledge
            if ((isAdmin && detectedContextType !== 'admin') || (!isAdmin && detectedContextType !== 'user')) {
                if (isAdmin) {
                    loadAdminKnowledge();
                } else {
                    // Could load user knowledge here if needed
                    addToSessionLogs('User context detected, but in admin console');
                }
            }

            return newContextType;
        } catch (error) {
            console.error('[Admin] Error detecting context:', error);
            addToSessionLogs(`Error detecting context: ${error.message}`, 'error');
            return 'admin'; // Default to admin on error
        }
    },

    // Handle access code validation
    validateAccessCode(accessCode, ADMIN_ACCESS_CODES, accessAttempts, setIsAuthenticated, setShowAccessForm, addToSessionLogs, setAccessAttempts, setAccessCode, setIsLocked, setLockdownTimer) {
        if (ADMIN_ACCESS_CODES.includes(accessCode)) {
            setIsAuthenticated(true);
            setShowAccessForm(false);
            addToSessionLogs(`Admin access granted with code: ${accessCode.substring(0, 4)}***`);

            // Store authentication in sessionStorage (not localStorage for security)
            if (typeof window !== 'undefined') {
                sessionStorage.setItem('taytrom_admin_authenticated', 'true');
                // Set expiry for 4 hours
                const expiry = Date.now() + (4 * 60 * 60 * 1000);
                sessionStorage.setItem('taytrom_admin_auth_expiry', expiry.toString());
            }
        } else {
            const newAttempts = accessAttempts + 1;
            setAccessAttempts(newAttempts);
            setAccessCode('');
            addToSessionLogs(`Failed access attempt with code: ${accessCode}`, 'warning');

            // Lock after 3 failed attempts
            if (newAttempts >= 3) {
                setIsLocked(true);
                setLockdownTimer(60); // 60 second lockdown
                addToSessionLogs('Access locked for 60 seconds due to multiple failed attempts', 'error');
            }
        }
    },

    // Handle sign out
    handleSignOut(addToSessionLogs, setIsAuthenticated, setShowAccessForm) {
        if (window.confirm('Are you sure you want to sign out of the admin console?')) {
            setIsAuthenticated(false);
            setShowAccessForm(true);

            // Clear auth from sessionStorage
            if (typeof window !== 'undefined') {
                sessionStorage.removeItem('taytrom_admin_authenticated');
                sessionStorage.removeItem('taytrom_admin_auth_expiry');
            }

            addToSessionLogs("Admin signed out");
        }
    },

    // Render message content with image support
    renderMessageContent(message, isDark) {
        if (message.role === 'user') {
            return (
                <div>
                    <div>{message.content}</div>
                    {message.images && message.images.length > 0 && (
                        <div style={{
                            marginTop: '8px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '8px'
                        }}>
                            {message.images.map((image, index) => (
                                <div
                                    key={`msg_img_${index}`}
                                    style={{
                                        width: '120px',
                                        height: '100px',
                                        borderRadius: '4px',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <img
                                        src={image.data}
                                        alt={image.name || `Image ${index + 1}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            );
        } else {
            // Use markdown for assistant messages
            return <div dangerouslySetInnerHTML={{ __html: this.markdownToHtml(message.content) }} />;
        }
    },

    // Simple markdown to HTML converter (for demonstration - should use a real markdown library)
    markdownToHtml(markdown) {
        // This is a placeholder - in the real implementation, use ReactMarkdown or another library
        return markdown
            .replace(/^# (.+)$/gm, '<h1>$1</h1>')
            .replace(/^## (.+)$/gm, '<h2>$1</h2>')
            .replace(/^### (.+)$/gm, '<h3>$1</h3>')
            .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>')
            .replace(/\*(.+?)\*/g, '<em>$1</em>')
            .replace(/`(.+?)`/g, '<code>$1</code>')
            .replace(/\n\n/g, '<br/>');
    }
};

export default Specialist_Remainder_Chat_AI_Admin;