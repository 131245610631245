import React, { useState, useEffect } from 'react';

/**
 * TaytromAIPillar - A breathtaking visualization of Taytrom's AI pillar
 *
 * Features:
 * - Dynamic neural network visualization
 * - Animated brain with pulsing nodes
 * - Data flow visualization
 * - Context-aware node system
 * - Interactive hover effects
 * - Personalized intelligence visualization
 *
 * @param {Object} props Component properties
 * @param {string|number} props.size Size of the SVG (default: "800px")
 * @param {string} props.primaryColor Primary color (default: "#14b8a6" - teal)
 * @param {string} props.secondaryColor Secondary color (default: "#0ea5e9" - blue)
 * @param {string} props.tertiaryColor Tertiary color (default: "#2563eb" - deeper blue)
 * @param {string} props.bgColor Background color (default: "#0F172A" - dark blue)
 * @param {string} props.className Additional CSS classes
 * @param {Object} props.style Additional inline styles
 * @param {boolean} props.interactive Enable hover interactions (default: true)
 * @param {boolean} props.glowEffect Enable glow effects (default: true)
 * @param {string} props.animationMode Animation style: "pulse", "wave", "flow" (default: "pulse")
 * @param {number} props.animationSpeed Animation speed multiplier (default: 1)
 * @param {boolean} props.reducedMotion Respect reduced motion preferences (default: true)
 */
const TaytromAIPillar = ({
                             size = "800px",
                             primaryColor = "#14b8a6", // teal
                             secondaryColor = "#0ea5e9", // blue
                             tertiaryColor = "#2563eb", // deeper blue
                             bgColor = "#0F172A", // dark blue
                             className = "",
                             style = {},
                             interactive = true,
                             glowEffect = true,
                             animationMode = "pulse",
                             animationSpeed = 1,
                             reducedMotion = true,
                             ...props
                         }) => {
    // Convert size to numeric value if needed
    const sizeValue = typeof size === 'string' && size.endsWith('px')
        ? parseInt(size)
        : (typeof size === 'number' ? size : 800);

    // State for hover interaction
    const [isHovered, setIsHovered] = useState(false);

    // State to track if user prefers reduced motion
    const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

    // Check for reduced motion preference
    useEffect(() => {
        if (typeof window !== 'undefined' && reducedMotion) {
            const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
            setPrefersReducedMotion(mediaQuery.matches);

            const handleChange = () => setPrefersReducedMotion(mediaQuery.matches);
            mediaQuery.addEventListener('change', handleChange);

            return () => mediaQuery.removeEventListener('change', handleChange);
        }
    }, [reducedMotion]);

    // Determine if animations should be active
    const animationsEnabled = !prefersReducedMotion;

    // Calculate animation durations based on speed
    const baseDuration = 4 / animationSpeed;
    const pulseDuration = 3 / animationSpeed;
    const neuroDuration = 8 / animationSpeed;

    // Dynamic styles based on hover state
    const dynamicStyles = {
        brainScale: isHovered && interactive ? 1.05 : 1,
        brainOpacity: isHovered && interactive ? 0.95 : 0.9,
        nodeOpacity: isHovered && interactive ? 1 : 0.8,
        connectionOpacity: isHovered && interactive ? 0.8 : 0.6,
        glowFilter: glowEffect ? "url(#taytrom-glow)" : "",
        softGlowFilter: glowEffect ? "url(#taytrom-softGlow)" : "",
    };

    // Animation values based on mode
    const animationValues = {
        pulseScale: animationMode === "wave" ? "0.95;1.05;0.95" : "0.95;1.02;0.95",
        flowSpeed: animationMode === "flow" ? neuroDuration * 0.8 : neuroDuration,
        neuralPulse: animationMode === "wave" ? "1000;0;1000" : "1000;0",
        fadeValues: animationMode === "pulse" ? "0.3;1;0.3" : "0.4;0.8;0.4",
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
            {/* Definitions for gradients, filters and patterns */}
            <defs>
                <radialGradient id="backgroundGlow" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" stop-color="#FCE4EC" />
                    <stop offset="90%" stop-color="#FFF0F7" />
                    <stop offset="100%" stop-color="#FFEBF3" />
                </radialGradient>

                <linearGradient id="leftHemisphereGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#AD1457" />
                    <stop offset="50%" stop-color="#E91E63" />
                    <stop offset="100%" stop-color="#EC407A" />
                </linearGradient>

                <linearGradient id="rightHemisphereGradient" x1="100%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stop-color="#AD1457" />
                    <stop offset="50%" stop-color="#E91E63" />
                    <stop offset="100%" stop-color="#EC407A" />
                </linearGradient>

                <filter id="enhancedGlow" x="-20%" y="-20%" width="140%" height="140%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="15" result="blur" />
                    <feColorMatrix in="blur" type="matrix" values="0 0 0 0 1 0 0 0 0 0.4 0 0 0 0 0.7 0 0 0 1 0" result="glow" />
                    <feBlend in="SourceGraphic" in2="glow" mode="screen" />
                </filter>

                <filter id="neuronGlow" x="-50%" y="-50%" width="200%" height="200%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
                    <feColorMatrix in="blur" type="matrix" values="0 0 0 0 1 0 0 0 0 0.4 0 0 0 0 0.7 0 0 0 1 0" result="glow" />
                    <feBlend in="SourceGraphic" in2="glow" mode="screen" />
                </filter>
            </defs>

            {/* Enhanced Background Circle */}
            <circle cx="400" cy="400" r="350" fill="url(#backgroundGlow)" stroke="#C2185B" stroke-width="5" />

            {/* Pulsing Background Effect */}
            <circle cx="400" cy="400" r="300" fill="#E91E63" opacity="0.05">
                <animate attributeName="r" values="300;330;300" dur="6s" repeatCount="indefinite" />
                <animate attributeName="opacity" values="0.05;0.1;0.05" dur="6s" repeatCount="indefinite" />
            </circle>

            {/* Brain Hemispheres Base */}
            <path d="M400,200 C300,120 150,220 150,350 C150,480 220,600 400,650 C580,600 650,480 650,350 C650,220 500,120 400,200" fill="#E91E63" filter="url(#enhancedGlow)" />

            {/* Left Hemisphere Sulci and Gyri */}
            <path d="M400,220 C350,180 290,170 250,210 C210,250 230,310 220,350 C210,390 250,450 270,500 C290,550 350,610 400,630" fill="url(#leftHemisphereGradient)" stroke="#D81B60" stroke-width="4" />
            <path d="M220,350 C180,320 160,280 180,250 C200,220 250,220 280,240 C310,260 290,280 300,320 C310,360 280,400 250,430 C220,460 230,500 270,520" fill="none" stroke="#D81B60" stroke-width="6" />
            <path d="M250,250 C270,230 300,240 320,260 C340,280 330,310 350,330 C370,350 350,390 330,420 C310,450 330,490 370,500" fill="none" stroke="#AD1457" stroke-width="5" />
            <path d="M300,500 C330,520 350,540 330,580 C310,620 350,630 370,610" fill="none" stroke="#AD1457" stroke-width="4" />
            <path d="M270,280 C290,260 310,240 340,260" fill="none" stroke="#880E4F" stroke-width="2.5" />
            <path d="M240,340 C260,320 290,300 320,320" fill="none" stroke="#880E4F" stroke-width="2.5" />
            <path d="M280,490 C300,470 320,450 340,470" fill="none" stroke="#880E4F" stroke-width="2.5" />

            {/* Right Hemisphere Sulci and Gyri */}
            <path d="M400,220 C450,180 510,170 550,210 C590,250 570,310 580,350 C590,390 550,450 530,500 C510,550 450,610 400,630" fill="url(#rightHemisphereGradient)" stroke="#D81B60" stroke-width="4" />
            <path d="M580,350 C620,320 640,280 620,250 C600,220 550,220 520,240 C490,260 510,280 500,320 C490,360 520,400 550,430 C580,460 570,500 530,520" fill="none" stroke="#D81B60" stroke-width="6" />
            <path d="M550,250 C530,230 500,240 480,260 C460,280 470,310 450,330 C430,350 450,390 470,420 C490,450 470,490 430,500" fill="none" stroke="#AD1457" stroke-width="5" />
            <path d="M500,500 C470,520 450,540 470,580 C490,620 450,630 430,610" fill="none" stroke="#AD1457" stroke-width="4" />
            <path d="M530,280 C510,260 490,240 460,260" fill="none" stroke="#880E4F" stroke-width="2.5" />
            <path d="M560,340 C540,320 510,300 480,320" fill="none" stroke="#880E4F" stroke-width="2.5" />
            <path d="M520,490 C500,470 480,450 460,470" fill="none" stroke="#880E4F" stroke-width="2.5" />

            {/* Cerebellum with Enhanced Detail */}
            <path d="M320,600 C340,620 370,630 400,630 C430,630 460,620 480,600 C510,570 520,540 490,520 C460,500 440,510 400,510 C360,510 340,500 310,520 C280,540 290,570 320,600" fill="#EC407A" stroke="#D81B60" stroke-width="3" />
            <path d="M330,570 C350,580 380,590 400,590 C420,590 450,580 470,570" fill="none" stroke="#AD1457" stroke-width="3" />
            <path d="M340,590 C360,600 380,610 400,610 C420,610 440,600 460,590" fill="none" stroke="#AD1457" stroke-width="3" />
            <path d="M335,550 C355,560 385,570 400,570 C415,570 445,560 465,550" fill="none" stroke="#AD1457" stroke-width="2" />
            <path d="M345,530 C365,540 385,550 400,550 C415,550 435,540 455,530" fill="none" stroke="#AD1457" stroke-width="2" />

            {/* Brain Stem with Added Detail */}
            <path d="M380,630 C375,650 380,670 400,680 C420,670 425,650 420,630" fill="#EC407A" stroke="#D81B60" stroke-width="3" />
            <path d="M390,640 C395,655 405,655 410,640" fill="none" stroke="#AD1457" stroke-width="1.5" />

            {/* Enhanced Neural Network Connections */}
            <g stroke="#F8BBD0" stroke-width="1.5">
                {/* Left Hemisphere Neurons */}
                <path d="M250,280 C270,300 290,290 310,270" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3s" repeatCount="indefinite" />
                </path>
                <path d="M230,320 C240,350 260,370 290,350" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="4s" repeatCount="indefinite" />
                </path>
                <path d="M280,400 C300,380 310,390 300,410" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.5s" repeatCount="indefinite" />
                </path>
                <path d="M260,450 C290,460 320,450 330,420" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="5s" repeatCount="indefinite" />
                </path>
                <path d="M290,500 C320,490 340,480 350,450" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="4.5s" repeatCount="indefinite" />
                </path>
                <path d="M350,570 C330,550 340,530 360,520" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.2s" repeatCount="indefinite" />
                </path>

                {/* Right Hemisphere Neurons */}
                <path d="M550,280 C530,300 510,290 490,270" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.8s" repeatCount="indefinite" />
                </path>
                <path d="M570,320 C560,350 540,370 510,350" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="4.2s" repeatCount="indefinite" />
                </path>
                <path d="M520,400 C500,380 490,390 500,410" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.7s" repeatCount="indefinite" />
                </path>
                <path d="M540,450 C510,460 480,450 470,420" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="4.7s" repeatCount="indefinite" />
                </path>
                <path d="M510,500 C480,490 460,480 450,450" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="5.2s" repeatCount="indefinite" />
                </path>
                <path d="M450,570 C470,550 460,530 440,520" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.4s" repeatCount="indefinite" />
                </path>

                {/* Additional Neural Connections */}
                <path d="M280,300 C310,320 340,310 360,280" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="4.3s" repeatCount="indefinite" />
                </path>
                <path d="M520,300 C490,320 460,310 440,280" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="4.3s" repeatCount="indefinite" />
                </path>
                <path d="M300,380 C330,400 370,390 390,370" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.9s" repeatCount="indefinite" />
                </path>
                <path d="M500,380 C470,400 430,390 410,370" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.9s" repeatCount="indefinite" />
                </path>

                {/* Cross-Hemisphere Connections */}
                <path d="M380,250 C390,240 410,240 420,250" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="2.5s" repeatCount="indefinite" />
                </path>
                <path d="M370,300 C385,290 415,290 430,300" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.6s" repeatCount="indefinite" />
                </path>
                <path d="M360,350 C380,340 420,340 440,350" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="4.8s" repeatCount="indefinite" />
                </path>
                <path d="M370,400 C385,390 415,390 430,400" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.1s" repeatCount="indefinite" />
                </path>
                <path d="M380,450 C390,440 410,440 420,450" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="4.4s" repeatCount="indefinite" />
                </path>
                <path d="M385,500 C390,490 410,490 415,500" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="5.5s" repeatCount="indefinite" />
                </path>
                <path d="M375,275 C385,265 415,265 425,275" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="2.8s" repeatCount="indefinite" />
                </path>
                <path d="M365,325 C385,315 415,315 435,325" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="3.3s" repeatCount="indefinite" />
                </path>
                <path d="M365,375 C385,365 415,365 435,375" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="5.1s" repeatCount="indefinite" />
                </path>
                <path d="M375,425 C385,415 415,415 425,425" fill="none">
                    <animate attributeName="stroke" values="#F8BBD0;#FFCDD2;#F8BBD0" dur="4.1s" repeatCount="indefinite" />
                </path>
            </g>

            {/* Enhanced Neuron Points with Animations */}
            <g>
                {/* Left Hemisphere Neurons */}
                <circle cx="250" cy="280" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2s" repeatCount="indefinite" />
                </circle>
                <circle cx="270" cy="300" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3s" repeatCount="indefinite" />
                </circle>
                <circle cx="310" cy="270" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.5s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.5s" repeatCount="indefinite" />
                </circle>
                <circle cx="230" cy="320" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.2s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.2s" repeatCount="indefinite" />
                </circle>
                <circle cx="240" cy="350" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="4s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="4s" repeatCount="indefinite" />
                </circle>
                <circle cx="290" cy="350" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.5s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.5s" repeatCount="indefinite" />
                </circle>
                <circle cx="280" cy="400" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.8s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.8s" repeatCount="indefinite" />
                </circle>
                <circle cx="300" cy="410" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.2s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.2s" repeatCount="indefinite" />
                </circle>
                <circle cx="260" cy="450" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.4s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.4s" repeatCount="indefinite" />
                </circle>
                <circle cx="330" cy="420" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.8s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.8s" repeatCount="indefinite" />
                </circle>
                <circle cx="290" cy="500" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.6s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.6s" repeatCount="indefinite" />
                </circle>
                <circle cx="350" cy="450" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.1s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.1s" repeatCount="indefinite" />
                </circle>
                <circle cx="350" cy="570" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.9s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.9s" repeatCount="indefinite" />
                </circle>
                <circle cx="360" cy="520" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.4s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.4s" repeatCount="indefinite" />
                </circle>

                {/* Right Hemisphere Neurons */}
                <circle cx="550" cy="280" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.3s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.3s" repeatCount="indefinite" />
                </circle>
                <circle cx="530" cy="300" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.3s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.3s" repeatCount="indefinite" />
                </circle>
                <circle cx="490" cy="270" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.7s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.7s" repeatCount="indefinite" />
                </circle>
                <circle cx="570" cy="320" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.1s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.1s" repeatCount="indefinite" />
                </circle>
                <circle cx="560" cy="350" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="4.2s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="4.2s" repeatCount="indefinite" />
                </circle>
                <circle cx="510" cy="350" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.7s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.7s" repeatCount="indefinite" />
                </circle>
                <circle cx="520" cy="400" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.9s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.9s" repeatCount="indefinite" />
                </circle>
                <circle cx="500" cy="410" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.1s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.1s" repeatCount="indefinite" />
                </circle>
                <circle cx="540" cy="450" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.6s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.6s" repeatCount="indefinite" />
                </circle>
                <circle cx="470" cy="420" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.9s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.9s" repeatCount="indefinite" />
                </circle>
                <circle cx="510" cy="500" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.4s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.4s" repeatCount="indefinite" />
                </circle>
                <circle cx="450" cy="450" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.3s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.3s" repeatCount="indefinite" />
                </circle>
                <circle cx="450" cy="570" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="2.8s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="2.8s" repeatCount="indefinite" />
                </circle>
                <circle cx="440" cy="520" r="4" fill="#FFCDD2" filter="url(#neuronGlow)">
                    <animate attributeName="r" values="3;5;3" dur="3.6s" repeatCount="indefinite" />
                    <animate attributeName="opacity" values="0.7;1;0.7" dur="3.6s" repeatCount="indefinite" />
                </circle>
            </g>

            {/* Visual Sparks */}
            <g>
                <path d="M300,380 L320,370 L310,390 L330,385" stroke="#FFCDD2" stroke-width="1.5" fill="none">
                    <animate attributeName="opacity" values="0;1;0" dur="1.5s" repeatCount="indefinite" begin="0.2s"/>
                </path>
                <path d="M500,380 L480,370 L490,390 L470,385" stroke="#FFCDD2" stroke-width="1.5" fill="none">
                    <animate attributeName="opacity" values="0;1;0" dur="1.5s" repeatCount="indefinite" begin="0.7s"/>
                </path>
                <path d="M390,280 L400,260 L410,280 L400,300" stroke="#FFCDD2" stroke-width="1.5" fill="none">
                    <animate attributeName="opacity" values="0;1;0" dur="1.5s" repeatCount="indefinite" begin="1.2s"/>
                </path>
                <path d="M370,480 L390,490 L410,485 L430,490" stroke="#FFCDD2" stroke-width="1.5" fill="none">
                    <animate attributeName="opacity" values="0;1;0" dur="1.5s" repeatCount="indefinite" begin="0.5s"/>
                </path>
            </g>

            {/* Energy Bursts */}
            <circle cx="300" cy="300" r="5" fill="#FF80AB" opacity="0">
                <animate attributeName="r" values="5;15;5" dur="3s" repeatCount="indefinite" begin="1s"/>
                <animate attributeName="opacity" values="0;0.7;0" dur="3s" repeatCount="indefinite" begin="1s"/>
            </circle>
            <circle cx="500" cy="300" r="5" fill="#FF80AB" opacity="0">
                <animate attributeName="r" values="5;15;5" dur="3s" repeatCount="indefinite" begin="2s"/>
                <animate attributeName="opacity" values="0;0.7;0" dur="3s" repeatCount="indefinite" begin="2s"/>
            </circle>
            <circle cx="400" cy="400" r="8" fill="#FF80AB" opacity="0">
                <animate attributeName="r" values="8;20;8" dur="4s" repeatCount="indefinite" begin="0.5s"/>
                <animate attributeName="opacity" values="0;0.7;0" dur="4s" repeatCount="indefinite" begin="0.5s"/>
            </circle>
            <circle cx="350" cy="500" r="5" fill="#FF80AB" opacity="0">
                <animate attributeName="r" values="5;15;5" dur="3s" repeatCount="indefinite" begin="1.5s"/>
                <animate attributeName="opacity" values="0;0.7;0" dur="3s" repeatCount="indefinite" begin="1.5s"/>
            </circle>
            <circle cx="450" cy="500" r="5" fill="#FF80AB" opacity="0">
                <animate attributeName="r" values="5;15;5" dur="3s" repeatCount="indefinite" begin="2.5s"/>
                <animate attributeName="opacity" values="0;0.7;0" dur="3s" repeatCount="indefinite" begin="2.5s"/>
            </circle>

            {/* Advanced Pulse Animation */}
            <circle cx="400" cy="400" r="100" fill="#E91E63" opacity="0.1">
                <animate attributeName="r" values="100;150;100" dur="4s" repeatCount="indefinite"/>
                <animate attributeName="opacity" values="0.1;0.3;0.1" dur="4s" repeatCount="indefinite"/>
            </circle>

            {/* Radiating Energy Lines */}
            <g stroke="#F8BBD0" stroke-width="0.5" opacity="0.6">
                <path d="M400,400 L350,250">
                    <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="0.1s"/>
                </path>
                <path d="M400,400 L450,250">
                    <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="0.4s"/>
                </path>
                <path d="M400,400 L300,350">
                    <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="0.7s"/>
                </path>
                <path d="M400,400 L500,350">
                    <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="1s"/>
                </path>
                <path d="M400,400 L300,450">
                    <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="1.3s"/>
                </path>
                <path d="M400,400 L500,450">
                    <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="1.6s"/>
                </path>
                <path d="M400,400 L350,550">
                    <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="1.9s"/>
                </path>
                <path d="M400,400 L450,550">
                    <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s"
                             repeatCount="indefinite" begin="2.2s"/>
                </path>
            </g>

            {/* Title */}
            <text x="400" y="120" text-anchor="middle" fill="#880E4F" font-size="24"
                  font-weight="bold">
                The AI
            </text>
            <text x="400" y="150" text-anchor="middle" fill="#C2185B" font-size="16">
                Personalized Intelligence
            </text>
        </svg>
    );
};

export default TaytromAIPillar;