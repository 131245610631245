import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HypnoticSpiral.module.css';

// Import the spiral image directly
// If you're using create-react-app or a similar bundler
import spiralImage from './spiral.png'; // Adjust this path to where your image actually is

const HypnoticSpiral = ({ onExplore }) => {
    const navigate = useNavigate();
    const [isHovering, setIsHovering] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [imageError, setImageError] = useState(false);
    const spiralRef = useRef(null);

    // Handle loading state
    useEffect(() => {
        // Try to load the image
        const img = new Image();

        // Try multiple possible paths to the image
        // This helps if we're not sure where exactly the image is stored
        const possiblePaths = [
            spiralImage, // Directly imported (preferred method)
            './spiral.png',
            '/spiral.png',
            '/assets/spiral.png',
            '/public/spiral.png',
            '/src/assets/spiral.png',
            // Add more potential paths if needed
        ];

        let pathIndex = 0;
        const tryLoadImage = () => {
            if (pathIndex >= possiblePaths.length) {
                console.error('Could not load spiral image from any path');
                setImageError(true);
                setIsLoading(false);
                return;
            }

            img.src = possiblePaths[pathIndex];
            img.onload = () => {
                console.log('Loaded spiral image from:', img.src);
                setIsLoading(false);
                setImageError(false);

                // If this worked, let's use this path for the actual image
                if (spiralRef.current) {
                    spiralRef.current.src = img.src;
                }
            };

            img.onerror = () => {
                console.log('Failed to load from:', possiblePaths[pathIndex]);
                pathIndex++;
                tryLoadImage(); // Try the next path
            };
        };

        tryLoadImage();

        // Apply rotation speed
        if (spiralRef.current) {
            spiralRef.current.style.animationDuration = "20s";
        }
    }, []);

    // Custom navigation if provided, otherwise use default
    const handleExplore = () => {
        if (onExplore) {
            onExplore();
        } else {
            navigate('/demo');
        }
    };

    return (
        <div
            className={styles.spiralContainer}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            aria-label="Hypnotic spiral animation with Explore button"
        >
            {/* Fallback for when image fails to load */}
            {imageError && <div className={styles.fallbackSpiral}></div>}

            {/* Animated spiral image with GPU acceleration */}
            {!imageError && (
                    <img
                        ref={spiralRef}
                        alt="Spiral"
                        className={styles.animatedSpiral}
                        style={{
                            transform: 'translateZ(0)', // Force GPU acceleration
                        }}
                    />
            )}

            {/* Loading indicator */}
            {isLoading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}

            {/* Hover effect */}
            {isHovering && (
                <div className={styles.hoverGlow} aria-hidden="true"></div>
            )}

            {/* Explore button overlay */}
            <div className={`${styles.exploreButtonContainer} ${isLoading ? styles.loading : ''}`}>
                {/* Decorative elements */}
                <div className={styles.buttonBackdrop}></div>
                <div className={styles.buttonRings}></div>

                <button
                    onClick={handleExplore}
                    className={styles.exploreButton}
                    aria-label="Explore the demo"
                    disabled={isLoading}
                >
                    EXPLORE
                </button>
            </div>
        </div>
    );
};

export default HypnoticSpiral;