import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import {
    Brain, Zap, Keyboard, X, ChevronDown, ChevronUp, Save, Upload, Download, RotateCcw, RotateCw, Trash2,
    Settings, Moon, Sun, Lock, Unlock
} from 'lucide-react';

// Import hooks and contexts
import { useTheme } from '@/Phase1App.jsx';
import AIContextManager from '@/ai-scope/contexts/AIContextManager.js';

// Import specialist components
import Document from "@/writing-scope/specialists/features/primary-view/document-panel/compiler/Compiler_DocumentPanel_Specialists_Writing.jsx";
import AISuggestions from "@/writing-scope/specialists/features/primary-view/visualization-panel/bottom/ai-suggestions/AISuggestions_Specialists_Writing.jsx";
import StructuralListVisualization from "@/writing-scope/specialists/features/primary-view/visualization-panel/top/list-editor/ListEditor_Specialists_3_Sections_ProductionDemo_Specialists_Writing.jsx";
import AIComponent from "@/writing-scope/specialists/features/primary-view/ai-overlay/ai-component/AIComponent_Specialists_Writing.jsx";
import FixedAIAssistant
    from "@/writing-scope/specialists/features/primary-view/ai-overlay/ai-component/wrapper/Wrapper_AIComponent_Specialists_Writing.jsx";
import ExpandableToolbar
    from "@/writing-scope/specialists/utils/expandable-toolbar/ExpandableToolbar_Utils_Specialists_Writing.jsx";

// Import the consolidated ImportExportManager component
import ImportExportManager
    from "@/writing-scope/specialists/features/import-export/ImportExport_Features_Specialists_Writing.jsx";

// Import CSS module
import styles from './Compiler_Writing.module.css';

// Create memoized versions of the components
const MemoizedDocument = memo(Document);
const MemoizedAISuggestions = memo(AISuggestions);
const MemoizedStructuralListVisualization = memo(StructuralListVisualization);
const MemoizedAIComponent = memo(AIComponent);
const MemoizedFixedAIAssistant = memo(FixedAIAssistant);
const MemoizedExpandableToolbar = memo(ExpandableToolbar);

// Main Compiler_Writing component
const Compiler_Writing = (props) => {
    const { isDark } = useTheme() || { isDark: false };

    // Get orchestrator from props
    const orchestrator = props.orchestrator || null;
    const aiManagerRef = useRef(null);

    // Document structure state with clean initial state
    const [documentContent, setDocumentContent] = useState({
        title: "Untitled Document",
        sections: [
            {
                id: Date.now(),
                title: "New Section",
                type: "paragraph",
                content: "",
                parentId: null,
                position: 0
            }
        ]
    });

    // Component state
    // State for keyboard shortcuts
    const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false);
    const [documentNodes, setDocumentNodes] = useState([]);
    const [thoughts, setThoughts] = useState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null); // Set by useEffect after init
    const [expandedBullets, setExpandedBullets] = useState({});
    const [styleSettings, setStyleSettings] = useState({
        formality: 50,
        technicality: 50,
        emotionality: 50,
        clarity: 60,
        conciseness: 55,
        persuasiveness: 45
    });

    // AI state
    const [isAIProcessing, setIsAIProcessing] = useState(false);
    const [conversationId, setConversationId] = useState(`conv_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`);
    const [draggedSuggestion, setDraggedSuggestion] = useState(null);
    const [expansionHistory, setExpansionHistory] = useState([]);
    const [showDragModeHint, setShowDragModeHint] = useState(false);
    const [replacedContent, setReplacedContent] = useState({});
    const [showUndoNotification, setShowUndoNotification] = useState(false);
    const [lastReplacedSection, setLastReplacedSection] = useState(null);
    const [isAIComponentMinimized, setIsAIComponentMinimized] = useState(true);
    const [showAIComponent, setShowAIComponent] = useState(false);

    // New state for animation control
    const [listAnimationVisible, setListAnimationVisible] = useState(false);
    const [documentLoaded, setDocumentLoaded] = useState(false);

    // Document history for undo/redo
    const [documentHistory, setDocumentHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);

    // Reference to scroll section into view
    const sectionRefs = useRef({});

    // Initialize AI Context Manager if orchestrator is available
    useEffect(() => {
        if (!aiManagerRef.current && orchestrator) {
            aiManagerRef.current = new AIContextManager(orchestrator);
            aiManagerRef.current.initialize().then(() => {
                console.log("AI Context Manager initialized successfully");
                // Trigger document loaded animation after initialization
                setDocumentLoaded(true);
            }).catch(err => {
                console.error("Failed to initialize AI Context Manager:", err);
                // Still trigger document loaded animation even if AI fails
                setDocumentLoaded(true);
            });
        } else {
            // If no orchestrator, still trigger document loaded animation
            setDocumentLoaded(true);
        }

        // Initialize document history
        setDocumentHistory([documentContent]);
        setHistoryIndex(0);
    }, [orchestrator, documentContent]);

    // Activate list animations after a small delay
    useEffect(() => {
        if (documentLoaded) {
            const timer = setTimeout(() => {
                setListAnimationVisible(true);
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [documentLoaded]);

    // Generate document nodes based on current document content - memoize expensive calculation
    const generateDocumentNodes = useCallback(() => {
        return documentContent.sections.map(section => {
            // Calculate depth - root nodes are depth 1, children are depth 2, etc.
            const getDepth = (section) => {
                let depth = 1;
                let currentSection = section;

                while (currentSection.parentId) {
                    depth++;
                    currentSection = documentContent.sections.find(s => s.id === currentSection.parentId);
                    if (!currentSection) break; // Safety check
                }

                return depth;
            };

            const depth = getDepth(section);

            // Get child node IDs
            const childNodes = documentContent.sections
                .filter(s => s.parentId === section.id)
                .map(s => s.id);

            return {
                id: section.id,
                title: section.title,
                type: section.type,
                parentId: section.parentId,
                childNodes: childNodes,
                depth: depth,
                position: section.position || 0
            };
        });
    }, [documentContent.sections]);

    // Memoize document nodes calculation
    useEffect(() => {
        setDocumentNodes(generateDocumentNodes());
    }, [generateDocumentNodes]);

    // Set selectedSection to first section if not already set and steps are available
    useEffect(() => {
        if (!selectedSection && documentContent.sections.length > 0) {
            setSelectedSection(documentContent.sections[0].id);
        }
    }, [documentContent.sections, selectedSection]);

    // One-way synchronization from selectedNode to selectedSection
    useEffect(() => {
        if (selectedNode && selectedNode !== selectedSection) {
            setSelectedSection(selectedNode);
        }
    }, [selectedNode, selectedSection]);

    // Show drag mode hint after a delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowDragModeHint(true);

            // Hide after 5 seconds
            const hideTimer = setTimeout(() => {
                setShowDragModeHint(false);
            }, 5000);

            return () => clearTimeout(hideTimer);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    // Functions for document structure manipulation

    // Scroll a section into view
    const scrollSectionIntoView = useCallback((sectionId) => {
        if (sectionRefs.current[sectionId]) {
            sectionRefs.current[sectionId].scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, []);

    // Add current document state to history for undo/redo
    const addToHistory = useCallback(() => {
        // Don't add to history if content is the same as current
        if (historyIndex >= 0 &&
            JSON.stringify(documentHistory[historyIndex]) === JSON.stringify(documentContent)) {
            return;
        }

        // Add current state to history, truncating any future states if we're not at the end
        const newHistory = historyIndex >= 0
            ? [...documentHistory.slice(0, historyIndex + 1), {...documentContent}]
            : [{...documentContent}];

        setDocumentHistory(newHistory);
        setHistoryIndex(newHistory.length - 1);
    }, [documentContent, documentHistory, historyIndex]);

    // Undo document changes with animation
    const handleUndo = useCallback(() => {
        if (historyIndex > 0) {
            const previousState = documentHistory[historyIndex - 1];
            setDocumentContent({...previousState});
            setHistoryIndex(historyIndex - 1);

            // Show feedback
            showUndoRedoNotification("Action undone");
        }
    }, [historyIndex, documentHistory]);

    // Redo document changes with animation
    const handleRedo = useCallback(() => {
        if (historyIndex < documentHistory.length - 1) {
            const nextState = documentHistory[historyIndex + 1];
            setDocumentContent({...nextState});
            setHistoryIndex(historyIndex + 1);

            // Show feedback
            showUndoRedoNotification("Action redone");
        }
    }, [historyIndex, documentHistory]);

    // Enhanced notification system with ripple effect
    const showUndoRedoNotification = useCallback((message) => {
        const notificationDiv = document.createElement('div');
        notificationDiv.className = styles['taytrom-undo-redo-notification'];
        notificationDiv.textContent = message;

        // Add ripple effect for visual feedback
        const ripple = document.createElement('span');
        ripple.style.position = 'absolute';
        ripple.style.top = '0';
        ripple.style.left = '0';
        ripple.style.width = '100%';
        ripple.style.height = '100%';
        ripple.style.background = `radial-gradient(circle, ${isDark ? 'rgba(32, 178, 170, 0.2)' : 'rgba(32, 178, 170, 0.1)'} 0%, transparent 70%)`;
        ripple.style.transform = 'scale(0)';
        ripple.style.opacity = '1';
        ripple.style.borderRadius = 'inherit';
        ripple.style.animation = 'ripple 0.6s var(--tacs-ease-out-expo)';

        notificationDiv.appendChild(ripple);
        document.body.appendChild(notificationDiv);

        setTimeout(() => {
            if (document.body.contains(notificationDiv)) {
                document.body.removeChild(notificationDiv);
            }
        }, 2000);
    }, [isDark]);

    // Enhanced Save function with animation
    const handleSave = useCallback(() => {
        showUndoRedoNotification("Document saved!");

        // Create a visual pulse effect on the document panel
        const documentPanel = document.querySelector(`.${styles['taytrom-document-panel']}`);
        if (documentPanel) {
            documentPanel.style.boxShadow = `0 0 0 2px ${isDark ? 'rgba(32, 178, 170, 0.3)' : 'rgba(32, 178, 170, 0.2)'}`;
            setTimeout(() => {
                documentPanel.style.boxShadow = '';
            }, 500);
        }
    }, [showUndoRedoNotification, isDark]);

    // Handle keyboard shortcuts
    const handleShortcutTriggered = useCallback((e) => {
        // Handle global shortcuts
        if ((e.ctrlKey || e.metaKey) && e.key === 'z' && !e.shiftKey) {
            handleUndo();
        } else if ((e.ctrlKey || e.metaKey) && ((e.shiftKey && e.key === 'z') || e.key === 'y')) {
            handleRedo();
        } else if ((e.ctrlKey || e.metaKey) && e.key === 's') {
            e.preventDefault();
            handleSave();
        }
        // Add more shortcuts as needed
    }, [handleUndo, handleRedo, handleSave]);

    // Import/Export Functions

    // Function to handle successful import with animation
    const handleImportComplete = useCallback((importedDocument) => {
        if (importedDocument) {
            // Save current state to history
            addToHistory();

            // Temporarily reduce opacity of document panel for transition effect
            const documentPanel = document.querySelector(`.${styles['taytrom-document-panel']}`);
            if (documentPanel) {
                documentPanel.style.opacity = '0.6';
                documentPanel.style.transition = 'opacity 0.3s var(--tacs-ease-out-cubic)';

                setTimeout(() => {
                    setDocumentContent(importedDocument);
                    setTimeout(() => {
                        documentPanel.style.opacity = '1';
                    }, 50);
                }, 100);
            } else {
                setDocumentContent(importedDocument);
            }

            showUndoRedoNotification("Document imported successfully");
        }
    }, [addToHistory, showUndoRedoNotification]);

    // AI functions

    // Generate real AI suggestions with enhanced animations
    const generateAISuggestion = useCallback(async (sectionContent) => {
        if (!aiManagerRef.current || !orchestrator) return null;

        setIsAIProcessing(true);

        try {
            const prompt = `Based on this document section: "${sectionContent}", 
                     suggest one improvement or addition that would enhance the content.
                     Keep your suggestion concise, about 10-15 words.`;

            const options = {
                conversationId,
                moduleContext: {
                    title: 'Writing Platform',
                    focus: 'content enhancement'
                },
                thinkingEnabled: true,
                thinkingBudget: 2048
            };

            // Get user context if available
            const userId = orchestrator.authManager?.getCurrentUser()?.id;
            if (userId && orchestrator.personalizationBridge) {
                options.personalizationParams = orchestrator.personalizationBridge
                    .preparePersonalizationParams(userId);
            }

            const result = await orchestrator.handleUserMessage(prompt, options);

            if (result.success) {
                // Pulse animation for AI suggestions panel when new thought arrives
                const suggestionPanel = document.querySelector(`.${styles['taytrom-ai-suggestions-panel']}`);
                if (suggestionPanel) {
                    suggestionPanel.classList.add('suggestion-pulse');
                    setTimeout(() => {
                        suggestionPanel.classList.remove('suggestion-pulse');
                    }, 600);
                }

                return {
                    id: `thought_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`,
                    content: result.response.substring(0, 100), // Limit length for display
                    category: ['Structure', 'Content', 'Style'][Math.floor(Math.random() * 3)],
                    color: `#${Math.floor(Math.random()*16777215).toString(16)}`,
                    position: {
                        x: 20 + Math.random() * 60,
                        y: 20 + Math.random() * 60
                    }
                };
            }
            return null;
        } catch (error) {
            console.error("Error generating AI suggestion:", error);
            return null;
        } finally {
            setIsAIProcessing(false);
        }
    }, [orchestrator, conversationId]);

    // Trigger AI suggestions for the selected section
    useEffect(() => {
        const maxThoughts = 3; // Limit to 3 suggestions at a time
        if (selectedSection && thoughts.length < maxThoughts && !isAIProcessing && orchestrator) {
            const section = documentContent.sections.find(s => s.id === selectedSection);
            if (section) {
                generateAISuggestion(section.content).then(suggestion => {
                    if (suggestion) {
                        setThoughts(prev => [...prev, suggestion]);
                    }
                });
            }
        }
    }, [selectedSection, thoughts.length, isAIProcessing, orchestrator, documentContent.sections, generateAISuggestion]);

    // Enhanced handleAddThought with AI expansion and animations
    const handleAddThought = useCallback(async (thought) => {
        if (!orchestrator) return;

        const section = documentContent.sections.find(s => s.id === selectedSection);
        if (section) {
            // Add a visual pulse to the selected section
            const sectionElement = sectionRefs.current[selectedSection];
            if (sectionElement) {
                sectionElement.style.transition = 'background-color 0.3s var(--tacs-ease-out-cubic)';
                sectionElement.style.backgroundColor = isDark
                    ? 'rgba(32, 178, 170, 0.1)'
                    : 'rgba(32, 178, 170, 0.05)';
                setTimeout(() => {
                    sectionElement.style.backgroundColor = '';
                }, 500);
            }

            setIsAIProcessing(true);

            try {
                // Ask AI to expand the thought into proper content
                const prompt = `Expand this suggestion: "${thought.content}" 
                      into a full paragraph that would fit well in this section: "${section.title}" 
                      with existing content: "${section.content}"`;

                const options = {
                    conversationId,
                    thinkingEnabled: true
                };

                const result = await orchestrator.handleUserMessage(prompt, options);

                if (result.success) {
                    // Save to history before update
                    addToHistory();

                    // Update the section with AI-expanded content
                    const updatedSections = documentContent.sections.map(s =>
                        s.id === selectedSection
                            ? { ...s, content: s.content + "\n\n" + result.response }
                            : s
                    );

                    setDocumentContent(prev => ({
                        ...prev,
                        sections: updatedSections
                    }));

                    // Track expansion history for undo functionality
                    setExpansionHistory(prev => [...prev, {
                        sectionId: selectedSection,
                        thought: thought,
                        originalContent: section.content,
                        expandedContent: result.response,
                        method: 'append'
                    }]);
                } else {
                    // Fallback to just adding the raw thought
                    addToHistory();

                    const updatedSections = documentContent.sections.map(s =>
                        s.id === selectedSection
                            ? { ...s, content: s.content + "\n\n" + thought.content }
                            : s
                    );

                    setDocumentContent(prev => ({
                        ...prev,
                        sections: updatedSections
                    }));
                }

                // Remove the used thought with fade-out animation
                const thoughtElement = document.querySelector(`[data-thought-id="${thought.id}"]`);
                if (thoughtElement) {
                    thoughtElement.style.transition = 'opacity 0.3s var(--tacs-ease-out-cubic), transform 0.3s var(--tacs-ease-out-cubic)';
                    thoughtElement.style.opacity = '0';
                    thoughtElement.style.transform = 'scale(0.9)';
                    setTimeout(() => {
                        setThoughts(prev => prev.filter(t => t.id !== thought.id));
                    }, 300);
                } else {
                    setThoughts(prev => prev.filter(t => t.id !== thought.id));
                }
            } catch (error) {
                console.error("Error expanding thought:", error);
            } finally {
                setIsAIProcessing(false);
            }
        }
    }, [orchestrator, selectedSection, documentContent.sections, conversationId, addToHistory, isDark]);

    // Handle dropped suggestions for section replacement with enhanced animations
    const handleDroppedSuggestion = useCallback(async (sectionId, thought) => {
        if (!orchestrator) return;

        const section = documentContent.sections.find(s => s.id === sectionId);
        if (!section) return;

        setIsAIProcessing(true);
        setDraggedSuggestion(thought);

        // Store the original content for undo functionality
        const originalContent = section.content;
        setReplacedContent(prev => ({
            ...prev,
            [sectionId]: originalContent
        }));

        // Visual feedback for the drop target section
        const sectionElement = sectionRefs.current[sectionId];
        if (sectionElement) {
            sectionElement.style.transition = 'all 0.3s var(--tacs-ease-out-cubic)';
            sectionElement.style.boxShadow = `0 0 0 2px ${isDark ? 'rgba(32, 178, 170, 0.6)' : 'rgba(32, 178, 170, 0.4)'}`;
            sectionElement.style.backgroundColor = isDark
                ? 'rgba(32, 178, 170, 0.1)'
                : 'rgba(32, 178, 170, 0.05)';
        }

        try {
            // Modified prompt for section replacement that avoids intro text
            const prompt = `Rewrite this section completely based on this suggestion: "${thought.content}"
                    
                    Section type: ${section.type === 'bulletList' ? 'bullet list' : 'paragraph text'}
                    Section title: "${section.title}"
                    
                    Style guide:
                    - Maintain the ${section.type === 'bulletList' ? 'bullet-point structure' : 'paragraph format'}
                    - Match the tone and writing style of the document
                    - Do NOT include phrases like "Looking at the existing content" or any commentary
                    - Do NOT include introduction sentences about what you're doing
                    - Start directly with the content
                    - Be concise and specific
                    ${section.type === 'bulletList' ? '- Each bullet should start with "• "' : ''}`;

            const options = {
                conversationId,
                moduleContext: {
                    title: 'Section Replacement',
                    focus: 'complete rewrite'
                },
                thinkingEnabled: true,
                thinkingBudget: 3072, // Allow more thinking for better integration
                replacementMode: true // Flag to avoid meta-commentary
            };

            const result = await orchestrator.handleUserMessage(prompt, options);

            if (result.success) {
                // Format the response based on section type
                let formattedResponse = result.response;

                // For bullet steps, ensure proper formatting
                if (section.type === 'bulletList' && !formattedResponse.includes('•')) {
                    formattedResponse = formattedResponse
                        .split('\n')
                        .map(line => line.trim())
                        .filter(line => line.length > 0)
                        .map(line => line.startsWith('•') ? line : `• ${line}`)
                        .join('\n');
                }

                // Save to history before updating
                addToHistory();

                // Update the section with the new AI-generated content (complete replacement)
                const updatedSections = documentContent.sections.map(s =>
                    s.id === sectionId
                        ? { ...s, content: formattedResponse }
                        : s
                );

                // Animate the content change
                if (sectionElement) {
                    sectionElement.style.opacity = '0.5';
                    setTimeout(() => {
                        setDocumentContent(prev => ({
                            ...prev,
                            sections: updatedSections
                        }));
                        setTimeout(() => {
                            if (sectionElement) {
                                sectionElement.style.opacity = '1';
                                sectionElement.style.boxShadow = '';
                                sectionElement.style.backgroundColor = '';
                            }
                        }, 100);
                    }, 200);
                } else {
                    setDocumentContent(prev => ({
                        ...prev,
                        sections: updatedSections
                    }));
                }

                // Track replacement history for undo functionality
                setExpansionHistory(prev => [...prev, {
                    sectionId,
                    thought,
                    originalContent,
                    expandedContent: formattedResponse,
                    method: 'replace'
                }]);

                // Set the last replaced section for undo functionality
                setLastReplacedSection(sectionId);

                // Show undo notification
                setShowUndoNotification(true);
                setTimeout(() => {
                    setShowUndoNotification(false);
                }, 5000);
            } else {
                // Fallback on error
                const updatedSections = documentContent.sections.map(s =>
                    s.id === sectionId
                        ? { ...s, content: originalContent } // Restore original content
                        : s
                );

                setDocumentContent(prev => ({
                    ...prev,
                    sections: updatedSections
                }));

                // Reset visual styling
                if (sectionElement) {
                    sectionElement.style.boxShadow = '';
                    sectionElement.style.backgroundColor = '';
                }
            }

            // Remove the used thought from the suggestions panel with animation
            const thoughtElement = document.querySelector(`[data-thought-id="${thought.id}"]`);
            if (thoughtElement) {
                thoughtElement.style.transition = 'all 0.3s var(--tacs-ease-out-cubic)';
                thoughtElement.style.opacity = '0';
                thoughtElement.style.transform = 'scale(0.9)';
                setTimeout(() => {
                    setThoughts(prev => prev.filter(t => t.id !== thought.id));
                }, 300);
            } else {
                setThoughts(prev => prev.filter(t => t.id !== thought.id));
            }
        } catch (error) {
            console.error("Error replacing section content:", error);

            // Restore original content on error
            const updatedSections = documentContent.sections.map(s =>
                s.id === sectionId
                    ? { ...s, content: originalContent }
                    : s
            );

            setDocumentContent(prev => ({
                ...prev,
                sections: updatedSections
            }));

            // Reset visual styling
            if (sectionElement) {
                sectionElement.style.boxShadow = '';
                sectionElement.style.backgroundColor = '';
            }
        } finally {
            setIsAIProcessing(false);
            setDraggedSuggestion(null);
        }
    }, [orchestrator, documentContent.sections, conversationId, addToHistory, isDark]);

    // Enhanced replacement function with animations
    const handleReplaceSectionContent = useCallback((sectionIdOrNewContent, newContent) => {
        // Save current state to history
        addToHistory();

        // Case 1: Called with a complete document object - from GenerateFromTitle
        if (typeof sectionIdOrNewContent === 'object' && sectionIdOrNewContent !== null) {
            // This is a complete document object
            const newDocument = sectionIdOrNewContent;

            // Validate it has the expected structure
            if (newDocument.title && Array.isArray(newDocument.sections)) {
                // Add fade transition effect
                const documentPanel = document.querySelector(`.${styles['taytrom-document-panel']}`);
                if (documentPanel) {
                    documentPanel.style.transition = 'opacity 0.3s var(--tacs-ease-out-cubic)';
                    documentPanel.style.opacity = '0.5';

                    setTimeout(() => {
                        setDocumentContent(newDocument);
                        setTimeout(() => {
                            documentPanel.style.opacity = '1';
                        }, 100);
                    }, 200);
                } else {
                    setDocumentContent(newDocument);
                }

                showUndoRedoNotification("Document generated successfully");
                return true;
            } else {
                console.error("Invalid document structure:", newDocument);
                return false;
            }
        }

        // Case 2: Called with a sectionId and new content - regular section update
        if (typeof sectionIdOrNewContent === 'number' || typeof sectionIdOrNewContent === 'string') {
            const sectionId = sectionIdOrNewContent;

            // Find the section
            const section = documentContent.sections.find(s => s.id === sectionId);
            if (!section) {
                console.error("Section not found:", sectionId);
                return false;
            }

            // Store original content for potential undo
            const originalContent = section.content;
            setReplacedContent(prev => ({
                ...prev,
                [sectionId]: originalContent
            }));

            // Add visual transition effect
            const sectionElement = sectionRefs.current[sectionId];
            if (sectionElement) {
                sectionElement.style.transition = 'opacity 0.3s var(--tacs-ease-out-cubic)';
                sectionElement.style.opacity = '0.5';

                setTimeout(() => {
                    // Update section with new content
                    const updatedSections = documentContent.sections.map(s =>
                        s.id === sectionId
                            ? { ...s, content: newContent }
                            : s
                    );

                    setDocumentContent(prev => ({
                        ...prev,
                        sections: updatedSections
                    }));

                    setTimeout(() => {
                        if (sectionElement) {
                            sectionElement.style.opacity = '1';
                        }
                    }, 100);
                }, 200);
            } else {
                // Update section with new content without animation
                const updatedSections = documentContent.sections.map(s =>
                    s.id === sectionId
                        ? { ...s, content: newContent }
                        : s
                );

                setDocumentContent(prev => ({
                    ...prev,
                    sections: updatedSections
                }));
            }

            // Set the last replaced section for undo functionality
            setLastReplacedSection(sectionId);

            return true;
        }

        console.error("Invalid parameters for handleReplaceSectionContent");
        return false;
    }, [documentContent.sections, addToHistory, showUndoRedoNotification]);

    // Apply suggestion from AI component with animation
    const handleApplySuggestion = useCallback((suggestion) => {
        if (selectedSection) {
            const section = documentContent.sections.find(s => s.id === selectedSection);
            if (section) {
                // Save state to history before updating
                addToHistory();

                // Add visual feedback
                const sectionElement = sectionRefs.current[selectedSection];
                if (sectionElement) {
                    sectionElement.style.transition = 'all 0.3s var(--tacs-ease-out-cubic)';
                    sectionElement.style.backgroundColor = isDark
                        ? 'rgba(32, 178, 170, 0.1)'
                        : 'rgba(32, 178, 170, 0.05)';

                    setTimeout(() => {
                        // Add the suggestion to the content
                        const updatedSections = documentContent.sections.map(s =>
                            s.id === selectedSection
                                ? { ...s, content: s.content + "\n\n" + suggestion.content }
                                : s
                        );

                        setDocumentContent(prev => ({
                            ...prev,
                            sections: updatedSections
                        }));

                        setTimeout(() => {
                            if (sectionElement) {
                                sectionElement.style.backgroundColor = '';
                            }
                        }, 300);
                    }, 200);
                } else {
                    // No animation if element not found
                    const updatedSections = documentContent.sections.map(s =>
                        s.id === selectedSection
                            ? { ...s, content: s.content + "\n\n" + suggestion.content }
                            : s
                    );

                    setDocumentContent(prev => ({
                        ...prev,
                        sections: updatedSections
                    }));
                }
            }
        }
    }, [selectedSection, documentContent.sections, addToHistory, isDark]);

    // Handle expanding section from AI component with animation
    const handleExpandSection = useCallback((sectionId, additionalContent) => {
        const section = documentContent.sections.find(s => s.id === sectionId);
        if (!section) return;

        // Save to history before updating
        addToHistory();

        // Add visual feedback
        const sectionElement = sectionRefs.current[sectionId];
        if (sectionElement) {
            sectionElement.style.transition = 'all 0.3s var(--tacs-ease-out-cubic)';
            sectionElement.style.backgroundColor = isDark
                ? 'rgba(32, 178, 170, 0.1)'
                : 'rgba(32, 178, 170, 0.05)';

            setTimeout(() => {
                // Update section by appending content
                const updatedSections = documentContent.sections.map(s =>
                    s.id === sectionId
                        ? { ...s, content: s.content + "\n\n" + additionalContent }
                        : s
                );

                setDocumentContent(prev => ({
                    ...prev,
                    sections: updatedSections
                }));

                setTimeout(() => {
                    if (sectionElement) {
                        sectionElement.style.backgroundColor = '';
                    }
                }, 300);
            }, 200);
        } else {
            // No animation if element not found
            const updatedSections = documentContent.sections.map(s =>
                s.id === sectionId
                    ? { ...s, content: s.content + "\n\n" + additionalContent }
                    : s
            );

            setDocumentContent(prev => ({
                ...prev,
                sections: updatedSections
            }));
        }

        // Track expansion for undo
        setExpansionHistory(prev => [...prev, {
            sectionId,
            originalContent: section.content,
            expandedContent: additionalContent,
            method: 'append'
        }]);
    }, [documentContent.sections, addToHistory, isDark]);

    // Expand bullet point with AI and animation
    const handleExpandBullet = useCallback(async (sectionId, bulletIndex) => {
        const section = documentContent.sections.find(s => s.id === sectionId);
        if (section && section.type === "bulletList" && orchestrator) {
            const bullets = section.content.split('\n');
            const bullet = bullets[bulletIndex];
            const expandedKey = `${sectionId}-${bulletIndex}`;

            if (!expandedBullets[expandedKey]) {
                // Add visual feedback for the expanding bullet
                const bulletElement = document.querySelector(`[data-bullet-id="${expandedKey}"]`);
                if (bulletElement) {
                    bulletElement.style.transition = 'all 0.3s var(--tacs-ease-out-cubic)';
                    bulletElement.style.backgroundColor = isDark
                        ? 'rgba(32, 178, 170, 0.1)'
                        : 'rgba(32, 178, 170, 0.05)';
                }

                setIsAIProcessing(true);

                try {
                    const prompt = `Expand this bullet point into a detailed explanation (2-3 sentences):
                        "${bullet}"`;

                    const options = {
                        conversationId,
                        moduleContext: {
                            title: 'Bullet Expansion',
                            focus: 'detail development'
                        }
                    };

                    const result = await orchestrator.handleUserMessage(prompt, options);

                    if (result.success) {
                        // Animate the expansion
                        if (bulletElement) {
                            bulletElement.style.opacity = '0.5';

                            setTimeout(() => {
                                setExpandedBullets(prev => ({
                                    ...prev,
                                    [expandedKey]: result.response
                                }));

                                setTimeout(() => {
                                    if (bulletElement) {
                                        bulletElement.style.opacity = '1';
                                        bulletElement.style.backgroundColor = '';
                                    }
                                }, 200);
                            }, 200);
                        } else {
                            setExpandedBullets(prev => ({
                                ...prev,
                                [expandedKey]: result.response
                            }));
                        }
                    }
                } catch (error) {
                    console.error("Error expanding bullet:", error);
                    // Reset styles on error
                    if (bulletElement) {
                        bulletElement.style.backgroundColor = '';
                    }
                } finally {
                    setIsAIProcessing(false);
                }
            } else {
                // Toggle off if already expanded with animation
                const bulletElement = document.querySelector(`[data-bullet-id="${expandedKey}"]`);
                if (bulletElement) {
                    const expandedContent = document.querySelector(`[data-expanded-content="${expandedKey}"]`);
                    if (expandedContent) {
                        expandedContent.style.transition = 'all 0.3s var(--tacs-ease-out-cubic)';
                        expandedContent.style.opacity = '0';
                        expandedContent.style.maxHeight = '0';

                        setTimeout(() => {
                            setExpandedBullets(prev => {
                                const newState = { ...prev };
                                delete newState[expandedKey];
                                return newState;
                            });
                        }, 300);
                    } else {
                        setExpandedBullets(prev => {
                            const newState = { ...prev };
                            delete newState[expandedKey];
                            return newState;
                        });
                    }
                } else {
                    setExpandedBullets(prev => {
                        const newState = { ...prev };
                        delete newState[expandedKey];
                        return newState;
                    });
                }
            }
        }
    }, [documentContent.sections, expandedBullets, orchestrator, conversationId, isDark]);

    // Undo last expansion or replacement with animation
    const undoLastExpansion = useCallback(() => {
        if (expansionHistory.length === 0) return;

        // Save current state to history
        addToHistory();

        const lastExpansion = expansionHistory[expansionHistory.length - 1];
        const section = documentContent.sections.find(s => s.id === lastExpansion.sectionId);
        if (!section) return;

        // Add visual feedback
        const sectionElement = sectionRefs.current[lastExpansion.sectionId];
        if (sectionElement) {
            sectionElement.style.transition = 'all 0.3s var(--tacs-ease-out-cubic)';
            sectionElement.style.backgroundColor = isDark
                ? 'rgba(255, 90, 90, 0.1)'
                : 'rgba(255, 90, 90, 0.05)';

            setTimeout(() => {
                // Handle different undo types based on the method
                if (lastExpansion.method === 'replace') {
                    // For replacements, restore the entire original content
                    const updatedSections = documentContent.sections.map(s =>
                        s.id === lastExpansion.sectionId
                            ? {...s, content: lastExpansion.originalContent}
                            : s
                    );

                    setDocumentContent(prev => ({
                        ...prev,
                        sections: updatedSections
                    }));
                } else {
                    // For additions, remove the appended content
                    const updatedContent = section.content.replace("\n\n" + lastExpansion.expandedContent, "");

                    const updatedSections = documentContent.sections.map(s =>
                        s.id === lastExpansion.sectionId
                            ? {...s, content: updatedContent}
                            : s
                    );

                    setDocumentContent(prev => ({
                        ...prev,
                        sections: updatedSections
                    }));
                }

                setTimeout(() => {
                    if (sectionElement) {
                        sectionElement.style.backgroundColor = '';
                    }
                }, 300);
            }, 200);
        } else {
            // No animation if element not found
            if (lastExpansion.method === 'replace') {
                // For replacements, restore the entire original content
                const updatedSections = documentContent.sections.map(s =>
                    s.id === lastExpansion.sectionId
                        ? {...s, content: lastExpansion.originalContent}
                        : s
                );

                setDocumentContent(prev => ({
                    ...prev,
                    sections: updatedSections
                }));
            } else {
                // For additions, remove the appended content
                const updatedContent = section.content.replace("\n\n" + lastExpansion.expandedContent, "");

                const updatedSections = documentContent.sections.map(s =>
                    s.id === lastExpansion.sectionId
                        ? {...s, content: updatedContent}
                        : s
                );

                setDocumentContent(prev => ({
                    ...prev,
                    sections: updatedSections
                }));
            }
        }

        // If this was a drag expansion or replacement, add the thought back to suggestions
        if (lastExpansion.method === 'drag' || lastExpansion.method === 'replace') {
            if (lastExpansion.thought) {
                setThoughts(prev => [...prev, lastExpansion.thought]);
            }
        }

        // Update history
        setExpansionHistory(prev => prev.slice(0, -1));

        // Show undo notification
        const undoAction = lastExpansion.method === 'replace' ? 'replacement' : 'expansion';
        showUndoRedoNotification(`Last ${undoAction} undone`);
    }, [expansionHistory, documentContent.sections, addToHistory, showUndoRedoNotification, isDark]);

    // Toggle AI Chat Component with enhanced animations
    const toggleAIComponent = useCallback(() => {
        // If component isn't visible, show it
        if (!showAIComponent) {
            setShowAIComponent(true);
            setIsAIComponentMinimized(false);

            // Pulse animation for AI icon in header
            const aiIcon = document.querySelector(`.${styles['taytrom-header']} [data-ai-icon="true"]`);
            if (aiIcon) {
                aiIcon.style.animation = 'pulse-icon 1s var(--tacs-ease-in-out-cubic)';
                setTimeout(() => {
                    aiIcon.style.animation = '';
                }, 1000);
            }
        } else {
            // If it's visible, toggle minimized state with animation
            setIsAIComponentMinimized(!isAIComponentMinimized);
        }

        // Show feedback to user
        showUndoRedoNotification(
            showAIComponent ?
                (isAIComponentMinimized ? "AI Assistant maximized" : "AI Assistant minimized") :
                "AI Assistant opened"
        );
    }, [showAIComponent, isAIComponentMinimized, showUndoRedoNotification]);

    // Handle keyboard shortcuts globally
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Toggle keyboard shortcuts panel with Ctrl+K
            if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'k') {
                e.preventDefault();
                setShowKeyboardShortcuts(prevState => !prevState);
                return;
            }

            // Toggle AI assistant with Alt+A
            if (e.altKey && e.key.toLowerCase() === 'a') {
                e.preventDefault();
                toggleAIComponent();
                return;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [toggleAIComponent]);

    // Apply keyboard shortcuts for undo/redo
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Ctrl/Cmd + Z: Undo
            if ((e.ctrlKey || e.metaKey) && e.key === 'z' && !e.shiftKey) {
                e.preventDefault();
                handleUndo();
            }
            // Ctrl/Cmd + Shift + Z or Ctrl/Cmd + Y: Redo
            else if ((e.ctrlKey || e.metaKey) && ((e.shiftKey && e.key === 'z') || e.key === 'y')) {
                e.preventDefault();
                handleRedo();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [handleUndo, handleRedo]);

    // Clear document function with enhanced confirmation dialog
    const handleClear = useCallback(() => {
        // Create a custom confirmation dialog instead of using window.confirm
        const dialogElement = document.createElement('div');
        dialogElement.className = styles['taytrom-import-export'];

        const dialogContent = document.createElement('div');
        dialogContent.className = styles['taytrom-import-export-dialog'];

        // Dialog header
        const dialogHeader = document.createElement('div');
        dialogHeader.className = styles['taytrom-import-export-header'];

        const dialogTitle = document.createElement('div');
        dialogTitle.className = styles['taytrom-import-export-title'];
        dialogTitle.textContent = 'Clear Document';

        const closeButton = document.createElement('button');
        closeButton.className = styles['taytrom-import-export-close'];
        closeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>';

        dialogHeader.appendChild(dialogTitle);
        dialogHeader.appendChild(closeButton);

        // Dialog content
        const dialogContentBody = document.createElement('div');
        dialogContentBody.className = styles['taytrom-import-export-content'];
        dialogContentBody.innerHTML = '<p>Are you sure you want to clear the entire document? This action cannot be undone.</p>';

        // Dialog actions
        const dialogActions = document.createElement('div');
        dialogActions.className = styles['taytrom-import-export-actions'];

        const cancelButton = document.createElement('button');
        cancelButton.className = `${styles['taytrom-button']} ${styles['taytrom-button-secondary']}`;
        cancelButton.textContent = 'Cancel';

        const confirmButton = document.createElement('button');
        confirmButton.className = `${styles['taytrom-button']} ${styles['taytrom-button-primary']}`;
        confirmButton.textContent = 'Clear Document';

        dialogActions.appendChild(cancelButton);
        dialogActions.appendChild(confirmButton);

        // Assemble dialog
        dialogContent.appendChild(dialogHeader);
        dialogContent.appendChild(dialogContentBody);
        dialogContent.appendChild(dialogActions);
        dialogElement.appendChild(dialogContent);

        // Add to DOM
        document.body.appendChild(dialogElement);

        // Add event listeners
        const closeDialog = () => {
            // Fade out animation
            dialogElement.style.opacity = '0';
            dialogContent.style.transform = 'scale(0.95)';
            dialogContent.style.transition = 'transform 0.2s var(--tacs-ease-in-cubic)';
            dialogElement.style.transition = 'opacity 0.2s var(--tacs-ease-in-cubic)';

            setTimeout(() => {
                document.body.removeChild(dialogElement);
            }, 200);
        };

        const handleConfirm = () => {
            // Create an empty document structure
            const emptyDocument = {
                title: "New Document",
                sections: [
                    {
                        id: Date.now(),
                        title: "Introduction",
                        content: "Start writing your document here...",
                        type: "introduction",
                        parentId: null,
                        position: 0
                    }
                ]
            };

            // Fade out the document panel
            const documentPanel = document.querySelector(`.${styles['taytrom-document-panel']}`);
            if (documentPanel) {
                documentPanel.style.transition = 'opacity 0.3s var(--tacs-ease-out-cubic)';
                documentPanel.style.opacity = '0.5';

                setTimeout(() => {
                    // Set the document content to the empty state
                    setDocumentContent(emptyDocument);

                    // Reset history
                    setDocumentHistory([emptyDocument]);
                    setHistoryIndex(0);

                    // Select the first section
                    setSelectedSection(emptyDocument.sections[0].id);

                    setTimeout(() => {
                        documentPanel.style.opacity = '1';
                    }, 100);
                }, 200);
            } else {
                // Set the document content to the empty state
                setDocumentContent(emptyDocument);

                // Reset history
                setDocumentHistory([emptyDocument]);
                setHistoryIndex(0);

                // Select the first section
                setSelectedSection(emptyDocument.sections[0].id);
            }

            // Show feedback
            showUndoRedoNotification("Document cleared");

            closeDialog();
        };

        closeButton.addEventListener('click', closeDialog);
        cancelButton.addEventListener('click', closeDialog);
        confirmButton.addEventListener('click', handleConfirm);

        // Add click outside to close
        dialogElement.addEventListener('click', (e) => {
            if (e.target === dialogElement) {
                closeDialog();
            }
        });

        // Add entrance animation
        requestAnimationFrame(() => {
            dialogContent.style.opacity = '0';
            dialogContent.style.transform = 'scale(0.95) translateY(10px)';
            dialogContent.style.transition = 'opacity 0.3s var(--tacs-ease-out-cubic), transform 0.3s var(--tacs-ease-out-cubic)';

            requestAnimationFrame(() => {
                dialogContent.style.opacity = '1';
                dialogContent.style.transform = 'scale(1) translateY(0)';
            });
        });
    }, [showUndoRedoNotification]);

    // Handle document update events
    useEffect(() => {
        // This would be replaced with your actual event system
        if (orchestrator) {
            const handleDocumentUpdate = (notification) => {
                console.log("Document update notification received:", notification);

                // Handle direct document updates if needed
                if (notification.type === 'DOCUMENT_UPDATE' && notification.content) {
                    console.log("Processing document update:", notification.content);
                    handleReplaceSectionContent(notification.content);
                }
            };

            // Register with orchestrator or event system
            // This is a placeholder - implement according to your actual event system
            const cleanup = () => {
                // Cleanup function
            };

            return cleanup;
        }
    }, [orchestrator, handleReplaceSectionContent]);

    // Export function with animations
    const handleExport = useCallback((format = 'html') => {
        // Add visual feedback when exporting
        const buttonElement = document.querySelector(`[data-export-format="${format}"]`);
        if (buttonElement) {
            buttonElement.style.transition = 'all 0.3s var(--tacs-ease-out-cubic)';
            buttonElement.style.backgroundColor = isDark
                ? 'rgba(32, 178, 170, 0.2)'
                : 'rgba(32, 178, 170, 0.1)';

            setTimeout(() => {
                buttonElement.style.backgroundColor = '';
            }, 500);
        }

        showUndoRedoNotification(`Exporting as ${format.toUpperCase()}...`);
        // In a real implementation, we would call the export functionality from the ImportExportManager
    }, [showUndoRedoNotification, isDark]);

    // Import function with animations
    const handleImport = useCallback((format = 'docx') => {
        // Add visual feedback when importing
        const buttonElement = document.querySelector(`[data-import-format="${format}"]`);
        if (buttonElement) {
            buttonElement.style.transition = 'all 0.3s var(--tacs-ease-out-cubic)';
            buttonElement.style.backgroundColor = isDark
                ? 'rgba(32, 178, 170, 0.2)'
                : 'rgba(32, 178, 170, 0.1)';

            setTimeout(() => {
                buttonElement.style.backgroundColor = '';
            }, 500);
        }

        showUndoRedoNotification(`Importing ${format.toUpperCase()} file...`);
        // In a real implementation, we would call the import functionality from the ImportExportManager
    }, [showUndoRedoNotification, isDark]);

    // Keyboard shortcuts dialog with enhanced rendering
    const renderKeyboardShortcutsDialog = () => {
        if (!showKeyboardShortcuts) return null;

        return (
            <div className={styles['taytrom-keyboard-shortcuts']}>
                <div className={styles['taytrom-keyboard-shortcuts-header']}>
                    <div className={styles['taytrom-keyboard-shortcuts-title']}>Keyboard Shortcuts</div>
                    <button
                        className={styles['taytrom-keyboard-shortcuts-close']}
                        onClick={() => setShowKeyboardShortcuts(false)}
                        aria-label="Close keyboard shortcuts"
                    >
                        <X size={20} />
                    </button>
                </div>

                <div className={styles['taytrom-keyboard-shortcut-group']}>
                    <div className={styles['taytrom-keyboard-shortcut-group-title']}>Document Actions</div>
                    <div className={styles['taytrom-keyboard-shortcut-item']}>
                        <span className={styles['taytrom-keyboard-shortcut-description']}>Save document</span>
                        <span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>Ctrl</span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>S</span>
                        </span>
                    </div>
                    <div className={styles['taytrom-keyboard-shortcut-item']}>
                        <span className={styles['taytrom-keyboard-shortcut-description']}>Undo</span>
                        <span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>Ctrl</span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>Z</span>
                        </span>
                    </div>
                    <div className={styles['taytrom-keyboard-shortcut-item']}>
                        <span className={styles['taytrom-keyboard-shortcut-description']}>Redo</span>
                        <span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>Ctrl</span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>Shift</span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>Z</span>
                        </span>
                    </div>
                </div>

                <div className={styles['taytrom-keyboard-shortcut-group']}>
                    <div className={styles['taytrom-keyboard-shortcut-group-title']}>Navigation</div>
                    <div className={styles['taytrom-keyboard-shortcut-item']}>
                        <span className={styles['taytrom-keyboard-shortcut-description']}>Show keyboard shortcuts</span>
                        <span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>Ctrl</span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>K</span>
                        </span>
                    </div>
                </div>

                <div className={styles['taytrom-keyboard-shortcut-group']}>
                    <div className={styles['taytrom-keyboard-shortcut-group-title']}>AI Assistant</div>
                    <div className={styles['taytrom-keyboard-shortcut-item']}>
                        <span className={styles['taytrom-keyboard-shortcut-description']}>Toggle AI assistant</span>
                        <span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>Alt</span>
                            <span className={styles['taytrom-keyboard-shortcut-key']}>A</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`${styles['taytrom-container']} ${isDark ? styles['taytrom-container-dark'] : styles['taytrom-container-light']}`}
            data-document-loaded={documentLoaded ? 'true' : 'false'}
        >
            {/* Header with title */}
            <header className={styles['taytrom-header']}>
                <h1 className={styles['taytrom-heading-1']}>
                    Taytrom's Writing Platform
                </h1>

                {/* Right side buttons group */}
                <div className={styles['taytrom-header-buttons']}>
                    {/* Use the enhanced ExpandableToolbar with keyboard shortcuts */}
                    <MemoizedExpandableToolbar
                        onClear={handleClear}
                        onImport={handleImport}
                        onExport={handleExport}
                        onUndo={handleUndo}
                        onRedo={handleRedo}
                        onSave={handleSave}
                        canUndo={historyIndex > 0}
                        canRedo={historyIndex < documentHistory.length - 1}
                        onToggleAI={toggleAIComponent}
                        isAIOpen={showAIComponent}
                        isDark={isDark}
                        position="header"
                        defaultExpanded={false}
                        showKeyboardShortcutsButton={true}
                        onShortcutTriggered={handleShortcutTriggered}
                        allowKeyboardCustomization={true}
                        orchestrator={orchestrator}
                    />


                </div>
            </header>

            {/* Drag mode hint tooltip */}
            {showDragModeHint && (
                <div className={styles['taytrom-drag-mode-hint']}>
                    <div className={styles['taytrom-drag-mode-hint-header']}>
                        <Zap size={18} className={styles['taytrom-drag-mode-hint-icon']} />
                        <span className={styles['taytrom-drag-mode-hint-title']}>AI Magic: Drag Suggestions to Transform Content!</span>
                    </div>
                    <p className={styles['taytrom-drag-mode-hint-text']}>
                        Drag any suggestion bubble directly onto a document section to
                        transform it with AI-enhanced writing.
                    </p>
                </div>
            )}

            {/* Undo notification for section replacements */}
            {showUndoNotification && (
                <div className={`${styles['taytrom-undo-notification']} ${isDark ? styles['taytrom-container-dark'] : styles['taytrom-container-light']}`}>
                    <Zap size={20} className={styles['taytrom-undo-notification-icon']} />
                    <div className={styles['taytrom-undo-notification-content']}>
                        <div className={styles['taytrom-undo-notification-title']}>Section transformed</div>
                        <div className={styles['taytrom-undo-notification-subtitle']}>Content has been replaced with AI-enhanced writing</div>
                    </div>
                    <button
                        onClick={undoLastExpansion}
                        className={`${styles['taytrom-undo-button']} ${isDark ? styles['taytrom-container-dark'] : styles['taytrom-container-light']}`}
                    >
                        <span>Undo</span>
                    </button>
                </div>
            )}

            {/* Main content area with 2-column layout */}
            <div className={styles['taytrom-main-content']}>
                {/* Left panel - Interactive Document (50% width) */}
                <div className={styles['taytrom-document-panel']}>
                    <MemoizedDocument
                        documentContent={documentContent}
                        setDocumentContent={(content) => {
                            addToHistory();
                            setDocumentContent(content);
                        }}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                        expandedBullets={expandedBullets}
                        setExpandedBullets={setExpandedBullets}
                        handleExpandBullet={handleExpandBullet}
                        onDropSuggestion={handleDroppedSuggestion}
                        isDark={isDark}
                        styleSettings={styleSettings}
                        sectionRefs={sectionRefs}
                        isAnimated={documentLoaded}
                    />
                </div>

                {/* Right panel - Split between List Visualization (top) and AI Suggestions (bottom) */}
                <div className={styles['taytrom-visualization-panel']}>
                    {/* Top section - Structural List Visualization */}
                    <div className={styles['taytrom-structural-list-panel']}>
                        <MemoizedStructuralListVisualization
                            documentContent={documentContent}
                            setDocumentContent={(content) => {
                                addToHistory();
                                setDocumentContent(content);
                            }}
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                            onSuccess={(message) => showUndoRedoNotification(message)}
                            isDark={isDark}
                            scrollSectionIntoView={scrollSectionIntoView}
                            isAnimated={listAnimationVisible}
                        />
                    </div>

                    {/* Bottom section - AI Suggestions */}
                    <div className={styles['taytrom-ai-suggestions-panel']}>
                        <MemoizedAISuggestions
                            thoughts={thoughts}
                            handleAddThought={handleAddThought}
                            setThoughts={setThoughts}
                            isProcessing={isAIProcessing}
                            generateNewThought={() => {
                                const section = documentContent.sections.find(s => s.id === selectedSection);
                                if (section) {
                                    generateAISuggestion(section.content).then(suggestion => {
                                        if (suggestion) {
                                            setThoughts(prev => [...prev, suggestion]);
                                        }
                                    });
                                }
                            }}
                            generateMultipleThoughts={() => {
                                const generateSequentially = async () => {
                                    for (let i = 0; i < 3; i++) {
                                        const section = documentContent.sections.find(s => s.id === selectedSection);
                                        if (section) {
                                            if (i > 0) {
                                                // Small delay between generations for better UX
                                                await new Promise(resolve => setTimeout(resolve, 500));
                                            }
                                            generateAISuggestion(section.content).then(suggestion => {
                                                if (suggestion) {
                                                    setThoughts(prev => [...prev, suggestion]);
                                                }
                                            });
                                        }
                                    }
                                };

                                generateSequentially();
                            }}
                            isDark={isDark}
                            useInternalButtons={true}
                            isAnimated={documentLoaded}
                        />
                    </div>
                </div>
            </div>

            {/* Keyboard shortcuts dialog */}
            {renderKeyboardShortcutsDialog()}

            {/* AI Assistant */}
            <div className={`
                ${styles['taytrom-fixed-ai-assistant']} 
                ${showAIComponent ? styles['taytrom-fixed-ai-assistant-visible'] : ''} 
                ${isAIComponentMinimized ? styles['taytrom-fixed-ai-assistant-minimized'] : ''}
            `}>
                {/* AI Assistant - Modified to always render the wrapper component */}
                <MemoizedFixedAIAssistant
                    isOpen={showAIComponent}
                    isMinimized={isAIComponentMinimized}
                    onClose={() => setShowAIComponent(false)}
                    onToggleMinimize={toggleAIComponent}
                    isDark={isDark}
                >
                    {showAIComponent && (
                        <MemoizedAIComponent
                            selectedSection={selectedSection}
                            documentContent={documentContent}
                            onApplySuggestion={handleApplySuggestion}
                            onAddThought={handleAddThought}
                            onReplaceSectionContent={handleReplaceSectionContent}
                            onExpandSection={handleExpandSection}
                            isMinimized={isAIComponentMinimized}
                            onToggleMinimize={() => setIsAIComponentMinimized(!isAIComponentMinimized)}
                            orchestrator={orchestrator}
                        />
                    )}
                </MemoizedFixedAIAssistant>
            </div>

            {/* Add CSS for custom animations */}
            <style jsx>{`
                /* Custom animations that work with our enhanced CSS */
                @keyframes ripple {
                    0% {
                        transform: scale(0) translate(-50%, -50%);
                        opacity: 0.5;
                    }
                    100% {
                        transform: scale(20) translate(-50%, -50%);
                        opacity: 0;
                    }
                }
                
                .suggestion-pulse {
                    animation: suggestion-pulse-anim 0.6s var(--tacs-ease-out-cubic);
                }
                
                @keyframes suggestion-pulse-anim {
                    0% {
                        background-color: ${isDark ? 'rgba(32, 178, 170, 0.2)' : 'rgba(32, 178, 170, 0.1)'};
                    }
                    100% {
                        background-color: transparent;
                    }
                }
                
                [data-document-loaded="true"] .${styles['taytrom-document-panel']} {
                    animation: fadeIn 0.5s var(--tacs-ease-out-cubic);
                }
                
                [data-document-loaded="true"] .${styles['taytrom-visualization-panel']} {
                    animation: fadeInRight 0.5s var(--tacs-ease-out-cubic);
                }
                
                @keyframes fadeInRight {
                    from {
                        opacity: 0;
                        transform: translateX(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
                
                /* Apply reduced motion if user prefers it */
                @media (prefers-reduced-motion: reduce) {
                    * {
                        animation-duration: 0.01ms !important;
                        animation-iteration-count: 1 !important;
                        transition-duration: 0.01ms !important;
                        scroll-behavior: auto !important;
                    }
                }
            `}</style>
        </div>
    );
};

export default memo(Compiler_Writing);