import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    ChevronDown, ChevronUp, Edit, Sparkles, Zap, Target, ListTree, X,
    Plus, Maximize2, ZoomIn, ZoomOut, Save, Brain, MessageSquare,
    PenTool, Layout, ArrowRight, RefreshCcw, BookOpen, Wand2,
    Star, Award, Lightbulb, MousePointer, Brush, Rocket
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// Import CSS module
import styles from './AliceWonderland_Showcase_Specialists_Writing.module.css';

// Import tour and interactive components
import EnhancedCheshireCat
    from "@/writing-scope/specialists/interactive-tour/cheshire-cat/CheshireCat_AliceWonderland_Showcase_Specialists_Writing.jsx";
import Compiler_1_Steps_InteractiveTour_Specialists_Writing
    from "@/writing-scope/specialists/interactive-tour/steps/1/compiler/Compiler_1_Steps_InteractiveTour_Specialists_Writing.jsx";
import Compiler_2_Steps_InteractiveDemo_Specialists_Writing
    from "@/writing-scope/specialists/interactive-tour/steps/2/compiler/Compiler_2_Steps_InteractiveDemo_Specialists_Writing.jsx";
import Compiler_3_Steps_InteractiveTour_Specialists_Writing
    from "@/writing-scope/specialists/interactive-tour/steps/3/compiler/Compiler_3_Steps_InteractiveTour_Specialists_Writing.jsx";
import Compiler_4_Steps_InteractiveTour_Specialists_Writing
    from "@/writing-scope/specialists/interactive-tour/steps/4/compiler/Compiler_4_Steps_InteractiveTour_Specialists_Writing.jsx";
import Compiler_5_Steps_InteractiveTour_Specialists_Writing
    from "@/writing-scope/specialists/interactive-tour/steps/5/compiler/Compiler_5_Steps_InteractiveTour_Specialists_Writing.jsx";
import Compiler_6_Steps_InteractiveTour_Specialists_Writing
    from "@/writing-scope/specialists/interactive-tour/steps/6/compiler/Compiler_6_Steps_InteractiveTour_Specialists_Writing.jsx";
import Compiler_7_Steps_InteractiveTour_Specialists_Writing
    from "@/writing-scope/specialists/interactive-tour/steps/7/compiler/Compiler_7_Steps_InteractiveTour_Specialists_Writing.jsx";

// Import actual components from Taytrom's platform
import Document from "@/writing-scope/specialists/features/primary-view/document-panel/compiler/Compiler_DocumentPanel_Specialists_Writing.jsx";
import AISuggestions from "@/writing-scope/specialists/features/primary-view/visualization-panel/bottom/ai-suggestions/AISuggestions_Specialists_Writing.jsx";
import StructuralListVisualization from "@/writing-scope/specialists/features/primary-view/visualization-panel/top/list-editor/ListEditor_Specialists_3_Sections_ProductionDemo_Specialists_Writing.jsx";
import ExpandableToolbar from "@/writing-scope/specialists/utils/expandable-toolbar/ExpandableToolbar_Utils_Specialists_Writing.jsx";
import { useTheme } from '@/Phase1App.jsx';

// Import platform styles to match the real UI
import platformStyles from '@/writing-scope/compiler/Compiler_Writing.module.css';


import AIComponent from "@/writing-scope/specialists/features/primary-view/ai-overlay/ai-component/AIComponent_Specialists_Writing.jsx";
import FixedAIAssistant
    from "@/writing-scope/specialists/features/primary-view/ai-overlay/ai-component/wrapper/Wrapper_AIComponent_Specialists_Writing.jsx";

const MemoizedAIComponent = memo(AIComponent);
const MemoizedFixedAIAssistant = memo(FixedAIAssistant);


// Alias block to match names used in implementation
const CheshireCat = EnhancedCheshireCat;
const Step1Tour = Compiler_1_Steps_InteractiveTour_Specialists_Writing;
const Step2Demo = Compiler_2_Steps_InteractiveDemo_Specialists_Writing;
const Step3Tour = Compiler_3_Steps_InteractiveTour_Specialists_Writing;
const Step4Tour = Compiler_4_Steps_InteractiveTour_Specialists_Writing;
const Step5Tour = Compiler_5_Steps_InteractiveTour_Specialists_Writing;
const Step6Tour = Compiler_6_Steps_InteractiveTour_Specialists_Writing;
const Step7Tour = Compiler_7_Steps_InteractiveTour_Specialists_Writing;

/**
 * AliceWonderland_Showcase_Specialists_Writing.jsx
 *
 * A streamlined version of the Alice's Adventures in Wonderland showcase,
 * focusing purely on the interactive tour and platform demonstration.
 */

// Welcome Options Component
const TaytromWelcomeOptions = ({ onStartTour, onExplore }) => {
    const [hoverTour, setHoverTour] = useState(false);
    const [hoverExplore, setHoverExplore] = useState(false);

    return (
        <div className="w-full h-full flex flex-col items-center justify-center bg-gray-900 text-white px-4">
            {/* Background subtle particle effect */}
            <div className="absolute inset-0 overflow-hidden pointer-events-none">
                {[...Array(20)].map((_, i) => (
                    <motion.div
                        key={i}
                        className="absolute rounded-full bg-teal-500/10 w-2 h-2"
                        style={{
                            left: `${Math.random() * 100}%`,
                            top: `${Math.random() * 100}%`,
                        }}
                        animate={{
                            y: [0, -30, 0],
                            opacity: [0.1, 0.3, 0.1],
                        }}
                        transition={{
                            duration: 4 + Math.random() * 4,
                            repeat: Infinity,
                            delay: Math.random() * 5,
                        }}
                    />
                ))}
            </div>

            {/* Main content */}
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="max-w-3xl w-full flex flex-col items-center text-center z-10 mb-16"
            >
                <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-white">
                    Welcome to <span className="text-teal-400">Taytrom</span>
                </h1>
                <p className="text-xl text-gray-300 mb-12 max-w-xl">
                    Experience the future of AI-assisted writing with our interactive platform. How would you like to begin?
                </p>
            </motion.div>

            {/* Option buttons */}
            <div className="flex flex-col sm:flex-row gap-6 w-full max-w-2xl z-10">
                {/* Start Interactive Tour */}
                <motion.button
                    className="flex-1 bg-gradient-to-r from-teal-500 to-teal-600 text-white rounded-xl p-6 flex flex-col items-center shadow-lg hover:shadow-teal-500/20 transition-all"
                    whileHover={{
                        scale: 1.03,
                        boxShadow: "0 10px 25px -5px rgba(20, 184, 166, 0.4)"
                    }}
                    whileTap={{ scale: 0.98 }}
                    onHoverStart={() => setHoverTour(true)}
                    onHoverEnd={() => setHoverTour(false)}
                    onClick={onStartTour}
                >
                    <div className="rounded-full bg-white/20 p-4 mb-4">
                        <Wand2 size={28} className="text-white" />
                    </div>
                    <h3 className="text-xl font-bold mb-2">Start Interactive Tour</h3>
                    <p className="text-sm text-teal-100 mb-4">Follow a guided experience through Taytrom's features</p>

                    <motion.div
                        className="flex items-center text-sm font-medium"
                        animate={{ x: hoverTour ? 5 : 0 }}
                        transition={{ type: "spring", stiffness: 400 }}
                    >
                        <span>Begin Tour</span>
                        <ArrowRight size={16} className="ml-2" />
                    </motion.div>

                    {/* Sparkle animation when hovered */}
                    {hoverTour && (
                        <motion.div
                            className="absolute top-0 right-0 p-2"
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                        >
                            <Sparkles size={20} className="text-yellow-300" />
                        </motion.div>
                    )}
                </motion.button>

                {/* Explore On Your Own */}
                <motion.button
                    className="flex-1 bg-gray-800 border border-gray-700 text-white rounded-xl p-6 flex flex-col items-center shadow-lg hover:shadow-gray-800/20 transition-all"
                    whileHover={{
                        scale: 1.03,
                        boxShadow: "0 10px 25px -5px rgba(30, 41, 59, 0.4)"
                    }}
                    whileTap={{ scale: 0.98 }}
                    onHoverStart={() => setHoverExplore(true)}
                    onHoverEnd={() => setHoverExplore(false)}
                    onClick={onExplore}
                >
                    <div className="rounded-full bg-white/10 p-4 mb-4">
                        <MousePointer size={28} className="text-white" />
                    </div>
                    <h3 className="text-xl font-bold mb-2">Explore On Your Own</h3>
                    <p className="text-sm text-gray-400 mb-4">Jump straight into the platform and discover at your pace</p>

                    <motion.div
                        className="flex items-center text-sm font-medium"
                        animate={{ x: hoverExplore ? 5 : 0 }}
                        transition={{ type: "spring", stiffness: 400 }}
                    >
                        <span>Get Started</span>
                        <ArrowRight size={16} className="ml-2" />
                    </motion.div>
                </motion.button>
            </div>

            {/* Footer note */}
            <motion.p
                className="mt-16 text-gray-500 text-sm"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8 }}
            >
                Powered by advanced AI to transform your writing experience
            </motion.p>
        </div>
    );
};

// Alice's Adventures in Wonderland Document Content
const AliceDocumentContent = {
    title: "Alice's Adventures in Wonderland",
    sections: [
        {
            id: 1,
            title: "Down the Rabbit-Hole",
            type: "introduction",
            content: "Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do: once or twice she had peeped into the book her sister was reading, but it had no pictures or conversations in it, 'and what is the use of a book,' thought Alice 'without pictures or conversations?'\n\nSo she was considering in her own mind (as well as she could, for the hot day made her feel very sleepy and stupid), whether the pleasure of making a daisy-chain would be worth the trouble of getting up and picking the daisies, when suddenly a White Rabbit with pink eyes ran close by her.",
            parentId: null,
            position: 0
        },
        {
            id: 2,
            title: "The Pool of Tears",
            type: "paragraph",
            content: "'Curiouser and curiouser!' cried Alice (she was so much surprised, that for the moment she quite forgot how to speak good English); 'now I'm opening out like the largest telescope that ever was! Good-bye, feet!' (for when she looked down at her feet, they seemed to be almost out of sight, they were getting so far off). 'Oh, my poor little feet, I wonder who will put on your shoes and stockings for you now, dears?'\n\nShe went on crying in this way until there was a large pool all round her, about four inches deep and reaching half down the hall.",
            parentId: null,
            position: 1
        },
        {
            id: 3,
            title: "Characters",
            type: "heading",
            content: "The main characters that Alice encounters throughout her journey.",
            parentId: null,
            position: 2
        },
        {
            id: 4,
            title: "The White Rabbit",
            type: "bulletList",
            content: "• Always in a hurry\n• Carries a pocket watch\n• Works for the Queen of Hearts\n• First character Alice follows into Wonderland\n• Dressed in a waistcoat and jacket",
            parentId: 3,
            position: 0
        },
        {
            id: 5,
            title: "The Cheshire Cat",
            type: "bulletList",
            content: "• Known for its distinctive mischievous grin\n• Can appear and disappear at will\n• Offers Alice philosophical insights\n• One of the few characters in Wonderland who listens to Alice\n• Often perches in a tree branch",
            parentId: 3,
            position: 1
        },
        {
            id: 6,
            title: "The Mad Hatter",
            type: "bulletList",
            content: "• Host of the mad tea party\n• Trapped in perpetual tea time\n• Known for speaking in riddles\n• Wears a large top hat with a price tag\n• Constantly argues with the March Hare",
            parentId: 3,
            position: 2
        },
        {
            id: 7,
            title: "The Queen of Hearts",
            type: "bulletList",
            content: "• Rules Wonderland with tyranny\n• Famous for shouting 'Off with their heads!'\n• Loves playing croquet with flamingos and hedgehogs\n• Easily provoked and quick to anger\n• Married to the meek King of Hearts",
            parentId: 3,
            position: 3
        },
        {
            id: 8,
            title: "Themes",
            type: "heading",
            content: "Key themes explored throughout Alice's adventures.",
            parentId: null,
            position: 3
        },
        {
            id: 9,
            title: "Growing Up",
            type: "paragraph",
            content: "The story uses physical transformations (growing and shrinking) as a metaphor for the confusing process of growing up and the changes that occur during childhood. Alice constantly struggles with her identity as she changes size repeatedly throughout her adventure.",
            parentId: 8,
            position: 0
        },
        {
            id: 10,
            title: "Absurdity and Nonsense",
            type: "paragraph",
            content: "Wonderland operates on dream logic and nonsensical rules, challenging Alice's understanding of the world. The absurdity of Wonderland represents a child's perception of the seemingly arbitrary and confusing rules of the adult world.",
            parentId: 8,
            position: 1
        }
    ]
};

// AI suggestions for the Alice content
const AliceAISuggestions = [
    {
        id: "sugg_1",
        content: "Add a section exploring how the Caterpillar's riddles reflect Alice's identity crisis",
        category: "Structure",
        color: "#3b82f6",
        position: { x: 30, y: 40 }
    },
    {
        id: "sugg_2",
        content: "Include quotes from the Mock Turtle's song to highlight the theme of nostalgia",
        category: "Content",
        color: "#10b981",
        position: { x: 70, y: 20 }
    },
    {
        id: "sugg_3",
        content: "Use more vivid language to describe the Queen's garden of living playing cards",
        category: "Style",
        color: "#8b5cf6",
        position: { x: 50, y: 70 }
    },
    {
        id: "sugg_4",
        content: "Create a parallel between Alice's physical changes and her emotional growth",
        category: "Content",
        color: "#10b981",
        position: { x: 20, y: 80 }
    },
    {
        id: "sugg_5",
        content: "Restructure the tea party scene to highlight its absurdist philosophical elements",
        category: "Structure",
        color: "#3b82f6",
        position: { x: 80, y: 60 }
    }
];

// Featured tooltips that highlight key aspects of the platform
const featureTooltips = [
    {
        content: "Drag suggestions to transform content",
        x: 55,
        y: 65,
        icon: <MousePointer size={16} />
    },
    {
        content: "Reorganize steps visually with the structure view",
        x: 80,
        y: 30,
        icon: <Layout size={16} />
    },
    {
        content: "AI generates context-aware enhancements",
        x: 25,
        y: 70,
        icon: <Lightbulb size={16} />
    },
    {
        content: "Characters and themes are automatically linked",
        x: 70,
        y: 45,
        icon: <ListTree size={16} />
    },
    {
        content: "Visual transitions help understand document structure",
        x: 35,
        y: 35,
        icon: <Brush size={16} />
    }
];

// Create a mock orchestrator for the components
const createMockOrchestrator = () => ({
    handleUserMessage: async (prompt, options) => ({
        success: true,
        response: "This is a simulated AI response about Alice in Wonderland"
    }),
    authManager: {
        isAuthenticated: () => true,
        getCurrentUser: () => ({ id: 'demo-user-123' })
    },
    subscriptionManager: {
        notify: (channel, message) => console.log('Notification:', channel, message),
        getUserSubscriptions: async () => ({ hasPremium: true })
    },
    personalizationBridge: {
        preparePersonalizationParams: (userId) => ({ userId, preferences: 'default' })
    },
    soundManager: {
        play: (soundName) => console.log(`Playing sound: ${soundName}`)
    }
});

// Mock responses for the AI assistant
const getMockAIResponse = (userInput) => {
    // Lowercase the input for easier matching
    const input = userInput.toLowerCase();

    // Different response categories based on input keywords
    if (input.includes('hello') || input.includes('hi') || input.includes('hey')) {
        return "Hello! I'm Taytrom's AI Assistant. I can help you craft your document, suggest improvements, and answer questions about the platform. What would you like to work on today?";
    }
    else if (input.includes('help') || input.includes('how') || input.includes('what')) {
        return "I'd be happy to help! With Taytrom's AI-powered writing platform, you can:\n\n• Create structured documents with nested steps\n• Receive AI-powered content suggestions\n• Visualize your document's structure\n• Get style recommendations\n\nWhat specific feature would you like to learn more about?";
    }
    else if (input.includes('feature') || input.includes('can you')) {
        return "Taytrom offers powerful features like:\n\n• Interactive document structure visualization\n• AI-powered content suggestions\n• Smart formatting and styling\n• Contextual writing assistance\n• Document analytics\n\nTo access all features, you'll need to subscribe. Would you like information about our subscription plans?";
    }
    else if (input.includes('price') || input.includes('cost') || input.includes('subscription') || input.includes('plan')) {
        return "Taytrom offers flexible subscription plans:\n\n• Free: Basic document creation and limited AI suggestions\n• Premium ($9.99/month): Full access to all AI features, unlimited documents, and priority support\n• Enterprise: Custom pricing for teams\n\nTry our interactive demo to experience all premium features before subscribing!";
    }
    else if (input.includes('document') || input.includes('write') || input.includes('create')) {
        return "Creating documents with Taytrom is intuitive! Start by adding steps from the document panel, then use the structure visualization to organize them. Our AI can suggest content for each section based on your document's context. Would you like a specific tip for document creation?";
    }
    else if (input.includes('example') || input.includes('sample')) {
        return "Our Alice in Wonderland example demonstrates how Taytrom helps organize complex narratives. Notice how characters, themes, and scenes are structured hierarchically with visual connections. Try the demo to see how you can apply similar organization to your own content!";
    }
    else {
        // Default response for any other input
        return "Thanks for your interest in Taytrom! Our AI assistant can help with document creation, content suggestions, and answering questions about our platform. To experience the full power of our AI features, try our interactive demo or sign up for a subscription. What would you like to know more about?";
    }
};

// Memoized components to improve performance
const MemoizedDocument = memo(Document);
const MemoizedAISuggestions = memo(AISuggestions);
const MemoizedStructuralListVisualization = memo(StructuralListVisualization);
const MemoizedExpandableToolbar = memo(ExpandableToolbar);

// Demo AI Component for the showcase
const DemoAIComponentWrapper = ({ isMinimized, onToggleMinimize }) => {
    const [messages, setMessages] = useState([
        {
            role: 'assistant',
            content: "Welcome to Taytrom's AI Assistant! I can help with document creation, suggest improvements, and answer questions about the platform. How can I assist you today?"
        }
    ]);
    const [inputValue, setInputValue] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    // Function to handle user input
    const handleSendMessage = () => {
        if (!inputValue.trim()) return;

        // Add user message to the chat
        const userMessage = { role: 'user', content: inputValue };
        setMessages(prev => [...prev, userMessage]);

        // Show processing state
        setIsProcessing(true);

        // Clear input field
        setInputValue('');

        // Simulate AI processing delay (500-1500ms)
        setTimeout(() => {
            // Generate mock response
            const mockResponse = getMockAIResponse(userMessage.content);

            // Add AI response to the chat
            setMessages(prev => [...prev, { role: 'assistant', content: mockResponse }]);
            setIsProcessing(false);
        }, 500 + Math.random() * 1000);
    };

    return (
        <div className={styles.taytromAiComponentWrapper || styles.aiComponentWrapper}>
            <div
                className={styles.taytromAiMessages || styles.aiMessages}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: isMinimized ? '0' : '300px',
                    overflow: 'auto',
                    padding: '16px',
                    gap: '12px',
                    transition: 'height 0.3s ease'
                }}
            >
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`${styles.taytromMessage || styles.message} ${styles[`taytromMessage${message.role}`] || styles[message.role]}`}
                        style={{
                            padding: '10px 14px',
                            borderRadius: '12px',
                            maxWidth: '80%',
                            alignSelf: message.role === 'user' ? 'flex-end' : 'flex-start',
                            background: message.role === 'user' ? 'var(--tacs-primary-500, #20b2aa)' : 'var(--tacs-dark-bg-floating, #334155)',
                            color: message.role === 'user' ? 'white' : 'var(--tacs-dark-substance-primary, #f8fafc)',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }}
                    >
                        {message.content}
                    </div>
                ))}

                {isProcessing && (
                    <div
                        className={styles.taytromMessageTyping || styles.messageTyping}
                        style={{
                            alignSelf: 'flex-start',
                            padding: '6px 12px',
                            borderRadius: '12px',
                            background: 'var(--tacs-dark-bg-floating, #334155)',
                            color: 'var(--tacs-dark-substance-secondary, #cbd5e1)'
                        }}
                    >
                        <div
                            className={styles.taytromTypingIndicator || styles.typingIndicator}
                            style={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center'
                            }}
                        >
                            <span style={{
                                width: '6px',
                                height: '6px',
                                borderRadius: '50%',
                                background: 'currentColor',
                                opacity: 0.6,
                                animation: 'taytromTypingDot 1.4s infinite'
                            }}></span>
                            <span style={{
                                width: '6px',
                                height: '6px',
                                borderRadius: '50%',
                                background: 'currentColor',
                                opacity: 0.6,
                                animation: 'taytromTypingDot 1.4s 0.2s infinite'
                            }}></span>
                            <span style={{
                                width: '6px',
                                height: '6px',
                                borderRadius: '50%',
                                background: 'currentColor',
                                opacity: 0.6,
                                animation: 'taytromTypingDot 1.4s 0.4s infinite'
                            }}></span>
                        </div>
                    </div>
                )}
            </div>

            <div
                className={styles.taytromAiInput || styles.aiInput}
                style={{
                    display: 'flex',
                    padding: '10px',
                    borderTop: '1px solid var(--tacs-dark-border-subtle, #334155)',
                    background: 'var(--tacs-dark-bg-elevated, #1e293b)'
                }}
            >
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleSendMessage();
                    }}
                    placeholder="Ask me about Taytrom..."
                    style={{
                        flex: 1,
                        padding: '8px 12px',
                        borderRadius: '8px',
                        border: '1px solid var(--tacs-dark-border-subtle, #334155)',
                        background: 'var(--tacs-dark-bg-depressed, #0a1020)',
                        color: 'var(--tacs-dark-substance-primary, #f8fafc)',
                        outline: 'none'
                    }}
                />
                <button
                    onClick={handleSendMessage}
                    disabled={!inputValue.trim()}
                    style={{
                        marginLeft: '8px',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        border: 'none',
                        background: 'var(--tacs-primary-500, #20b2aa)',
                        color: 'white',
                        cursor: inputValue.trim() ? 'pointer' : 'not-allowed',
                        opacity: inputValue.trim() ? 1 : 0.6
                    }}
                >
                    Send
                </button>
            </div>

            <style jsx>{`
                @keyframes taytromTypingDot {
                    0%, 60%, 100% { transform: translateY(0); }
                    30% { transform: translateY(-4px); }
                }
            `}</style>
        </div>
    );
};

// Try Demo Tour Button component
const TryDemoTourButton = ({ onClick }) => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{
                type: "spring",
                stiffness: 400,
                damping: 15,
                delay: 0.5
            }}
            className={styles.tryDemoButtonContainer || "try-demo-button-container"}
            style={{
                position: 'fixed',
                bottom: '30px',
                left: '30px',
                zIndex: 1000
            }}
        >
            <div style={{
                position: 'relative',
                display: 'inline-block'
            }}>
                {/* Animated glow effect */}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: '12px',
                        background: 'radial-gradient(circle at center, rgba(32, 178, 170, 0.5) 0%, transparent 70%)',
                        filter: 'blur(10px)',
                        opacity: 0.7,
                        animation: 'pulse 2s infinite ease-in-out'
                    }}
                ></div>

                {/* The actual button */}
                <button
                    onClick={onClick}
                    aria-label="Open interactive demo tour of the platform"
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            onClick();
                        }
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '12px 20px',
                        background: 'linear-gradient(to right, #20b2aa, #4682B4)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '12px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        position: 'relative',
                        overflow: 'hidden',
                        boxShadow: '0 4px 15px rgba(0,0,0,0.2), 0 0 20px rgba(32, 178, 170, 0.3)',
                        transition: 'all 0.3s ease'
                    }}
                >
                    {/* Animated shine effect */}
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '200%',
                            height: '100%',
                            background: 'linear-gradient(to right, transparent 0%, rgba(255,255,255,0.2) 50%, transparent 100%)',
                            transform: 'translateX(-100%)',
                            animation: 'shine 3s infinite'
                        }}
                    ></div>

                    {/* Icon with animation */}
                    <div style={{ position: 'relative' }}>
                        <Rocket size={18} strokeWidth={2.5} />

                        {/* Animated flame effect */}
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '-5px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: '10px',
                                height: '10px',
                                background: 'radial-gradient(circle, #FFD700 0%, #FF8C00 70%, transparent 100%)',
                                borderRadius: '50%',
                                filter: 'blur(2px)',
                                animation: 'flicker 0.5s infinite alternate'
                            }}
                        ></div>
                    </div>

                    <span style={{ position: 'relative' }}>Try Demo Tour</span>
                </button>

                {/* Enhanced tooltip */}
                <div
                    style={{
                        position: 'absolute',
                        bottom: '100%',
                        right: '0',
                        marginBottom: '10px',
                        background: 'rgba(30, 41, 59, 0.95)',
                        color: 'white',
                        padding: '10px 15px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 15px rgba(0,0,0,0.3)',
                        width: '220px',
                        pointerEvents: 'none',
                        opacity: 0,
                        transform: 'translateY(10px)',
                        transition: 'opacity 0.3s, transform 0.3s',
                        visibility: 'hidden'
                    }}
                    className={styles.taytromEnhancedTooltip || styles.enhancedTooltip || ''}
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div style={{ color: '#3b82f6' }}>
                            <Sparkles size={14} />
                        </div>
                        <span>Interactive guided tour of all features</span>
                    </div>

                    {/* Tooltip arrow */}
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '-5px',
                            right: '20px',
                            width: '10px',
                            height: '10px',
                            background: 'rgba(30, 41, 59, 0.95)',
                            transform: 'rotate(45deg)',
                            boxShadow: '2px 2px 5px rgba(0,0,0,0.1)'
                        }}
                    ></div>
                </div>
            </div>

            <style jsx>{`
                @keyframes pulse {
                    0%, 100% { opacity: 0.7; transform: scale(1); }
                    50% { opacity: 0.5; transform: scale(1.05); }
                }

                @keyframes shine {
                    0% { transform: translateX(-100%); }
                    20%, 100% { transform: translateX(100%); }
                }

                @keyframes flicker {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0.6; }
                }

                /* Additional style to show tooltip on hover */
                div:hover > .${styles.taytromEnhancedTooltip || styles.enhancedTooltip} {
                    opacity: 1;
                    transform: translateY(0);
                    visibility: visible;
                }
            `}</style>
        </motion.div>
    );
};

// Main component - Streamlined for just the showcase functionality
const AliceWonderland_Showcase_Specialists_Writing = ({ onGetStarted, onSignIn, startTourAutomatically = false }) => {
    const { isDark } = useTheme() || { isDark: false };
    const navigate = useNavigate();

    // Add state to control the welcome screen visibility
    const [showWelcome, setShowWelcome] = useState(true);


    // Component state
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [listAnimationVisible, setListAnimationVisible] = useState(false);
    const [isAIProcessing, setIsAIProcessing] = useState(false);
    const [showFeatureTooltip, setShowFeatureTooltip] = useState(false);
    const [tooltipContent, setTooltipContent] = useState("");
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [tooltipIcon, setTooltipIcon] = useState(null);
    const [showPlatform, setShowPlatform] = useState(false);

    // AI Component states
    const [showAIComponent, setShowAIComponent] = useState(false);
    const [isAIComponentMinimized, setIsAIComponentMinimized] = useState(true);

    // Tour state
    const [showTour, setShowTour] = useState(startTourAutomatically);
    const [currentTourStep, setCurrentTourStep] = useState(1);
    const [showCheshireCat, setShowCheshireCat] = useState(false);
    const [showProductionDemo, setShowProductionDemo] = useState(false);

    // Document state
    const [documentContent, setDocumentContent] = useState(AliceDocumentContent);
    const [selectedSection, setSelectedSection] = useState(1);
    const [thoughts, setThoughts] = useState(AliceAISuggestions);
    const [expandedBullets, setExpandedBullets] = useState({});

    // Mock state for history
    const [documentHistory, setDocumentHistory] = useState([AliceDocumentContent]);
    const [historyIndex, setHistoryIndex] = useState(0);

    // Refs
    const sectionRefs = useRef({});
    const mockOrchestratorRef = useRef(createMockOrchestrator());
    const containerRef = useRef(null);
    const tooltipTimerRef = useRef(null);
    const isComponentMounted = useRef(true);

    // Toggle AI Chat Component
    const toggleAIComponent = useCallback(() => {
        // If component isn't visible, show it
        if (!showAIComponent) {
            setShowAIComponent(true);
            setIsAIComponentMinimized(false);

            // Pulse animation for AI icon in header
            const aiIcon = document.querySelector(`[data-ai-icon="true"]`);
            if (aiIcon) {
                aiIcon.style.animation = 'pulse-icon 1s var(--tacs-ease-in-out-cubic)';
                setTimeout(() => {
                    aiIcon.style.animation = '';
                }, 1000);
            }
        } else {
            // If it's visible, toggle minimized state with animation
            setIsAIComponentMinimized(!isAIComponentMinimized);
        }
    }, [showAIComponent, isAIComponentMinimized]);


    // Tour navigation handlers
    const handleStartTour = useCallback(() => {
        setShowWelcome(false); // Hide welcome screen
        setShowTour(true);
        setShowCheshireCat(true);
        setCurrentTourStep(1);
        setShowPlatform(false);
    }, []);

    const handleNextStep = useCallback(() => {
        if (currentTourStep < 7) {
            setCurrentTourStep(prev => prev + 1);
        } else {
            // Tour completed
            setShowTour(false);
            setShowPlatform(true);
        }
    }, [currentTourStep]);

    const handlePreviousStep = useCallback(() => {
        if (currentTourStep > 1) {
            setCurrentTourStep(prev => prev - 1);
        }
    }, [currentTourStep]);

    // Handler for cat questions
    const handleCatQuestion = useCallback(async (question) => {
        return `I'd be delighted to explain more about Taytrom's amazing writing platform! ${question.charAt(0).toUpperCase() + question.slice(1)} is an important feature that helps transform your writing experience with AI assistance.`;
    }, []);

    // Feature demo handler for the cat
    const handleFeatureDemo = useCallback((featureId) => {
        if (featureId === 'wizard-mode-start') {
            // Start guided tour mode
            if (!showTour) {
                handleStartTour();
            }
        } else {
            showNotification(`The ${featureId} feature helps transform your writing!`);
        }
    }, [showTour, handleStartTour]);

    // Toggle to show the production demo
    const showWritingPlatformDemo = useCallback(() => {
        setShowProductionDemo(true);
        setShowCheshireCat(true);
    }, []);

    // Show notification helper function
    const showNotification = useCallback((message) => {
        const notification = document.createElement('div');
        notification.className = styles.taytromNotification || styles.notification;
        notification.textContent = message;

        if (containerRef.current) {
            containerRef.current.appendChild(notification);

            setTimeout(() => {
                notification.classList.add(styles.taytromNotificationFadeOut || styles.notificationFadeOut);
                setTimeout(() => {
                    if (containerRef.current && containerRef.current.contains(notification)) {
                        containerRef.current.removeChild(notification);
                    }
                }, 500);
            }, 2500);
        }
    }, []);

    // Add an effect to auto-start tour when the prop is true
    useEffect(() => {
        if (startTourAutomatically) {
            setShowWelcome(false); // Skip welcome screen if tour starts automatically
            // Short delay to ensure component is fully rendered
            setTimeout(() => {
                handleStartTour();
            }, 500);
        }
    }, [startTourAutomatically, handleStartTour]);

    // Handle animations after loading
    useEffect(() => {
        setDocumentLoaded(true);

        const timer = setTimeout(() => {
            setListAnimationVisible(true);
        }, 300);

        const featureTimer = setTimeout(() => {
            showFeatureHighlight();
        }, 2000);

        return () => {
            clearTimeout(timer);
            clearTimeout(featureTimer);
            if (tooltipTimerRef.current) {
                clearTimeout(tooltipTimerRef.current);
            }
        };
    }, []);

    // Render current tour step
    const renderCurrentTourStep = useCallback(() => {
        const commonProps = {
            isDark,
            orchestrator: mockOrchestratorRef.current
        };

        switch(currentTourStep) {
            case 1:
                return <Step1Tour onNext={handleNextStep} {...commonProps} />;
            case 2:
                return <Step2Demo onNext={handleNextStep} onPrevious={handlePreviousStep} {...commonProps} />;
            case 3:
                return <Step3Tour onNext={handleNextStep} onPrevious={handlePreviousStep} {...commonProps} />;
            case 4:
                return <Step4Tour onNext={handleNextStep} onPrevious={handlePreviousStep} {...commonProps} />;
            case 5:
                return <Step5Tour onNext={handleNextStep} onPrevious={handlePreviousStep} {...commonProps} />;
            case 6:
                return <Step6Tour onNext={handleNextStep} onPrevious={handlePreviousStep} {...commonProps} />;
            case 7:
                return <Step7Tour onComplete={() => {
                    setShowTour(false);
                    setShowPlatform(true);
                }} {...commonProps} />;
            default:
                return null;
        }
    }, [currentTourStep, handleNextStep, handlePreviousStep, isDark]);

    // Change showFeatureHighlight to be properly memoized with useCallback
    const showFeatureHighlight = useCallback(() => {
        if (!isComponentMounted.current) return;

        if (tooltipTimerRef.current) {
            clearTimeout(tooltipTimerRef.current);
        }

        // Select a random feature tooltip
        const feature = featureTooltips[Math.floor(Math.random() * featureTooltips.length)];
        setTooltipContent(feature.content);
        setTooltipPosition({ x: feature.x, y: feature.y });
        setTooltipIcon(feature.icon);
        setShowFeatureTooltip(true);

        tooltipTimerRef.current = setTimeout(() => {
            if (!isComponentMounted.current) return;
            setShowFeatureTooltip(false);

            tooltipTimerRef.current = setTimeout(() => {
                if (isComponentMounted.current) {
                    showFeatureHighlight();
                }
            }, 5000);
        }, 5000);
    }, []);

    // Handle direct navigation if props aren't provided
    const handleGetStarted = useCallback(() => {
        if (onGetStarted) {
            onGetStarted();
        } else if (onSignIn) {
            onSignIn();
        } else {
            window.location.href = '/#/signin';
        }
    }, [onGetStarted, onSignIn]);

    // Enhanced handleExplore function for smoother platform navigation
    const handleExplore = useCallback(() => {
        // Hide welcome screen and show the platform
        setShowWelcome(false);
        setShowPlatform(true);
        setShowCheshireCat(true);
        setShowTour(false);

        // Scroll to platform section
        setTimeout(() => {
            const platformElement = document.getElementById('platform-showcase');
            if (platformElement) {
                platformElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }, 300);
    }, []);

    // Mock handlers with visual feedback
    const addToHistory = useCallback(() => {
        console.log('Adding to history');
        showNotification('Action saved to history');
    }, [showNotification]);

    const handleUndo = useCallback(() => {
        console.log('Undo action');
        showNotification('Undo successful');
    }, [showNotification]);

    const handleRedo = useCallback(() => {
        console.log('Redo action');
        showNotification('Redo successful');
    }, [showNotification]);

    const handleSave = useCallback(() => {
        console.log('Save action');
        showNotification('Document saved');
    }, [showNotification]);

    const handleClear = useCallback(() => {
        console.log('Clear document');
        showNotification('Document cleared');
    }, [showNotification]);

    const handleImport = useCallback((format) => {
        console.log('Import', format);
        showNotification(`Importing ${format.toUpperCase()} file`);
    }, [showNotification]);

    const handleExport = useCallback((format) => {
        console.log('Export', format);
        showNotification(`Exporting as ${format.toUpperCase()}`);
    }, [showNotification]);

    const handleDroppedSuggestion = useCallback((sectionId, thought) => {
        console.log('Dropped suggestion on section', sectionId, thought);
        showNotification('AI suggestion applied');

        // Create visual effect
        const sectionElement = document.querySelector(`[data-section-id="${sectionId}"]`);
        if (sectionElement) {
            sectionElement.classList.add(styles.taytromSectionHighlight || styles.sectionHighlight);
            setTimeout(() => {
                sectionElement.classList.remove(styles.taytromSectionHighlight || styles.sectionHighlight);
            }, 1200);
        }
    }, [showNotification]);

    const handleExpandBullet = useCallback((sectionId, bulletIndex) => {
        console.log('Expand bullet', sectionId, bulletIndex);

        // Toggle expansion state
        setExpandedBullets(prev => {
            const key = `${sectionId}-${bulletIndex}`;
            if (prev[key]) {
                const newState = {...prev};
                delete newState[key];
                return newState;
            } else {
                return {
                    ...prev,
                    [key]: "AI has expanded this bullet point with additional context and details about the character or concept, providing more depth to the original point."
                };
            }
        });

        showNotification('Bullet expanded with AI');
    }, [showNotification]);

    const scrollSectionIntoView = useCallback((sectionId) => {
        console.log('Scrolling to section', sectionId);

        // Find the section element
        const sectionElement = document.querySelector(`[data-section-id="${sectionId}"]`);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

            // Add highlight effect
            sectionElement.classList.add(styles.taytromSectionHighlight || styles.sectionHighlight);
            setTimeout(() => {
                sectionElement.classList.remove(styles.taytromSectionHighlight || styles.sectionHighlight);
            }, 1200);
        }
    }, []);

    const handleAddThought = useCallback((thought) => {
        console.log('Add thought', thought);
        showNotification('AI suggestion applied');

        // Remove the thought from suggestions
        setThoughts(prev => prev.filter(t => t.id !== thought.id));
    }, [showNotification]);

    // Generate new AI thoughts
    const generateNewThought = useCallback(() => {
        setIsAIProcessing(true);
        setTimeout(() => {
            const newThought = {
                id: `thought_${Date.now()}`,
                content: "Add more descriptive language when introducing the White Rabbit",
                category: ["Structure", "Content", "Style"][Math.floor(Math.random() * 3)],
                color: ["#3b82f6", "#10b981", "#8b5cf6"][Math.floor(Math.random() * 3)],
                position: {
                    x: 20 + Math.random() * 60,
                    y: 20 + Math.random() * 60
                }
            };
            setThoughts(prev => [...prev, newThought]);
            setIsAIProcessing(false);
            showNotification('New AI suggestion generated');
        }, 1500);
    }, [showNotification]);

    // Generate multiple AI thoughts
    const generateMultipleThoughts = useCallback(() => {
        setIsAIProcessing(true);
        setTimeout(() => {
            const newThoughts = Array(3).fill().map((_, i) => ({
                id: `thought_${Date.now()}_${i}`,
                content: [
                    "Expand on Alice's emotional journey throughout the story",
                    "Consider adding more detailed descriptions of Wonderland's environment",
                    "Enhance the Queen's character with more royal mannerisms"
                ][i],
                category: ["Structure", "Content", "Style"][Math.floor(Math.random() * 3)],
                color: ["#3b82f6", "#10b981", "#8b5cf6"][Math.floor(Math.random() * 3)],
                position: {
                    x: 20 + Math.random() * 60,
                    y: 20 + Math.random() * 60
                }
            }));
            setThoughts(prev => [...prev, ...newThoughts]);
            setIsAIProcessing(false);
            showNotification('Multiple AI suggestions generated');
        }, 2000);
    }, [showNotification]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            isComponentMounted.current = false;
            if (tooltipTimerRef.current) {
                clearTimeout(tooltipTimerRef.current);
            }
        };
    }, []);

    const handleUpdateDocument = useCallback((content) => {
        addToHistory();
        setDocumentContent(content);
    }, [addToHistory]);

    return (
        <div className={styles.taytromAliceShowcaseContainer || styles.aliceShowcaseContainer}
             ref={containerRef}>

            {/* Welcome screen overlay - show first before any other content */}
            <AnimatePresence>
                {showWelcome && (
                    <motion.div
                        className={styles.taytromWelcomeContainer || "taytrom-welcome-container"}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 100
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <TaytromWelcomeOptions
                            onStartTour={handleStartTour}
                            onExplore={handleExplore}
                        />
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Only show the regular content when welcome screen is dismissed */}
            {!showWelcome && (
                <>

                    {/* Interactive Tour Container */}
                    <AnimatePresence>
                        {showTour && (
                            <motion.div
                                className={styles.taytromTourContainer || styles.tourContainer}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ duration: 0.5 }}
                            >
                                {renderCurrentTourStep()}
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {/* Main platform showcase */}
                    <AnimatePresence>
                        {(showPlatform || !isDark) && !showTour && (
                            <motion.div
                                id="platform-showcase"
                                className={`${styles.taytromPlatformContainer || styles.platformContainer} ${showPlatform ? styles.taytromPlatformVisible || styles.platformVisible : ''}`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                {/* Header with toolbar */}
                                <header className={platformStyles["taytrom-header"]}>
                                    <h1 className={platformStyles["taytrom-heading-1"]}>
                                        Taytrom's Writing Platform
                                    </h1>

                                    {/* Right side buttons group */}
                                    <div className={platformStyles["taytrom-header-buttons"]}>
                                        <MemoizedExpandableToolbar
                                            onClear={handleClear}
                                            onImport={handleImport}
                                            onExport={handleExport}
                                            onUndo={handleUndo}
                                            onRedo={handleRedo}
                                            onSave={handleSave}
                                            canUndo={historyIndex > 0}
                                            canRedo={historyIndex < documentHistory.length - 1}
                                            isDark={isDark}
                                            position="header"
                                            defaultExpanded={false}
                                        />

                                    </div>
                                </header>

                                {/* Main content area with 2-column layout */}
                                <div className={platformStyles["taytrom-main-content"]}>
                                    {/* Left panel - Interactive Document (60% width) */}
                                    <div className={platformStyles["taytrom-document-panel"]}>
                                        <MemoizedDocument
                                            documentContent={documentContent}
                                            setDocumentContent={handleUpdateDocument}
                                            selectedSection={selectedSection}
                                            setSelectedSection={setSelectedSection}
                                            expandedBullets={expandedBullets}
                                            setExpandedBullets={setExpandedBullets}
                                            handleExpandBullet={handleExpandBullet}
                                            onDropSuggestion={handleDroppedSuggestion}
                                            isDark={isDark}
                                            sectionRefs={sectionRefs}
                                            isAnimated={documentLoaded}
                                            demoMode={true}
                                        />
                                    </div>

                                    {/* Right panel - Split between List Visualization (top) and AI Suggestions (bottom) */}
                                    <div className={platformStyles["taytrom-visualization-panel"]}>
                                        {/* Top section - Structural List Visualization */}
                                        <div
                                            className={platformStyles["taytrom-structural-list-panel"]}>
                                            <MemoizedStructuralListVisualization
                                                documentContent={documentContent}
                                                setDocumentContent={handleUpdateDocument}
                                                selectedSection={selectedSection}
                                                setSelectedSection={setSelectedSection}
                                                onSuccess={(message) => showNotification(message)}
                                                isDark={isDark}
                                                scrollSectionIntoView={scrollSectionIntoView}
                                                isAnimated={listAnimationVisible}
                                                demoMode={true}
                                            />
                                        </div>

                                        {/* Bottom section - AI Suggestions */}
                                        <div className={platformStyles["taytrom-ai-suggestions-panel"]}>
                                            <MemoizedAISuggestions
                                                thoughts={thoughts}
                                                handleAddThought={handleAddThought}
                                                setThoughts={setThoughts}
                                                isProcessing={isAIProcessing}
                                                generateNewThought={generateNewThought}
                                                generateMultipleThoughts={generateMultipleThoughts}
                                                isDark={isDark}
                                                useInternalButtons={true}
                                                isAnimated={documentLoaded}
                                                demoMode={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {/* CheshireCat Guide */}
                    {showCheshireCat && (
                        <CheshireCat
                            currentSection={showTour ? `step-${currentTourStep}` : (selectedSection ? `section-${selectedSection}` : null)}
                            isDark={isDark}
                            onAskQuestion={handleCatQuestion}
                            isVisible={true}
                            position="top-left"
                            emotion="delighted"
                            size="medium"
                            onFeatureDemo={handleFeatureDemo}
                            productMode={true}
                        />
                    )}

                    {/* AI Assistant */}
                    <div className={`
    ${styles['taytrom-fixed-ai-assistant']} 
    ${showAIComponent ? styles['taytrom-fixed-ai-assistant-visible'] : ''} 
    ${isAIComponentMinimized ? styles['taytrom-fixed-ai-assistant-minimized'] : ''}
`}>
                        {/* AI Assistant - Modified to always render the wrapper component */}
                        <MemoizedFixedAIAssistant
                            isOpen={showAIComponent}
                            isMinimized={isAIComponentMinimized}
                            onClose={() => setShowAIComponent(false)}
                            onToggleMinimize={toggleAIComponent}
                            isDark={isDark}
                        >
                            {showAIComponent && (
                                // Check if we're in demo mode or real mode
                                effectiveDevMode ? (
                                    // In development mode, use the real component
                                    <MemoizedAIComponent
                                        isMinimized={isAIComponentMinimized}
                                        onToggleMinimize={() => setIsAIComponentMinimized(!isAIComponentMinimized)}
                                        orchestrator={orchestrator}
                                    />
                                ) : (
                                    // In production/demo mode, use our demo wrapper
                                    <DemoAIComponentWrapper
                                        isMinimized={isAIComponentMinimized}
                                        onToggleMinimize={() => setIsAIComponentMinimized(!isAIComponentMinimized)}
                                        orchestrator={orchestrator}
                                    />
                                )
                            )}
                        </MemoizedFixedAIAssistant>
                    </div>

                    {/* Try Demo Tour Button */}
                    <TryDemoTourButton onClick={handleStartTour} />

                    {/* Animated feature tooltip */}
                    <AnimatePresence>
                        {showFeatureTooltip && (
                            <motion.div
                                className={styles.taytromFeatureTooltip || styles.featureTooltip}
                                style={{
                                    left: `${tooltipPosition.x}%`,
                                    top: `${tooltipPosition.y}%`
                                }}
                                initial={{opacity: 0, scale: 0.8, y: 20}}
                                animate={{opacity: 1, scale: 1, y: 0}}
                                exit={{opacity: 0, scale: 0.8, y: 10}}
                                transition={{
                                    duration: 0.4,
                                    ease: [0.175, 0.885, 0.32, 1.275]
                                }}
                            >
                                <span className={styles.taytromTooltipIcon || styles.tooltipIcon}>
                                    {tooltipIcon || <Wand2 size={16}/>}
                                </span>
                                <span>{tooltipContent}</span>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </>
            )}
        </div>
    );
};

export default AliceWonderland_Showcase_Specialists_Writing;