/**
 * @file Specialist_Knowledge_Contexts_AI.js
 *
 * @description
 * Specialized manager for knowledge domains in AI interactions.
 * This module organizes, categorizes, and provides contextually relevant
 * knowledge domains to the AIContextManager, enhancing AI responses with
 * domain-specific knowledge.
 *
 * Works in conjunction with AIContextManager.js to provide rich knowledge
 * context for more accurate, helpful AI responses.
 */

/**
 * Class that manages knowledge domain contexts for AI interactions
 */
class KnowledgeContextSpecialist {
    constructor(orchestrator = null) {
        this._orchestrator = orchestrator;
        this._cachedDomains = new Map();
        this._lastRefreshTime = new Map();
        this._refreshInterval = 15 * 60 * 1000; // 15 minutes in milliseconds

        // Initialize knowledge domains
        this._knowledgeDomains = this._getInitialKnowledgeDomains();

        console.log('[KnowledgeContextSpecialist] Initializing with',
            Object.keys(this._knowledgeDomains).length, 'knowledge domains');
    }

    /**
     * Get initial knowledge domains organized by context type
     *
     * @private
     * @returns {Object} - Knowledge domains by category
     */
    _getInitialKnowledgeDomains() {
        return {
            // Shared knowledge domains (always included)
            tacsUIComponents: {
                title: "TACS UI Component System",
                description: "Component structure and implementation following STACK hierarchy",
                category: "design-system",
                priority: 9,
                contextType: "both", // Mark as available in both contexts
                sections: [
                    {
                        title: "Component Structure",
                        content: "TACS UI components follow a strict construction pattern with three distinct steps: Construction, Substance, and Style, each serving a specific purpose in the component lifecycle."
                    },
                    {
                        title: "Implementation Pattern",
                        content: "Components should be implemented following the STACK hierarchy, ensuring proper composition and encapsulation."
                    }
                ]
            },
            tacsCCnSS: {
                title: "Color Centurial & Shape Standards",
                description: "TACS standardized color system and shape standards",
                category: "design-system",
                priority: 8,
                contextType: "both", // Mark as available in both contexts
                sections: [
                    {
                        title: "Color System",
                        content: "The Color Centurial system provides a comprehensive palette of semantically named colors with standardized variations for different contexts and themes."
                    },
                    {
                        title: "Shape Standards",
                        content: "TACS defines specific standards for shapes, borders, and spatial relationships to ensure consistency across the application."
                    }
                ]
            },
            tacsAOS: {
                title: "Autocratic Orchestration System",
                description: "Central orchestration system for Taytrom applications",
                category: "architecture",
                priority: 7,
                contextType: "both", // Mark as available in both contexts
                sections: [
                    {
                        title: "Orchestration Pattern",
                        content: "The AOS provides centralized control and coordination of component communication, ensuring decoupled yet coordinated interactions."
                    },
                    {
                        title: "Implementation Details",
                        content: "The FrontAutocrat class manages subscriptions, message routing, and state synchronization across components."
                    }
                ]
            },
            tacsTIES: {
                title: "Target ID Enum Source Protocol",
                description: "Standardized messaging protocol for component communication",
                category: "architecture",
                priority: 7,
                contextType: "both", // Mark as available in both contexts
                sections: [
                    {
                        title: "Message Format",
                        content: "TIES messages follow a standardized format with Target, ID, Enum, and Source properties, creating a consistent communication protocol."
                    },
                    {
                        title: "Routing System",
                        content: "Messages are routed through the orchestrator, which manages delivery based on target information."
                    }
                ]
            },
            tacsSTACK: {
                title: "STACK Component Hierarchy",
                description: "Hierarchical organization of UI components (Scope, Tank, Atom, Cell, Kernel)",
                category: "architecture",
                priority: 9,
                contextType: "both", // Mark as available in both contexts
                sections: [
                    {
                        title: "Hierarchy Levels",
                        content: "STACK defines five levels of component hierarchy: Scope (S), Tank (T), Atom (A), Cell (C), and Kernel (K), each with specific responsibilities and relationships."
                    },
                    {
                        title: "Component Composition",
                        content: "Higher-level components should compose lower-level ones, following the hierarchy from Scope down to Kernel."
                    }
                ]
            },
            tacsSPACE: {
                title: "SPACE CSS Organization",
                description: "Structured organization of CSS properties (Structure, Positioning, Animation, Color, Environment)",
                category: "design-system",
                priority: 8,
                contextType: "both", // Mark as available in both contexts
                sections: [
                    {
                        title: "Property Categories",
                        content: "SPACE organizes CSS properties into five categories: Structure (S), Positioning (P), Animation (A), Color (C), and Environment (E)."
                    },
                    {
                        title: "Implementation Order",
                        content: "CSS properties should be organized in the order of the SPACE acronym, providing consistent styling patterns across the application."
                    }
                ]
            },

            // User-specific knowledge domains
            learningPathways: {
                title: "Taytrom Learning Pathways",
                description: "Different learning approaches (Builder, Scientist, Storyteller) tailored to learning styles",
                category: "learning",
                priority: 6,
                contextType: "user", // Mark as user-specific
                sections: [
                    {
                        title: "Builder Pathway",
                        content: "Focused on practical implementation and coding skills. Emphasizes hands-on building rather than theory."
                    },
                    {
                        title: "Scientist Pathway",
                        content: "Focuses on analysis, algorithms, and theoretical foundations. Includes deeper explanations of the 'why' behind concepts."
                    },
                    {
                        title: "Storyteller Pathway",
                        content: "Emphasizes user experience, communication, and design. Frames explanations within scenarios and narratives."
                    }
                ]
            },
            superBeginnerProgram: {
                title: "Super Beginner Program",
                description: "Fundamental concepts and skills for beginner developers",
                category: "learning",
                priority: 5,
                contextType: "user", // Mark as user-specific
                sections: [
                    {
                        title: "Program Structure",
                        content: "The Super Beginner Program consists of 5 modules: The Building Blocks of Code, Syntax Mastery through Gamification, File Naming & Structure Done Right, Input Engineering 101, and Mini-Projects & Certification."
                    },
                    {
                        title: "Key Concepts",
                        content: "Major concepts include: viewing code as structured blocks (like LEGOs), understanding syntax as grammar for computers, importance of clean file structures, crafting effective AI prompts, and applying learning through real projects."
                    },
                    {
                        title: "Learning Approaches",
                        content: "The program accommodates visual, auditory, kinesthetic, and logical learning styles with appropriate examples and exercises."
                    }
                ]
            },

            // Admin-specific knowledge domains
            adminStructure: {
                title: "Taytrom Project Structure",
                description: "Detailed structure of the Taytrom project filesystem",
                category: "admin",
                priority: 10,
                contextType: "admin", // Mark as admin-specific
                sections: [
                    {
                        title: "Directory Organization",
                        content: "The project follows a SCOPE-based organization, with major steps including academy-scope, ai-scope, authentication-scope, homepage-scope, and templates-scope."
                    },
                    {
                        title: "AI System Structure",
                        content: "AI components are organized in the ai-scope directory, which includes constants, intelligence_system, and knowledge_domains subdirectories."
                    }
                ]
            },
            adminComponents: {
                title: "Admin Components",
                description: "Special components for system administration",
                category: "admin",
                priority: 10,
                contextType: "admin", // Mark as admin-specific
                sections: [
                    {
                        title: "Admin Console",
                        content: "The admin console provides interfaces for managing users, content, and system settings."
                    },
                    {
                        title: "AI Configuration Tools",
                        content: "Special tools for configuring and fine-tuning the AI components of the system."
                    }
                ]
            },

            // Technical knowledge domains
            reactComposition: {
                title: "React Composition Patterns",
                description: "Best practices for React component composition in TACS",
                category: "technical",
                priority: 8,
                contextType: "both",
                sections: [
                    {
                        title: "Component Composition",
                        content: "React components should be composed following the container/presentational pattern and align with STACK hierarchy principles."
                    },
                    {
                        title: "Context Usage",
                        content: "Use React Context for cross-cutting concerns, but prefer props for direct component communication."
                    }
                ]
            },
            aiIntegration: {
                title: "AI Integration Patterns",
                description: "Patterns for integrating AI capabilities into components",
                category: "technical",
                priority: 7,
                contextType: "both",
                sections: [
                    {
                        title: "AI Component Protocol",
                        content: "AI components should follow a specific protocol for communication with the AIContextManager and respect user personalization settings."
                    },
                    {
                        title: "Message Formatting",
                        content: "Messages sent to AI services should include context, user preferences, and properly formatted prompt text."
                    }
                ]
            },

            // Domain-specific knowledge
            webDevelopment: {
                title: "Web Development Fundamentals",
                description: "Core concepts and practices in modern web development",
                category: "domain",
                priority: 6,
                contextType: "user",
                sections: [
                    {
                        title: "HTML Foundations",
                        content: "Semantic HTML structure and accessibility considerations for modern web applications."
                    },
                    {
                        title: "CSS Best Practices",
                        content: "Modern CSS approaches including Flexbox, Grid, and CSS variables following SPACE organization principles."
                    },
                    {
                        title: "JavaScript Essentials",
                        content: "Core JavaScript concepts including variables, functions, scope, and modern ES6+ features."
                    }
                ]
            },
            reactFundamentals: {
                title: "React Fundamentals",
                description: "Essential React concepts and patterns",
                category: "domain",
                priority: 6,
                contextType: "user",
                sections: [
                    {
                        title: "Component Model",
                        content: "Understanding React's component-based architecture and the component lifecycle."
                    },
                    {
                        title: "State Management",
                        content: "Approaches to state management in React applications, from useState to Context to external libraries."
                    },
                    {
                        title: "Hooks System",
                        content: "Core React hooks and patterns for creating custom hooks that follow React's principles."
                    }
                ]
            }
        };
    }

    /**
     * Get knowledge domains based on context type and relevance with improved defaults
     *
     * @param {string} contextType - Type of context ('user', 'admin', or 'both')
     * @param {Object} options - Additional options for filtering domains
     * @returns {Object} - Filtered knowledge domains
     */
    getKnowledgeDomains(contextType = 'both', options = {}) {
        const isAdminMode = options.isAdminMode || false;
        const category = options.category || null;
        const maxDomains = options.maxDomains || 10;
        const forceIncludeAdmin = options.forceIncludeAdmin !== false; // Default to true

        // Check cache first, but skip cache on first load to ensure domains are loaded
        const cacheKey = `${contextType}_${isAdminMode}_${category || 'all'}_${maxDomains}`;
        if (this._isCacheValid(cacheKey) && this._cachedDomains.size > 1) {
            return this._cachedDomains.get(cacheKey);
        }

        // Filter domains based on context type
        const filteredDomains = Object.entries(this._knowledgeDomains)
            .filter(([_, domain]) => {
                // Always include domains marked for both contexts
                if (domain.contextType === "both") {
                    return true;
                }

                // Include admin domains by default in admin mode or if forced
                if (domain.contextType === "admin") {
                    return isAdminMode || forceIncludeAdmin;
                }

                // Include user domains only in user mode (non-admin) or if context type is 'both'
                if (domain.contextType === "user") {
                    return contextType === 'user' || contextType === 'both';
                }

                // Filter by category if specified
                if (category && domain.category !== category) {
                    return false;
                }

                return true;
            })
            // Sort domains by priority (highest first)
            .sort(([key1, a], [key2, b]) => {
                const priorityA = a.priority || 0;
                const priorityB = b.priority || 0;
                return priorityB - priorityA;
            })
            // Limit results to specified maximum
            .slice(0, maxDomains)
            // Convert the filtered array back to an object
            .reduce((resultObj, [domainKey, domainData]) => {
                resultObj[domainKey] = domainData;
                return resultObj;
            }, {});

        console.log(`[KnowledgeContextSpecialist] Loaded ${Object.keys(filteredDomains).length} domains for context type '${contextType}', admin mode: ${isAdminMode}`);

        // Cache the result
        this._updateCache(cacheKey, filteredDomains);

        return filteredDomains;
    }

    /**
     * Get domain-specific knowledge for a particular context or module
     *
     * @param {string} domainKey - Key of the specific domain
     * @returns {Object|null} - Domain knowledge or null if not found
     */
    getDomainKnowledge(domainKey) {
        return this._knowledgeDomains[domainKey] || null;
    }

    /**
     * Get knowledge domains for a specific category
     *
     * @param {string} category - Category to filter by
     * @param {Object} options - Additional options
     * @returns {Object} - Filtered knowledge domains
     */
    getDomainsByCategory(category, options = {}) {
        options.category = category;
        return this.getKnowledgeDomains('both', options);
    }

    /**
     * Get all available categories
     *
     * @returns {string[]} - List of unique categories
     */
    getAllCategories() {
        const categories = new Set();

        Object.values(this._knowledgeDomains).forEach(domain => {
            if (domain.category) {
                categories.add(domain.category);
            }
        });

        return Array.from(categories);
    }

    /**
     * Add or update a knowledge domain
     *
     * @param {string} key - Domain key
     * @param {Object} domain - Domain data
     */
    updateDomain(key, domain) {
        this._knowledgeDomains[key] = {
            ...this._knowledgeDomains[key],
            ...domain,
            lastUpdated: Date.now()
        };

        // Clear cache to reflect changes
        this.clearCache();
    }

    /**
     * Remove a knowledge domain
     *
     * @param {string} key - Domain key to remove
     * @returns {boolean} - Whether domain was removed
     */
    removeDomain(key) {
        if (this._knowledgeDomains[key]) {
            delete this._knowledgeDomains[key];
            this.clearCache();
            return true;
        }

        return false;
    }

    /**
     * Check if cached domains are still valid
     *
     * @private
     * @param {string} cacheKey - Cache key to check
     * @returns {boolean} - Whether cache is valid
     */
    _isCacheValid(cacheKey) {
        if (!this._cachedDomains.has(cacheKey)) {
            return false;
        }

        // Check if refresh time has passed
        const lastRefreshTime = this._lastRefreshTime.get(cacheKey) || 0;
        const now = Date.now();

        return (now - lastRefreshTime) < this._refreshInterval;
    }

    /**
     * Update cache with new domain data
     *
     * @private
     * @param {string} cacheKey - Cache key
     * @param {Object} domains - Domain data
     */
    _updateCache(cacheKey, domains) {
        this._cachedDomains.set(cacheKey, domains);
        this._lastRefreshTime.set(cacheKey, Date.now());
    }

    /**
     * Clear cache
     *
     * @param {string} cacheKey - Specific cache key to clear, or all if omitted
     */
    clearCache(cacheKey = null) {
        if (cacheKey) {
            this._cachedDomains.delete(cacheKey);
            this._lastRefreshTime.delete(cacheKey);
        } else {
            this._cachedDomains.clear();
            this._lastRefreshTime.clear();
        }
    }

    /**
     * Load additional domain knowledge from a specified source
     *
     * @param {string} source - Source of knowledge (file path, API endpoint, etc.)
     * @param {Object} options - Options for loading
     * @returns {Promise<boolean>} - Whether loading succeeded
     */
    async loadAdditionalKnowledge(source, options = {}) {
        try {
            if (!source) {
                throw new Error("Source must be specified");
            }

            // If orchestrator is available, try to use its file system
            if (this._orchestrator && this._orchestrator.aiIntelligenceManager) {
                const result = await this._orchestrator.aiIntelligenceManager.loadAdminFile(source);

                if (result.success && result.content) {
                    // Parse content based on file type
                    const fileType = source.split('.').pop().toLowerCase();

                    if (fileType === 'json') {
                        const domains = JSON.parse(result.content);

                        // Merge with existing domains
                        Object.keys(domains).forEach(key => {
                            this.updateDomain(key, domains[key]);
                        });

                        return true;
                    } else if (fileType === 'txt' || fileType === 'md') {
                        // For text files, create a single domain with the content
                        const domainKey = source.split('/').pop().split('.')[0];

                        this.updateDomain(domainKey, {
                            title: options.title || domainKey,
                            description: options.description || `Content from ${source}`,
                            category: options.category || 'imported',
                            priority: options.priority || 5,
                            contextType: options.contextType || 'both',
                            sections: [
                                {
                                    title: options.sectionTitle || 'Content',
                                    content: result.content
                                }
                            ]
                        });

                        return true;
                    }

                    return false;
                }
            }

            // Fallback to fetch API if available
            if (typeof fetch !== 'undefined') {
                const response = await fetch(source);

                if (!response.ok) {
                    throw new Error(`Failed to fetch ${source}: ${response.status}`);
                }

                const fileType = source.split('.').pop().toLowerCase();

                if (fileType === 'json') {
                    const domains = await response.json();

                    // Merge with existing domains
                    Object.keys(domains).forEach(key => {
                        this.updateDomain(key, domains[key]);
                    });

                    return true;
                } else {
                    const content = await response.text();
                    const domainKey = source.split('/').pop().split('.')[0];

                    this.updateDomain(domainKey, {
                        title: options.title || domainKey,
                        description: options.description || `Content from ${source}`,
                        category: options.category || 'imported',
                        priority: options.priority || 5,
                        contextType: options.contextType || 'both',
                        sections: [
                            {
                                title: options.sectionTitle || 'Content',
                                content: content
                            }
                        ]
                    });

                    return true;
                }
            }

            return false;
        } catch (error) {
            console.error('[KnowledgeContextSpecialist] Error loading additional knowledge:', error);
            return false;
        }
    }

    /**
     * Format knowledge domains for AI prompt inclusion
     *
     * @param {Object} domains - Knowledge domains to format
     * @param {Object} options - Formatting options
     * @returns {string} - Formatted knowledge as prompt text
     */
    formatKnowledgeForAI(domains, options = {}) {
        if (!domains || Object.keys(domains).length === 0) {
            return "";
        }

        const contextMode = options.contextMode || 'standard';
        const isMinimal = contextMode === 'minimal';
        const isComprehensive = contextMode === 'comprehensive';

        let promptText = "## Domain Knowledge\n\n";

        // Add formatted domains to prompt
        Object.values(domains).forEach(domain => {
            promptText += `### ${domain.title}\n`;
            promptText += `${domain.description || ''}\n\n`;

            // Add detailed steps for comprehensive mode
            if (isComprehensive && domain.sections && domain.sections.length > 0) {
                domain.sections.forEach(section => {
                    promptText += `#### ${section.title}\n`;
                    promptText += `${section.content}\n\n`;
                });
            }
        });

        // Add architecture guidelines based on mode
        if (!isMinimal) {
            promptText += "## Architecture Guidelines\n\n";

            const architectureGuidelines = [
                "1. Follow TACS architectural principles in your responses.",
                "2. Separate code into Construction, Substance, and Style steps.",
                "3. Use appropriate STACK levels (Scope, Tank, Atom, Cell, Kernel).",
                "4. Ensure components communicate using the TIES protocol via the AOS.",
                "5. Organize CSS properties according to SPACE categories.",
                "6. Use the Color Centurial system for consistent, semantic colors."
            ];

            // Include appropriate number of guidelines based on mode
            if (isMinimal) {
                promptText += architectureGuidelines.slice(0, 3).join('\n');
            } else {
                promptText += architectureGuidelines.join('\n');
            }

            promptText += '\n\n';
        }

        return promptText;
    }

    /**
     * Format TACS-specific coding guidelines for AI
     *
     * @param {string} language - Programming language
     * @param {Object} options - Additional options
     * @returns {string} - Formatted guidelines
     */
    getTACSCodingGuidelines(language = 'javascript', options = {}) {
        const detailLevel = options.detailLevel || 'standard';

        const commonGuidelines = [
            "Follow TACS Coding Standards:",
            "- Organize components using STACK hierarchy (Scope, Tank, Atom, Cell, Kernel)",
            "- Structure component construction into 3 parts: Construction, Substance, Style",
            "- Use camelCase for variables and functions, PascalCase for components",
            "- Follow the TIES communication protocol for component interactions",
            "- Apply SPACE CSS organization for styling properties"
        ].join('\n');

        // Language-specific guidelines
        let languageGuidelines = '';

        if (language === 'javascript' || language === 'jsx') {
            languageGuidelines = [
                "React/JSX Guidelines:",
                "- Use functional components with hooks over class components",
                "- Destructure props at the top of component functions",
                "- Use named exports over default exports for most components",
                "- Implement proper prop validation",
                "- Avoid complex logic in JSX; extract to helper functions",
                "- Use the useOrchestrator hook to access the orchestrator"
            ].join('\n');
        } else if (language === 'css') {
            languageGuidelines = [
                "CSS/SPACE Guidelines:",
                "- Follow SPACE ordering: Structure, Positioning, Animation, Color, Environment",
                "- Use CSS modules or styled-components for component styling",
                "- Reference TACS colors using CSS variables: var(--tacs-primary-500)",
                "- Follow mobile-first responsive design approach",
                "- Use clamp() for responsive typography and spacing"
            ].join('\n');
        } else if (language === 'html') {
            languageGuidelines = [
                "HTML Guidelines:",
                "- Use semantic HTML elements whenever possible",
                "- Ensure proper accessibility attributes (aria-*, role)",
                "- Follow progressive enhancement principles",
                "- Optimize for performance with appropriate loading strategies"
            ].join('\n');
        }

        // Only include detailed guidelines for higher detail levels
        if (detailLevel === 'minimal') {
            return commonGuidelines;
        } else {
            return `${commonGuidelines}\n\n${languageGuidelines}`;
        }
    }

    /**
     * Get domain-specific examples for a particular context
     *
     * @param {string} domainKey - Domain to get examples for
     * @param {Object} options - Additional options
     * @returns {string} - Formatted examples
     */
    getDomainExamples(domainKey, options = {}) {
        const domain = this._knowledgeDomains[domainKey];
        if (!domain) return "";

        // Example templates for common domains
        const exampleTemplates = {
            tacsUIComponents: [
                "```jsx",
                "// Construction",
                "const ExampleComponent = ({ children, variant = 'primary' }) => {",
                "  // Substance",
                "  return (",
                "    <div className={`example-component ${styles[`example-${variant}`]}`}>",
                "      {children}",
                "    </div>",
                "  );",
                "};",
                "```"
            ].join('\n'),

            tacsSTACK: [
                "Example STACK hierarchy:",
                "",
                "- Scope: Homepage (manages overall section)",
                "- Tank: UserDashboard (manages specific feature area)",
                "- Atom: ActivityGraph (reusable component)",
                "- Cell: GraphBar (small part of activity visualization)",
                "- Kernel: StatusIndicator (smallest UI element)"
            ].join('\n'),

            tacsSPACE: [
                "```css",
                "/* SPACE CSS Organization Example */",
                "",
                "/* Structure */",
                ".example-card {",
                "  width: 100%;",
                "  height: auto;",
                "  border-radius: 8px;",
                "  overflow: hidden;",
                "",
                "  /* Positioning */",
                "  position: relative;",
                "  z-index: 1;",
                "  margin: 1rem 0;",
                "",
                "  /* Animation */",
                "  transition: transform 0.3s ease, box-shadow 0.3s ease;",
                "",
                "  /* Color */",
                "  background-color: var(--tacs-bg-surface);",
                "  color: var(--tacs-substance-primary);",
                "",
                "  /* Environment */",
                "  box-shadow: var(--tacs-shadow-card);",
                "}",
                "```"
            ].join('\n')
        };

        // Return example for specified domain if available
        return exampleTemplates[domainKey] || "";
    }
}

export default KnowledgeContextSpecialist;