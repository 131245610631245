/**
 * Specialist for knowledge domains and admin file system access in the Chat AI Admin component
 */
const Specialist_Knowledge_Contexts_Chat_AI_Admin = {
    // State initialization
    initializeKnowledgeState() {
        return {
            adminKnowledge: null,
            isAdminKnowledgeLoaded: false,
            viewingAdminFile: null,
            lastFilePath: null,
            availableKnowledgeDomains: []
        };
    },

    // Load available knowledge domains from AIContextManager
    loadAvailableKnowledgeDomains: async (aiManagerRef, addToSessionLogs) => {
        if (!aiManagerRef.current) {
            addToSessionLogs('Cannot load knowledge domains - AI Manager not initialized', 'warning');
            return [];
        }

        try {
            if (!aiManagerRef.current.knowledgeContextSpecialist) {
                addToSessionLogs('Knowledge Context Specialist not available', 'warning');
                return [];
            }

            const domainData = aiManagerRef.current.knowledgeContextSpecialist.getKnowledgeDomains('both', {
                isAdminMode: true,
                maxDomains: 20
            });

            if (!domainData || Object.keys(domainData).length === 0) {
                addToSessionLogs('No knowledge domains returned from specialist', 'warning');
                return [];
            }

            const domains = Object.entries(domainData).map(([key, domain]) => ({
                id: key,
                title: domain.title || key,
                description: domain.description || '',
                category: domain.category || 'uncategorized',
                priority: domain.priority || domain.relevance || 5,
                contextType: domain.contextType || 'both'
            }));

            addToSessionLogs(`Loaded ${domains.length} knowledge domains`);
            return domains;
        } catch (error) {
            console.error('[Admin] Error loading knowledge domains:', error);
            addToSessionLogs(`Error loading knowledge domains: ${error.message}`, 'error');
            return [];
        }
    },

    // Force refresh of knowledge domains (for UI updates)
    forceRefreshKnowledgeDomains: (aiManagerRef, addToSessionLogs) => {
        if (aiManagerRef.current && aiManagerRef.current.knowledgeContextSpecialist) {
            try {
                const domainData = aiManagerRef.current.knowledgeContextSpecialist.getKnowledgeDomains('both', {
                    isAdminMode: true,
                    maxDomains: 20
                });

                console.log('[Admin] Raw domain data:', domainData);

                if (domainData && Object.keys(domainData).length > 0) {
                    const domains = Object.entries(domainData).map(([key, domain]) => ({
                        id: key,
                        title: domain.title || key,
                        description: domain.description || '',
                        category: domain.category || 'uncategorized',
                        priority: domain.priority || domain.relevance || 5,
                        contextType: domain.contextType || 'both'
                    }));

                    addToSessionLogs(`Force refreshed ${domains.length} knowledge domains`);
                    return domains;
                }
            } catch (error) {
                console.error('[Admin] Error in force refresh:', error);
                return [];
            }
        }
        return [];
    },

    // Check file system status
    checkFileSystemStatus: (aiManagerRef, addToSessionLogs) => {
        if (!aiManagerRef.current) {
            addToSessionLogs("AI Manager not initialized", 'error');
            return false;
        }

        const fsExists = typeof window !== 'undefined' && !!window.fs;
        const readFileExists = fsExists && typeof window.fs.readFile === 'function';

        addToSessionLogs(`File system status: ${fsExists ? 'Available' : 'Not available'}`,
            fsExists ? 'info' : 'warning');

        if (fsExists) {
            addToSessionLogs(`readFile method: ${readFileExists ? 'Available' : 'Not available'}`,
                readFileExists ? 'info' : 'warning');

            const isFallback = window.fs._isFallback === true;
            addToSessionLogs(`Using ${isFallback ? 'fallback' : 'native'} file system`,
                isFallback ? 'warning' : 'info');

            return readFileExists;
        }

        return false;
    },

    // Reinitialize file system
    reinitializeFileSystem: async (aiManagerRef, addToSessionLogs) => {
        if (!aiManagerRef.current || !aiManagerRef.current.initializeFileSystem) {
            addToSessionLogs("Cannot reinitialize - AI Manager or method not available", 'error');
            return false;
        }

        try {
            addToSessionLogs("Reinitializing file system...");
            const result = await aiManagerRef.current.initializeFileSystem();

            if (result) {
                addToSessionLogs("File system reinitialized successfully");
            } else {
                addToSessionLogs("File system reinitialization failed", 'error');
            }

            return result;
        } catch (error) {
            console.error('[Admin] Error reinitializing file system:', error);
            addToSessionLogs(`Error reinitializing file system: ${error.message}`, 'error');
            return false;
        }
    },

    // Load admin knowledge
    loadAdminKnowledge: async (aiManagerRef, isAuthenticated, addToSessionLogs, checkFileSystemStatus, reinitializeFileSystem) => {
        if (!aiManagerRef.current) {
            addToSessionLogs("AI Manager not initialized", 'error');
            return { success: false, error: "AI Manager not initialized" };
        }

        if (!isAuthenticated) {
            addToSessionLogs("Cannot load admin knowledge - not authenticated", 'warning');
            return { success: false, error: "Not authenticated" };
        }

        try {
            console.log("[Admin] Starting to load admin knowledge...");
            addToSessionLogs("Loading admin knowledge...");

            // Check file system status first
            checkFileSystemStatus(aiManagerRef, addToSessionLogs);

            // Call the method on AIContextManager
            console.log("[Admin] Calling getAdminKnowledge on AIContextManager");
            const adminData = await aiManagerRef.current.getAdminKnowledge();
            console.log("[Admin] getAdminKnowledge result:", adminData);

            if (adminData.admin && adminData.admin.projectStructure) {
                console.log("[Admin] Successfully loaded admin knowledge with project structure");
                addToSessionLogs(`Project structure loaded (${adminData.admin.projectStructure.length} bytes)`);

                if (adminData.admin.loadedPath) {
                    addToSessionLogs(`Loaded from path: ${adminData.admin.loadedPath}`);
                } else {
                    // If using fallback, show that
                    addToSessionLogs("Loaded using fallback mechanism");
                }

                return { success: true, adminData };
            } else {
                console.error("[Admin] Failed to load admin knowledge:",
                    adminData.error || adminData.admin?.error || "Unknown error");

                const errorMsg = adminData.error || adminData.admin?.error || "Unknown error";
                addToSessionLogs(`Error loading admin knowledge: ${errorMsg}`, 'error');

                // Try reinitializing the file system
                addToSessionLogs("Attempting to reinitialize file system...");
                await reinitializeFileSystem(aiManagerRef, addToSessionLogs);

                return { success: false, error: errorMsg };
            }
        } catch (error) {
            console.error("[Admin] Error loading admin knowledge:", error);
            console.error("[Admin] Error stack:", error.stack);
            addToSessionLogs(`Error loading admin knowledge: ${error.message}`, 'error');
            return { success: false, error: error.message };
        }
    },

    // Test file access
    testFileAccess: async (aiManagerRef, addToSessionLogs, reinitializeFileSystem, setLastFilePath, viewAdminFile) => {
        if (!aiManagerRef.current) {
            addToSessionLogs("AI Manager not initialized", 'error');
            return;
        }

        try {
            addToSessionLogs("Testing file system access...");

            // First check basic file system availability
            const fsExists = typeof window !== 'undefined' && !!window.fs;
            const readFileExists = fsExists && typeof window.fs.readFile === 'function';

            addToSessionLogs(`File system API available: ${fsExists ? 'Yes' : 'No'}`,
                fsExists ? 'info' : 'error');

            if (!fsExists) {
                addToSessionLogs("No file system API available. Attempting to initialize...", 'warning');
                await reinitializeFileSystem(aiManagerRef, addToSessionLogs);
                return;
            }

            addToSessionLogs(`readFile method available: ${readFileExists ? 'Yes' : 'No'}`,
                readFileExists ? 'info' : 'error');

            if (!readFileExists) {
                addToSessionLogs("readFile method not available. Attempting to reinitialize...", 'warning');
                await reinitializeFileSystem(aiManagerRef, addToSessionLogs);
                return;
            }

            // Try to read project structure file
            try {
                const paths = [
                    'project-structure.txt',
                    'SCOPE/ai-scope/knowledge_domains/admin/structure/project-structure.txt',
                    'src/SCOPE/ai-scope/knowledge_domains/admin/structure/project-structure.txt',
                    'knowledge_domains/admin/structure/project-structure.txt'
                ];

                addToSessionLogs(`Attempting to read project structure from ${paths.length} possible paths...`);

                for (const path of paths) {
                    try {
                        addToSessionLogs(`Trying path: ${path}...`);
                        const content = await window.fs.readFile(path, { encoding: 'utf8' });

                        if (content && content.length > 0) {
                            addToSessionLogs(`Successfully read from path: ${path} (${content.length} bytes)`, 'info');
                            setLastFilePath(path);
                            // View the file content
                            viewAdminFile(content, path);
                            break;
                        }
                    } catch (err) {
                        addToSessionLogs(`Failed to read from path: ${path} - ${err.message}`, 'warning');
                    }
                }
            } catch (error) {
                addToSessionLogs(`Error testing file paths: ${error.message}`, 'error');
            }
        } catch (error) {
            console.error("[Admin] Error testing file access:", error);
            addToSessionLogs(`Error testing file access: ${error.message}`, 'error');
        }
    },

    // Prepare AI request with knowledge domains
    prepareAIRequestWithDomains: async (userInput, aiManagerRef, addToSessionLogs, conversationHistory, conversationId, moduleContext, systemPrompt, temperature, maxTokens, isAdminKnowledgeLoaded, adminKnowledge, extendedThinkingEnabled, thinkingBudget, isDark, debugMode) => {
        if (!aiManagerRef.current) {
            throw new Error('AI system is not initialized');
        }

        // Ensure aiManagerRef is defined
        if (!aiManagerRef.current) {
            addToSessionLogs("AI Manager not initialized", 'error');
            throw new Error('AI Manager not initialized');
        }

        // Get domains directly from the knowledgeContextSpecialist
        let domainData = {};
        if (aiManagerRef.current.knowledgeContextSpecialist) {
            domainData = aiManagerRef.current.knowledgeContextSpecialist.getKnowledgeDomains('both', {
                isAdminMode: true,
                maxDomains: 20
            });
            console.log("[Admin Debug] Retrieved domains:", Object.keys(domainData).length);
        }

        let enhancedSystemPrompt = systemPrompt || "You are Claude, an AI assistant integrated into the Taytrom system.";
        enhancedSystemPrompt += "\n\nIMPORTANT: Maintain awareness of all information shared in our conversation. If the user mentions preferences or personal details, remember those details and refer back to them appropriately in future responses.\n\n";

        // Add instructions for image analysis
        enhancedSystemPrompt += "\n\nYou can analyze images shared by the user. When an image is shared, describe what you see in detail and address any questions related to the image content. If the image contains code, diagrams, or technical content, provide detailed analysis and explanations.\n\n";

        // Add domain information to system prompt
        Object.entries(domainData).forEach(([key, domain]) => {
            enhancedSystemPrompt += `### ${domain.title}\n`;
            enhancedSystemPrompt += `${domain.description || ''}\n\n`;

            // Include steps for more detailed context
            if (domain.sections && domain.sections.length > 0) {
                domain.sections.forEach(section => {
                    enhancedSystemPrompt += `#### ${section.title}\n`;
                    enhancedSystemPrompt += `${section.content}\n\n`;
                });
            }
        });

        // Ensure all items in history have just role and content fields
        const processedHistory = conversationHistory.map(item => ({
            role: item.role,
            content: item.content,
            images: item.images
        }));

        // Prepare request options
        return {
            conversationId,
            moduleContext: moduleContext || {
                title: 'Taytrom Administration',
                focus: 'system administration and development',
            },
            conversationHistory: processedHistory,
            applicationState: {
                theme: isDark ? 'dark' : 'light',
                debugMode,
                route: window.location.pathname,
            },
            systemPrompt: enhancedSystemPrompt,
            temperature: temperature,
            maxTokens: maxTokens,
            adminKnowledge: isAdminKnowledgeLoaded ? adminKnowledge : null,
            knowledgeDomains: domainData,
            thinkingEnabled: extendedThinkingEnabled,
            thinkingBudget: thinkingBudget,
            includeThinkingInResponse: true
        };
    }
};

export default Specialist_Knowledge_Contexts_Chat_AI_Admin;