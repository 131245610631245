import React, { useState, useEffect, useRef, useMemo, useCallback, memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    FileText, FileCode, FileBox, Share2, Clock, Shield, Award,
    ChevronDown, ChevronRight, Download, Pencil, Eye, MessageSquare,
    Copy, CheckCircle, AlertTriangle, Lock, ArrowRight, Settings,
    UserPlus, FileX, Heart, Info
} from 'lucide-react';

import styles from './Compiler_7_Steps_InteractiveTour_Specialists_Writing.module.css';

/**
 * Section 6 of Production Demo: Document Sharing & Export
 * Simplified version with minimal animations to prevent render loops
 */
const Compiler_7_Steps_InteractiveTour_Specialists_Writing = memo(({
                                                                         isDark = true,
                                                                         onComplete,
                                                                         demoSpeed = 'normal',
                                                                         skipIntro = false
                                                                     }) => {
    // Component state
    const [currentStep, setCurrentStep] = useState(skipIntro ? 1 : 0);
    const [selectedFormat, setSelectedFormat] = useState(null);
    const [selectedPermission, setSelectedPermission] = useState('editor');
    const [showVersionHistory, setShowVersionHistory] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [exportProgress, setExportProgress] = useState(0);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationData, setNotificationData] = useState({ text: '', type: 'success' });
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [collaborators, setCollaborators] = useState([
        { id: 1, name: 'White Rabbit', email: 'rabbit@wonderland.ai', role: 'editor', color: '#e57373', avatar: 'WR', isActive: true },
        { id: 2, name: 'Cheshire Cat', email: 'cheshire@wonderland.ai', role: 'commenter', color: '#81c784', avatar: 'CH', isActive: false }
    ]);
    const [newCollaborator, setNewCollaborator] = useState({ email: '', role: 'viewer' });

    // Refs for timers and cleanups
    const timerRefs = useRef({});

    // Memoized data structures
    const exportFormats = useMemo(() => [
        {
            id: 'docx',
            name: 'DOCX',
            description: 'Microsoft Word Document',
            fileExtension: '.docx',
            icon: <FileText size={24} />,
            character: 'White Rabbit',
            characterImage: '🐇',
            testimony: 'Perfect for formal documents.',
            color: '#4285F4'
        },
        {
            id: 'pdf',
            name: 'PDF',
            description: 'Portable Document Format',
            fileExtension: '.pdf',
            icon: <FileBox size={24} />,
            character: 'Caterpillar',
            characterImage: '🐛',
            testimony: 'Stable format. Appears exactly the same for all viewers.',
            color: '#EA4335'
        },
        {
            id: 'markdown',
            name: 'Markdown',
            description: 'Plain text formatting syntax',
            fileExtension: '.md',
            icon: <FileCode size={24} />,
            character: 'March Hare',
            characterImage: '🐇',
            testimony: 'Simple! Clean! Portable!',
            color: '#34A853'
        },
        {
            id: 'html',
            name: 'HTML',
            description: 'Web page format',
            fileExtension: '.html',
            icon: <FileCode size={24} />,
            character: 'Mad Hatter',
            characterImage: '🎩',
            testimony: 'For web publishing.',
            color: '#FBBC05'
        }
    ], []);

    const permissionRoles = useMemo(() => [
        {
            id: 'viewer',
            name: 'Viewer',
            description: 'Can only view the document',
            icon: <Eye size={20} />,
            character: 'Dormouse',
            characterImage: '🐭',
            reaction: 'Can only view documents',
            color: '#B4C7E7'
        },
        {
            id: 'commenter',
            name: 'Commenter',
            description: 'Can view and add comments',
            icon: <MessageSquare size={20} />,
            character: 'Dodo',
            characterImage: '🦤',
            reaction: 'May view AND comment!',
            color: '#C9DAF8'
        },
        {
            id: 'editor',
            name: 'Editor',
            description: 'Can view, comment and edit',
            icon: <Pencil size={20} />,
            character: 'Duchess',
            characterImage: '👸',
            reaction: 'Full editing privileges!',
            color: '#A4C2F4'
        }
    ], []);

    // Format time elapsed
    const formatTimeElapsed = useCallback((seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }, []);

    // Helper for notifications
    const showNotificationMessage = useCallback((text, type = 'success') => {
        if (timerRefs.current.notification) {
            clearTimeout(timerRefs.current.notification);
        }

        setNotificationData({ text, type });
        setShowNotification(true);

        timerRefs.current.notification = setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    }, []);

    // Handle format selection
    const handleSelectFormat = useCallback((format) => {
        if (!format || selectedFormat?.id === format.id) return;

        setSelectedFormat(format);

        // Simulate export progress with cleanup
        if (timerRefs.current.exportProgress) {
            clearInterval(timerRefs.current.exportProgress);
        }

        setExportProgress(0);

        const progressInterval = setInterval(() => {
            setExportProgress(prev => {
                const newValue = prev + 5;
                if (newValue >= 100) {
                    clearInterval(progressInterval);
                    showNotificationMessage(`${format.name} export ready!`);
                    return 100;
                }
                return newValue;
            });
        }, 80);

        timerRefs.current.exportProgress = progressInterval;
    }, [selectedFormat?.id, showNotificationMessage]);

    // Handle permission selection
    const handleSelectPermission = useCallback((permission) => {
        if (!permission || selectedPermission === permission) return;

        setSelectedPermission(permission);
    }, [selectedPermission]);

    // Generate share link
    const generateShareLink = useCallback(() => {
        // Simulate progress with cleanup
        if (timerRefs.current.shareProgress) {
            clearInterval(timerRefs.current.shareProgress);
        }

        setExportProgress(0);

        const progressInterval = setInterval(() => {
            setExportProgress(prev => {
                const newValue = prev + 5;
                if (newValue >= 100) {
                    clearInterval(progressInterval);
                    setShareLink('https://taytrom.ai/shared/wonderland-trial-28fj9');
                    showNotificationMessage('Share link generated successfully!');
                    return 100;
                }
                return newValue;
            });
        }, 80);

        timerRefs.current.shareProgress = progressInterval;
    }, [showNotificationMessage]);

    // Copy share link to clipboard
    const copyShareLink = useCallback(() => {
        if (!shareLink) return;

        navigator.clipboard.writeText(shareLink).then(() => {
            setCopiedToClipboard(true);
            showNotificationMessage('Link copied to clipboard!');

            if (timerRefs.current.clipboard) {
                clearTimeout(timerRefs.current.clipboard);
            }

            timerRefs.current.clipboard = setTimeout(() => {
                setCopiedToClipboard(false);
            }, 2000);
        }).catch(err => {
            showNotificationMessage('Failed to copy link', 'error');
        });
    }, [shareLink, showNotificationMessage]);

    // Add a new collaborator
    const addCollaborator = useCallback(() => {
        if (!newCollaborator.email) {
            showNotificationMessage('Please enter an email address', 'error');
            return;
        }

        const emailParts = newCollaborator.email.split('@');
        const name = emailParts[0].charAt(0).toUpperCase() + emailParts[0].charAt(1).toUpperCase();
        const colors = ['#FFB74D', '#9575CD', '#4FC3F7', '#AED581', '#FF8A65'];
        const color = colors[Math.floor(Math.random() * colors.length)];

        setCollaborators(prev => [
            ...prev,
            {
                id: prev.length + 1,
                name: `New User (${name})`,
                email: newCollaborator.email,
                role: newCollaborator.role,
                color: color,
                avatar: name,
                isActive: false,
                lastActive: 'Just added'
            }
        ]);

        setNewCollaborator({ email: '', role: 'viewer' });
        showNotificationMessage('New collaborator added!');
    }, [newCollaborator, showNotificationMessage]);

    // Progress to next step
    const handleNextStep = useCallback(() => {
        if (currentStep < 5) {
            setCurrentStep(prev => prev + 1);
        } else {
            if (onComplete) onComplete();
        }
    }, [currentStep, onComplete]);

    // Trial timer
    useEffect(() => {
        if (currentStep > 0) {
            if (timerRefs.current.trial) {
                clearInterval(timerRefs.current.trial);
            }

            timerRefs.current.trial = setInterval(() => {
                setTimeElapsed(prev => prev + 1);
            }, 1000);
        }

        return () => {
            if (timerRefs.current.trial) {
                clearInterval(timerRefs.current.trial);
            }
        };
    }, [currentStep]);

    // Intro animation/setup
    useEffect(() => {
        if (!skipIntro) {
            const introTimer = setTimeout(() => {
                setCurrentStep(1);
            }, 2000);

            timerRefs.current.intro = introTimer;
        }

        return () => {
            if (timerRefs.current.intro) {
                clearTimeout(timerRefs.current.intro);
            }
        };
    }, [skipIntro]);

    // Cleanup all timers on unmount
    useEffect(() => {
        return () => {
            Object.values(timerRefs.current).forEach(timer => {
                clearTimeout(timer);
                clearInterval(timer);
            });
        };
    }, []);

    // Simplified components

    // Header
    const SectionHeader = memo(() => (
        <div className={styles.sectionHeader}>
            <div className={styles.headerContent}>
                <h2 className={styles.sectionTitle}>Document Sharing & Export</h2>
                <p className={styles.sectionSubtitle}>Share your work and export it in various formats</p>
            </div>

            {currentStep > 0 && (
                <div className={styles.trialInfo}>
                    <div className={styles.trialTimer}>
                        <Clock size={14} />
                        <span>{formatTimeElapsed(timeElapsed)}</span>
                    </div>
                </div>
            )}
        </div>
    ));

    // Intro Screen
    const IntroScreen = memo(() => (
        <div className={styles.introAnimation}>
            <h3>Ready to share your document</h3>
            <p>Explore formats and sharing options</p>
        </div>
    ));

    // Export Formats Panel
    const ExportFormatsPanel = memo(() => (
        <div className={styles.panelSection}>
            <h3 className={styles.panelTitle}>
                <FileText size={20} />
                Export Document
            </h3>
            <p className={styles.panelDescription}>
                Choose a format to export your document.
            </p>

            <div className={styles.formatGrid}>
                {exportFormats.map((format) => (
                    <div
                        key={format.id}
                        className={`${styles.formatCard} ${selectedFormat?.id === format.id ? styles.selectedFormat : ''}`}
                        onClick={() => handleSelectFormat(format)}
                        style={{
                            borderColor: format.color,
                            backgroundColor: selectedFormat?.id === format.id ? `${format.color}15` : 'rgba(0,0,0,0)'
                        }}
                    >
                        <div className={styles.formatIcon} style={{ color: format.color }}>
                            {format.icon}
                        </div>
                        <div className={styles.formatInfo}>
                            <h4>{format.name}</h4>
                            <div className={styles.formatDescription}>{format.description}</div>

                            {selectedFormat?.id === format.id && (
                                <div className={styles.formatDetails}>
                                    <div className={styles.fileExtension}>
                                        Extension: <strong>{format.fileExtension}</strong>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {selectedFormat && (
                <div className={styles.exportProgress}>
                    <div className={styles.progressLabel}>
                        {exportProgress < 100 ? `Preparing ${selectedFormat.name} export...` : `${selectedFormat.name} export ready!`}
                    </div>
                    <div className={styles.progressBar}>
                        <div
                            className={styles.progressFill}
                            style={{
                                width: `${exportProgress}%`,
                                backgroundColor: selectedFormat.color
                            }}
                        />
                    </div>

                    {exportProgress === 100 && (
                        <div className={styles.exportActions}>
                            <button className={styles.downloadButton} style={{ backgroundColor: selectedFormat.color }}>
                                <Download size={16} />
                                Download {selectedFormat.name}
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    ));

    // Sharing Permissions Panel
    const SharingPermissionsPanel = memo(() => (
        <div className={styles.panelSection}>
            <h3 className={styles.panelTitle}>
                <Share2 size={20} />
                Share Document
            </h3>
            <p className={styles.panelDescription}>
                Share your document with collaborators.
            </p>

            <div className={styles.sharingControls}>
                <div className={styles.emailInput}>
                    <input
                        type="email"
                        placeholder="Enter email address"
                        className={styles.emailField}
                        value={newCollaborator.email}
                        onChange={(e) => setNewCollaborator(prev => ({
                            ...prev,
                            email: e.target.value
                        }))}
                    />

                    <div className={styles.permissionSelect}>
                        {permissionRoles.map((role) => (
                            <div
                                key={role.id}
                                className={`${styles.permissionOption} ${newCollaborator.role === role.id ? styles.selectedPermission : ''}`}
                                onClick={() => setNewCollaborator(prev => ({
                                    ...prev,
                                    role: role.id
                                }))}
                            >
                                <div className={styles.permissionIcon} style={{ color: newCollaborator.role === role.id ? role.color : 'inherit' }}>
                                    {role.icon}
                                </div>
                                <div className={styles.permissionLabel}>{role.name}</div>
                            </div>
                        ))}
                    </div>

                    <button className={styles.addButton} onClick={addCollaborator}>
                        <UserPlus size={16} />
                        Add
                    </button>
                </div>

                <div className={styles.collaborators}>
                    <h4 className={styles.collaboratorsTitle}>Current Collaborators</h4>

                    <div className={styles.collaboratorsList}>
                        {collaborators.map((collaborator) => (
                            <div key={collaborator.id} className={styles.collaboratorItem}>
                                <div className={styles.collaboratorAvatar}>
                                    <div className={styles.avatarImage} style={{ backgroundColor: collaborator.color }}>
                                        {collaborator.avatar}
                                    </div>
                                    {collaborator.isActive && <div className={styles.activeIndicator}></div>}
                                </div>
                                <div className={styles.collaboratorInfo}>
                                    <div className={styles.collaboratorName}>{collaborator.name}</div>
                                    <div className={styles.collaboratorEmail}>{collaborator.email}</div>
                                </div>
                                <div
                                    className={styles.collaboratorRole}
                                    style={{ backgroundColor: permissionRoles.find(r => r.id === collaborator.role)?.color + '30' || 'rgba(0,0,0,0)' }}
                                >
                                    {permissionRoles.find(r => r.id === collaborator.role)?.name || collaborator.role}
                                </div>
                                <div className={styles.collaboratorActions}>
                                    <button className={styles.actionButton} title="Remove collaborator">
                                        <FileX size={16} />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.sharePanel}>
                    <button className={styles.generateLinkButton} onClick={generateShareLink}>
                        <Share2 size={16} />
                        Generate Share Link
                    </button>

                    {shareLink && (
                        <div className={styles.shareLinkPanel}>
                            <div className={styles.shareLinkField}>
                                <input
                                    type="text"
                                    value={shareLink}
                                    readOnly
                                    className={styles.linkInput}
                                />
                                <button className={styles.copyButton} onClick={copyShareLink}>
                                    {copiedToClipboard ? (
                                        <><CheckCircle size={16} /> Copied</>
                                    ) : (
                                        <><Copy size={16} /> Copy</>
                                    )}
                                </button>
                            </div>
                            <div className={styles.shareOptions}>
                                <div className={styles.shareOption}>
                                    <Shield size={14} />
                                    Anyone with link can view
                                </div>
                                <div className={styles.shareOption}>
                                    <Lock size={14} />
                                    Password protection
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    ));

    // Version History toggle - minimal
    const VersionHistoryPanel = memo(() => (
        <div className={styles.panelSection}>
            <div
                className={styles.versionHeader}
                onClick={() => setShowVersionHistory(!showVersionHistory)}
            >
                <h3 className={styles.panelTitle}>
                    <Clock size={20} />
                    Version History
                </h3>
                <div>
                    {showVersionHistory ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                </div>
            </div>

            {showVersionHistory && (
                <div className={styles.versionList}>
                    <p>5 versions by 5 collaborators</p>
                    <ul className={styles.versionSummary}>
                        <li>Most recent: 2 minutes ago by Mad Hatter</li>
                        <li>Created: 2 hours ago by Alice</li>
                    </ul>
                </div>
            )}
        </div>
    ));

    // Notification component
    const Notification = memo(() => (
        showNotification && (
            <div className={`${styles.notification} ${styles[`notification-${notificationData.type}`]}`}>
                <div className={styles.notificationIcon}>
                    {notificationData.type === 'error' ? (
                        <AlertTriangle size={20} />
                    ) : notificationData.type === 'warning' ? (
                        <Info size={20} />
                    ) : (
                        <CheckCircle size={20} />
                    )}
                </div>
                <span>{notificationData.text}</span>
            </div>
        )
    ));

    return (
        <div className={`${styles.section6Container} ${isDark ? styles.darkMode : styles.lightMode}`}>
            <SectionHeader />

            <div className={styles.trialContainer}>
                {currentStep === 0 && <IntroScreen />}

                {currentStep >= 1 && (
                    <div className={styles.trialContent}>
                        <div className={styles.controlPanels}>
                            {currentStep >= 2 && <ExportFormatsPanel />}
                            {currentStep >= 3 && <SharingPermissionsPanel />}
                            {currentStep >= 4 && <VersionHistoryPanel />}
                        </div>

                        <div className={styles.navigationControls}>
                            <button className={styles.nextButton} onClick={handleNextStep}>
                                {currentStep >= 5 ? 'Complete' : 'Continue'}
                                <ArrowRight size={18} />
                            </button>
                        </div>
                    </div>
                )}

                <Notification />
            </div>
        </div>
    );
});

export default Compiler_7_Steps_InteractiveTour_Specialists_Writing;