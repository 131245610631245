/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                       /SCOPE/templates-report/TANK/generalWebpages/ATOM/container/primary/Primary_Containers_GeneralWebpages_Templates.jsx
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

import React, { useContext } from 'react';
import { ThemeContext } from '@/FullDevApp.jsx';

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                          CONSTANTS & UTILITY FUNCTIONS
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

const logWx4H = (who, what, why, how) => {
    if (typeof window !== 'undefined' && window.DEBUG_LOGGING) {
        console.log(`[WHO: ${who}] [WHAT: ${what}] [WHY: ${why}] [HOW: ${how}]`);
    }
};

// Inline CSS for components that might not have access to CSS modules
const inlineStyles = {
    container: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        width: '100%',
        position: 'relative',
    },
    wrapper: {
        width: '100%',
        margin: '0 auto',
        position: 'relative',
    },
    grid: {
        display: 'grid',
        width: '100%',
    },
    flex: {
        display: 'flex',
        width: '100%',
    }
};

// Container variants
const CONTAINER_VARIANTS = {
    full: 'full',             // Full-width container
    boxed: 'boxed',           // Boxed container with max-width
    fluid: 'fluid',           // Fluid container with side padding
    section: 'section',       // Full-width section with cmd width constraints
    hero: 'hero',             // Hero section with special styling
    card: 'card',             // Card-like container with shadow and border
    masonry: 'masonry',       // Masonry-style layout
    grid: 'grid',             // CSS Grid layout
    flex: 'flex',             // Flexbox layout
    sidebar: 'sidebar',       // Container with sidebar
    nested: 'nested',         // Nested container with adjusted spacing
};

// Standard container sizes for consistent UI
const CONTAINER_SIZES = {
    sm: {
        maxWidth: '640px',
        padding: '1rem',
    },
    md: {
        maxWidth: '768px',
        padding: '1.5rem',
    },
    lg: {
        maxWidth: '1024px',
        padding: '2rem',
    },
    xl: {
        maxWidth: '1280px',
        padding: '2.5rem',
    },
    '2xl': {
        maxWidth: '1536px',
        padding: '3rem',
    },
    full: {
        maxWidth: '100%',
        padding: '0',
    },
};

// Spacing scales for consistent margins and paddings
const SPACING_SCALES = {
    '0': '0',
    '0.5': '0.125rem',
    '1': '0.25rem',
    '1.5': '0.375rem',
    '2': '0.5rem',
    '2.5': '0.625rem',
    '3': '0.75rem',
    '3.5': '0.875rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
};

// Border radius scales
const BORDER_RADIUS_SCALES = {
    none: '0',
    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.375rem',
    xl: '0.5rem',
    '2xl': '0.75rem',
    '3xl': '1rem',
    full: '9999px',
};

// Standard breakpoints
const BREAKPOINTS = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
};

// Grid templates-report for quick layouts
const GRID_TEMPLATES = {
    '1-col': {
        columns: '1fr',
        gap: '1rem',
    },
    '2-col': {
        columns: 'repeat(2, 1fr)',
        gap: '1.5rem',
    },
    '3-col': {
        columns: 'repeat(3, 1fr)',
        gap: '1.5rem',
    },
    '4-col': {
        columns: 'repeat(4, 1fr)',
        gap: '1.5rem',
    },
    '5-col': {
        columns: 'repeat(5, 1fr)',
        gap: '1.5rem',
    },
    '6-col': {
        columns: 'repeat(6, 1fr)',
        gap: '1.5rem',
    },
    'auto-fill-sm': {
        columns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '1.5rem',
    },
    'auto-fill-md': {
        columns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '1.5rem',
    },
    'auto-fill-lg': {
        columns: 'repeat(auto-fill, minmax(400px, 1fr))',
        gap: '1.5rem',
    },
    'featured-left': {
        columns: '2fr 1fr',
        gap: '1.5rem',
    },
    'featured-right': {
        columns: '1fr 2fr',
        gap: '1.5rem',
    },
    'sidebar-left': {
        columns: '1fr 3fr',
        gap: '1.5rem',
    },
    'sidebar-right': {
        columns: '3fr 1fr',
        gap: '1.5rem',
    },
    'header-footer': {
        rows: 'auto 1fr auto',
        gap: '1.5rem',
    },
    'areas': {
        areas: `"header header header"
                "sidebar main main"
                "footer footer footer"`,
        columns: '1fr 4fr 1fr',
        rows: 'auto 1fr auto',
    },
};

// Flexbox templates-report for quick layouts
const FLEX_TEMPLATES = {
    'row': {
        direction: 'row',
        wrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: '1rem',
    },
    'row-reverse': {
        direction: 'row-reverse',
        wrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: '1rem',
    },
    'column': {
        direction: 'column',
        wrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: '1rem',
    },
    'column-reverse': {
        direction: 'column-reverse',
        wrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: '1rem',
    },
    'center': {
        direction: 'row',
        wrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
    },
    'center-column': {
        direction: 'column',
        wrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
    },
    'space-between': {
        direction: 'row',
        wrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0',
    },
    'space-around': {
        direction: 'row',
        wrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '0',
    },
    'space-evenly': {
        direction: 'row',
        wrap: 'nowrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: '0',
    },
    'wrap': {
        direction: 'row',
        wrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '1rem',
    },
    'wrap-center': {
        direction: 'row',
        wrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
    },
};

// Background variants
const BACKGROUND_VARIANTS = {
    none: 'none',                // No background
    solid: 'solid',              // Solid color background
    gradient: 'gradient',        // Gradient background
    image: 'image',              // Image background
    pattern: 'pattern',          // Pattern background
    glass: 'glass',              // Glassmorphic effect
    overlay: 'overlay'           // Overlay with cmd on top
};

// Preset gradient options
const PRESET_GRADIENTS = {
    primary: {
        light: 'linear-gradient(135deg, #4facfe, #00f2fe)',
        dark: 'linear-gradient(135deg, #4facfe, #00f2fe)'
    },
    secondary: {
        light: 'linear-gradient(135deg, #8fd3f4, #84fab0)',
        dark: 'linear-gradient(135deg, #8fd3f4, #84fab0)'
    },
    accent: {
        light: 'linear-gradient(135deg, #f093fb, #f5576c)',
        dark: 'linear-gradient(135deg, #f093fb, #f5576c)'
    },
    success: {
        light: 'linear-gradient(135deg, #84fab0, #8fd3f4)',
        dark: 'linear-gradient(135deg, #84fab0, #8fd3f4)'
    },
    error: {
        light: 'linear-gradient(135deg, #ff6b6b, #ff8e8e)',
        dark: 'linear-gradient(135deg, #ff6b6b, #ff8e8e)'
    },
    warning: {
        light: 'linear-gradient(135deg, #ffd166, #ffb347)',
        dark: 'linear-gradient(135deg, #ffd166, #ffb347)'
    },
    info: {
        light: 'linear-gradient(135deg, #6a11cb, #2575fc)',
        dark: 'linear-gradient(135deg, #6a11cb, #2575fc)'
    },
    sunset: {
        light: 'linear-gradient(135deg, #FF8C42, #F25767)',
        dark: 'linear-gradient(135deg, #FF8C42, #F25767)'
    },
    ocean: {
        light: 'linear-gradient(135deg, #4A90E2, #7442C8)',
        dark: 'linear-gradient(135deg, #4A90E2, #7442C8)'
    },
    forest: {
        light: 'linear-gradient(135deg, #3BDA83, #0F998B)',
        dark: 'linear-gradient(135deg, #3BDA83, #0F998B)'
    },
    night: {
        light: 'linear-gradient(135deg, #232526, #414345)',
        dark: 'linear-gradient(135deg, #232526, #414345)'
    },
    dawn: {
        light: 'linear-gradient(135deg, #F093FB, #F5576C)',
        dark: 'linear-gradient(135deg, #F093FB, #F5576C)'
    },
    cosmic: {
        light: 'linear-gradient(135deg, #3A1C71, #D76D77, #FFAF7B)',
        dark: 'linear-gradient(135deg, #3A1C71, #D76D77, #FFAF7B)'
    },
    neural: {
        light: 'linear-gradient(135deg, #4facfe, #00f2fe)',
        dark: 'linear-gradient(135deg, #4facfe, #00f2fe)'
    }
};

// Preset pattern backgrounds
const PRESET_PATTERNS = {
    grid: {
        light: 'repeating-linear-gradient(to right, #f5f5f5, #f5f5f5 1px, transparent 1px, transparent 20px) repeating-linear-gradient(to bottom, #f5f5f5, #f5f5f5 1px, transparent 1px, transparent 20px)',
        dark: 'repeating-linear-gradient(to right, #333, #333 1px, transparent 1px, transparent 20px) repeating-linear-gradient(to bottom, #333, #333 1px, transparent 1px, transparent 20px)'
    },
    dots: {
        light: 'radial-gradient(#f5f5f5 1px, transparent 1px)',
        dark: 'radial-gradient(#333 1px, transparent 1px)'
    },
    stripes: {
        light: 'linear-gradient(45deg, #f5f5f5 25%, transparent 25%, transparent 50%, #f5f5f5 50%, #f5f5f5 75%, transparent 75%, transparent)',
        dark: 'linear-gradient(45deg, #333 25%, transparent 25%, transparent 50%, #333 50%, #333 75%, transparent 75%, transparent)'
    },
    zigzag: {
        light: 'linear-gradient(135deg, #f5f5f5 25%, transparent 25%) -10px 0, linear-gradient(225deg, #f5f5f5 25%, transparent 25%) -10px 0, linear-gradient(315deg, #f5f5f5 25%, transparent 25%), linear-gradient(45deg, #f5f5f5 25%, transparent 25%)',
        dark: 'linear-gradient(135deg, #333 25%, transparent 25%) -10px 0, linear-gradient(225deg, #333 25%, transparent 25%) -10px 0, linear-gradient(315deg, #333 25%, transparent 25%), linear-gradient(45deg, #333 25%, transparent 25%)'
    },
    waves: {
        light: 'linear-gradient(to right, #f5f5f5, #f5f5f5 5px, transparent 5px, transparent 10px)',
        dark: 'linear-gradient(to right, #333, #333 5px, transparent 5px, transparent 10px)'
    },
};

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                                   PRIMARY CONTAINER COMPONENT
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

/**
 * Primary_Containers_GeneralWebpages_Templates - A versatile and customizable container component supporting multiple layout inlineStyles
 */
const Primary_Containers_GeneralWebpages_Templates = React.forwardRef((props, ref) => {
    const {
        // Content props
        id,
        children,

        // Layout props
        variant = CONTAINER_VARIANTS.boxed,
        size = 'lg',
        minHeight,
        height,
        maxHeight,

        // Spacing props
        m, mt, mr, mb, ml, mx, my,              // margin props
        p, pt, pr, pb, pl, px, py,              // padding props

        // Styling props
        backgroundColor,
        backgroundVariant = BACKGROUND_VARIANTS.none,
        backgroundImage,
        backgroundPattern,
        backgroundGradient,
        backgroundOpacity = 1,
        backgroundBlur = 0,
        backgroundOverlay,
        colorScheme = 'primary',

        borderRadius,
        boxShadow,
        border,
        borderColor,

        // Layout props
        flexTemplate,
        gridTemplate,
        columns,
        rows,
        gap,
        rowGap,
        columnGap,
        areas,

        // Responsive props
        responsive = true,
        hideOnSm,
        hideOnMd,
        hideOnLg,
        hideOnXl,

        // Interactive props
        onClick,
        hoverEffect,
        transition,

        // HTML attributes
        className = '',
        style = {},
        testId,
        as = 'div',

        ...restProps
    } = props;

    // Safely access theme context or use fallback
    const themeContext = useContext(ThemeContext) || { isDark: false };
    const { isDark } = themeContext;

    /*
    ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
                                  VARIANT-SPECIFIC STYLING FUNCTIONS
    ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
    */

    // Helper to get spacing values from scale
    const getSpacingValue = (value) => {
        if (value === undefined) return undefined;
        return SPACING_SCALES[value] || value;
    };

    // Helper to get border radius values from scale
    const getBorderRadiusValue = (value) => {
        if (value === undefined) return undefined;
        return BORDER_RADIUS_SCALES[value] || value;
    };

    // Helper to get appropriate grid template based on name or custom values
    const getGridTemplateValue = () => {
        if (gridTemplate && GRID_TEMPLATES[gridTemplate]) {
            return GRID_TEMPLATES[gridTemplate];
        }

        return {
            columns: columns || GRID_TEMPLATES['1-col'].columns,
            rows: rows || 'auto',
            gap: gap || '1rem',
            rowGap: rowGap,
            columnGap: columnGap,
            areas: areas,
        };
    };

    // Helper to get appropriate flex template based on name or custom values
    const getFlexTemplateValue = () => {
        if (flexTemplate && FLEX_TEMPLATES[flexTemplate]) {
            return FLEX_TEMPLATES[flexTemplate];
        }

        return {
            direction: 'row',
            wrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: gap || '0',
        };
    };

    // Get container max width from size
    const getContainerMaxWidth = () => {
        const containerSize = CONTAINER_SIZES[size] || CONTAINER_SIZES.lg;
        if (variant === CONTAINER_VARIANTS.full) {
            return '100%';
        }
        return containerSize.maxWidth;
    };

    // Get container padding from size
    const getContainerPadding = () => {
        const containerSize = CONTAINER_SIZES[size] || CONTAINER_SIZES.lg;
        if (px !== undefined || py !== undefined) {
            const horizontalPadding = getSpacingValue(px) || '0';
            const verticalPadding = getSpacingValue(py) || '0';
            return `${verticalPadding} ${horizontalPadding}`;
        }

        if (p !== undefined) {
            return getSpacingValue(p);
        }

        if (variant === CONTAINER_VARIANTS.full) {
            return '0';
        }

        if (variant === CONTAINER_VARIANTS.fluid) {
            return `0 ${containerSize.padding}`;
        }

        return containerSize.padding;
    };

    // Get margin values
    const getMarginValues = () => {
        // Individual margin values take precedence over shorthand
        const marginTop = mt !== undefined ? getSpacingValue(mt) : (my !== undefined ? getSpacingValue(my) : (m !== undefined ? getSpacingValue(m) : undefined));
        const marginRight = mr !== undefined ? getSpacingValue(mr) : (mx !== undefined ? getSpacingValue(mx) : (m !== undefined ? getSpacingValue(m) : undefined));
        const marginBottom = mb !== undefined ? getSpacingValue(mb) : (my !== undefined ? getSpacingValue(my) : (m !== undefined ? getSpacingValue(m) : undefined));
        const marginLeft = ml !== undefined ? getSpacingValue(ml) : (mx !== undefined ? getSpacingValue(mx) : (m !== undefined ? getSpacingValue(m) : undefined));

        return {
            marginTop,
            marginRight,
            marginBottom,
            marginLeft,
        };
    };

    // Get padding values
    const getPaddingValues = () => {
        // If container padding is set, we only apply additional padding on top of it
        const containerPadding = getContainerPadding();

        // Individual padding values take precedence over shorthand
        const paddingTop = pt !== undefined ? getSpacingValue(pt) : undefined;
        const paddingRight = pr !== undefined ? getSpacingValue(pr) : undefined;
        const paddingBottom = pb !== undefined ? getSpacingValue(pb) : undefined;
        const paddingLeft = pl !== undefined ? getSpacingValue(pl) : undefined;

        // Only return values that are explicitly set
        const result = {};
        if (paddingTop) result.paddingTop = paddingTop;
        if (paddingRight) result.paddingRight = paddingRight;
        if (paddingBottom) result.paddingBottom = paddingBottom;
        if (paddingLeft) result.paddingLeft = paddingLeft;

        // If no individual padding values are set, use container padding
        if (Object.keys(result).length === 0 && containerPadding) {
            result.padding = containerPadding;
        }

        return result;
    };

    // Get background inlineStyles based on variant
    const getBackgroundStyles = () => {
        switch (backgroundVariant) {
            case BACKGROUND_VARIANTS.solid:
                return {
                    backgroundColor: backgroundColor || (isDark ? '#1E1E1E' : '#FFFFFF'),
                    opacity: backgroundOpacity,
                };

            case BACKGROUND_VARIANTS.gradient:
                const gradient = backgroundGradient || PRESET_GRADIENTS[colorScheme]?.[isDark ? 'dark' : 'light'] || PRESET_GRADIENTS.primary[isDark ? 'dark' : 'light'];
                return {
                    background: gradient,
                    opacity: backgroundOpacity,
                };

            case BACKGROUND_VARIANTS.image:
                return {
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: backgroundOpacity,
                };

            case BACKGROUND_VARIANTS.pattern:
                const pattern = backgroundPattern || PRESET_PATTERNS[isDark ? 'grid' : 'dots'][isDark ? 'dark' : 'light'];
                return {
                    backgroundImage: pattern,
                    backgroundSize: '20px 20px',
                    opacity: backgroundOpacity,
                };

            case BACKGROUND_VARIANTS.glass:
                return {
                    backgroundColor: isDark ? 'rgba(30, 30, 30, 0.5)' : 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: `blur(${backgroundBlur}px)`,
                    WebkitBackdropFilter: `blur(${backgroundBlur}px)`,
                    opacity: backgroundOpacity,
                };

            case BACKGROUND_VARIANTS.overlay:
                return {
                    position: 'relative',
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: backgroundOverlay || 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1,
                    },
                    '& > *': {
                        position: 'relative',
                        zIndex: 2,
                    },
                };

            case BACKGROUND_VARIANTS.none:
            default:
                return {};
        }
    };

    // Get responsive display inlineStyles
    const getResponsiveStyles = () => {
        if (!responsive) return {};

        const styles = {};

        if (hideOnSm) {
            styles[`@media (max-width: ${BREAKPOINTS.sm})`] = { display: 'none' };
        }

        if (hideOnMd) {
            styles[`@media (min-width: ${BREAKPOINTS.sm}) and (max-width: ${BREAKPOINTS.md})`] = { display: 'none' };
        }

        if (hideOnLg) {
            styles[`@media (min-width: ${BREAKPOINTS.md}) and (max-width: ${BREAKPOINTS.lg})`] = { display: 'none' };
        }

        if (hideOnXl) {
            styles[`@media (min-width: ${BREAKPOINTS.lg}) and (max-width: ${BREAKPOINTS.xl})`] = { display: 'none' };
        }

        return styles;
    };

    /* 1. Full width container styling */
    const calculateFullStyles = () => {
        return {
            ...inlineStyles.container,
            width: '100%',
            maxWidth: '100%',
            ...getPaddingValues(),
            ...getMarginValues(),
        };
    };

    /* 2. Boxed container styling */
    const calculateBoxedStyles = () => {
        return {
            ...inlineStyles.container,
            width: '100%',
            maxWidth: getContainerMaxWidth(),
            marginLeft: 'auto',
            marginRight: 'auto',
            ...getPaddingValues(),
            ...getMarginValues(),
        };
    };

    /* 3. Fluid container styling */
    const calculateFluidStyles = () => {
        return {
            ...inlineStyles.container,
            width: '100%',
            maxWidth: '100%',
            ...getPaddingValues(),
            ...getMarginValues(),
        };
    };

    /* 4. Section container styling */
    const calculateSectionStyles = () => {
        return {
            ...inlineStyles.section,
            width: '100%',
            ...getPaddingValues(),
            ...getMarginValues(),
            position: 'relative'
        };
    };

    /* 5. Hero section styling */
    const calculateHeroStyles = () => {
        return {
            ...inlineStyles.section,
            width: '100%',
            minHeight: minHeight || '70vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ...getPaddingValues(),
            ...getMarginValues(),
            position: 'relative'
        };
    };

    /* 6. Card container styling */
    const calculateCardStyles = () => {
        const effectiveBorderRadius = getBorderRadiusValue(borderRadius) || BORDER_RADIUS_SCALES.lg;
        const effectiveBoxShadow = boxShadow || (isDark
            ? '0 4px 20px rgba(0, 0, 0, 0.25)'
            : '0 4px 20px rgba(0, 0, 0, 0.1)');

        return {
            ...inlineStyles.container,
            width: '100%',
            maxWidth: getContainerMaxWidth(),
            borderRadius: effectiveBorderRadius,
            boxShadow: effectiveBoxShadow,
            overflow: 'hidden',
            ...getPaddingValues(),
            ...getMarginValues(),
            backgroundColor: backgroundColor || (isDark ? '#2A2A2A' : '#FFFFFF'),
            border: border || `1px solid ${isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`
        };
    };

    /* 7. Masonry container styling */
    const calculateMasonryStyles = () => {
        return {
            ...inlineStyles.container,
            columnCount: columns || 3,
            columnGap: columnGap || gap || '1rem',
            ...getPaddingValues(),
            ...getMarginValues(),
            '& > *': {
                display: 'inline-block',
                width: '100%',
                marginBottom: rowGap || gap || '1rem',
            }
        };
    };

    /* 8. Grid container styling */
    const calculateGridStyles = () => {
        const gridTemplateValues = getGridTemplateValue();

        return {
            ...inlineStyles.grid,
            gridTemplateColumns: gridTemplateValues.columns,
            gridTemplateRows: gridTemplateValues.rows,
            gap: gridTemplateValues.gap,
            rowGap: gridTemplateValues.rowGap,
            columnGap: gridTemplateValues.columnGap,
            gridTemplateAreas: gridTemplateValues.areas,
            ...getPaddingValues(),
            ...getMarginValues(),
        };
    };

    /* 9. Flex container styling */
    const calculateFlexStyles = () => {
        const flexTemplateValues = getFlexTemplateValue();

        return {
            ...inlineStyles.flex,
            flexDirection: flexTemplateValues.direction,
            flexWrap: flexTemplateValues.wrap,
            justifyContent: flexTemplateValues.justifyContent,
            alignItems: flexTemplateValues.alignItems,
            gap: flexTemplateValues.gap,
            ...getPaddingValues(),
            ...getMarginValues(),
        };
    };

    /* 10. Sidebar container styling */
    const calculateSidebarStyles = () => {
        return {
            ...inlineStyles.grid,
            gridTemplateColumns: '1fr 3fr',
            gap: gap || '2rem',
            ...getPaddingValues(),
            ...getMarginValues(),
            '@media (max-width: 768px)': {
                gridTemplateColumns: '1fr',
                gridTemplateRows: 'auto 1fr',
            }
        };
    };

    /* 11. Nested container styling */
    const calculateNestedStyles = () => {
        return {
            ...inlineStyles.container,
            ...getPaddingValues(),
            ...getMarginValues(),
        };
    };

    /* Get combined inlineStyles based on selected variant */
    const getVariantStyles = () => {
        switch(variant) {
            case CONTAINER_VARIANTS.full:
                return calculateFullStyles();
            case CONTAINER_VARIANTS.fluid:
                return calculateFluidStyles();
            case CONTAINER_VARIANTS.section:
                return calculateSectionStyles();
            case CONTAINER_VARIANTS.hero:
                return calculateHeroStyles();
            case CONTAINER_VARIANTS.card:
                return calculateCardStyles();
            case CONTAINER_VARIANTS.masonry:
                return calculateMasonryStyles();
            case CONTAINER_VARIANTS.grid:
                return calculateGridStyles();
            case CONTAINER_VARIANTS.flex:
                return calculateFlexStyles();
            case CONTAINER_VARIANTS.sidebar:
                return calculateSidebarStyles();
            case CONTAINER_VARIANTS.nested:
                return calculateNestedStyles();
            case CONTAINER_VARIANTS.boxed:
            default:
                return calculateBoxedStyles();
        }
    };

    /*
    ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
                                           COMPONENT RENDERING
    ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
    */

    // Get variant-specific inlineStyles
    const variantStyles = getVariantStyles();

    // Get background inlineStyles
    const backgroundStyles = getBackgroundStyles();

    // Get responsive inlineStyles
    const responsiveStyles = getResponsiveStyles();

    // Combine all inlineStyles
    const combinedStyles = {
        ...variantStyles,
        ...backgroundStyles,
        ...responsiveStyles,
        height,
        minHeight,
        maxHeight,
        borderRadius: getBorderRadiusValue(borderRadius),
        border,
        borderColor,
        boxShadow,
        transition: transition || 'all 0.3s ease',
        cursor: onClick ? 'pointer' : 'default',
        ...style
    };

    // Set the component to render
    const Component = as;

    try {
        return (
            <Component
                ref={ref}
                id={id}
                className={className}
                onClick={onClick}
                style={combinedStyles}
                data-testid={testId}
                {...restProps}
            >
                {children}
            </Component>
        );
    } catch (error) {
        console.error("Error rendering Primary_Containers_GeneralWebpages_Templates:", error);
        return (
            <div style={{
                padding: '1rem',
                border: '1px solid red',
                borderRadius: '0.5rem',
                color: 'red'
            }}>
                Error rendering container component. Please check your console for details.
            </div>
        );
    }
});

/*
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
                                         DEFAULT EXPORTS
▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄▀▄
*/

// Add display name for dev tools
Primary_Containers_GeneralWebpages_Templates.displayName = 'Primary_Containers_GeneralWebpages_Templates';

// Export constants for easier consumption
Primary_Containers_GeneralWebpages_Templates.CONTAINER_VARIANTS = CONTAINER_VARIANTS;
Primary_Containers_GeneralWebpages_Templates.CONTAINER_SIZES = CONTAINER_SIZES;
Primary_Containers_GeneralWebpages_Templates.SPACING_SCALES = SPACING_SCALES;
Primary_Containers_GeneralWebpages_Templates.BORDER_RADIUS_SCALES = BORDER_RADIUS_SCALES;
Primary_Containers_GeneralWebpages_Templates.GRID_TEMPLATES = GRID_TEMPLATES;
Primary_Containers_GeneralWebpages_Templates.FLEX_TEMPLATES = FLEX_TEMPLATES;
Primary_Containers_GeneralWebpages_Templates.BACKGROUND_VARIANTS = BACKGROUND_VARIANTS;
Primary_Containers_GeneralWebpages_Templates.PRESET_GRADIENTS = PRESET_GRADIENTS;
Primary_Containers_GeneralWebpages_Templates.PRESET_PATTERNS = PRESET_PATTERNS;

export default Primary_Containers_GeneralWebpages_Templates;


/*
# Primary_Containers_GeneralWebpages_Templates Implementation Guide

## Basic Setup

### Step 1: Import the Component

First, import the `Primary_Containers_GeneralWebpages_Templates` component into your React file:

    ```jsx
import Primary_Containers_GeneralWebpages_Templates from '/path/to/Primary_Containers_GeneralWebpages_Templates';
```

### Step 2: Implement a Basic Container

At minimum, you need to provide cmd for your container:

    ```jsx
// Basic implementation with cmd
<Primary_Containers_GeneralWebpages_Templates>
  <h1>Hello World</h1>
  <p>This is a simple container with default settings.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

## Component Props Overview

The component accepts the following props:

### Content Props
- `id` - HTML id attribute for the container
- `children` - Content to render inside the container

### Layout Props
- `variant` - Container type (full, boxed, fluid, section, hero, card, masonry, grid, flex, sidebar, nested)
- `size` - Predefined container size (sm, md, lg, xl, 2xl, full)
- `minHeight` - Minimum height of the container
- `height` - Height of the container
- `maxHeight` - Maximum height of the container

### Spacing Props
- `m`, `mt`, `mr`, `mb`, `ml`, `mx`, `my` - Margin properties (accepts values from spacing scale)
- `p`, `pt`, `pr`, `pb`, `pl`, `px`, `py` - Padding properties (accepts values from spacing scale)

### Styling Props
- `backgroundColor` - Background color
- `backgroundVariant` - Background style (none, solid, gradient, image, pattern, glass, overlay)
- `backgroundImage` - URL for image background
- `backgroundPattern` - Pattern for background
- `backgroundGradient` - Gradient for background
- `backgroundOpacity` - Opacity of the background
- `backgroundBlur` - Blur strength for glass effect
- `backgroundOverlay` - Color for overlay background
- `colorScheme` - Color theme (primary, secondary, accent, success, error, warning, info)
- `borderRadius` - Border radius
- `boxShadow` - Box shadow
- `border` - Border style
- `borderColor` - Border color

### Layout Props
- `flexTemplate` - Predefined flexbox layout
- `gridTemplate` - Predefined grid layout
- `columns` - Number of columns for grid layout
- `rows` - Row configuration for grid layout
- `gap` - Gap between grid/flex items
- `rowGap` - Row gap for grid layout
- `columnGap` - Column gap for grid layout
- `areas` - Grid areas for grid layout

### Responsive Props
- `responsive` - Enable responsive behavior
- `hideOnSm` - Hide on small screens
- `hideOnMd` - Hide on medium screens
- `hideOnLg` - Hide on large screens
- `hideOnXl` - Hide on extra large screens

### Interactive Props
- `onClick` - Function to execute when the container is clicked
- `hoverEffect` - Effect on hover
- `transition` - Transition effect

### HTML Attributes
- `className` - Additional CSS classes
- `style` - Additional inline inlineStyles
- `testId` - Data attribute for testing
- `as` - Render as a different element

## Implementing Different Variants

### Full Width Container

Container that spans the entire width of the viewport:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="full"
  backgroundVariant="solid"
  backgroundColor="#f5f5f5"
>
  <h1>Full Width Container</h1>
  <p>This container spans the entire width of the viewport.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

### Boxed Container

Container with a maximum width that's centered in the viewport:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="boxed"
  size="lg"
>
  <h1>Boxed Container</h1>
  <p>This container has a maximum width and is centered in the viewport.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

### Fluid Container

Container that takes full width but has horizontal padding:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="fluid"
  size="lg"
>
  <h1>Fluid Container</h1>
  <p>This container takes the full width but has horizontal padding.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

### Section Container

Full-width container with cmd width constraints:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="section"
  backgroundVariant="solid"
  backgroundColor="#f5f5f5"
  py="8"
>
  <Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
    <h1>Section Container</h1>
    <p>A full-width section with inner cmd constrained to a max width.</p>
  </Primary_Containers_GeneralWebpages_Templates>
</Primary_Containers_GeneralWebpages_Templates>
```

### Hero Section

Full-height container typically used for landing page hero steps:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="hero"
  backgroundVariant="gradient"
  colorScheme="primary"
>
  <Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
    <h1>Hero Container</h1>
    <p>A full-height container for landing page hero steps.</p>
    <button>Get Started</button>
  </Primary_Containers_GeneralWebpages_Templates>
</Primary_Containers_GeneralWebpages_Templates>
```

### Card Container

Container with shadow and rounded corners like a card:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="card"
  size="md"
  p="6"
  borderRadius="xl"
>
  <h2>Card Container</h2>
  <p>A container styled like a card with shadow and rounded corners.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

### Masonry Container

Container that arranges items in a masonry layout:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="masonry"
  columns={3}
  gap="4"
>
  <div style={{ height: '100px', background: '#f0f0f0' }}>Item 1</div>
  <div style={{ height: '200px', background: '#e0e0e0' }}>Item 2</div>
  <div style={{ height: '150px', background: '#d0d0d0' }}>Item 3</div>
  <div style={{ height: '180px', background: '#c0c0c0' }}>Item 4</div>
  <div style={{ height: '120px', background: '#b0b0b0' }}>Item 5</div>
  <div style={{ height: '250px', background: '#a0a0a0' }}>Item 6</div>
</Primary_Containers_GeneralWebpages_Templates>
```

### Grid Container

Container that uses CSS Grid for layout:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="grid"
  gridTemplate="3-col"
  gap="4"
>
  <div style={{ padding: '1rem', background: '#f0f0f0' }}>Grid Item 1</div>
  <div style={{ padding: '1rem', background: '#e0e0e0' }}>Grid Item 2</div>
  <div style={{ padding: '1rem', background: '#d0d0d0' }}>Grid Item 3</div>
  <div style={{ padding: '1rem', background: '#c0c0c0' }}>Grid Item 4</div>
  <div style={{ padding: '1rem', background: '#b0b0b0' }}>Grid Item 5</div>
  <div style={{ padding: '1rem', background: '#a0a0a0' }}>Grid Item 6</div>
</Primary_Containers_GeneralWebpages_Templates>
```

### Flex Container

Container that uses Flexbox for layout:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="flex"
  flexTemplate="space-between"
  gap="4"
>
  <div style={{ padding: '1rem', background: '#f0f0f0' }}>Flex Item 1</div>
  <div style={{ padding: '1rem', background: '#e0e0e0' }}>Flex Item 2</div>
  <div style={{ padding: '1rem', background: '#d0d0d0' }}>Flex Item 3</div>
</Primary_Containers_GeneralWebpages_Templates>
```

### Sidebar Container

Container with a sidebar and main cmd area:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="sidebar"
  gap="6"
>
  <div style={{ padding: '1rem', background: '#f0f0f0' }}>Sidebar</div>
  <div style={{ padding: '1rem', background: '#e0e0e0' }}>Main Content</div>
</Primary_Containers_GeneralWebpages_Templates>
```

### Nested Container

Container designed to be nested inside other containers:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates variant="section" py="8">
  <Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
    <h2>Main Section</h2>
    <Primary_Containers_GeneralWebpages_Templates
      variant="nested"
      backgroundColor="#f5f5f5"
      p="4"
      borderRadius="md"
      mt="4"
    >
      <p>This is a nested container with its own styling.</p>
    </Primary_Containers_GeneralWebpages_Templates>
  </Primary_Containers_GeneralWebpages_Templates>
</Primary_Containers_GeneralWebpages_Templates>
```

## Background Styling

### Solid Background

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  backgroundVariant="solid"
  backgroundColor="#f5f5f5"
  p="6"
>
  <h2>Solid Background</h2>
  <p>Container with a solid background color.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

### Gradient Background

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  backgroundVariant="gradient"
  colorScheme="primary"
  p="6"
>
  <h2>Gradient Background</h2>
  <p>Container with a gradient background.</p>
</Primary_Containers_GeneralWebpages_Templates>

// With custom gradient
<Primary_Containers_GeneralWebpages_Templates
  backgroundVariant="gradient"
  backgroundGradient="linear-gradient(45deg, #FF8C42, #F25767)"
  p="6"
>
  <h2>Custom Gradient</h2>
  <p>Container with a custom gradient background.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

### Image Background

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  backgroundVariant="image"
  backgroundImage="/path/to/image.jpg"
  p="6"
  minHeight="300px"
>
  <h2 style={{ color: 'white' }}>Image Background</h2>
  <p style={{ color: 'white' }}>Container with an image background.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

### Pattern Background

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  backgroundVariant="pattern"
  p="6"
>
  <h2>Pattern Background</h2>
  <p>Container with a pattern background.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

### Glass Effect

    ```jsx
<div style={{ background: 'url(/path/to/background.jpg)', padding: '2rem' }}>
  <Primary_Containers_GeneralWebpages_Templates
    backgroundVariant="glass"
    backgroundBlur={10}
    p="6"
    borderRadius="lg"
  >
    <h2>Glass Effect</h2>
    <p>Container with a glassmorphic effect.</p>
  </Primary_Containers_GeneralWebpages_Templates>
</div>
```

### Overlay Background

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  backgroundVariant="overlay"
  backgroundImage="/path/to/image.jpg"
  backgroundOverlay="rgba(0, 0, 0, 0.7)"
  p="6"
  minHeight="300px"
>
  <h2 style={{ color: 'white' }}>Overlay Background</h2>
  <p style={{ color: 'white' }}>Container with an overlay on top of a background image.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

## Spacing Configuration

Using the spacing scale for margins and paddings:

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  p="4"          // Padding on all sides
  pt="6"         // Padding top
  pb="6"         // Padding bottom
  px="8"         // Padding left and right
  m="4"          // Margin on all sides
  mt="6"         // Margin top
  mb="0"         // Margin bottom
  mx="auto"      // Center horizontally with auto margins
>
  <h2>Spacing Configuration</h2>
  <p>Container with custom spacing settings.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

## Layout Configuration

### Grid Layout

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="grid"
  columns="repeat(3, 1fr)"    // 3 equal columns
  rows="auto 1fr auto"        // Header, cmd, footer
  gap="4"                     // Gap between items
  p="4"
>
  <div style={{ gridColumn: '1 / -1', padding: '1rem', background: '#f0f0f0' }}>Header</div>
  <div style={{ padding: '1rem', background: '#e0e0e0' }}>Sidebar</div>
  <div style={{ gridColumn: 'span 2', padding: '1rem', background: '#d0d0d0' }}>Main Content</div>
  <div style={{ gridColumn: '1 / -1', padding: '1rem', background: '#c0c0c0' }}>Footer</div>
</Primary_Containers_GeneralWebpages_Templates>
```

### Using Predefined Grid Templates

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="grid"
  gridTemplate="sidebar-left"
  p="4"
>
  <div style={{ padding: '1rem', background: '#f0f0f0' }}>Sidebar</div>
  <div style={{ padding: '1rem', background: '#e0e0e0' }}>Main Content</div>
</Primary_Containers_GeneralWebpages_Templates>
```

### Flex Layout

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="flex"
  flexTemplate="center"
  gap="4"
  p="4"
>
  <div style={{ padding: '1rem', background: '#f0f0f0' }}>Item 1</div>
  <div style={{ padding: '1rem', background: '#e0e0e0' }}>Item 2</div>
  <div style={{ padding: '1rem', background: '#d0d0d0' }}>Item 3</div>
</Primary_Containers_GeneralWebpages_Templates>
```

### Using Custom Flex Properties

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="flex"
  style={{
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  }}
  gap="4"
  p="4"
  minHeight="300px"
>
  <div style={{ padding: '1rem', background: '#f0f0f0' }}>Top</div>
  <div style={{ padding: '1rem', background: '#e0e0e0' }}>Middle</div>
  <div style={{ padding: '1rem', background: '#d0d0d0' }}>Bottom</div>
</Primary_Containers_GeneralWebpages_Templates>
```

## Responsive Behavior

### Hide on Specific Breakpoints

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  hideOnSm  // Hide on small screens
  p="4"
  backgroundVariant="solid"
  backgroundColor="#f5f5f5"
>
  <p>This container is hidden on small screens.</p>
</Primary_Containers_GeneralWebpages_Templates>

<Primary_Containers_GeneralWebpages_Templates
  hideOnMd  // Hide on medium screens
  p="4"
  backgroundVariant="solid"
  backgroundColor="#e0e0e0"
>
  <p>This container is hidden on medium screens.</p>
</Primary_Containers_GeneralWebpages_Templates>
```

### Responsive Grid

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="grid"
  style={{
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  }}
  gap="4"
  p="4"
>
  <div style={{ padding: '1rem', background: '#f0f0f0' }}>Item 1</div>
  <div style={{ padding: '1rem', background: '#e0e0e0' }}>Item 2</div>
  <div style={{ padding: '1rem', background: '#d0d0d0' }}>Item 3</div>
  <div style={{ padding: '1rem', background: '#c0c0c0' }}>Item 4</div>
</Primary_Containers_GeneralWebpages_Templates>
```

## Common Use Cases

### Page Layout

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="grid"
  gridTemplate="header-footer"
  minHeight="100vh"
>
  <header style={{ padding: '1rem', background: '#f0f0f0' }}>
    <Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
      <h1>Website Header</h1>
    </Primary_Containers_GeneralWebpages_Templates>
  </header>

  <main>
    <Primary_Containers_GeneralWebpages_Templates
      variant="section"
      py="8"
      backgroundVariant="solid"
      backgroundColor="#f9f9f9"
    >
      <Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
        <h2>Main Content Section</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </Primary_Containers_GeneralWebpages_Templates>
    </Primary_Containers_GeneralWebpages_Templates>

    <Primary_Containers_GeneralWebpages_Templates
      variant="section"
      py="8"
    >
      <Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
        <h2>Another Section</h2>
        <p>Nullam quis risus eget urna mollis ornare vel eu leo.</p>
      </Primary_Containers_GeneralWebpages_Templates>
    </Primary_Containers_GeneralWebpages_Templates>
  </main>

  <footer style={{ padding: '1rem', background: '#333', color: 'white' }}>
    <Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
      <p>Website Footer © 2023</p>
    </Primary_Containers_GeneralWebpages_Templates>
  </footer>
</Primary_Containers_GeneralWebpages_Templates>
```

### Card Grid Layout

    ```jsx
<Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
  <h2 style={{ marginBottom: '2rem' }}>Featured Products</h2>

  <Primary_Containers_GeneralWebpages_Templates
    variant="grid"
    gridTemplate="auto-fill-md"
    gap="6"
  >
    {products.map(product => (
      <Primary_Containers_GeneralWebpages_Templates
        key={product.id}
        variant="card"
        p="0"
        borderRadius="lg"
        overflow="hidden"
      >
        <img src={product.image} alt={product.name} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
        <div style={{ padding: '1.5rem' }}>
          <h3>{product.name}</h3>
          <p>{product.description}</p>
          <button style={{ marginTop: '1rem' }}>Add to Cart</button>
        </div>
      </Primary_Containers_GeneralWebpages_Templates>
    ))}
  </Primary_Containers_GeneralWebpages_Templates>
</Primary_Containers_GeneralWebpages_Templates>
```

### Hero Section with Call-to-Action

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="hero"
  backgroundVariant="image"
  backgroundImage="/path/to/hero-bg.jpg"
  backgroundOverlay="rgba(0, 0, 0, 0.6)"
  p="8"
>
  <Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
    <div style={{ color: 'white', textAlign: 'center', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{ fontSize: '3rem', marginBottom: '1rem' }}>Welcome to Our Platform</h1>
      <p style={{ fontSize: '1.5rem', marginBottom: '2rem' }}>The all-in-one solution for your business needs.</p>
      <div>
        <button style={{ padding: '1rem 2rem', fontSize: '1.25rem', marginRight: '1rem' }}>Get Started</button>
        <button style={{ padding: '1rem 2rem', fontSize: '1.25rem' }}>Learn More</button>
      </div>
    </div>
  </Primary_Containers_GeneralWebpages_Templates>
</Primary_Containers_GeneralWebpages_Templates>
```

### Feature Section

    ```jsx
<Primary_Containers_GeneralWebpages_Templates
  variant="section"
  py="16"
  backgroundVariant="solid"
  backgroundColor="#f5f5f5"
>
  <Primary_Containers_GeneralWebpages_Templates variant="boxed" size="lg">
    <h2 style={{ textAlign: 'center', marginBottom: '3rem' }}>Our Features</h2>

    <Primary_Containers_GeneralWebpages_Templates
      variant="grid"
      gridTemplate="3-col"
      gap="8"
    >
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '3rem', marginBottom: '1rem' }}>🚀</div>
        <h3 style={{ marginBottom: '1rem' }}>Lightning Fast</h3>
        <p>Our platform is optimized for speed, ensuring the best performance.</p>
      </div>

      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '3rem', marginBottom: '1rem' }}>🔒</div>
        <h3 style={{ marginBottom: '1rem' }}>Secure</h3>
        <p>We prioritize your security with enterprise-grade protection.</p>
      </div>

      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '3rem', marginBottom: '1rem' }}>💡</div>
        <h3 style={{ marginBottom: '1rem' }}>Intuitive</h3>
        <p>Our user-friendly interface makes everything simple and intuitive.</p>
      </div>
    </Primary_Containers_GeneralWebpages_Templates>
  </Primary_Containers_GeneralWebpages_Templates>
</Primary_Containers_GeneralWebpages_Templates>
```

## Troubleshooting

### Container Not Rendering

If your container is not displaying properly:

1. Check that you've imported the component correctly
2. Verify that your React version is compatible
3. Ensure the ThemeContext is available in your application
4. Check the console for any errors

### Spacing Issues

If the spacing doesn't look as expected:

1. Ensure that you're using the correct spacing values from the spacing scale
2. Check for conflicting inlineStyles in your application
3. Try using inline style overrides with the `style` prop for debugging

### Grid Layout Issues

If your grid layout doesn't work as expected:

1. Ensure that you're providing valid CSS Grid properties
2. Check if you're using the correct grid template
3. Verify that you have the correct number of child elements
4. Try simplifying your grid layout for testing

### Responsive Issues

If your responsive behavior isn't working:

1. Ensure that you've set the `responsive` prop to true
2. Check that you're using the correct breakpoint props (`hideOnSm`, etc.)
3. Test with different viewport sizes
4. Use browser dev tools to inspect the applied inlineStyles

### Background Issues

If backgrounds aren't displaying correctly:

1. Ensure that you're using the correct background variant
2. Check if your image paths are correct
3. Try with a simpler background first (e.g., solid color)
4. Verify z-index values if you have overlapping elements
*/